/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PlatformAdminApi = (function() {

    /**
     * Namespace PlatformAdminApi.
     * @exports PlatformAdminApi
     * @namespace
     */
    var PlatformAdminApi = {};

    PlatformAdminApi.IdRequest = (function() {

        /**
         * Properties of an IdRequest.
         * @memberof PlatformAdminApi
         * @interface IIdRequest
         * @property {number|null} [id] IdRequest id
         */

        /**
         * Constructs a new IdRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an IdRequest.
         * @implements IIdRequest
         * @constructor
         * @param {PlatformAdminApi.IIdRequest=} [properties] Properties to set
         */
        function IdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IdRequest id.
         * @member {number} id
         * @memberof PlatformAdminApi.IdRequest
         * @instance
         */
        IdRequest.prototype.id = 0;

        /**
         * Encodes the specified IdRequest message. Does not implicitly {@link PlatformAdminApi.IdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.IdRequest
         * @static
         * @param {PlatformAdminApi.IIdRequest} message IdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            return writer;
        };

        /**
         * Decodes an IdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.IdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.IdRequest} IdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.IdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return IdRequest;
    })();

    PlatformAdminApi.UuidRequest = (function() {

        /**
         * Properties of an UuidRequest.
         * @memberof PlatformAdminApi
         * @interface IUuidRequest
         * @property {string|null} [id] UuidRequest id
         */

        /**
         * Constructs a new UuidRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UuidRequest.
         * @implements IUuidRequest
         * @constructor
         * @param {PlatformAdminApi.IUuidRequest=} [properties] Properties to set
         */
        function UuidRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UuidRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UuidRequest
         * @instance
         */
        UuidRequest.prototype.id = "";

        /**
         * Encodes the specified UuidRequest message. Does not implicitly {@link PlatformAdminApi.UuidRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UuidRequest
         * @static
         * @param {PlatformAdminApi.IUuidRequest} message UuidRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UuidRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes an UuidRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UuidRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UuidRequest} UuidRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UuidRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UuidRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UuidRequest;
    })();

    PlatformAdminApi.UuidResponse = (function() {

        /**
         * Properties of an UuidResponse.
         * @memberof PlatformAdminApi
         * @interface IUuidResponse
         * @property {string|null} [id] UuidResponse id
         */

        /**
         * Constructs a new UuidResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UuidResponse.
         * @implements IUuidResponse
         * @constructor
         * @param {PlatformAdminApi.IUuidResponse=} [properties] Properties to set
         */
        function UuidResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UuidResponse id.
         * @member {string} id
         * @memberof PlatformAdminApi.UuidResponse
         * @instance
         */
        UuidResponse.prototype.id = "";

        /**
         * Encodes the specified UuidResponse message. Does not implicitly {@link PlatformAdminApi.UuidResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UuidResponse
         * @static
         * @param {PlatformAdminApi.IUuidResponse} message UuidResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UuidResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes an UuidResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UuidResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UuidResponse} UuidResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UuidResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UuidResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UuidResponse;
    })();

    PlatformAdminApi.SortField = (function() {

        /**
         * Properties of a SortField.
         * @memberof PlatformAdminApi
         * @interface ISortField
         * @property {string|null} [field] SortField field
         * @property {PlatformAdminApi.SortOrder|null} [order] SortField order
         */

        /**
         * Constructs a new SortField.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SortField.
         * @implements ISortField
         * @constructor
         * @param {PlatformAdminApi.ISortField=} [properties] Properties to set
         */
        function SortField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SortField field.
         * @member {string} field
         * @memberof PlatformAdminApi.SortField
         * @instance
         */
        SortField.prototype.field = "";

        /**
         * SortField order.
         * @member {PlatformAdminApi.SortOrder} order
         * @memberof PlatformAdminApi.SortField
         * @instance
         */
        SortField.prototype.order = 0;

        /**
         * Encodes the specified SortField message. Does not implicitly {@link PlatformAdminApi.SortField.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SortField
         * @static
         * @param {PlatformAdminApi.ISortField} message SortField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SortField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.field != null && Object.hasOwnProperty.call(message, "field"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.order);
            return writer;
        };

        /**
         * Decodes a SortField message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SortField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SortField} SortField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SortField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SortField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.field = reader.string();
                    break;
                case 2:
                    message.order = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SortField;
    })();

    /**
     * SortOrder enum.
     * @name PlatformAdminApi.SortOrder
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} ASC=1 ASC value
     * @property {number} DESC=2 DESC value
     */
    PlatformAdminApi.SortOrder = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "ASC"] = 1;
        values[valuesById[2] = "DESC"] = 2;
        return values;
    })();

    PlatformAdminApi.UuidCondition = (function() {

        /**
         * Properties of an UuidCondition.
         * @memberof PlatformAdminApi
         * @interface IUuidCondition
         * @property {string|null} [eq] UuidCondition eq
         * @property {string|null} [not] UuidCondition not
         * @property {Array.<string>|null} ["in"] UuidCondition in
         * @property {Array.<string>|null} [notIn] UuidCondition notIn
         * @property {boolean|null} [isNull] UuidCondition isNull
         * @property {boolean|null} [isNotNull] UuidCondition isNotNull
         */

        /**
         * Constructs a new UuidCondition.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UuidCondition.
         * @implements IUuidCondition
         * @constructor
         * @param {PlatformAdminApi.IUuidCondition=} [properties] Properties to set
         */
        function UuidCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UuidCondition eq.
         * @member {string} eq
         * @memberof PlatformAdminApi.UuidCondition
         * @instance
         */
        UuidCondition.prototype.eq = "";

        /**
         * UuidCondition not.
         * @member {string} not
         * @memberof PlatformAdminApi.UuidCondition
         * @instance
         */
        UuidCondition.prototype.not = "";

        /**
         * UuidCondition in.
         * @member {Array.<string>} in
         * @memberof PlatformAdminApi.UuidCondition
         * @instance
         */
        UuidCondition.prototype["in"] = $util.emptyArray;

        /**
         * UuidCondition notIn.
         * @member {Array.<string>} notIn
         * @memberof PlatformAdminApi.UuidCondition
         * @instance
         */
        UuidCondition.prototype.notIn = $util.emptyArray;

        /**
         * UuidCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformAdminApi.UuidCondition
         * @instance
         */
        UuidCondition.prototype.isNull = false;

        /**
         * UuidCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformAdminApi.UuidCondition
         * @instance
         */
        UuidCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified UuidCondition message. Does not implicitly {@link PlatformAdminApi.UuidCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UuidCondition
         * @static
         * @param {PlatformAdminApi.IUuidCondition} message UuidCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UuidCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message["in"] != null && message["in"].length)
                for (var i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notIn[i]);
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes an UuidCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UuidCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UuidCondition} UuidCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UuidCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UuidCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    message["in"].push(reader.string());
                    break;
                case 4:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    message.notIn.push(reader.string());
                    break;
                case 6:
                    message.isNull = reader.bool();
                    break;
                case 7:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UuidCondition;
    })();

    PlatformAdminApi.DateCondition = (function() {

        /**
         * Properties of a DateCondition.
         * @memberof PlatformAdminApi
         * @interface IDateCondition
         * @property {string|null} [eq] DateCondition eq
         * @property {string|null} [not] DateCondition not
         * @property {string|null} [less] DateCondition less
         * @property {string|null} [lessEq] DateCondition lessEq
         * @property {string|null} [more] DateCondition more
         * @property {string|null} [moreEq] DateCondition moreEq
         * @property {PlatformAdminApi.IStringBetweenOperation|null} [between] DateCondition between
         * @property {PlatformAdminApi.IStringBetweenOperation|null} [notBetween] DateCondition notBetween
         * @property {boolean|null} [isNull] DateCondition isNull
         * @property {boolean|null} [isNotNull] DateCondition isNotNull
         */

        /**
         * Constructs a new DateCondition.
         * @memberof PlatformAdminApi
         * @classdesc Represents a DateCondition.
         * @implements IDateCondition
         * @constructor
         * @param {PlatformAdminApi.IDateCondition=} [properties] Properties to set
         */
        function DateCondition(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateCondition eq.
         * @member {string} eq
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.eq = "";

        /**
         * DateCondition not.
         * @member {string} not
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.not = "";

        /**
         * DateCondition less.
         * @member {string} less
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.less = "";

        /**
         * DateCondition lessEq.
         * @member {string} lessEq
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.lessEq = "";

        /**
         * DateCondition more.
         * @member {string} more
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.more = "";

        /**
         * DateCondition moreEq.
         * @member {string} moreEq
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.moreEq = "";

        /**
         * DateCondition between.
         * @member {PlatformAdminApi.IStringBetweenOperation|null|undefined} between
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.between = null;

        /**
         * DateCondition notBetween.
         * @member {PlatformAdminApi.IStringBetweenOperation|null|undefined} notBetween
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.notBetween = null;

        /**
         * DateCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.isNull = false;

        /**
         * DateCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformAdminApi.DateCondition
         * @instance
         */
        DateCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified DateCondition message. Does not implicitly {@link PlatformAdminApi.DateCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.DateCondition
         * @static
         * @param {PlatformAdminApi.IDateCondition} message DateCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message.less != null && Object.hasOwnProperty.call(message, "less"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.less);
            if (message.lessEq != null && Object.hasOwnProperty.call(message, "lessEq"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lessEq);
            if (message.more != null && Object.hasOwnProperty.call(message, "more"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.more);
            if (message.moreEq != null && Object.hasOwnProperty.call(message, "moreEq"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.moreEq);
            if (message.between != null && Object.hasOwnProperty.call(message, "between"))
                $root.PlatformAdminApi.StringBetweenOperation.encode(message.between, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.notBetween != null && Object.hasOwnProperty.call(message, "notBetween"))
                $root.PlatformAdminApi.StringBetweenOperation.encode(message.notBetween, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a DateCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.DateCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.DateCondition} DateCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.DateCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    message.less = reader.string();
                    break;
                case 4:
                    message.lessEq = reader.string();
                    break;
                case 5:
                    message.more = reader.string();
                    break;
                case 6:
                    message.moreEq = reader.string();
                    break;
                case 7:
                    message.between = $root.PlatformAdminApi.StringBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.notBetween = $root.PlatformAdminApi.StringBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.isNull = reader.bool();
                    break;
                case 10:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DateCondition;
    })();

    PlatformAdminApi.StringCondition = (function() {

        /**
         * Properties of a StringCondition.
         * @memberof PlatformAdminApi
         * @interface IStringCondition
         * @property {string|null} [eq] StringCondition eq
         * @property {string|null} [not] StringCondition not
         * @property {Array.<string>|null} ["in"] StringCondition in
         * @property {Array.<string>|null} [notIn] StringCondition notIn
         * @property {string|null} [like] StringCondition like
         * @property {boolean|null} [isNull] StringCondition isNull
         * @property {boolean|null} [isNotNull] StringCondition isNotNull
         */

        /**
         * Constructs a new StringCondition.
         * @memberof PlatformAdminApi
         * @classdesc Represents a StringCondition.
         * @implements IStringCondition
         * @constructor
         * @param {PlatformAdminApi.IStringCondition=} [properties] Properties to set
         */
        function StringCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringCondition eq.
         * @member {string} eq
         * @memberof PlatformAdminApi.StringCondition
         * @instance
         */
        StringCondition.prototype.eq = "";

        /**
         * StringCondition not.
         * @member {string} not
         * @memberof PlatformAdminApi.StringCondition
         * @instance
         */
        StringCondition.prototype.not = "";

        /**
         * StringCondition in.
         * @member {Array.<string>} in
         * @memberof PlatformAdminApi.StringCondition
         * @instance
         */
        StringCondition.prototype["in"] = $util.emptyArray;

        /**
         * StringCondition notIn.
         * @member {Array.<string>} notIn
         * @memberof PlatformAdminApi.StringCondition
         * @instance
         */
        StringCondition.prototype.notIn = $util.emptyArray;

        /**
         * StringCondition like.
         * @member {string} like
         * @memberof PlatformAdminApi.StringCondition
         * @instance
         */
        StringCondition.prototype.like = "";

        /**
         * StringCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformAdminApi.StringCondition
         * @instance
         */
        StringCondition.prototype.isNull = false;

        /**
         * StringCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformAdminApi.StringCondition
         * @instance
         */
        StringCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified StringCondition message. Does not implicitly {@link PlatformAdminApi.StringCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.StringCondition
         * @static
         * @param {PlatformAdminApi.IStringCondition} message StringCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message["in"] != null && message["in"].length)
                for (var i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notIn[i]);
            if (message.like != null && Object.hasOwnProperty.call(message, "like"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.like);
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a StringCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.StringCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.StringCondition} StringCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.StringCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    message["in"].push(reader.string());
                    break;
                case 4:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    message.notIn.push(reader.string());
                    break;
                case 5:
                    message.like = reader.string();
                    break;
                case 6:
                    message.isNull = reader.bool();
                    break;
                case 7:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return StringCondition;
    })();

    PlatformAdminApi.NumberCondition = (function() {

        /**
         * Properties of a NumberCondition.
         * @memberof PlatformAdminApi
         * @interface INumberCondition
         * @property {number|null} [eq] NumberCondition eq
         * @property {number|null} [not] NumberCondition not
         * @property {number|null} [less] NumberCondition less
         * @property {number|null} [lessEq] NumberCondition lessEq
         * @property {number|null} [more] NumberCondition more
         * @property {number|null} [moreEq] NumberCondition moreEq
         * @property {PlatformAdminApi.INumberBetweenOperation|null} [between] NumberCondition between
         * @property {PlatformAdminApi.INumberBetweenOperation|null} [notBetween] NumberCondition notBetween
         * @property {Array.<number>|null} ["in"] NumberCondition in
         * @property {Array.<number>|null} [notIn] NumberCondition notIn
         * @property {boolean|null} [isNull] NumberCondition isNull
         * @property {boolean|null} [isNotNull] NumberCondition isNotNull
         */

        /**
         * Constructs a new NumberCondition.
         * @memberof PlatformAdminApi
         * @classdesc Represents a NumberCondition.
         * @implements INumberCondition
         * @constructor
         * @param {PlatformAdminApi.INumberCondition=} [properties] Properties to set
         */
        function NumberCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NumberCondition eq.
         * @member {number} eq
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.eq = 0;

        /**
         * NumberCondition not.
         * @member {number} not
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.not = 0;

        /**
         * NumberCondition less.
         * @member {number} less
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.less = 0;

        /**
         * NumberCondition lessEq.
         * @member {number} lessEq
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.lessEq = 0;

        /**
         * NumberCondition more.
         * @member {number} more
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.more = 0;

        /**
         * NumberCondition moreEq.
         * @member {number} moreEq
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.moreEq = 0;

        /**
         * NumberCondition between.
         * @member {PlatformAdminApi.INumberBetweenOperation|null|undefined} between
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.between = null;

        /**
         * NumberCondition notBetween.
         * @member {PlatformAdminApi.INumberBetweenOperation|null|undefined} notBetween
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.notBetween = null;

        /**
         * NumberCondition in.
         * @member {Array.<number>} in
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype["in"] = $util.emptyArray;

        /**
         * NumberCondition notIn.
         * @member {Array.<number>} notIn
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.notIn = $util.emptyArray;

        /**
         * NumberCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.isNull = false;

        /**
         * NumberCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformAdminApi.NumberCondition
         * @instance
         */
        NumberCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified NumberCondition message. Does not implicitly {@link PlatformAdminApi.NumberCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.NumberCondition
         * @static
         * @param {PlatformAdminApi.INumberCondition} message NumberCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NumberCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.not);
            if (message.less != null && Object.hasOwnProperty.call(message, "less"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.less);
            if (message.lessEq != null && Object.hasOwnProperty.call(message, "lessEq"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lessEq);
            if (message.more != null && Object.hasOwnProperty.call(message, "more"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.more);
            if (message.moreEq != null && Object.hasOwnProperty.call(message, "moreEq"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.moreEq);
            if (message.between != null && Object.hasOwnProperty.call(message, "between"))
                $root.PlatformAdminApi.NumberBetweenOperation.encode(message.between, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.notBetween != null && Object.hasOwnProperty.call(message, "notBetween"))
                $root.PlatformAdminApi.NumberBetweenOperation.encode(message.notBetween, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message["in"] != null && message["in"].length) {
                writer.uint32(/* id 9, wireType 2 =*/74).fork();
                for (var i = 0; i < message["in"].length; ++i)
                    writer.int32(message["in"][i]);
                writer.ldelim();
            }
            if (message.notIn != null && message.notIn.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.int32(message.notIn[i]);
                writer.ldelim();
            }
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a NumberCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.NumberCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.NumberCondition} NumberCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NumberCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.NumberCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.int32();
                    break;
                case 2:
                    message.not = reader.int32();
                    break;
                case 3:
                    message.less = reader.int32();
                    break;
                case 4:
                    message.lessEq = reader.int32();
                    break;
                case 5:
                    message.more = reader.int32();
                    break;
                case 6:
                    message.moreEq = reader.int32();
                    break;
                case 7:
                    message.between = $root.PlatformAdminApi.NumberBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.notBetween = $root.PlatformAdminApi.NumberBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 9:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message["in"].push(reader.int32());
                    } else
                        message["in"].push(reader.int32());
                    break;
                case 10:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.notIn.push(reader.int32());
                    } else
                        message.notIn.push(reader.int32());
                    break;
                case 11:
                    message.isNull = reader.bool();
                    break;
                case 12:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NumberCondition;
    })();

    PlatformAdminApi.StringBetweenOperation = (function() {

        /**
         * Properties of a StringBetweenOperation.
         * @memberof PlatformAdminApi
         * @interface IStringBetweenOperation
         * @property {string|null} [low] StringBetweenOperation low
         * @property {string|null} [high] StringBetweenOperation high
         */

        /**
         * Constructs a new StringBetweenOperation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a StringBetweenOperation.
         * @implements IStringBetweenOperation
         * @constructor
         * @param {PlatformAdminApi.IStringBetweenOperation=} [properties] Properties to set
         */
        function StringBetweenOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringBetweenOperation low.
         * @member {string} low
         * @memberof PlatformAdminApi.StringBetweenOperation
         * @instance
         */
        StringBetweenOperation.prototype.low = "";

        /**
         * StringBetweenOperation high.
         * @member {string} high
         * @memberof PlatformAdminApi.StringBetweenOperation
         * @instance
         */
        StringBetweenOperation.prototype.high = "";

        /**
         * Encodes the specified StringBetweenOperation message. Does not implicitly {@link PlatformAdminApi.StringBetweenOperation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.StringBetweenOperation
         * @static
         * @param {PlatformAdminApi.IStringBetweenOperation} message StringBetweenOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringBetweenOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.low);
            if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.high);
            return writer;
        };

        /**
         * Decodes a StringBetweenOperation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.StringBetweenOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.StringBetweenOperation} StringBetweenOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringBetweenOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.StringBetweenOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.low = reader.string();
                    break;
                case 2:
                    message.high = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return StringBetweenOperation;
    })();

    PlatformAdminApi.NumberBetweenOperation = (function() {

        /**
         * Properties of a NumberBetweenOperation.
         * @memberof PlatformAdminApi
         * @interface INumberBetweenOperation
         * @property {number|null} [low] NumberBetweenOperation low
         * @property {number|null} [high] NumberBetweenOperation high
         */

        /**
         * Constructs a new NumberBetweenOperation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a NumberBetweenOperation.
         * @implements INumberBetweenOperation
         * @constructor
         * @param {PlatformAdminApi.INumberBetweenOperation=} [properties] Properties to set
         */
        function NumberBetweenOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NumberBetweenOperation low.
         * @member {number} low
         * @memberof PlatformAdminApi.NumberBetweenOperation
         * @instance
         */
        NumberBetweenOperation.prototype.low = 0;

        /**
         * NumberBetweenOperation high.
         * @member {number} high
         * @memberof PlatformAdminApi.NumberBetweenOperation
         * @instance
         */
        NumberBetweenOperation.prototype.high = 0;

        /**
         * Encodes the specified NumberBetweenOperation message. Does not implicitly {@link PlatformAdminApi.NumberBetweenOperation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.NumberBetweenOperation
         * @static
         * @param {PlatformAdminApi.INumberBetweenOperation} message NumberBetweenOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NumberBetweenOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.low);
            if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.high);
            return writer;
        };

        /**
         * Decodes a NumberBetweenOperation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.NumberBetweenOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.NumberBetweenOperation} NumberBetweenOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NumberBetweenOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.NumberBetweenOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.low = reader.int32();
                    break;
                case 2:
                    message.high = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NumberBetweenOperation;
    })();

    PlatformAdminApi.Empty = (function() {

        /**
         * Properties of an Empty.
         * @memberof PlatformAdminApi
         * @interface IEmpty
         */

        /**
         * Constructs a new Empty.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Empty.
         * @implements IEmpty
         * @constructor
         * @param {PlatformAdminApi.IEmpty=} [properties] Properties to set
         */
        function Empty(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified Empty message. Does not implicitly {@link PlatformAdminApi.Empty.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Empty
         * @static
         * @param {PlatformAdminApi.IEmpty} message Empty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Empty.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an Empty message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Empty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Empty} Empty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Empty.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Empty();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Empty;
    })();

    PlatformAdminApi.Coordinates = (function() {

        /**
         * Properties of a Coordinates.
         * @memberof PlatformAdminApi
         * @interface ICoordinates
         * @property {number|null} [lat] Coordinates lat
         * @property {number|null} [lng] Coordinates lng
         */

        /**
         * Constructs a new Coordinates.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Coordinates.
         * @implements ICoordinates
         * @constructor
         * @param {PlatformAdminApi.ICoordinates=} [properties] Properties to set
         */
        function Coordinates(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Coordinates lat.
         * @member {number} lat
         * @memberof PlatformAdminApi.Coordinates
         * @instance
         */
        Coordinates.prototype.lat = 0;

        /**
         * Coordinates lng.
         * @member {number} lng
         * @memberof PlatformAdminApi.Coordinates
         * @instance
         */
        Coordinates.prototype.lng = 0;

        /**
         * Encodes the specified Coordinates message. Does not implicitly {@link PlatformAdminApi.Coordinates.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Coordinates
         * @static
         * @param {PlatformAdminApi.ICoordinates} message Coordinates message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Coordinates.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.lat);
            if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.lng);
            return writer;
        };

        /**
         * Decodes a Coordinates message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Coordinates
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Coordinates} Coordinates
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Coordinates.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Coordinates();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.lat = reader.double();
                    break;
                case 2:
                    message.lng = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Coordinates;
    })();

    PlatformAdminApi.Metro = (function() {

        /**
         * Properties of a Metro.
         * @memberof PlatformAdminApi
         * @interface IMetro
         * @property {number|null} [distance] Metro distance
         * @property {string|null} [line] Metro line
         * @property {string|null} [name] Metro name
         */

        /**
         * Constructs a new Metro.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Metro.
         * @implements IMetro
         * @constructor
         * @param {PlatformAdminApi.IMetro=} [properties] Properties to set
         */
        function Metro(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Metro distance.
         * @member {number} distance
         * @memberof PlatformAdminApi.Metro
         * @instance
         */
        Metro.prototype.distance = 0;

        /**
         * Metro line.
         * @member {string} line
         * @memberof PlatformAdminApi.Metro
         * @instance
         */
        Metro.prototype.line = "";

        /**
         * Metro name.
         * @member {string} name
         * @memberof PlatformAdminApi.Metro
         * @instance
         */
        Metro.prototype.name = "";

        /**
         * Encodes the specified Metro message. Does not implicitly {@link PlatformAdminApi.Metro.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Metro
         * @static
         * @param {PlatformAdminApi.IMetro} message Metro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metro.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.distance);
            if (message.line != null && Object.hasOwnProperty.call(message, "line"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.line);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a Metro message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Metro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Metro} Metro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metro.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Metro();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.distance = reader.double();
                    break;
                case 2:
                    message.line = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Metro;
    })();

    PlatformAdminApi.AddressDetail = (function() {

        /**
         * Properties of an AddressDetail.
         * @memberof PlatformAdminApi
         * @interface IAddressDetail
         * @property {string|null} [region] AddressDetail region
         * @property {string|null} [city] AddressDetail city
         * @property {string|null} [area] AddressDetail area
         * @property {string|null} [street] AddressDetail street
         * @property {string|null} [block] AddressDetail block
         * @property {string|null} [house] AddressDetail house
         * @property {string|null} [flat] AddressDetail flat
         * @property {string|null} [floor] AddressDetail floor
         * @property {string|null} [comment] AddressDetail comment
         * @property {string|null} [intercom] AddressDetail intercom
         * @property {string|null} [entrance] AddressDetail entrance
         * @property {string|null} [settlement] AddressDetail settlement
         */

        /**
         * Constructs a new AddressDetail.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AddressDetail.
         * @implements IAddressDetail
         * @constructor
         * @param {PlatformAdminApi.IAddressDetail=} [properties] Properties to set
         */
        function AddressDetail(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressDetail region.
         * @member {string} region
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.region = "";

        /**
         * AddressDetail city.
         * @member {string} city
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.city = "";

        /**
         * AddressDetail area.
         * @member {string} area
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.area = "";

        /**
         * AddressDetail street.
         * @member {string} street
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.street = "";

        /**
         * AddressDetail block.
         * @member {string} block
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.block = "";

        /**
         * AddressDetail house.
         * @member {string} house
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.house = "";

        /**
         * AddressDetail flat.
         * @member {string} flat
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.flat = "";

        /**
         * AddressDetail floor.
         * @member {string} floor
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.floor = "";

        /**
         * AddressDetail comment.
         * @member {string} comment
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.comment = "";

        /**
         * AddressDetail intercom.
         * @member {string} intercom
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.intercom = "";

        /**
         * AddressDetail entrance.
         * @member {string} entrance
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.entrance = "";

        /**
         * AddressDetail settlement.
         * @member {string} settlement
         * @memberof PlatformAdminApi.AddressDetail
         * @instance
         */
        AddressDetail.prototype.settlement = "";

        /**
         * Encodes the specified AddressDetail message. Does not implicitly {@link PlatformAdminApi.AddressDetail.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AddressDetail
         * @static
         * @param {PlatformAdminApi.IAddressDetail} message AddressDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.region);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
            if (message.area != null && Object.hasOwnProperty.call(message, "area"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.area);
            if (message.street != null && Object.hasOwnProperty.call(message, "street"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.street);
            if (message.block != null && Object.hasOwnProperty.call(message, "block"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.block);
            if (message.house != null && Object.hasOwnProperty.call(message, "house"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.house);
            if (message.flat != null && Object.hasOwnProperty.call(message, "flat"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.flat);
            if (message.floor != null && Object.hasOwnProperty.call(message, "floor"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.floor);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.comment);
            if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.intercom);
            if (message.entrance != null && Object.hasOwnProperty.call(message, "entrance"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.entrance);
            if (message.settlement != null && Object.hasOwnProperty.call(message, "settlement"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.settlement);
            return writer;
        };

        /**
         * Decodes an AddressDetail message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AddressDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AddressDetail} AddressDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AddressDetail();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.region = reader.string();
                    break;
                case 2:
                    message.city = reader.string();
                    break;
                case 3:
                    message.area = reader.string();
                    break;
                case 4:
                    message.street = reader.string();
                    break;
                case 5:
                    message.block = reader.string();
                    break;
                case 6:
                    message.house = reader.string();
                    break;
                case 7:
                    message.flat = reader.string();
                    break;
                case 8:
                    message.floor = reader.string();
                    break;
                case 9:
                    message.comment = reader.string();
                    break;
                case 10:
                    message.intercom = reader.string();
                    break;
                case 11:
                    message.entrance = reader.string();
                    break;
                case 12:
                    message.settlement = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressDetail;
    })();

    PlatformAdminApi.Address = (function() {

        /**
         * Properties of an Address.
         * @memberof PlatformAdminApi
         * @interface IAddress
         * @property {string|null} [id] Address id
         * @property {string|null} [label] Address label
         * @property {string|null} [value] Address value
         * @property {string|null} [unrestrictedValue] Address unrestrictedValue
         * @property {string|null} [object] Address object
         * @property {PlatformAdminApi.IAddressDetail|null} [detail] Address detail
         * @property {PlatformAdminApi.ICoordinates|null} [coordinates] Address coordinates
         * @property {Array.<PlatformAdminApi.IMetro>|null} [metro] Address metro
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] Address type
         * @property {string|null} [createdAt] Address createdAt
         * @property {string|null} [updatedAt] Address updatedAt
         * @property {boolean|null} [isDeleted] Address isDeleted
         * @property {string|null} [deletedAt] Address deletedAt
         */

        /**
         * Constructs a new Address.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Address.
         * @implements IAddress
         * @constructor
         * @param {PlatformAdminApi.IAddress=} [properties] Properties to set
         */
        function Address(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Address id.
         * @member {string} id
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.id = "";

        /**
         * Address label.
         * @member {string} label
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.label = "";

        /**
         * Address value.
         * @member {string} value
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.value = "";

        /**
         * Address unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.unrestrictedValue = "";

        /**
         * Address object.
         * @member {string} object
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.object = "";

        /**
         * Address detail.
         * @member {PlatformAdminApi.IAddressDetail|null|undefined} detail
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.detail = null;

        /**
         * Address coordinates.
         * @member {PlatformAdminApi.ICoordinates|null|undefined} coordinates
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.coordinates = null;

        /**
         * Address metro.
         * @member {Array.<PlatformAdminApi.IMetro>} metro
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.metro = $util.emptyArray;

        /**
         * Address type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.type = 0;

        /**
         * Address createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.createdAt = "";

        /**
         * Address updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.updatedAt = "";

        /**
         * Address isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.isDeleted = false;

        /**
         * Address deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Address
         * @instance
         */
        Address.prototype.deletedAt = "";

        /**
         * Encodes the specified Address message. Does not implicitly {@link PlatformAdminApi.Address.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Address
         * @static
         * @param {PlatformAdminApi.IAddress} message Address message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Address.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.PlatformAdminApi.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.PlatformAdminApi.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.PlatformAdminApi.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.type);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes an Address message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Address
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Address} Address
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Address.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Address();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.PlatformAdminApi.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.PlatformAdminApi.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.PlatformAdminApi.Metro.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.type = reader.int32();
                    break;
                case 10:
                    message.createdAt = reader.string();
                    break;
                case 11:
                    message.updatedAt = reader.string();
                    break;
                case 12:
                    message.isDeleted = reader.bool();
                    break;
                case 13:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Address;
    })();

    PlatformAdminApi.Contact = (function() {

        /**
         * Properties of a Contact.
         * @memberof PlatformAdminApi
         * @interface IContact
         * @property {string|null} [value] Contact value
         * @property {PlatformAdminApi.CONTACT_TYPE|null} [type] Contact type
         * @property {string|null} [id] Contact id
         * @property {string|null} [createdAt] Contact createdAt
         * @property {string|null} [updatedAt] Contact updatedAt
         * @property {boolean|null} [isDeleted] Contact isDeleted
         * @property {string|null} [deletedAt] Contact deletedAt
         * @property {boolean|null} [isVerified] Contact isVerified
         * @property {boolean|null} [isMain] Contact isMain
         */

        /**
         * Constructs a new Contact.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Contact.
         * @implements IContact
         * @constructor
         * @param {PlatformAdminApi.IContact=} [properties] Properties to set
         */
        function Contact(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Contact value.
         * @member {string} value
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.value = "";

        /**
         * Contact type.
         * @member {PlatformAdminApi.CONTACT_TYPE} type
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.type = 0;

        /**
         * Contact id.
         * @member {string} id
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.id = "";

        /**
         * Contact createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.createdAt = "";

        /**
         * Contact updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.updatedAt = "";

        /**
         * Contact isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.isDeleted = false;

        /**
         * Contact deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.deletedAt = "";

        /**
         * Contact isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.isVerified = false;

        /**
         * Contact isMain.
         * @member {boolean} isMain
         * @memberof PlatformAdminApi.Contact
         * @instance
         */
        Contact.prototype.isMain = false;

        /**
         * Encodes the specified Contact message. Does not implicitly {@link PlatformAdminApi.Contact.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Contact
         * @static
         * @param {PlatformAdminApi.IContact} message Contact message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Contact.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isVerified);
            if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isMain);
            return writer;
        };

        /**
         * Decodes a Contact message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Contact
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Contact} Contact
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Contact.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Contact();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                case 8:
                    message.isVerified = reader.bool();
                    break;
                case 9:
                    message.isMain = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Contact;
    })();

    PlatformAdminApi.CommonSearch = (function() {

        /**
         * Properties of a CommonSearch.
         * @memberof PlatformAdminApi
         * @interface ICommonSearch
         * @property {number|null} [page] CommonSearch page
         * @property {number|null} [limit] CommonSearch limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] CommonSearch sort
         * @property {string|null} [searchText] CommonSearch searchText
         */

        /**
         * Constructs a new CommonSearch.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CommonSearch.
         * @implements ICommonSearch
         * @constructor
         * @param {PlatformAdminApi.ICommonSearch=} [properties] Properties to set
         */
        function CommonSearch(properties) {
            this.sort = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommonSearch page.
         * @member {number} page
         * @memberof PlatformAdminApi.CommonSearch
         * @instance
         */
        CommonSearch.prototype.page = 0;

        /**
         * CommonSearch limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.CommonSearch
         * @instance
         */
        CommonSearch.prototype.limit = 0;

        /**
         * CommonSearch sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.CommonSearch
         * @instance
         */
        CommonSearch.prototype.sort = $util.emptyArray;

        /**
         * CommonSearch searchText.
         * @member {string} searchText
         * @memberof PlatformAdminApi.CommonSearch
         * @instance
         */
        CommonSearch.prototype.searchText = "";

        /**
         * Encodes the specified CommonSearch message. Does not implicitly {@link PlatformAdminApi.CommonSearch.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CommonSearch
         * @static
         * @param {PlatformAdminApi.ICommonSearch} message CommonSearch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommonSearch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.searchText != null && Object.hasOwnProperty.call(message, "searchText"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.searchText);
            return writer;
        };

        /**
         * Decodes a CommonSearch message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CommonSearch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CommonSearch} CommonSearch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommonSearch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CommonSearch();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.searchText = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CommonSearch;
    })();

    PlatformAdminApi.CovidCertificate = (function() {

        /**
         * Properties of a CovidCertificate.
         * @memberof PlatformAdminApi
         * @interface ICovidCertificate
         * @property {string|null} [id] CovidCertificate id
         * @property {string|null} [type] CovidCertificate type
         * @property {string|null} [link] CovidCertificate link
         * @property {string|null} [validUntil] CovidCertificate validUntil
         * @property {boolean|null} [isValid] CovidCertificate isValid
         */

        /**
         * Constructs a new CovidCertificate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CovidCertificate.
         * @implements ICovidCertificate
         * @constructor
         * @param {PlatformAdminApi.ICovidCertificate=} [properties] Properties to set
         */
        function CovidCertificate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CovidCertificate id.
         * @member {string} id
         * @memberof PlatformAdminApi.CovidCertificate
         * @instance
         */
        CovidCertificate.prototype.id = "";

        /**
         * CovidCertificate type.
         * @member {string} type
         * @memberof PlatformAdminApi.CovidCertificate
         * @instance
         */
        CovidCertificate.prototype.type = "";

        /**
         * CovidCertificate link.
         * @member {string} link
         * @memberof PlatformAdminApi.CovidCertificate
         * @instance
         */
        CovidCertificate.prototype.link = "";

        /**
         * CovidCertificate validUntil.
         * @member {string} validUntil
         * @memberof PlatformAdminApi.CovidCertificate
         * @instance
         */
        CovidCertificate.prototype.validUntil = "";

        /**
         * CovidCertificate isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.CovidCertificate
         * @instance
         */
        CovidCertificate.prototype.isValid = false;

        /**
         * Encodes the specified CovidCertificate message. Does not implicitly {@link PlatformAdminApi.CovidCertificate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CovidCertificate
         * @static
         * @param {PlatformAdminApi.ICovidCertificate} message CovidCertificate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CovidCertificate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.link);
            if (message.validUntil != null && Object.hasOwnProperty.call(message, "validUntil"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.validUntil);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isValid);
            return writer;
        };

        /**
         * Decodes a CovidCertificate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CovidCertificate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CovidCertificate} CovidCertificate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CovidCertificate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CovidCertificate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.link = reader.string();
                    break;
                case 4:
                    message.validUntil = reader.string();
                    break;
                case 5:
                    message.isValid = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CovidCertificate;
    })();

    PlatformAdminApi.SelectOption = (function() {

        /**
         * Properties of a SelectOption.
         * @memberof PlatformAdminApi
         * @interface ISelectOption
         * @property {string|null} [name] SelectOption name
         * @property {string|null} [label] SelectOption label
         */

        /**
         * Constructs a new SelectOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SelectOption.
         * @implements ISelectOption
         * @constructor
         * @param {PlatformAdminApi.ISelectOption=} [properties] Properties to set
         */
        function SelectOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SelectOption name.
         * @member {string} name
         * @memberof PlatformAdminApi.SelectOption
         * @instance
         */
        SelectOption.prototype.name = "";

        /**
         * SelectOption label.
         * @member {string} label
         * @memberof PlatformAdminApi.SelectOption
         * @instance
         */
        SelectOption.prototype.label = "";

        /**
         * Encodes the specified SelectOption message. Does not implicitly {@link PlatformAdminApi.SelectOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SelectOption
         * @static
         * @param {PlatformAdminApi.ISelectOption} message SelectOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            return writer;
        };

        /**
         * Decodes a SelectOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SelectOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SelectOption} SelectOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SelectOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SelectOption;
    })();

    PlatformAdminApi.AddressLink = (function() {

        /**
         * Properties of an AddressLink.
         * @memberof PlatformAdminApi
         * @interface IAddressLink
         * @property {string|null} [addressLink] AddressLink addressLink
         * @property {string|null} [addressString] AddressLink addressString
         */

        /**
         * Constructs a new AddressLink.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AddressLink.
         * @implements IAddressLink
         * @constructor
         * @param {PlatformAdminApi.IAddressLink=} [properties] Properties to set
         */
        function AddressLink(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressLink addressLink.
         * @member {string} addressLink
         * @memberof PlatformAdminApi.AddressLink
         * @instance
         */
        AddressLink.prototype.addressLink = "";

        /**
         * AddressLink addressString.
         * @member {string} addressString
         * @memberof PlatformAdminApi.AddressLink
         * @instance
         */
        AddressLink.prototype.addressString = "";

        /**
         * Encodes the specified AddressLink message. Does not implicitly {@link PlatformAdminApi.AddressLink.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AddressLink
         * @static
         * @param {PlatformAdminApi.IAddressLink} message AddressLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addressLink != null && Object.hasOwnProperty.call(message, "addressLink"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.addressLink);
            if (message.addressString != null && Object.hasOwnProperty.call(message, "addressString"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.addressString);
            return writer;
        };

        /**
         * Decodes an AddressLink message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AddressLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AddressLink} AddressLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AddressLink();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.addressLink = reader.string();
                    break;
                case 2:
                    message.addressString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressLink;
    })();

    PlatformAdminApi.SocialNetwork = (function() {

        /**
         * Properties of a SocialNetwork.
         * @memberof PlatformAdminApi
         * @interface ISocialNetwork
         * @property {string|null} [name] SocialNetwork name
         * @property {string|null} [url] SocialNetwork url
         */

        /**
         * Constructs a new SocialNetwork.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SocialNetwork.
         * @implements ISocialNetwork
         * @constructor
         * @param {PlatformAdminApi.ISocialNetwork=} [properties] Properties to set
         */
        function SocialNetwork(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SocialNetwork name.
         * @member {string} name
         * @memberof PlatformAdminApi.SocialNetwork
         * @instance
         */
        SocialNetwork.prototype.name = "";

        /**
         * SocialNetwork url.
         * @member {string} url
         * @memberof PlatformAdminApi.SocialNetwork
         * @instance
         */
        SocialNetwork.prototype.url = "";

        /**
         * Encodes the specified SocialNetwork message. Does not implicitly {@link PlatformAdminApi.SocialNetwork.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SocialNetwork
         * @static
         * @param {PlatformAdminApi.ISocialNetwork} message SocialNetwork message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SocialNetwork.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            return writer;
        };

        /**
         * Decodes a SocialNetwork message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SocialNetwork
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SocialNetwork} SocialNetwork
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SocialNetwork.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SocialNetwork();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SocialNetwork;
    })();

    /**
     * BLOCKING_REASON_SLUG enum.
     * @name PlatformAdminApi.BLOCKING_REASON_SLUG
     * @enum {number}
     * @property {number} UNDEFINED_BLOCKING_REASON_SLUG=0 UNDEFINED_BLOCKING_REASON_SLUG value
     * @property {number} REVOCATION_PERS_DATA=1 REVOCATION_PERS_DATA value
     * @property {number} END_COOPERATION=2 END_COOPERATION value
     */
    PlatformAdminApi.BLOCKING_REASON_SLUG = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_BLOCKING_REASON_SLUG"] = 0;
        values[valuesById[1] = "REVOCATION_PERS_DATA"] = 1;
        values[valuesById[2] = "END_COOPERATION"] = 2;
        return values;
    })();

    PlatformAdminApi.MetroStation = (function() {

        /**
         * Properties of a MetroStation.
         * @memberof PlatformAdminApi
         * @interface IMetroStation
         * @property {string|null} [color] MetroStation color
         * @property {string|null} [name] MetroStation name
         * @property {string|null} [distance] MetroStation distance
         */

        /**
         * Constructs a new MetroStation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a MetroStation.
         * @implements IMetroStation
         * @constructor
         * @param {PlatformAdminApi.IMetroStation=} [properties] Properties to set
         */
        function MetroStation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MetroStation color.
         * @member {string} color
         * @memberof PlatformAdminApi.MetroStation
         * @instance
         */
        MetroStation.prototype.color = "";

        /**
         * MetroStation name.
         * @member {string} name
         * @memberof PlatformAdminApi.MetroStation
         * @instance
         */
        MetroStation.prototype.name = "";

        /**
         * MetroStation distance.
         * @member {string} distance
         * @memberof PlatformAdminApi.MetroStation
         * @instance
         */
        MetroStation.prototype.distance = "";

        /**
         * Encodes the specified MetroStation message. Does not implicitly {@link PlatformAdminApi.MetroStation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.MetroStation
         * @static
         * @param {PlatformAdminApi.IMetroStation} message MetroStation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MetroStation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.color);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.distance);
            return writer;
        };

        /**
         * Decodes a MetroStation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.MetroStation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.MetroStation} MetroStation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MetroStation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.MetroStation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.color = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.distance = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return MetroStation;
    })();

    PlatformAdminApi.UserAddressRequest = (function() {

        /**
         * Properties of a UserAddressRequest.
         * @memberof PlatformAdminApi
         * @interface IUserAddressRequest
         * @property {string|null} [userId] UserAddressRequest userId
         * @property {string|null} [addressId] UserAddressRequest addressId
         */

        /**
         * Constructs a new UserAddressRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a UserAddressRequest.
         * @implements IUserAddressRequest
         * @constructor
         * @param {PlatformAdminApi.IUserAddressRequest=} [properties] Properties to set
         */
        function UserAddressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserAddressRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.UserAddressRequest
         * @instance
         */
        UserAddressRequest.prototype.userId = "";

        /**
         * UserAddressRequest addressId.
         * @member {string} addressId
         * @memberof PlatformAdminApi.UserAddressRequest
         * @instance
         */
        UserAddressRequest.prototype.addressId = "";

        /**
         * Encodes the specified UserAddressRequest message. Does not implicitly {@link PlatformAdminApi.UserAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UserAddressRequest
         * @static
         * @param {PlatformAdminApi.IUserAddressRequest} message UserAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.addressId != null && Object.hasOwnProperty.call(message, "addressId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.addressId);
            return writer;
        };

        /**
         * Decodes a UserAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UserAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UserAddressRequest} UserAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UserAddressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.addressId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UserAddressRequest;
    })();

    PlatformAdminApi.UserBlockingReason = (function() {

        /**
         * Properties of a UserBlockingReason.
         * @memberof PlatformAdminApi
         * @interface IUserBlockingReason
         * @property {string|null} [label] UserBlockingReason label
         * @property {PlatformAdminApi.BLOCKING_REASON_SLUG|null} [value] UserBlockingReason value
         */

        /**
         * Constructs a new UserBlockingReason.
         * @memberof PlatformAdminApi
         * @classdesc Represents a UserBlockingReason.
         * @implements IUserBlockingReason
         * @constructor
         * @param {PlatformAdminApi.IUserBlockingReason=} [properties] Properties to set
         */
        function UserBlockingReason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserBlockingReason label.
         * @member {string} label
         * @memberof PlatformAdminApi.UserBlockingReason
         * @instance
         */
        UserBlockingReason.prototype.label = "";

        /**
         * UserBlockingReason value.
         * @member {PlatformAdminApi.BLOCKING_REASON_SLUG} value
         * @memberof PlatformAdminApi.UserBlockingReason
         * @instance
         */
        UserBlockingReason.prototype.value = 0;

        /**
         * Encodes the specified UserBlockingReason message. Does not implicitly {@link PlatformAdminApi.UserBlockingReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UserBlockingReason
         * @static
         * @param {PlatformAdminApi.IUserBlockingReason} message UserBlockingReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserBlockingReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            return writer;
        };

        /**
         * Decodes a UserBlockingReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UserBlockingReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UserBlockingReason} UserBlockingReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserBlockingReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UserBlockingReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UserBlockingReason;
    })();

    PlatformAdminApi.ProfileDadataFIO = (function() {

        /**
         * Properties of a ProfileDadataFIO.
         * @memberof PlatformAdminApi
         * @interface IProfileDadataFIO
         * @property {string|null} [value] ProfileDadataFIO value
         * @property {string|null} [unrestrictedValue] ProfileDadataFIO unrestrictedValue
         * @property {PlatformAdminApi.ProfileDadataFIO.IData|null} [data] ProfileDadataFIO data
         */

        /**
         * Constructs a new ProfileDadataFIO.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileDadataFIO.
         * @implements IProfileDadataFIO
         * @constructor
         * @param {PlatformAdminApi.IProfileDadataFIO=} [properties] Properties to set
         */
        function ProfileDadataFIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileDadataFIO value.
         * @member {string} value
         * @memberof PlatformAdminApi.ProfileDadataFIO
         * @instance
         */
        ProfileDadataFIO.prototype.value = "";

        /**
         * ProfileDadataFIO unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof PlatformAdminApi.ProfileDadataFIO
         * @instance
         */
        ProfileDadataFIO.prototype.unrestrictedValue = "";

        /**
         * ProfileDadataFIO data.
         * @member {PlatformAdminApi.ProfileDadataFIO.IData|null|undefined} data
         * @memberof PlatformAdminApi.ProfileDadataFIO
         * @instance
         */
        ProfileDadataFIO.prototype.data = null;

        /**
         * Encodes the specified ProfileDadataFIO message. Does not implicitly {@link PlatformAdminApi.ProfileDadataFIO.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileDadataFIO
         * @static
         * @param {PlatformAdminApi.IProfileDadataFIO} message ProfileDadataFIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileDadataFIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.unrestrictedValue);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.ProfileDadataFIO.Data.encode(message.data, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfileDadataFIO message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileDadataFIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileDadataFIO} ProfileDadataFIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileDadataFIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileDadataFIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                case 2:
                    message.unrestrictedValue = reader.string();
                    break;
                case 3:
                    message.data = $root.PlatformAdminApi.ProfileDadataFIO.Data.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ProfileDadataFIO.Data = (function() {

            /**
             * Properties of a Data.
             * @memberof PlatformAdminApi.ProfileDadataFIO
             * @interface IData
             * @property {string|null} [surname] Data surname
             * @property {string|null} [name] Data name
             * @property {string|null} [patronymic] Data patronymic
             * @property {string|null} [gender] Data gender
             * @property {string|null} [source] Data source
             * @property {string|null} [qc] Data qc
             */

            /**
             * Constructs a new Data.
             * @memberof PlatformAdminApi.ProfileDadataFIO
             * @classdesc Represents a Data.
             * @implements IData
             * @constructor
             * @param {PlatformAdminApi.ProfileDadataFIO.IData=} [properties] Properties to set
             */
            function Data(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Data surname.
             * @member {string} surname
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @instance
             */
            Data.prototype.surname = "";

            /**
             * Data name.
             * @member {string} name
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @instance
             */
            Data.prototype.name = "";

            /**
             * Data patronymic.
             * @member {string} patronymic
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @instance
             */
            Data.prototype.patronymic = "";

            /**
             * Data gender.
             * @member {string} gender
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @instance
             */
            Data.prototype.gender = "";

            /**
             * Data source.
             * @member {string} source
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @instance
             */
            Data.prototype.source = "";

            /**
             * Data qc.
             * @member {string} qc
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @instance
             */
            Data.prototype.qc = "";

            /**
             * Encodes the specified Data message. Does not implicitly {@link PlatformAdminApi.ProfileDadataFIO.Data.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @static
             * @param {PlatformAdminApi.ProfileDadataFIO.IData} message Data message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Data.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.surname);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.patronymic);
                if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.gender);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.source);
                if (message.qc != null && Object.hasOwnProperty.call(message, "qc"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.qc);
                return writer;
            };

            /**
             * Decodes a Data message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ProfileDadataFIO.Data
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ProfileDadataFIO.Data} Data
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Data.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileDadataFIO.Data();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.surname = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.patronymic = reader.string();
                        break;
                    case 4:
                        message.gender = reader.string();
                        break;
                    case 5:
                        message.source = reader.string();
                        break;
                    case 6:
                        message.qc = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Data;
        })();

        return ProfileDadataFIO;
    })();

    PlatformAdminApi.UserContactRequest = (function() {

        /**
         * Properties of a UserContactRequest.
         * @memberof PlatformAdminApi
         * @interface IUserContactRequest
         * @property {string|null} [userId] UserContactRequest userId
         * @property {string|null} [contactId] UserContactRequest contactId
         */

        /**
         * Constructs a new UserContactRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a UserContactRequest.
         * @implements IUserContactRequest
         * @constructor
         * @param {PlatformAdminApi.IUserContactRequest=} [properties] Properties to set
         */
        function UserContactRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserContactRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.UserContactRequest
         * @instance
         */
        UserContactRequest.prototype.userId = "";

        /**
         * UserContactRequest contactId.
         * @member {string} contactId
         * @memberof PlatformAdminApi.UserContactRequest
         * @instance
         */
        UserContactRequest.prototype.contactId = "";

        /**
         * Encodes the specified UserContactRequest message. Does not implicitly {@link PlatformAdminApi.UserContactRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UserContactRequest
         * @static
         * @param {PlatformAdminApi.IUserContactRequest} message UserContactRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserContactRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.contactId != null && Object.hasOwnProperty.call(message, "contactId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contactId);
            return writer;
        };

        /**
         * Decodes a UserContactRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UserContactRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UserContactRequest} UserContactRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserContactRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UserContactRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.contactId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UserContactRequest;
    })();

    /**
     * PAYMENT_TYPE enum.
     * @name PlatformAdminApi.PAYMENT_TYPE
     * @enum {number}
     * @property {number} UNKNOWN_PAYMENT_TYPE=0 UNKNOWN_PAYMENT_TYPE value
     * @property {number} CLIENT_CARD=1 CLIENT_CARD value
     * @property {number} CLIENT_CASH=2 CLIENT_CASH value
     * @property {number} CLIENT_BONUS=3 CLIENT_BONUS value
     */
    PlatformAdminApi.PAYMENT_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_PAYMENT_TYPE"] = 0;
        values[valuesById[1] = "CLIENT_CARD"] = 1;
        values[valuesById[2] = "CLIENT_CASH"] = 2;
        values[valuesById[3] = "CLIENT_BONUS"] = 3;
        return values;
    })();

    /**
     * ORDER_ASSIGNMENT_STATUS enum.
     * @name PlatformAdminApi.ORDER_ASSIGNMENT_STATUS
     * @enum {number}
     * @property {number} UNKNOWN_ORDER_ASSIGNMENT_STATUS=0 UNKNOWN_ORDER_ASSIGNMENT_STATUS value
     * @property {number} WAITING=1 WAITING value
     * @property {number} CONFIRMED=2 CONFIRMED value
     * @property {number} REJECTED=3 REJECTED value
     */
    PlatformAdminApi.ORDER_ASSIGNMENT_STATUS = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ORDER_ASSIGNMENT_STATUS"] = 0;
        values[valuesById[1] = "WAITING"] = 1;
        values[valuesById[2] = "CONFIRMED"] = 2;
        values[valuesById[3] = "REJECTED"] = 3;
        return values;
    })();

    /**
     * APP_TYPE enum.
     * @name PlatformAdminApi.APP_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_APP_TYPE=0 UNDEFINED_APP_TYPE value
     * @property {number} NATIVE=1 NATIVE value
     * @property {number} SPA=2 SPA value
     * @property {number} REGULAR=3 REGULAR value
     * @property {number} MACHINE=4 MACHINE value
     */
    PlatformAdminApi.APP_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_APP_TYPE"] = 0;
        values[valuesById[1] = "NATIVE"] = 1;
        values[valuesById[2] = "SPA"] = 2;
        values[valuesById[3] = "REGULAR"] = 3;
        values[valuesById[4] = "MACHINE"] = 4;
        return values;
    })();

    /**
     * SEND enum.
     * @name PlatformAdminApi.SEND
     * @enum {number}
     * @property {number} UNDEFINED_SEND=0 UNDEFINED_SEND value
     * @property {number} CODE=1 CODE value
     */
    PlatformAdminApi.SEND = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_SEND"] = 0;
        values[valuesById[1] = "CODE"] = 1;
        return values;
    })();

    /**
     * USER_TYPE enum.
     * @name PlatformAdminApi.USER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_USER_TYPE=0 UNDEFINED_USER_TYPE value
     * @property {number} CUSTOMER=1 CUSTOMER value
     * @property {number} CONTRACTOR=2 CONTRACTOR value
     * @property {number} EMPLOYEE=3 EMPLOYEE value
     * @property {number} PARTNER=4 PARTNER value
     */
    PlatformAdminApi.USER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_USER_TYPE"] = 0;
        values[valuesById[1] = "CUSTOMER"] = 1;
        values[valuesById[2] = "CONTRACTOR"] = 2;
        values[valuesById[3] = "EMPLOYEE"] = 3;
        values[valuesById[4] = "PARTNER"] = 4;
        return values;
    })();

    /**
     * CONNECTION enum.
     * @name PlatformAdminApi.CONNECTION
     * @enum {number}
     * @property {number} UNDEFINED_CONNECTION=0 UNDEFINED_CONNECTION value
     * @property {number} CREDENTIAL=1 CREDENTIAL value
     * @property {number} M2M=2 M2M value
     * @property {number} PASSWORDLESS=3 PASSWORDLESS value
     * @property {number} YCLIENTS=4 YCLIENTS value
     */
    PlatformAdminApi.CONNECTION = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONNECTION"] = 0;
        values[valuesById[1] = "CREDENTIAL"] = 1;
        values[valuesById[2] = "M2M"] = 2;
        values[valuesById[3] = "PASSWORDLESS"] = 3;
        values[valuesById[4] = "YCLIENTS"] = 4;
        return values;
    })();

    /**
     * CONTACT_TYPE enum.
     * @name PlatformAdminApi.CONTACT_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_CONTACT_TYPE=0 UNDEFINED_CONTACT_TYPE value
     * @property {number} MOBILE=1 MOBILE value
     * @property {number} EMAIL=2 EMAIL value
     * @property {number} TELEGRAM=3 TELEGRAM value
     * @property {number} WHATSAPP=4 WHATSAPP value
     * @property {number} FACEBOOK=5 FACEBOOK value
     * @property {number} VK=6 VK value
     * @property {number} OK=7 OK value
     * @property {number} INSTAGRAM=8 INSTAGRAM value
     * @property {number} YOUTUBE=9 YOUTUBE value
     * @property {number} TWITTER=10 TWITTER value
     * @property {number} MY_MAIL=11 MY_MAIL value
     * @property {number} LINKED_IN=12 LINKED_IN value
     */
    PlatformAdminApi.CONTACT_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONTACT_TYPE"] = 0;
        values[valuesById[1] = "MOBILE"] = 1;
        values[valuesById[2] = "EMAIL"] = 2;
        values[valuesById[3] = "TELEGRAM"] = 3;
        values[valuesById[4] = "WHATSAPP"] = 4;
        values[valuesById[5] = "FACEBOOK"] = 5;
        values[valuesById[6] = "VK"] = 6;
        values[valuesById[7] = "OK"] = 7;
        values[valuesById[8] = "INSTAGRAM"] = 8;
        values[valuesById[9] = "YOUTUBE"] = 9;
        values[valuesById[10] = "TWITTER"] = 10;
        values[valuesById[11] = "MY_MAIL"] = 11;
        values[valuesById[12] = "LINKED_IN"] = 12;
        return values;
    })();

    /**
     * ADDRESS_TYPE enum.
     * @name PlatformAdminApi.ADDRESS_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_ADDRESS_TYPE=0 UNDEFINED_ADDRESS_TYPE value
     * @property {number} DEFAULT=1 DEFAULT value
     * @property {number} BIRTH_ADDRESS=2 BIRTH_ADDRESS value
     * @property {number} REG_ADDRESS=3 REG_ADDRESS value
     * @property {number} RESIDENCE_ADDRESS=4 RESIDENCE_ADDRESS value
     * @property {number} PICKUP_POINT=5 PICKUP_POINT value
     */
    PlatformAdminApi.ADDRESS_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_ADDRESS_TYPE"] = 0;
        values[valuesById[1] = "DEFAULT"] = 1;
        values[valuesById[2] = "BIRTH_ADDRESS"] = 2;
        values[valuesById[3] = "REG_ADDRESS"] = 3;
        values[valuesById[4] = "RESIDENCE_ADDRESS"] = 4;
        values[valuesById[5] = "PICKUP_POINT"] = 5;
        return values;
    })();

    /**
     * GENDER_TYPE enum.
     * @name PlatformAdminApi.GENDER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_GENDER_TYPE=0 UNDEFINED_GENDER_TYPE value
     * @property {number} MALE=1 MALE value
     * @property {number} FEMALE=2 FEMALE value
     */
    PlatformAdminApi.GENDER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_GENDER_TYPE"] = 0;
        values[valuesById[1] = "MALE"] = 1;
        values[valuesById[2] = "FEMALE"] = 2;
        return values;
    })();

    PlatformAdminApi.DadataFioObject = (function() {

        /**
         * Properties of a DadataFioObject.
         * @memberof PlatformAdminApi
         * @interface IDadataFioObject
         * @property {string|null} [value] DadataFioObject value
         * @property {string|null} [unrestrictedValue] DadataFioObject unrestrictedValue
         * @property {PlatformAdminApi.DadataFioObject.IData|null} [data] DadataFioObject data
         */

        /**
         * Constructs a new DadataFioObject.
         * @memberof PlatformAdminApi
         * @classdesc Represents a DadataFioObject.
         * @implements IDadataFioObject
         * @constructor
         * @param {PlatformAdminApi.IDadataFioObject=} [properties] Properties to set
         */
        function DadataFioObject(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DadataFioObject value.
         * @member {string} value
         * @memberof PlatformAdminApi.DadataFioObject
         * @instance
         */
        DadataFioObject.prototype.value = "";

        /**
         * DadataFioObject unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof PlatformAdminApi.DadataFioObject
         * @instance
         */
        DadataFioObject.prototype.unrestrictedValue = "";

        /**
         * DadataFioObject data.
         * @member {PlatformAdminApi.DadataFioObject.IData|null|undefined} data
         * @memberof PlatformAdminApi.DadataFioObject
         * @instance
         */
        DadataFioObject.prototype.data = null;

        /**
         * Encodes the specified DadataFioObject message. Does not implicitly {@link PlatformAdminApi.DadataFioObject.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.DadataFioObject
         * @static
         * @param {PlatformAdminApi.IDadataFioObject} message DadataFioObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DadataFioObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.unrestrictedValue);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.DadataFioObject.Data.encode(message.data, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DadataFioObject message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.DadataFioObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.DadataFioObject} DadataFioObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DadataFioObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.DadataFioObject();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                case 2:
                    message.unrestrictedValue = reader.string();
                    break;
                case 3:
                    message.data = $root.PlatformAdminApi.DadataFioObject.Data.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DadataFioObject.Data = (function() {

            /**
             * Properties of a Data.
             * @memberof PlatformAdminApi.DadataFioObject
             * @interface IData
             * @property {string|null} [surname] Data surname
             * @property {string|null} [name] Data name
             * @property {string|null} [patronymic] Data patronymic
             * @property {string|null} [gender] Data gender
             * @property {string|null} [source] Data source
             * @property {string|null} [qc] Data qc
             */

            /**
             * Constructs a new Data.
             * @memberof PlatformAdminApi.DadataFioObject
             * @classdesc Represents a Data.
             * @implements IData
             * @constructor
             * @param {PlatformAdminApi.DadataFioObject.IData=} [properties] Properties to set
             */
            function Data(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Data surname.
             * @member {string} surname
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @instance
             */
            Data.prototype.surname = "";

            /**
             * Data name.
             * @member {string} name
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @instance
             */
            Data.prototype.name = "";

            /**
             * Data patronymic.
             * @member {string} patronymic
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @instance
             */
            Data.prototype.patronymic = "";

            /**
             * Data gender.
             * @member {string} gender
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @instance
             */
            Data.prototype.gender = "";

            /**
             * Data source.
             * @member {string} source
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @instance
             */
            Data.prototype.source = "";

            /**
             * Data qc.
             * @member {string} qc
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @instance
             */
            Data.prototype.qc = "";

            /**
             * Encodes the specified Data message. Does not implicitly {@link PlatformAdminApi.DadataFioObject.Data.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @static
             * @param {PlatformAdminApi.DadataFioObject.IData} message Data message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Data.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.surname);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.patronymic);
                if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.gender);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.source);
                if (message.qc != null && Object.hasOwnProperty.call(message, "qc"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.qc);
                return writer;
            };

            /**
             * Decodes a Data message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.DadataFioObject.Data
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.DadataFioObject.Data} Data
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Data.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.DadataFioObject.Data();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.surname = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.patronymic = reader.string();
                        break;
                    case 4:
                        message.gender = reader.string();
                        break;
                    case 5:
                        message.source = reader.string();
                        break;
                    case 6:
                        message.qc = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Data;
        })();

        return DadataFioObject;
    })();

    PlatformAdminApi.AuthorizeService = (function() {

        /**
         * Constructs a new AuthorizeService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AuthorizeService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function AuthorizeService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (AuthorizeService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AuthorizeService;

        /**
         * Callback as used by {@link PlatformAdminApi.AuthorizeService#sendEmailAndPass}.
         * @memberof PlatformAdminApi.AuthorizeService
         * @typedef SendEmailAndPassCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls SendEmailAndPass.
         * @function sendEmailAndPass
         * @memberof PlatformAdminApi.AuthorizeService
         * @instance
         * @param {PlatformAdminApi.ISendEmailAndPassRequest} request SendEmailAndPassRequest message or plain object
         * @param {PlatformAdminApi.AuthorizeService.SendEmailAndPassCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthorizeService.prototype.sendEmailAndPass = function sendEmailAndPass(request, callback) {
            return this.rpcCall(sendEmailAndPass, $root.PlatformAdminApi.SendEmailAndPassRequest, $root.PlatformAdminApi.TokenResponse, request, callback);
        }, "name", { value: "SendEmailAndPass" });

        /**
         * Calls SendEmailAndPass.
         * @function sendEmailAndPass
         * @memberof PlatformAdminApi.AuthorizeService
         * @instance
         * @param {PlatformAdminApi.ISendEmailAndPassRequest} request SendEmailAndPassRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TokenResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.AuthorizeService#refreshToken}.
         * @memberof PlatformAdminApi.AuthorizeService
         * @typedef RefreshTokenCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof PlatformAdminApi.AuthorizeService
         * @instance
         * @param {PlatformAdminApi.IRefreshTokenRequest} request RefreshTokenRequest message or plain object
         * @param {PlatformAdminApi.AuthorizeService.RefreshTokenCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthorizeService.prototype.refreshToken = function refreshToken(request, callback) {
            return this.rpcCall(refreshToken, $root.PlatformAdminApi.RefreshTokenRequest, $root.PlatformAdminApi.TokenResponse, request, callback);
        }, "name", { value: "RefreshToken" });

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof PlatformAdminApi.AuthorizeService
         * @instance
         * @param {PlatformAdminApi.IRefreshTokenRequest} request RefreshTokenRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TokenResponse>} Promise
         * @variation 2
         */

        return AuthorizeService;
    })();

    PlatformAdminApi.SendEmailAndPassRequest = (function() {

        /**
         * Properties of a SendEmailAndPassRequest.
         * @memberof PlatformAdminApi
         * @interface ISendEmailAndPassRequest
         * @property {string|null} [email] SendEmailAndPassRequest email
         * @property {string|null} [password] SendEmailAndPassRequest password
         */

        /**
         * Constructs a new SendEmailAndPassRequest.
         * @memberof PlatformAdminApi
         * @classdesc request
         * @implements ISendEmailAndPassRequest
         * @constructor
         * @param {PlatformAdminApi.ISendEmailAndPassRequest=} [properties] Properties to set
         */
        function SendEmailAndPassRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendEmailAndPassRequest email.
         * @member {string} email
         * @memberof PlatformAdminApi.SendEmailAndPassRequest
         * @instance
         */
        SendEmailAndPassRequest.prototype.email = "";

        /**
         * SendEmailAndPassRequest password.
         * @member {string} password
         * @memberof PlatformAdminApi.SendEmailAndPassRequest
         * @instance
         */
        SendEmailAndPassRequest.prototype.password = "";

        /**
         * Encodes the specified SendEmailAndPassRequest message. Does not implicitly {@link PlatformAdminApi.SendEmailAndPassRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SendEmailAndPassRequest
         * @static
         * @param {PlatformAdminApi.ISendEmailAndPassRequest} message SendEmailAndPassRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendEmailAndPassRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Decodes a SendEmailAndPassRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SendEmailAndPassRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SendEmailAndPassRequest} SendEmailAndPassRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendEmailAndPassRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SendEmailAndPassRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.email = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendEmailAndPassRequest;
    })();

    PlatformAdminApi.RefreshTokenRequest = (function() {

        /**
         * Properties of a RefreshTokenRequest.
         * @memberof PlatformAdminApi
         * @interface IRefreshTokenRequest
         * @property {string|null} [refreshToken] RefreshTokenRequest refreshToken
         */

        /**
         * Constructs a new RefreshTokenRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RefreshTokenRequest.
         * @implements IRefreshTokenRequest
         * @constructor
         * @param {PlatformAdminApi.IRefreshTokenRequest=} [properties] Properties to set
         */
        function RefreshTokenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RefreshTokenRequest refreshToken.
         * @member {string} refreshToken
         * @memberof PlatformAdminApi.RefreshTokenRequest
         * @instance
         */
        RefreshTokenRequest.prototype.refreshToken = "";

        /**
         * Encodes the specified RefreshTokenRequest message. Does not implicitly {@link PlatformAdminApi.RefreshTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RefreshTokenRequest
         * @static
         * @param {PlatformAdminApi.IRefreshTokenRequest} message RefreshTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.refreshToken);
            return writer;
        };

        /**
         * Decodes a RefreshTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RefreshTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RefreshTokenRequest} RefreshTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RefreshTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RefreshTokenRequest;
    })();

    PlatformAdminApi.TokenResponse = (function() {

        /**
         * Properties of a TokenResponse.
         * @memberof PlatformAdminApi
         * @interface ITokenResponse
         * @property {string|null} [accessToken] TokenResponse accessToken
         * @property {string|null} [refreshToken] TokenResponse refreshToken
         * @property {string|null} [expiresIn] TokenResponse expiresIn
         */

        /**
         * Constructs a new TokenResponse.
         * @memberof PlatformAdminApi
         * @classdesc response
         * @implements ITokenResponse
         * @constructor
         * @param {PlatformAdminApi.ITokenResponse=} [properties] Properties to set
         */
        function TokenResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TokenResponse accessToken.
         * @member {string} accessToken
         * @memberof PlatformAdminApi.TokenResponse
         * @instance
         */
        TokenResponse.prototype.accessToken = "";

        /**
         * TokenResponse refreshToken.
         * @member {string} refreshToken
         * @memberof PlatformAdminApi.TokenResponse
         * @instance
         */
        TokenResponse.prototype.refreshToken = "";

        /**
         * TokenResponse expiresIn.
         * @member {string} expiresIn
         * @memberof PlatformAdminApi.TokenResponse
         * @instance
         */
        TokenResponse.prototype.expiresIn = "";

        /**
         * Encodes the specified TokenResponse message. Does not implicitly {@link PlatformAdminApi.TokenResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TokenResponse
         * @static
         * @param {PlatformAdminApi.ITokenResponse} message TokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessToken);
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.refreshToken);
            if (message.expiresIn != null && Object.hasOwnProperty.call(message, "expiresIn"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.expiresIn);
            return writer;
        };

        /**
         * Decodes a TokenResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TokenResponse} TokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TokenResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.accessToken = reader.string();
                    break;
                case 2:
                    message.refreshToken = reader.string();
                    break;
                case 3:
                    message.expiresIn = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TokenResponse;
    })();

    PlatformAdminApi.GalleryService = (function() {

        /**
         * Constructs a new GalleryService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GalleryService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function GalleryService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (GalleryService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GalleryService;

        /**
         * Callback as used by {@link PlatformAdminApi.GalleryService#singleUpload}.
         * @memberof PlatformAdminApi.GalleryService
         * @typedef SingleUploadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GallerySingleUploadResponse} [response] GallerySingleUploadResponse
         */

        /**
         * Calls SingleUpload.
         * @function singleUpload
         * @memberof PlatformAdminApi.GalleryService
         * @instance
         * @param {PlatformAdminApi.IGallerySingleUploadRequest} request GallerySingleUploadRequest message or plain object
         * @param {PlatformAdminApi.GalleryService.SingleUploadCallback} callback Node-style callback called with the error, if any, and GallerySingleUploadResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(GalleryService.prototype.singleUpload = function singleUpload(request, callback) {
            return this.rpcCall(singleUpload, $root.PlatformAdminApi.GallerySingleUploadRequest, $root.PlatformAdminApi.GallerySingleUploadResponse, request, callback);
        }, "name", { value: "SingleUpload" });

        /**
         * Calls SingleUpload.
         * @function singleUpload
         * @memberof PlatformAdminApi.GalleryService
         * @instance
         * @param {PlatformAdminApi.IGallerySingleUploadRequest} request GallerySingleUploadRequest message or plain object
         * @returns {Promise<PlatformAdminApi.GallerySingleUploadResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.GalleryService#multiUpload}.
         * @memberof PlatformAdminApi.GalleryService
         * @typedef MultiUploadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GalleryMultiUploadResponse} [response] GalleryMultiUploadResponse
         */

        /**
         * Calls MultiUpload.
         * @function multiUpload
         * @memberof PlatformAdminApi.GalleryService
         * @instance
         * @param {PlatformAdminApi.IGalleryMultiUploadRequest} request GalleryMultiUploadRequest message or plain object
         * @param {PlatformAdminApi.GalleryService.MultiUploadCallback} callback Node-style callback called with the error, if any, and GalleryMultiUploadResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(GalleryService.prototype.multiUpload = function multiUpload(request, callback) {
            return this.rpcCall(multiUpload, $root.PlatformAdminApi.GalleryMultiUploadRequest, $root.PlatformAdminApi.GalleryMultiUploadResponse, request, callback);
        }, "name", { value: "MultiUpload" });

        /**
         * Calls MultiUpload.
         * @function multiUpload
         * @memberof PlatformAdminApi.GalleryService
         * @instance
         * @param {PlatformAdminApi.IGalleryMultiUploadRequest} request GalleryMultiUploadRequest message or plain object
         * @returns {Promise<PlatformAdminApi.GalleryMultiUploadResponse>} Promise
         * @variation 2
         */

        return GalleryService;
    })();

    PlatformAdminApi.GallerySingleUploadResponse = (function() {

        /**
         * Properties of a GallerySingleUploadResponse.
         * @memberof PlatformAdminApi
         * @interface IGallerySingleUploadResponse
         * @property {string|null} [src] GallerySingleUploadResponse src
         */

        /**
         * Constructs a new GallerySingleUploadResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GallerySingleUploadResponse.
         * @implements IGallerySingleUploadResponse
         * @constructor
         * @param {PlatformAdminApi.IGallerySingleUploadResponse=} [properties] Properties to set
         */
        function GallerySingleUploadResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GallerySingleUploadResponse src.
         * @member {string} src
         * @memberof PlatformAdminApi.GallerySingleUploadResponse
         * @instance
         */
        GallerySingleUploadResponse.prototype.src = "";

        /**
         * Encodes the specified GallerySingleUploadResponse message. Does not implicitly {@link PlatformAdminApi.GallerySingleUploadResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GallerySingleUploadResponse
         * @static
         * @param {PlatformAdminApi.IGallerySingleUploadResponse} message GallerySingleUploadResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GallerySingleUploadResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.src);
            return writer;
        };

        /**
         * Decodes a GallerySingleUploadResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GallerySingleUploadResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GallerySingleUploadResponse} GallerySingleUploadResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GallerySingleUploadResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GallerySingleUploadResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.src = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GallerySingleUploadResponse;
    })();

    PlatformAdminApi.GallerySingleUploadRequest = (function() {

        /**
         * Properties of a GallerySingleUploadRequest.
         * @memberof PlatformAdminApi
         * @interface IGallerySingleUploadRequest
         * @property {string|null} [fileName] GallerySingleUploadRequest fileName
         * @property {Uint8Array|null} [data] GallerySingleUploadRequest data
         */

        /**
         * Constructs a new GallerySingleUploadRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GallerySingleUploadRequest.
         * @implements IGallerySingleUploadRequest
         * @constructor
         * @param {PlatformAdminApi.IGallerySingleUploadRequest=} [properties] Properties to set
         */
        function GallerySingleUploadRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GallerySingleUploadRequest fileName.
         * @member {string} fileName
         * @memberof PlatformAdminApi.GallerySingleUploadRequest
         * @instance
         */
        GallerySingleUploadRequest.prototype.fileName = "";

        /**
         * GallerySingleUploadRequest data.
         * @member {Uint8Array} data
         * @memberof PlatformAdminApi.GallerySingleUploadRequest
         * @instance
         */
        GallerySingleUploadRequest.prototype.data = $util.newBuffer([]);

        /**
         * Encodes the specified GallerySingleUploadRequest message. Does not implicitly {@link PlatformAdminApi.GallerySingleUploadRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GallerySingleUploadRequest
         * @static
         * @param {PlatformAdminApi.IGallerySingleUploadRequest} message GallerySingleUploadRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GallerySingleUploadRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileName);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data);
            return writer;
        };

        /**
         * Decodes a GallerySingleUploadRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GallerySingleUploadRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GallerySingleUploadRequest} GallerySingleUploadRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GallerySingleUploadRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GallerySingleUploadRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileName = reader.string();
                    break;
                case 2:
                    message.data = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GallerySingleUploadRequest;
    })();

    PlatformAdminApi.GalleryMultiUploadRequest = (function() {

        /**
         * Properties of a GalleryMultiUploadRequest.
         * @memberof PlatformAdminApi
         * @interface IGalleryMultiUploadRequest
         * @property {Array.<PlatformAdminApi.IGallerySingleUploadRequest>|null} [args] GalleryMultiUploadRequest args
         */

        /**
         * Constructs a new GalleryMultiUploadRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GalleryMultiUploadRequest.
         * @implements IGalleryMultiUploadRequest
         * @constructor
         * @param {PlatformAdminApi.IGalleryMultiUploadRequest=} [properties] Properties to set
         */
        function GalleryMultiUploadRequest(properties) {
            this.args = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GalleryMultiUploadRequest args.
         * @member {Array.<PlatformAdminApi.IGallerySingleUploadRequest>} args
         * @memberof PlatformAdminApi.GalleryMultiUploadRequest
         * @instance
         */
        GalleryMultiUploadRequest.prototype.args = $util.emptyArray;

        /**
         * Encodes the specified GalleryMultiUploadRequest message. Does not implicitly {@link PlatformAdminApi.GalleryMultiUploadRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GalleryMultiUploadRequest
         * @static
         * @param {PlatformAdminApi.IGalleryMultiUploadRequest} message GalleryMultiUploadRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GalleryMultiUploadRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.args != null && message.args.length)
                for (var i = 0; i < message.args.length; ++i)
                    $root.PlatformAdminApi.GallerySingleUploadRequest.encode(message.args[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GalleryMultiUploadRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GalleryMultiUploadRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GalleryMultiUploadRequest} GalleryMultiUploadRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GalleryMultiUploadRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GalleryMultiUploadRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.args && message.args.length))
                        message.args = [];
                    message.args.push($root.PlatformAdminApi.GallerySingleUploadRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GalleryMultiUploadRequest;
    })();

    PlatformAdminApi.GalleryMultiUploadResponse = (function() {

        /**
         * Properties of a GalleryMultiUploadResponse.
         * @memberof PlatformAdminApi
         * @interface IGalleryMultiUploadResponse
         * @property {Array.<PlatformAdminApi.IGalleryListDataResponse>|null} [src] GalleryMultiUploadResponse src
         */

        /**
         * Constructs a new GalleryMultiUploadResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GalleryMultiUploadResponse.
         * @implements IGalleryMultiUploadResponse
         * @constructor
         * @param {PlatformAdminApi.IGalleryMultiUploadResponse=} [properties] Properties to set
         */
        function GalleryMultiUploadResponse(properties) {
            this.src = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GalleryMultiUploadResponse src.
         * @member {Array.<PlatformAdminApi.IGalleryListDataResponse>} src
         * @memberof PlatformAdminApi.GalleryMultiUploadResponse
         * @instance
         */
        GalleryMultiUploadResponse.prototype.src = $util.emptyArray;

        /**
         * Encodes the specified GalleryMultiUploadResponse message. Does not implicitly {@link PlatformAdminApi.GalleryMultiUploadResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GalleryMultiUploadResponse
         * @static
         * @param {PlatformAdminApi.IGalleryMultiUploadResponse} message GalleryMultiUploadResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GalleryMultiUploadResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.src != null && message.src.length)
                for (var i = 0; i < message.src.length; ++i)
                    $root.PlatformAdminApi.GalleryListDataResponse.encode(message.src[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GalleryMultiUploadResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GalleryMultiUploadResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GalleryMultiUploadResponse} GalleryMultiUploadResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GalleryMultiUploadResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GalleryMultiUploadResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.src && message.src.length))
                        message.src = [];
                    message.src.push($root.PlatformAdminApi.GalleryListDataResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GalleryMultiUploadResponse;
    })();

    PlatformAdminApi.GalleryListDataResponse = (function() {

        /**
         * Properties of a GalleryListDataResponse.
         * @memberof PlatformAdminApi
         * @interface IGalleryListDataResponse
         * @property {string|null} [id] GalleryListDataResponse id
         * @property {string|null} [name] GalleryListDataResponse name
         * @property {boolean|null} [isDeleted] GalleryListDataResponse isDeleted
         * @property {string|null} [original] GalleryListDataResponse original
         * @property {string|null} [thumb] GalleryListDataResponse thumb
         * @property {string|null} [processingError] GalleryListDataResponse processingError
         */

        /**
         * Constructs a new GalleryListDataResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GalleryListDataResponse.
         * @implements IGalleryListDataResponse
         * @constructor
         * @param {PlatformAdminApi.IGalleryListDataResponse=} [properties] Properties to set
         */
        function GalleryListDataResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GalleryListDataResponse id.
         * @member {string} id
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @instance
         */
        GalleryListDataResponse.prototype.id = "";

        /**
         * GalleryListDataResponse name.
         * @member {string} name
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @instance
         */
        GalleryListDataResponse.prototype.name = "";

        /**
         * GalleryListDataResponse isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @instance
         */
        GalleryListDataResponse.prototype.isDeleted = false;

        /**
         * GalleryListDataResponse original.
         * @member {string} original
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @instance
         */
        GalleryListDataResponse.prototype.original = "";

        /**
         * GalleryListDataResponse thumb.
         * @member {string} thumb
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @instance
         */
        GalleryListDataResponse.prototype.thumb = "";

        /**
         * GalleryListDataResponse processingError.
         * @member {string} processingError
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @instance
         */
        GalleryListDataResponse.prototype.processingError = "";

        /**
         * Encodes the specified GalleryListDataResponse message. Does not implicitly {@link PlatformAdminApi.GalleryListDataResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @static
         * @param {PlatformAdminApi.IGalleryListDataResponse} message GalleryListDataResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GalleryListDataResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDeleted);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.original);
            if (message.thumb != null && Object.hasOwnProperty.call(message, "thumb"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumb);
            if (message.processingError != null && Object.hasOwnProperty.call(message, "processingError"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.processingError);
            return writer;
        };

        /**
         * Decodes a GalleryListDataResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GalleryListDataResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GalleryListDataResponse} GalleryListDataResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GalleryListDataResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GalleryListDataResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.isDeleted = reader.bool();
                    break;
                case 4:
                    message.original = reader.string();
                    break;
                case 5:
                    message.thumb = reader.string();
                    break;
                case 6:
                    message.processingError = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GalleryListDataResponse;
    })();

    PlatformAdminApi.PresencePolygonService = (function() {

        /**
         * Constructs a new PresencePolygonService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PresencePolygonService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PresencePolygonService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PresencePolygonService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PresencePolygonService;

        /**
         * Callback as used by {@link PlatformAdminApi.PresencePolygonService#create}.
         * @memberof PlatformAdminApi.PresencePolygonService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresencePolygon} [response] PresencePolygon
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.ICreatePresencePolygonRequest} request CreatePresencePolygonRequest message or plain object
         * @param {PlatformAdminApi.PresencePolygonService.CreateCallback} callback Node-style callback called with the error, if any, and PresencePolygon
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresencePolygonService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreatePresencePolygonRequest, $root.PlatformAdminApi.PresencePolygon, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.ICreatePresencePolygonRequest} request CreatePresencePolygonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresencePolygon>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresencePolygonService#update}.
         * @memberof PlatformAdminApi.PresencePolygonService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresencePolygon} [response] PresencePolygon
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUpdatePresencePolygonRequest} request UpdatePresencePolygonRequest message or plain object
         * @param {PlatformAdminApi.PresencePolygonService.UpdateCallback} callback Node-style callback called with the error, if any, and PresencePolygon
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresencePolygonService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdatePresencePolygonRequest, $root.PlatformAdminApi.PresencePolygon, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUpdatePresencePolygonRequest} request UpdatePresencePolygonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresencePolygon>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresencePolygonService#search}.
         * @memberof PlatformAdminApi.PresencePolygonService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresencePolygonsList} [response] PresencePolygonsList
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.ISearchPresencePolygonRequest} request SearchPresencePolygonRequest message or plain object
         * @param {PlatformAdminApi.PresencePolygonService.SearchCallback} callback Node-style callback called with the error, if any, and PresencePolygonsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresencePolygonService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchPresencePolygonRequest, $root.PlatformAdminApi.PresencePolygonsList, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.ISearchPresencePolygonRequest} request SearchPresencePolygonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresencePolygonsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresencePolygonService#findById}.
         * @memberof PlatformAdminApi.PresencePolygonService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresencePolygon} [response] PresencePolygon
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PresencePolygonService.FindByIdCallback} callback Node-style callback called with the error, if any, and PresencePolygon
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresencePolygonService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PresencePolygon, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresencePolygon>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresencePolygonService#remove}.
         * @memberof PlatformAdminApi.PresencePolygonService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresencePolygon} [response] PresencePolygon
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PresencePolygonService.RemoveCallback} callback Node-style callback called with the error, if any, and PresencePolygon
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresencePolygonService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PresencePolygon, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresencePolygon>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresencePolygonService#restore}.
         * @memberof PlatformAdminApi.PresencePolygonService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresencePolygon} [response] PresencePolygon
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PresencePolygonService.RestoreCallback} callback Node-style callback called with the error, if any, and PresencePolygon
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresencePolygonService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PresencePolygon, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.PresencePolygonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresencePolygon>} Promise
         * @variation 2
         */

        return PresencePolygonService;
    })();

    PlatformAdminApi.PresencePolygon = (function() {

        /**
         * Properties of a PresencePolygon.
         * @memberof PlatformAdminApi
         * @interface IPresencePolygon
         * @property {string|null} [id] PresencePolygon id
         * @property {string|null} [workAreaId] PresencePolygon workAreaId
         * @property {string|null} [data] PresencePolygon data
         * @property {string|null} [createdAt] PresencePolygon createdAt
         * @property {string|null} [updatedAt] PresencePolygon updatedAt
         * @property {boolean|null} [isDeleted] PresencePolygon isDeleted
         * @property {string|null} [deletedAt] PresencePolygon deletedAt
         */

        /**
         * Constructs a new PresencePolygon.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PresencePolygon.
         * @implements IPresencePolygon
         * @constructor
         * @param {PlatformAdminApi.IPresencePolygon=} [properties] Properties to set
         */
        function PresencePolygon(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresencePolygon id.
         * @member {string} id
         * @memberof PlatformAdminApi.PresencePolygon
         * @instance
         */
        PresencePolygon.prototype.id = "";

        /**
         * PresencePolygon workAreaId.
         * @member {string} workAreaId
         * @memberof PlatformAdminApi.PresencePolygon
         * @instance
         */
        PresencePolygon.prototype.workAreaId = "";

        /**
         * PresencePolygon data.
         * @member {string} data
         * @memberof PlatformAdminApi.PresencePolygon
         * @instance
         */
        PresencePolygon.prototype.data = "";

        /**
         * PresencePolygon createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.PresencePolygon
         * @instance
         */
        PresencePolygon.prototype.createdAt = "";

        /**
         * PresencePolygon updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.PresencePolygon
         * @instance
         */
        PresencePolygon.prototype.updatedAt = "";

        /**
         * PresencePolygon isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.PresencePolygon
         * @instance
         */
        PresencePolygon.prototype.isDeleted = false;

        /**
         * PresencePolygon deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.PresencePolygon
         * @instance
         */
        PresencePolygon.prototype.deletedAt = "";

        /**
         * Encodes the specified PresencePolygon message. Does not implicitly {@link PlatformAdminApi.PresencePolygon.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PresencePolygon
         * @static
         * @param {PlatformAdminApi.IPresencePolygon} message PresencePolygon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresencePolygon.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.workAreaId != null && Object.hasOwnProperty.call(message, "workAreaId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.workAreaId);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.data);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a PresencePolygon message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PresencePolygon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PresencePolygon} PresencePolygon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresencePolygon.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PresencePolygon();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.workAreaId = reader.string();
                    break;
                case 3:
                    message.data = reader.string();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PresencePolygon;
    })();

    PlatformAdminApi.CreatePresencePolygonRequest = (function() {

        /**
         * Properties of a CreatePresencePolygonRequest.
         * @memberof PlatformAdminApi
         * @interface ICreatePresencePolygonRequest
         * @property {string|null} [workAreaId] CreatePresencePolygonRequest workAreaId
         * @property {string|null} [data] CreatePresencePolygonRequest data
         */

        /**
         * Constructs a new CreatePresencePolygonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания полигона присутствия
         * @implements ICreatePresencePolygonRequest
         * @constructor
         * @param {PlatformAdminApi.ICreatePresencePolygonRequest=} [properties] Properties to set
         */
        function CreatePresencePolygonRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreatePresencePolygonRequest workAreaId.
         * @member {string} workAreaId
         * @memberof PlatformAdminApi.CreatePresencePolygonRequest
         * @instance
         */
        CreatePresencePolygonRequest.prototype.workAreaId = "";

        /**
         * CreatePresencePolygonRequest data.
         * @member {string} data
         * @memberof PlatformAdminApi.CreatePresencePolygonRequest
         * @instance
         */
        CreatePresencePolygonRequest.prototype.data = "";

        /**
         * Encodes the specified CreatePresencePolygonRequest message. Does not implicitly {@link PlatformAdminApi.CreatePresencePolygonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreatePresencePolygonRequest
         * @static
         * @param {PlatformAdminApi.ICreatePresencePolygonRequest} message CreatePresencePolygonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePresencePolygonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workAreaId != null && Object.hasOwnProperty.call(message, "workAreaId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.workAreaId);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.data);
            return writer;
        };

        /**
         * Decodes a CreatePresencePolygonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreatePresencePolygonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreatePresencePolygonRequest} CreatePresencePolygonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePresencePolygonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreatePresencePolygonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.workAreaId = reader.string();
                    break;
                case 2:
                    message.data = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreatePresencePolygonRequest;
    })();

    PlatformAdminApi.UpdatePresencePolygonRequest = (function() {

        /**
         * Properties of an UpdatePresencePolygonRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdatePresencePolygonRequest
         * @property {string|null} [id] UpdatePresencePolygonRequest id
         * @property {string|null} [workAreaId] UpdatePresencePolygonRequest workAreaId
         * @property {string|null} [data] UpdatePresencePolygonRequest data
         */

        /**
         * Constructs a new UpdatePresencePolygonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновление полигона присутствия
         * @implements IUpdatePresencePolygonRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdatePresencePolygonRequest=} [properties] Properties to set
         */
        function UpdatePresencePolygonRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePresencePolygonRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdatePresencePolygonRequest
         * @instance
         */
        UpdatePresencePolygonRequest.prototype.id = "";

        /**
         * UpdatePresencePolygonRequest workAreaId.
         * @member {string} workAreaId
         * @memberof PlatformAdminApi.UpdatePresencePolygonRequest
         * @instance
         */
        UpdatePresencePolygonRequest.prototype.workAreaId = "";

        /**
         * UpdatePresencePolygonRequest data.
         * @member {string} data
         * @memberof PlatformAdminApi.UpdatePresencePolygonRequest
         * @instance
         */
        UpdatePresencePolygonRequest.prototype.data = "";

        /**
         * Encodes the specified UpdatePresencePolygonRequest message. Does not implicitly {@link PlatformAdminApi.UpdatePresencePolygonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdatePresencePolygonRequest
         * @static
         * @param {PlatformAdminApi.IUpdatePresencePolygonRequest} message UpdatePresencePolygonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePresencePolygonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.workAreaId != null && Object.hasOwnProperty.call(message, "workAreaId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.workAreaId);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.data);
            return writer;
        };

        /**
         * Decodes an UpdatePresencePolygonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdatePresencePolygonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdatePresencePolygonRequest} UpdatePresencePolygonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePresencePolygonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdatePresencePolygonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.workAreaId = reader.string();
                    break;
                case 3:
                    message.data = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePresencePolygonRequest;
    })();

    PlatformAdminApi.SearchPresencePolygonRequest = (function() {

        /**
         * Properties of a SearchPresencePolygonRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchPresencePolygonRequest
         * @property {number|null} [page] SearchPresencePolygonRequest page
         * @property {number|null} [limit] SearchPresencePolygonRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchPresencePolygonRequest sort
         * @property {Array.<PlatformAdminApi.SearchPresencePolygonRequest.IWhereFields>|null} [where] SearchPresencePolygonRequest where
         */

        /**
         * Constructs a new SearchPresencePolygonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных полигонов присутствия
         * @implements ISearchPresencePolygonRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchPresencePolygonRequest=} [properties] Properties to set
         */
        function SearchPresencePolygonRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPresencePolygonRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPresencePolygonRequest
         * @instance
         */
        SearchPresencePolygonRequest.prototype.page = 0;

        /**
         * SearchPresencePolygonRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPresencePolygonRequest
         * @instance
         */
        SearchPresencePolygonRequest.prototype.limit = 0;

        /**
         * SearchPresencePolygonRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchPresencePolygonRequest
         * @instance
         */
        SearchPresencePolygonRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchPresencePolygonRequest where.
         * @member {Array.<PlatformAdminApi.SearchPresencePolygonRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchPresencePolygonRequest
         * @instance
         */
        SearchPresencePolygonRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchPresencePolygonRequest message. Does not implicitly {@link PlatformAdminApi.SearchPresencePolygonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPresencePolygonRequest
         * @static
         * @param {PlatformAdminApi.ISearchPresencePolygonRequest} message SearchPresencePolygonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPresencePolygonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchPresencePolygonRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchPresencePolygonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPresencePolygonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPresencePolygonRequest} SearchPresencePolygonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPresencePolygonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPresencePolygonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchPresencePolygonRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchPresencePolygonRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchPresencePolygonRequest
             * @interface IWhereFields
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchPresencePolygonRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchPresencePolygonRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchPresencePolygonRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchPresencePolygonRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchPresencePolygonRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchPresencePolygonRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchPresencePolygonRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPresencePolygonRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchPresencePolygonRequest;
    })();

    PlatformAdminApi.PresencePolygonsList = (function() {

        /**
         * Properties of a PresencePolygonsList.
         * @memberof PlatformAdminApi
         * @interface IPresencePolygonsList
         * @property {Array.<PlatformAdminApi.IPresencePolygon>|null} [presencePolygons] PresencePolygonsList presencePolygons
         * @property {number|null} [page] PresencePolygonsList page
         * @property {number|null} [limit] PresencePolygonsList limit
         * @property {number|null} [total] PresencePolygonsList total
         */

        /**
         * Constructs a new PresencePolygonsList.
         * @memberof PlatformAdminApi
         * @classdesc Список доступных полигонов присутствия
         * @implements IPresencePolygonsList
         * @constructor
         * @param {PlatformAdminApi.IPresencePolygonsList=} [properties] Properties to set
         */
        function PresencePolygonsList(properties) {
            this.presencePolygons = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresencePolygonsList presencePolygons.
         * @member {Array.<PlatformAdminApi.IPresencePolygon>} presencePolygons
         * @memberof PlatformAdminApi.PresencePolygonsList
         * @instance
         */
        PresencePolygonsList.prototype.presencePolygons = $util.emptyArray;

        /**
         * PresencePolygonsList page.
         * @member {number} page
         * @memberof PlatformAdminApi.PresencePolygonsList
         * @instance
         */
        PresencePolygonsList.prototype.page = 0;

        /**
         * PresencePolygonsList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.PresencePolygonsList
         * @instance
         */
        PresencePolygonsList.prototype.limit = 0;

        /**
         * PresencePolygonsList total.
         * @member {number} total
         * @memberof PlatformAdminApi.PresencePolygonsList
         * @instance
         */
        PresencePolygonsList.prototype.total = 0;

        /**
         * Encodes the specified PresencePolygonsList message. Does not implicitly {@link PlatformAdminApi.PresencePolygonsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PresencePolygonsList
         * @static
         * @param {PlatformAdminApi.IPresencePolygonsList} message PresencePolygonsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresencePolygonsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.presencePolygons != null && message.presencePolygons.length)
                for (var i = 0; i < message.presencePolygons.length; ++i)
                    $root.PlatformAdminApi.PresencePolygon.encode(message.presencePolygons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a PresencePolygonsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PresencePolygonsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PresencePolygonsList} PresencePolygonsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresencePolygonsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PresencePolygonsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.presencePolygons && message.presencePolygons.length))
                        message.presencePolygons = [];
                    message.presencePolygons.push($root.PlatformAdminApi.PresencePolygon.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PresencePolygonsList;
    })();

    PlatformAdminApi.PresenceAreaService = (function() {

        /**
         * Constructs a new PresenceAreaService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PresenceAreaService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PresenceAreaService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PresenceAreaService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PresenceAreaService;

        /**
         * Callback as used by {@link PlatformAdminApi.PresenceAreaService#create}.
         * @memberof PlatformAdminApi.PresenceAreaService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresenceArea} [response] PresenceArea
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.ICreatePresenceAreaRequest} request CreatePresenceAreaRequest message or plain object
         * @param {PlatformAdminApi.PresenceAreaService.CreateCallback} callback Node-style callback called with the error, if any, and PresenceArea
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresenceAreaService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreatePresenceAreaRequest, $root.PlatformAdminApi.PresenceArea, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.ICreatePresenceAreaRequest} request CreatePresenceAreaRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresenceArea>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresenceAreaService#update}.
         * @memberof PlatformAdminApi.PresenceAreaService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresenceArea} [response] PresenceArea
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUpdatePresenceAreaRequest} request UpdatePresenceAreaRequest message or plain object
         * @param {PlatformAdminApi.PresenceAreaService.UpdateCallback} callback Node-style callback called with the error, if any, and PresenceArea
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresenceAreaService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdatePresenceAreaRequest, $root.PlatformAdminApi.PresenceArea, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUpdatePresenceAreaRequest} request UpdatePresenceAreaRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresenceArea>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresenceAreaService#search}.
         * @memberof PlatformAdminApi.PresenceAreaService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresenceAreasList} [response] PresenceAreasList
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.ISearchPresenceAreaRequest} request SearchPresenceAreaRequest message or plain object
         * @param {PlatformAdminApi.PresenceAreaService.SearchCallback} callback Node-style callback called with the error, if any, and PresenceAreasList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresenceAreaService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchPresenceAreaRequest, $root.PlatformAdminApi.PresenceAreasList, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.ISearchPresenceAreaRequest} request SearchPresenceAreaRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresenceAreasList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresenceAreaService#findById}.
         * @memberof PlatformAdminApi.PresenceAreaService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresenceArea} [response] PresenceArea
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PresenceAreaService.FindByIdCallback} callback Node-style callback called with the error, if any, and PresenceArea
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresenceAreaService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PresenceArea, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresenceArea>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresenceAreaService#remove}.
         * @memberof PlatformAdminApi.PresenceAreaService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresenceArea} [response] PresenceArea
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PresenceAreaService.RemoveCallback} callback Node-style callback called with the error, if any, and PresenceArea
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresenceAreaService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PresenceArea, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresenceArea>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PresenceAreaService#restore}.
         * @memberof PlatformAdminApi.PresenceAreaService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PresenceArea} [response] PresenceArea
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PresenceAreaService.RestoreCallback} callback Node-style callback called with the error, if any, and PresenceArea
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PresenceAreaService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PresenceArea, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.PresenceAreaService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PresenceArea>} Promise
         * @variation 2
         */

        return PresenceAreaService;
    })();

    PlatformAdminApi.PresenceArea = (function() {

        /**
         * Properties of a PresenceArea.
         * @memberof PlatformAdminApi
         * @interface IPresenceArea
         * @property {string|null} [id] PresenceArea id
         * @property {string|null} [tenantId] PresenceArea tenantId
         * @property {string|null} [name] PresenceArea name
         * @property {string|null} [title] PresenceArea title
         * @property {string|null} [urlSlug] PresenceArea urlSlug
         * @property {number|null} [level] PresenceArea level
         * @property {Array.<PlatformAdminApi.IPresencePolygon>|null} [polygons] PresenceArea polygons
         * @property {string|null} [createdAt] PresenceArea createdAt
         * @property {string|null} [updatedAt] PresenceArea updatedAt
         * @property {boolean|null} [isDeleted] PresenceArea isDeleted
         * @property {string|null} [deletedAt] PresenceArea deletedAt
         */

        /**
         * Constructs a new PresenceArea.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PresenceArea.
         * @implements IPresenceArea
         * @constructor
         * @param {PlatformAdminApi.IPresenceArea=} [properties] Properties to set
         */
        function PresenceArea(properties) {
            this.polygons = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresenceArea id.
         * @member {string} id
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.id = "";

        /**
         * PresenceArea tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.tenantId = "";

        /**
         * PresenceArea name.
         * @member {string} name
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.name = "";

        /**
         * PresenceArea title.
         * @member {string} title
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.title = "";

        /**
         * PresenceArea urlSlug.
         * @member {string} urlSlug
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.urlSlug = "";

        /**
         * PresenceArea level.
         * @member {number} level
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.level = 0;

        /**
         * PresenceArea polygons.
         * @member {Array.<PlatformAdminApi.IPresencePolygon>} polygons
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.polygons = $util.emptyArray;

        /**
         * PresenceArea createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.createdAt = "";

        /**
         * PresenceArea updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.updatedAt = "";

        /**
         * PresenceArea isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.isDeleted = false;

        /**
         * PresenceArea deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.PresenceArea
         * @instance
         */
        PresenceArea.prototype.deletedAt = "";

        /**
         * Encodes the specified PresenceArea message. Does not implicitly {@link PlatformAdminApi.PresenceArea.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PresenceArea
         * @static
         * @param {PlatformAdminApi.IPresenceArea} message PresenceArea message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceArea.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tenantId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
            if (message.urlSlug != null && Object.hasOwnProperty.call(message, "urlSlug"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.urlSlug);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.level);
            if (message.polygons != null && message.polygons.length)
                for (var i = 0; i < message.polygons.length; ++i)
                    $root.PlatformAdminApi.PresencePolygon.encode(message.polygons[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a PresenceArea message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PresenceArea
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PresenceArea} PresenceArea
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceArea.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PresenceArea();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.tenantId = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.title = reader.string();
                    break;
                case 5:
                    message.urlSlug = reader.string();
                    break;
                case 6:
                    message.level = reader.int32();
                    break;
                case 7:
                    if (!(message.polygons && message.polygons.length))
                        message.polygons = [];
                    message.polygons.push($root.PlatformAdminApi.PresencePolygon.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.createdAt = reader.string();
                    break;
                case 9:
                    message.updatedAt = reader.string();
                    break;
                case 10:
                    message.isDeleted = reader.bool();
                    break;
                case 11:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PresenceArea;
    })();

    PlatformAdminApi.CreatePresenceAreaRequest = (function() {

        /**
         * Properties of a CreatePresenceAreaRequest.
         * @memberof PlatformAdminApi
         * @interface ICreatePresenceAreaRequest
         * @property {string|null} [tenantId] CreatePresenceAreaRequest tenantId
         * @property {string|null} [name] CreatePresenceAreaRequest name
         * @property {string|null} [title] CreatePresenceAreaRequest title
         * @property {string|null} [urlSlug] CreatePresenceAreaRequest urlSlug
         * @property {number|null} [level] CreatePresenceAreaRequest level
         */

        /**
         * Constructs a new CreatePresenceAreaRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания региона присутствия
         * @implements ICreatePresenceAreaRequest
         * @constructor
         * @param {PlatformAdminApi.ICreatePresenceAreaRequest=} [properties] Properties to set
         */
        function CreatePresenceAreaRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreatePresenceAreaRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.CreatePresenceAreaRequest
         * @instance
         */
        CreatePresenceAreaRequest.prototype.tenantId = "";

        /**
         * CreatePresenceAreaRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.CreatePresenceAreaRequest
         * @instance
         */
        CreatePresenceAreaRequest.prototype.name = "";

        /**
         * CreatePresenceAreaRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreatePresenceAreaRequest
         * @instance
         */
        CreatePresenceAreaRequest.prototype.title = "";

        /**
         * CreatePresenceAreaRequest urlSlug.
         * @member {string} urlSlug
         * @memberof PlatformAdminApi.CreatePresenceAreaRequest
         * @instance
         */
        CreatePresenceAreaRequest.prototype.urlSlug = "";

        /**
         * CreatePresenceAreaRequest level.
         * @member {number} level
         * @memberof PlatformAdminApi.CreatePresenceAreaRequest
         * @instance
         */
        CreatePresenceAreaRequest.prototype.level = 0;

        /**
         * Encodes the specified CreatePresenceAreaRequest message. Does not implicitly {@link PlatformAdminApi.CreatePresenceAreaRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreatePresenceAreaRequest
         * @static
         * @param {PlatformAdminApi.ICreatePresenceAreaRequest} message CreatePresenceAreaRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePresenceAreaRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tenantId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            if (message.urlSlug != null && Object.hasOwnProperty.call(message, "urlSlug"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.urlSlug);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.level);
            return writer;
        };

        /**
         * Decodes a CreatePresenceAreaRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreatePresenceAreaRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreatePresenceAreaRequest} CreatePresenceAreaRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePresenceAreaRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreatePresenceAreaRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tenantId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                case 4:
                    message.urlSlug = reader.string();
                    break;
                case 5:
                    message.level = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreatePresenceAreaRequest;
    })();

    PlatformAdminApi.UpdatePresenceAreaRequest = (function() {

        /**
         * Properties of an UpdatePresenceAreaRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdatePresenceAreaRequest
         * @property {string|null} [id] UpdatePresenceAreaRequest id
         * @property {string|null} [tenantId] UpdatePresenceAreaRequest tenantId
         * @property {string|null} [name] UpdatePresenceAreaRequest name
         * @property {string|null} [title] UpdatePresenceAreaRequest title
         * @property {string|null} [urlSlug] UpdatePresenceAreaRequest urlSlug
         * @property {number|null} [level] UpdatePresenceAreaRequest level
         */

        /**
         * Constructs a new UpdatePresenceAreaRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновление региона присутствия
         * @implements IUpdatePresenceAreaRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdatePresenceAreaRequest=} [properties] Properties to set
         */
        function UpdatePresenceAreaRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePresenceAreaRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @instance
         */
        UpdatePresenceAreaRequest.prototype.id = "";

        /**
         * UpdatePresenceAreaRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @instance
         */
        UpdatePresenceAreaRequest.prototype.tenantId = "";

        /**
         * UpdatePresenceAreaRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @instance
         */
        UpdatePresenceAreaRequest.prototype.name = "";

        /**
         * UpdatePresenceAreaRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @instance
         */
        UpdatePresenceAreaRequest.prototype.title = "";

        /**
         * UpdatePresenceAreaRequest urlSlug.
         * @member {string} urlSlug
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @instance
         */
        UpdatePresenceAreaRequest.prototype.urlSlug = "";

        /**
         * UpdatePresenceAreaRequest level.
         * @member {number} level
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @instance
         */
        UpdatePresenceAreaRequest.prototype.level = 0;

        /**
         * Encodes the specified UpdatePresenceAreaRequest message. Does not implicitly {@link PlatformAdminApi.UpdatePresenceAreaRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @static
         * @param {PlatformAdminApi.IUpdatePresenceAreaRequest} message UpdatePresenceAreaRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePresenceAreaRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tenantId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
            if (message.urlSlug != null && Object.hasOwnProperty.call(message, "urlSlug"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.urlSlug);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.level);
            return writer;
        };

        /**
         * Decodes an UpdatePresenceAreaRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdatePresenceAreaRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdatePresenceAreaRequest} UpdatePresenceAreaRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePresenceAreaRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdatePresenceAreaRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.tenantId = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.title = reader.string();
                    break;
                case 5:
                    message.urlSlug = reader.string();
                    break;
                case 6:
                    message.level = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePresenceAreaRequest;
    })();

    PlatformAdminApi.SearchPresenceAreaRequest = (function() {

        /**
         * Properties of a SearchPresenceAreaRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchPresenceAreaRequest
         * @property {number|null} [page] SearchPresenceAreaRequest page
         * @property {number|null} [limit] SearchPresenceAreaRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchPresenceAreaRequest sort
         * @property {Array.<PlatformAdminApi.SearchPresenceAreaRequest.IWhereFields>|null} [where] SearchPresenceAreaRequest where
         */

        /**
         * Constructs a new SearchPresenceAreaRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных регионов присутствия
         * @implements ISearchPresenceAreaRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchPresenceAreaRequest=} [properties] Properties to set
         */
        function SearchPresenceAreaRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPresenceAreaRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPresenceAreaRequest
         * @instance
         */
        SearchPresenceAreaRequest.prototype.page = 0;

        /**
         * SearchPresenceAreaRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPresenceAreaRequest
         * @instance
         */
        SearchPresenceAreaRequest.prototype.limit = 0;

        /**
         * SearchPresenceAreaRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchPresenceAreaRequest
         * @instance
         */
        SearchPresenceAreaRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchPresenceAreaRequest where.
         * @member {Array.<PlatformAdminApi.SearchPresenceAreaRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchPresenceAreaRequest
         * @instance
         */
        SearchPresenceAreaRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchPresenceAreaRequest message. Does not implicitly {@link PlatformAdminApi.SearchPresenceAreaRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPresenceAreaRequest
         * @static
         * @param {PlatformAdminApi.ISearchPresenceAreaRequest} message SearchPresenceAreaRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPresenceAreaRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchPresenceAreaRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchPresenceAreaRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPresenceAreaRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPresenceAreaRequest} SearchPresenceAreaRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPresenceAreaRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPresenceAreaRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchPresenceAreaRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchPresenceAreaRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchPresenceAreaRequest
             * @interface IWhereFields
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchPresenceAreaRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchPresenceAreaRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchPresenceAreaRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchPresenceAreaRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchPresenceAreaRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchPresenceAreaRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchPresenceAreaRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPresenceAreaRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchPresenceAreaRequest;
    })();

    PlatformAdminApi.PresenceAreasList = (function() {

        /**
         * Properties of a PresenceAreasList.
         * @memberof PlatformAdminApi
         * @interface IPresenceAreasList
         * @property {Array.<PlatformAdminApi.IPresenceArea>|null} [presenceAreas] PresenceAreasList presenceAreas
         * @property {number|null} [page] PresenceAreasList page
         * @property {number|null} [limit] PresenceAreasList limit
         * @property {number|null} [total] PresenceAreasList total
         */

        /**
         * Constructs a new PresenceAreasList.
         * @memberof PlatformAdminApi
         * @classdesc Список доступных регионов присутствия
         * @implements IPresenceAreasList
         * @constructor
         * @param {PlatformAdminApi.IPresenceAreasList=} [properties] Properties to set
         */
        function PresenceAreasList(properties) {
            this.presenceAreas = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresenceAreasList presenceAreas.
         * @member {Array.<PlatformAdminApi.IPresenceArea>} presenceAreas
         * @memberof PlatformAdminApi.PresenceAreasList
         * @instance
         */
        PresenceAreasList.prototype.presenceAreas = $util.emptyArray;

        /**
         * PresenceAreasList page.
         * @member {number} page
         * @memberof PlatformAdminApi.PresenceAreasList
         * @instance
         */
        PresenceAreasList.prototype.page = 0;

        /**
         * PresenceAreasList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.PresenceAreasList
         * @instance
         */
        PresenceAreasList.prototype.limit = 0;

        /**
         * PresenceAreasList total.
         * @member {number} total
         * @memberof PlatformAdminApi.PresenceAreasList
         * @instance
         */
        PresenceAreasList.prototype.total = 0;

        /**
         * Encodes the specified PresenceAreasList message. Does not implicitly {@link PlatformAdminApi.PresenceAreasList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PresenceAreasList
         * @static
         * @param {PlatformAdminApi.IPresenceAreasList} message PresenceAreasList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresenceAreasList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.presenceAreas != null && message.presenceAreas.length)
                for (var i = 0; i < message.presenceAreas.length; ++i)
                    $root.PlatformAdminApi.PresenceArea.encode(message.presenceAreas[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a PresenceAreasList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PresenceAreasList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PresenceAreasList} PresenceAreasList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresenceAreasList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PresenceAreasList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.presenceAreas && message.presenceAreas.length))
                        message.presenceAreas = [];
                    message.presenceAreas.push($root.PlatformAdminApi.PresenceArea.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PresenceAreasList;
    })();

    PlatformAdminApi.PositionService = (function() {

        /**
         * Constructs a new PositionService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PositionService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PositionService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PositionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PositionService;

        /**
         * Callback as used by {@link PlatformAdminApi.PositionService#create}.
         * @memberof PlatformAdminApi.PositionService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PositionResponse} [response] PositionResponse
         */

        /**
         * Запрос на создание объекта
         * @function create
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionCreateRequest} request PositionCreateRequest message or plain object
         * @param {PlatformAdminApi.PositionService.CreateCallback} callback Node-style callback called with the error, if any, and PositionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PositionService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.PositionCreateRequest, $root.PlatformAdminApi.PositionResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Запрос на создание объекта
         * @function create
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionCreateRequest} request PositionCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PositionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PositionService#search}.
         * @memberof PlatformAdminApi.PositionService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PositionSearchResponse} [response] PositionSearchResponse
         */

        /**
         * Запрос на поиск массива объектов по параметрам
         * @function search
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionSearchRequest} request PositionSearchRequest message or plain object
         * @param {PlatformAdminApi.PositionService.SearchCallback} callback Node-style callback called with the error, if any, and PositionSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PositionService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.PositionSearchRequest, $root.PlatformAdminApi.PositionSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Запрос на поиск массива объектов по параметрам
         * @function search
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionSearchRequest} request PositionSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PositionSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PositionService#update}.
         * @memberof PlatformAdminApi.PositionService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PositionResponse} [response] PositionResponse
         */

        /**
         * Запрос на изменение объекта  по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionUpdateRequest} request PositionUpdateRequest message or plain object
         * @param {PlatformAdminApi.PositionService.UpdateCallback} callback Node-style callback called with the error, if any, and PositionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PositionService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.PositionUpdateRequest, $root.PlatformAdminApi.PositionResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Запрос на изменение объекта  по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionUpdateRequest} request PositionUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PositionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PositionService#findById}.
         * @memberof PlatformAdminApi.PositionService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PositionResponse} [response] PositionResponse
         */

        /**
         * Запрос на получение объекта по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PositionService.FindByIdCallback} callback Node-style callback called with the error, if any, and PositionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PositionService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PositionResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Запрос на получение объекта по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PositionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PositionService#remove}.
         * @memberof PlatformAdminApi.PositionService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PositionResponse} [response] PositionResponse
         */

        /**
         * Запрос на удаление объекта по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionRemoveRequest} request PositionRemoveRequest message or plain object
         * @param {PlatformAdminApi.PositionService.RemoveCallback} callback Node-style callback called with the error, if any, and PositionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PositionService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.PositionRemoveRequest, $root.PlatformAdminApi.PositionResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Запрос на удаление объекта по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionRemoveRequest} request PositionRemoveRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PositionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PositionService#restore}.
         * @memberof PlatformAdminApi.PositionService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PositionResponse} [response] PositionResponse
         */

        /**
         * Запрос на восстановление объекта по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionRestoreRequest} request PositionRestoreRequest message or plain object
         * @param {PlatformAdminApi.PositionService.RestoreCallback} callback Node-style callback called with the error, if any, and PositionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PositionService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.PositionRestoreRequest, $root.PlatformAdminApi.PositionResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Запрос на восстановление объекта по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.PositionService
         * @instance
         * @param {PlatformAdminApi.IPositionRestoreRequest} request PositionRestoreRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PositionResponse>} Promise
         * @variation 2
         */

        return PositionService;
    })();

    PlatformAdminApi.PositionCreateRequest = (function() {

        /**
         * Properties of a PositionCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IPositionCreateRequest
         * @property {string|null} [title] PositionCreateRequest title
         * @property {string|null} [name] PositionCreateRequest name
         */

        /**
         * Constructs a new PositionCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PositionCreateRequest.
         * @implements IPositionCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IPositionCreateRequest=} [properties] Properties to set
         */
        function PositionCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionCreateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.PositionCreateRequest
         * @instance
         */
        PositionCreateRequest.prototype.title = "";

        /**
         * PositionCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.PositionCreateRequest
         * @instance
         */
        PositionCreateRequest.prototype.name = "";

        /**
         * Encodes the specified PositionCreateRequest message. Does not implicitly {@link PlatformAdminApi.PositionCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionCreateRequest
         * @static
         * @param {PlatformAdminApi.IPositionCreateRequest} message PositionCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a PositionCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionCreateRequest} PositionCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PositionCreateRequest;
    })();

    PlatformAdminApi.PositionSearchRequest = (function() {

        /**
         * Properties of a PositionSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IPositionSearchRequest
         * @property {number|null} [page] PositionSearchRequest page
         * @property {number|null} [limit] PositionSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] PositionSearchRequest sort
         * @property {Array.<PlatformAdminApi.PositionSearchRequest.IWhereFields>|null} [where] PositionSearchRequest where
         */

        /**
         * Constructs a new PositionSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса всех объектов
         * @implements IPositionSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IPositionSearchRequest=} [properties] Properties to set
         */
        function PositionSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.PositionSearchRequest
         * @instance
         */
        PositionSearchRequest.prototype.page = 0;

        /**
         * PositionSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.PositionSearchRequest
         * @instance
         */
        PositionSearchRequest.prototype.limit = 0;

        /**
         * PositionSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.PositionSearchRequest
         * @instance
         */
        PositionSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * PositionSearchRequest where.
         * @member {Array.<PlatformAdminApi.PositionSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.PositionSearchRequest
         * @instance
         */
        PositionSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified PositionSearchRequest message. Does not implicitly {@link PlatformAdminApi.PositionSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionSearchRequest
         * @static
         * @param {PlatformAdminApi.IPositionSearchRequest} message PositionSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.PositionSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PositionSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionSearchRequest} PositionSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.PositionSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PositionSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.PositionSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.PositionSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.PositionSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.PositionSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.PositionSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.PositionSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.PositionSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 5:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return PositionSearchRequest;
    })();

    PlatformAdminApi.PositionSearchResponse = (function() {

        /**
         * Properties of a PositionSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IPositionSearchResponse
         * @property {Array.<PlatformAdminApi.IPosition>|null} [data] PositionSearchResponse data
         * @property {number|null} [page] PositionSearchResponse page
         * @property {number|null} [limit] PositionSearchResponse limit
         * @property {number|null} [total] PositionSearchResponse total
         */

        /**
         * Constructs a new PositionSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE при запросе для всех объектов
         * @implements IPositionSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IPositionSearchResponse=} [properties] Properties to set
         */
        function PositionSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionSearchResponse data.
         * @member {Array.<PlatformAdminApi.IPosition>} data
         * @memberof PlatformAdminApi.PositionSearchResponse
         * @instance
         */
        PositionSearchResponse.prototype.data = $util.emptyArray;

        /**
         * PositionSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.PositionSearchResponse
         * @instance
         */
        PositionSearchResponse.prototype.page = 0;

        /**
         * PositionSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.PositionSearchResponse
         * @instance
         */
        PositionSearchResponse.prototype.limit = 0;

        /**
         * PositionSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.PositionSearchResponse
         * @instance
         */
        PositionSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified PositionSearchResponse message. Does not implicitly {@link PlatformAdminApi.PositionSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionSearchResponse
         * @static
         * @param {PlatformAdminApi.IPositionSearchResponse} message PositionSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Position.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a PositionSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionSearchResponse} PositionSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Position.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PositionSearchResponse;
    })();

    PlatformAdminApi.Position = (function() {

        /**
         * Properties of a Position.
         * @memberof PlatformAdminApi
         * @interface IPosition
         * @property {string|null} [id] Position id
         * @property {string|null} [title] Position title
         * @property {string|null} [name] Position name
         * @property {string|null} [createdAt] Position createdAt
         * @property {string|null} [updatedAt] Position updatedAt
         * @property {boolean|null} [isDeleted] Position isDeleted
         * @property {string|null} [deletedAt] Position deletedAt
         */

        /**
         * Constructs a new Position.
         * @memberof PlatformAdminApi
         * @classdesc Модель объекта
         * @implements IPosition
         * @constructor
         * @param {PlatformAdminApi.IPosition=} [properties] Properties to set
         */
        function Position(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Position id.
         * @member {string} id
         * @memberof PlatformAdminApi.Position
         * @instance
         */
        Position.prototype.id = "";

        /**
         * Position title.
         * @member {string} title
         * @memberof PlatformAdminApi.Position
         * @instance
         */
        Position.prototype.title = "";

        /**
         * Position name.
         * @member {string} name
         * @memberof PlatformAdminApi.Position
         * @instance
         */
        Position.prototype.name = "";

        /**
         * Position createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Position
         * @instance
         */
        Position.prototype.createdAt = "";

        /**
         * Position updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Position
         * @instance
         */
        Position.prototype.updatedAt = "";

        /**
         * Position isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Position
         * @instance
         */
        Position.prototype.isDeleted = false;

        /**
         * Position deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Position
         * @instance
         */
        Position.prototype.deletedAt = "";

        /**
         * Encodes the specified Position message. Does not implicitly {@link PlatformAdminApi.Position.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Position
         * @static
         * @param {PlatformAdminApi.IPosition} message Position message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Position.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Position message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Position
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Position} Position
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Position.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Position();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Position;
    })();

    PlatformAdminApi.PositionUpdateRequest = (function() {

        /**
         * Properties of a PositionUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IPositionUpdateRequest
         * @property {string|null} [id] PositionUpdateRequest id
         * @property {string|null} [title] PositionUpdateRequest title
         * @property {string|null} [name] PositionUpdateRequest name
         */

        /**
         * Constructs a new PositionUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса редактирования
         * @implements IPositionUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IPositionUpdateRequest=} [properties] Properties to set
         */
        function PositionUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.PositionUpdateRequest
         * @instance
         */
        PositionUpdateRequest.prototype.id = "";

        /**
         * PositionUpdateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.PositionUpdateRequest
         * @instance
         */
        PositionUpdateRequest.prototype.title = "";

        /**
         * PositionUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.PositionUpdateRequest
         * @instance
         */
        PositionUpdateRequest.prototype.name = "";

        /**
         * Encodes the specified PositionUpdateRequest message. Does not implicitly {@link PlatformAdminApi.PositionUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionUpdateRequest
         * @static
         * @param {PlatformAdminApi.IPositionUpdateRequest} message PositionUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a PositionUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionUpdateRequest} PositionUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PositionUpdateRequest;
    })();

    PlatformAdminApi.PositionRemoveRequest = (function() {

        /**
         * Properties of a PositionRemoveRequest.
         * @memberof PlatformAdminApi
         * @interface IPositionRemoveRequest
         * @property {string|null} [id] PositionRemoveRequest id
         */

        /**
         * Constructs a new PositionRemoveRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса удаления
         * @implements IPositionRemoveRequest
         * @constructor
         * @param {PlatformAdminApi.IPositionRemoveRequest=} [properties] Properties to set
         */
        function PositionRemoveRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionRemoveRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.PositionRemoveRequest
         * @instance
         */
        PositionRemoveRequest.prototype.id = "";

        /**
         * Encodes the specified PositionRemoveRequest message. Does not implicitly {@link PlatformAdminApi.PositionRemoveRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionRemoveRequest
         * @static
         * @param {PlatformAdminApi.IPositionRemoveRequest} message PositionRemoveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionRemoveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a PositionRemoveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionRemoveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionRemoveRequest} PositionRemoveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionRemoveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionRemoveRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PositionRemoveRequest;
    })();

    PlatformAdminApi.PositionRestoreRequest = (function() {

        /**
         * Properties of a PositionRestoreRequest.
         * @memberof PlatformAdminApi
         * @interface IPositionRestoreRequest
         * @property {string|null} [id] PositionRestoreRequest id
         */

        /**
         * Constructs a new PositionRestoreRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса восстановления
         * @implements IPositionRestoreRequest
         * @constructor
         * @param {PlatformAdminApi.IPositionRestoreRequest=} [properties] Properties to set
         */
        function PositionRestoreRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionRestoreRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.PositionRestoreRequest
         * @instance
         */
        PositionRestoreRequest.prototype.id = "";

        /**
         * Encodes the specified PositionRestoreRequest message. Does not implicitly {@link PlatformAdminApi.PositionRestoreRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionRestoreRequest
         * @static
         * @param {PlatformAdminApi.IPositionRestoreRequest} message PositionRestoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionRestoreRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a PositionRestoreRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionRestoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionRestoreRequest} PositionRestoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionRestoreRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionRestoreRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PositionRestoreRequest;
    })();

    PlatformAdminApi.PositionResponse = (function() {

        /**
         * Properties of a PositionResponse.
         * @memberof PlatformAdminApi
         * @interface IPositionResponse
         * @property {PlatformAdminApi.IPosition|null} [data] PositionResponse data
         */

        /**
         * Constructs a new PositionResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE
         * @implements IPositionResponse
         * @constructor
         * @param {PlatformAdminApi.IPositionResponse=} [properties] Properties to set
         */
        function PositionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionResponse data.
         * @member {PlatformAdminApi.IPosition|null|undefined} data
         * @memberof PlatformAdminApi.PositionResponse
         * @instance
         */
        PositionResponse.prototype.data = null;

        /**
         * Encodes the specified PositionResponse message. Does not implicitly {@link PlatformAdminApi.PositionResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionResponse
         * @static
         * @param {PlatformAdminApi.IPositionResponse} message PositionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Position.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PositionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionResponse} PositionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Position.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PositionResponse;
    })();

    PlatformAdminApi.SpecializationsService = (function() {

        /**
         * Constructs a new SpecializationsService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SpecializationsService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SpecializationsService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SpecializationsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SpecializationsService;

        /**
         * Callback as used by {@link PlatformAdminApi.SpecializationsService#search}.
         * @memberof PlatformAdminApi.SpecializationsService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SpecializationsSearchResponse} [response] SpecializationsSearchResponse
         */

        /**
         * Запрос на поиск массива объектов SPECIALIZATION по параметрам
         * @function search
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationsSearchRequest} request SpecializationsSearchRequest message or plain object
         * @param {PlatformAdminApi.SpecializationsService.SearchCallback} callback Node-style callback called with the error, if any, and SpecializationsSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SpecializationsService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SpecializationsSearchRequest, $root.PlatformAdminApi.SpecializationsSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Запрос на поиск массива объектов SPECIALIZATION по параметрам
         * @function search
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationsSearchRequest} request SpecializationsSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SpecializationsSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SpecializationsService#create}.
         * @memberof PlatformAdminApi.SpecializationsService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SpecializationResponse} [response] SpecializationResponse
         */

        /**
         * Запрос на создание объекта SPECIALIZATION
         * @function create
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationCreateRequest} request SpecializationCreateRequest message or plain object
         * @param {PlatformAdminApi.SpecializationsService.CreateCallback} callback Node-style callback called with the error, if any, and SpecializationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SpecializationsService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.SpecializationCreateRequest, $root.PlatformAdminApi.SpecializationResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Запрос на создание объекта SPECIALIZATION
         * @function create
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationCreateRequest} request SpecializationCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SpecializationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SpecializationsService#update}.
         * @memberof PlatformAdminApi.SpecializationsService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SpecializationResponse} [response] SpecializationResponse
         */

        /**
         * Запрос на изменение объекта SPECIALIZATION по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationUpdateRequest} request SpecializationUpdateRequest message or plain object
         * @param {PlatformAdminApi.SpecializationsService.UpdateCallback} callback Node-style callback called with the error, if any, and SpecializationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SpecializationsService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.SpecializationUpdateRequest, $root.PlatformAdminApi.SpecializationResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Запрос на изменение объекта SPECIALIZATION по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationUpdateRequest} request SpecializationUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SpecializationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SpecializationsService#findById}.
         * @memberof PlatformAdminApi.SpecializationsService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SpecializationResponse} [response] SpecializationResponse
         */

        /**
         * Запрос на получение объекта SPECIALIZATION по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.SpecializationsService.FindByIdCallback} callback Node-style callback called with the error, if any, and SpecializationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SpecializationsService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.SpecializationResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Запрос на получение объекта SPECIALIZATION по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SpecializationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SpecializationsService#remove}.
         * @memberof PlatformAdminApi.SpecializationsService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SpecializationResponse} [response] SpecializationResponse
         */

        /**
         * Запрос на удаление объекта SPECIALIZATION по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationRemoveRequest} request SpecializationRemoveRequest message or plain object
         * @param {PlatformAdminApi.SpecializationsService.RemoveCallback} callback Node-style callback called with the error, if any, and SpecializationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SpecializationsService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.SpecializationRemoveRequest, $root.PlatformAdminApi.SpecializationResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Запрос на удаление объекта SPECIALIZATION по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationRemoveRequest} request SpecializationRemoveRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SpecializationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SpecializationsService#restore}.
         * @memberof PlatformAdminApi.SpecializationsService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SpecializationResponse} [response] SpecializationResponse
         */

        /**
         * Запрос на восстановление объекта SPECIALIZATION по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationRestoreRequest} request SpecializationRestoreRequest message or plain object
         * @param {PlatformAdminApi.SpecializationsService.RestoreCallback} callback Node-style callback called with the error, if any, and SpecializationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SpecializationsService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.SpecializationRestoreRequest, $root.PlatformAdminApi.SpecializationResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Запрос на восстановление объекта SPECIALIZATION по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.SpecializationsService
         * @instance
         * @param {PlatformAdminApi.ISpecializationRestoreRequest} request SpecializationRestoreRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SpecializationResponse>} Promise
         * @variation 2
         */

        return SpecializationsService;
    })();

    PlatformAdminApi.SpecializationsSearchRequest = (function() {

        /**
         * Properties of a SpecializationsSearchRequest.
         * @memberof PlatformAdminApi
         * @interface ISpecializationsSearchRequest
         * @property {number|null} [page] SpecializationsSearchRequest page
         * @property {number|null} [limit] SpecializationsSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SpecializationsSearchRequest sort
         * @property {Array.<PlatformAdminApi.SpecializationsSearchRequest.IWhereFields>|null} [where] SpecializationsSearchRequest where
         */

        /**
         * Constructs a new SpecializationsSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса всех специализаций
         * @implements ISpecializationsSearchRequest
         * @constructor
         * @param {PlatformAdminApi.ISpecializationsSearchRequest=} [properties] Properties to set
         */
        function SpecializationsSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationsSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SpecializationsSearchRequest
         * @instance
         */
        SpecializationsSearchRequest.prototype.page = 0;

        /**
         * SpecializationsSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SpecializationsSearchRequest
         * @instance
         */
        SpecializationsSearchRequest.prototype.limit = 0;

        /**
         * SpecializationsSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SpecializationsSearchRequest
         * @instance
         */
        SpecializationsSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * SpecializationsSearchRequest where.
         * @member {Array.<PlatformAdminApi.SpecializationsSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SpecializationsSearchRequest
         * @instance
         */
        SpecializationsSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SpecializationsSearchRequest message. Does not implicitly {@link PlatformAdminApi.SpecializationsSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationsSearchRequest
         * @static
         * @param {PlatformAdminApi.ISpecializationsSearchRequest} message SpecializationsSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationsSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SpecializationsSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SpecializationsSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationsSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationsSearchRequest} SpecializationsSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationsSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationsSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SpecializationsSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SpecializationsSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SpecializationsSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SpecializationsSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SpecializationsSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SpecializationsSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SpecializationsSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SpecializationsSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SpecializationsSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationsSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 5:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SpecializationsSearchRequest;
    })();

    PlatformAdminApi.SpecializationsSearchResponse = (function() {

        /**
         * Properties of a SpecializationsSearchResponse.
         * @memberof PlatformAdminApi
         * @interface ISpecializationsSearchResponse
         * @property {Array.<PlatformAdminApi.ISpecialization>|null} [data] SpecializationsSearchResponse data
         * @property {number|null} [page] SpecializationsSearchResponse page
         * @property {number|null} [pageCount] SpecializationsSearchResponse pageCount
         * @property {number|null} [limit] SpecializationsSearchResponse limit
         * @property {number|null} [count] SpecializationsSearchResponse count
         * @property {number|null} [total] SpecializationsSearchResponse total
         */

        /**
         * Constructs a new SpecializationsSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE при запросе для всех специализаций
         * @implements ISpecializationsSearchResponse
         * @constructor
         * @param {PlatformAdminApi.ISpecializationsSearchResponse=} [properties] Properties to set
         */
        function SpecializationsSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationsSearchResponse data.
         * @member {Array.<PlatformAdminApi.ISpecialization>} data
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @instance
         */
        SpecializationsSearchResponse.prototype.data = $util.emptyArray;

        /**
         * SpecializationsSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @instance
         */
        SpecializationsSearchResponse.prototype.page = 0;

        /**
         * SpecializationsSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @instance
         */
        SpecializationsSearchResponse.prototype.pageCount = 0;

        /**
         * SpecializationsSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @instance
         */
        SpecializationsSearchResponse.prototype.limit = 0;

        /**
         * SpecializationsSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @instance
         */
        SpecializationsSearchResponse.prototype.count = 0;

        /**
         * SpecializationsSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @instance
         */
        SpecializationsSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified SpecializationsSearchResponse message. Does not implicitly {@link PlatformAdminApi.SpecializationsSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @static
         * @param {PlatformAdminApi.ISpecializationsSearchResponse} message SpecializationsSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationsSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Specialization.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SpecializationsSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationsSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationsSearchResponse} SpecializationsSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationsSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationsSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Specialization.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationsSearchResponse;
    })();

    PlatformAdminApi.Specialization = (function() {

        /**
         * Properties of a Specialization.
         * @memberof PlatformAdminApi
         * @interface ISpecialization
         * @property {string|null} [id] Specialization id
         * @property {string|null} [title] Specialization title
         * @property {string|null} [name] Specialization name
         * @property {string|null} [createdAt] Specialization createdAt
         * @property {string|null} [updatedAt] Specialization updatedAt
         * @property {boolean|null} [isDeleted] Specialization isDeleted
         * @property {string|null} [deletedAt] Specialization deletedAt
         */

        /**
         * Constructs a new Specialization.
         * @memberof PlatformAdminApi
         * @classdesc Модель SPECIALIZATION
         * @implements ISpecialization
         * @constructor
         * @param {PlatformAdminApi.ISpecialization=} [properties] Properties to set
         */
        function Specialization(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Specialization id.
         * @member {string} id
         * @memberof PlatformAdminApi.Specialization
         * @instance
         */
        Specialization.prototype.id = "";

        /**
         * Specialization title.
         * @member {string} title
         * @memberof PlatformAdminApi.Specialization
         * @instance
         */
        Specialization.prototype.title = "";

        /**
         * Specialization name.
         * @member {string} name
         * @memberof PlatformAdminApi.Specialization
         * @instance
         */
        Specialization.prototype.name = "";

        /**
         * Specialization createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Specialization
         * @instance
         */
        Specialization.prototype.createdAt = "";

        /**
         * Specialization updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Specialization
         * @instance
         */
        Specialization.prototype.updatedAt = "";

        /**
         * Specialization isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Specialization
         * @instance
         */
        Specialization.prototype.isDeleted = false;

        /**
         * Specialization deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Specialization
         * @instance
         */
        Specialization.prototype.deletedAt = "";

        /**
         * Encodes the specified Specialization message. Does not implicitly {@link PlatformAdminApi.Specialization.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Specialization
         * @static
         * @param {PlatformAdminApi.ISpecialization} message Specialization message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Specialization.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Specialization message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Specialization
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Specialization} Specialization
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Specialization.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Specialization();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Specialization;
    })();

    PlatformAdminApi.SpecializationUpdateRequest = (function() {

        /**
         * Properties of a SpecializationUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface ISpecializationUpdateRequest
         * @property {string|null} [id] SpecializationUpdateRequest id
         * @property {string|null} [title] SpecializationUpdateRequest title
         * @property {string|null} [name] SpecializationUpdateRequest name
         */

        /**
         * Constructs a new SpecializationUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса редактирования специализации
         * @implements ISpecializationUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.ISpecializationUpdateRequest=} [properties] Properties to set
         */
        function SpecializationUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.SpecializationUpdateRequest
         * @instance
         */
        SpecializationUpdateRequest.prototype.id = "";

        /**
         * SpecializationUpdateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.SpecializationUpdateRequest
         * @instance
         */
        SpecializationUpdateRequest.prototype.title = "";

        /**
         * SpecializationUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.SpecializationUpdateRequest
         * @instance
         */
        SpecializationUpdateRequest.prototype.name = "";

        /**
         * Encodes the specified SpecializationUpdateRequest message. Does not implicitly {@link PlatformAdminApi.SpecializationUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationUpdateRequest
         * @static
         * @param {PlatformAdminApi.ISpecializationUpdateRequest} message SpecializationUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a SpecializationUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationUpdateRequest} SpecializationUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationUpdateRequest;
    })();

    PlatformAdminApi.SpecializationRemoveRequest = (function() {

        /**
         * Properties of a SpecializationRemoveRequest.
         * @memberof PlatformAdminApi
         * @interface ISpecializationRemoveRequest
         * @property {string|null} [id] SpecializationRemoveRequest id
         */

        /**
         * Constructs a new SpecializationRemoveRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса удаления специализации
         * @implements ISpecializationRemoveRequest
         * @constructor
         * @param {PlatformAdminApi.ISpecializationRemoveRequest=} [properties] Properties to set
         */
        function SpecializationRemoveRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationRemoveRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.SpecializationRemoveRequest
         * @instance
         */
        SpecializationRemoveRequest.prototype.id = "";

        /**
         * Encodes the specified SpecializationRemoveRequest message. Does not implicitly {@link PlatformAdminApi.SpecializationRemoveRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationRemoveRequest
         * @static
         * @param {PlatformAdminApi.ISpecializationRemoveRequest} message SpecializationRemoveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationRemoveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a SpecializationRemoveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationRemoveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationRemoveRequest} SpecializationRemoveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationRemoveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationRemoveRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationRemoveRequest;
    })();

    PlatformAdminApi.SpecializationRestoreRequest = (function() {

        /**
         * Properties of a SpecializationRestoreRequest.
         * @memberof PlatformAdminApi
         * @interface ISpecializationRestoreRequest
         * @property {string|null} [id] SpecializationRestoreRequest id
         */

        /**
         * Constructs a new SpecializationRestoreRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса восстановления специализации
         * @implements ISpecializationRestoreRequest
         * @constructor
         * @param {PlatformAdminApi.ISpecializationRestoreRequest=} [properties] Properties to set
         */
        function SpecializationRestoreRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationRestoreRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.SpecializationRestoreRequest
         * @instance
         */
        SpecializationRestoreRequest.prototype.id = "";

        /**
         * Encodes the specified SpecializationRestoreRequest message. Does not implicitly {@link PlatformAdminApi.SpecializationRestoreRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationRestoreRequest
         * @static
         * @param {PlatformAdminApi.ISpecializationRestoreRequest} message SpecializationRestoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationRestoreRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a SpecializationRestoreRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationRestoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationRestoreRequest} SpecializationRestoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationRestoreRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationRestoreRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationRestoreRequest;
    })();

    PlatformAdminApi.SpecializationCreateRequest = (function() {

        /**
         * Properties of a SpecializationCreateRequest.
         * @memberof PlatformAdminApi
         * @interface ISpecializationCreateRequest
         * @property {string|null} [title] SpecializationCreateRequest title
         * @property {string|null} [name] SpecializationCreateRequest name
         */

        /**
         * Constructs a new SpecializationCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса создания специализации
         * @implements ISpecializationCreateRequest
         * @constructor
         * @param {PlatformAdminApi.ISpecializationCreateRequest=} [properties] Properties to set
         */
        function SpecializationCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationCreateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.SpecializationCreateRequest
         * @instance
         */
        SpecializationCreateRequest.prototype.title = "";

        /**
         * SpecializationCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.SpecializationCreateRequest
         * @instance
         */
        SpecializationCreateRequest.prototype.name = "";

        /**
         * Encodes the specified SpecializationCreateRequest message. Does not implicitly {@link PlatformAdminApi.SpecializationCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationCreateRequest
         * @static
         * @param {PlatformAdminApi.ISpecializationCreateRequest} message SpecializationCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a SpecializationCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationCreateRequest} SpecializationCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationCreateRequest;
    })();

    PlatformAdminApi.SpecializationResponse = (function() {

        /**
         * Properties of a SpecializationResponse.
         * @memberof PlatformAdminApi
         * @interface ISpecializationResponse
         * @property {PlatformAdminApi.ISpecialization|null} [data] SpecializationResponse data
         */

        /**
         * Constructs a new SpecializationResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE для специализации
         * @implements ISpecializationResponse
         * @constructor
         * @param {PlatformAdminApi.ISpecializationResponse=} [properties] Properties to set
         */
        function SpecializationResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationResponse data.
         * @member {PlatformAdminApi.ISpecialization|null|undefined} data
         * @memberof PlatformAdminApi.SpecializationResponse
         * @instance
         */
        SpecializationResponse.prototype.data = null;

        /**
         * Encodes the specified SpecializationResponse message. Does not implicitly {@link PlatformAdminApi.SpecializationResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationResponse
         * @static
         * @param {PlatformAdminApi.ISpecializationResponse} message SpecializationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Specialization.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SpecializationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationResponse} SpecializationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Specialization.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationResponse;
    })();

    PlatformAdminApi.SpecializationRelation = (function() {

        /**
         * Properties of a SpecializationRelation.
         * @memberof PlatformAdminApi
         * @interface ISpecializationRelation
         * @property {string|null} [id] SpecializationRelation id
         * @property {string|null} [title] SpecializationRelation title
         * @property {string|null} [name] SpecializationRelation name
         */

        /**
         * Constructs a new SpecializationRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SpecializationRelation.
         * @implements ISpecializationRelation
         * @constructor
         * @param {PlatformAdminApi.ISpecializationRelation=} [properties] Properties to set
         */
        function SpecializationRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.SpecializationRelation
         * @instance
         */
        SpecializationRelation.prototype.id = "";

        /**
         * SpecializationRelation title.
         * @member {string} title
         * @memberof PlatformAdminApi.SpecializationRelation
         * @instance
         */
        SpecializationRelation.prototype.title = "";

        /**
         * SpecializationRelation name.
         * @member {string} name
         * @memberof PlatformAdminApi.SpecializationRelation
         * @instance
         */
        SpecializationRelation.prototype.name = "";

        /**
         * Encodes the specified SpecializationRelation message. Does not implicitly {@link PlatformAdminApi.SpecializationRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationRelation
         * @static
         * @param {PlatformAdminApi.ISpecializationRelation} message SpecializationRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a SpecializationRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationRelation} SpecializationRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationRelation;
    })();

    PlatformAdminApi.BlockingReasonGroupService = (function() {

        /**
         * Constructs a new BlockingReasonGroupService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonGroupService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function BlockingReasonGroupService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (BlockingReasonGroupService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BlockingReasonGroupService;

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonGroupService#search}.
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonGroupSearchResponse} [response] BlockingReasonGroupSearchResponse
         */

        /**
         * Запрос на поиск массива объектов BlockingReasonGroup по параметрам
         * @function search
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupSearchRequest} request BlockingReasonGroupSearchRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonGroupService.SearchCallback} callback Node-style callback called with the error, if any, and BlockingReasonGroupSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonGroupService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.BlockingReasonGroupSearchRequest, $root.PlatformAdminApi.BlockingReasonGroupSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Запрос на поиск массива объектов BlockingReasonGroup по параметрам
         * @function search
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupSearchRequest} request BlockingReasonGroupSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonGroupSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonGroupService#create}.
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonGroupResponse} [response] BlockingReasonGroupResponse
         */

        /**
         * Запрос на создание объекта BlockingReasonGroup
         * @function create
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupCreateRequest} request BlockingReasonGroupCreateRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonGroupService.CreateCallback} callback Node-style callback called with the error, if any, and BlockingReasonGroupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonGroupService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.BlockingReasonGroupCreateRequest, $root.PlatformAdminApi.BlockingReasonGroupResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Запрос на создание объекта BlockingReasonGroup
         * @function create
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupCreateRequest} request BlockingReasonGroupCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonGroupResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonGroupService#update}.
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonGroupResponse} [response] BlockingReasonGroupResponse
         */

        /**
         * Запрос на изменение объекта BlockingReasonGroup по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupUpdateRequest} request BlockingReasonGroupUpdateRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonGroupService.UpdateCallback} callback Node-style callback called with the error, if any, and BlockingReasonGroupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonGroupService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.BlockingReasonGroupUpdateRequest, $root.PlatformAdminApi.BlockingReasonGroupResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Запрос на изменение объекта BlockingReasonGroup по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupUpdateRequest} request BlockingReasonGroupUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonGroupResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonGroupService#findById}.
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonGroupResponse} [response] BlockingReasonGroupResponse
         */

        /**
         * Запрос на получение объекта BlockingReasonGroup по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonGroupService.FindByIdCallback} callback Node-style callback called with the error, if any, and BlockingReasonGroupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonGroupService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.BlockingReasonGroupResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Запрос на получение объекта BlockingReasonGroup по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonGroupResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonGroupService#remove}.
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonGroupResponse} [response] BlockingReasonGroupResponse
         */

        /**
         * Запрос на удаление объекта BlockingReasonGroup по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupRemoveRequest} request BlockingReasonGroupRemoveRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonGroupService.RemoveCallback} callback Node-style callback called with the error, if any, and BlockingReasonGroupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonGroupService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.BlockingReasonGroupRemoveRequest, $root.PlatformAdminApi.BlockingReasonGroupResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Запрос на удаление объекта BlockingReasonGroup по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupRemoveRequest} request BlockingReasonGroupRemoveRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonGroupResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonGroupService#restore}.
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonGroupResponse} [response] BlockingReasonGroupResponse
         */

        /**
         * Запрос на получение объекта BlockingReasonGroup по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupRestoreRequest} request BlockingReasonGroupRestoreRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonGroupService.RestoreCallback} callback Node-style callback called with the error, if any, and BlockingReasonGroupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonGroupService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.BlockingReasonGroupRestoreRequest, $root.PlatformAdminApi.BlockingReasonGroupResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Запрос на получение объекта BlockingReasonGroup по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.BlockingReasonGroupService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonGroupRestoreRequest} request BlockingReasonGroupRestoreRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonGroupResponse>} Promise
         * @variation 2
         */

        return BlockingReasonGroupService;
    })();

    PlatformAdminApi.BlockingReasonGroupSearchRequest = (function() {

        /**
         * Properties of a BlockingReasonGroupSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupSearchRequest
         * @property {number|null} [page] BlockingReasonGroupSearchRequest page
         * @property {number|null} [limit] BlockingReasonGroupSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] BlockingReasonGroupSearchRequest sort
         * @property {Array.<PlatformAdminApi.BlockingReasonGroupSearchRequest.IWhereFields>|null} [where] BlockingReasonGroupSearchRequest where
         */

        /**
         * Constructs a new BlockingReasonGroupSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса всех групп причин блокировки
         * @implements IBlockingReasonGroupSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupSearchRequest=} [properties] Properties to set
         */
        function BlockingReasonGroupSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
         * @instance
         */
        BlockingReasonGroupSearchRequest.prototype.page = 0;

        /**
         * BlockingReasonGroupSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
         * @instance
         */
        BlockingReasonGroupSearchRequest.prototype.limit = 0;

        /**
         * BlockingReasonGroupSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
         * @instance
         */
        BlockingReasonGroupSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * BlockingReasonGroupSearchRequest where.
         * @member {Array.<PlatformAdminApi.BlockingReasonGroupSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
         * @instance
         */
        BlockingReasonGroupSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified BlockingReasonGroupSearchRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupSearchRequest} message BlockingReasonGroupSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupSearchRequest} BlockingReasonGroupSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        BlockingReasonGroupSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.BlockingReasonGroupSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.BlockingReasonGroupSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return BlockingReasonGroupSearchRequest;
    })();

    PlatformAdminApi.BlockingReasonGroupSearchResponse = (function() {

        /**
         * Properties of a BlockingReasonGroupSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupSearchResponse
         * @property {Array.<PlatformAdminApi.IBlockingReasonGroup>|null} [data] BlockingReasonGroupSearchResponse data
         * @property {number|null} [page] BlockingReasonGroupSearchResponse page
         * @property {number|null} [pageCount] BlockingReasonGroupSearchResponse pageCount
         * @property {number|null} [limit] BlockingReasonGroupSearchResponse limit
         * @property {number|null} [count] BlockingReasonGroupSearchResponse count
         * @property {number|null} [total] BlockingReasonGroupSearchResponse total
         */

        /**
         * Constructs a new BlockingReasonGroupSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE при запросе для всех групп причин блокировки
         * @implements IBlockingReasonGroupSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupSearchResponse=} [properties] Properties to set
         */
        function BlockingReasonGroupSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupSearchResponse data.
         * @member {Array.<PlatformAdminApi.IBlockingReasonGroup>} data
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @instance
         */
        BlockingReasonGroupSearchResponse.prototype.data = $util.emptyArray;

        /**
         * BlockingReasonGroupSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @instance
         */
        BlockingReasonGroupSearchResponse.prototype.page = 0;

        /**
         * BlockingReasonGroupSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @instance
         */
        BlockingReasonGroupSearchResponse.prototype.pageCount = 0;

        /**
         * BlockingReasonGroupSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @instance
         */
        BlockingReasonGroupSearchResponse.prototype.limit = 0;

        /**
         * BlockingReasonGroupSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @instance
         */
        BlockingReasonGroupSearchResponse.prototype.count = 0;

        /**
         * BlockingReasonGroupSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @instance
         */
        BlockingReasonGroupSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified BlockingReasonGroupSearchResponse message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupSearchResponse} message BlockingReasonGroupSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.BlockingReasonGroup.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupSearchResponse} BlockingReasonGroupSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.BlockingReasonGroup.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupSearchResponse;
    })();

    PlatformAdminApi.BlockingReasonGroup = (function() {

        /**
         * Properties of a BlockingReasonGroup.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroup
         * @property {string|null} [id] BlockingReasonGroup id
         * @property {string|null} [name] BlockingReasonGroup name
         * @property {string|null} [createdAt] BlockingReasonGroup createdAt
         * @property {string|null} [updatedAt] BlockingReasonGroup updatedAt
         * @property {boolean|null} [isDeleted] BlockingReasonGroup isDeleted
         * @property {string|null} [deletedAt] BlockingReasonGroup deletedAt
         */

        /**
         * Constructs a new BlockingReasonGroup.
         * @memberof PlatformAdminApi
         * @classdesc Модель BlockingReasonGroup
         * @implements IBlockingReasonGroup
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroup=} [properties] Properties to set
         */
        function BlockingReasonGroup(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroup id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @instance
         */
        BlockingReasonGroup.prototype.id = "";

        /**
         * BlockingReasonGroup name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @instance
         */
        BlockingReasonGroup.prototype.name = "";

        /**
         * BlockingReasonGroup createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @instance
         */
        BlockingReasonGroup.prototype.createdAt = "";

        /**
         * BlockingReasonGroup updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @instance
         */
        BlockingReasonGroup.prototype.updatedAt = "";

        /**
         * BlockingReasonGroup isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @instance
         */
        BlockingReasonGroup.prototype.isDeleted = false;

        /**
         * BlockingReasonGroup deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @instance
         */
        BlockingReasonGroup.prototype.deletedAt = "";

        /**
         * Encodes the specified BlockingReasonGroup message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroup.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroup} message BlockingReasonGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroup message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroup} BlockingReasonGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroup();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.createdAt = reader.string();
                    break;
                case 4:
                    message.updatedAt = reader.string();
                    break;
                case 5:
                    message.isDeleted = reader.bool();
                    break;
                case 6:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroup;
    })();

    PlatformAdminApi.BlockingReasonGroupUpdateRequest = (function() {

        /**
         * Properties of a BlockingReasonGroupUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupUpdateRequest
         * @property {string|null} [id] BlockingReasonGroupUpdateRequest id
         * @property {string|null} [name] BlockingReasonGroupUpdateRequest name
         */

        /**
         * Constructs a new BlockingReasonGroupUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса редактирования группы причин блокировки
         * @implements IBlockingReasonGroupUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupUpdateRequest=} [properties] Properties to set
         */
        function BlockingReasonGroupUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonGroupUpdateRequest
         * @instance
         */
        BlockingReasonGroupUpdateRequest.prototype.id = "";

        /**
         * BlockingReasonGroupUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonGroupUpdateRequest
         * @instance
         */
        BlockingReasonGroupUpdateRequest.prototype.name = "";

        /**
         * Encodes the specified BlockingReasonGroupUpdateRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupUpdateRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupUpdateRequest} message BlockingReasonGroupUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupUpdateRequest} BlockingReasonGroupUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupUpdateRequest;
    })();

    PlatformAdminApi.BlockingReasonGroupRequest = (function() {

        /**
         * Properties of a BlockingReasonGroupRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupRequest
         * @property {string|null} [id] BlockingReasonGroupRequest id
         */

        /**
         * Constructs a new BlockingReasonGroupRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса группы причин блокировки по id
         * @implements IBlockingReasonGroupRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupRequest=} [properties] Properties to set
         */
        function BlockingReasonGroupRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonGroupRequest
         * @instance
         */
        BlockingReasonGroupRequest.prototype.id = "";

        /**
         * Encodes the specified BlockingReasonGroupRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupRequest} message BlockingReasonGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupRequest} BlockingReasonGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupRequest;
    })();

    PlatformAdminApi.BlockingReasonGroupCreateRequest = (function() {

        /**
         * Properties of a BlockingReasonGroupCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupCreateRequest
         * @property {string|null} [name] BlockingReasonGroupCreateRequest name
         */

        /**
         * Constructs a new BlockingReasonGroupCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса создания группы причин блокировки
         * @implements IBlockingReasonGroupCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupCreateRequest=} [properties] Properties to set
         */
        function BlockingReasonGroupCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonGroupCreateRequest
         * @instance
         */
        BlockingReasonGroupCreateRequest.prototype.name = "";

        /**
         * Encodes the specified BlockingReasonGroupCreateRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupCreateRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupCreateRequest} message BlockingReasonGroupCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupCreateRequest} BlockingReasonGroupCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupCreateRequest;
    })();

    PlatformAdminApi.BlockingReasonGroupRemoveRequest = (function() {

        /**
         * Properties of a BlockingReasonGroupRemoveRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupRemoveRequest
         * @property {string|null} [id] BlockingReasonGroupRemoveRequest id
         */

        /**
         * Constructs a new BlockingReasonGroupRemoveRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса удаления группы причин блокировки
         * @implements IBlockingReasonGroupRemoveRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupRemoveRequest=} [properties] Properties to set
         */
        function BlockingReasonGroupRemoveRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupRemoveRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonGroupRemoveRequest
         * @instance
         */
        BlockingReasonGroupRemoveRequest.prototype.id = "";

        /**
         * Encodes the specified BlockingReasonGroupRemoveRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupRemoveRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupRemoveRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupRemoveRequest} message BlockingReasonGroupRemoveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupRemoveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupRemoveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupRemoveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupRemoveRequest} BlockingReasonGroupRemoveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupRemoveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupRemoveRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupRemoveRequest;
    })();

    PlatformAdminApi.BlockingReasonGroupRestoreRequest = (function() {

        /**
         * Properties of a BlockingReasonGroupRestoreRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupRestoreRequest
         * @property {string|null} [id] BlockingReasonGroupRestoreRequest id
         */

        /**
         * Constructs a new BlockingReasonGroupRestoreRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса восстановления группы причин блокировки
         * @implements IBlockingReasonGroupRestoreRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupRestoreRequest=} [properties] Properties to set
         */
        function BlockingReasonGroupRestoreRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupRestoreRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonGroupRestoreRequest
         * @instance
         */
        BlockingReasonGroupRestoreRequest.prototype.id = "";

        /**
         * Encodes the specified BlockingReasonGroupRestoreRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupRestoreRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupRestoreRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupRestoreRequest} message BlockingReasonGroupRestoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupRestoreRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupRestoreRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupRestoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupRestoreRequest} BlockingReasonGroupRestoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupRestoreRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupRestoreRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupRestoreRequest;
    })();

    PlatformAdminApi.BlockingReasonGroupResponse = (function() {

        /**
         * Properties of a BlockingReasonGroupResponse.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupResponse
         * @property {PlatformAdminApi.IBlockingReasonGroup|null} [data] BlockingReasonGroupResponse data
         */

        /**
         * Constructs a new BlockingReasonGroupResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE для группы причин блокировки
         * @implements IBlockingReasonGroupResponse
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupResponse=} [properties] Properties to set
         */
        function BlockingReasonGroupResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupResponse data.
         * @member {PlatformAdminApi.IBlockingReasonGroup|null|undefined} data
         * @memberof PlatformAdminApi.BlockingReasonGroupResponse
         * @instance
         */
        BlockingReasonGroupResponse.prototype.data = null;

        /**
         * Encodes the specified BlockingReasonGroupResponse message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupResponse
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupResponse} message BlockingReasonGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.BlockingReasonGroup.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupResponse} BlockingReasonGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.BlockingReasonGroup.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupResponse;
    })();

    PlatformAdminApi.BlockingReasonGroupRelation = (function() {

        /**
         * Properties of a BlockingReasonGroupRelation.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupRelation
         * @property {string|null} [id] BlockingReasonGroupRelation id
         * @property {string|null} [name] BlockingReasonGroupRelation name
         */

        /**
         * Constructs a new BlockingReasonGroupRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonGroupRelation.
         * @implements IBlockingReasonGroupRelation
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupRelation=} [properties] Properties to set
         */
        function BlockingReasonGroupRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonGroupRelation
         * @instance
         */
        BlockingReasonGroupRelation.prototype.id = "";

        /**
         * BlockingReasonGroupRelation name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonGroupRelation
         * @instance
         */
        BlockingReasonGroupRelation.prototype.name = "";

        /**
         * Encodes the specified BlockingReasonGroupRelation message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupRelation
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupRelation} message BlockingReasonGroupRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupRelation} BlockingReasonGroupRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupRelation;
    })();

    PlatformAdminApi.SkillService = (function() {

        /**
         * Constructs a new SkillService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkillService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SkillService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SkillService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SkillService;

        /**
         * Callback as used by {@link PlatformAdminApi.SkillService#create}.
         * @memberof PlatformAdminApi.SkillService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkillResponse} [response] SkillResponse
         */

        /**
         * Запрос на создание нового объекта Skill
         * @function create
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillCreateRequest} request SkillCreateRequest message or plain object
         * @param {PlatformAdminApi.SkillService.CreateCallback} callback Node-style callback called with the error, if any, and SkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkillService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.SkillCreateRequest, $root.PlatformAdminApi.SkillResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Запрос на создание нового объекта Skill
         * @function create
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillCreateRequest} request SkillCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkillService#update}.
         * @memberof PlatformAdminApi.SkillService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkillResponse} [response] SkillResponse
         */

        /**
         * Запрос на обновление параметров объекта с указанным id
         * @function update
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillUpdateRequest} request SkillUpdateRequest message or plain object
         * @param {PlatformAdminApi.SkillService.UpdateCallback} callback Node-style callback called with the error, if any, and SkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkillService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.SkillUpdateRequest, $root.PlatformAdminApi.SkillResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Запрос на обновление параметров объекта с указанным id
         * @function update
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillUpdateRequest} request SkillUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkillService#findById}.
         * @memberof PlatformAdminApi.SkillService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkillResponse} [response] SkillResponse
         */

        /**
         * Запрос на получение объекта Skill по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.SkillService.FindByIdCallback} callback Node-style callback called with the error, if any, and SkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkillService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.SkillResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Запрос на получение объекта Skill по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkillService#search}.
         * @memberof PlatformAdminApi.SkillService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkillSearchResponse} [response] SkillSearchResponse
         */

        /**
         * Запрос на поиск всех объектов Skill по параметрам
         * @function search
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillSearchRequest} request SkillSearchRequest message or plain object
         * @param {PlatformAdminApi.SkillService.SearchCallback} callback Node-style callback called with the error, if any, and SkillSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkillService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SkillSearchRequest, $root.PlatformAdminApi.SkillSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Запрос на поиск всех объектов Skill по параметрам
         * @function search
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillSearchRequest} request SkillSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkillSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkillService#remove}.
         * @memberof PlatformAdminApi.SkillService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkillResponse} [response] SkillResponse
         */

        /**
         * Запрос на удаление объекта Skill по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillRemoveRequest} request SkillRemoveRequest message or plain object
         * @param {PlatformAdminApi.SkillService.RemoveCallback} callback Node-style callback called with the error, if any, and SkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkillService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.SkillRemoveRequest, $root.PlatformAdminApi.SkillResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Запрос на удаление объекта Skill по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillRemoveRequest} request SkillRemoveRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkillService#restore}.
         * @memberof PlatformAdminApi.SkillService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkillResponse} [response] SkillResponse
         */

        /**
         * Запрос на восстановление объекта Skill по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillRemoveRequest} request SkillRemoveRequest message or plain object
         * @param {PlatformAdminApi.SkillService.RestoreCallback} callback Node-style callback called with the error, if any, and SkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkillService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.SkillRemoveRequest, $root.PlatformAdminApi.SkillResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Запрос на восстановление объекта Skill по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.SkillService
         * @instance
         * @param {PlatformAdminApi.ISkillRemoveRequest} request SkillRemoveRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkillResponse>} Promise
         * @variation 2
         */

        return SkillService;
    })();

    PlatformAdminApi.SkillCreateRequest = (function() {

        /**
         * Properties of a SkillCreateRequest.
         * @memberof PlatformAdminApi
         * @interface ISkillCreateRequest
         * @property {string|null} [title] SkillCreateRequest title
         * @property {string|null} [name] SkillCreateRequest name
         * @property {Array.<string>|null} [optionsSlugs] SkillCreateRequest optionsSlugs
         */

        /**
         * Constructs a new SkillCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Запрос на создание объекта Skill
         * @implements ISkillCreateRequest
         * @constructor
         * @param {PlatformAdminApi.ISkillCreateRequest=} [properties] Properties to set
         */
        function SkillCreateRequest(properties) {
            this.optionsSlugs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillCreateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.SkillCreateRequest
         * @instance
         */
        SkillCreateRequest.prototype.title = "";

        /**
         * SkillCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.SkillCreateRequest
         * @instance
         */
        SkillCreateRequest.prototype.name = "";

        /**
         * SkillCreateRequest optionsSlugs.
         * @member {Array.<string>} optionsSlugs
         * @memberof PlatformAdminApi.SkillCreateRequest
         * @instance
         */
        SkillCreateRequest.prototype.optionsSlugs = $util.emptyArray;

        /**
         * Encodes the specified SkillCreateRequest message. Does not implicitly {@link PlatformAdminApi.SkillCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillCreateRequest
         * @static
         * @param {PlatformAdminApi.ISkillCreateRequest} message SkillCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.optionsSlugs != null && message.optionsSlugs.length)
                for (var i = 0; i < message.optionsSlugs.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.optionsSlugs[i]);
            return writer;
        };

        /**
         * Decodes a SkillCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillCreateRequest} SkillCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.optionsSlugs && message.optionsSlugs.length))
                        message.optionsSlugs = [];
                    message.optionsSlugs.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillCreateRequest;
    })();

    PlatformAdminApi.Skill = (function() {

        /**
         * Properties of a Skill.
         * @memberof PlatformAdminApi
         * @interface ISkill
         * @property {string|null} [id] Skill id
         * @property {string|null} [title] Skill title
         * @property {string|null} [name] Skill name
         * @property {Array.<PlatformAdminApi.IOption>|null} [options] Skill options
         * @property {string|null} [createdAt] Skill createdAt
         * @property {string|null} [updatedAt] Skill updatedAt
         * @property {boolean|null} [isDeleted] Skill isDeleted
         * @property {string|null} [deletedAt] Skill deletedAt
         */

        /**
         * Constructs a new Skill.
         * @memberof PlatformAdminApi
         * @classdesc Модель Skill
         * @implements ISkill
         * @constructor
         * @param {PlatformAdminApi.ISkill=} [properties] Properties to set
         */
        function Skill(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Skill id.
         * @member {string} id
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.id = "";

        /**
         * Skill title.
         * @member {string} title
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.title = "";

        /**
         * Skill name.
         * @member {string} name
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.name = "";

        /**
         * Skill options.
         * @member {Array.<PlatformAdminApi.IOption>} options
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.options = $util.emptyArray;

        /**
         * Skill createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.createdAt = "";

        /**
         * Skill updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.updatedAt = "";

        /**
         * Skill isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.isDeleted = false;

        /**
         * Skill deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Skill
         * @instance
         */
        Skill.prototype.deletedAt = "";

        /**
         * Encodes the specified Skill message. Does not implicitly {@link PlatformAdminApi.Skill.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Skill
         * @static
         * @param {PlatformAdminApi.ISkill} message Skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.Option.encode(message.options[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Skill message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Skill} Skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Skill();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.Option.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.createdAt = reader.string();
                    break;
                case 6:
                    message.updatedAt = reader.string();
                    break;
                case 7:
                    message.isDeleted = reader.bool();
                    break;
                case 8:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Skill;
    })();

    PlatformAdminApi.SkillResponse = (function() {

        /**
         * Properties of a SkillResponse.
         * @memberof PlatformAdminApi
         * @interface ISkillResponse
         * @property {PlatformAdminApi.ISkill|null} [data] SkillResponse data
         */

        /**
         * Constructs a new SkillResponse.
         * @memberof PlatformAdminApi
         * @classdesc ответ на запрос возращающий модель Skill
         * @implements ISkillResponse
         * @constructor
         * @param {PlatformAdminApi.ISkillResponse=} [properties] Properties to set
         */
        function SkillResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillResponse data.
         * @member {PlatformAdminApi.ISkill|null|undefined} data
         * @memberof PlatformAdminApi.SkillResponse
         * @instance
         */
        SkillResponse.prototype.data = null;

        /**
         * Encodes the specified SkillResponse message. Does not implicitly {@link PlatformAdminApi.SkillResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillResponse
         * @static
         * @param {PlatformAdminApi.ISkillResponse} message SkillResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Skill.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SkillResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillResponse} SkillResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Skill.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillResponse;
    })();

    PlatformAdminApi.SkillUpdateRequest = (function() {

        /**
         * Properties of a SkillUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface ISkillUpdateRequest
         * @property {string|null} [id] SkillUpdateRequest id
         * @property {string|null} [title] SkillUpdateRequest title
         * @property {string|null} [name] SkillUpdateRequest name
         * @property {Array.<string>|null} [optionsSlugs] SkillUpdateRequest optionsSlugs
         */

        /**
         * Constructs a new SkillUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Запрос на изменение Skill
         * @implements ISkillUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.ISkillUpdateRequest=} [properties] Properties to set
         */
        function SkillUpdateRequest(properties) {
            this.optionsSlugs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.SkillUpdateRequest
         * @instance
         */
        SkillUpdateRequest.prototype.id = "";

        /**
         * SkillUpdateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.SkillUpdateRequest
         * @instance
         */
        SkillUpdateRequest.prototype.title = "";

        /**
         * SkillUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.SkillUpdateRequest
         * @instance
         */
        SkillUpdateRequest.prototype.name = "";

        /**
         * SkillUpdateRequest optionsSlugs.
         * @member {Array.<string>} optionsSlugs
         * @memberof PlatformAdminApi.SkillUpdateRequest
         * @instance
         */
        SkillUpdateRequest.prototype.optionsSlugs = $util.emptyArray;

        /**
         * Encodes the specified SkillUpdateRequest message. Does not implicitly {@link PlatformAdminApi.SkillUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillUpdateRequest
         * @static
         * @param {PlatformAdminApi.ISkillUpdateRequest} message SkillUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.optionsSlugs != null && message.optionsSlugs.length)
                for (var i = 0; i < message.optionsSlugs.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.optionsSlugs[i]);
            return writer;
        };

        /**
         * Decodes a SkillUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillUpdateRequest} SkillUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    if (!(message.optionsSlugs && message.optionsSlugs.length))
                        message.optionsSlugs = [];
                    message.optionsSlugs.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillUpdateRequest;
    })();

    PlatformAdminApi.SkillRemoveRequest = (function() {

        /**
         * Properties of a SkillRemoveRequest.
         * @memberof PlatformAdminApi
         * @interface ISkillRemoveRequest
         * @property {string|null} [id] SkillRemoveRequest id
         */

        /**
         * Constructs a new SkillRemoveRequest.
         * @memberof PlatformAdminApi
         * @classdesc Запрос на удаление Skill
         * @implements ISkillRemoveRequest
         * @constructor
         * @param {PlatformAdminApi.ISkillRemoveRequest=} [properties] Properties to set
         */
        function SkillRemoveRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillRemoveRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.SkillRemoveRequest
         * @instance
         */
        SkillRemoveRequest.prototype.id = "";

        /**
         * Encodes the specified SkillRemoveRequest message. Does not implicitly {@link PlatformAdminApi.SkillRemoveRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillRemoveRequest
         * @static
         * @param {PlatformAdminApi.ISkillRemoveRequest} message SkillRemoveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillRemoveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a SkillRemoveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillRemoveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillRemoveRequest} SkillRemoveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillRemoveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillRemoveRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillRemoveRequest;
    })();

    PlatformAdminApi.SkillRestoreRequest = (function() {

        /**
         * Properties of a SkillRestoreRequest.
         * @memberof PlatformAdminApi
         * @interface ISkillRestoreRequest
         * @property {string|null} [id] SkillRestoreRequest id
         */

        /**
         * Constructs a new SkillRestoreRequest.
         * @memberof PlatformAdminApi
         * @classdesc Запрос на восстановление Skill
         * @implements ISkillRestoreRequest
         * @constructor
         * @param {PlatformAdminApi.ISkillRestoreRequest=} [properties] Properties to set
         */
        function SkillRestoreRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillRestoreRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.SkillRestoreRequest
         * @instance
         */
        SkillRestoreRequest.prototype.id = "";

        /**
         * Encodes the specified SkillRestoreRequest message. Does not implicitly {@link PlatformAdminApi.SkillRestoreRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillRestoreRequest
         * @static
         * @param {PlatformAdminApi.ISkillRestoreRequest} message SkillRestoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillRestoreRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a SkillRestoreRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillRestoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillRestoreRequest} SkillRestoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillRestoreRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillRestoreRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillRestoreRequest;
    })();

    PlatformAdminApi.SkillSearchRequest = (function() {

        /**
         * Properties of a SkillSearchRequest.
         * @memberof PlatformAdminApi
         * @interface ISkillSearchRequest
         * @property {number|null} [page] SkillSearchRequest page
         * @property {number|null} [limit] SkillSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SkillSearchRequest sort
         * @property {Array.<PlatformAdminApi.SkillSearchRequest.IWhereFields>|null} [where] SkillSearchRequest where
         */

        /**
         * Constructs a new SkillSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Запрос всех объектов Skill по параметрам
         * @implements ISkillSearchRequest
         * @constructor
         * @param {PlatformAdminApi.ISkillSearchRequest=} [properties] Properties to set
         */
        function SkillSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SkillSearchRequest
         * @instance
         */
        SkillSearchRequest.prototype.page = 0;

        /**
         * SkillSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SkillSearchRequest
         * @instance
         */
        SkillSearchRequest.prototype.limit = 0;

        /**
         * SkillSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SkillSearchRequest
         * @instance
         */
        SkillSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * SkillSearchRequest where.
         * @member {Array.<PlatformAdminApi.SkillSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SkillSearchRequest
         * @instance
         */
        SkillSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SkillSearchRequest message. Does not implicitly {@link PlatformAdminApi.SkillSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillSearchRequest
         * @static
         * @param {PlatformAdminApi.ISkillSearchRequest} message SkillSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SkillSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SkillSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillSearchRequest} SkillSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SkillSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SkillSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SkillSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SkillSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SkillSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SkillSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SkillSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SkillSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SkillSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 5:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SkillSearchRequest;
    })();

    PlatformAdminApi.SkillSearchResponse = (function() {

        /**
         * Properties of a SkillSearchResponse.
         * @memberof PlatformAdminApi
         * @interface ISkillSearchResponse
         * @property {Array.<PlatformAdminApi.ISkill>|null} [data] SkillSearchResponse data
         * @property {number|null} [page] SkillSearchResponse page
         * @property {number|null} [limit] SkillSearchResponse limit
         * @property {number|null} [total] SkillSearchResponse total
         */

        /**
         * Constructs a new SkillSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Ответ на запрос все объектов Skill по параметрам
         * @implements ISkillSearchResponse
         * @constructor
         * @param {PlatformAdminApi.ISkillSearchResponse=} [properties] Properties to set
         */
        function SkillSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillSearchResponse data.
         * @member {Array.<PlatformAdminApi.ISkill>} data
         * @memberof PlatformAdminApi.SkillSearchResponse
         * @instance
         */
        SkillSearchResponse.prototype.data = $util.emptyArray;

        /**
         * SkillSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SkillSearchResponse
         * @instance
         */
        SkillSearchResponse.prototype.page = 0;

        /**
         * SkillSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SkillSearchResponse
         * @instance
         */
        SkillSearchResponse.prototype.limit = 0;

        /**
         * SkillSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SkillSearchResponse
         * @instance
         */
        SkillSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified SkillSearchResponse message. Does not implicitly {@link PlatformAdminApi.SkillSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillSearchResponse
         * @static
         * @param {PlatformAdminApi.ISkillSearchResponse} message SkillSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Skill.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SkillSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillSearchResponse} SkillSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Skill.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillSearchResponse;
    })();

    PlatformAdminApi.SkillRelation = (function() {

        /**
         * Properties of a SkillRelation.
         * @memberof PlatformAdminApi
         * @interface ISkillRelation
         * @property {string|null} [id] SkillRelation id
         * @property {string|null} [title] SkillRelation title
         * @property {string|null} [name] SkillRelation name
         * @property {Array.<string>|null} [optionsSlugs] SkillRelation optionsSlugs
         */

        /**
         * Constructs a new SkillRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkillRelation.
         * @implements ISkillRelation
         * @constructor
         * @param {PlatformAdminApi.ISkillRelation=} [properties] Properties to set
         */
        function SkillRelation(properties) {
            this.optionsSlugs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.SkillRelation
         * @instance
         */
        SkillRelation.prototype.id = "";

        /**
         * SkillRelation title.
         * @member {string} title
         * @memberof PlatformAdminApi.SkillRelation
         * @instance
         */
        SkillRelation.prototype.title = "";

        /**
         * SkillRelation name.
         * @member {string} name
         * @memberof PlatformAdminApi.SkillRelation
         * @instance
         */
        SkillRelation.prototype.name = "";

        /**
         * SkillRelation optionsSlugs.
         * @member {Array.<string>} optionsSlugs
         * @memberof PlatformAdminApi.SkillRelation
         * @instance
         */
        SkillRelation.prototype.optionsSlugs = $util.emptyArray;

        /**
         * Encodes the specified SkillRelation message. Does not implicitly {@link PlatformAdminApi.SkillRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillRelation
         * @static
         * @param {PlatformAdminApi.ISkillRelation} message SkillRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.optionsSlugs != null && message.optionsSlugs.length)
                for (var i = 0; i < message.optionsSlugs.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.optionsSlugs[i]);
            return writer;
        };

        /**
         * Decodes a SkillRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillRelation} SkillRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    if (!(message.optionsSlugs && message.optionsSlugs.length))
                        message.optionsSlugs = [];
                    message.optionsSlugs.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillRelation;
    })();

    PlatformAdminApi.PriceElementService = (function() {

        /**
         * Constructs a new PriceElementService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PriceElementService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PriceElementService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PriceElementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PriceElementService;

        /**
         * Callback as used by {@link PlatformAdminApi.PriceElementService#search}.
         * @memberof PlatformAdminApi.PriceElementService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchPriceElementResponse} [response] SearchPriceElementResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PriceElementService
         * @instance
         * @param {PlatformAdminApi.ISearchPriceElementRequest} request SearchPriceElementRequest message or plain object
         * @param {PlatformAdminApi.PriceElementService.SearchCallback} callback Node-style callback called with the error, if any, and SearchPriceElementResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PriceElementService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchPriceElementRequest, $root.PlatformAdminApi.SearchPriceElementResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PriceElementService
         * @instance
         * @param {PlatformAdminApi.ISearchPriceElementRequest} request SearchPriceElementRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchPriceElementResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PriceElementService#findById}.
         * @memberof PlatformAdminApi.PriceElementService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PriceElementResponse} [response] PriceElementResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PriceElementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PriceElementService.FindByIdCallback} callback Node-style callback called with the error, if any, and PriceElementResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PriceElementService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PriceElementResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PriceElementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PriceElementResponse>} Promise
         * @variation 2
         */

        return PriceElementService;
    })();

    PlatformAdminApi.Technological = (function() {

        /**
         * Properties of a Technological.
         * @memberof PlatformAdminApi
         * @interface ITechnological
         * @property {string|null} [id] Technological id
         * @property {string|null} [title] Technological title
         * @property {string|null} [name] Technological name
         */

        /**
         * Constructs a new Technological.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Technological.
         * @implements ITechnological
         * @constructor
         * @param {PlatformAdminApi.ITechnological=} [properties] Properties to set
         */
        function Technological(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Technological id.
         * @member {string} id
         * @memberof PlatformAdminApi.Technological
         * @instance
         */
        Technological.prototype.id = "";

        /**
         * Technological title.
         * @member {string} title
         * @memberof PlatformAdminApi.Technological
         * @instance
         */
        Technological.prototype.title = "";

        /**
         * Technological name.
         * @member {string} name
         * @memberof PlatformAdminApi.Technological
         * @instance
         */
        Technological.prototype.name = "";

        /**
         * Encodes the specified Technological message. Does not implicitly {@link PlatformAdminApi.Technological.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Technological
         * @static
         * @param {PlatformAdminApi.ITechnological} message Technological message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Technological.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a Technological message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Technological
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Technological} Technological
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Technological.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Technological();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Technological;
    })();

    PlatformAdminApi.Option = (function() {

        /**
         * Properties of an Option.
         * @memberof PlatformAdminApi
         * @interface IOption
         * @property {string|null} [id] Option id
         * @property {string|null} [title] Option title
         * @property {string|null} [name] Option name
         * @property {string|null} [shortDesc] Option shortDesc
         * @property {string|null} [createdAt] Option createdAt
         * @property {string|null} [updatedAt] Option updatedAt
         * @property {boolean|null} [isDeleted] Option isDeleted
         * @property {string|null} [elementId] Option elementId
         * @property {number|null} [order] Option order
         * @property {Array.<PlatformAdminApi.IOrderedTag>|null} [tags] Option tags
         * @property {Array.<PlatformAdminApi.ITechnological>|null} [technological] Option technological
         */

        /**
         * Constructs a new Option.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Option.
         * @implements IOption
         * @constructor
         * @param {PlatformAdminApi.IOption=} [properties] Properties to set
         */
        function Option(properties) {
            this.tags = [];
            this.technological = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Option id.
         * @member {string} id
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.id = "";

        /**
         * Option title.
         * @member {string} title
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.title = "";

        /**
         * Option name.
         * @member {string} name
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.name = "";

        /**
         * Option shortDesc.
         * @member {string} shortDesc
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.shortDesc = "";

        /**
         * Option createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.createdAt = "";

        /**
         * Option updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.updatedAt = "";

        /**
         * Option isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.isDeleted = false;

        /**
         * Option elementId.
         * @member {string} elementId
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.elementId = "";

        /**
         * Option order.
         * @member {number} order
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.order = 0;

        /**
         * Option tags.
         * @member {Array.<PlatformAdminApi.IOrderedTag>} tags
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.tags = $util.emptyArray;

        /**
         * Option technological.
         * @member {Array.<PlatformAdminApi.ITechnological>} technological
         * @memberof PlatformAdminApi.Option
         * @instance
         */
        Option.prototype.technological = $util.emptyArray;

        /**
         * Encodes the specified Option message. Does not implicitly {@link PlatformAdminApi.Option.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Option
         * @static
         * @param {PlatformAdminApi.IOption} message Option message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Option.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.shortDesc != null && Object.hasOwnProperty.call(message, "shortDesc"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortDesc);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
            if (message.elementId != null && Object.hasOwnProperty.call(message, "elementId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.elementId);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.order);
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.PlatformAdminApi.OrderedTag.encode(message.tags[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.technological != null && message.technological.length)
                for (var i = 0; i < message.technological.length; ++i)
                    $root.PlatformAdminApi.Technological.encode(message.technological[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Option message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Option
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Option} Option
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Option.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Option();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.shortDesc = reader.string();
                    break;
                case 5:
                    message.createdAt = reader.string();
                    break;
                case 6:
                    message.updatedAt = reader.string();
                    break;
                case 7:
                    message.isDeleted = reader.bool();
                    break;
                case 8:
                    message.elementId = reader.string();
                    break;
                case 9:
                    message.order = reader.int32();
                    break;
                case 10:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.PlatformAdminApi.OrderedTag.decode(reader, reader.uint32()));
                    break;
                case 11:
                    if (!(message.technological && message.technological.length))
                        message.technological = [];
                    message.technological.push($root.PlatformAdminApi.Technological.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Option;
    })();

    /**
     * MatrixUnit enum.
     * @name PlatformAdminApi.MatrixUnit
     * @enum {number}
     * @property {number} UNIT=1 UNIT value
     * @property {number} SERVICE=2 SERVICE value
     */
    PlatformAdminApi.MatrixUnit = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "UNIT"] = 1;
        values[valuesById[2] = "SERVICE"] = 2;
        return values;
    })();

    /**
     * PriceOptionType enum.
     * @name PlatformAdminApi.PriceOptionType
     * @enum {number}
     * @property {number} BASE=1 BASE value
     * @property {number} OPTION=2 OPTION value
     */
    PlatformAdminApi.PriceOptionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "BASE"] = 1;
        values[valuesById[2] = "OPTION"] = 2;
        return values;
    })();

    /**
     * DisplayOption enum.
     * @name PlatformAdminApi.DisplayOption
     * @enum {number}
     * @property {number} CHECKBOX=1 CHECKBOX value
     * @property {number} LIST=2 LIST value
     * @property {number} READONLY=3 READONLY value
     * @property {number} NUMBER=4 NUMBER value
     */
    PlatformAdminApi.DisplayOption = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "CHECKBOX"] = 1;
        values[valuesById[2] = "LIST"] = 2;
        values[valuesById[3] = "READONLY"] = 3;
        values[valuesById[4] = "NUMBER"] = 4;
        return values;
    })();

    PlatformAdminApi.MatrixItem = (function() {

        /**
         * Properties of a MatrixItem.
         * @memberof PlatformAdminApi
         * @interface IMatrixItem
         * @property {string|null} [range] MatrixItem range
         * @property {number|null} [value] MatrixItem value
         */

        /**
         * Constructs a new MatrixItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a MatrixItem.
         * @implements IMatrixItem
         * @constructor
         * @param {PlatformAdminApi.IMatrixItem=} [properties] Properties to set
         */
        function MatrixItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MatrixItem range.
         * @member {string} range
         * @memberof PlatformAdminApi.MatrixItem
         * @instance
         */
        MatrixItem.prototype.range = "";

        /**
         * MatrixItem value.
         * @member {number} value
         * @memberof PlatformAdminApi.MatrixItem
         * @instance
         */
        MatrixItem.prototype.value = 0;

        /**
         * Encodes the specified MatrixItem message. Does not implicitly {@link PlatformAdminApi.MatrixItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.MatrixItem
         * @static
         * @param {PlatformAdminApi.IMatrixItem} message MatrixItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatrixItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.range);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            return writer;
        };

        /**
         * Decodes a MatrixItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.MatrixItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.MatrixItem} MatrixItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatrixItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.MatrixItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.range = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return MatrixItem;
    })();

    PlatformAdminApi.Matrix = (function() {

        /**
         * Properties of a Matrix.
         * @memberof PlatformAdminApi
         * @interface IMatrix
         * @property {PlatformAdminApi.MatrixUnit|null} [per] Matrix per
         * @property {Array.<PlatformAdminApi.IMatrixItem>|null} [items] Matrix items
         */

        /**
         * Constructs a new Matrix.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Matrix.
         * @implements IMatrix
         * @constructor
         * @param {PlatformAdminApi.IMatrix=} [properties] Properties to set
         */
        function Matrix(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Matrix per.
         * @member {PlatformAdminApi.MatrixUnit} per
         * @memberof PlatformAdminApi.Matrix
         * @instance
         */
        Matrix.prototype.per = 1;

        /**
         * Matrix items.
         * @member {Array.<PlatformAdminApi.IMatrixItem>} items
         * @memberof PlatformAdminApi.Matrix
         * @instance
         */
        Matrix.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified Matrix message. Does not implicitly {@link PlatformAdminApi.Matrix.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Matrix
         * @static
         * @param {PlatformAdminApi.IMatrix} message Matrix message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Matrix.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.per != null && Object.hasOwnProperty.call(message, "per"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.per);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformAdminApi.MatrixItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Matrix message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Matrix
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Matrix} Matrix
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Matrix.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Matrix();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.per = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformAdminApi.MatrixItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Matrix;
    })();

    PlatformAdminApi.PriceOption = (function() {

        /**
         * Properties of a PriceOption.
         * @memberof PlatformAdminApi
         * @interface IPriceOption
         * @property {string|null} [id] PriceOption id
         * @property {PlatformAdminApi.IOption|null} [option] PriceOption option
         * @property {PlatformAdminApi.IUnit|null} [unit] PriceOption unit
         * @property {PlatformAdminApi.PriceOptionType|null} [type] PriceOption type
         * @property {number|null} [costLinear] PriceOption costLinear
         * @property {PlatformAdminApi.IMatrix|null} [costMatrix] PriceOption costMatrix
         * @property {number|null} [durationLinear] PriceOption durationLinear
         * @property {PlatformAdminApi.IMatrix|null} [durationMatrix] PriceOption durationMatrix
         * @property {boolean|null} [isFree] PriceOption isFree
         * @property {PlatformAdminApi.DisplayOption|null} [display] PriceOption display
         * @property {number|null} [defaultValue] PriceOption defaultValue
         * @property {number|null} [minValue] PriceOption minValue
         * @property {number|null} [maxValue] PriceOption maxValue
         * @property {number|null} [defaultFactor] PriceOption defaultFactor
         * @property {number|null} [minFactor] PriceOption minFactor
         * @property {number|null} [maxFactor] PriceOption maxFactor
         */

        /**
         * Constructs a new PriceOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PriceOption.
         * @implements IPriceOption
         * @constructor
         * @param {PlatformAdminApi.IPriceOption=} [properties] Properties to set
         */
        function PriceOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceOption id.
         * @member {string} id
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.id = "";

        /**
         * PriceOption option.
         * @member {PlatformAdminApi.IOption|null|undefined} option
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.option = null;

        /**
         * PriceOption unit.
         * @member {PlatformAdminApi.IUnit|null|undefined} unit
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.unit = null;

        /**
         * PriceOption type.
         * @member {PlatformAdminApi.PriceOptionType} type
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.type = 1;

        /**
         * PriceOption costLinear.
         * @member {number} costLinear
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.costLinear = 0;

        /**
         * PriceOption costMatrix.
         * @member {PlatformAdminApi.IMatrix|null|undefined} costMatrix
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.costMatrix = null;

        /**
         * PriceOption durationLinear.
         * @member {number} durationLinear
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.durationLinear = 0;

        /**
         * PriceOption durationMatrix.
         * @member {PlatformAdminApi.IMatrix|null|undefined} durationMatrix
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.durationMatrix = null;

        /**
         * PriceOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.isFree = false;

        /**
         * PriceOption display.
         * @member {PlatformAdminApi.DisplayOption} display
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.display = 1;

        /**
         * PriceOption defaultValue.
         * @member {number} defaultValue
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.defaultValue = 0;

        /**
         * PriceOption minValue.
         * @member {number} minValue
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.minValue = 0;

        /**
         * PriceOption maxValue.
         * @member {number} maxValue
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.maxValue = 0;

        /**
         * PriceOption defaultFactor.
         * @member {number} defaultFactor
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.defaultFactor = 0;

        /**
         * PriceOption minFactor.
         * @member {number} minFactor
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.minFactor = 0;

        /**
         * PriceOption maxFactor.
         * @member {number} maxFactor
         * @memberof PlatformAdminApi.PriceOption
         * @instance
         */
        PriceOption.prototype.maxFactor = 0;

        /**
         * Encodes the specified PriceOption message. Does not implicitly {@link PlatformAdminApi.PriceOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PriceOption
         * @static
         * @param {PlatformAdminApi.IPriceOption} message PriceOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.Option.encode(message.option, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                $root.PlatformAdminApi.Unit.encode(message.unit, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
            if (message.costLinear != null && Object.hasOwnProperty.call(message, "costLinear"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.costLinear);
            if (message.costMatrix != null && Object.hasOwnProperty.call(message, "costMatrix"))
                $root.PlatformAdminApi.Matrix.encode(message.costMatrix, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.durationLinear != null && Object.hasOwnProperty.call(message, "durationLinear"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.durationLinear);
            if (message.durationMatrix != null && Object.hasOwnProperty.call(message, "durationMatrix"))
                $root.PlatformAdminApi.Matrix.encode(message.durationMatrix, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isFree);
            if (message.display != null && Object.hasOwnProperty.call(message, "display"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.display);
            if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.defaultValue);
            if (message.minValue != null && Object.hasOwnProperty.call(message, "minValue"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.minValue);
            if (message.maxValue != null && Object.hasOwnProperty.call(message, "maxValue"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.maxValue);
            if (message.defaultFactor != null && Object.hasOwnProperty.call(message, "defaultFactor"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.defaultFactor);
            if (message.minFactor != null && Object.hasOwnProperty.call(message, "minFactor"))
                writer.uint32(/* id 15, wireType 0 =*/120).int32(message.minFactor);
            if (message.maxFactor != null && Object.hasOwnProperty.call(message, "maxFactor"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.maxFactor);
            return writer;
        };

        /**
         * Decodes a PriceOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PriceOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PriceOption} PriceOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PriceOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.option = $root.PlatformAdminApi.Option.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.unit = $root.PlatformAdminApi.Unit.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                case 5:
                    message.costLinear = reader.int32();
                    break;
                case 6:
                    message.costMatrix = $root.PlatformAdminApi.Matrix.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.durationLinear = reader.int32();
                    break;
                case 8:
                    message.durationMatrix = $root.PlatformAdminApi.Matrix.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.isFree = reader.bool();
                    break;
                case 10:
                    message.display = reader.int32();
                    break;
                case 11:
                    message.defaultValue = reader.int32();
                    break;
                case 12:
                    message.minValue = reader.int32();
                    break;
                case 13:
                    message.maxValue = reader.int32();
                    break;
                case 14:
                    message.defaultFactor = reader.int32();
                    break;
                case 15:
                    message.minFactor = reader.int32();
                    break;
                case 16:
                    message.maxFactor = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceOption;
    })();

    PlatformAdminApi.PriceElement = (function() {

        /**
         * Properties of a PriceElement.
         * @memberof PlatformAdminApi
         * @interface IPriceElement
         * @property {string|null} [id] PriceElement id
         * @property {string|null} [createdAt] PriceElement createdAt
         * @property {string|null} [updatedAt] PriceElement updatedAt
         * @property {string|null} [deletedAt] PriceElement deletedAt
         * @property {boolean|null} [isDeleted] PriceElement isDeleted
         * @property {PlatformAdminApi.IRegion|null} [region] PriceElement region
         * @property {string|null} [dateStart] PriceElement dateStart
         * @property {string|null} [dateEnd] PriceElement dateEnd
         * @property {number|null} [cost] PriceElement cost
         * @property {number|null} [duration] PriceElement duration
         * @property {string|null} [elementId] PriceElement elementId
         * @property {Array.<PlatformAdminApi.IPriceOption>|null} [priceOptions] PriceElement priceOptions
         */

        /**
         * Constructs a new PriceElement.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PriceElement.
         * @implements IPriceElement
         * @constructor
         * @param {PlatformAdminApi.IPriceElement=} [properties] Properties to set
         */
        function PriceElement(properties) {
            this.priceOptions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElement id.
         * @member {string} id
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.id = "";

        /**
         * PriceElement createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.createdAt = "";

        /**
         * PriceElement updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.updatedAt = "";

        /**
         * PriceElement deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.deletedAt = "";

        /**
         * PriceElement isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.isDeleted = false;

        /**
         * PriceElement region.
         * @member {PlatformAdminApi.IRegion|null|undefined} region
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.region = null;

        /**
         * PriceElement dateStart.
         * @member {string} dateStart
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.dateStart = "";

        /**
         * PriceElement dateEnd.
         * @member {string} dateEnd
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.dateEnd = "";

        /**
         * PriceElement cost.
         * @member {number} cost
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.cost = 0;

        /**
         * PriceElement duration.
         * @member {number} duration
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.duration = 0;

        /**
         * PriceElement elementId.
         * @member {string} elementId
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.elementId = "";

        /**
         * PriceElement priceOptions.
         * @member {Array.<PlatformAdminApi.IPriceOption>} priceOptions
         * @memberof PlatformAdminApi.PriceElement
         * @instance
         */
        PriceElement.prototype.priceOptions = $util.emptyArray;

        /**
         * Encodes the specified PriceElement message. Does not implicitly {@link PlatformAdminApi.PriceElement.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PriceElement
         * @static
         * @param {PlatformAdminApi.IPriceElement} message PriceElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.deletedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                $root.PlatformAdminApi.Region.encode(message.region, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.dateStart != null && Object.hasOwnProperty.call(message, "dateStart"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.dateStart);
            if (message.dateEnd != null && Object.hasOwnProperty.call(message, "dateEnd"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.dateEnd);
            if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.cost);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.duration);
            if (message.elementId != null && Object.hasOwnProperty.call(message, "elementId"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.elementId);
            if (message.priceOptions != null && message.priceOptions.length)
                for (var i = 0; i < message.priceOptions.length; ++i)
                    $root.PlatformAdminApi.PriceOption.encode(message.priceOptions[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PriceElement message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PriceElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PriceElement} PriceElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PriceElement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.deletedAt = reader.string();
                    break;
                case 5:
                    message.isDeleted = reader.bool();
                    break;
                case 6:
                    message.region = $root.PlatformAdminApi.Region.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.dateStart = reader.string();
                    break;
                case 8:
                    message.dateEnd = reader.string();
                    break;
                case 9:
                    message.cost = reader.int32();
                    break;
                case 10:
                    message.duration = reader.int32();
                    break;
                case 11:
                    message.elementId = reader.string();
                    break;
                case 12:
                    if (!(message.priceOptions && message.priceOptions.length))
                        message.priceOptions = [];
                    message.priceOptions.push($root.PlatformAdminApi.PriceOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElement;
    })();

    PlatformAdminApi.SearchPriceElementRequest = (function() {

        /**
         * Properties of a SearchPriceElementRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchPriceElementRequest
         * @property {number|null} [page] SearchPriceElementRequest page
         * @property {number|null} [limit] SearchPriceElementRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchPriceElementRequest sort
         * @property {PlatformAdminApi.SearchPriceElementRequest.IWhereFields|null} [where] SearchPriceElementRequest where
         */

        /**
         * Constructs a new SearchPriceElementRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных прайс элементов
         * @implements ISearchPriceElementRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchPriceElementRequest=} [properties] Properties to set
         */
        function SearchPriceElementRequest(properties) {
            this.sort = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPriceElementRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPriceElementRequest
         * @instance
         */
        SearchPriceElementRequest.prototype.page = 0;

        /**
         * SearchPriceElementRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPriceElementRequest
         * @instance
         */
        SearchPriceElementRequest.prototype.limit = 0;

        /**
         * SearchPriceElementRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchPriceElementRequest
         * @instance
         */
        SearchPriceElementRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchPriceElementRequest where.
         * @member {PlatformAdminApi.SearchPriceElementRequest.IWhereFields|null|undefined} where
         * @memberof PlatformAdminApi.SearchPriceElementRequest
         * @instance
         */
        SearchPriceElementRequest.prototype.where = null;

        /**
         * Encodes the specified SearchPriceElementRequest message. Does not implicitly {@link PlatformAdminApi.SearchPriceElementRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPriceElementRequest
         * @static
         * @param {PlatformAdminApi.ISearchPriceElementRequest} message SearchPriceElementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPriceElementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && Object.hasOwnProperty.call(message, "where"))
                $root.PlatformAdminApi.SearchPriceElementRequest.WhereFields.encode(message.where, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchPriceElementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPriceElementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPriceElementRequest} SearchPriceElementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPriceElementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPriceElementRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.where = $root.PlatformAdminApi.SearchPriceElementRequest.WhereFields.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchPriceElementRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchPriceElementRequest
             * @interface IWhereFields
             * @property {string|null} [elementId] WhereFields elementId
             * @property {string|null} [regionName] WhereFields regionName
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchPriceElementRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchPriceElementRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields elementId.
             * @member {string} elementId
             * @memberof PlatformAdminApi.SearchPriceElementRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.elementId = "";

            /**
             * WhereFields regionName.
             * @member {string} regionName
             * @memberof PlatformAdminApi.SearchPriceElementRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.regionName = "";

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchPriceElementRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchPriceElementRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchPriceElementRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.elementId != null && Object.hasOwnProperty.call(message, "elementId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.elementId);
                if (message.regionName != null && Object.hasOwnProperty.call(message, "regionName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.regionName);
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchPriceElementRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchPriceElementRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPriceElementRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.elementId = reader.string();
                        break;
                    case 2:
                        message.regionName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchPriceElementRequest;
    })();

    PlatformAdminApi.SearchPriceElementResponse = (function() {

        /**
         * Properties of a SearchPriceElementResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchPriceElementResponse
         * @property {Array.<PlatformAdminApi.IPriceElement>|null} [data] SearchPriceElementResponse data
         * @property {number|null} [page] SearchPriceElementResponse page
         * @property {number|null} [pageCount] SearchPriceElementResponse pageCount
         * @property {number|null} [limit] SearchPriceElementResponse limit
         * @property {number|null} [count] SearchPriceElementResponse count
         * @property {number|null} [total] SearchPriceElementResponse total
         */

        /**
         * Constructs a new SearchPriceElementResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchPriceElementResponse.
         * @implements ISearchPriceElementResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchPriceElementResponse=} [properties] Properties to set
         */
        function SearchPriceElementResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPriceElementResponse data.
         * @member {Array.<PlatformAdminApi.IPriceElement>} data
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @instance
         */
        SearchPriceElementResponse.prototype.data = $util.emptyArray;

        /**
         * SearchPriceElementResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @instance
         */
        SearchPriceElementResponse.prototype.page = 0;

        /**
         * SearchPriceElementResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @instance
         */
        SearchPriceElementResponse.prototype.pageCount = 0;

        /**
         * SearchPriceElementResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @instance
         */
        SearchPriceElementResponse.prototype.limit = 0;

        /**
         * SearchPriceElementResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @instance
         */
        SearchPriceElementResponse.prototype.count = 0;

        /**
         * SearchPriceElementResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @instance
         */
        SearchPriceElementResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchPriceElementResponse message. Does not implicitly {@link PlatformAdminApi.SearchPriceElementResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @static
         * @param {PlatformAdminApi.ISearchPriceElementResponse} message SearchPriceElementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPriceElementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.PriceElement.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchPriceElementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPriceElementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPriceElementResponse} SearchPriceElementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPriceElementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPriceElementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.PriceElement.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchPriceElementResponse;
    })();

    PlatformAdminApi.PriceElementResponse = (function() {

        /**
         * Properties of a PriceElementResponse.
         * @memberof PlatformAdminApi
         * @interface IPriceElementResponse
         * @property {PlatformAdminApi.IPriceElement|null} [data] PriceElementResponse data
         */

        /**
         * Constructs a new PriceElementResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PriceElementResponse.
         * @implements IPriceElementResponse
         * @constructor
         * @param {PlatformAdminApi.IPriceElementResponse=} [properties] Properties to set
         */
        function PriceElementResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElementResponse data.
         * @member {PlatformAdminApi.IPriceElement|null|undefined} data
         * @memberof PlatformAdminApi.PriceElementResponse
         * @instance
         */
        PriceElementResponse.prototype.data = null;

        /**
         * Encodes the specified PriceElementResponse message. Does not implicitly {@link PlatformAdminApi.PriceElementResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PriceElementResponse
         * @static
         * @param {PlatformAdminApi.IPriceElementResponse} message PriceElementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.PriceElement.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PriceElementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PriceElementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PriceElementResponse} PriceElementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PriceElementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.PriceElement.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElementResponse;
    })();

    PlatformAdminApi.RegionService = (function() {

        /**
         * Constructs a new RegionService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RegionService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function RegionService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (RegionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RegionService;

        /**
         * Callback as used by {@link PlatformAdminApi.RegionService#search}.
         * @memberof PlatformAdminApi.RegionService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchRegionResponse} [response] SearchRegionResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.ISearchRegionRequest} request SearchRegionRequest message or plain object
         * @param {PlatformAdminApi.RegionService.SearchCallback} callback Node-style callback called with the error, if any, and SearchRegionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegionService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchRegionRequest, $root.PlatformAdminApi.SearchRegionResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.ISearchRegionRequest} request SearchRegionRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchRegionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegionService#create}.
         * @memberof PlatformAdminApi.RegionService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegionResponse} [response] RegionResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.ICreateRegionRequest} request CreateRegionRequest message or plain object
         * @param {PlatformAdminApi.RegionService.CreateCallback} callback Node-style callback called with the error, if any, and RegionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegionService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreateRegionRequest, $root.PlatformAdminApi.RegionResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.ICreateRegionRequest} request CreateRegionRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegionService#update}.
         * @memberof PlatformAdminApi.RegionService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegionResponse} [response] RegionResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IUpdateRegionRequest} request UpdateRegionRequest message or plain object
         * @param {PlatformAdminApi.RegionService.UpdateCallback} callback Node-style callback called with the error, if any, and RegionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegionService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdateRegionRequest, $root.PlatformAdminApi.RegionResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IUpdateRegionRequest} request UpdateRegionRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegionService#findById}.
         * @memberof PlatformAdminApi.RegionService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegionResponse} [response] RegionResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IRegionIdRequest} request RegionIdRequest message or plain object
         * @param {PlatformAdminApi.RegionService.FindByIdCallback} callback Node-style callback called with the error, if any, and RegionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegionService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.RegionIdRequest, $root.PlatformAdminApi.RegionResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IRegionIdRequest} request RegionIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegionService#remove}.
         * @memberof PlatformAdminApi.RegionService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegionResponse} [response] RegionResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IRegionIdRequest} request RegionIdRequest message or plain object
         * @param {PlatformAdminApi.RegionService.RemoveCallback} callback Node-style callback called with the error, if any, and RegionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegionService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.RegionIdRequest, $root.PlatformAdminApi.RegionResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IRegionIdRequest} request RegionIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegionService#restore}.
         * @memberof PlatformAdminApi.RegionService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegionResponse} [response] RegionResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IRegionIdRequest} request RegionIdRequest message or plain object
         * @param {PlatformAdminApi.RegionService.RestoreCallback} callback Node-style callback called with the error, if any, and RegionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegionService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.RegionIdRequest, $root.PlatformAdminApi.RegionResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.RegionService
         * @instance
         * @param {PlatformAdminApi.IRegionIdRequest} request RegionIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegionResponse>} Promise
         * @variation 2
         */

        return RegionService;
    })();

    PlatformAdminApi.SearchRegionRequest = (function() {

        /**
         * Properties of a SearchRegionRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchRegionRequest
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchRegionRequest sort
         * @property {Array.<PlatformAdminApi.SearchRegionRequest.IWhereFields>|null} [where] SearchRegionRequest where
         */

        /**
         * Constructs a new SearchRegionRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных региионов
         * @implements ISearchRegionRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchRegionRequest=} [properties] Properties to set
         */
        function SearchRegionRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchRegionRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchRegionRequest
         * @instance
         */
        SearchRegionRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchRegionRequest where.
         * @member {Array.<PlatformAdminApi.SearchRegionRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchRegionRequest
         * @instance
         */
        SearchRegionRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchRegionRequest message. Does not implicitly {@link PlatformAdminApi.SearchRegionRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchRegionRequest
         * @static
         * @param {PlatformAdminApi.ISearchRegionRequest} message SearchRegionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchRegionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchRegionRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchRegionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchRegionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchRegionRequest} SearchRegionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchRegionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchRegionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchRegionRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchRegionRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchRegionRequest
             * @interface IWhereFields
             * @property {string|null} [search] WhereFields search
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchRegionRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchRegionRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields search.
             * @member {string} search
             * @memberof PlatformAdminApi.SearchRegionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.search = "";

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchRegionRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchRegionRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchRegionRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.search);
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchRegionRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchRegionRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchRegionRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.search = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchRegionRequest;
    })();

    PlatformAdminApi.Region = (function() {

        /**
         * Properties of a Region.
         * @memberof PlatformAdminApi
         * @interface IRegion
         * @property {string|null} [id] Region id
         * @property {string|null} [title] Region title
         * @property {string|null} [name] Region name
         */

        /**
         * Constructs a new Region.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Region.
         * @implements IRegion
         * @constructor
         * @param {PlatformAdminApi.IRegion=} [properties] Properties to set
         */
        function Region(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Region id.
         * @member {string} id
         * @memberof PlatformAdminApi.Region
         * @instance
         */
        Region.prototype.id = "";

        /**
         * Region title.
         * @member {string} title
         * @memberof PlatformAdminApi.Region
         * @instance
         */
        Region.prototype.title = "";

        /**
         * Region name.
         * @member {string} name
         * @memberof PlatformAdminApi.Region
         * @instance
         */
        Region.prototype.name = "";

        /**
         * Encodes the specified Region message. Does not implicitly {@link PlatformAdminApi.Region.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Region
         * @static
         * @param {PlatformAdminApi.IRegion} message Region message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Region.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a Region message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Region
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Region} Region
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Region.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Region();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Region;
    })();

    PlatformAdminApi.SearchRegionResponse = (function() {

        /**
         * Properties of a SearchRegionResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchRegionResponse
         * @property {Array.<PlatformAdminApi.IRegion>|null} [data] SearchRegionResponse data
         * @property {number|null} [page] SearchRegionResponse page
         * @property {number|null} [limit] SearchRegionResponse limit
         * @property {number|null} [total] SearchRegionResponse total
         */

        /**
         * Constructs a new SearchRegionResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchRegionResponse.
         * @implements ISearchRegionResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchRegionResponse=} [properties] Properties to set
         */
        function SearchRegionResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchRegionResponse data.
         * @member {Array.<PlatformAdminApi.IRegion>} data
         * @memberof PlatformAdminApi.SearchRegionResponse
         * @instance
         */
        SearchRegionResponse.prototype.data = $util.emptyArray;

        /**
         * SearchRegionResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchRegionResponse
         * @instance
         */
        SearchRegionResponse.prototype.page = 0;

        /**
         * SearchRegionResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchRegionResponse
         * @instance
         */
        SearchRegionResponse.prototype.limit = 0;

        /**
         * SearchRegionResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchRegionResponse
         * @instance
         */
        SearchRegionResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchRegionResponse message. Does not implicitly {@link PlatformAdminApi.SearchRegionResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchRegionResponse
         * @static
         * @param {PlatformAdminApi.ISearchRegionResponse} message SearchRegionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchRegionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Region.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchRegionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchRegionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchRegionResponse} SearchRegionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchRegionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchRegionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Region.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchRegionResponse;
    })();

    PlatformAdminApi.CreateRegionRequest = (function() {

        /**
         * Properties of a CreateRegionRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateRegionRequest
         * @property {string|null} [title] CreateRegionRequest title
         * @property {string|null} [name] CreateRegionRequest name
         */

        /**
         * Constructs a new CreateRegionRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания региона
         * @implements ICreateRegionRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateRegionRequest=} [properties] Properties to set
         */
        function CreateRegionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateRegionRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreateRegionRequest
         * @instance
         */
        CreateRegionRequest.prototype.title = "";

        /**
         * CreateRegionRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.CreateRegionRequest
         * @instance
         */
        CreateRegionRequest.prototype.name = "";

        /**
         * Encodes the specified CreateRegionRequest message. Does not implicitly {@link PlatformAdminApi.CreateRegionRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateRegionRequest
         * @static
         * @param {PlatformAdminApi.ICreateRegionRequest} message CreateRegionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateRegionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a CreateRegionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateRegionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateRegionRequest} CreateRegionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateRegionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateRegionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateRegionRequest;
    })();

    PlatformAdminApi.UpdateRegionRequest = (function() {

        /**
         * Properties of an UpdateRegionRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateRegionRequest
         * @property {string|null} [id] UpdateRegionRequest id
         * @property {string|null} [title] UpdateRegionRequest title
         * @property {string|null} [name] UpdateRegionRequest name
         */

        /**
         * Constructs a new UpdateRegionRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновления региона
         * @implements IUpdateRegionRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateRegionRequest=} [properties] Properties to set
         */
        function UpdateRegionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateRegionRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdateRegionRequest
         * @instance
         */
        UpdateRegionRequest.prototype.id = "";

        /**
         * UpdateRegionRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdateRegionRequest
         * @instance
         */
        UpdateRegionRequest.prototype.title = "";

        /**
         * UpdateRegionRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.UpdateRegionRequest
         * @instance
         */
        UpdateRegionRequest.prototype.name = "";

        /**
         * Encodes the specified UpdateRegionRequest message. Does not implicitly {@link PlatformAdminApi.UpdateRegionRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateRegionRequest
         * @static
         * @param {PlatformAdminApi.IUpdateRegionRequest} message UpdateRegionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRegionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes an UpdateRegionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateRegionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateRegionRequest} UpdateRegionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRegionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateRegionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateRegionRequest;
    })();

    PlatformAdminApi.RegionResponse = (function() {

        /**
         * Properties of a RegionResponse.
         * @memberof PlatformAdminApi
         * @interface IRegionResponse
         * @property {PlatformAdminApi.IRegion|null} [data] RegionResponse data
         */

        /**
         * Constructs a new RegionResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RegionResponse.
         * @implements IRegionResponse
         * @constructor
         * @param {PlatformAdminApi.IRegionResponse=} [properties] Properties to set
         */
        function RegionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegionResponse data.
         * @member {PlatformAdminApi.IRegion|null|undefined} data
         * @memberof PlatformAdminApi.RegionResponse
         * @instance
         */
        RegionResponse.prototype.data = null;

        /**
         * Encodes the specified RegionResponse message. Does not implicitly {@link PlatformAdminApi.RegionResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RegionResponse
         * @static
         * @param {PlatformAdminApi.IRegionResponse} message RegionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Region.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RegionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RegionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RegionResponse} RegionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RegionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Region.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RegionResponse;
    })();

    PlatformAdminApi.RegionIdRequest = (function() {

        /**
         * Properties of a RegionIdRequest.
         * @memberof PlatformAdminApi
         * @interface IRegionIdRequest
         * @property {string|null} [id] RegionIdRequest id
         */

        /**
         * Constructs a new RegionIdRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RegionIdRequest.
         * @implements IRegionIdRequest
         * @constructor
         * @param {PlatformAdminApi.IRegionIdRequest=} [properties] Properties to set
         */
        function RegionIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegionIdRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.RegionIdRequest
         * @instance
         */
        RegionIdRequest.prototype.id = "";

        /**
         * Encodes the specified RegionIdRequest message. Does not implicitly {@link PlatformAdminApi.RegionIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RegionIdRequest
         * @static
         * @param {PlatformAdminApi.IRegionIdRequest} message RegionIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegionIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a RegionIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RegionIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RegionIdRequest} RegionIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegionIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RegionIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RegionIdRequest;
    })();

    PlatformAdminApi.UnitService = (function() {

        /**
         * Constructs a new UnitService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UnitService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function UnitService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (UnitService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UnitService;

        /**
         * Callback as used by {@link PlatformAdminApi.UnitService#search}.
         * @memberof PlatformAdminApi.UnitService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchUnitResponse} [response] SearchUnitResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.ISearchUnitRequest} request SearchUnitRequest message or plain object
         * @param {PlatformAdminApi.UnitService.SearchCallback} callback Node-style callback called with the error, if any, and SearchUnitResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UnitService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchUnitRequest, $root.PlatformAdminApi.SearchUnitResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.ISearchUnitRequest} request SearchUnitRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchUnitResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.UnitService#create}.
         * @memberof PlatformAdminApi.UnitService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.UnitResponse} [response] UnitResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.ICreateUnitRequest} request CreateUnitRequest message or plain object
         * @param {PlatformAdminApi.UnitService.CreateCallback} callback Node-style callback called with the error, if any, and UnitResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UnitService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreateUnitRequest, $root.PlatformAdminApi.UnitResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.ICreateUnitRequest} request CreateUnitRequest message or plain object
         * @returns {Promise<PlatformAdminApi.UnitResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.UnitService#update}.
         * @memberof PlatformAdminApi.UnitService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.UnitResponse} [response] UnitResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUpdateUnitRequest} request UpdateUnitRequest message or plain object
         * @param {PlatformAdminApi.UnitService.UpdateCallback} callback Node-style callback called with the error, if any, and UnitResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UnitService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdateUnitRequest, $root.PlatformAdminApi.UnitResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUpdateUnitRequest} request UpdateUnitRequest message or plain object
         * @returns {Promise<PlatformAdminApi.UnitResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.UnitService#findById}.
         * @memberof PlatformAdminApi.UnitService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.UnitResponse} [response] UnitResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUnitIdRequest} request UnitIdRequest message or plain object
         * @param {PlatformAdminApi.UnitService.FindByIdCallback} callback Node-style callback called with the error, if any, and UnitResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UnitService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UnitIdRequest, $root.PlatformAdminApi.UnitResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUnitIdRequest} request UnitIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.UnitResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.UnitService#remove}.
         * @memberof PlatformAdminApi.UnitService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.UnitResponse} [response] UnitResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUnitIdRequest} request UnitIdRequest message or plain object
         * @param {PlatformAdminApi.UnitService.RemoveCallback} callback Node-style callback called with the error, if any, and UnitResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UnitService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UnitIdRequest, $root.PlatformAdminApi.UnitResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUnitIdRequest} request UnitIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.UnitResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.UnitService#restore}.
         * @memberof PlatformAdminApi.UnitService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.UnitResponse} [response] UnitResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUnitIdRequest} request UnitIdRequest message or plain object
         * @param {PlatformAdminApi.UnitService.RestoreCallback} callback Node-style callback called with the error, if any, and UnitResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UnitService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UnitIdRequest, $root.PlatformAdminApi.UnitResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.UnitService
         * @instance
         * @param {PlatformAdminApi.IUnitIdRequest} request UnitIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.UnitResponse>} Promise
         * @variation 2
         */

        return UnitService;
    })();

    PlatformAdminApi.SearchUnitRequest = (function() {

        /**
         * Properties of a SearchUnitRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchUnitRequest
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchUnitRequest sort
         * @property {Array.<PlatformAdminApi.SearchUnitRequest.IWhereFields>|null} [where] SearchUnitRequest where
         */

        /**
         * Constructs a new SearchUnitRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных единиц измерения
         * @implements ISearchUnitRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchUnitRequest=} [properties] Properties to set
         */
        function SearchUnitRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUnitRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchUnitRequest
         * @instance
         */
        SearchUnitRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchUnitRequest where.
         * @member {Array.<PlatformAdminApi.SearchUnitRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchUnitRequest
         * @instance
         */
        SearchUnitRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchUnitRequest message. Does not implicitly {@link PlatformAdminApi.SearchUnitRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchUnitRequest
         * @static
         * @param {PlatformAdminApi.ISearchUnitRequest} message SearchUnitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUnitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchUnitRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchUnitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchUnitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchUnitRequest} SearchUnitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUnitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchUnitRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchUnitRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchUnitRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchUnitRequest
             * @interface IWhereFields
             * @property {Array.<string>|null} [excludes] WhereFields excludes
             * @property {string|null} [search] WhereFields search
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchUnitRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchUnitRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                this.excludes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields excludes.
             * @member {Array.<string>} excludes
             * @memberof PlatformAdminApi.SearchUnitRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.excludes = $util.emptyArray;

            /**
             * WhereFields search.
             * @member {string} search
             * @memberof PlatformAdminApi.SearchUnitRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.search = "";

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchUnitRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchUnitRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchUnitRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.excludes != null && message.excludes.length)
                    for (var i = 0; i < message.excludes.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.excludes[i]);
                if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.search);
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchUnitRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchUnitRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchUnitRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.excludes && message.excludes.length))
                            message.excludes = [];
                        message.excludes.push(reader.string());
                        break;
                    case 2:
                        message.search = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchUnitRequest;
    })();

    PlatformAdminApi.Unit = (function() {

        /**
         * Properties of an Unit.
         * @memberof PlatformAdminApi
         * @interface IUnit
         * @property {string|null} [id] Unit id
         * @property {string|null} [title] Unit title
         * @property {string|null} [abbreviation] Unit abbreviation
         */

        /**
         * Constructs a new Unit.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Unit.
         * @implements IUnit
         * @constructor
         * @param {PlatformAdminApi.IUnit=} [properties] Properties to set
         */
        function Unit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Unit id.
         * @member {string} id
         * @memberof PlatformAdminApi.Unit
         * @instance
         */
        Unit.prototype.id = "";

        /**
         * Unit title.
         * @member {string} title
         * @memberof PlatformAdminApi.Unit
         * @instance
         */
        Unit.prototype.title = "";

        /**
         * Unit abbreviation.
         * @member {string} abbreviation
         * @memberof PlatformAdminApi.Unit
         * @instance
         */
        Unit.prototype.abbreviation = "";

        /**
         * Encodes the specified Unit message. Does not implicitly {@link PlatformAdminApi.Unit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Unit
         * @static
         * @param {PlatformAdminApi.IUnit} message Unit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Unit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.abbreviation != null && Object.hasOwnProperty.call(message, "abbreviation"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.abbreviation);
            return writer;
        };

        /**
         * Decodes an Unit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Unit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Unit} Unit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Unit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Unit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.abbreviation = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Unit;
    })();

    PlatformAdminApi.SearchUnitResponse = (function() {

        /**
         * Properties of a SearchUnitResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchUnitResponse
         * @property {Array.<PlatformAdminApi.IUnit>|null} [data] SearchUnitResponse data
         * @property {number|null} [page] SearchUnitResponse page
         * @property {number|null} [limit] SearchUnitResponse limit
         * @property {number|null} [total] SearchUnitResponse total
         */

        /**
         * Constructs a new SearchUnitResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchUnitResponse.
         * @implements ISearchUnitResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchUnitResponse=} [properties] Properties to set
         */
        function SearchUnitResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchUnitResponse data.
         * @member {Array.<PlatformAdminApi.IUnit>} data
         * @memberof PlatformAdminApi.SearchUnitResponse
         * @instance
         */
        SearchUnitResponse.prototype.data = $util.emptyArray;

        /**
         * SearchUnitResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchUnitResponse
         * @instance
         */
        SearchUnitResponse.prototype.page = 0;

        /**
         * SearchUnitResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchUnitResponse
         * @instance
         */
        SearchUnitResponse.prototype.limit = 0;

        /**
         * SearchUnitResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchUnitResponse
         * @instance
         */
        SearchUnitResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchUnitResponse message. Does not implicitly {@link PlatformAdminApi.SearchUnitResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchUnitResponse
         * @static
         * @param {PlatformAdminApi.ISearchUnitResponse} message SearchUnitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchUnitResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Unit.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchUnitResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchUnitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchUnitResponse} SearchUnitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchUnitResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchUnitResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Unit.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchUnitResponse;
    })();

    PlatformAdminApi.CreateUnitRequest = (function() {

        /**
         * Properties of a CreateUnitRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateUnitRequest
         * @property {string|null} [title] CreateUnitRequest title
         * @property {string|null} [abbreviation] CreateUnitRequest abbreviation
         */

        /**
         * Constructs a new CreateUnitRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания единицы измерения
         * @implements ICreateUnitRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateUnitRequest=} [properties] Properties to set
         */
        function CreateUnitRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateUnitRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreateUnitRequest
         * @instance
         */
        CreateUnitRequest.prototype.title = "";

        /**
         * CreateUnitRequest abbreviation.
         * @member {string} abbreviation
         * @memberof PlatformAdminApi.CreateUnitRequest
         * @instance
         */
        CreateUnitRequest.prototype.abbreviation = "";

        /**
         * Encodes the specified CreateUnitRequest message. Does not implicitly {@link PlatformAdminApi.CreateUnitRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateUnitRequest
         * @static
         * @param {PlatformAdminApi.ICreateUnitRequest} message CreateUnitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUnitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.abbreviation != null && Object.hasOwnProperty.call(message, "abbreviation"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.abbreviation);
            return writer;
        };

        /**
         * Decodes a CreateUnitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateUnitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateUnitRequest} CreateUnitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUnitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateUnitRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.abbreviation = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateUnitRequest;
    })();

    PlatformAdminApi.UpdateUnitRequest = (function() {

        /**
         * Properties of an UpdateUnitRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateUnitRequest
         * @property {string|null} [id] UpdateUnitRequest id
         * @property {string|null} [title] UpdateUnitRequest title
         * @property {string|null} [abbreviation] UpdateUnitRequest abbreviation
         */

        /**
         * Constructs a new UpdateUnitRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновления единицы измерения
         * @implements IUpdateUnitRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateUnitRequest=} [properties] Properties to set
         */
        function UpdateUnitRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUnitRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdateUnitRequest
         * @instance
         */
        UpdateUnitRequest.prototype.id = "";

        /**
         * UpdateUnitRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdateUnitRequest
         * @instance
         */
        UpdateUnitRequest.prototype.title = "";

        /**
         * UpdateUnitRequest abbreviation.
         * @member {string} abbreviation
         * @memberof PlatformAdminApi.UpdateUnitRequest
         * @instance
         */
        UpdateUnitRequest.prototype.abbreviation = "";

        /**
         * Encodes the specified UpdateUnitRequest message. Does not implicitly {@link PlatformAdminApi.UpdateUnitRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateUnitRequest
         * @static
         * @param {PlatformAdminApi.IUpdateUnitRequest} message UpdateUnitRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUnitRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.abbreviation != null && Object.hasOwnProperty.call(message, "abbreviation"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.abbreviation);
            return writer;
        };

        /**
         * Decodes an UpdateUnitRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateUnitRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateUnitRequest} UpdateUnitRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUnitRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateUnitRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.abbreviation = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateUnitRequest;
    })();

    PlatformAdminApi.UnitResponse = (function() {

        /**
         * Properties of an UnitResponse.
         * @memberof PlatformAdminApi
         * @interface IUnitResponse
         * @property {PlatformAdminApi.IUnit|null} [data] UnitResponse data
         */

        /**
         * Constructs a new UnitResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UnitResponse.
         * @implements IUnitResponse
         * @constructor
         * @param {PlatformAdminApi.IUnitResponse=} [properties] Properties to set
         */
        function UnitResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnitResponse data.
         * @member {PlatformAdminApi.IUnit|null|undefined} data
         * @memberof PlatformAdminApi.UnitResponse
         * @instance
         */
        UnitResponse.prototype.data = null;

        /**
         * Encodes the specified UnitResponse message. Does not implicitly {@link PlatformAdminApi.UnitResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UnitResponse
         * @static
         * @param {PlatformAdminApi.IUnitResponse} message UnitResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnitResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Unit.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UnitResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UnitResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UnitResponse} UnitResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnitResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UnitResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Unit.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UnitResponse;
    })();

    PlatformAdminApi.UnitIdRequest = (function() {

        /**
         * Properties of an UnitIdRequest.
         * @memberof PlatformAdminApi
         * @interface IUnitIdRequest
         * @property {string|null} [id] UnitIdRequest id
         */

        /**
         * Constructs a new UnitIdRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UnitIdRequest.
         * @implements IUnitIdRequest
         * @constructor
         * @param {PlatformAdminApi.IUnitIdRequest=} [properties] Properties to set
         */
        function UnitIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnitIdRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UnitIdRequest
         * @instance
         */
        UnitIdRequest.prototype.id = "";

        /**
         * Encodes the specified UnitIdRequest message. Does not implicitly {@link PlatformAdminApi.UnitIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UnitIdRequest
         * @static
         * @param {PlatformAdminApi.IUnitIdRequest} message UnitIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnitIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes an UnitIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UnitIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UnitIdRequest} UnitIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnitIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UnitIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UnitIdRequest;
    })();

    PlatformAdminApi.TagService = (function() {

        /**
         * Constructs a new TagService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TagService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function TagService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (TagService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TagService;

        /**
         * Callback as used by {@link PlatformAdminApi.TagService#create}.
         * @memberof PlatformAdminApi.TagService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TagResponse} [response] TagResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ICreateTagRequest} request CreateTagRequest message or plain object
         * @param {PlatformAdminApi.TagService.CreateCallback} callback Node-style callback called with the error, if any, and TagResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TagService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreateTagRequest, $root.PlatformAdminApi.TagResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ICreateTagRequest} request CreateTagRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TagResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TagService#update}.
         * @memberof PlatformAdminApi.TagService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TagResponse} [response] TagResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.IUpdateTagRequest} request UpdateTagRequest message or plain object
         * @param {PlatformAdminApi.TagService.UpdateCallback} callback Node-style callback called with the error, if any, and TagResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TagService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdateTagRequest, $root.PlatformAdminApi.TagResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.IUpdateTagRequest} request UpdateTagRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TagResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TagService#search}.
         * @memberof PlatformAdminApi.TagService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchTagResponse} [response] SearchTagResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ISearchTagRequest} request SearchTagRequest message or plain object
         * @param {PlatformAdminApi.TagService.SearchCallback} callback Node-style callback called with the error, if any, and SearchTagResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TagService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchTagRequest, $root.PlatformAdminApi.SearchTagResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ISearchTagRequest} request SearchTagRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchTagResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TagService#findById}.
         * @memberof PlatformAdminApi.TagService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TagResponse} [response] TagResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ITagIdRequest} request TagIdRequest message or plain object
         * @param {PlatformAdminApi.TagService.FindByIdCallback} callback Node-style callback called with the error, if any, and TagResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TagService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.TagIdRequest, $root.PlatformAdminApi.TagResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ITagIdRequest} request TagIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TagResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TagService#remove}.
         * @memberof PlatformAdminApi.TagService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TagResponse} [response] TagResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ITagIdRequest} request TagIdRequest message or plain object
         * @param {PlatformAdminApi.TagService.RemoveCallback} callback Node-style callback called with the error, if any, and TagResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TagService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.TagIdRequest, $root.PlatformAdminApi.TagResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ITagIdRequest} request TagIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TagResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TagService#restore}.
         * @memberof PlatformAdminApi.TagService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TagResponse} [response] TagResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ITagIdRequest} request TagIdRequest message or plain object
         * @param {PlatformAdminApi.TagService.RestoreCallback} callback Node-style callback called with the error, if any, and TagResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TagService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.TagIdRequest, $root.PlatformAdminApi.TagResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.TagService
         * @instance
         * @param {PlatformAdminApi.ITagIdRequest} request TagIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TagResponse>} Promise
         * @variation 2
         */

        return TagService;
    })();

    PlatformAdminApi.SearchTagRequest = (function() {

        /**
         * Properties of a SearchTagRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchTagRequest
         * @property {number|null} [page] SearchTagRequest page
         * @property {number|null} [limit] SearchTagRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchTagRequest sort
         * @property {Array.<PlatformAdminApi.SearchTagRequest.IWhereFields>|null} [where] SearchTagRequest where
         */

        /**
         * Constructs a new SearchTagRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных тегов
         * @implements ISearchTagRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchTagRequest=} [properties] Properties to set
         */
        function SearchTagRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchTagRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchTagRequest
         * @instance
         */
        SearchTagRequest.prototype.page = 0;

        /**
         * SearchTagRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchTagRequest
         * @instance
         */
        SearchTagRequest.prototype.limit = 0;

        /**
         * SearchTagRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchTagRequest
         * @instance
         */
        SearchTagRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchTagRequest where.
         * @member {Array.<PlatformAdminApi.SearchTagRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchTagRequest
         * @instance
         */
        SearchTagRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchTagRequest message. Does not implicitly {@link PlatformAdminApi.SearchTagRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchTagRequest
         * @static
         * @param {PlatformAdminApi.ISearchTagRequest} message SearchTagRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchTagRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchTagRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchTagRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchTagRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchTagRequest} SearchTagRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchTagRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchTagRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchTagRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchTagRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchTagRequest
             * @interface IWhereFields
             * @property {Array.<string>|null} [excludes] WhereFields excludes
             * @property {string|null} [search] WhereFields search
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchTagRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchTagRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                this.excludes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields excludes.
             * @member {Array.<string>} excludes
             * @memberof PlatformAdminApi.SearchTagRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.excludes = $util.emptyArray;

            /**
             * WhereFields search.
             * @member {string} search
             * @memberof PlatformAdminApi.SearchTagRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.search = "";

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchTagRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchTagRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchTagRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.excludes != null && message.excludes.length)
                    for (var i = 0; i < message.excludes.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.excludes[i]);
                if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.search);
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchTagRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchTagRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchTagRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.excludes && message.excludes.length))
                            message.excludes = [];
                        message.excludes.push(reader.string());
                        break;
                    case 2:
                        message.search = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchTagRequest;
    })();

    PlatformAdminApi.CreateTagRequest = (function() {

        /**
         * Properties of a CreateTagRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateTagRequest
         * @property {string|null} [title] CreateTagRequest title
         * @property {string|null} [name] CreateTagRequest name
         */

        /**
         * Constructs a new CreateTagRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания тега
         * @implements ICreateTagRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateTagRequest=} [properties] Properties to set
         */
        function CreateTagRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateTagRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreateTagRequest
         * @instance
         */
        CreateTagRequest.prototype.title = "";

        /**
         * CreateTagRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.CreateTagRequest
         * @instance
         */
        CreateTagRequest.prototype.name = "";

        /**
         * Encodes the specified CreateTagRequest message. Does not implicitly {@link PlatformAdminApi.CreateTagRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateTagRequest
         * @static
         * @param {PlatformAdminApi.ICreateTagRequest} message CreateTagRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTagRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a CreateTagRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateTagRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateTagRequest} CreateTagRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTagRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateTagRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateTagRequest;
    })();

    PlatformAdminApi.UpdateTagRequest = (function() {

        /**
         * Properties of an UpdateTagRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateTagRequest
         * @property {string|null} [id] UpdateTagRequest id
         * @property {string|null} [title] UpdateTagRequest title
         * @property {string|null} [name] UpdateTagRequest name
         */

        /**
         * Constructs a new UpdateTagRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновления тега
         * @implements IUpdateTagRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateTagRequest=} [properties] Properties to set
         */
        function UpdateTagRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateTagRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdateTagRequest
         * @instance
         */
        UpdateTagRequest.prototype.id = "";

        /**
         * UpdateTagRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdateTagRequest
         * @instance
         */
        UpdateTagRequest.prototype.title = "";

        /**
         * UpdateTagRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.UpdateTagRequest
         * @instance
         */
        UpdateTagRequest.prototype.name = "";

        /**
         * Encodes the specified UpdateTagRequest message. Does not implicitly {@link PlatformAdminApi.UpdateTagRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateTagRequest
         * @static
         * @param {PlatformAdminApi.IUpdateTagRequest} message UpdateTagRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTagRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes an UpdateTagRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateTagRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateTagRequest} UpdateTagRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTagRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateTagRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateTagRequest;
    })();

    PlatformAdminApi.Tag = (function() {

        /**
         * Properties of a Tag.
         * @memberof PlatformAdminApi
         * @interface ITag
         * @property {string|null} [id] Tag id
         * @property {string|null} [title] Tag title
         * @property {string|null} [name] Tag name
         */

        /**
         * Constructs a new Tag.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Tag.
         * @implements ITag
         * @constructor
         * @param {PlatformAdminApi.ITag=} [properties] Properties to set
         */
        function Tag(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Tag id.
         * @member {string} id
         * @memberof PlatformAdminApi.Tag
         * @instance
         */
        Tag.prototype.id = "";

        /**
         * Tag title.
         * @member {string} title
         * @memberof PlatformAdminApi.Tag
         * @instance
         */
        Tag.prototype.title = "";

        /**
         * Tag name.
         * @member {string} name
         * @memberof PlatformAdminApi.Tag
         * @instance
         */
        Tag.prototype.name = "";

        /**
         * Encodes the specified Tag message. Does not implicitly {@link PlatformAdminApi.Tag.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Tag
         * @static
         * @param {PlatformAdminApi.ITag} message Tag message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tag.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a Tag message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Tag
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Tag} Tag
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tag.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Tag();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Tag;
    })();

    PlatformAdminApi.SearchTagResponse = (function() {

        /**
         * Properties of a SearchTagResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchTagResponse
         * @property {Array.<PlatformAdminApi.ITag>|null} [data] SearchTagResponse data
         * @property {number|null} [page] SearchTagResponse page
         * @property {number|null} [limit] SearchTagResponse limit
         * @property {number|null} [total] SearchTagResponse total
         */

        /**
         * Constructs a new SearchTagResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchTagResponse.
         * @implements ISearchTagResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchTagResponse=} [properties] Properties to set
         */
        function SearchTagResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchTagResponse data.
         * @member {Array.<PlatformAdminApi.ITag>} data
         * @memberof PlatformAdminApi.SearchTagResponse
         * @instance
         */
        SearchTagResponse.prototype.data = $util.emptyArray;

        /**
         * SearchTagResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchTagResponse
         * @instance
         */
        SearchTagResponse.prototype.page = 0;

        /**
         * SearchTagResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchTagResponse
         * @instance
         */
        SearchTagResponse.prototype.limit = 0;

        /**
         * SearchTagResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchTagResponse
         * @instance
         */
        SearchTagResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchTagResponse message. Does not implicitly {@link PlatformAdminApi.SearchTagResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchTagResponse
         * @static
         * @param {PlatformAdminApi.ISearchTagResponse} message SearchTagResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchTagResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Tag.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchTagResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchTagResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchTagResponse} SearchTagResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchTagResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchTagResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Tag.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchTagResponse;
    })();

    PlatformAdminApi.TagResponse = (function() {

        /**
         * Properties of a TagResponse.
         * @memberof PlatformAdminApi
         * @interface ITagResponse
         * @property {PlatformAdminApi.ITag|null} [data] TagResponse data
         */

        /**
         * Constructs a new TagResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TagResponse.
         * @implements ITagResponse
         * @constructor
         * @param {PlatformAdminApi.ITagResponse=} [properties] Properties to set
         */
        function TagResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagResponse data.
         * @member {PlatformAdminApi.ITag|null|undefined} data
         * @memberof PlatformAdminApi.TagResponse
         * @instance
         */
        TagResponse.prototype.data = null;

        /**
         * Encodes the specified TagResponse message. Does not implicitly {@link PlatformAdminApi.TagResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TagResponse
         * @static
         * @param {PlatformAdminApi.ITagResponse} message TagResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Tag.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TagResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TagResponse} TagResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TagResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Tag.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TagResponse;
    })();

    PlatformAdminApi.TagIdRequest = (function() {

        /**
         * Properties of a TagIdRequest.
         * @memberof PlatformAdminApi
         * @interface ITagIdRequest
         * @property {string|null} [id] TagIdRequest id
         */

        /**
         * Constructs a new TagIdRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TagIdRequest.
         * @implements ITagIdRequest
         * @constructor
         * @param {PlatformAdminApi.ITagIdRequest=} [properties] Properties to set
         */
        function TagIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagIdRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.TagIdRequest
         * @instance
         */
        TagIdRequest.prototype.id = "";

        /**
         * Encodes the specified TagIdRequest message. Does not implicitly {@link PlatformAdminApi.TagIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TagIdRequest
         * @static
         * @param {PlatformAdminApi.ITagIdRequest} message TagIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a TagIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TagIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TagIdRequest} TagIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TagIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TagIdRequest;
    })();

    PlatformAdminApi.RegulationsService = (function() {

        /**
         * Constructs a new RegulationsService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RegulationsService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function RegulationsService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (RegulationsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RegulationsService;

        /**
         * Callback as used by {@link PlatformAdminApi.RegulationsService#create}.
         * @memberof PlatformAdminApi.RegulationsService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegulationsResponse} [response] RegulationsResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.ICreateRegulationsRequest} request CreateRegulationsRequest message or plain object
         * @param {PlatformAdminApi.RegulationsService.CreateCallback} callback Node-style callback called with the error, if any, and RegulationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegulationsService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreateRegulationsRequest, $root.PlatformAdminApi.RegulationsResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.ICreateRegulationsRequest} request CreateRegulationsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegulationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegulationsService#update}.
         * @memberof PlatformAdminApi.RegulationsService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegulationsResponse} [response] RegulationsResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUpdateRegulationsRequest} request UpdateRegulationsRequest message or plain object
         * @param {PlatformAdminApi.RegulationsService.UpdateCallback} callback Node-style callback called with the error, if any, and RegulationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegulationsService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdateRegulationsRequest, $root.PlatformAdminApi.RegulationsResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUpdateRegulationsRequest} request UpdateRegulationsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegulationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegulationsService#search}.
         * @memberof PlatformAdminApi.RegulationsService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchRegulationsResponse} [response] SearchRegulationsResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.ISearchRegulationsRequest} request SearchRegulationsRequest message or plain object
         * @param {PlatformAdminApi.RegulationsService.SearchCallback} callback Node-style callback called with the error, if any, and SearchRegulationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegulationsService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchRegulationsRequest, $root.PlatformAdminApi.SearchRegulationsResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.ISearchRegulationsRequest} request SearchRegulationsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchRegulationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegulationsService#findById}.
         * @memberof PlatformAdminApi.RegulationsService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegulationsResponse} [response] RegulationsResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.RegulationsService.FindByIdCallback} callback Node-style callback called with the error, if any, and RegulationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegulationsService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.RegulationsResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegulationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegulationsService#remove}.
         * @memberof PlatformAdminApi.RegulationsService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegulationsResponse} [response] RegulationsResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.RegulationsService.RemoveCallback} callback Node-style callback called with the error, if any, and RegulationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegulationsService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.RegulationsResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegulationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.RegulationsService#restore}.
         * @memberof PlatformAdminApi.RegulationsService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RegulationsResponse} [response] RegulationsResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.RegulationsService.RestoreCallback} callback Node-style callback called with the error, if any, and RegulationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RegulationsService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.RegulationsResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.RegulationsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RegulationsResponse>} Promise
         * @variation 2
         */

        return RegulationsService;
    })();

    PlatformAdminApi.CreateRegulationsRequest = (function() {

        /**
         * Properties of a CreateRegulationsRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateRegulationsRequest
         * @property {string|null} [title] CreateRegulationsRequest title
         * @property {string|null} [name] CreateRegulationsRequest name
         * @property {Array.<PlatformAdminApi.IOption>|null} [options] CreateRegulationsRequest options
         */

        /**
         * Constructs a new CreateRegulationsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания регламента
         * @implements ICreateRegulationsRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateRegulationsRequest=} [properties] Properties to set
         */
        function CreateRegulationsRequest(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateRegulationsRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreateRegulationsRequest
         * @instance
         */
        CreateRegulationsRequest.prototype.title = "";

        /**
         * CreateRegulationsRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.CreateRegulationsRequest
         * @instance
         */
        CreateRegulationsRequest.prototype.name = "";

        /**
         * CreateRegulationsRequest options.
         * @member {Array.<PlatformAdminApi.IOption>} options
         * @memberof PlatformAdminApi.CreateRegulationsRequest
         * @instance
         */
        CreateRegulationsRequest.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified CreateRegulationsRequest message. Does not implicitly {@link PlatformAdminApi.CreateRegulationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateRegulationsRequest
         * @static
         * @param {PlatformAdminApi.ICreateRegulationsRequest} message CreateRegulationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateRegulationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.Option.encode(message.options[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreateRegulationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateRegulationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateRegulationsRequest} CreateRegulationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateRegulationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateRegulationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.Option.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateRegulationsRequest;
    })();

    PlatformAdminApi.UpdateRegulationsRequest = (function() {

        /**
         * Properties of an UpdateRegulationsRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateRegulationsRequest
         * @property {string|null} [id] UpdateRegulationsRequest id
         * @property {string|null} [title] UpdateRegulationsRequest title
         * @property {string|null} [name] UpdateRegulationsRequest name
         * @property {Array.<PlatformAdminApi.IOption>|null} [options] UpdateRegulationsRequest options
         */

        /**
         * Constructs a new UpdateRegulationsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновления регламента
         * @implements IUpdateRegulationsRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateRegulationsRequest=} [properties] Properties to set
         */
        function UpdateRegulationsRequest(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateRegulationsRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdateRegulationsRequest
         * @instance
         */
        UpdateRegulationsRequest.prototype.id = "";

        /**
         * UpdateRegulationsRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdateRegulationsRequest
         * @instance
         */
        UpdateRegulationsRequest.prototype.title = "";

        /**
         * UpdateRegulationsRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.UpdateRegulationsRequest
         * @instance
         */
        UpdateRegulationsRequest.prototype.name = "";

        /**
         * UpdateRegulationsRequest options.
         * @member {Array.<PlatformAdminApi.IOption>} options
         * @memberof PlatformAdminApi.UpdateRegulationsRequest
         * @instance
         */
        UpdateRegulationsRequest.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified UpdateRegulationsRequest message. Does not implicitly {@link PlatformAdminApi.UpdateRegulationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateRegulationsRequest
         * @static
         * @param {PlatformAdminApi.IUpdateRegulationsRequest} message UpdateRegulationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRegulationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.Option.encode(message.options[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateRegulationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateRegulationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateRegulationsRequest} UpdateRegulationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRegulationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateRegulationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.Option.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateRegulationsRequest;
    })();

    PlatformAdminApi.SearchRegulationsRequest = (function() {

        /**
         * Properties of a SearchRegulationsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchRegulationsRequest
         * @property {number|null} [page] SearchRegulationsRequest page
         * @property {number|null} [limit] SearchRegulationsRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchRegulationsRequest sort
         * @property {Array.<PlatformAdminApi.SearchRegulationsRequest.IWhereFields>|null} [where] SearchRegulationsRequest where
         */

        /**
         * Constructs a new SearchRegulationsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных услуг
         * @implements ISearchRegulationsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchRegulationsRequest=} [properties] Properties to set
         */
        function SearchRegulationsRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchRegulationsRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchRegulationsRequest
         * @instance
         */
        SearchRegulationsRequest.prototype.page = 0;

        /**
         * SearchRegulationsRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchRegulationsRequest
         * @instance
         */
        SearchRegulationsRequest.prototype.limit = 0;

        /**
         * SearchRegulationsRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchRegulationsRequest
         * @instance
         */
        SearchRegulationsRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchRegulationsRequest where.
         * @member {Array.<PlatformAdminApi.SearchRegulationsRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchRegulationsRequest
         * @instance
         */
        SearchRegulationsRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchRegulationsRequest message. Does not implicitly {@link PlatformAdminApi.SearchRegulationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchRegulationsRequest
         * @static
         * @param {PlatformAdminApi.ISearchRegulationsRequest} message SearchRegulationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchRegulationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchRegulationsRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchRegulationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchRegulationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchRegulationsRequest} SearchRegulationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchRegulationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchRegulationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchRegulationsRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchRegulationsRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchRegulationsRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {PlatformAdminApi.INumberCondition|null} [duration] WhereFields duration
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchRegulationsRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchRegulationsRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields duration.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} duration
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.duration = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchRegulationsRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchRegulationsRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.duration, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchRegulationsRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchRegulationsRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchRegulationsRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.isDeleted = reader.bool();
                        break;
                    case 3:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.duration = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchRegulationsRequest;
    })();

    PlatformAdminApi.Regulations = (function() {

        /**
         * Properties of a Regulations.
         * @memberof PlatformAdminApi
         * @interface IRegulations
         * @property {string|null} [id] Regulations id
         * @property {string|null} [title] Regulations title
         * @property {string|null} [name] Regulations name
         * @property {Array.<PlatformAdminApi.IOption>|null} [options] Regulations options
         * @property {string|null} [createdAt] Regulations createdAt
         * @property {string|null} [updatedAt] Regulations updatedAt
         * @property {boolean|null} [isDeleted] Regulations isDeleted
         * @property {string|null} [deletedAt] Regulations deletedAt
         */

        /**
         * Constructs a new Regulations.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Regulations.
         * @implements IRegulations
         * @constructor
         * @param {PlatformAdminApi.IRegulations=} [properties] Properties to set
         */
        function Regulations(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Regulations id.
         * @member {string} id
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.id = "";

        /**
         * Regulations title.
         * @member {string} title
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.title = "";

        /**
         * Regulations name.
         * @member {string} name
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.name = "";

        /**
         * Regulations options.
         * @member {Array.<PlatformAdminApi.IOption>} options
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.options = $util.emptyArray;

        /**
         * Regulations createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.createdAt = "";

        /**
         * Regulations updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.updatedAt = "";

        /**
         * Regulations isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.isDeleted = false;

        /**
         * Regulations deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Regulations
         * @instance
         */
        Regulations.prototype.deletedAt = "";

        /**
         * Encodes the specified Regulations message. Does not implicitly {@link PlatformAdminApi.Regulations.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Regulations
         * @static
         * @param {PlatformAdminApi.IRegulations} message Regulations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Regulations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.Option.encode(message.options[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Regulations message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Regulations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Regulations} Regulations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Regulations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Regulations();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.Option.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.createdAt = reader.string();
                    break;
                case 6:
                    message.updatedAt = reader.string();
                    break;
                case 7:
                    message.isDeleted = reader.bool();
                    break;
                case 8:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Regulations;
    })();

    PlatformAdminApi.RegulationsResponse = (function() {

        /**
         * Properties of a RegulationsResponse.
         * @memberof PlatformAdminApi
         * @interface IRegulationsResponse
         * @property {PlatformAdminApi.IRegulations|null} [data] RegulationsResponse data
         */

        /**
         * Constructs a new RegulationsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RegulationsResponse.
         * @implements IRegulationsResponse
         * @constructor
         * @param {PlatformAdminApi.IRegulationsResponse=} [properties] Properties to set
         */
        function RegulationsResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegulationsResponse data.
         * @member {PlatformAdminApi.IRegulations|null|undefined} data
         * @memberof PlatformAdminApi.RegulationsResponse
         * @instance
         */
        RegulationsResponse.prototype.data = null;

        /**
         * Encodes the specified RegulationsResponse message. Does not implicitly {@link PlatformAdminApi.RegulationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RegulationsResponse
         * @static
         * @param {PlatformAdminApi.IRegulationsResponse} message RegulationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegulationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Regulations.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RegulationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RegulationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RegulationsResponse} RegulationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegulationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RegulationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Regulations.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RegulationsResponse;
    })();

    PlatformAdminApi.SearchRegulationsResponse = (function() {

        /**
         * Properties of a SearchRegulationsResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchRegulationsResponse
         * @property {Array.<PlatformAdminApi.IRegulations>|null} [data] SearchRegulationsResponse data
         * @property {number|null} [page] SearchRegulationsResponse page
         * @property {number|null} [pageCount] SearchRegulationsResponse pageCount
         * @property {number|null} [limit] SearchRegulationsResponse limit
         * @property {number|null} [count] SearchRegulationsResponse count
         * @property {number|null} [total] SearchRegulationsResponse total
         */

        /**
         * Constructs a new SearchRegulationsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchRegulationsResponse.
         * @implements ISearchRegulationsResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchRegulationsResponse=} [properties] Properties to set
         */
        function SearchRegulationsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchRegulationsResponse data.
         * @member {Array.<PlatformAdminApi.IRegulations>} data
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @instance
         */
        SearchRegulationsResponse.prototype.data = $util.emptyArray;

        /**
         * SearchRegulationsResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @instance
         */
        SearchRegulationsResponse.prototype.page = 0;

        /**
         * SearchRegulationsResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @instance
         */
        SearchRegulationsResponse.prototype.pageCount = 0;

        /**
         * SearchRegulationsResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @instance
         */
        SearchRegulationsResponse.prototype.limit = 0;

        /**
         * SearchRegulationsResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @instance
         */
        SearchRegulationsResponse.prototype.count = 0;

        /**
         * SearchRegulationsResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @instance
         */
        SearchRegulationsResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchRegulationsResponse message. Does not implicitly {@link PlatformAdminApi.SearchRegulationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @static
         * @param {PlatformAdminApi.ISearchRegulationsResponse} message SearchRegulationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchRegulationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Regulations.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchRegulationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchRegulationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchRegulationsResponse} SearchRegulationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchRegulationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchRegulationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Regulations.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchRegulationsResponse;
    })();

    PlatformAdminApi.RegulationsRelation = (function() {

        /**
         * Properties of a RegulationsRelation.
         * @memberof PlatformAdminApi
         * @interface IRegulationsRelation
         * @property {string|null} [id] RegulationsRelation id
         * @property {string|null} [title] RegulationsRelation title
         * @property {string|null} [name] RegulationsRelation name
         * @property {number|null} [duration] RegulationsRelation duration
         */

        /**
         * Constructs a new RegulationsRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RegulationsRelation.
         * @implements IRegulationsRelation
         * @constructor
         * @param {PlatformAdminApi.IRegulationsRelation=} [properties] Properties to set
         */
        function RegulationsRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegulationsRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.RegulationsRelation
         * @instance
         */
        RegulationsRelation.prototype.id = "";

        /**
         * RegulationsRelation title.
         * @member {string} title
         * @memberof PlatformAdminApi.RegulationsRelation
         * @instance
         */
        RegulationsRelation.prototype.title = "";

        /**
         * RegulationsRelation name.
         * @member {string} name
         * @memberof PlatformAdminApi.RegulationsRelation
         * @instance
         */
        RegulationsRelation.prototype.name = "";

        /**
         * RegulationsRelation duration.
         * @member {number} duration
         * @memberof PlatformAdminApi.RegulationsRelation
         * @instance
         */
        RegulationsRelation.prototype.duration = 0;

        /**
         * Encodes the specified RegulationsRelation message. Does not implicitly {@link PlatformAdminApi.RegulationsRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RegulationsRelation
         * @static
         * @param {PlatformAdminApi.IRegulationsRelation} message RegulationsRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegulationsRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.duration);
            return writer;
        };

        /**
         * Decodes a RegulationsRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RegulationsRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RegulationsRelation} RegulationsRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegulationsRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RegulationsRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.duration = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RegulationsRelation;
    })();

    PlatformAdminApi.BlockingReasonsService = (function() {

        /**
         * Constructs a new BlockingReasonsService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonsService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function BlockingReasonsService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (BlockingReasonsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BlockingReasonsService;

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonsService#create}.
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonResponse} [response] BlockingReasonResponse
         */

        /**
         * Запрос на создание объекта BlockingReason
         * @function create
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonCreateRequest} request BlockingReasonCreateRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonsService.CreateCallback} callback Node-style callback called with the error, if any, and BlockingReasonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonsService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.BlockingReasonCreateRequest, $root.PlatformAdminApi.BlockingReasonResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Запрос на создание объекта BlockingReason
         * @function create
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonCreateRequest} request BlockingReasonCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonsService#update}.
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonResponse} [response] BlockingReasonResponse
         */

        /**
         * Запрос на изменение объекта BlockingReason по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonUpdateRequest} request BlockingReasonUpdateRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonsService.UpdateCallback} callback Node-style callback called with the error, if any, and BlockingReasonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonsService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.BlockingReasonUpdateRequest, $root.PlatformAdminApi.BlockingReasonResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Запрос на изменение объекта BlockingReason по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonUpdateRequest} request BlockingReasonUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonsService#search}.
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonSearchResponse} [response] BlockingReasonSearchResponse
         */

        /**
         * Запрос на поиск массива объектов BlockingReason по параметрам
         * @function search
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonSearchRequest} request BlockingReasonSearchRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonsService.SearchCallback} callback Node-style callback called with the error, if any, and BlockingReasonSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonsService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.BlockingReasonSearchRequest, $root.PlatformAdminApi.BlockingReasonSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Запрос на поиск массива объектов BlockingReason по параметрам
         * @function search
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonSearchRequest} request BlockingReasonSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonsService#findById}.
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonResponse} [response] BlockingReasonResponse
         */

        /**
         * Запрос на получение объекта BlockingReason по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonsService.FindByIdCallback} callback Node-style callback called with the error, if any, and BlockingReasonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonsService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.BlockingReasonResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Запрос на получение объекта BlockingReason по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonsService#remove}.
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonResponse} [response] BlockingReasonResponse
         */

        /**
         * Запрос на удаление объекта BlockingReason по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonRemoveRequest} request BlockingReasonRemoveRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonsService.RemoveCallback} callback Node-style callback called with the error, if any, and BlockingReasonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonsService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.BlockingReasonRemoveRequest, $root.PlatformAdminApi.BlockingReasonResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Запрос на удаление объекта BlockingReason по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonRemoveRequest} request BlockingReasonRemoveRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.BlockingReasonsService#restore}.
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonResponse} [response] BlockingReasonResponse
         */

        /**
         * Запрос на восстановление объекта BlockingReason по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonRestoreRequest} request BlockingReasonRestoreRequest message or plain object
         * @param {PlatformAdminApi.BlockingReasonsService.RestoreCallback} callback Node-style callback called with the error, if any, and BlockingReasonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(BlockingReasonsService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.BlockingReasonRestoreRequest, $root.PlatformAdminApi.BlockingReasonResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Запрос на восстановление объекта BlockingReason по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.BlockingReasonsService
         * @instance
         * @param {PlatformAdminApi.IBlockingReasonRestoreRequest} request BlockingReasonRestoreRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonResponse>} Promise
         * @variation 2
         */

        return BlockingReasonsService;
    })();

    PlatformAdminApi.BlockingReasonResponse = (function() {

        /**
         * Properties of a BlockingReasonResponse.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonResponse
         * @property {PlatformAdminApi.IBlockingReason|null} [data] BlockingReasonResponse data
         */

        /**
         * Constructs a new BlockingReasonResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE для причины блокировки
         * @implements IBlockingReasonResponse
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonResponse=} [properties] Properties to set
         */
        function BlockingReasonResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonResponse data.
         * @member {PlatformAdminApi.IBlockingReason|null|undefined} data
         * @memberof PlatformAdminApi.BlockingReasonResponse
         * @instance
         */
        BlockingReasonResponse.prototype.data = null;

        /**
         * Encodes the specified BlockingReasonResponse message. Does not implicitly {@link PlatformAdminApi.BlockingReasonResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonResponse
         * @static
         * @param {PlatformAdminApi.IBlockingReasonResponse} message BlockingReasonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.BlockingReason.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonResponse} BlockingReasonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.BlockingReason.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonResponse;
    })();

    PlatformAdminApi.BlockingReasonSearchRequest = (function() {

        /**
         * Properties of a BlockingReasonSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonSearchRequest
         * @property {number|null} [page] BlockingReasonSearchRequest page
         * @property {number|null} [limit] BlockingReasonSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] BlockingReasonSearchRequest sort
         * @property {Array.<PlatformAdminApi.BlockingReasonSearchRequest.IWhereFields>|null} [where] BlockingReasonSearchRequest where
         */

        /**
         * Constructs a new BlockingReasonSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса всех причин блокировки
         * @implements IBlockingReasonSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonSearchRequest=} [properties] Properties to set
         */
        function BlockingReasonSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.BlockingReasonSearchRequest
         * @instance
         */
        BlockingReasonSearchRequest.prototype.page = 0;

        /**
         * BlockingReasonSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.BlockingReasonSearchRequest
         * @instance
         */
        BlockingReasonSearchRequest.prototype.limit = 0;

        /**
         * BlockingReasonSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.BlockingReasonSearchRequest
         * @instance
         */
        BlockingReasonSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * BlockingReasonSearchRequest where.
         * @member {Array.<PlatformAdminApi.BlockingReasonSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.BlockingReasonSearchRequest
         * @instance
         */
        BlockingReasonSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified BlockingReasonSearchRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonSearchRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonSearchRequest} message BlockingReasonSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonSearchRequest} BlockingReasonSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        BlockingReasonSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IStringCondition|null} [type] WhereFields type
             * @property {boolean|null} [isRecoverable] WhereFields isRecoverable
             * @property {boolean|null} [isAvailableForContractor] WhereFields isAvailableForContractor
             * @property {boolean|null} [isInstant] WhereFields isInstant
             * @property {boolean|null} [isNeedRetraining] WhereFields isNeedRetraining
             * @property {boolean|null} [isCommonBlock] WhereFields isCommonBlock
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {Array.<PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.IGroupWhereFields>|null} [group] WhereFields group
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.BlockingReasonSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                this.group = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields type.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} type
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.type = null;

            /**
             * WhereFields isRecoverable.
             * @member {boolean} isRecoverable
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isRecoverable = false;

            /**
             * WhereFields isAvailableForContractor.
             * @member {boolean} isAvailableForContractor
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isAvailableForContractor = false;

            /**
             * WhereFields isInstant.
             * @member {boolean} isInstant
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isInstant = false;

            /**
             * WhereFields isNeedRetraining.
             * @member {boolean} isNeedRetraining
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isNeedRetraining = false;

            /**
             * WhereFields isCommonBlock.
             * @member {boolean} isCommonBlock
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isCommonBlock = false;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields group.
             * @member {Array.<PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.IGroupWhereFields>} group
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.group = $util.emptyArray;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.BlockingReasonSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    $root.PlatformAdminApi.StringCondition.encode(message.type, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isRecoverable != null && Object.hasOwnProperty.call(message, "isRecoverable"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isRecoverable);
                if (message.isAvailableForContractor != null && Object.hasOwnProperty.call(message, "isAvailableForContractor"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isAvailableForContractor);
                if (message.isInstant != null && Object.hasOwnProperty.call(message, "isInstant"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isInstant);
                if (message.isNeedRetraining != null && Object.hasOwnProperty.call(message, "isNeedRetraining"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNeedRetraining);
                if (message.isCommonBlock != null && Object.hasOwnProperty.call(message, "isCommonBlock"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isCommonBlock);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.group != null && message.group.length)
                    for (var i = 0; i < message.group.length; ++i)
                        $root.PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields.encode(message.group[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.BlockingReasonSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.isDeleted = reader.bool();
                        break;
                    case 3:
                        message.type = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.isRecoverable = reader.bool();
                        break;
                    case 5:
                        message.isAvailableForContractor = reader.bool();
                        break;
                    case 6:
                        message.isInstant = reader.bool();
                        break;
                    case 7:
                        message.isNeedRetraining = reader.bool();
                        break;
                    case 8:
                        message.isCommonBlock = reader.bool();
                        break;
                    case 9:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 10:
                        if (!(message.group && message.group.length))
                            message.group = [];
                        message.group.push($root.PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            WhereFields.GroupWhereFields = (function() {

                /**
                 * Properties of a GroupWhereFields.
                 * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
                 * @interface IGroupWhereFields
                 * @property {boolean|null} [isDeleted] GroupWhereFields isDeleted
                 * @property {PlatformAdminApi.IStringCondition|null} [name] GroupWhereFields name
                 */

                /**
                 * Constructs a new GroupWhereFields.
                 * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields
                 * @classdesc Represents a GroupWhereFields.
                 * @implements IGroupWhereFields
                 * @constructor
                 * @param {PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.IGroupWhereFields=} [properties] Properties to set
                 */
                function GroupWhereFields(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GroupWhereFields isDeleted.
                 * @member {boolean} isDeleted
                 * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields
                 * @instance
                 */
                GroupWhereFields.prototype.isDeleted = false;

                /**
                 * GroupWhereFields name.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} name
                 * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields
                 * @instance
                 */
                GroupWhereFields.prototype.name = null;

                /**
                 * Encodes the specified GroupWhereFields message. Does not implicitly {@link PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields.verify|verify} messages.
                 * @function encode
                 * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields
                 * @static
                 * @param {PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.IGroupWhereFields} message GroupWhereFields message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GroupWhereFields.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isDeleted);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a GroupWhereFields message from the specified reader or buffer.
                 * @function decode
                 * @memberof PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields} GroupWhereFields
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GroupWhereFields.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonSearchRequest.WhereFields.GroupWhereFields();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isDeleted = reader.bool();
                            break;
                        case 2:
                            message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return GroupWhereFields;
            })();

            return WhereFields;
        })();

        return BlockingReasonSearchRequest;
    })();

    PlatformAdminApi.BlockingReasonSearchResponse = (function() {

        /**
         * Properties of a BlockingReasonSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonSearchResponse
         * @property {Array.<PlatformAdminApi.IBlockingReason>|null} [data] BlockingReasonSearchResponse data
         * @property {number|null} [page] BlockingReasonSearchResponse page
         * @property {number|null} [pageCount] BlockingReasonSearchResponse pageCount
         * @property {number|null} [limit] BlockingReasonSearchResponse limit
         * @property {number|null} [count] BlockingReasonSearchResponse count
         * @property {number|null} [total] BlockingReasonSearchResponse total
         */

        /**
         * Constructs a new BlockingReasonSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE при запросе для всех причин блокировки
         * @implements IBlockingReasonSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonSearchResponse=} [properties] Properties to set
         */
        function BlockingReasonSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonSearchResponse data.
         * @member {Array.<PlatformAdminApi.IBlockingReason>} data
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @instance
         */
        BlockingReasonSearchResponse.prototype.data = $util.emptyArray;

        /**
         * BlockingReasonSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @instance
         */
        BlockingReasonSearchResponse.prototype.page = 0;

        /**
         * BlockingReasonSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @instance
         */
        BlockingReasonSearchResponse.prototype.pageCount = 0;

        /**
         * BlockingReasonSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @instance
         */
        BlockingReasonSearchResponse.prototype.limit = 0;

        /**
         * BlockingReasonSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @instance
         */
        BlockingReasonSearchResponse.prototype.count = 0;

        /**
         * BlockingReasonSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @instance
         */
        BlockingReasonSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified BlockingReasonSearchResponse message. Does not implicitly {@link PlatformAdminApi.BlockingReasonSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @static
         * @param {PlatformAdminApi.IBlockingReasonSearchResponse} message BlockingReasonSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.BlockingReason.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a BlockingReasonSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonSearchResponse} BlockingReasonSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.BlockingReason.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonSearchResponse;
    })();

    PlatformAdminApi.BlockingReasonCreateRequest = (function() {

        /**
         * Properties of a BlockingReasonCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonCreateRequest
         * @property {string|null} [name] BlockingReasonCreateRequest name
         * @property {string|null} [type] BlockingReasonCreateRequest type
         * @property {boolean|null} [isAvailableForContractor] BlockingReasonCreateRequest isAvailableForContractor
         * @property {boolean|null} [isInstant] BlockingReasonCreateRequest isInstant
         * @property {boolean|null} [isNeedRetraining] BlockingReasonCreateRequest isNeedRetraining
         * @property {boolean|null} [isCommonBlock] BlockingReasonCreateRequest isCommonBlock
         * @property {boolean|null} [isPermanent] BlockingReasonCreateRequest isPermanent
         * @property {boolean|null} [isRecoverable] BlockingReasonCreateRequest isRecoverable
         * @property {PlatformAdminApi.IBlockingReasonGroupRelation|null} [group] BlockingReasonCreateRequest group
         */

        /**
         * Constructs a new BlockingReasonCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса создания причины блокировки
         * @implements IBlockingReasonCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonCreateRequest=} [properties] Properties to set
         */
        function BlockingReasonCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.name = "";

        /**
         * BlockingReasonCreateRequest type.
         * @member {string} type
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.type = "";

        /**
         * BlockingReasonCreateRequest isAvailableForContractor.
         * @member {boolean} isAvailableForContractor
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.isAvailableForContractor = false;

        /**
         * BlockingReasonCreateRequest isInstant.
         * @member {boolean} isInstant
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.isInstant = false;

        /**
         * BlockingReasonCreateRequest isNeedRetraining.
         * @member {boolean} isNeedRetraining
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.isNeedRetraining = false;

        /**
         * BlockingReasonCreateRequest isCommonBlock.
         * @member {boolean} isCommonBlock
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.isCommonBlock = false;

        /**
         * BlockingReasonCreateRequest isPermanent.
         * @member {boolean} isPermanent
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.isPermanent = false;

        /**
         * BlockingReasonCreateRequest isRecoverable.
         * @member {boolean} isRecoverable
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.isRecoverable = false;

        /**
         * BlockingReasonCreateRequest group.
         * @member {PlatformAdminApi.IBlockingReasonGroupRelation|null|undefined} group
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @instance
         */
        BlockingReasonCreateRequest.prototype.group = null;

        /**
         * Encodes the specified BlockingReasonCreateRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonCreateRequest} message BlockingReasonCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.isAvailableForContractor != null && Object.hasOwnProperty.call(message, "isAvailableForContractor"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isAvailableForContractor);
            if (message.isInstant != null && Object.hasOwnProperty.call(message, "isInstant"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isInstant);
            if (message.isNeedRetraining != null && Object.hasOwnProperty.call(message, "isNeedRetraining"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isNeedRetraining);
            if (message.isCommonBlock != null && Object.hasOwnProperty.call(message, "isCommonBlock"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isCommonBlock);
            if (message.isPermanent != null && Object.hasOwnProperty.call(message, "isPermanent"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isPermanent);
            if (message.isRecoverable != null && Object.hasOwnProperty.call(message, "isRecoverable"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isRecoverable);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.PlatformAdminApi.BlockingReasonGroupRelation.encode(message.group, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonCreateRequest} BlockingReasonCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.isAvailableForContractor = reader.bool();
                    break;
                case 4:
                    message.isInstant = reader.bool();
                    break;
                case 5:
                    message.isNeedRetraining = reader.bool();
                    break;
                case 6:
                    message.isCommonBlock = reader.bool();
                    break;
                case 7:
                    message.isPermanent = reader.bool();
                    break;
                case 8:
                    message.isRecoverable = reader.bool();
                    break;
                case 9:
                    message.group = $root.PlatformAdminApi.BlockingReasonGroupRelation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonCreateRequest;
    })();

    PlatformAdminApi.BlockingReasonUpdateRequest = (function() {

        /**
         * Properties of a BlockingReasonUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonUpdateRequest
         * @property {string|null} [id] BlockingReasonUpdateRequest id
         * @property {string|null} [name] BlockingReasonUpdateRequest name
         * @property {string|null} [type] BlockingReasonUpdateRequest type
         * @property {boolean|null} [isAvailableForContractor] BlockingReasonUpdateRequest isAvailableForContractor
         * @property {boolean|null} [isInstant] BlockingReasonUpdateRequest isInstant
         * @property {boolean|null} [isNeedRetraining] BlockingReasonUpdateRequest isNeedRetraining
         * @property {boolean|null} [isCommonBlock] BlockingReasonUpdateRequest isCommonBlock
         * @property {boolean|null} [isPermanent] BlockingReasonUpdateRequest isPermanent
         * @property {boolean|null} [isRecoverable] BlockingReasonUpdateRequest isRecoverable
         * @property {PlatformAdminApi.IBlockingReasonGroupRelation|null} [group] BlockingReasonUpdateRequest group
         */

        /**
         * Constructs a new BlockingReasonUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса редактирования причины блокировки
         * @implements IBlockingReasonUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonUpdateRequest=} [properties] Properties to set
         */
        function BlockingReasonUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.id = "";

        /**
         * BlockingReasonUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.name = "";

        /**
         * BlockingReasonUpdateRequest type.
         * @member {string} type
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.type = "";

        /**
         * BlockingReasonUpdateRequest isAvailableForContractor.
         * @member {boolean} isAvailableForContractor
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.isAvailableForContractor = false;

        /**
         * BlockingReasonUpdateRequest isInstant.
         * @member {boolean} isInstant
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.isInstant = false;

        /**
         * BlockingReasonUpdateRequest isNeedRetraining.
         * @member {boolean} isNeedRetraining
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.isNeedRetraining = false;

        /**
         * BlockingReasonUpdateRequest isCommonBlock.
         * @member {boolean} isCommonBlock
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.isCommonBlock = false;

        /**
         * BlockingReasonUpdateRequest isPermanent.
         * @member {boolean} isPermanent
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.isPermanent = false;

        /**
         * BlockingReasonUpdateRequest isRecoverable.
         * @member {boolean} isRecoverable
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.isRecoverable = false;

        /**
         * BlockingReasonUpdateRequest group.
         * @member {PlatformAdminApi.IBlockingReasonGroupRelation|null|undefined} group
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @instance
         */
        BlockingReasonUpdateRequest.prototype.group = null;

        /**
         * Encodes the specified BlockingReasonUpdateRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonUpdateRequest} message BlockingReasonUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.isAvailableForContractor != null && Object.hasOwnProperty.call(message, "isAvailableForContractor"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAvailableForContractor);
            if (message.isInstant != null && Object.hasOwnProperty.call(message, "isInstant"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isInstant);
            if (message.isNeedRetraining != null && Object.hasOwnProperty.call(message, "isNeedRetraining"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNeedRetraining);
            if (message.isCommonBlock != null && Object.hasOwnProperty.call(message, "isCommonBlock"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isCommonBlock);
            if (message.isPermanent != null && Object.hasOwnProperty.call(message, "isPermanent"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isPermanent);
            if (message.isRecoverable != null && Object.hasOwnProperty.call(message, "isRecoverable"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isRecoverable);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.PlatformAdminApi.BlockingReasonGroupRelation.encode(message.group, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonUpdateRequest} BlockingReasonUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.isAvailableForContractor = reader.bool();
                    break;
                case 5:
                    message.isInstant = reader.bool();
                    break;
                case 6:
                    message.isNeedRetraining = reader.bool();
                    break;
                case 7:
                    message.isCommonBlock = reader.bool();
                    break;
                case 8:
                    message.isPermanent = reader.bool();
                    break;
                case 9:
                    message.isRecoverable = reader.bool();
                    break;
                case 10:
                    message.group = $root.PlatformAdminApi.BlockingReasonGroupRelation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonUpdateRequest;
    })();

    PlatformAdminApi.BlockingReasonRemoveRequest = (function() {

        /**
         * Properties of a BlockingReasonRemoveRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonRemoveRequest
         * @property {string|null} [id] BlockingReasonRemoveRequest id
         */

        /**
         * Constructs a new BlockingReasonRemoveRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса удаления причины блокировки
         * @implements IBlockingReasonRemoveRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonRemoveRequest=} [properties] Properties to set
         */
        function BlockingReasonRemoveRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonRemoveRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonRemoveRequest
         * @instance
         */
        BlockingReasonRemoveRequest.prototype.id = "";

        /**
         * Encodes the specified BlockingReasonRemoveRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonRemoveRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonRemoveRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonRemoveRequest} message BlockingReasonRemoveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonRemoveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a BlockingReasonRemoveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonRemoveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonRemoveRequest} BlockingReasonRemoveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonRemoveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonRemoveRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonRemoveRequest;
    })();

    PlatformAdminApi.BlockingReasonRestoreRequest = (function() {

        /**
         * Properties of a BlockingReasonRestoreRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonRestoreRequest
         * @property {string|null} [id] BlockingReasonRestoreRequest id
         */

        /**
         * Constructs a new BlockingReasonRestoreRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса восстановления причины блокировки
         * @implements IBlockingReasonRestoreRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonRestoreRequest=} [properties] Properties to set
         */
        function BlockingReasonRestoreRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonRestoreRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonRestoreRequest
         * @instance
         */
        BlockingReasonRestoreRequest.prototype.id = "";

        /**
         * Encodes the specified BlockingReasonRestoreRequest message. Does not implicitly {@link PlatformAdminApi.BlockingReasonRestoreRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonRestoreRequest
         * @static
         * @param {PlatformAdminApi.IBlockingReasonRestoreRequest} message BlockingReasonRestoreRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonRestoreRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a BlockingReasonRestoreRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonRestoreRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonRestoreRequest} BlockingReasonRestoreRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonRestoreRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonRestoreRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonRestoreRequest;
    })();

    PlatformAdminApi.BlockingReason = (function() {

        /**
         * Properties of a BlockingReason.
         * @memberof PlatformAdminApi
         * @interface IBlockingReason
         * @property {string|null} [id] BlockingReason id
         * @property {string|null} [name] BlockingReason name
         * @property {string|null} [type] BlockingReason type
         * @property {boolean|null} [isAvailableForContractor] BlockingReason isAvailableForContractor
         * @property {boolean|null} [isInstant] BlockingReason isInstant
         * @property {boolean|null} [isNeedRetraining] BlockingReason isNeedRetraining
         * @property {boolean|null} [isCommonBlock] BlockingReason isCommonBlock
         * @property {boolean|null} [isPermanent] BlockingReason isPermanent
         * @property {boolean|null} [isRecoverable] BlockingReason isRecoverable
         * @property {PlatformAdminApi.IBlockingReasonGroupRelation|null} [group] BlockingReason group
         * @property {string|null} [createdAt] BlockingReason createdAt
         * @property {string|null} [updatedAt] BlockingReason updatedAt
         * @property {boolean|null} [isDeleted] BlockingReason isDeleted
         * @property {string|null} [deletedAt] BlockingReason deletedAt
         */

        /**
         * Constructs a new BlockingReason.
         * @memberof PlatformAdminApi
         * @classdesc Модель BlockingReason
         * @implements IBlockingReason
         * @constructor
         * @param {PlatformAdminApi.IBlockingReason=} [properties] Properties to set
         */
        function BlockingReason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReason id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.id = "";

        /**
         * BlockingReason name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.name = "";

        /**
         * BlockingReason type.
         * @member {string} type
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.type = "";

        /**
         * BlockingReason isAvailableForContractor.
         * @member {boolean} isAvailableForContractor
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.isAvailableForContractor = false;

        /**
         * BlockingReason isInstant.
         * @member {boolean} isInstant
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.isInstant = false;

        /**
         * BlockingReason isNeedRetraining.
         * @member {boolean} isNeedRetraining
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.isNeedRetraining = false;

        /**
         * BlockingReason isCommonBlock.
         * @member {boolean} isCommonBlock
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.isCommonBlock = false;

        /**
         * BlockingReason isPermanent.
         * @member {boolean} isPermanent
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.isPermanent = false;

        /**
         * BlockingReason isRecoverable.
         * @member {boolean} isRecoverable
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.isRecoverable = false;

        /**
         * BlockingReason group.
         * @member {PlatformAdminApi.IBlockingReasonGroupRelation|null|undefined} group
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.group = null;

        /**
         * BlockingReason createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.createdAt = "";

        /**
         * BlockingReason updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.updatedAt = "";

        /**
         * BlockingReason isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.isDeleted = false;

        /**
         * BlockingReason deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.BlockingReason
         * @instance
         */
        BlockingReason.prototype.deletedAt = "";

        /**
         * Encodes the specified BlockingReason message. Does not implicitly {@link PlatformAdminApi.BlockingReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReason
         * @static
         * @param {PlatformAdminApi.IBlockingReason} message BlockingReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.isAvailableForContractor != null && Object.hasOwnProperty.call(message, "isAvailableForContractor"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAvailableForContractor);
            if (message.isInstant != null && Object.hasOwnProperty.call(message, "isInstant"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isInstant);
            if (message.isNeedRetraining != null && Object.hasOwnProperty.call(message, "isNeedRetraining"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNeedRetraining);
            if (message.isCommonBlock != null && Object.hasOwnProperty.call(message, "isCommonBlock"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isCommonBlock);
            if (message.isPermanent != null && Object.hasOwnProperty.call(message, "isPermanent"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isPermanent);
            if (message.isRecoverable != null && Object.hasOwnProperty.call(message, "isRecoverable"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isRecoverable);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.PlatformAdminApi.BlockingReasonGroupRelation.encode(message.group, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a BlockingReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReason} BlockingReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.isAvailableForContractor = reader.bool();
                    break;
                case 5:
                    message.isInstant = reader.bool();
                    break;
                case 6:
                    message.isNeedRetraining = reader.bool();
                    break;
                case 7:
                    message.isCommonBlock = reader.bool();
                    break;
                case 8:
                    message.isPermanent = reader.bool();
                    break;
                case 9:
                    message.isRecoverable = reader.bool();
                    break;
                case 10:
                    message.group = $root.PlatformAdminApi.BlockingReasonGroupRelation.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.createdAt = reader.string();
                    break;
                case 12:
                    message.updatedAt = reader.string();
                    break;
                case 13:
                    message.isDeleted = reader.bool();
                    break;
                case 14:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReason;
    })();

    PlatformAdminApi.BlockingReasonRelation = (function() {

        /**
         * Properties of a BlockingReasonRelation.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonRelation
         * @property {string|null} [id] BlockingReasonRelation id
         * @property {string|null} [name] BlockingReasonRelation name
         * @property {string|null} [type] BlockingReasonRelation type
         */

        /**
         * Constructs a new BlockingReasonRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonRelation.
         * @implements IBlockingReasonRelation
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonRelation=} [properties] Properties to set
         */
        function BlockingReasonRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonRelation
         * @instance
         */
        BlockingReasonRelation.prototype.id = "";

        /**
         * BlockingReasonRelation name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonRelation
         * @instance
         */
        BlockingReasonRelation.prototype.name = "";

        /**
         * BlockingReasonRelation type.
         * @member {string} type
         * @memberof PlatformAdminApi.BlockingReasonRelation
         * @instance
         */
        BlockingReasonRelation.prototype.type = "";

        /**
         * Encodes the specified BlockingReasonRelation message. Does not implicitly {@link PlatformAdminApi.BlockingReasonRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonRelation
         * @static
         * @param {PlatformAdminApi.IBlockingReasonRelation} message BlockingReasonRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            return writer;
        };

        /**
         * Decodes a BlockingReasonRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonRelation} BlockingReasonRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonRelation;
    })();

    /**
     * Enum для BLOCKING_TYPE
     * @see
     * {@link https://gitlab.qleanlabs.ru/platform/hrm/hrm-core-svc/-/blob/master/src/domain/status-type.enum.ts GitLab}
     * @name PlatformAdminApi.BlockingType
     * @enum {number}
     * @property {number} FREEZE=0 FREEZE value
     * @property {number} BLOCK=1 BLOCK value
     */
    PlatformAdminApi.BlockingType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FREEZE"] = 0;
        values[valuesById[1] = "BLOCK"] = 1;
        return values;
    })();

    PlatformAdminApi.WageService = (function() {

        /**
         * Constructs a new WageService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function WageService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (WageService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WageService;

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#create}.
         * @memberof PlatformAdminApi.WageService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageResponse} [response] WageResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageCreatePage} request WageCreatePage message or plain object
         * @param {PlatformAdminApi.WageService.CreateCallback} callback Node-style callback called with the error, if any, and WageResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.WageCreatePage, $root.PlatformAdminApi.WageResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageCreatePage} request WageCreatePage message or plain object
         * @returns {Promise<PlatformAdminApi.WageResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#update}.
         * @memberof PlatformAdminApi.WageService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageResponse} [response] WageResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageEditPage} request WageEditPage message or plain object
         * @param {PlatformAdminApi.WageService.UpdateCallback} callback Node-style callback called with the error, if any, and WageResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.WageEditPage, $root.PlatformAdminApi.WageResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageEditPage} request WageEditPage message or plain object
         * @returns {Promise<PlatformAdminApi.WageResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#search}.
         * @memberof PlatformAdminApi.WageService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WagesList} [response] WagesList
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSearchRequest} request WageSearchRequest message or plain object
         * @param {PlatformAdminApi.WageService.SearchCallback} callback Node-style callback called with the error, if any, and WagesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.WageSearchRequest, $root.PlatformAdminApi.WagesList, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSearchRequest} request WageSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WagesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#findById}.
         * @memberof PlatformAdminApi.WageService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageViewResponse} [response] WageViewResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.WageService.FindByIdCallback} callback Node-style callback called with the error, if any, and WageViewResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.WageViewResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageViewResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#remove}.
         * @memberof PlatformAdminApi.WageService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageViewResponse} [response] WageViewResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.WageService.RemoveCallback} callback Node-style callback called with the error, if any, and WageViewResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.WageViewResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageViewResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#restore}.
         * @memberof PlatformAdminApi.WageService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageViewResponse} [response] WageViewResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.WageService.RestoreCallback} callback Node-style callback called with the error, if any, and WageViewResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.WageViewResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageViewResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#retrieveEditWage}.
         * @memberof PlatformAdminApi.WageService
         * @typedef RetrieveEditWageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageEditPage} [response] WageEditPage
         */

        /**
         * Calls RetrieveEditWage.
         * @function retrieveEditWage
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.WageService.RetrieveEditWageCallback} callback Node-style callback called with the error, if any, and WageEditPage
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.retrieveEditWage = function retrieveEditWage(request, callback) {
            return this.rpcCall(retrieveEditWage, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.WageEditPage, request, callback);
        }, "name", { value: "RetrieveEditWage" });

        /**
         * Calls RetrieveEditWage.
         * @function retrieveEditWage
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageEditPage>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#suggestSpecializations}.
         * @memberof PlatformAdminApi.WageService
         * @typedef SuggestSpecializationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageSuggestSpecializationsResponse} [response] WageSuggestSpecializationsResponse
         */

        /**
         * Calls SuggestSpecializations.
         * @function suggestSpecializations
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestSpecializationsRequest} request WageSuggestSpecializationsRequest message or plain object
         * @param {PlatformAdminApi.WageService.SuggestSpecializationsCallback} callback Node-style callback called with the error, if any, and WageSuggestSpecializationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.suggestSpecializations = function suggestSpecializations(request, callback) {
            return this.rpcCall(suggestSpecializations, $root.PlatformAdminApi.WageSuggestSpecializationsRequest, $root.PlatformAdminApi.WageSuggestSpecializationsResponse, request, callback);
        }, "name", { value: "SuggestSpecializations" });

        /**
         * Calls SuggestSpecializations.
         * @function suggestSpecializations
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestSpecializationsRequest} request WageSuggestSpecializationsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageSuggestSpecializationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#suggestRegulations}.
         * @memberof PlatformAdminApi.WageService
         * @typedef SuggestRegulationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageSuggestRegulationsResponse} [response] WageSuggestRegulationsResponse
         */

        /**
         * Calls SuggestRegulations.
         * @function suggestRegulations
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestRegulationsRequest} request WageSuggestRegulationsRequest message or plain object
         * @param {PlatformAdminApi.WageService.SuggestRegulationsCallback} callback Node-style callback called with the error, if any, and WageSuggestRegulationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.suggestRegulations = function suggestRegulations(request, callback) {
            return this.rpcCall(suggestRegulations, $root.PlatformAdminApi.WageSuggestRegulationsRequest, $root.PlatformAdminApi.WageSuggestRegulationsResponse, request, callback);
        }, "name", { value: "SuggestRegulations" });

        /**
         * Calls SuggestRegulations.
         * @function suggestRegulations
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestRegulationsRequest} request WageSuggestRegulationsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageSuggestRegulationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#suggestPositions}.
         * @memberof PlatformAdminApi.WageService
         * @typedef SuggestPositionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageSuggestPositionsResponse} [response] WageSuggestPositionsResponse
         */

        /**
         * Calls SuggestPositions.
         * @function suggestPositions
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestPositionsRequest} request WageSuggestPositionsRequest message or plain object
         * @param {PlatformAdminApi.WageService.SuggestPositionsCallback} callback Node-style callback called with the error, if any, and WageSuggestPositionsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.suggestPositions = function suggestPositions(request, callback) {
            return this.rpcCall(suggestPositions, $root.PlatformAdminApi.WageSuggestPositionsRequest, $root.PlatformAdminApi.WageSuggestPositionsResponse, request, callback);
        }, "name", { value: "SuggestPositions" });

        /**
         * Calls SuggestPositions.
         * @function suggestPositions
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestPositionsRequest} request WageSuggestPositionsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageSuggestPositionsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#suggestRegions}.
         * @memberof PlatformAdminApi.WageService
         * @typedef SuggestRegionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageSuggestRegionsResponse} [response] WageSuggestRegionsResponse
         */

        /**
         * Calls SuggestRegions.
         * @function suggestRegions
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestRegionsRequest} request WageSuggestRegionsRequest message or plain object
         * @param {PlatformAdminApi.WageService.SuggestRegionsCallback} callback Node-style callback called with the error, if any, and WageSuggestRegionsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.suggestRegions = function suggestRegions(request, callback) {
            return this.rpcCall(suggestRegions, $root.PlatformAdminApi.WageSuggestRegionsRequest, $root.PlatformAdminApi.WageSuggestRegionsResponse, request, callback);
        }, "name", { value: "SuggestRegions" });

        /**
         * Calls SuggestRegions.
         * @function suggestRegions
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestRegionsRequest} request WageSuggestRegionsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageSuggestRegionsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WageService#suggestOptions}.
         * @memberof PlatformAdminApi.WageService
         * @typedef SuggestOptionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageSuggestOptionsResponse} [response] WageSuggestOptionsResponse
         */

        /**
         * Calls SuggestOptions.
         * @function suggestOptions
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestOptionsRequest} request WageSuggestOptionsRequest message or plain object
         * @param {PlatformAdminApi.WageService.SuggestOptionsCallback} callback Node-style callback called with the error, if any, and WageSuggestOptionsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WageService.prototype.suggestOptions = function suggestOptions(request, callback) {
            return this.rpcCall(suggestOptions, $root.PlatformAdminApi.WageSuggestOptionsRequest, $root.PlatformAdminApi.WageSuggestOptionsResponse, request, callback);
        }, "name", { value: "SuggestOptions" });

        /**
         * Calls SuggestOptions.
         * @function suggestOptions
         * @memberof PlatformAdminApi.WageService
         * @instance
         * @param {PlatformAdminApi.IWageSuggestOptionsRequest} request WageSuggestOptionsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageSuggestOptionsResponse>} Promise
         * @variation 2
         */

        return WageService;
    })();

    PlatformAdminApi.Grade = (function() {

        /**
         * Properties of a Grade.
         * @memberof PlatformAdminApi
         * @interface IGrade
         * @property {string|null} [id] Grade id
         * @property {PlatformAdminApi.IPositionRelation|null} [position] Grade position
         * @property {PlatformAdminApi.IRate|null} [rate] Grade rate
         * @property {Array.<PlatformAdminApi.ICompensation>|null} [compensations] Grade compensations
         * @property {Array.<PlatformAdminApi.IAdditionalRate>|null} [additionalRates] Grade additionalRates
         */

        /**
         * Constructs a new Grade.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Grade.
         * @implements IGrade
         * @constructor
         * @param {PlatformAdminApi.IGrade=} [properties] Properties to set
         */
        function Grade(properties) {
            this.compensations = [];
            this.additionalRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Grade id.
         * @member {string} id
         * @memberof PlatformAdminApi.Grade
         * @instance
         */
        Grade.prototype.id = "";

        /**
         * Grade position.
         * @member {PlatformAdminApi.IPositionRelation|null|undefined} position
         * @memberof PlatformAdminApi.Grade
         * @instance
         */
        Grade.prototype.position = null;

        /**
         * Grade rate.
         * @member {PlatformAdminApi.IRate|null|undefined} rate
         * @memberof PlatformAdminApi.Grade
         * @instance
         */
        Grade.prototype.rate = null;

        /**
         * Grade compensations.
         * @member {Array.<PlatformAdminApi.ICompensation>} compensations
         * @memberof PlatformAdminApi.Grade
         * @instance
         */
        Grade.prototype.compensations = $util.emptyArray;

        /**
         * Grade additionalRates.
         * @member {Array.<PlatformAdminApi.IAdditionalRate>} additionalRates
         * @memberof PlatformAdminApi.Grade
         * @instance
         */
        Grade.prototype.additionalRates = $util.emptyArray;

        /**
         * Encodes the specified Grade message. Does not implicitly {@link PlatformAdminApi.Grade.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Grade
         * @static
         * @param {PlatformAdminApi.IGrade} message Grade message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Grade.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                $root.PlatformAdminApi.PositionRelation.encode(message.position, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                $root.PlatformAdminApi.Rate.encode(message.rate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.compensations != null && message.compensations.length)
                for (var i = 0; i < message.compensations.length; ++i)
                    $root.PlatformAdminApi.Compensation.encode(message.compensations[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.additionalRates != null && message.additionalRates.length)
                for (var i = 0; i < message.additionalRates.length; ++i)
                    $root.PlatformAdminApi.AdditionalRate.encode(message.additionalRates[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Grade message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Grade
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Grade} Grade
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Grade.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Grade();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.position = $root.PlatformAdminApi.PositionRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.rate = $root.PlatformAdminApi.Rate.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.compensations && message.compensations.length))
                        message.compensations = [];
                    message.compensations.push($root.PlatformAdminApi.Compensation.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.additionalRates && message.additionalRates.length))
                        message.additionalRates = [];
                    message.additionalRates.push($root.PlatformAdminApi.AdditionalRate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Grade;
    })();

    PlatformAdminApi.GradeRelation = (function() {

        /**
         * Properties of a GradeRelation.
         * @memberof PlatformAdminApi
         * @interface IGradeRelation
         * @property {string|null} [id] GradeRelation id
         */

        /**
         * Constructs a new GradeRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GradeRelation.
         * @implements IGradeRelation
         * @constructor
         * @param {PlatformAdminApi.IGradeRelation=} [properties] Properties to set
         */
        function GradeRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GradeRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.GradeRelation
         * @instance
         */
        GradeRelation.prototype.id = "";

        /**
         * Encodes the specified GradeRelation message. Does not implicitly {@link PlatformAdminApi.GradeRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GradeRelation
         * @static
         * @param {PlatformAdminApi.IGradeRelation} message GradeRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GradeRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a GradeRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GradeRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GradeRelation} GradeRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GradeRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GradeRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GradeRelation;
    })();

    PlatformAdminApi.Rate = (function() {

        /**
         * Properties of a Rate.
         * @memberof PlatformAdminApi
         * @interface IRate
         * @property {number|null} [amount] Rate amount
         * @property {string|null} [type] Rate type
         * @property {string|null} [unit] Rate unit
         */

        /**
         * Constructs a new Rate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Rate.
         * @implements IRate
         * @constructor
         * @param {PlatformAdminApi.IRate=} [properties] Properties to set
         */
        function Rate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Rate amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.Rate
         * @instance
         */
        Rate.prototype.amount = 0;

        /**
         * Rate type.
         * @member {string} type
         * @memberof PlatformAdminApi.Rate
         * @instance
         */
        Rate.prototype.type = "";

        /**
         * Rate unit.
         * @member {string} unit
         * @memberof PlatformAdminApi.Rate
         * @instance
         */
        Rate.prototype.unit = "";

        /**
         * Encodes the specified Rate message. Does not implicitly {@link PlatformAdminApi.Rate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Rate
         * @static
         * @param {PlatformAdminApi.IRate} message Rate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.amount);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.unit);
            return writer;
        };

        /**
         * Decodes a Rate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Rate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Rate} Rate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Rate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.amount = reader.int32();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.unit = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Rate;
    })();

    PlatformAdminApi.Compensation = (function() {

        /**
         * Properties of a Compensation.
         * @memberof PlatformAdminApi
         * @interface ICompensation
         * @property {string|null} [id] Compensation id
         * @property {number|null} [amount] Compensation amount
         * @property {string|null} [type] Compensation type
         * @property {PlatformAdminApi.IOption|null} [option] Compensation option
         */

        /**
         * Constructs a new Compensation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Compensation.
         * @implements ICompensation
         * @constructor
         * @param {PlatformAdminApi.ICompensation=} [properties] Properties to set
         */
        function Compensation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Compensation id.
         * @member {string} id
         * @memberof PlatformAdminApi.Compensation
         * @instance
         */
        Compensation.prototype.id = "";

        /**
         * Compensation amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.Compensation
         * @instance
         */
        Compensation.prototype.amount = 0;

        /**
         * Compensation type.
         * @member {string} type
         * @memberof PlatformAdminApi.Compensation
         * @instance
         */
        Compensation.prototype.type = "";

        /**
         * Compensation option.
         * @member {PlatformAdminApi.IOption|null|undefined} option
         * @memberof PlatformAdminApi.Compensation
         * @instance
         */
        Compensation.prototype.option = null;

        /**
         * Encodes the specified Compensation message. Does not implicitly {@link PlatformAdminApi.Compensation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Compensation
         * @static
         * @param {PlatformAdminApi.ICompensation} message Compensation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Compensation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.Option.encode(message.option, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Compensation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Compensation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Compensation} Compensation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Compensation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Compensation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.option = $root.PlatformAdminApi.Option.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Compensation;
    })();

    PlatformAdminApi.AdditionalRate = (function() {

        /**
         * Properties of an AdditionalRate.
         * @memberof PlatformAdminApi
         * @interface IAdditionalRate
         * @property {string|null} [id] AdditionalRate id
         * @property {string|null} [type] AdditionalRate type
         * @property {number|null} [amount] AdditionalRate amount
         * @property {string|null} [regionId] AdditionalRate regionId
         */

        /**
         * Constructs a new AdditionalRate.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalRate.
         * @implements IAdditionalRate
         * @constructor
         * @param {PlatformAdminApi.IAdditionalRate=} [properties] Properties to set
         */
        function AdditionalRate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalRate id.
         * @member {string} id
         * @memberof PlatformAdminApi.AdditionalRate
         * @instance
         */
        AdditionalRate.prototype.id = "";

        /**
         * AdditionalRate type.
         * @member {string} type
         * @memberof PlatformAdminApi.AdditionalRate
         * @instance
         */
        AdditionalRate.prototype.type = "";

        /**
         * AdditionalRate amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AdditionalRate
         * @instance
         */
        AdditionalRate.prototype.amount = 0;

        /**
         * AdditionalRate regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.AdditionalRate
         * @instance
         */
        AdditionalRate.prototype.regionId = "";

        /**
         * Encodes the specified AdditionalRate message. Does not implicitly {@link PlatformAdminApi.AdditionalRate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalRate
         * @static
         * @param {PlatformAdminApi.IAdditionalRate} message AdditionalRate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalRate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.regionId);
            return writer;
        };

        /**
         * Decodes an AdditionalRate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalRate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalRate} AdditionalRate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalRate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalRate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.regionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalRate;
    })();

    PlatformAdminApi.Relation = (function() {

        /**
         * Properties of a Relation.
         * @memberof PlatformAdminApi
         * @interface IRelation
         * @property {string|null} [id] Relation id
         */

        /**
         * Constructs a new Relation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Relation.
         * @implements IRelation
         * @constructor
         * @param {PlatformAdminApi.IRelation=} [properties] Properties to set
         */
        function Relation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Relation id.
         * @member {string} id
         * @memberof PlatformAdminApi.Relation
         * @instance
         */
        Relation.prototype.id = "";

        /**
         * Encodes the specified Relation message. Does not implicitly {@link PlatformAdminApi.Relation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Relation
         * @static
         * @param {PlatformAdminApi.IRelation} message Relation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Relation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a Relation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Relation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Relation} Relation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Relation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Relation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Relation;
    })();

    PlatformAdminApi.Product = (function() {

        /**
         * Properties of a Product.
         * @memberof PlatformAdminApi
         * @interface IProduct
         * @property {string|null} [id] Product id
         * @property {string|null} [title] Product title
         * @property {string|null} [name] Product name
         */

        /**
         * Constructs a new Product.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Product.
         * @implements IProduct
         * @constructor
         * @param {PlatformAdminApi.IProduct=} [properties] Properties to set
         */
        function Product(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Product id.
         * @member {string} id
         * @memberof PlatformAdminApi.Product
         * @instance
         */
        Product.prototype.id = "";

        /**
         * Product title.
         * @member {string} title
         * @memberof PlatformAdminApi.Product
         * @instance
         */
        Product.prototype.title = "";

        /**
         * Product name.
         * @member {string} name
         * @memberof PlatformAdminApi.Product
         * @instance
         */
        Product.prototype.name = "";

        /**
         * Encodes the specified Product message. Does not implicitly {@link PlatformAdminApi.Product.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Product
         * @static
         * @param {PlatformAdminApi.IProduct} message Product message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Product.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a Product message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Product
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Product} Product
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Product.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Product();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Product;
    })();

    PlatformAdminApi.PositionRelation = (function() {

        /**
         * Properties of a PositionRelation.
         * @memberof PlatformAdminApi
         * @interface IPositionRelation
         * @property {string|null} [id] PositionRelation id
         * @property {string|null} [title] PositionRelation title
         * @property {string|null} [name] PositionRelation name
         */

        /**
         * Constructs a new PositionRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PositionRelation.
         * @implements IPositionRelation
         * @constructor
         * @param {PlatformAdminApi.IPositionRelation=} [properties] Properties to set
         */
        function PositionRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PositionRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.PositionRelation
         * @instance
         */
        PositionRelation.prototype.id = "";

        /**
         * PositionRelation title.
         * @member {string} title
         * @memberof PlatformAdminApi.PositionRelation
         * @instance
         */
        PositionRelation.prototype.title = "";

        /**
         * PositionRelation name.
         * @member {string} name
         * @memberof PlatformAdminApi.PositionRelation
         * @instance
         */
        PositionRelation.prototype.name = "";

        /**
         * Encodes the specified PositionRelation message. Does not implicitly {@link PlatformAdminApi.PositionRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PositionRelation
         * @static
         * @param {PlatformAdminApi.IPositionRelation} message PositionRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PositionRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a PositionRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PositionRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PositionRelation} PositionRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PositionRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PositionRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PositionRelation;
    })();

    PlatformAdminApi.WageResponse = (function() {

        /**
         * Properties of a WageResponse.
         * @memberof PlatformAdminApi
         * @interface IWageResponse
         * @property {PlatformAdminApi.IWage|null} [data] WageResponse data
         */

        /**
         * Constructs a new WageResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageResponse.
         * @implements IWageResponse
         * @constructor
         * @param {PlatformAdminApi.IWageResponse=} [properties] Properties to set
         */
        function WageResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageResponse data.
         * @member {PlatformAdminApi.IWage|null|undefined} data
         * @memberof PlatformAdminApi.WageResponse
         * @instance
         */
        WageResponse.prototype.data = null;

        /**
         * Encodes the specified WageResponse message. Does not implicitly {@link PlatformAdminApi.WageResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageResponse
         * @static
         * @param {PlatformAdminApi.IWageResponse} message WageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Wage.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageResponse} WageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Wage.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageResponse;
    })();

    PlatformAdminApi.Wage = (function() {

        /**
         * Properties of a Wage.
         * @memberof PlatformAdminApi
         * @interface IWage
         * @property {string|null} [id] Wage id
         * @property {string|null} [createdAt] Wage createdAt
         * @property {string|null} [updatedAt] Wage updatedAt
         * @property {boolean|null} [isDeleted] Wage isDeleted
         * @property {string|null} [deletedAt] Wage deletedAt
         * @property {string|null} [name] Wage name
         * @property {PlatformAdminApi.IProduct|null} [product] Wage product
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] Wage specialization
         * @property {Array.<PlatformAdminApi.IGrade>|null} [grades] Wage grades
         * @property {string|null} [regionId] Wage regionId
         * @property {string|null} [type] Wage type
         */

        /**
         * Constructs a new Wage.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Wage.
         * @implements IWage
         * @constructor
         * @param {PlatformAdminApi.IWage=} [properties] Properties to set
         */
        function Wage(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Wage id.
         * @member {string} id
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.id = "";

        /**
         * Wage createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.createdAt = "";

        /**
         * Wage updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.updatedAt = "";

        /**
         * Wage isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.isDeleted = false;

        /**
         * Wage deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.deletedAt = "";

        /**
         * Wage name.
         * @member {string} name
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.name = "";

        /**
         * Wage product.
         * @member {PlatformAdminApi.IProduct|null|undefined} product
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.product = null;

        /**
         * Wage specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.specialization = null;

        /**
         * Wage grades.
         * @member {Array.<PlatformAdminApi.IGrade>} grades
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.grades = $util.emptyArray;

        /**
         * Wage regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.regionId = "";

        /**
         * Wage type.
         * @member {string} type
         * @memberof PlatformAdminApi.Wage
         * @instance
         */
        Wage.prototype.type = "";

        /**
         * Encodes the specified Wage message. Does not implicitly {@link PlatformAdminApi.Wage.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Wage
         * @static
         * @param {PlatformAdminApi.IWage} message Wage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Wage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.PlatformAdminApi.Product.encode(message.product, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.Grade.encode(message.grades[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.regionId);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.type);
            return writer;
        };

        /**
         * Decodes a Wage message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Wage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Wage} Wage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Wage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Wage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.product = $root.PlatformAdminApi.Product.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 9:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.Grade.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.regionId = reader.string();
                    break;
                case 11:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Wage;
    })();

    PlatformAdminApi.WageGradeAdditionalRates = (function() {

        /**
         * Properties of a WageGradeAdditionalRates.
         * @memberof PlatformAdminApi
         * @interface IWageGradeAdditionalRates
         */

        /**
         * Constructs a new WageGradeAdditionalRates.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageGradeAdditionalRates.
         * @implements IWageGradeAdditionalRates
         * @constructor
         * @param {PlatformAdminApi.IWageGradeAdditionalRates=} [properties] Properties to set
         */
        function WageGradeAdditionalRates(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified WageGradeAdditionalRates message. Does not implicitly {@link PlatformAdminApi.WageGradeAdditionalRates.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageGradeAdditionalRates
         * @static
         * @param {PlatformAdminApi.IWageGradeAdditionalRates} message WageGradeAdditionalRates message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageGradeAdditionalRates.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a WageGradeAdditionalRates message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageGradeAdditionalRates
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageGradeAdditionalRates} WageGradeAdditionalRates
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageGradeAdditionalRates.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageGradeAdditionalRates();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageGradeAdditionalRates;
    })();

    PlatformAdminApi.WageRegulationRel = (function() {

        /**
         * Properties of a WageRegulationRel.
         * @memberof PlatformAdminApi
         * @interface IWageRegulationRel
         * @property {string|null} [id] WageRegulationRel id
         * @property {string|null} [title] WageRegulationRel title
         * @property {string|null} [name] WageRegulationRel name
         * @property {boolean|null} [isDeleted] WageRegulationRel isDeleted
         */

        /**
         * Constructs a new WageRegulationRel.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageRegulationRel.
         * @implements IWageRegulationRel
         * @constructor
         * @param {PlatformAdminApi.IWageRegulationRel=} [properties] Properties to set
         */
        function WageRegulationRel(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageRegulationRel id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageRegulationRel
         * @instance
         */
        WageRegulationRel.prototype.id = "";

        /**
         * WageRegulationRel title.
         * @member {string} title
         * @memberof PlatformAdminApi.WageRegulationRel
         * @instance
         */
        WageRegulationRel.prototype.title = "";

        /**
         * WageRegulationRel name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageRegulationRel
         * @instance
         */
        WageRegulationRel.prototype.name = "";

        /**
         * WageRegulationRel isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.WageRegulationRel
         * @instance
         */
        WageRegulationRel.prototype.isDeleted = false;

        /**
         * Encodes the specified WageRegulationRel message. Does not implicitly {@link PlatformAdminApi.WageRegulationRel.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageRegulationRel
         * @static
         * @param {PlatformAdminApi.IWageRegulationRel} message WageRegulationRel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageRegulationRel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a WageRegulationRel message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageRegulationRel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageRegulationRel} WageRegulationRel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageRegulationRel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageRegulationRel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 7:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageRegulationRel;
    })();

    PlatformAdminApi.WageRelation = (function() {

        /**
         * Properties of a WageRelation.
         * @memberof PlatformAdminApi
         * @interface IWageRelation
         * @property {string|null} [id] WageRelation id
         * @property {string|null} [name] WageRelation name
         * @property {Array.<PlatformAdminApi.IGrade>|null} [grades] WageRelation grades
         */

        /**
         * Constructs a new WageRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageRelation.
         * @implements IWageRelation
         * @constructor
         * @param {PlatformAdminApi.IWageRelation=} [properties] Properties to set
         */
        function WageRelation(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageRelation
         * @instance
         */
        WageRelation.prototype.id = "";

        /**
         * WageRelation name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageRelation
         * @instance
         */
        WageRelation.prototype.name = "";

        /**
         * WageRelation grades.
         * @member {Array.<PlatformAdminApi.IGrade>} grades
         * @memberof PlatformAdminApi.WageRelation
         * @instance
         */
        WageRelation.prototype.grades = $util.emptyArray;

        /**
         * Encodes the specified WageRelation message. Does not implicitly {@link PlatformAdminApi.WageRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageRelation
         * @static
         * @param {PlatformAdminApi.IWageRelation} message WageRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.Grade.encode(message.grades[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageRelation} WageRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 9:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.Grade.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageRelation;
    })();

    PlatformAdminApi.WageSuggestRegulationsRequest = (function() {

        /**
         * Properties of a WageSuggestRegulationsRequest.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestRegulationsRequest
         * @property {string|null} [searchString] WageSuggestRegulationsRequest searchString
         */

        /**
         * Constructs a new WageSuggestRegulationsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestRegulationsRequest.
         * @implements IWageSuggestRegulationsRequest
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestRegulationsRequest=} [properties] Properties to set
         */
        function WageSuggestRegulationsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestRegulationsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.WageSuggestRegulationsRequest
         * @instance
         */
        WageSuggestRegulationsRequest.prototype.searchString = "";

        /**
         * Encodes the specified WageSuggestRegulationsRequest message. Does not implicitly {@link PlatformAdminApi.WageSuggestRegulationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestRegulationsRequest
         * @static
         * @param {PlatformAdminApi.IWageSuggestRegulationsRequest} message WageSuggestRegulationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestRegulationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a WageSuggestRegulationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestRegulationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestRegulationsRequest} WageSuggestRegulationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestRegulationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestRegulationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestRegulationsRequest;
    })();

    PlatformAdminApi.WageSuggestRegulationsResponse = (function() {

        /**
         * Properties of a WageSuggestRegulationsResponse.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestRegulationsResponse
         * @property {Array.<PlatformAdminApi.IWageRegulationRel>|null} [regulations] WageSuggestRegulationsResponse regulations
         */

        /**
         * Constructs a new WageSuggestRegulationsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestRegulationsResponse.
         * @implements IWageSuggestRegulationsResponse
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestRegulationsResponse=} [properties] Properties to set
         */
        function WageSuggestRegulationsResponse(properties) {
            this.regulations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestRegulationsResponse regulations.
         * @member {Array.<PlatformAdminApi.IWageRegulationRel>} regulations
         * @memberof PlatformAdminApi.WageSuggestRegulationsResponse
         * @instance
         */
        WageSuggestRegulationsResponse.prototype.regulations = $util.emptyArray;

        /**
         * Encodes the specified WageSuggestRegulationsResponse message. Does not implicitly {@link PlatformAdminApi.WageSuggestRegulationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestRegulationsResponse
         * @static
         * @param {PlatformAdminApi.IWageSuggestRegulationsResponse} message WageSuggestRegulationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestRegulationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.regulations != null && message.regulations.length)
                for (var i = 0; i < message.regulations.length; ++i)
                    $root.PlatformAdminApi.WageRegulationRel.encode(message.regulations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSuggestRegulationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestRegulationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestRegulationsResponse} WageSuggestRegulationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestRegulationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestRegulationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.regulations && message.regulations.length))
                        message.regulations = [];
                    message.regulations.push($root.PlatformAdminApi.WageRegulationRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestRegulationsResponse;
    })();

    PlatformAdminApi.WageSuggestOptionsRequest = (function() {

        /**
         * Properties of a WageSuggestOptionsRequest.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestOptionsRequest
         * @property {string|null} [searchString] WageSuggestOptionsRequest searchString
         */

        /**
         * Constructs a new WageSuggestOptionsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestOptionsRequest.
         * @implements IWageSuggestOptionsRequest
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestOptionsRequest=} [properties] Properties to set
         */
        function WageSuggestOptionsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestOptionsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.WageSuggestOptionsRequest
         * @instance
         */
        WageSuggestOptionsRequest.prototype.searchString = "";

        /**
         * Encodes the specified WageSuggestOptionsRequest message. Does not implicitly {@link PlatformAdminApi.WageSuggestOptionsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestOptionsRequest
         * @static
         * @param {PlatformAdminApi.IWageSuggestOptionsRequest} message WageSuggestOptionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestOptionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a WageSuggestOptionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestOptionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestOptionsRequest} WageSuggestOptionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestOptionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestOptionsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestOptionsRequest;
    })();

    PlatformAdminApi.WageSuggestOptionsResponse = (function() {

        /**
         * Properties of a WageSuggestOptionsResponse.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestOptionsResponse
         * @property {Array.<PlatformAdminApi.IOption>|null} [options] WageSuggestOptionsResponse options
         */

        /**
         * Constructs a new WageSuggestOptionsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestOptionsResponse.
         * @implements IWageSuggestOptionsResponse
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestOptionsResponse=} [properties] Properties to set
         */
        function WageSuggestOptionsResponse(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestOptionsResponse options.
         * @member {Array.<PlatformAdminApi.IOption>} options
         * @memberof PlatformAdminApi.WageSuggestOptionsResponse
         * @instance
         */
        WageSuggestOptionsResponse.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified WageSuggestOptionsResponse message. Does not implicitly {@link PlatformAdminApi.WageSuggestOptionsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestOptionsResponse
         * @static
         * @param {PlatformAdminApi.IWageSuggestOptionsResponse} message WageSuggestOptionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestOptionsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.Option.encode(message.options[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSuggestOptionsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestOptionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestOptionsResponse} WageSuggestOptionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestOptionsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestOptionsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.Option.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestOptionsResponse;
    })();

    PlatformAdminApi.WageSuggestSpecializationsRequest = (function() {

        /**
         * Properties of a WageSuggestSpecializationsRequest.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestSpecializationsRequest
         * @property {string|null} [searchString] WageSuggestSpecializationsRequest searchString
         */

        /**
         * Constructs a new WageSuggestSpecializationsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestSpecializationsRequest.
         * @implements IWageSuggestSpecializationsRequest
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestSpecializationsRequest=} [properties] Properties to set
         */
        function WageSuggestSpecializationsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestSpecializationsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.WageSuggestSpecializationsRequest
         * @instance
         */
        WageSuggestSpecializationsRequest.prototype.searchString = "";

        /**
         * Encodes the specified WageSuggestSpecializationsRequest message. Does not implicitly {@link PlatformAdminApi.WageSuggestSpecializationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestSpecializationsRequest
         * @static
         * @param {PlatformAdminApi.IWageSuggestSpecializationsRequest} message WageSuggestSpecializationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestSpecializationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a WageSuggestSpecializationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestSpecializationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestSpecializationsRequest} WageSuggestSpecializationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestSpecializationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestSpecializationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestSpecializationsRequest;
    })();

    PlatformAdminApi.WageSuggestSpecializationsResponse = (function() {

        /**
         * Properties of a WageSuggestSpecializationsResponse.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestSpecializationsResponse
         * @property {Array.<PlatformAdminApi.ISpecialization>|null} [specializations] WageSuggestSpecializationsResponse specializations
         */

        /**
         * Constructs a new WageSuggestSpecializationsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestSpecializationsResponse.
         * @implements IWageSuggestSpecializationsResponse
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestSpecializationsResponse=} [properties] Properties to set
         */
        function WageSuggestSpecializationsResponse(properties) {
            this.specializations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestSpecializationsResponse specializations.
         * @member {Array.<PlatformAdminApi.ISpecialization>} specializations
         * @memberof PlatformAdminApi.WageSuggestSpecializationsResponse
         * @instance
         */
        WageSuggestSpecializationsResponse.prototype.specializations = $util.emptyArray;

        /**
         * Encodes the specified WageSuggestSpecializationsResponse message. Does not implicitly {@link PlatformAdminApi.WageSuggestSpecializationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestSpecializationsResponse
         * @static
         * @param {PlatformAdminApi.IWageSuggestSpecializationsResponse} message WageSuggestSpecializationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestSpecializationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.specializations != null && message.specializations.length)
                for (var i = 0; i < message.specializations.length; ++i)
                    $root.PlatformAdminApi.Specialization.encode(message.specializations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSuggestSpecializationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestSpecializationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestSpecializationsResponse} WageSuggestSpecializationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestSpecializationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestSpecializationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.specializations && message.specializations.length))
                        message.specializations = [];
                    message.specializations.push($root.PlatformAdminApi.Specialization.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestSpecializationsResponse;
    })();

    PlatformAdminApi.WageSuggestPositionsRequest = (function() {

        /**
         * Properties of a WageSuggestPositionsRequest.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestPositionsRequest
         * @property {string|null} [searchString] WageSuggestPositionsRequest searchString
         */

        /**
         * Constructs a new WageSuggestPositionsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestPositionsRequest.
         * @implements IWageSuggestPositionsRequest
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestPositionsRequest=} [properties] Properties to set
         */
        function WageSuggestPositionsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestPositionsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.WageSuggestPositionsRequest
         * @instance
         */
        WageSuggestPositionsRequest.prototype.searchString = "";

        /**
         * Encodes the specified WageSuggestPositionsRequest message. Does not implicitly {@link PlatformAdminApi.WageSuggestPositionsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestPositionsRequest
         * @static
         * @param {PlatformAdminApi.IWageSuggestPositionsRequest} message WageSuggestPositionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestPositionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a WageSuggestPositionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestPositionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestPositionsRequest} WageSuggestPositionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestPositionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestPositionsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestPositionsRequest;
    })();

    PlatformAdminApi.WageSuggestPositionsResponse = (function() {

        /**
         * Properties of a WageSuggestPositionsResponse.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestPositionsResponse
         * @property {Array.<PlatformAdminApi.IPosition>|null} [positions] WageSuggestPositionsResponse positions
         */

        /**
         * Constructs a new WageSuggestPositionsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestPositionsResponse.
         * @implements IWageSuggestPositionsResponse
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestPositionsResponse=} [properties] Properties to set
         */
        function WageSuggestPositionsResponse(properties) {
            this.positions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestPositionsResponse positions.
         * @member {Array.<PlatformAdminApi.IPosition>} positions
         * @memberof PlatformAdminApi.WageSuggestPositionsResponse
         * @instance
         */
        WageSuggestPositionsResponse.prototype.positions = $util.emptyArray;

        /**
         * Encodes the specified WageSuggestPositionsResponse message. Does not implicitly {@link PlatformAdminApi.WageSuggestPositionsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestPositionsResponse
         * @static
         * @param {PlatformAdminApi.IWageSuggestPositionsResponse} message WageSuggestPositionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestPositionsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.positions != null && message.positions.length)
                for (var i = 0; i < message.positions.length; ++i)
                    $root.PlatformAdminApi.Position.encode(message.positions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSuggestPositionsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestPositionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestPositionsResponse} WageSuggestPositionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestPositionsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestPositionsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.positions && message.positions.length))
                        message.positions = [];
                    message.positions.push($root.PlatformAdminApi.Position.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestPositionsResponse;
    })();

    PlatformAdminApi.WageSuggestRegionsRequest = (function() {

        /**
         * Properties of a WageSuggestRegionsRequest.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestRegionsRequest
         * @property {string|null} [searchString] WageSuggestRegionsRequest searchString
         */

        /**
         * Constructs a new WageSuggestRegionsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestRegionsRequest.
         * @implements IWageSuggestRegionsRequest
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestRegionsRequest=} [properties] Properties to set
         */
        function WageSuggestRegionsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestRegionsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.WageSuggestRegionsRequest
         * @instance
         */
        WageSuggestRegionsRequest.prototype.searchString = "";

        /**
         * Encodes the specified WageSuggestRegionsRequest message. Does not implicitly {@link PlatformAdminApi.WageSuggestRegionsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestRegionsRequest
         * @static
         * @param {PlatformAdminApi.IWageSuggestRegionsRequest} message WageSuggestRegionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestRegionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a WageSuggestRegionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestRegionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestRegionsRequest} WageSuggestRegionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestRegionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestRegionsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestRegionsRequest;
    })();

    PlatformAdminApi.WageSuggestRegionsResponse = (function() {

        /**
         * Properties of a WageSuggestRegionsResponse.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestRegionsResponse
         * @property {Array.<PlatformAdminApi.IRegion>|null} [regions] WageSuggestRegionsResponse regions
         */

        /**
         * Constructs a new WageSuggestRegionsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestRegionsResponse.
         * @implements IWageSuggestRegionsResponse
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestRegionsResponse=} [properties] Properties to set
         */
        function WageSuggestRegionsResponse(properties) {
            this.regions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestRegionsResponse regions.
         * @member {Array.<PlatformAdminApi.IRegion>} regions
         * @memberof PlatformAdminApi.WageSuggestRegionsResponse
         * @instance
         */
        WageSuggestRegionsResponse.prototype.regions = $util.emptyArray;

        /**
         * Encodes the specified WageSuggestRegionsResponse message. Does not implicitly {@link PlatformAdminApi.WageSuggestRegionsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestRegionsResponse
         * @static
         * @param {PlatformAdminApi.IWageSuggestRegionsResponse} message WageSuggestRegionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestRegionsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.regions != null && message.regions.length)
                for (var i = 0; i < message.regions.length; ++i)
                    $root.PlatformAdminApi.Region.encode(message.regions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSuggestRegionsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestRegionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestRegionsResponse} WageSuggestRegionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestRegionsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestRegionsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.regions && message.regions.length))
                        message.regions = [];
                    message.regions.push($root.PlatformAdminApi.Region.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestRegionsResponse;
    })();

    PlatformAdminApi.WageCreatePage = (function() {

        /**
         * Properties of a WageCreatePage.
         * @memberof PlatformAdminApi
         * @interface IWageCreatePage
         * @property {PlatformAdminApi.ISelectOption|null} [type] WageCreatePage type
         * @property {string|null} [name] WageCreatePage name
         * @property {PlatformAdminApi.IRegion|null} [region] WageCreatePage region
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] WageCreatePage specialization
         * @property {PlatformAdminApi.IProduct|null} [product] WageCreatePage product
         * @property {Array.<PlatformAdminApi.IGradeCreate>|null} [grades] WageCreatePage grades
         */

        /**
         * Constructs a new WageCreatePage.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageCreatePage.
         * @implements IWageCreatePage
         * @constructor
         * @param {PlatformAdminApi.IWageCreatePage=} [properties] Properties to set
         */
        function WageCreatePage(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageCreatePage type.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} type
         * @memberof PlatformAdminApi.WageCreatePage
         * @instance
         */
        WageCreatePage.prototype.type = null;

        /**
         * WageCreatePage name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageCreatePage
         * @instance
         */
        WageCreatePage.prototype.name = "";

        /**
         * WageCreatePage region.
         * @member {PlatformAdminApi.IRegion|null|undefined} region
         * @memberof PlatformAdminApi.WageCreatePage
         * @instance
         */
        WageCreatePage.prototype.region = null;

        /**
         * WageCreatePage specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.WageCreatePage
         * @instance
         */
        WageCreatePage.prototype.specialization = null;

        /**
         * WageCreatePage product.
         * @member {PlatformAdminApi.IProduct|null|undefined} product
         * @memberof PlatformAdminApi.WageCreatePage
         * @instance
         */
        WageCreatePage.prototype.product = null;

        /**
         * WageCreatePage grades.
         * @member {Array.<PlatformAdminApi.IGradeCreate>} grades
         * @memberof PlatformAdminApi.WageCreatePage
         * @instance
         */
        WageCreatePage.prototype.grades = $util.emptyArray;

        /**
         * Encodes the specified WageCreatePage message. Does not implicitly {@link PlatformAdminApi.WageCreatePage.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageCreatePage
         * @static
         * @param {PlatformAdminApi.IWageCreatePage} message WageCreatePage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageCreatePage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                $root.PlatformAdminApi.SelectOption.encode(message.type, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                $root.PlatformAdminApi.Region.encode(message.region, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.PlatformAdminApi.Product.encode(message.product, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.GradeCreate.encode(message.grades[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageCreatePage message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageCreatePage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageCreatePage} WageCreatePage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageCreatePage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageCreatePage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.region = $root.PlatformAdminApi.Region.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.product = $root.PlatformAdminApi.Product.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.GradeCreate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageCreatePage;
    })();

    PlatformAdminApi.WageCreateRequest = (function() {

        /**
         * Properties of a WageCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IWageCreateRequest
         * @property {string|null} [name] WageCreateRequest name
         * @property {string|null} [productSlug] WageCreateRequest productSlug
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] WageCreateRequest specialization
         * @property {Array.<PlatformAdminApi.IGradeCreateRequest>|null} [grades] WageCreateRequest grades
         * @property {string|null} [regionId] WageCreateRequest regionId
         * @property {string|null} [type] WageCreateRequest type
         */

        /**
         * Constructs a new WageCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageCreateRequest.
         * @implements IWageCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IWageCreateRequest=} [properties] Properties to set
         */
        function WageCreateRequest(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageCreateRequest
         * @instance
         */
        WageCreateRequest.prototype.name = "";

        /**
         * WageCreateRequest productSlug.
         * @member {string} productSlug
         * @memberof PlatformAdminApi.WageCreateRequest
         * @instance
         */
        WageCreateRequest.prototype.productSlug = "";

        /**
         * WageCreateRequest specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.WageCreateRequest
         * @instance
         */
        WageCreateRequest.prototype.specialization = null;

        /**
         * WageCreateRequest grades.
         * @member {Array.<PlatformAdminApi.IGradeCreateRequest>} grades
         * @memberof PlatformAdminApi.WageCreateRequest
         * @instance
         */
        WageCreateRequest.prototype.grades = $util.emptyArray;

        /**
         * WageCreateRequest regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.WageCreateRequest
         * @instance
         */
        WageCreateRequest.prototype.regionId = "";

        /**
         * WageCreateRequest type.
         * @member {string} type
         * @memberof PlatformAdminApi.WageCreateRequest
         * @instance
         */
        WageCreateRequest.prototype.type = "";

        /**
         * Encodes the specified WageCreateRequest message. Does not implicitly {@link PlatformAdminApi.WageCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageCreateRequest
         * @static
         * @param {PlatformAdminApi.IWageCreateRequest} message WageCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.productSlug);
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.GradeCreateRequest.encode(message.grades[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.regionId);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.type);
            return writer;
        };

        /**
         * Decodes a WageCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageCreateRequest} WageCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.productSlug = reader.string();
                    break;
                case 3:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.GradeCreateRequest.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.regionId = reader.string();
                    break;
                case 6:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageCreateRequest;
    })();

    PlatformAdminApi.GradeCreate = (function() {

        /**
         * Properties of a GradeCreate.
         * @memberof PlatformAdminApi
         * @interface IGradeCreate
         * @property {PlatformAdminApi.IPosition|null} [position] GradeCreate position
         * @property {number|null} [rateAmount] GradeCreate rateAmount
         * @property {PlatformAdminApi.ISelectOption|null} [rateAmountOption] GradeCreate rateAmountOption
         * @property {PlatformAdminApi.ISelectOption|null} [rateUnit] GradeCreate rateUnit
         * @property {Array.<PlatformAdminApi.ICompensationCreate>|null} [compensations] GradeCreate compensations
         * @property {Array.<PlatformAdminApi.IAdditionalRateCreateRequest>|null} [additionalRates] GradeCreate additionalRates
         */

        /**
         * Constructs a new GradeCreate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GradeCreate.
         * @implements IGradeCreate
         * @constructor
         * @param {PlatformAdminApi.IGradeCreate=} [properties] Properties to set
         */
        function GradeCreate(properties) {
            this.compensations = [];
            this.additionalRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GradeCreate position.
         * @member {PlatformAdminApi.IPosition|null|undefined} position
         * @memberof PlatformAdminApi.GradeCreate
         * @instance
         */
        GradeCreate.prototype.position = null;

        /**
         * GradeCreate rateAmount.
         * @member {number} rateAmount
         * @memberof PlatformAdminApi.GradeCreate
         * @instance
         */
        GradeCreate.prototype.rateAmount = 0;

        /**
         * GradeCreate rateAmountOption.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} rateAmountOption
         * @memberof PlatformAdminApi.GradeCreate
         * @instance
         */
        GradeCreate.prototype.rateAmountOption = null;

        /**
         * GradeCreate rateUnit.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} rateUnit
         * @memberof PlatformAdminApi.GradeCreate
         * @instance
         */
        GradeCreate.prototype.rateUnit = null;

        /**
         * GradeCreate compensations.
         * @member {Array.<PlatformAdminApi.ICompensationCreate>} compensations
         * @memberof PlatformAdminApi.GradeCreate
         * @instance
         */
        GradeCreate.prototype.compensations = $util.emptyArray;

        /**
         * GradeCreate additionalRates.
         * @member {Array.<PlatformAdminApi.IAdditionalRateCreateRequest>} additionalRates
         * @memberof PlatformAdminApi.GradeCreate
         * @instance
         */
        GradeCreate.prototype.additionalRates = $util.emptyArray;

        /**
         * Encodes the specified GradeCreate message. Does not implicitly {@link PlatformAdminApi.GradeCreate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GradeCreate
         * @static
         * @param {PlatformAdminApi.IGradeCreate} message GradeCreate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GradeCreate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                $root.PlatformAdminApi.Position.encode(message.position, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.rateAmount != null && Object.hasOwnProperty.call(message, "rateAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rateAmount);
            if (message.rateAmountOption != null && Object.hasOwnProperty.call(message, "rateAmountOption"))
                $root.PlatformAdminApi.SelectOption.encode(message.rateAmountOption, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.rateUnit != null && Object.hasOwnProperty.call(message, "rateUnit"))
                $root.PlatformAdminApi.SelectOption.encode(message.rateUnit, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.compensations != null && message.compensations.length)
                for (var i = 0; i < message.compensations.length; ++i)
                    $root.PlatformAdminApi.CompensationCreate.encode(message.compensations[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.additionalRates != null && message.additionalRates.length)
                for (var i = 0; i < message.additionalRates.length; ++i)
                    $root.PlatformAdminApi.AdditionalRateCreateRequest.encode(message.additionalRates[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GradeCreate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GradeCreate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GradeCreate} GradeCreate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GradeCreate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GradeCreate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.position = $root.PlatformAdminApi.Position.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.rateAmount = reader.int32();
                    break;
                case 4:
                    message.rateAmountOption = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.rateUnit = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.compensations && message.compensations.length))
                        message.compensations = [];
                    message.compensations.push($root.PlatformAdminApi.CompensationCreate.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.additionalRates && message.additionalRates.length))
                        message.additionalRates = [];
                    message.additionalRates.push($root.PlatformAdminApi.AdditionalRateCreateRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GradeCreate;
    })();

    PlatformAdminApi.GradeCreateRequest = (function() {

        /**
         * Properties of a GradeCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IGradeCreateRequest
         * @property {PlatformAdminApi.IPositionRelation|null} [position] GradeCreateRequest position
         * @property {PlatformAdminApi.IRate|null} [rate] GradeCreateRequest rate
         * @property {Array.<PlatformAdminApi.ICompensationCreateRequest>|null} [compensations] GradeCreateRequest compensations
         * @property {Array.<PlatformAdminApi.IAdditionalRateCreateRequest>|null} [additionalRates] GradeCreateRequest additionalRates
         */

        /**
         * Constructs a new GradeCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GradeCreateRequest.
         * @implements IGradeCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IGradeCreateRequest=} [properties] Properties to set
         */
        function GradeCreateRequest(properties) {
            this.compensations = [];
            this.additionalRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GradeCreateRequest position.
         * @member {PlatformAdminApi.IPositionRelation|null|undefined} position
         * @memberof PlatformAdminApi.GradeCreateRequest
         * @instance
         */
        GradeCreateRequest.prototype.position = null;

        /**
         * GradeCreateRequest rate.
         * @member {PlatformAdminApi.IRate|null|undefined} rate
         * @memberof PlatformAdminApi.GradeCreateRequest
         * @instance
         */
        GradeCreateRequest.prototype.rate = null;

        /**
         * GradeCreateRequest compensations.
         * @member {Array.<PlatformAdminApi.ICompensationCreateRequest>} compensations
         * @memberof PlatformAdminApi.GradeCreateRequest
         * @instance
         */
        GradeCreateRequest.prototype.compensations = $util.emptyArray;

        /**
         * GradeCreateRequest additionalRates.
         * @member {Array.<PlatformAdminApi.IAdditionalRateCreateRequest>} additionalRates
         * @memberof PlatformAdminApi.GradeCreateRequest
         * @instance
         */
        GradeCreateRequest.prototype.additionalRates = $util.emptyArray;

        /**
         * Encodes the specified GradeCreateRequest message. Does not implicitly {@link PlatformAdminApi.GradeCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GradeCreateRequest
         * @static
         * @param {PlatformAdminApi.IGradeCreateRequest} message GradeCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GradeCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                $root.PlatformAdminApi.PositionRelation.encode(message.position, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                $root.PlatformAdminApi.Rate.encode(message.rate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.compensations != null && message.compensations.length)
                for (var i = 0; i < message.compensations.length; ++i)
                    $root.PlatformAdminApi.CompensationCreateRequest.encode(message.compensations[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.additionalRates != null && message.additionalRates.length)
                for (var i = 0; i < message.additionalRates.length; ++i)
                    $root.PlatformAdminApi.AdditionalRateCreateRequest.encode(message.additionalRates[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GradeCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GradeCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GradeCreateRequest} GradeCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GradeCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GradeCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.position = $root.PlatformAdminApi.PositionRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.rate = $root.PlatformAdminApi.Rate.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.compensations && message.compensations.length))
                        message.compensations = [];
                    message.compensations.push($root.PlatformAdminApi.CompensationCreateRequest.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.additionalRates && message.additionalRates.length))
                        message.additionalRates = [];
                    message.additionalRates.push($root.PlatformAdminApi.AdditionalRateCreateRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GradeCreateRequest;
    })();

    PlatformAdminApi.CompensationCreate = (function() {

        /**
         * Properties of a CompensationCreate.
         * @memberof PlatformAdminApi
         * @interface ICompensationCreate
         * @property {number|null} [amount] CompensationCreate amount
         * @property {PlatformAdminApi.ISelectOption|null} [type] CompensationCreate type
         * @property {PlatformAdminApi.IOption|null} [option] CompensationCreate option
         */

        /**
         * Constructs a new CompensationCreate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CompensationCreate.
         * @implements ICompensationCreate
         * @constructor
         * @param {PlatformAdminApi.ICompensationCreate=} [properties] Properties to set
         */
        function CompensationCreate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompensationCreate amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.CompensationCreate
         * @instance
         */
        CompensationCreate.prototype.amount = 0;

        /**
         * CompensationCreate type.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} type
         * @memberof PlatformAdminApi.CompensationCreate
         * @instance
         */
        CompensationCreate.prototype.type = null;

        /**
         * CompensationCreate option.
         * @member {PlatformAdminApi.IOption|null|undefined} option
         * @memberof PlatformAdminApi.CompensationCreate
         * @instance
         */
        CompensationCreate.prototype.option = null;

        /**
         * Encodes the specified CompensationCreate message. Does not implicitly {@link PlatformAdminApi.CompensationCreate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CompensationCreate
         * @static
         * @param {PlatformAdminApi.ICompensationCreate} message CompensationCreate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompensationCreate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.amount);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                $root.PlatformAdminApi.SelectOption.encode(message.type, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.Option.encode(message.option, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CompensationCreate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CompensationCreate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CompensationCreate} CompensationCreate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompensationCreate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CompensationCreate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.amount = reader.int32();
                    break;
                case 2:
                    message.type = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.option = $root.PlatformAdminApi.Option.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CompensationCreate;
    })();

    PlatformAdminApi.CompensationCreateRequest = (function() {

        /**
         * Properties of a CompensationCreateRequest.
         * @memberof PlatformAdminApi
         * @interface ICompensationCreateRequest
         * @property {number|null} [amount] CompensationCreateRequest amount
         * @property {string|null} [type] CompensationCreateRequest type
         * @property {PlatformAdminApi.IOption|null} [option] CompensationCreateRequest option
         */

        /**
         * Constructs a new CompensationCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CompensationCreateRequest.
         * @implements ICompensationCreateRequest
         * @constructor
         * @param {PlatformAdminApi.ICompensationCreateRequest=} [properties] Properties to set
         */
        function CompensationCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompensationCreateRequest amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.CompensationCreateRequest
         * @instance
         */
        CompensationCreateRequest.prototype.amount = 0;

        /**
         * CompensationCreateRequest type.
         * @member {string} type
         * @memberof PlatformAdminApi.CompensationCreateRequest
         * @instance
         */
        CompensationCreateRequest.prototype.type = "";

        /**
         * CompensationCreateRequest option.
         * @member {PlatformAdminApi.IOption|null|undefined} option
         * @memberof PlatformAdminApi.CompensationCreateRequest
         * @instance
         */
        CompensationCreateRequest.prototype.option = null;

        /**
         * Encodes the specified CompensationCreateRequest message. Does not implicitly {@link PlatformAdminApi.CompensationCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CompensationCreateRequest
         * @static
         * @param {PlatformAdminApi.ICompensationCreateRequest} message CompensationCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompensationCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.Option.encode(message.option, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CompensationCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CompensationCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CompensationCreateRequest} CompensationCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompensationCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CompensationCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.amount = reader.int32();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.option = $root.PlatformAdminApi.Option.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CompensationCreateRequest;
    })();

    PlatformAdminApi.AdditionalRateCreateRequest = (function() {

        /**
         * Properties of an AdditionalRateCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IAdditionalRateCreateRequest
         * @property {string|null} [type] AdditionalRateCreateRequest type
         * @property {number|null} [amount] AdditionalRateCreateRequest amount
         * @property {string|null} [regionId] AdditionalRateCreateRequest regionId
         */

        /**
         * Constructs a new AdditionalRateCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalRateCreateRequest.
         * @implements IAdditionalRateCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IAdditionalRateCreateRequest=} [properties] Properties to set
         */
        function AdditionalRateCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalRateCreateRequest type.
         * @member {string} type
         * @memberof PlatformAdminApi.AdditionalRateCreateRequest
         * @instance
         */
        AdditionalRateCreateRequest.prototype.type = "";

        /**
         * AdditionalRateCreateRequest amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AdditionalRateCreateRequest
         * @instance
         */
        AdditionalRateCreateRequest.prototype.amount = 0;

        /**
         * AdditionalRateCreateRequest regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.AdditionalRateCreateRequest
         * @instance
         */
        AdditionalRateCreateRequest.prototype.regionId = "";

        /**
         * Encodes the specified AdditionalRateCreateRequest message. Does not implicitly {@link PlatformAdminApi.AdditionalRateCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalRateCreateRequest
         * @static
         * @param {PlatformAdminApi.IAdditionalRateCreateRequest} message AdditionalRateCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalRateCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.regionId);
            return writer;
        };

        /**
         * Decodes an AdditionalRateCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalRateCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalRateCreateRequest} AdditionalRateCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalRateCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalRateCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.regionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalRateCreateRequest;
    })();

    PlatformAdminApi.WageEditPage = (function() {

        /**
         * Properties of a WageEditPage.
         * @memberof PlatformAdminApi
         * @interface IWageEditPage
         * @property {string|null} [id] WageEditPage id
         * @property {PlatformAdminApi.ISelectOption|null} [type] WageEditPage type
         * @property {string|null} [name] WageEditPage name
         * @property {PlatformAdminApi.IRegion|null} [region] WageEditPage region
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] WageEditPage specialization
         * @property {PlatformAdminApi.IProduct|null} [product] WageEditPage product
         * @property {Array.<PlatformAdminApi.IGradeUpdate>|null} [grades] WageEditPage grades
         */

        /**
         * Constructs a new WageEditPage.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageEditPage.
         * @implements IWageEditPage
         * @constructor
         * @param {PlatformAdminApi.IWageEditPage=} [properties] Properties to set
         */
        function WageEditPage(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageEditPage id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageEditPage
         * @instance
         */
        WageEditPage.prototype.id = "";

        /**
         * WageEditPage type.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} type
         * @memberof PlatformAdminApi.WageEditPage
         * @instance
         */
        WageEditPage.prototype.type = null;

        /**
         * WageEditPage name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageEditPage
         * @instance
         */
        WageEditPage.prototype.name = "";

        /**
         * WageEditPage region.
         * @member {PlatformAdminApi.IRegion|null|undefined} region
         * @memberof PlatformAdminApi.WageEditPage
         * @instance
         */
        WageEditPage.prototype.region = null;

        /**
         * WageEditPage specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.WageEditPage
         * @instance
         */
        WageEditPage.prototype.specialization = null;

        /**
         * WageEditPage product.
         * @member {PlatformAdminApi.IProduct|null|undefined} product
         * @memberof PlatformAdminApi.WageEditPage
         * @instance
         */
        WageEditPage.prototype.product = null;

        /**
         * WageEditPage grades.
         * @member {Array.<PlatformAdminApi.IGradeUpdate>} grades
         * @memberof PlatformAdminApi.WageEditPage
         * @instance
         */
        WageEditPage.prototype.grades = $util.emptyArray;

        /**
         * Encodes the specified WageEditPage message. Does not implicitly {@link PlatformAdminApi.WageEditPage.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageEditPage
         * @static
         * @param {PlatformAdminApi.IWageEditPage} message WageEditPage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageEditPage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                $root.PlatformAdminApi.SelectOption.encode(message.type, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                $root.PlatformAdminApi.Region.encode(message.region, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.PlatformAdminApi.Product.encode(message.product, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.GradeUpdate.encode(message.grades[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageEditPage message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageEditPage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageEditPage} WageEditPage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageEditPage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageEditPage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.region = $root.PlatformAdminApi.Region.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.product = $root.PlatformAdminApi.Product.decode(reader, reader.uint32());
                    break;
                case 7:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.GradeUpdate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageEditPage;
    })();

    PlatformAdminApi.WageUpdateRequest = (function() {

        /**
         * Properties of a WageUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IWageUpdateRequest
         * @property {string|null} [id] WageUpdateRequest id
         * @property {string|null} [name] WageUpdateRequest name
         * @property {string|null} [productSlug] WageUpdateRequest productSlug
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] WageUpdateRequest specialization
         * @property {Array.<PlatformAdminApi.IGradeUpdateRequest>|null} [grades] WageUpdateRequest grades
         * @property {string|null} [regionId] WageUpdateRequest regionId
         * @property {string|null} [type] WageUpdateRequest type
         */

        /**
         * Constructs a new WageUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageUpdateRequest.
         * @implements IWageUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IWageUpdateRequest=} [properties] Properties to set
         */
        function WageUpdateRequest(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @instance
         */
        WageUpdateRequest.prototype.id = "";

        /**
         * WageUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @instance
         */
        WageUpdateRequest.prototype.name = "";

        /**
         * WageUpdateRequest productSlug.
         * @member {string} productSlug
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @instance
         */
        WageUpdateRequest.prototype.productSlug = "";

        /**
         * WageUpdateRequest specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @instance
         */
        WageUpdateRequest.prototype.specialization = null;

        /**
         * WageUpdateRequest grades.
         * @member {Array.<PlatformAdminApi.IGradeUpdateRequest>} grades
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @instance
         */
        WageUpdateRequest.prototype.grades = $util.emptyArray;

        /**
         * WageUpdateRequest regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @instance
         */
        WageUpdateRequest.prototype.regionId = "";

        /**
         * WageUpdateRequest type.
         * @member {string} type
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @instance
         */
        WageUpdateRequest.prototype.type = "";

        /**
         * Encodes the specified WageUpdateRequest message. Does not implicitly {@link PlatformAdminApi.WageUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @static
         * @param {PlatformAdminApi.IWageUpdateRequest} message WageUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.productSlug);
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.GradeUpdateRequest.encode(message.grades[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.regionId);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.type);
            return writer;
        };

        /**
         * Decodes a WageUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageUpdateRequest} WageUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.productSlug = reader.string();
                    break;
                case 4:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.GradeUpdateRequest.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.regionId = reader.string();
                    break;
                case 7:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageUpdateRequest;
    })();

    PlatformAdminApi.GradeEdit = (function() {

        /**
         * Properties of a GradeEdit.
         * @memberof PlatformAdminApi
         * @interface IGradeEdit
         * @property {string|null} [id] GradeEdit id
         * @property {PlatformAdminApi.IPosition|null} [position] GradeEdit position
         * @property {number|null} [rateAmount] GradeEdit rateAmount
         * @property {PlatformAdminApi.ISelectOption|null} [rateAmountOption] GradeEdit rateAmountOption
         * @property {PlatformAdminApi.ISelectOption|null} [rateUnit] GradeEdit rateUnit
         * @property {Array.<PlatformAdminApi.ICompensationEdit>|null} [compensations] GradeEdit compensations
         * @property {Array.<PlatformAdminApi.IAdditionalRateEdit>|null} [additionalRates] GradeEdit additionalRates
         */

        /**
         * Constructs a new GradeEdit.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GradeEdit.
         * @implements IGradeEdit
         * @constructor
         * @param {PlatformAdminApi.IGradeEdit=} [properties] Properties to set
         */
        function GradeEdit(properties) {
            this.compensations = [];
            this.additionalRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GradeEdit id.
         * @member {string} id
         * @memberof PlatformAdminApi.GradeEdit
         * @instance
         */
        GradeEdit.prototype.id = "";

        /**
         * GradeEdit position.
         * @member {PlatformAdminApi.IPosition|null|undefined} position
         * @memberof PlatformAdminApi.GradeEdit
         * @instance
         */
        GradeEdit.prototype.position = null;

        /**
         * GradeEdit rateAmount.
         * @member {number} rateAmount
         * @memberof PlatformAdminApi.GradeEdit
         * @instance
         */
        GradeEdit.prototype.rateAmount = 0;

        /**
         * GradeEdit rateAmountOption.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} rateAmountOption
         * @memberof PlatformAdminApi.GradeEdit
         * @instance
         */
        GradeEdit.prototype.rateAmountOption = null;

        /**
         * GradeEdit rateUnit.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} rateUnit
         * @memberof PlatformAdminApi.GradeEdit
         * @instance
         */
        GradeEdit.prototype.rateUnit = null;

        /**
         * GradeEdit compensations.
         * @member {Array.<PlatformAdminApi.ICompensationEdit>} compensations
         * @memberof PlatformAdminApi.GradeEdit
         * @instance
         */
        GradeEdit.prototype.compensations = $util.emptyArray;

        /**
         * GradeEdit additionalRates.
         * @member {Array.<PlatformAdminApi.IAdditionalRateEdit>} additionalRates
         * @memberof PlatformAdminApi.GradeEdit
         * @instance
         */
        GradeEdit.prototype.additionalRates = $util.emptyArray;

        /**
         * Encodes the specified GradeEdit message. Does not implicitly {@link PlatformAdminApi.GradeEdit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GradeEdit
         * @static
         * @param {PlatformAdminApi.IGradeEdit} message GradeEdit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GradeEdit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                $root.PlatformAdminApi.Position.encode(message.position, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.rateAmount != null && Object.hasOwnProperty.call(message, "rateAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rateAmount);
            if (message.rateAmountOption != null && Object.hasOwnProperty.call(message, "rateAmountOption"))
                $root.PlatformAdminApi.SelectOption.encode(message.rateAmountOption, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.rateUnit != null && Object.hasOwnProperty.call(message, "rateUnit"))
                $root.PlatformAdminApi.SelectOption.encode(message.rateUnit, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.compensations != null && message.compensations.length)
                for (var i = 0; i < message.compensations.length; ++i)
                    $root.PlatformAdminApi.CompensationEdit.encode(message.compensations[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.additionalRates != null && message.additionalRates.length)
                for (var i = 0; i < message.additionalRates.length; ++i)
                    $root.PlatformAdminApi.AdditionalRateEdit.encode(message.additionalRates[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GradeEdit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GradeEdit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GradeEdit} GradeEdit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GradeEdit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GradeEdit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.position = $root.PlatformAdminApi.Position.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.rateAmount = reader.int32();
                    break;
                case 4:
                    message.rateAmountOption = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.rateUnit = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.compensations && message.compensations.length))
                        message.compensations = [];
                    message.compensations.push($root.PlatformAdminApi.CompensationEdit.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.additionalRates && message.additionalRates.length))
                        message.additionalRates = [];
                    message.additionalRates.push($root.PlatformAdminApi.AdditionalRateEdit.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GradeEdit;
    })();

    PlatformAdminApi.AdditionalRateEdit = (function() {

        /**
         * Properties of an AdditionalRateEdit.
         * @memberof PlatformAdminApi
         * @interface IAdditionalRateEdit
         * @property {string|null} [id] AdditionalRateEdit id
         * @property {string|null} [type] AdditionalRateEdit type
         * @property {number|null} [amount] AdditionalRateEdit amount
         * @property {string|null} [regionId] AdditionalRateEdit regionId
         */

        /**
         * Constructs a new AdditionalRateEdit.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalRateEdit.
         * @implements IAdditionalRateEdit
         * @constructor
         * @param {PlatformAdminApi.IAdditionalRateEdit=} [properties] Properties to set
         */
        function AdditionalRateEdit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalRateEdit id.
         * @member {string} id
         * @memberof PlatformAdminApi.AdditionalRateEdit
         * @instance
         */
        AdditionalRateEdit.prototype.id = "";

        /**
         * AdditionalRateEdit type.
         * @member {string} type
         * @memberof PlatformAdminApi.AdditionalRateEdit
         * @instance
         */
        AdditionalRateEdit.prototype.type = "";

        /**
         * AdditionalRateEdit amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AdditionalRateEdit
         * @instance
         */
        AdditionalRateEdit.prototype.amount = 0;

        /**
         * AdditionalRateEdit regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.AdditionalRateEdit
         * @instance
         */
        AdditionalRateEdit.prototype.regionId = "";

        /**
         * Encodes the specified AdditionalRateEdit message. Does not implicitly {@link PlatformAdminApi.AdditionalRateEdit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalRateEdit
         * @static
         * @param {PlatformAdminApi.IAdditionalRateEdit} message AdditionalRateEdit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalRateEdit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.regionId);
            return writer;
        };

        /**
         * Decodes an AdditionalRateEdit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalRateEdit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalRateEdit} AdditionalRateEdit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalRateEdit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalRateEdit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.regionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalRateEdit;
    })();

    PlatformAdminApi.CompensationEdit = (function() {

        /**
         * Properties of a CompensationEdit.
         * @memberof PlatformAdminApi
         * @interface ICompensationEdit
         * @property {string|null} [id] CompensationEdit id
         * @property {number|null} [amount] CompensationEdit amount
         * @property {PlatformAdminApi.ISelectOption|null} [type] CompensationEdit type
         * @property {PlatformAdminApi.IOption|null} [option] CompensationEdit option
         */

        /**
         * Constructs a new CompensationEdit.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CompensationEdit.
         * @implements ICompensationEdit
         * @constructor
         * @param {PlatformAdminApi.ICompensationEdit=} [properties] Properties to set
         */
        function CompensationEdit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompensationEdit id.
         * @member {string} id
         * @memberof PlatformAdminApi.CompensationEdit
         * @instance
         */
        CompensationEdit.prototype.id = "";

        /**
         * CompensationEdit amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.CompensationEdit
         * @instance
         */
        CompensationEdit.prototype.amount = 0;

        /**
         * CompensationEdit type.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} type
         * @memberof PlatformAdminApi.CompensationEdit
         * @instance
         */
        CompensationEdit.prototype.type = null;

        /**
         * CompensationEdit option.
         * @member {PlatformAdminApi.IOption|null|undefined} option
         * @memberof PlatformAdminApi.CompensationEdit
         * @instance
         */
        CompensationEdit.prototype.option = null;

        /**
         * Encodes the specified CompensationEdit message. Does not implicitly {@link PlatformAdminApi.CompensationEdit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CompensationEdit
         * @static
         * @param {PlatformAdminApi.ICompensationEdit} message CompensationEdit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompensationEdit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                $root.PlatformAdminApi.SelectOption.encode(message.type, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.Option.encode(message.option, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CompensationEdit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CompensationEdit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CompensationEdit} CompensationEdit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompensationEdit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CompensationEdit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                case 3:
                    message.type = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.option = $root.PlatformAdminApi.Option.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CompensationEdit;
    })();

    PlatformAdminApi.GradeUpdateRequest = (function() {

        /**
         * Properties of a GradeUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IGradeUpdateRequest
         * @property {string|null} [id] GradeUpdateRequest id
         * @property {PlatformAdminApi.IPositionRelation|null} [position] GradeUpdateRequest position
         * @property {PlatformAdminApi.IRate|null} [rate] GradeUpdateRequest rate
         * @property {Array.<PlatformAdminApi.ICompensationUpdate>|null} [compensations] GradeUpdateRequest compensations
         * @property {Array.<PlatformAdminApi.IAdditionalRateUpdate>|null} [additionalRates] GradeUpdateRequest additionalRates
         */

        /**
         * Constructs a new GradeUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GradeUpdateRequest.
         * @implements IGradeUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IGradeUpdateRequest=} [properties] Properties to set
         */
        function GradeUpdateRequest(properties) {
            this.compensations = [];
            this.additionalRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GradeUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.GradeUpdateRequest
         * @instance
         */
        GradeUpdateRequest.prototype.id = "";

        /**
         * GradeUpdateRequest position.
         * @member {PlatformAdminApi.IPositionRelation|null|undefined} position
         * @memberof PlatformAdminApi.GradeUpdateRequest
         * @instance
         */
        GradeUpdateRequest.prototype.position = null;

        /**
         * GradeUpdateRequest rate.
         * @member {PlatformAdminApi.IRate|null|undefined} rate
         * @memberof PlatformAdminApi.GradeUpdateRequest
         * @instance
         */
        GradeUpdateRequest.prototype.rate = null;

        /**
         * GradeUpdateRequest compensations.
         * @member {Array.<PlatformAdminApi.ICompensationUpdate>} compensations
         * @memberof PlatformAdminApi.GradeUpdateRequest
         * @instance
         */
        GradeUpdateRequest.prototype.compensations = $util.emptyArray;

        /**
         * GradeUpdateRequest additionalRates.
         * @member {Array.<PlatformAdminApi.IAdditionalRateUpdate>} additionalRates
         * @memberof PlatformAdminApi.GradeUpdateRequest
         * @instance
         */
        GradeUpdateRequest.prototype.additionalRates = $util.emptyArray;

        /**
         * Encodes the specified GradeUpdateRequest message. Does not implicitly {@link PlatformAdminApi.GradeUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GradeUpdateRequest
         * @static
         * @param {PlatformAdminApi.IGradeUpdateRequest} message GradeUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GradeUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                $root.PlatformAdminApi.PositionRelation.encode(message.position, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                $root.PlatformAdminApi.Rate.encode(message.rate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.compensations != null && message.compensations.length)
                for (var i = 0; i < message.compensations.length; ++i)
                    $root.PlatformAdminApi.CompensationUpdate.encode(message.compensations[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.additionalRates != null && message.additionalRates.length)
                for (var i = 0; i < message.additionalRates.length; ++i)
                    $root.PlatformAdminApi.AdditionalRateUpdate.encode(message.additionalRates[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GradeUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GradeUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GradeUpdateRequest} GradeUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GradeUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GradeUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.position = $root.PlatformAdminApi.PositionRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.rate = $root.PlatformAdminApi.Rate.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.compensations && message.compensations.length))
                        message.compensations = [];
                    message.compensations.push($root.PlatformAdminApi.CompensationUpdate.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.additionalRates && message.additionalRates.length))
                        message.additionalRates = [];
                    message.additionalRates.push($root.PlatformAdminApi.AdditionalRateUpdate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GradeUpdateRequest;
    })();

    PlatformAdminApi.GradeUpdate = (function() {

        /**
         * Properties of a GradeUpdate.
         * @memberof PlatformAdminApi
         * @interface IGradeUpdate
         * @property {string|null} [id] GradeUpdate id
         * @property {PlatformAdminApi.IPosition|null} [position] GradeUpdate position
         * @property {number|null} [rateAmount] GradeUpdate rateAmount
         * @property {PlatformAdminApi.ISelectOption|null} [rateAmountOption] GradeUpdate rateAmountOption
         * @property {PlatformAdminApi.ISelectOption|null} [rateUnit] GradeUpdate rateUnit
         * @property {Array.<PlatformAdminApi.ICompensationCreate>|null} [compensations] GradeUpdate compensations
         * @property {Array.<PlatformAdminApi.IAdditionalRateCreate>|null} [additionalRates] GradeUpdate additionalRates
         */

        /**
         * Constructs a new GradeUpdate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GradeUpdate.
         * @implements IGradeUpdate
         * @constructor
         * @param {PlatformAdminApi.IGradeUpdate=} [properties] Properties to set
         */
        function GradeUpdate(properties) {
            this.compensations = [];
            this.additionalRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GradeUpdate id.
         * @member {string} id
         * @memberof PlatformAdminApi.GradeUpdate
         * @instance
         */
        GradeUpdate.prototype.id = "";

        /**
         * GradeUpdate position.
         * @member {PlatformAdminApi.IPosition|null|undefined} position
         * @memberof PlatformAdminApi.GradeUpdate
         * @instance
         */
        GradeUpdate.prototype.position = null;

        /**
         * GradeUpdate rateAmount.
         * @member {number} rateAmount
         * @memberof PlatformAdminApi.GradeUpdate
         * @instance
         */
        GradeUpdate.prototype.rateAmount = 0;

        /**
         * GradeUpdate rateAmountOption.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} rateAmountOption
         * @memberof PlatformAdminApi.GradeUpdate
         * @instance
         */
        GradeUpdate.prototype.rateAmountOption = null;

        /**
         * GradeUpdate rateUnit.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} rateUnit
         * @memberof PlatformAdminApi.GradeUpdate
         * @instance
         */
        GradeUpdate.prototype.rateUnit = null;

        /**
         * GradeUpdate compensations.
         * @member {Array.<PlatformAdminApi.ICompensationCreate>} compensations
         * @memberof PlatformAdminApi.GradeUpdate
         * @instance
         */
        GradeUpdate.prototype.compensations = $util.emptyArray;

        /**
         * GradeUpdate additionalRates.
         * @member {Array.<PlatformAdminApi.IAdditionalRateCreate>} additionalRates
         * @memberof PlatformAdminApi.GradeUpdate
         * @instance
         */
        GradeUpdate.prototype.additionalRates = $util.emptyArray;

        /**
         * Encodes the specified GradeUpdate message. Does not implicitly {@link PlatformAdminApi.GradeUpdate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GradeUpdate
         * @static
         * @param {PlatformAdminApi.IGradeUpdate} message GradeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GradeUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                $root.PlatformAdminApi.Position.encode(message.position, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.rateAmount != null && Object.hasOwnProperty.call(message, "rateAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rateAmount);
            if (message.rateAmountOption != null && Object.hasOwnProperty.call(message, "rateAmountOption"))
                $root.PlatformAdminApi.SelectOption.encode(message.rateAmountOption, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.rateUnit != null && Object.hasOwnProperty.call(message, "rateUnit"))
                $root.PlatformAdminApi.SelectOption.encode(message.rateUnit, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.compensations != null && message.compensations.length)
                for (var i = 0; i < message.compensations.length; ++i)
                    $root.PlatformAdminApi.CompensationCreate.encode(message.compensations[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.additionalRates != null && message.additionalRates.length)
                for (var i = 0; i < message.additionalRates.length; ++i)
                    $root.PlatformAdminApi.AdditionalRateCreate.encode(message.additionalRates[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GradeUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GradeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GradeUpdate} GradeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GradeUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GradeUpdate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.position = $root.PlatformAdminApi.Position.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.rateAmount = reader.int32();
                    break;
                case 4:
                    message.rateAmountOption = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.rateUnit = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.compensations && message.compensations.length))
                        message.compensations = [];
                    message.compensations.push($root.PlatformAdminApi.CompensationCreate.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.additionalRates && message.additionalRates.length))
                        message.additionalRates = [];
                    message.additionalRates.push($root.PlatformAdminApi.AdditionalRateCreate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GradeUpdate;
    })();

    PlatformAdminApi.CompensationUpdate = (function() {

        /**
         * Properties of a CompensationUpdate.
         * @memberof PlatformAdminApi
         * @interface ICompensationUpdate
         * @property {string|null} [id] CompensationUpdate id
         * @property {number|null} [amount] CompensationUpdate amount
         * @property {string|null} [type] CompensationUpdate type
         * @property {string|null} [optionSlug] CompensationUpdate optionSlug
         */

        /**
         * Constructs a new CompensationUpdate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CompensationUpdate.
         * @implements ICompensationUpdate
         * @constructor
         * @param {PlatformAdminApi.ICompensationUpdate=} [properties] Properties to set
         */
        function CompensationUpdate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompensationUpdate id.
         * @member {string} id
         * @memberof PlatformAdminApi.CompensationUpdate
         * @instance
         */
        CompensationUpdate.prototype.id = "";

        /**
         * CompensationUpdate amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.CompensationUpdate
         * @instance
         */
        CompensationUpdate.prototype.amount = 0;

        /**
         * CompensationUpdate type.
         * @member {string} type
         * @memberof PlatformAdminApi.CompensationUpdate
         * @instance
         */
        CompensationUpdate.prototype.type = "";

        /**
         * CompensationUpdate optionSlug.
         * @member {string} optionSlug
         * @memberof PlatformAdminApi.CompensationUpdate
         * @instance
         */
        CompensationUpdate.prototype.optionSlug = "";

        /**
         * Encodes the specified CompensationUpdate message. Does not implicitly {@link PlatformAdminApi.CompensationUpdate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CompensationUpdate
         * @static
         * @param {PlatformAdminApi.ICompensationUpdate} message CompensationUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompensationUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.optionSlug != null && Object.hasOwnProperty.call(message, "optionSlug"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.optionSlug);
            return writer;
        };

        /**
         * Decodes a CompensationUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CompensationUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CompensationUpdate} CompensationUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompensationUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CompensationUpdate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.optionSlug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CompensationUpdate;
    })();

    PlatformAdminApi.AdditionalRateCreate = (function() {

        /**
         * Properties of an AdditionalRateCreate.
         * @memberof PlatformAdminApi
         * @interface IAdditionalRateCreate
         * @property {string|null} [id] AdditionalRateCreate id
         * @property {string|null} [type] AdditionalRateCreate type
         * @property {number|null} [amount] AdditionalRateCreate amount
         * @property {string|null} [regionId] AdditionalRateCreate regionId
         */

        /**
         * Constructs a new AdditionalRateCreate.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalRateCreate.
         * @implements IAdditionalRateCreate
         * @constructor
         * @param {PlatformAdminApi.IAdditionalRateCreate=} [properties] Properties to set
         */
        function AdditionalRateCreate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalRateCreate id.
         * @member {string} id
         * @memberof PlatformAdminApi.AdditionalRateCreate
         * @instance
         */
        AdditionalRateCreate.prototype.id = "";

        /**
         * AdditionalRateCreate type.
         * @member {string} type
         * @memberof PlatformAdminApi.AdditionalRateCreate
         * @instance
         */
        AdditionalRateCreate.prototype.type = "";

        /**
         * AdditionalRateCreate amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AdditionalRateCreate
         * @instance
         */
        AdditionalRateCreate.prototype.amount = 0;

        /**
         * AdditionalRateCreate regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.AdditionalRateCreate
         * @instance
         */
        AdditionalRateCreate.prototype.regionId = "";

        /**
         * Encodes the specified AdditionalRateCreate message. Does not implicitly {@link PlatformAdminApi.AdditionalRateCreate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalRateCreate
         * @static
         * @param {PlatformAdminApi.IAdditionalRateCreate} message AdditionalRateCreate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalRateCreate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.regionId);
            return writer;
        };

        /**
         * Decodes an AdditionalRateCreate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalRateCreate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalRateCreate} AdditionalRateCreate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalRateCreate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalRateCreate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.regionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalRateCreate;
    })();

    PlatformAdminApi.CompensationUpdateRequest = (function() {

        /**
         * Properties of a CompensationUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface ICompensationUpdateRequest
         * @property {string|null} [id] CompensationUpdateRequest id
         * @property {number|null} [amount] CompensationUpdateRequest amount
         * @property {string|null} [type] CompensationUpdateRequest type
         * @property {PlatformAdminApi.IOption|null} [option] CompensationUpdateRequest option
         */

        /**
         * Constructs a new CompensationUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CompensationUpdateRequest.
         * @implements ICompensationUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.ICompensationUpdateRequest=} [properties] Properties to set
         */
        function CompensationUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompensationUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.CompensationUpdateRequest
         * @instance
         */
        CompensationUpdateRequest.prototype.id = "";

        /**
         * CompensationUpdateRequest amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.CompensationUpdateRequest
         * @instance
         */
        CompensationUpdateRequest.prototype.amount = 0;

        /**
         * CompensationUpdateRequest type.
         * @member {string} type
         * @memberof PlatformAdminApi.CompensationUpdateRequest
         * @instance
         */
        CompensationUpdateRequest.prototype.type = "";

        /**
         * CompensationUpdateRequest option.
         * @member {PlatformAdminApi.IOption|null|undefined} option
         * @memberof PlatformAdminApi.CompensationUpdateRequest
         * @instance
         */
        CompensationUpdateRequest.prototype.option = null;

        /**
         * Encodes the specified CompensationUpdateRequest message. Does not implicitly {@link PlatformAdminApi.CompensationUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CompensationUpdateRequest
         * @static
         * @param {PlatformAdminApi.ICompensationUpdateRequest} message CompensationUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompensationUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.Option.encode(message.option, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CompensationUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CompensationUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CompensationUpdateRequest} CompensationUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompensationUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CompensationUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.option = $root.PlatformAdminApi.Option.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CompensationUpdateRequest;
    })();

    PlatformAdminApi.AdditionalRateUpdate = (function() {

        /**
         * Properties of an AdditionalRateUpdate.
         * @memberof PlatformAdminApi
         * @interface IAdditionalRateUpdate
         * @property {string|null} [id] AdditionalRateUpdate id
         * @property {string|null} [type] AdditionalRateUpdate type
         * @property {number|null} [amount] AdditionalRateUpdate amount
         * @property {string|null} [regionId] AdditionalRateUpdate regionId
         */

        /**
         * Constructs a new AdditionalRateUpdate.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalRateUpdate.
         * @implements IAdditionalRateUpdate
         * @constructor
         * @param {PlatformAdminApi.IAdditionalRateUpdate=} [properties] Properties to set
         */
        function AdditionalRateUpdate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalRateUpdate id.
         * @member {string} id
         * @memberof PlatformAdminApi.AdditionalRateUpdate
         * @instance
         */
        AdditionalRateUpdate.prototype.id = "";

        /**
         * AdditionalRateUpdate type.
         * @member {string} type
         * @memberof PlatformAdminApi.AdditionalRateUpdate
         * @instance
         */
        AdditionalRateUpdate.prototype.type = "";

        /**
         * AdditionalRateUpdate amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AdditionalRateUpdate
         * @instance
         */
        AdditionalRateUpdate.prototype.amount = 0;

        /**
         * AdditionalRateUpdate regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.AdditionalRateUpdate
         * @instance
         */
        AdditionalRateUpdate.prototype.regionId = "";

        /**
         * Encodes the specified AdditionalRateUpdate message. Does not implicitly {@link PlatformAdminApi.AdditionalRateUpdate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalRateUpdate
         * @static
         * @param {PlatformAdminApi.IAdditionalRateUpdate} message AdditionalRateUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalRateUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.regionId);
            return writer;
        };

        /**
         * Decodes an AdditionalRateUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalRateUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalRateUpdate} AdditionalRateUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalRateUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalRateUpdate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.regionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalRateUpdate;
    })();

    PlatformAdminApi.WagesListItem = (function() {

        /**
         * Properties of a WagesListItem.
         * @memberof PlatformAdminApi
         * @interface IWagesListItem
         * @property {string|null} [id] WagesListItem id
         * @property {string|null} [productTitle] WagesListItem productTitle
         * @property {string|null} [wageName] WagesListItem wageName
         * @property {string|null} [updatedAt] WagesListItem updatedAt
         * @property {boolean|null} [isDeleted] WagesListItem isDeleted
         */

        /**
         * Constructs a new WagesListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WagesListItem.
         * @implements IWagesListItem
         * @constructor
         * @param {PlatformAdminApi.IWagesListItem=} [properties] Properties to set
         */
        function WagesListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WagesListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.WagesListItem
         * @instance
         */
        WagesListItem.prototype.id = "";

        /**
         * WagesListItem productTitle.
         * @member {string} productTitle
         * @memberof PlatformAdminApi.WagesListItem
         * @instance
         */
        WagesListItem.prototype.productTitle = "";

        /**
         * WagesListItem wageName.
         * @member {string} wageName
         * @memberof PlatformAdminApi.WagesListItem
         * @instance
         */
        WagesListItem.prototype.wageName = "";

        /**
         * WagesListItem updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.WagesListItem
         * @instance
         */
        WagesListItem.prototype.updatedAt = "";

        /**
         * WagesListItem isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.WagesListItem
         * @instance
         */
        WagesListItem.prototype.isDeleted = false;

        /**
         * Encodes the specified WagesListItem message. Does not implicitly {@link PlatformAdminApi.WagesListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WagesListItem
         * @static
         * @param {PlatformAdminApi.IWagesListItem} message WagesListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WagesListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.productTitle != null && Object.hasOwnProperty.call(message, "productTitle"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.productTitle);
            if (message.wageName != null && Object.hasOwnProperty.call(message, "wageName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.wageName);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a WagesListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WagesListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WagesListItem} WagesListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WagesListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WagesListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.productTitle = reader.string();
                    break;
                case 3:
                    message.wageName = reader.string();
                    break;
                case 4:
                    message.updatedAt = reader.string();
                    break;
                case 5:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WagesListItem;
    })();

    PlatformAdminApi.WagesList = (function() {

        /**
         * Properties of a WagesList.
         * @memberof PlatformAdminApi
         * @interface IWagesList
         * @property {Array.<PlatformAdminApi.IWagesListItem>|null} [wages] WagesList wages
         * @property {number|null} [page] WagesList page
         * @property {number|null} [pageCount] WagesList pageCount
         * @property {number|null} [limit] WagesList limit
         * @property {number|null} [count] WagesList count
         * @property {number|null} [total] WagesList total
         */

        /**
         * Constructs a new WagesList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WagesList.
         * @implements IWagesList
         * @constructor
         * @param {PlatformAdminApi.IWagesList=} [properties] Properties to set
         */
        function WagesList(properties) {
            this.wages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WagesList wages.
         * @member {Array.<PlatformAdminApi.IWagesListItem>} wages
         * @memberof PlatformAdminApi.WagesList
         * @instance
         */
        WagesList.prototype.wages = $util.emptyArray;

        /**
         * WagesList page.
         * @member {number} page
         * @memberof PlatformAdminApi.WagesList
         * @instance
         */
        WagesList.prototype.page = 0;

        /**
         * WagesList pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.WagesList
         * @instance
         */
        WagesList.prototype.pageCount = 0;

        /**
         * WagesList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.WagesList
         * @instance
         */
        WagesList.prototype.limit = 0;

        /**
         * WagesList count.
         * @member {number} count
         * @memberof PlatformAdminApi.WagesList
         * @instance
         */
        WagesList.prototype.count = 0;

        /**
         * WagesList total.
         * @member {number} total
         * @memberof PlatformAdminApi.WagesList
         * @instance
         */
        WagesList.prototype.total = 0;

        /**
         * Encodes the specified WagesList message. Does not implicitly {@link PlatformAdminApi.WagesList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WagesList
         * @static
         * @param {PlatformAdminApi.IWagesList} message WagesList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WagesList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.wages != null && message.wages.length)
                for (var i = 0; i < message.wages.length; ++i)
                    $root.PlatformAdminApi.WagesListItem.encode(message.wages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a WagesList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WagesList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WagesList} WagesList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WagesList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WagesList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.wages && message.wages.length))
                        message.wages = [];
                    message.wages.push($root.PlatformAdminApi.WagesListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WagesList;
    })();

    PlatformAdminApi.WageSearchRequest = (function() {

        /**
         * Properties of a WageSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IWageSearchRequest
         * @property {number|null} [page] WageSearchRequest page
         * @property {number|null} [limit] WageSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] WageSearchRequest sort
         * @property {Array.<PlatformAdminApi.WageSearchRequest.IWhereFields>|null} [where] WageSearchRequest where
         */

        /**
         * Constructs a new WageSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSearchRequest.
         * @implements IWageSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IWageSearchRequest=} [properties] Properties to set
         */
        function WageSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.WageSearchRequest
         * @instance
         */
        WageSearchRequest.prototype.page = 0;

        /**
         * WageSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.WageSearchRequest
         * @instance
         */
        WageSearchRequest.prototype.limit = 0;

        /**
         * WageSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.WageSearchRequest
         * @instance
         */
        WageSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * WageSearchRequest where.
         * @member {Array.<PlatformAdminApi.WageSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.WageSearchRequest
         * @instance
         */
        WageSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified WageSearchRequest message. Does not implicitly {@link PlatformAdminApi.WageSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSearchRequest
         * @static
         * @param {PlatformAdminApi.IWageSearchRequest} message WageSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.WageSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSearchRequest} WageSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.WageSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        WageSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.WageSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {PlatformAdminApi.IStringCondition|null} [productSlug] WhereFields productSlug
             * @property {PlatformAdminApi.IUuidCondition|null} [specializationId] WhereFields specializationId
             * @property {PlatformAdminApi.IUuidCondition|null} [regionId] WhereFields regionId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.WageSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.WageSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields productSlug.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} productSlug
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.productSlug = null;

            /**
             * WhereFields specializationId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} specializationId
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.specializationId = null;

            /**
             * WhereFields regionId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} regionId
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.regionId = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.WageSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.WageSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                    $root.PlatformAdminApi.StringCondition.encode(message.productSlug, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.specializationId != null && Object.hasOwnProperty.call(message, "specializationId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.specializationId, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.regionId, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.WageSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.WageSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.isDeleted = reader.bool();
                        break;
                    case 6:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.productSlug = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.specializationId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.regionId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return WageSearchRequest;
    })();

    PlatformAdminApi.WageSearchResponse = (function() {

        /**
         * Properties of a WageSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IWageSearchResponse
         * @property {Array.<PlatformAdminApi.IWage>|null} [data] WageSearchResponse data
         * @property {number|null} [page] WageSearchResponse page
         * @property {number|null} [pageCount] WageSearchResponse pageCount
         * @property {number|null} [limit] WageSearchResponse limit
         * @property {number|null} [count] WageSearchResponse count
         * @property {number|null} [total] WageSearchResponse total
         */

        /**
         * Constructs a new WageSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSearchResponse.
         * @implements IWageSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IWageSearchResponse=} [properties] Properties to set
         */
        function WageSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSearchResponse data.
         * @member {Array.<PlatformAdminApi.IWage>} data
         * @memberof PlatformAdminApi.WageSearchResponse
         * @instance
         */
        WageSearchResponse.prototype.data = $util.emptyArray;

        /**
         * WageSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.WageSearchResponse
         * @instance
         */
        WageSearchResponse.prototype.page = 0;

        /**
         * WageSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.WageSearchResponse
         * @instance
         */
        WageSearchResponse.prototype.pageCount = 0;

        /**
         * WageSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.WageSearchResponse
         * @instance
         */
        WageSearchResponse.prototype.limit = 0;

        /**
         * WageSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.WageSearchResponse
         * @instance
         */
        WageSearchResponse.prototype.count = 0;

        /**
         * WageSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.WageSearchResponse
         * @instance
         */
        WageSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified WageSearchResponse message. Does not implicitly {@link PlatformAdminApi.WageSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSearchResponse
         * @static
         * @param {PlatformAdminApi.IWageSearchResponse} message WageSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Wage.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a WageSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSearchResponse} WageSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Wage.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSearchResponse;
    })();

    PlatformAdminApi.WageGradeView = (function() {

        /**
         * Properties of a WageGradeView.
         * @memberof PlatformAdminApi
         * @interface IWageGradeView
         * @property {string|null} [id] WageGradeView id
         * @property {string|null} [position] WageGradeView position
         * @property {number|null} [baseRate] WageGradeView baseRate
         * @property {string|null} [rateType] WageGradeView rateType
         * @property {string|null} [rateUnit] WageGradeView rateUnit
         * @property {Array.<PlatformAdminApi.IWageCompensationView>|null} [compensations] WageGradeView compensations
         * @property {Array.<PlatformAdminApi.IWageGradeAdditionalRate>|null} [additionalRates] WageGradeView additionalRates
         */

        /**
         * Constructs a new WageGradeView.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageGradeView.
         * @implements IWageGradeView
         * @constructor
         * @param {PlatformAdminApi.IWageGradeView=} [properties] Properties to set
         */
        function WageGradeView(properties) {
            this.compensations = [];
            this.additionalRates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageGradeView id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageGradeView
         * @instance
         */
        WageGradeView.prototype.id = "";

        /**
         * WageGradeView position.
         * @member {string} position
         * @memberof PlatformAdminApi.WageGradeView
         * @instance
         */
        WageGradeView.prototype.position = "";

        /**
         * WageGradeView baseRate.
         * @member {number} baseRate
         * @memberof PlatformAdminApi.WageGradeView
         * @instance
         */
        WageGradeView.prototype.baseRate = 0;

        /**
         * WageGradeView rateType.
         * @member {string} rateType
         * @memberof PlatformAdminApi.WageGradeView
         * @instance
         */
        WageGradeView.prototype.rateType = "";

        /**
         * WageGradeView rateUnit.
         * @member {string} rateUnit
         * @memberof PlatformAdminApi.WageGradeView
         * @instance
         */
        WageGradeView.prototype.rateUnit = "";

        /**
         * WageGradeView compensations.
         * @member {Array.<PlatformAdminApi.IWageCompensationView>} compensations
         * @memberof PlatformAdminApi.WageGradeView
         * @instance
         */
        WageGradeView.prototype.compensations = $util.emptyArray;

        /**
         * WageGradeView additionalRates.
         * @member {Array.<PlatformAdminApi.IWageGradeAdditionalRate>} additionalRates
         * @memberof PlatformAdminApi.WageGradeView
         * @instance
         */
        WageGradeView.prototype.additionalRates = $util.emptyArray;

        /**
         * Encodes the specified WageGradeView message. Does not implicitly {@link PlatformAdminApi.WageGradeView.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageGradeView
         * @static
         * @param {PlatformAdminApi.IWageGradeView} message WageGradeView message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageGradeView.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.position);
            if (message.baseRate != null && Object.hasOwnProperty.call(message, "baseRate"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.baseRate);
            if (message.rateType != null && Object.hasOwnProperty.call(message, "rateType"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.rateType);
            if (message.rateUnit != null && Object.hasOwnProperty.call(message, "rateUnit"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.rateUnit);
            if (message.compensations != null && message.compensations.length)
                for (var i = 0; i < message.compensations.length; ++i)
                    $root.PlatformAdminApi.WageCompensationView.encode(message.compensations[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.additionalRates != null && message.additionalRates.length)
                for (var i = 0; i < message.additionalRates.length; ++i)
                    $root.PlatformAdminApi.WageGradeAdditionalRate.encode(message.additionalRates[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageGradeView message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageGradeView
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageGradeView} WageGradeView
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageGradeView.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageGradeView();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.position = reader.string();
                    break;
                case 3:
                    message.baseRate = reader.int32();
                    break;
                case 4:
                    message.rateType = reader.string();
                    break;
                case 5:
                    message.rateUnit = reader.string();
                    break;
                case 6:
                    if (!(message.compensations && message.compensations.length))
                        message.compensations = [];
                    message.compensations.push($root.PlatformAdminApi.WageCompensationView.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.additionalRates && message.additionalRates.length))
                        message.additionalRates = [];
                    message.additionalRates.push($root.PlatformAdminApi.WageGradeAdditionalRate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageGradeView;
    })();

    PlatformAdminApi.WageViewResponse = (function() {

        /**
         * Properties of a WageViewResponse.
         * @memberof PlatformAdminApi
         * @interface IWageViewResponse
         * @property {string|null} [id] WageViewResponse id
         * @property {string|null} [name] WageViewResponse name
         * @property {string|null} [type] WageViewResponse type
         * @property {string|null} [specialization] WageViewResponse specialization
         * @property {string|null} [product] WageViewResponse product
         * @property {string|null} [createdAt] WageViewResponse createdAt
         * @property {string|null} [updatedAt] WageViewResponse updatedAt
         * @property {boolean|null} [isDeleted] WageViewResponse isDeleted
         * @property {Array.<PlatformAdminApi.IWageGradeView>|null} [grades] WageViewResponse grades
         */

        /**
         * Constructs a new WageViewResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageViewResponse.
         * @implements IWageViewResponse
         * @constructor
         * @param {PlatformAdminApi.IWageViewResponse=} [properties] Properties to set
         */
        function WageViewResponse(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageViewResponse id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.id = "";

        /**
         * WageViewResponse name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.name = "";

        /**
         * WageViewResponse type.
         * @member {string} type
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.type = "";

        /**
         * WageViewResponse specialization.
         * @member {string} specialization
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.specialization = "";

        /**
         * WageViewResponse product.
         * @member {string} product
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.product = "";

        /**
         * WageViewResponse createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.createdAt = "";

        /**
         * WageViewResponse updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.updatedAt = "";

        /**
         * WageViewResponse isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.isDeleted = false;

        /**
         * WageViewResponse grades.
         * @member {Array.<PlatformAdminApi.IWageGradeView>} grades
         * @memberof PlatformAdminApi.WageViewResponse
         * @instance
         */
        WageViewResponse.prototype.grades = $util.emptyArray;

        /**
         * Encodes the specified WageViewResponse message. Does not implicitly {@link PlatformAdminApi.WageViewResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageViewResponse
         * @static
         * @param {PlatformAdminApi.IWageViewResponse} message WageViewResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageViewResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.specialization);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.product);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeleted);
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.WageGradeView.encode(message.grades[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageViewResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageViewResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageViewResponse} WageViewResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageViewResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageViewResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.specialization = reader.string();
                    break;
                case 5:
                    message.product = reader.string();
                    break;
                case 6:
                    message.createdAt = reader.string();
                    break;
                case 7:
                    message.updatedAt = reader.string();
                    break;
                case 8:
                    message.isDeleted = reader.bool();
                    break;
                case 9:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.WageGradeView.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageViewResponse;
    })();

    PlatformAdminApi.WageCompensationView = (function() {

        /**
         * Properties of a WageCompensationView.
         * @memberof PlatformAdminApi
         * @interface IWageCompensationView
         * @property {string|null} [id] WageCompensationView id
         * @property {string|null} [type] WageCompensationView type
         * @property {number|null} [amount] WageCompensationView amount
         * @property {string|null} [optionTitle] WageCompensationView optionTitle
         * @property {string|null} [optionName] WageCompensationView optionName
         */

        /**
         * Constructs a new WageCompensationView.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageCompensationView.
         * @implements IWageCompensationView
         * @constructor
         * @param {PlatformAdminApi.IWageCompensationView=} [properties] Properties to set
         */
        function WageCompensationView(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageCompensationView id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageCompensationView
         * @instance
         */
        WageCompensationView.prototype.id = "";

        /**
         * WageCompensationView type.
         * @member {string} type
         * @memberof PlatformAdminApi.WageCompensationView
         * @instance
         */
        WageCompensationView.prototype.type = "";

        /**
         * WageCompensationView amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.WageCompensationView
         * @instance
         */
        WageCompensationView.prototype.amount = 0;

        /**
         * WageCompensationView optionTitle.
         * @member {string} optionTitle
         * @memberof PlatformAdminApi.WageCompensationView
         * @instance
         */
        WageCompensationView.prototype.optionTitle = "";

        /**
         * WageCompensationView optionName.
         * @member {string} optionName
         * @memberof PlatformAdminApi.WageCompensationView
         * @instance
         */
        WageCompensationView.prototype.optionName = "";

        /**
         * Encodes the specified WageCompensationView message. Does not implicitly {@link PlatformAdminApi.WageCompensationView.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageCompensationView
         * @static
         * @param {PlatformAdminApi.IWageCompensationView} message WageCompensationView message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageCompensationView.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.optionTitle != null && Object.hasOwnProperty.call(message, "optionTitle"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.optionTitle);
            if (message.optionName != null && Object.hasOwnProperty.call(message, "optionName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.optionName);
            return writer;
        };

        /**
         * Decodes a WageCompensationView message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageCompensationView
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageCompensationView} WageCompensationView
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageCompensationView.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageCompensationView();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.optionTitle = reader.string();
                    break;
                case 5:
                    message.optionName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageCompensationView;
    })();

    PlatformAdminApi.WageGradeAdditionalRate = (function() {

        /**
         * Properties of a WageGradeAdditionalRate.
         * @memberof PlatformAdminApi
         * @interface IWageGradeAdditionalRate
         * @property {string|null} [id] WageGradeAdditionalRate id
         * @property {string|null} [type] WageGradeAdditionalRate type
         * @property {number|null} [amount] WageGradeAdditionalRate amount
         * @property {string|null} [regionId] WageGradeAdditionalRate regionId
         */

        /**
         * Constructs a new WageGradeAdditionalRate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageGradeAdditionalRate.
         * @implements IWageGradeAdditionalRate
         * @constructor
         * @param {PlatformAdminApi.IWageGradeAdditionalRate=} [properties] Properties to set
         */
        function WageGradeAdditionalRate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageGradeAdditionalRate id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageGradeAdditionalRate
         * @instance
         */
        WageGradeAdditionalRate.prototype.id = "";

        /**
         * WageGradeAdditionalRate type.
         * @member {string} type
         * @memberof PlatformAdminApi.WageGradeAdditionalRate
         * @instance
         */
        WageGradeAdditionalRate.prototype.type = "";

        /**
         * WageGradeAdditionalRate amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.WageGradeAdditionalRate
         * @instance
         */
        WageGradeAdditionalRate.prototype.amount = 0;

        /**
         * WageGradeAdditionalRate regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.WageGradeAdditionalRate
         * @instance
         */
        WageGradeAdditionalRate.prototype.regionId = "";

        /**
         * Encodes the specified WageGradeAdditionalRate message. Does not implicitly {@link PlatformAdminApi.WageGradeAdditionalRate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageGradeAdditionalRate
         * @static
         * @param {PlatformAdminApi.IWageGradeAdditionalRate} message WageGradeAdditionalRate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageGradeAdditionalRate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.regionId);
            return writer;
        };

        /**
         * Decodes a WageGradeAdditionalRate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageGradeAdditionalRate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageGradeAdditionalRate} WageGradeAdditionalRate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageGradeAdditionalRate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageGradeAdditionalRate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.regionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageGradeAdditionalRate;
    })();

    PlatformAdminApi.WageWithRegion = (function() {

        /**
         * Properties of a WageWithRegion.
         * @memberof PlatformAdminApi
         * @interface IWageWithRegion
         * @property {string|null} [id] WageWithRegion id
         * @property {string|null} [createdAt] WageWithRegion createdAt
         * @property {string|null} [updatedAt] WageWithRegion updatedAt
         * @property {boolean|null} [isDeleted] WageWithRegion isDeleted
         * @property {string|null} [deletedAt] WageWithRegion deletedAt
         * @property {string|null} [name] WageWithRegion name
         * @property {PlatformAdminApi.IProduct|null} [product] WageWithRegion product
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] WageWithRegion specialization
         * @property {Array.<PlatformAdminApi.IGrade>|null} [grades] WageWithRegion grades
         * @property {PlatformAdminApi.IRegion|null} [region] WageWithRegion region
         * @property {string|null} [type] WageWithRegion type
         */

        /**
         * Constructs a new WageWithRegion.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageWithRegion.
         * @implements IWageWithRegion
         * @constructor
         * @param {PlatformAdminApi.IWageWithRegion=} [properties] Properties to set
         */
        function WageWithRegion(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageWithRegion id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.id = "";

        /**
         * WageWithRegion createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.createdAt = "";

        /**
         * WageWithRegion updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.updatedAt = "";

        /**
         * WageWithRegion isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.isDeleted = false;

        /**
         * WageWithRegion deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.deletedAt = "";

        /**
         * WageWithRegion name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.name = "";

        /**
         * WageWithRegion product.
         * @member {PlatformAdminApi.IProduct|null|undefined} product
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.product = null;

        /**
         * WageWithRegion specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.specialization = null;

        /**
         * WageWithRegion grades.
         * @member {Array.<PlatformAdminApi.IGrade>} grades
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.grades = $util.emptyArray;

        /**
         * WageWithRegion region.
         * @member {PlatformAdminApi.IRegion|null|undefined} region
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.region = null;

        /**
         * WageWithRegion type.
         * @member {string} type
         * @memberof PlatformAdminApi.WageWithRegion
         * @instance
         */
        WageWithRegion.prototype.type = "";

        /**
         * Encodes the specified WageWithRegion message. Does not implicitly {@link PlatformAdminApi.WageWithRegion.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageWithRegion
         * @static
         * @param {PlatformAdminApi.IWageWithRegion} message WageWithRegion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageWithRegion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.PlatformAdminApi.Product.encode(message.product, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.Grade.encode(message.grades[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                $root.PlatformAdminApi.Region.encode(message.region, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.type);
            return writer;
        };

        /**
         * Decodes a WageWithRegion message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageWithRegion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageWithRegion} WageWithRegion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageWithRegion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageWithRegion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.product = $root.PlatformAdminApi.Product.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 9:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.Grade.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.region = $root.PlatformAdminApi.Region.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageWithRegion;
    })();

    PlatformAdminApi.ContractService = (function() {

        /**
         * Constructs a new ContractService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ContractService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ContractService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ContractService;

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#create}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractResponse} [response] ContractResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractCreatePage} request ContractCreatePage message or plain object
         * @param {PlatformAdminApi.ContractService.CreateCallback} callback Node-style callback called with the error, if any, and ContractResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.ContractCreatePage, $root.PlatformAdminApi.ContractResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractCreatePage} request ContractCreatePage message or plain object
         * @returns {Promise<PlatformAdminApi.ContractResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#update}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractResponse} [response] ContractResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractEditPage} request ContractEditPage message or plain object
         * @param {PlatformAdminApi.ContractService.UpdateCallback} callback Node-style callback called with the error, if any, and ContractResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.ContractEditPage, $root.PlatformAdminApi.ContractResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractEditPage} request ContractEditPage message or plain object
         * @returns {Promise<PlatformAdminApi.ContractResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#search}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractsList} [response] ContractsList
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractSearchRequest} request ContractSearchRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchCallback} callback Node-style callback called with the error, if any, and ContractsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.ContractSearchRequest, $root.PlatformAdminApi.ContractsList, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractSearchRequest} request ContractSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#findById}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractView} [response] ContractView
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractService.FindByIdCallback} callback Node-style callback called with the error, if any, and ContractView
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractView, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractView>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#remove}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractView} [response] ContractView
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractService.RemoveCallback} callback Node-style callback called with the error, if any, and ContractView
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractView, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractView>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#restore}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractView} [response] ContractView
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractService.RestoreCallback} callback Node-style callback called with the error, if any, and ContractView
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractView, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractView>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#block}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef BlockCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractView} [response] ContractView
         */

        /**
         * Calls Block.
         * @function block
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractBlockRequest} request ContractBlockRequest message or plain object
         * @param {PlatformAdminApi.ContractService.BlockCallback} callback Node-style callback called with the error, if any, and ContractView
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.block = function block(request, callback) {
            return this.rpcCall(block, $root.PlatformAdminApi.ContractBlockRequest, $root.PlatformAdminApi.ContractView, request, callback);
        }, "name", { value: "Block" });

        /**
         * Calls Block.
         * @function block
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractBlockRequest} request ContractBlockRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractView>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#searchBlockingReasonGroupSuggests}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchBlockingReasonGroupSuggestsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonGroupSuggestsList} [response] BlockingReasonGroupSuggestsList
         */

        /**
         * Calls SearchBlockingReasonGroupSuggests.
         * @function searchBlockingReasonGroupSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchBlockingReasonGroupSuggestsRequest} request SearchBlockingReasonGroupSuggestsRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchBlockingReasonGroupSuggestsCallback} callback Node-style callback called with the error, if any, and BlockingReasonGroupSuggestsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.searchBlockingReasonGroupSuggests = function searchBlockingReasonGroupSuggests(request, callback) {
            return this.rpcCall(searchBlockingReasonGroupSuggests, $root.PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest, $root.PlatformAdminApi.BlockingReasonGroupSuggestsList, request, callback);
        }, "name", { value: "SearchBlockingReasonGroupSuggests" });

        /**
         * Calls SearchBlockingReasonGroupSuggests.
         * @function searchBlockingReasonGroupSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchBlockingReasonGroupSuggestsRequest} request SearchBlockingReasonGroupSuggestsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonGroupSuggestsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#searchBlockingReasonSuggests}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchBlockingReasonSuggestsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.BlockingReasonSuggestsList} [response] BlockingReasonSuggestsList
         */

        /**
         * Calls SearchBlockingReasonSuggests.
         * @function searchBlockingReasonSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchBlockingReasonSuggestsRequest} request SearchBlockingReasonSuggestsRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchBlockingReasonSuggestsCallback} callback Node-style callback called with the error, if any, and BlockingReasonSuggestsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.searchBlockingReasonSuggests = function searchBlockingReasonSuggests(request, callback) {
            return this.rpcCall(searchBlockingReasonSuggests, $root.PlatformAdminApi.SearchBlockingReasonSuggestsRequest, $root.PlatformAdminApi.BlockingReasonSuggestsList, request, callback);
        }, "name", { value: "SearchBlockingReasonSuggests" });

        /**
         * Calls SearchBlockingReasonSuggests.
         * @function searchBlockingReasonSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchBlockingReasonSuggestsRequest} request SearchBlockingReasonSuggestsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.BlockingReasonSuggestsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#searchWageSuggests}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchWageSuggestsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WageSuggestsList} [response] WageSuggestsList
         */

        /**
         * Calls SearchWageSuggests.
         * @function searchWageSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchWageSuggestsRequest} request SearchWageSuggestsRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchWageSuggestsCallback} callback Node-style callback called with the error, if any, and WageSuggestsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.searchWageSuggests = function searchWageSuggests(request, callback) {
            return this.rpcCall(searchWageSuggests, $root.PlatformAdminApi.SearchWageSuggestsRequest, $root.PlatformAdminApi.WageSuggestsList, request, callback);
        }, "name", { value: "SearchWageSuggests" });

        /**
         * Calls SearchWageSuggests.
         * @function searchWageSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchWageSuggestsRequest} request SearchWageSuggestsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WageSuggestsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#searchSkillSuggests}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchSkillSuggestsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkillSuggestsList} [response] SkillSuggestsList
         */

        /**
         * Calls SearchSkillSuggests.
         * @function searchSkillSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchSkillSuggestsRequest} request SearchSkillSuggestsRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchSkillSuggestsCallback} callback Node-style callback called with the error, if any, and SkillSuggestsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.searchSkillSuggests = function searchSkillSuggests(request, callback) {
            return this.rpcCall(searchSkillSuggests, $root.PlatformAdminApi.SearchSkillSuggestsRequest, $root.PlatformAdminApi.SkillSuggestsList, request, callback);
        }, "name", { value: "SearchSkillSuggests" });

        /**
         * Calls SearchSkillSuggests.
         * @function searchSkillSuggests
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchSkillSuggestsRequest} request SearchSkillSuggestsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkillSuggestsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#retrieveEditContract}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef RetrieveEditContractCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractEditPage} [response] ContractEditPage
         */

        /**
         * Calls RetrieveEditContract.
         * @function retrieveEditContract
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractService.RetrieveEditContractCallback} callback Node-style callback called with the error, if any, and ContractEditPage
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.retrieveEditContract = function retrieveEditContract(request, callback) {
            return this.rpcCall(retrieveEditContract, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractEditPage, request, callback);
        }, "name", { value: "RetrieveEditContract" });

        /**
         * Calls RetrieveEditContract.
         * @function retrieveEditContract
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractEditPage>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#searchProductFilterOptions}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchProductFilterOptionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProductFilterOptionsList} [response] ProductFilterOptionsList
         */

        /**
         * Calls SearchProductFilterOptions.
         * @function searchProductFilterOptions
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchProductFilterOptionsRequest} request SearchProductFilterOptionsRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchProductFilterOptionsCallback} callback Node-style callback called with the error, if any, and ProductFilterOptionsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.searchProductFilterOptions = function searchProductFilterOptions(request, callback) {
            return this.rpcCall(searchProductFilterOptions, $root.PlatformAdminApi.SearchProductFilterOptionsRequest, $root.PlatformAdminApi.ProductFilterOptionsList, request, callback);
        }, "name", { value: "SearchProductFilterOptions" });

        /**
         * Calls SearchProductFilterOptions.
         * @function searchProductFilterOptions
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchProductFilterOptionsRequest} request SearchProductFilterOptionsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProductFilterOptionsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#searchSpecializationFilterOptions}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchSpecializationFilterOptionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SpecializationFilterOptionsList} [response] SpecializationFilterOptionsList
         */

        /**
         * Calls SearchSpecializationFilterOptions.
         * @function searchSpecializationFilterOptions
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchSpecializationFilterOptionsRequest} request SearchSpecializationFilterOptionsRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchSpecializationFilterOptionsCallback} callback Node-style callback called with the error, if any, and SpecializationFilterOptionsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.searchSpecializationFilterOptions = function searchSpecializationFilterOptions(request, callback) {
            return this.rpcCall(searchSpecializationFilterOptions, $root.PlatformAdminApi.SearchSpecializationFilterOptionsRequest, $root.PlatformAdminApi.SpecializationFilterOptionsList, request, callback);
        }, "name", { value: "SearchSpecializationFilterOptions" });

        /**
         * Calls SearchSpecializationFilterOptions.
         * @function searchSpecializationFilterOptions
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.ISearchSpecializationFilterOptionsRequest} request SearchSpecializationFilterOptionsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SpecializationFilterOptionsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractService#searchOldVersion}.
         * @memberof PlatformAdminApi.ContractService
         * @typedef SearchOldVersionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractSearchResponse} [response] ContractSearchResponse
         */

        /**
         * Calls SearchOldVersion.
         * @function searchOldVersion
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractSearchRequest} request ContractSearchRequest message or plain object
         * @param {PlatformAdminApi.ContractService.SearchOldVersionCallback} callback Node-style callback called with the error, if any, and ContractSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractService.prototype.searchOldVersion = function searchOldVersion(request, callback) {
            return this.rpcCall(searchOldVersion, $root.PlatformAdminApi.ContractSearchRequest, $root.PlatformAdminApi.ContractSearchResponse, request, callback);
        }, "name", { value: "SearchOldVersion" });

        /**
         * Calls SearchOldVersion.
         * @function searchOldVersion
         * @memberof PlatformAdminApi.ContractService
         * @instance
         * @param {PlatformAdminApi.IContractSearchRequest} request ContractSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractSearchResponse>} Promise
         * @variation 2
         */

        return ContractService;
    })();

    PlatformAdminApi.ContractResponse = (function() {

        /**
         * Properties of a ContractResponse.
         * @memberof PlatformAdminApi
         * @interface IContractResponse
         * @property {PlatformAdminApi.IContract|null} [data] ContractResponse data
         */

        /**
         * Constructs a new ContractResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractResponse.
         * @implements IContractResponse
         * @constructor
         * @param {PlatformAdminApi.IContractResponse=} [properties] Properties to set
         */
        function ContractResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractResponse data.
         * @member {PlatformAdminApi.IContract|null|undefined} data
         * @memberof PlatformAdminApi.ContractResponse
         * @instance
         */
        ContractResponse.prototype.data = null;

        /**
         * Encodes the specified ContractResponse message. Does not implicitly {@link PlatformAdminApi.ContractResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractResponse
         * @static
         * @param {PlatformAdminApi.IContractResponse} message ContractResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Contract.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractResponse} ContractResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Contract.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractResponse;
    })();

    PlatformAdminApi.Contract = (function() {

        /**
         * Properties of a Contract.
         * @memberof PlatformAdminApi
         * @interface IContract
         * @property {string|null} [id] Contract id
         * @property {string|null} [createdAt] Contract createdAt
         * @property {string|null} [updatedAt] Contract updatedAt
         * @property {boolean|null} [isDeleted] Contract isDeleted
         * @property {string|null} [deletedAt] Contract deletedAt
         * @property {string|null} [status] Contract status
         * @property {PlatformAdminApi.IProduct|null} [product] Contract product
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] Contract specialization
         * @property {PlatformAdminApi.IGrade|null} [grade] Contract grade
         * @property {PlatformAdminApi.IWageRelation|null} [wage] Contract wage
         * @property {PlatformAdminApi.IContractorRelation|null} [contractor] Contract contractor
         * @property {Array.<PlatformAdminApi.ISkillRelation>|null} [skills] Contract skills
         * @property {string|null} [blockedAt] Contract blockedAt
         * @property {PlatformAdminApi.IBlockingReason|null} [blockingReason] Contract blockingReason
         */

        /**
         * Constructs a new Contract.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Contract.
         * @implements IContract
         * @constructor
         * @param {PlatformAdminApi.IContract=} [properties] Properties to set
         */
        function Contract(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Contract id.
         * @member {string} id
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.id = "";

        /**
         * Contract createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.createdAt = "";

        /**
         * Contract updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.updatedAt = "";

        /**
         * Contract isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.isDeleted = false;

        /**
         * Contract deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.deletedAt = "";

        /**
         * Contract status.
         * @member {string} status
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.status = "";

        /**
         * Contract product.
         * @member {PlatformAdminApi.IProduct|null|undefined} product
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.product = null;

        /**
         * Contract specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.specialization = null;

        /**
         * Contract grade.
         * @member {PlatformAdminApi.IGrade|null|undefined} grade
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.grade = null;

        /**
         * Contract wage.
         * @member {PlatformAdminApi.IWageRelation|null|undefined} wage
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.wage = null;

        /**
         * Contract contractor.
         * @member {PlatformAdminApi.IContractorRelation|null|undefined} contractor
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.contractor = null;

        /**
         * Contract skills.
         * @member {Array.<PlatformAdminApi.ISkillRelation>} skills
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.skills = $util.emptyArray;

        /**
         * Contract blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.blockedAt = "";

        /**
         * Contract blockingReason.
         * @member {PlatformAdminApi.IBlockingReason|null|undefined} blockingReason
         * @memberof PlatformAdminApi.Contract
         * @instance
         */
        Contract.prototype.blockingReason = null;

        /**
         * Encodes the specified Contract message. Does not implicitly {@link PlatformAdminApi.Contract.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Contract
         * @static
         * @param {PlatformAdminApi.IContract} message Contract message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Contract.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.status);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.PlatformAdminApi.Product.encode(message.product, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                $root.PlatformAdminApi.Grade.encode(message.grade, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                $root.PlatformAdminApi.WageRelation.encode(message.wage, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.contractor != null && Object.hasOwnProperty.call(message, "contractor"))
                $root.PlatformAdminApi.ContractorRelation.encode(message.contractor, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.SkillRelation.encode(message.skills[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.blockedAt);
            if (message.blockingReason != null && Object.hasOwnProperty.call(message, "blockingReason"))
                $root.PlatformAdminApi.BlockingReason.encode(message.blockingReason, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Contract message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Contract
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Contract} Contract
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Contract.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Contract();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.status = reader.string();
                    break;
                case 7:
                    message.product = $root.PlatformAdminApi.Product.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.grade = $root.PlatformAdminApi.Grade.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.wage = $root.PlatformAdminApi.WageRelation.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.contractor = $root.PlatformAdminApi.ContractorRelation.decode(reader, reader.uint32());
                    break;
                case 12:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.SkillRelation.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.blockedAt = reader.string();
                    break;
                case 14:
                    message.blockingReason = $root.PlatformAdminApi.BlockingReason.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Contract;
    })();

    PlatformAdminApi.ContractorRelation = (function() {

        /**
         * Properties of a ContractorRelation.
         * @memberof PlatformAdminApi
         * @interface IContractorRelation
         * @property {string|null} [id] ContractorRelation id
         * @property {string|null} [userId] ContractorRelation userId
         * @property {string|null} [status] ContractorRelation status
         * @property {string|null} [workStatus] ContractorRelation workStatus
         * @property {number|null} [rating] ContractorRelation rating
         * @property {string|null} [regionId] ContractorRelation regionId
         * @property {PlatformAdminApi.IContractorProfile|null} [profile] ContractorRelation profile
         */

        /**
         * Constructs a new ContractorRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorRelation.
         * @implements IContractorRelation
         * @constructor
         * @param {PlatformAdminApi.IContractorRelation=} [properties] Properties to set
         */
        function ContractorRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorRelation
         * @instance
         */
        ContractorRelation.prototype.id = "";

        /**
         * ContractorRelation userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.ContractorRelation
         * @instance
         */
        ContractorRelation.prototype.userId = "";

        /**
         * ContractorRelation status.
         * @member {string} status
         * @memberof PlatformAdminApi.ContractorRelation
         * @instance
         */
        ContractorRelation.prototype.status = "";

        /**
         * ContractorRelation workStatus.
         * @member {string} workStatus
         * @memberof PlatformAdminApi.ContractorRelation
         * @instance
         */
        ContractorRelation.prototype.workStatus = "";

        /**
         * ContractorRelation rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.ContractorRelation
         * @instance
         */
        ContractorRelation.prototype.rating = 0;

        /**
         * ContractorRelation regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.ContractorRelation
         * @instance
         */
        ContractorRelation.prototype.regionId = "";

        /**
         * ContractorRelation profile.
         * @member {PlatformAdminApi.IContractorProfile|null|undefined} profile
         * @memberof PlatformAdminApi.ContractorRelation
         * @instance
         */
        ContractorRelation.prototype.profile = null;

        /**
         * Encodes the specified ContractorRelation message. Does not implicitly {@link PlatformAdminApi.ContractorRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorRelation
         * @static
         * @param {PlatformAdminApi.IContractorRelation} message ContractorRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.workStatus != null && Object.hasOwnProperty.call(message, "workStatus"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.workStatus);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.rating);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.regionId);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.PlatformAdminApi.ContractorProfile.encode(message.profile, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorRelation} ContractorRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.workStatus = reader.string();
                    break;
                case 5:
                    message.rating = reader.double();
                    break;
                case 6:
                    message.regionId = reader.string();
                    break;
                case 7:
                    message.profile = $root.PlatformAdminApi.ContractorProfile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorRelation;
    })();

    PlatformAdminApi.GradeOption = (function() {

        /**
         * Properties of a GradeOption.
         * @memberof PlatformAdminApi
         * @interface IGradeOption
         * @property {string|null} [id] GradeOption id
         * @property {string|null} [title] GradeOption title
         */

        /**
         * Constructs a new GradeOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GradeOption.
         * @implements IGradeOption
         * @constructor
         * @param {PlatformAdminApi.IGradeOption=} [properties] Properties to set
         */
        function GradeOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GradeOption id.
         * @member {string} id
         * @memberof PlatformAdminApi.GradeOption
         * @instance
         */
        GradeOption.prototype.id = "";

        /**
         * GradeOption title.
         * @member {string} title
         * @memberof PlatformAdminApi.GradeOption
         * @instance
         */
        GradeOption.prototype.title = "";

        /**
         * Encodes the specified GradeOption message. Does not implicitly {@link PlatformAdminApi.GradeOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GradeOption
         * @static
         * @param {PlatformAdminApi.IGradeOption} message GradeOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GradeOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes a GradeOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GradeOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GradeOption} GradeOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GradeOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GradeOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GradeOption;
    })();

    PlatformAdminApi.SearchSkillSuggestsRequest = (function() {

        /**
         * Properties of a SearchSkillSuggestsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchSkillSuggestsRequest
         * @property {string|null} [searchString] SearchSkillSuggestsRequest searchString
         */

        /**
         * Constructs a new SearchSkillSuggestsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchSkillSuggestsRequest.
         * @implements ISearchSkillSuggestsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchSkillSuggestsRequest=} [properties] Properties to set
         */
        function SearchSkillSuggestsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchSkillSuggestsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.SearchSkillSuggestsRequest
         * @instance
         */
        SearchSkillSuggestsRequest.prototype.searchString = "";

        /**
         * Encodes the specified SearchSkillSuggestsRequest message. Does not implicitly {@link PlatformAdminApi.SearchSkillSuggestsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchSkillSuggestsRequest
         * @static
         * @param {PlatformAdminApi.ISearchSkillSuggestsRequest} message SearchSkillSuggestsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSkillSuggestsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a SearchSkillSuggestsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchSkillSuggestsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchSkillSuggestsRequest} SearchSkillSuggestsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSkillSuggestsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchSkillSuggestsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchSkillSuggestsRequest;
    })();

    PlatformAdminApi.SkillSuggestsListItem = (function() {

        /**
         * Properties of a SkillSuggestsListItem.
         * @memberof PlatformAdminApi
         * @interface ISkillSuggestsListItem
         * @property {string|null} [id] SkillSuggestsListItem id
         * @property {string|null} [title] SkillSuggestsListItem title
         */

        /**
         * Constructs a new SkillSuggestsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkillSuggestsListItem.
         * @implements ISkillSuggestsListItem
         * @constructor
         * @param {PlatformAdminApi.ISkillSuggestsListItem=} [properties] Properties to set
         */
        function SkillSuggestsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillSuggestsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.SkillSuggestsListItem
         * @instance
         */
        SkillSuggestsListItem.prototype.id = "";

        /**
         * SkillSuggestsListItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.SkillSuggestsListItem
         * @instance
         */
        SkillSuggestsListItem.prototype.title = "";

        /**
         * Encodes the specified SkillSuggestsListItem message. Does not implicitly {@link PlatformAdminApi.SkillSuggestsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillSuggestsListItem
         * @static
         * @param {PlatformAdminApi.ISkillSuggestsListItem} message SkillSuggestsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillSuggestsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes a SkillSuggestsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillSuggestsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillSuggestsListItem} SkillSuggestsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillSuggestsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillSuggestsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillSuggestsListItem;
    })();

    PlatformAdminApi.SkillSuggestsList = (function() {

        /**
         * Properties of a SkillSuggestsList.
         * @memberof PlatformAdminApi
         * @interface ISkillSuggestsList
         * @property {Array.<PlatformAdminApi.ISkillSuggestsListItem>|null} [suggests] SkillSuggestsList suggests
         */

        /**
         * Constructs a new SkillSuggestsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkillSuggestsList.
         * @implements ISkillSuggestsList
         * @constructor
         * @param {PlatformAdminApi.ISkillSuggestsList=} [properties] Properties to set
         */
        function SkillSuggestsList(properties) {
            this.suggests = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkillSuggestsList suggests.
         * @member {Array.<PlatformAdminApi.ISkillSuggestsListItem>} suggests
         * @memberof PlatformAdminApi.SkillSuggestsList
         * @instance
         */
        SkillSuggestsList.prototype.suggests = $util.emptyArray;

        /**
         * Encodes the specified SkillSuggestsList message. Does not implicitly {@link PlatformAdminApi.SkillSuggestsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkillSuggestsList
         * @static
         * @param {PlatformAdminApi.ISkillSuggestsList} message SkillSuggestsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkillSuggestsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.suggests != null && message.suggests.length)
                for (var i = 0; i < message.suggests.length; ++i)
                    $root.PlatformAdminApi.SkillSuggestsListItem.encode(message.suggests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SkillSuggestsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkillSuggestsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkillSuggestsList} SkillSuggestsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkillSuggestsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkillSuggestsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.suggests && message.suggests.length))
                        message.suggests = [];
                    message.suggests.push($root.PlatformAdminApi.SkillSuggestsListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkillSuggestsList;
    })();

    PlatformAdminApi.SearchWageSuggestsRequest = (function() {

        /**
         * Properties of a SearchWageSuggestsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchWageSuggestsRequest
         * @property {string|null} [searchString] SearchWageSuggestsRequest searchString
         */

        /**
         * Constructs a new SearchWageSuggestsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchWageSuggestsRequest.
         * @implements ISearchWageSuggestsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchWageSuggestsRequest=} [properties] Properties to set
         */
        function SearchWageSuggestsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchWageSuggestsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.SearchWageSuggestsRequest
         * @instance
         */
        SearchWageSuggestsRequest.prototype.searchString = "";

        /**
         * Encodes the specified SearchWageSuggestsRequest message. Does not implicitly {@link PlatformAdminApi.SearchWageSuggestsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchWageSuggestsRequest
         * @static
         * @param {PlatformAdminApi.ISearchWageSuggestsRequest} message SearchWageSuggestsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchWageSuggestsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a SearchWageSuggestsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchWageSuggestsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchWageSuggestsRequest} SearchWageSuggestsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchWageSuggestsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchWageSuggestsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchWageSuggestsRequest;
    })();

    PlatformAdminApi.WageSuggestsListItem = (function() {

        /**
         * Properties of a WageSuggestsListItem.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestsListItem
         * @property {string|null} [id] WageSuggestsListItem id
         * @property {string|null} [name] WageSuggestsListItem name
         * @property {Array.<PlatformAdminApi.IGradeOption>|null} [gradeOptions] WageSuggestsListItem gradeOptions
         */

        /**
         * Constructs a new WageSuggestsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestsListItem.
         * @implements IWageSuggestsListItem
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestsListItem=} [properties] Properties to set
         */
        function WageSuggestsListItem(properties) {
            this.gradeOptions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.WageSuggestsListItem
         * @instance
         */
        WageSuggestsListItem.prototype.id = "";

        /**
         * WageSuggestsListItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.WageSuggestsListItem
         * @instance
         */
        WageSuggestsListItem.prototype.name = "";

        /**
         * WageSuggestsListItem gradeOptions.
         * @member {Array.<PlatformAdminApi.IGradeOption>} gradeOptions
         * @memberof PlatformAdminApi.WageSuggestsListItem
         * @instance
         */
        WageSuggestsListItem.prototype.gradeOptions = $util.emptyArray;

        /**
         * Encodes the specified WageSuggestsListItem message. Does not implicitly {@link PlatformAdminApi.WageSuggestsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestsListItem
         * @static
         * @param {PlatformAdminApi.IWageSuggestsListItem} message WageSuggestsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.gradeOptions != null && message.gradeOptions.length)
                for (var i = 0; i < message.gradeOptions.length; ++i)
                    $root.PlatformAdminApi.GradeOption.encode(message.gradeOptions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSuggestsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestsListItem} WageSuggestsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.gradeOptions && message.gradeOptions.length))
                        message.gradeOptions = [];
                    message.gradeOptions.push($root.PlatformAdminApi.GradeOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestsListItem;
    })();

    PlatformAdminApi.WageSuggestsList = (function() {

        /**
         * Properties of a WageSuggestsList.
         * @memberof PlatformAdminApi
         * @interface IWageSuggestsList
         * @property {Array.<PlatformAdminApi.IWageSuggestsListItem>|null} [suggests] WageSuggestsList suggests
         */

        /**
         * Constructs a new WageSuggestsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WageSuggestsList.
         * @implements IWageSuggestsList
         * @constructor
         * @param {PlatformAdminApi.IWageSuggestsList=} [properties] Properties to set
         */
        function WageSuggestsList(properties) {
            this.suggests = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WageSuggestsList suggests.
         * @member {Array.<PlatformAdminApi.IWageSuggestsListItem>} suggests
         * @memberof PlatformAdminApi.WageSuggestsList
         * @instance
         */
        WageSuggestsList.prototype.suggests = $util.emptyArray;

        /**
         * Encodes the specified WageSuggestsList message. Does not implicitly {@link PlatformAdminApi.WageSuggestsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WageSuggestsList
         * @static
         * @param {PlatformAdminApi.IWageSuggestsList} message WageSuggestsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WageSuggestsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.suggests != null && message.suggests.length)
                for (var i = 0; i < message.suggests.length; ++i)
                    $root.PlatformAdminApi.WageSuggestsListItem.encode(message.suggests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WageSuggestsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WageSuggestsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WageSuggestsList} WageSuggestsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WageSuggestsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WageSuggestsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.suggests && message.suggests.length))
                        message.suggests = [];
                    message.suggests.push($root.PlatformAdminApi.WageSuggestsListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WageSuggestsList;
    })();

    PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest = (function() {

        /**
         * Properties of a SearchBlockingReasonGroupSuggestsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchBlockingReasonGroupSuggestsRequest
         * @property {string|null} [searchString] SearchBlockingReasonGroupSuggestsRequest searchString
         */

        /**
         * Constructs a new SearchBlockingReasonGroupSuggestsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchBlockingReasonGroupSuggestsRequest.
         * @implements ISearchBlockingReasonGroupSuggestsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchBlockingReasonGroupSuggestsRequest=} [properties] Properties to set
         */
        function SearchBlockingReasonGroupSuggestsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchBlockingReasonGroupSuggestsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest
         * @instance
         */
        SearchBlockingReasonGroupSuggestsRequest.prototype.searchString = "";

        /**
         * Encodes the specified SearchBlockingReasonGroupSuggestsRequest message. Does not implicitly {@link PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest
         * @static
         * @param {PlatformAdminApi.ISearchBlockingReasonGroupSuggestsRequest} message SearchBlockingReasonGroupSuggestsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchBlockingReasonGroupSuggestsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a SearchBlockingReasonGroupSuggestsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest} SearchBlockingReasonGroupSuggestsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchBlockingReasonGroupSuggestsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchBlockingReasonGroupSuggestsRequest;
    })();

    PlatformAdminApi.BlockingReasonGroupSuggestsListItem = (function() {

        /**
         * Properties of a BlockingReasonGroupSuggestsListItem.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupSuggestsListItem
         * @property {string|null} [id] BlockingReasonGroupSuggestsListItem id
         * @property {string|null} [name] BlockingReasonGroupSuggestsListItem name
         */

        /**
         * Constructs a new BlockingReasonGroupSuggestsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonGroupSuggestsListItem.
         * @implements IBlockingReasonGroupSuggestsListItem
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupSuggestsListItem=} [properties] Properties to set
         */
        function BlockingReasonGroupSuggestsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupSuggestsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonGroupSuggestsListItem
         * @instance
         */
        BlockingReasonGroupSuggestsListItem.prototype.id = "";

        /**
         * BlockingReasonGroupSuggestsListItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonGroupSuggestsListItem
         * @instance
         */
        BlockingReasonGroupSuggestsListItem.prototype.name = "";

        /**
         * Encodes the specified BlockingReasonGroupSuggestsListItem message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupSuggestsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupSuggestsListItem
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupSuggestsListItem} message BlockingReasonGroupSuggestsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupSuggestsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupSuggestsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupSuggestsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupSuggestsListItem} BlockingReasonGroupSuggestsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupSuggestsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupSuggestsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupSuggestsListItem;
    })();

    PlatformAdminApi.BlockingReasonGroupSuggestsList = (function() {

        /**
         * Properties of a BlockingReasonGroupSuggestsList.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonGroupSuggestsList
         * @property {Array.<PlatformAdminApi.IBlockingReasonGroupSuggestsListItem>|null} [suggests] BlockingReasonGroupSuggestsList suggests
         */

        /**
         * Constructs a new BlockingReasonGroupSuggestsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonGroupSuggestsList.
         * @implements IBlockingReasonGroupSuggestsList
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonGroupSuggestsList=} [properties] Properties to set
         */
        function BlockingReasonGroupSuggestsList(properties) {
            this.suggests = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonGroupSuggestsList suggests.
         * @member {Array.<PlatformAdminApi.IBlockingReasonGroupSuggestsListItem>} suggests
         * @memberof PlatformAdminApi.BlockingReasonGroupSuggestsList
         * @instance
         */
        BlockingReasonGroupSuggestsList.prototype.suggests = $util.emptyArray;

        /**
         * Encodes the specified BlockingReasonGroupSuggestsList message. Does not implicitly {@link PlatformAdminApi.BlockingReasonGroupSuggestsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonGroupSuggestsList
         * @static
         * @param {PlatformAdminApi.IBlockingReasonGroupSuggestsList} message BlockingReasonGroupSuggestsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonGroupSuggestsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.suggests != null && message.suggests.length)
                for (var i = 0; i < message.suggests.length; ++i)
                    $root.PlatformAdminApi.BlockingReasonGroupSuggestsListItem.encode(message.suggests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonGroupSuggestsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonGroupSuggestsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonGroupSuggestsList} BlockingReasonGroupSuggestsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonGroupSuggestsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonGroupSuggestsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.suggests && message.suggests.length))
                        message.suggests = [];
                    message.suggests.push($root.PlatformAdminApi.BlockingReasonGroupSuggestsListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonGroupSuggestsList;
    })();

    PlatformAdminApi.SearchBlockingReasonSuggestsRequest = (function() {

        /**
         * Properties of a SearchBlockingReasonSuggestsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchBlockingReasonSuggestsRequest
         * @property {string|null} [searchString] SearchBlockingReasonSuggestsRequest searchString
         * @property {string|null} [groupName] SearchBlockingReasonSuggestsRequest groupName
         */

        /**
         * Constructs a new SearchBlockingReasonSuggestsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchBlockingReasonSuggestsRequest.
         * @implements ISearchBlockingReasonSuggestsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchBlockingReasonSuggestsRequest=} [properties] Properties to set
         */
        function SearchBlockingReasonSuggestsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchBlockingReasonSuggestsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.SearchBlockingReasonSuggestsRequest
         * @instance
         */
        SearchBlockingReasonSuggestsRequest.prototype.searchString = "";

        /**
         * SearchBlockingReasonSuggestsRequest groupName.
         * @member {string} groupName
         * @memberof PlatformAdminApi.SearchBlockingReasonSuggestsRequest
         * @instance
         */
        SearchBlockingReasonSuggestsRequest.prototype.groupName = "";

        /**
         * Encodes the specified SearchBlockingReasonSuggestsRequest message. Does not implicitly {@link PlatformAdminApi.SearchBlockingReasonSuggestsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchBlockingReasonSuggestsRequest
         * @static
         * @param {PlatformAdminApi.ISearchBlockingReasonSuggestsRequest} message SearchBlockingReasonSuggestsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchBlockingReasonSuggestsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.groupName);
            return writer;
        };

        /**
         * Decodes a SearchBlockingReasonSuggestsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchBlockingReasonSuggestsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchBlockingReasonSuggestsRequest} SearchBlockingReasonSuggestsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchBlockingReasonSuggestsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchBlockingReasonSuggestsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                case 2:
                    message.groupName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchBlockingReasonSuggestsRequest;
    })();

    PlatformAdminApi.BlockingReasonSuggestsListItem = (function() {

        /**
         * Properties of a BlockingReasonSuggestsListItem.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonSuggestsListItem
         * @property {string|null} [id] BlockingReasonSuggestsListItem id
         * @property {string|null} [name] BlockingReasonSuggestsListItem name
         * @property {boolean|null} [isRecoverable] BlockingReasonSuggestsListItem isRecoverable
         */

        /**
         * Constructs a new BlockingReasonSuggestsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonSuggestsListItem.
         * @implements IBlockingReasonSuggestsListItem
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonSuggestsListItem=} [properties] Properties to set
         */
        function BlockingReasonSuggestsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonSuggestsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockingReasonSuggestsListItem
         * @instance
         */
        BlockingReasonSuggestsListItem.prototype.id = "";

        /**
         * BlockingReasonSuggestsListItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.BlockingReasonSuggestsListItem
         * @instance
         */
        BlockingReasonSuggestsListItem.prototype.name = "";

        /**
         * BlockingReasonSuggestsListItem isRecoverable.
         * @member {boolean} isRecoverable
         * @memberof PlatformAdminApi.BlockingReasonSuggestsListItem
         * @instance
         */
        BlockingReasonSuggestsListItem.prototype.isRecoverable = false;

        /**
         * Encodes the specified BlockingReasonSuggestsListItem message. Does not implicitly {@link PlatformAdminApi.BlockingReasonSuggestsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonSuggestsListItem
         * @static
         * @param {PlatformAdminApi.IBlockingReasonSuggestsListItem} message BlockingReasonSuggestsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonSuggestsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.isRecoverable != null && Object.hasOwnProperty.call(message, "isRecoverable"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isRecoverable);
            return writer;
        };

        /**
         * Decodes a BlockingReasonSuggestsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonSuggestsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonSuggestsListItem} BlockingReasonSuggestsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonSuggestsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonSuggestsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.isRecoverable = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonSuggestsListItem;
    })();

    PlatformAdminApi.BlockingReasonSuggestsList = (function() {

        /**
         * Properties of a BlockingReasonSuggestsList.
         * @memberof PlatformAdminApi
         * @interface IBlockingReasonSuggestsList
         * @property {Array.<PlatformAdminApi.IBlockingReasonSuggestsListItem>|null} [suggests] BlockingReasonSuggestsList suggests
         */

        /**
         * Constructs a new BlockingReasonSuggestsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockingReasonSuggestsList.
         * @implements IBlockingReasonSuggestsList
         * @constructor
         * @param {PlatformAdminApi.IBlockingReasonSuggestsList=} [properties] Properties to set
         */
        function BlockingReasonSuggestsList(properties) {
            this.suggests = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonSuggestsList suggests.
         * @member {Array.<PlatformAdminApi.IBlockingReasonSuggestsListItem>} suggests
         * @memberof PlatformAdminApi.BlockingReasonSuggestsList
         * @instance
         */
        BlockingReasonSuggestsList.prototype.suggests = $util.emptyArray;

        /**
         * Encodes the specified BlockingReasonSuggestsList message. Does not implicitly {@link PlatformAdminApi.BlockingReasonSuggestsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockingReasonSuggestsList
         * @static
         * @param {PlatformAdminApi.IBlockingReasonSuggestsList} message BlockingReasonSuggestsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonSuggestsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.suggests != null && message.suggests.length)
                for (var i = 0; i < message.suggests.length; ++i)
                    $root.PlatformAdminApi.BlockingReasonSuggestsListItem.encode(message.suggests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a BlockingReasonSuggestsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockingReasonSuggestsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockingReasonSuggestsList} BlockingReasonSuggestsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonSuggestsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockingReasonSuggestsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.suggests && message.suggests.length))
                        message.suggests = [];
                    message.suggests.push($root.PlatformAdminApi.BlockingReasonSuggestsListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonSuggestsList;
    })();

    PlatformAdminApi.SearchProductFilterOptionsRequest = (function() {

        /**
         * Properties of a SearchProductFilterOptionsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchProductFilterOptionsRequest
         */

        /**
         * Constructs a new SearchProductFilterOptionsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchProductFilterOptionsRequest.
         * @implements ISearchProductFilterOptionsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchProductFilterOptionsRequest=} [properties] Properties to set
         */
        function SearchProductFilterOptionsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified SearchProductFilterOptionsRequest message. Does not implicitly {@link PlatformAdminApi.SearchProductFilterOptionsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchProductFilterOptionsRequest
         * @static
         * @param {PlatformAdminApi.ISearchProductFilterOptionsRequest} message SearchProductFilterOptionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchProductFilterOptionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a SearchProductFilterOptionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchProductFilterOptionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchProductFilterOptionsRequest} SearchProductFilterOptionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchProductFilterOptionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchProductFilterOptionsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchProductFilterOptionsRequest;
    })();

    PlatformAdminApi.ProductFilterOptionsListItem = (function() {

        /**
         * Properties of a ProductFilterOptionsListItem.
         * @memberof PlatformAdminApi
         * @interface IProductFilterOptionsListItem
         * @property {string|null} [id] ProductFilterOptionsListItem id
         * @property {string|null} [name] ProductFilterOptionsListItem name
         * @property {string|null} [title] ProductFilterOptionsListItem title
         */

        /**
         * Constructs a new ProductFilterOptionsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProductFilterOptionsListItem.
         * @implements IProductFilterOptionsListItem
         * @constructor
         * @param {PlatformAdminApi.IProductFilterOptionsListItem=} [properties] Properties to set
         */
        function ProductFilterOptionsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductFilterOptionsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.ProductFilterOptionsListItem
         * @instance
         */
        ProductFilterOptionsListItem.prototype.id = "";

        /**
         * ProductFilterOptionsListItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.ProductFilterOptionsListItem
         * @instance
         */
        ProductFilterOptionsListItem.prototype.name = "";

        /**
         * ProductFilterOptionsListItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.ProductFilterOptionsListItem
         * @instance
         */
        ProductFilterOptionsListItem.prototype.title = "";

        /**
         * Encodes the specified ProductFilterOptionsListItem message. Does not implicitly {@link PlatformAdminApi.ProductFilterOptionsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProductFilterOptionsListItem
         * @static
         * @param {PlatformAdminApi.IProductFilterOptionsListItem} message ProductFilterOptionsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductFilterOptionsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            return writer;
        };

        /**
         * Decodes a ProductFilterOptionsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProductFilterOptionsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProductFilterOptionsListItem} ProductFilterOptionsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductFilterOptionsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProductFilterOptionsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProductFilterOptionsListItem;
    })();

    PlatformAdminApi.ProductFilterOptionsList = (function() {

        /**
         * Properties of a ProductFilterOptionsList.
         * @memberof PlatformAdminApi
         * @interface IProductFilterOptionsList
         * @property {Array.<PlatformAdminApi.IProductFilterOptionsListItem>|null} [options] ProductFilterOptionsList options
         */

        /**
         * Constructs a new ProductFilterOptionsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProductFilterOptionsList.
         * @implements IProductFilterOptionsList
         * @constructor
         * @param {PlatformAdminApi.IProductFilterOptionsList=} [properties] Properties to set
         */
        function ProductFilterOptionsList(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductFilterOptionsList options.
         * @member {Array.<PlatformAdminApi.IProductFilterOptionsListItem>} options
         * @memberof PlatformAdminApi.ProductFilterOptionsList
         * @instance
         */
        ProductFilterOptionsList.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified ProductFilterOptionsList message. Does not implicitly {@link PlatformAdminApi.ProductFilterOptionsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProductFilterOptionsList
         * @static
         * @param {PlatformAdminApi.IProductFilterOptionsList} message ProductFilterOptionsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductFilterOptionsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.ProductFilterOptionsListItem.encode(message.options[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProductFilterOptionsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProductFilterOptionsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProductFilterOptionsList} ProductFilterOptionsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductFilterOptionsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProductFilterOptionsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.ProductFilterOptionsListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProductFilterOptionsList;
    })();

    PlatformAdminApi.SearchSpecializationFilterOptionsRequest = (function() {

        /**
         * Properties of a SearchSpecializationFilterOptionsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchSpecializationFilterOptionsRequest
         */

        /**
         * Constructs a new SearchSpecializationFilterOptionsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchSpecializationFilterOptionsRequest.
         * @implements ISearchSpecializationFilterOptionsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchSpecializationFilterOptionsRequest=} [properties] Properties to set
         */
        function SearchSpecializationFilterOptionsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified SearchSpecializationFilterOptionsRequest message. Does not implicitly {@link PlatformAdminApi.SearchSpecializationFilterOptionsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchSpecializationFilterOptionsRequest
         * @static
         * @param {PlatformAdminApi.ISearchSpecializationFilterOptionsRequest} message SearchSpecializationFilterOptionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchSpecializationFilterOptionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a SearchSpecializationFilterOptionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchSpecializationFilterOptionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchSpecializationFilterOptionsRequest} SearchSpecializationFilterOptionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchSpecializationFilterOptionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchSpecializationFilterOptionsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchSpecializationFilterOptionsRequest;
    })();

    PlatformAdminApi.SpecializationFilterOptionsListItem = (function() {

        /**
         * Properties of a SpecializationFilterOptionsListItem.
         * @memberof PlatformAdminApi
         * @interface ISpecializationFilterOptionsListItem
         * @property {string|null} [id] SpecializationFilterOptionsListItem id
         * @property {string|null} [title] SpecializationFilterOptionsListItem title
         */

        /**
         * Constructs a new SpecializationFilterOptionsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SpecializationFilterOptionsListItem.
         * @implements ISpecializationFilterOptionsListItem
         * @constructor
         * @param {PlatformAdminApi.ISpecializationFilterOptionsListItem=} [properties] Properties to set
         */
        function SpecializationFilterOptionsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationFilterOptionsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.SpecializationFilterOptionsListItem
         * @instance
         */
        SpecializationFilterOptionsListItem.prototype.id = "";

        /**
         * SpecializationFilterOptionsListItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.SpecializationFilterOptionsListItem
         * @instance
         */
        SpecializationFilterOptionsListItem.prototype.title = "";

        /**
         * Encodes the specified SpecializationFilterOptionsListItem message. Does not implicitly {@link PlatformAdminApi.SpecializationFilterOptionsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationFilterOptionsListItem
         * @static
         * @param {PlatformAdminApi.ISpecializationFilterOptionsListItem} message SpecializationFilterOptionsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationFilterOptionsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes a SpecializationFilterOptionsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationFilterOptionsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationFilterOptionsListItem} SpecializationFilterOptionsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationFilterOptionsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationFilterOptionsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationFilterOptionsListItem;
    })();

    PlatformAdminApi.SpecializationFilterOptionsList = (function() {

        /**
         * Properties of a SpecializationFilterOptionsList.
         * @memberof PlatformAdminApi
         * @interface ISpecializationFilterOptionsList
         * @property {Array.<PlatformAdminApi.ISpecializationFilterOptionsListItem>|null} [options] SpecializationFilterOptionsList options
         */

        /**
         * Constructs a new SpecializationFilterOptionsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SpecializationFilterOptionsList.
         * @implements ISpecializationFilterOptionsList
         * @constructor
         * @param {PlatformAdminApi.ISpecializationFilterOptionsList=} [properties] Properties to set
         */
        function SpecializationFilterOptionsList(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecializationFilterOptionsList options.
         * @member {Array.<PlatformAdminApi.ISpecializationFilterOptionsListItem>} options
         * @memberof PlatformAdminApi.SpecializationFilterOptionsList
         * @instance
         */
        SpecializationFilterOptionsList.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified SpecializationFilterOptionsList message. Does not implicitly {@link PlatformAdminApi.SpecializationFilterOptionsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SpecializationFilterOptionsList
         * @static
         * @param {PlatformAdminApi.ISpecializationFilterOptionsList} message SpecializationFilterOptionsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecializationFilterOptionsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.SpecializationFilterOptionsListItem.encode(message.options[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SpecializationFilterOptionsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SpecializationFilterOptionsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SpecializationFilterOptionsList} SpecializationFilterOptionsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecializationFilterOptionsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SpecializationFilterOptionsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.SpecializationFilterOptionsListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SpecializationFilterOptionsList;
    })();

    PlatformAdminApi.ContractCreatePage = (function() {

        /**
         * Properties of a ContractCreatePage.
         * @memberof PlatformAdminApi
         * @interface IContractCreatePage
         * @property {PlatformAdminApi.IWageRelation|null} [wage] ContractCreatePage wage
         * @property {PlatformAdminApi.IGradeOption|null} [grade] ContractCreatePage grade
         * @property {string|null} [contractorId] ContractCreatePage contractorId
         * @property {Array.<PlatformAdminApi.ISkillSuggestsListItem>|null} [skills] ContractCreatePage skills
         */

        /**
         * Constructs a new ContractCreatePage.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractCreatePage.
         * @implements IContractCreatePage
         * @constructor
         * @param {PlatformAdminApi.IContractCreatePage=} [properties] Properties to set
         */
        function ContractCreatePage(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractCreatePage wage.
         * @member {PlatformAdminApi.IWageRelation|null|undefined} wage
         * @memberof PlatformAdminApi.ContractCreatePage
         * @instance
         */
        ContractCreatePage.prototype.wage = null;

        /**
         * ContractCreatePage grade.
         * @member {PlatformAdminApi.IGradeOption|null|undefined} grade
         * @memberof PlatformAdminApi.ContractCreatePage
         * @instance
         */
        ContractCreatePage.prototype.grade = null;

        /**
         * ContractCreatePage contractorId.
         * @member {string} contractorId
         * @memberof PlatformAdminApi.ContractCreatePage
         * @instance
         */
        ContractCreatePage.prototype.contractorId = "";

        /**
         * ContractCreatePage skills.
         * @member {Array.<PlatformAdminApi.ISkillSuggestsListItem>} skills
         * @memberof PlatformAdminApi.ContractCreatePage
         * @instance
         */
        ContractCreatePage.prototype.skills = $util.emptyArray;

        /**
         * Encodes the specified ContractCreatePage message. Does not implicitly {@link PlatformAdminApi.ContractCreatePage.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractCreatePage
         * @static
         * @param {PlatformAdminApi.IContractCreatePage} message ContractCreatePage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractCreatePage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                $root.PlatformAdminApi.WageRelation.encode(message.wage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                $root.PlatformAdminApi.GradeOption.encode(message.grade, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.contractorId);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.SkillSuggestsListItem.encode(message.skills[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractCreatePage message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractCreatePage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractCreatePage} ContractCreatePage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractCreatePage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractCreatePage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.wage = $root.PlatformAdminApi.WageRelation.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.grade = $root.PlatformAdminApi.GradeOption.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.contractorId = reader.string();
                    break;
                case 4:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.SkillSuggestsListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractCreatePage;
    })();

    PlatformAdminApi.ContractCreateRequest = (function() {

        /**
         * Properties of a ContractCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractCreateRequest
         * @property {PlatformAdminApi.IGradeRelation|null} [grade] ContractCreateRequest grade
         * @property {PlatformAdminApi.IWageRelation|null} [wage] ContractCreateRequest wage
         * @property {PlatformAdminApi.IRelation|null} [contractor] ContractCreateRequest contractor
         * @property {Array.<PlatformAdminApi.IRelation>|null} [skills] ContractCreateRequest skills
         */

        /**
         * Constructs a new ContractCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractCreateRequest.
         * @implements IContractCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractCreateRequest=} [properties] Properties to set
         */
        function ContractCreateRequest(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractCreateRequest grade.
         * @member {PlatformAdminApi.IGradeRelation|null|undefined} grade
         * @memberof PlatformAdminApi.ContractCreateRequest
         * @instance
         */
        ContractCreateRequest.prototype.grade = null;

        /**
         * ContractCreateRequest wage.
         * @member {PlatformAdminApi.IWageRelation|null|undefined} wage
         * @memberof PlatformAdminApi.ContractCreateRequest
         * @instance
         */
        ContractCreateRequest.prototype.wage = null;

        /**
         * ContractCreateRequest contractor.
         * @member {PlatformAdminApi.IRelation|null|undefined} contractor
         * @memberof PlatformAdminApi.ContractCreateRequest
         * @instance
         */
        ContractCreateRequest.prototype.contractor = null;

        /**
         * ContractCreateRequest skills.
         * @member {Array.<PlatformAdminApi.IRelation>} skills
         * @memberof PlatformAdminApi.ContractCreateRequest
         * @instance
         */
        ContractCreateRequest.prototype.skills = $util.emptyArray;

        /**
         * Encodes the specified ContractCreateRequest message. Does not implicitly {@link PlatformAdminApi.ContractCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractCreateRequest
         * @static
         * @param {PlatformAdminApi.IContractCreateRequest} message ContractCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                $root.PlatformAdminApi.GradeRelation.encode(message.grade, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                $root.PlatformAdminApi.WageRelation.encode(message.wage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.contractor != null && Object.hasOwnProperty.call(message, "contractor"))
                $root.PlatformAdminApi.Relation.encode(message.contractor, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.Relation.encode(message.skills[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractCreateRequest} ContractCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.grade = $root.PlatformAdminApi.GradeRelation.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.wage = $root.PlatformAdminApi.WageRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.contractor = $root.PlatformAdminApi.Relation.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.Relation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractCreateRequest;
    })();

    PlatformAdminApi.ContractEditPage = (function() {

        /**
         * Properties of a ContractEditPage.
         * @memberof PlatformAdminApi
         * @interface IContractEditPage
         * @property {string|null} [id] ContractEditPage id
         * @property {PlatformAdminApi.IWageRelation|null} [wage] ContractEditPage wage
         * @property {PlatformAdminApi.IGradeOption|null} [grade] ContractEditPage grade
         * @property {string|null} [contractorId] ContractEditPage contractorId
         * @property {Array.<PlatformAdminApi.ISkillSuggestsListItem>|null} [skills] ContractEditPage skills
         * @property {Array.<PlatformAdminApi.IGradeOption>|null} [gradeOptions] ContractEditPage gradeOptions
         */

        /**
         * Constructs a new ContractEditPage.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractEditPage.
         * @implements IContractEditPage
         * @constructor
         * @param {PlatformAdminApi.IContractEditPage=} [properties] Properties to set
         */
        function ContractEditPage(properties) {
            this.skills = [];
            this.gradeOptions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractEditPage id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractEditPage
         * @instance
         */
        ContractEditPage.prototype.id = "";

        /**
         * ContractEditPage wage.
         * @member {PlatformAdminApi.IWageRelation|null|undefined} wage
         * @memberof PlatformAdminApi.ContractEditPage
         * @instance
         */
        ContractEditPage.prototype.wage = null;

        /**
         * ContractEditPage grade.
         * @member {PlatformAdminApi.IGradeOption|null|undefined} grade
         * @memberof PlatformAdminApi.ContractEditPage
         * @instance
         */
        ContractEditPage.prototype.grade = null;

        /**
         * ContractEditPage contractorId.
         * @member {string} contractorId
         * @memberof PlatformAdminApi.ContractEditPage
         * @instance
         */
        ContractEditPage.prototype.contractorId = "";

        /**
         * ContractEditPage skills.
         * @member {Array.<PlatformAdminApi.ISkillSuggestsListItem>} skills
         * @memberof PlatformAdminApi.ContractEditPage
         * @instance
         */
        ContractEditPage.prototype.skills = $util.emptyArray;

        /**
         * ContractEditPage gradeOptions.
         * @member {Array.<PlatformAdminApi.IGradeOption>} gradeOptions
         * @memberof PlatformAdminApi.ContractEditPage
         * @instance
         */
        ContractEditPage.prototype.gradeOptions = $util.emptyArray;

        /**
         * Encodes the specified ContractEditPage message. Does not implicitly {@link PlatformAdminApi.ContractEditPage.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractEditPage
         * @static
         * @param {PlatformAdminApi.IContractEditPage} message ContractEditPage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractEditPage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                $root.PlatformAdminApi.WageRelation.encode(message.wage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                $root.PlatformAdminApi.GradeOption.encode(message.grade, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.contractorId);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.SkillSuggestsListItem.encode(message.skills[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.gradeOptions != null && message.gradeOptions.length)
                for (var i = 0; i < message.gradeOptions.length; ++i)
                    $root.PlatformAdminApi.GradeOption.encode(message.gradeOptions[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractEditPage message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractEditPage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractEditPage} ContractEditPage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractEditPage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractEditPage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.wage = $root.PlatformAdminApi.WageRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.grade = $root.PlatformAdminApi.GradeOption.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.contractorId = reader.string();
                    break;
                case 5:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.SkillSuggestsListItem.decode(reader, reader.uint32()));
                    break;
                case 6:
                    if (!(message.gradeOptions && message.gradeOptions.length))
                        message.gradeOptions = [];
                    message.gradeOptions.push($root.PlatformAdminApi.GradeOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractEditPage;
    })();

    PlatformAdminApi.ContractUpdateRequest = (function() {

        /**
         * Properties of a ContractUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractUpdateRequest
         * @property {string|null} [id] ContractUpdateRequest id
         * @property {PlatformAdminApi.IGradeRelation|null} [grade] ContractUpdateRequest grade
         * @property {PlatformAdminApi.IWageRelation|null} [wage] ContractUpdateRequest wage
         * @property {PlatformAdminApi.IRelation|null} [contractor] ContractUpdateRequest contractor
         * @property {Array.<PlatformAdminApi.IRelation>|null} [skills] ContractUpdateRequest skills
         */

        /**
         * Constructs a new ContractUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractUpdateRequest.
         * @implements IContractUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractUpdateRequest=} [properties] Properties to set
         */
        function ContractUpdateRequest(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractUpdateRequest
         * @instance
         */
        ContractUpdateRequest.prototype.id = "";

        /**
         * ContractUpdateRequest grade.
         * @member {PlatformAdminApi.IGradeRelation|null|undefined} grade
         * @memberof PlatformAdminApi.ContractUpdateRequest
         * @instance
         */
        ContractUpdateRequest.prototype.grade = null;

        /**
         * ContractUpdateRequest wage.
         * @member {PlatformAdminApi.IWageRelation|null|undefined} wage
         * @memberof PlatformAdminApi.ContractUpdateRequest
         * @instance
         */
        ContractUpdateRequest.prototype.wage = null;

        /**
         * ContractUpdateRequest contractor.
         * @member {PlatformAdminApi.IRelation|null|undefined} contractor
         * @memberof PlatformAdminApi.ContractUpdateRequest
         * @instance
         */
        ContractUpdateRequest.prototype.contractor = null;

        /**
         * ContractUpdateRequest skills.
         * @member {Array.<PlatformAdminApi.IRelation>} skills
         * @memberof PlatformAdminApi.ContractUpdateRequest
         * @instance
         */
        ContractUpdateRequest.prototype.skills = $util.emptyArray;

        /**
         * Encodes the specified ContractUpdateRequest message. Does not implicitly {@link PlatformAdminApi.ContractUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractUpdateRequest
         * @static
         * @param {PlatformAdminApi.IContractUpdateRequest} message ContractUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                $root.PlatformAdminApi.GradeRelation.encode(message.grade, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                $root.PlatformAdminApi.WageRelation.encode(message.wage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.contractor != null && Object.hasOwnProperty.call(message, "contractor"))
                $root.PlatformAdminApi.Relation.encode(message.contractor, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.Relation.encode(message.skills[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractUpdateRequest} ContractUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.grade = $root.PlatformAdminApi.GradeRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.wage = $root.PlatformAdminApi.WageRelation.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.contractor = $root.PlatformAdminApi.Relation.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.Relation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractUpdateRequest;
    })();

    PlatformAdminApi.ContractSearchRequest = (function() {

        /**
         * Properties of a ContractSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IContractSearchRequest
         * @property {number|null} [page] ContractSearchRequest page
         * @property {number|null} [limit] ContractSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] ContractSearchRequest sort
         * @property {Array.<PlatformAdminApi.ContractSearchRequest.IWhereFields>|null} [where] ContractSearchRequest where
         */

        /**
         * Constructs a new ContractSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractSearchRequest.
         * @implements IContractSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IContractSearchRequest=} [properties] Properties to set
         */
        function ContractSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractSearchRequest
         * @instance
         */
        ContractSearchRequest.prototype.page = 0;

        /**
         * ContractSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractSearchRequest
         * @instance
         */
        ContractSearchRequest.prototype.limit = 0;

        /**
         * ContractSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.ContractSearchRequest
         * @instance
         */
        ContractSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * ContractSearchRequest where.
         * @member {Array.<PlatformAdminApi.ContractSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.ContractSearchRequest
         * @instance
         */
        ContractSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified ContractSearchRequest message. Does not implicitly {@link PlatformAdminApi.ContractSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractSearchRequest
         * @static
         * @param {PlatformAdminApi.IContractSearchRequest} message ContractSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.ContractSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractSearchRequest} ContractSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.ContractSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ContractSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.ContractSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [status] WhereFields status
             * @property {PlatformAdminApi.IStringCondition|null} [productSlug] WhereFields productSlug
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IUuidCondition|null} [specializationId] WhereFields specializationId
             * @property {PlatformAdminApi.IUuidCondition|null} [gradeId] WhereFields gradeId
             * @property {PlatformAdminApi.IUuidCondition|null} [wageId] WhereFields wageId
             * @property {PlatformAdminApi.IUuidCondition|null} [contractorId] WhereFields contractorId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.ContractSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.ContractSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields status.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} status
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.status = null;

            /**
             * WhereFields productSlug.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} productSlug
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.productSlug = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields specializationId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} specializationId
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.specializationId = null;

            /**
             * WhereFields gradeId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} gradeId
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.gradeId = null;

            /**
             * WhereFields wageId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} wageId
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.wageId = null;

            /**
             * WhereFields contractorId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} contractorId
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.contractorId = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.ContractSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.ContractSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.PlatformAdminApi.StringCondition.encode(message.status, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                    $root.PlatformAdminApi.StringCondition.encode(message.productSlug, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.specializationId != null && Object.hasOwnProperty.call(message, "specializationId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.specializationId, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.gradeId != null && Object.hasOwnProperty.call(message, "gradeId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.gradeId, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.wageId != null && Object.hasOwnProperty.call(message, "wageId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.wageId, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.contractorId, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ContractSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ContractSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.status = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.productSlug = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 5:
                        message.specializationId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.gradeId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.wageId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.contractorId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return ContractSearchRequest;
    })();

    PlatformAdminApi.ContractsListItem = (function() {

        /**
         * Properties of a ContractsListItem.
         * @memberof PlatformAdminApi
         * @interface IContractsListItem
         * @property {string|null} [id] ContractsListItem id
         * @property {string|null} [status] ContractsListItem status
         * @property {string|null} [createdAt] ContractsListItem createdAt
         * @property {string|null} [product] ContractsListItem product
         * @property {string|null} [grade] ContractsListItem grade
         * @property {string|null} [specialization] ContractsListItem specialization
         * @property {string|null} [wage] ContractsListItem wage
         * @property {string|null} [contractorStatus] ContractsListItem contractorStatus
         * @property {string|null} [contractorFIO] ContractsListItem contractorFIO
         * @property {string|null} [contractorId] ContractsListItem contractorId
         */

        /**
         * Constructs a new ContractsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractsListItem.
         * @implements IContractsListItem
         * @constructor
         * @param {PlatformAdminApi.IContractsListItem=} [properties] Properties to set
         */
        function ContractsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.id = "";

        /**
         * ContractsListItem status.
         * @member {string} status
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.status = "";

        /**
         * ContractsListItem createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.createdAt = "";

        /**
         * ContractsListItem product.
         * @member {string} product
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.product = "";

        /**
         * ContractsListItem grade.
         * @member {string} grade
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.grade = "";

        /**
         * ContractsListItem specialization.
         * @member {string} specialization
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.specialization = "";

        /**
         * ContractsListItem wage.
         * @member {string} wage
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.wage = "";

        /**
         * ContractsListItem contractorStatus.
         * @member {string} contractorStatus
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.contractorStatus = "";

        /**
         * ContractsListItem contractorFIO.
         * @member {string} contractorFIO
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.contractorFIO = "";

        /**
         * ContractsListItem contractorId.
         * @member {string} contractorId
         * @memberof PlatformAdminApi.ContractsListItem
         * @instance
         */
        ContractsListItem.prototype.contractorId = "";

        /**
         * Encodes the specified ContractsListItem message. Does not implicitly {@link PlatformAdminApi.ContractsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractsListItem
         * @static
         * @param {PlatformAdminApi.IContractsListItem} message ContractsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.createdAt);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.product);
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.grade);
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.specialization);
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.wage);
            if (message.contractorStatus != null && Object.hasOwnProperty.call(message, "contractorStatus"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.contractorStatus);
            if (message.contractorFIO != null && Object.hasOwnProperty.call(message, "contractorFIO"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.contractorFIO);
            if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.contractorId);
            return writer;
        };

        /**
         * Decodes a ContractsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractsListItem} ContractsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                case 3:
                    message.createdAt = reader.string();
                    break;
                case 4:
                    message.product = reader.string();
                    break;
                case 5:
                    message.grade = reader.string();
                    break;
                case 6:
                    message.specialization = reader.string();
                    break;
                case 7:
                    message.wage = reader.string();
                    break;
                case 8:
                    message.contractorStatus = reader.string();
                    break;
                case 9:
                    message.contractorFIO = reader.string();
                    break;
                case 10:
                    message.contractorId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractsListItem;
    })();

    PlatformAdminApi.ContractsList = (function() {

        /**
         * Properties of a ContractsList.
         * @memberof PlatformAdminApi
         * @interface IContractsList
         * @property {Array.<PlatformAdminApi.IContractsListItem>|null} [data] ContractsList data
         * @property {number|null} [page] ContractsList page
         * @property {number|null} [pageCount] ContractsList pageCount
         * @property {number|null} [limit] ContractsList limit
         * @property {number|null} [count] ContractsList count
         * @property {number|null} [total] ContractsList total
         */

        /**
         * Constructs a new ContractsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractsList.
         * @implements IContractsList
         * @constructor
         * @param {PlatformAdminApi.IContractsList=} [properties] Properties to set
         */
        function ContractsList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractsList data.
         * @member {Array.<PlatformAdminApi.IContractsListItem>} data
         * @memberof PlatformAdminApi.ContractsList
         * @instance
         */
        ContractsList.prototype.data = $util.emptyArray;

        /**
         * ContractsList page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractsList
         * @instance
         */
        ContractsList.prototype.page = 0;

        /**
         * ContractsList pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.ContractsList
         * @instance
         */
        ContractsList.prototype.pageCount = 0;

        /**
         * ContractsList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractsList
         * @instance
         */
        ContractsList.prototype.limit = 0;

        /**
         * ContractsList count.
         * @member {number} count
         * @memberof PlatformAdminApi.ContractsList
         * @instance
         */
        ContractsList.prototype.count = 0;

        /**
         * ContractsList total.
         * @member {number} total
         * @memberof PlatformAdminApi.ContractsList
         * @instance
         */
        ContractsList.prototype.total = 0;

        /**
         * Encodes the specified ContractsList message. Does not implicitly {@link PlatformAdminApi.ContractsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractsList
         * @static
         * @param {PlatformAdminApi.IContractsList} message ContractsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.ContractsListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ContractsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractsList} ContractsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.ContractsListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractsList;
    })();

    PlatformAdminApi.ContractSearchResponse = (function() {

        /**
         * Properties of a ContractSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IContractSearchResponse
         * @property {Array.<PlatformAdminApi.IContract>|null} [data] ContractSearchResponse data
         * @property {number|null} [page] ContractSearchResponse page
         * @property {number|null} [pageCount] ContractSearchResponse pageCount
         * @property {number|null} [limit] ContractSearchResponse limit
         * @property {number|null} [count] ContractSearchResponse count
         * @property {number|null} [total] ContractSearchResponse total
         */

        /**
         * Constructs a new ContractSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractSearchResponse.
         * @implements IContractSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IContractSearchResponse=} [properties] Properties to set
         */
        function ContractSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractSearchResponse data.
         * @member {Array.<PlatformAdminApi.IContract>} data
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @instance
         */
        ContractSearchResponse.prototype.data = $util.emptyArray;

        /**
         * ContractSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @instance
         */
        ContractSearchResponse.prototype.page = 0;

        /**
         * ContractSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @instance
         */
        ContractSearchResponse.prototype.pageCount = 0;

        /**
         * ContractSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @instance
         */
        ContractSearchResponse.prototype.limit = 0;

        /**
         * ContractSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @instance
         */
        ContractSearchResponse.prototype.count = 0;

        /**
         * ContractSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @instance
         */
        ContractSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified ContractSearchResponse message. Does not implicitly {@link PlatformAdminApi.ContractSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @static
         * @param {PlatformAdminApi.IContractSearchResponse} message ContractSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Contract.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ContractSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractSearchResponse} ContractSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Contract.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractSearchResponse;
    })();

    PlatformAdminApi.ContractSkillView = (function() {

        /**
         * Properties of a ContractSkillView.
         * @memberof PlatformAdminApi
         * @interface IContractSkillView
         * @property {string|null} [id] ContractSkillView id
         * @property {string|null} [name] ContractSkillView name
         * @property {string|null} [title] ContractSkillView title
         */

        /**
         * Constructs a new ContractSkillView.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractSkillView.
         * @implements IContractSkillView
         * @constructor
         * @param {PlatformAdminApi.IContractSkillView=} [properties] Properties to set
         */
        function ContractSkillView(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractSkillView id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractSkillView
         * @instance
         */
        ContractSkillView.prototype.id = "";

        /**
         * ContractSkillView name.
         * @member {string} name
         * @memberof PlatformAdminApi.ContractSkillView
         * @instance
         */
        ContractSkillView.prototype.name = "";

        /**
         * ContractSkillView title.
         * @member {string} title
         * @memberof PlatformAdminApi.ContractSkillView
         * @instance
         */
        ContractSkillView.prototype.title = "";

        /**
         * Encodes the specified ContractSkillView message. Does not implicitly {@link PlatformAdminApi.ContractSkillView.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractSkillView
         * @static
         * @param {PlatformAdminApi.IContractSkillView} message ContractSkillView message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractSkillView.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            return writer;
        };

        /**
         * Decodes a ContractSkillView message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractSkillView
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractSkillView} ContractSkillView
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractSkillView.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractSkillView();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractSkillView;
    })();

    PlatformAdminApi.ContractView = (function() {

        /**
         * Properties of a ContractView.
         * @memberof PlatformAdminApi
         * @interface IContractView
         * @property {string|null} [id] ContractView id
         * @property {string|null} [status] ContractView status
         * @property {string|null} [blockingReason] ContractView blockingReason
         * @property {boolean|null} [blockingReasonIsRecoverable] ContractView blockingReasonIsRecoverable
         * @property {string|null} [blockedAt] ContractView blockedAt
         * @property {string|null} [contractorStatus] ContractView contractorStatus
         * @property {string|null} [contractorRating] ContractView contractorRating
         * @property {string|null} [product] ContractView product
         * @property {string|null} [specialization] ContractView specialization
         * @property {string|null} [grade] ContractView grade
         * @property {string|null} [wage] ContractView wage
         * @property {string|null} [createdAt] ContractView createdAt
         * @property {string|null} [updatedAt] ContractView updatedAt
         * @property {string|null} [deletedAt] ContractView deletedAt
         * @property {Array.<PlatformAdminApi.IContractSkillView>|null} [skills] ContractView skills
         * @property {boolean|null} [isDeleted] ContractView isDeleted
         */

        /**
         * Constructs a new ContractView.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractView.
         * @implements IContractView
         * @constructor
         * @param {PlatformAdminApi.IContractView=} [properties] Properties to set
         */
        function ContractView(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractView id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.id = "";

        /**
         * ContractView status.
         * @member {string} status
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.status = "";

        /**
         * ContractView blockingReason.
         * @member {string} blockingReason
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.blockingReason = "";

        /**
         * ContractView blockingReasonIsRecoverable.
         * @member {boolean} blockingReasonIsRecoverable
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.blockingReasonIsRecoverable = false;

        /**
         * ContractView blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.blockedAt = "";

        /**
         * ContractView contractorStatus.
         * @member {string} contractorStatus
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.contractorStatus = "";

        /**
         * ContractView contractorRating.
         * @member {string} contractorRating
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.contractorRating = "";

        /**
         * ContractView product.
         * @member {string} product
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.product = "";

        /**
         * ContractView specialization.
         * @member {string} specialization
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.specialization = "";

        /**
         * ContractView grade.
         * @member {string} grade
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.grade = "";

        /**
         * ContractView wage.
         * @member {string} wage
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.wage = "";

        /**
         * ContractView createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.createdAt = "";

        /**
         * ContractView updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.updatedAt = "";

        /**
         * ContractView deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.deletedAt = "";

        /**
         * ContractView skills.
         * @member {Array.<PlatformAdminApi.IContractSkillView>} skills
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.skills = $util.emptyArray;

        /**
         * ContractView isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ContractView
         * @instance
         */
        ContractView.prototype.isDeleted = false;

        /**
         * Encodes the specified ContractView message. Does not implicitly {@link PlatformAdminApi.ContractView.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractView
         * @static
         * @param {PlatformAdminApi.IContractView} message ContractView message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractView.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.blockingReason != null && Object.hasOwnProperty.call(message, "blockingReason"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.blockingReason);
            if (message.blockingReasonIsRecoverable != null && Object.hasOwnProperty.call(message, "blockingReasonIsRecoverable"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.blockingReasonIsRecoverable);
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.blockedAt);
            if (message.contractorStatus != null && Object.hasOwnProperty.call(message, "contractorStatus"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.contractorStatus);
            if (message.contractorRating != null && Object.hasOwnProperty.call(message, "contractorRating"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.contractorRating);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.product);
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.specialization);
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.grade);
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.wage);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.deletedAt);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.ContractSkillView.encode(message.skills[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a ContractView message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractView
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractView} ContractView
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractView.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractView();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                case 3:
                    message.blockingReason = reader.string();
                    break;
                case 4:
                    message.blockingReasonIsRecoverable = reader.bool();
                    break;
                case 5:
                    message.blockedAt = reader.string();
                    break;
                case 6:
                    message.contractorStatus = reader.string();
                    break;
                case 7:
                    message.contractorRating = reader.string();
                    break;
                case 8:
                    message.product = reader.string();
                    break;
                case 9:
                    message.specialization = reader.string();
                    break;
                case 10:
                    message.grade = reader.string();
                    break;
                case 11:
                    message.wage = reader.string();
                    break;
                case 12:
                    message.createdAt = reader.string();
                    break;
                case 13:
                    message.updatedAt = reader.string();
                    break;
                case 14:
                    message.deletedAt = reader.string();
                    break;
                case 15:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.ContractSkillView.decode(reader, reader.uint32()));
                    break;
                case 16:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractView;
    })();

    PlatformAdminApi.ContractBlockRequest = (function() {

        /**
         * Properties of a ContractBlockRequest.
         * @memberof PlatformAdminApi
         * @interface IContractBlockRequest
         * @property {string|null} [id] ContractBlockRequest id
         * @property {PlatformAdminApi.IBlockingReasonRelation|null} [reason] ContractBlockRequest reason
         * @property {string|null} [startBlockDate] ContractBlockRequest startBlockDate
         * @property {string|null} [endBlockDate] ContractBlockRequest endBlockDate
         * @property {string|null} [changerId] ContractBlockRequest changerId
         */

        /**
         * Constructs a new ContractBlockRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractBlockRequest.
         * @implements IContractBlockRequest
         * @constructor
         * @param {PlatformAdminApi.IContractBlockRequest=} [properties] Properties to set
         */
        function ContractBlockRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractBlockRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractBlockRequest
         * @instance
         */
        ContractBlockRequest.prototype.id = "";

        /**
         * ContractBlockRequest reason.
         * @member {PlatformAdminApi.IBlockingReasonRelation|null|undefined} reason
         * @memberof PlatformAdminApi.ContractBlockRequest
         * @instance
         */
        ContractBlockRequest.prototype.reason = null;

        /**
         * ContractBlockRequest startBlockDate.
         * @member {string} startBlockDate
         * @memberof PlatformAdminApi.ContractBlockRequest
         * @instance
         */
        ContractBlockRequest.prototype.startBlockDate = "";

        /**
         * ContractBlockRequest endBlockDate.
         * @member {string} endBlockDate
         * @memberof PlatformAdminApi.ContractBlockRequest
         * @instance
         */
        ContractBlockRequest.prototype.endBlockDate = "";

        /**
         * ContractBlockRequest changerId.
         * @member {string} changerId
         * @memberof PlatformAdminApi.ContractBlockRequest
         * @instance
         */
        ContractBlockRequest.prototype.changerId = "";

        /**
         * Encodes the specified ContractBlockRequest message. Does not implicitly {@link PlatformAdminApi.ContractBlockRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractBlockRequest
         * @static
         * @param {PlatformAdminApi.IContractBlockRequest} message ContractBlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractBlockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                $root.PlatformAdminApi.BlockingReasonRelation.encode(message.reason, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.startBlockDate != null && Object.hasOwnProperty.call(message, "startBlockDate"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startBlockDate);
            if (message.endBlockDate != null && Object.hasOwnProperty.call(message, "endBlockDate"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.endBlockDate);
            if (message.changerId != null && Object.hasOwnProperty.call(message, "changerId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.changerId);
            return writer;
        };

        /**
         * Decodes a ContractBlockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractBlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractBlockRequest} ContractBlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractBlockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractBlockRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.reason = $root.PlatformAdminApi.BlockingReasonRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.startBlockDate = reader.string();
                    break;
                case 4:
                    message.endBlockDate = reader.string();
                    break;
                case 5:
                    message.changerId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractBlockRequest;
    })();

    PlatformAdminApi.BlockContractReason = (function() {

        /**
         * Properties of a BlockContractReason.
         * @memberof PlatformAdminApi
         * @interface IBlockContractReason
         * @property {string|null} [id] BlockContractReason id
         */

        /**
         * Constructs a new BlockContractReason.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockContractReason.
         * @implements IBlockContractReason
         * @constructor
         * @param {PlatformAdminApi.IBlockContractReason=} [properties] Properties to set
         */
        function BlockContractReason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockContractReason id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockContractReason
         * @instance
         */
        BlockContractReason.prototype.id = "";

        /**
         * Encodes the specified BlockContractReason message. Does not implicitly {@link PlatformAdminApi.BlockContractReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockContractReason
         * @static
         * @param {PlatformAdminApi.IBlockContractReason} message BlockContractReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockContractReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a BlockContractReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockContractReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockContractReason} BlockContractReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockContractReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockContractReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockContractReason;
    })();

    PlatformAdminApi.ContractorService = (function() {

        /**
         * Constructs a new ContractorService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ContractorService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ContractorService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ContractorService;

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#create}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorResponse} [response] ContractorResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorCreateRequest} request ContractorCreateRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.CreateCallback} callback Node-style callback called with the error, if any, and ContractorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.ContractorCreateRequest, $root.PlatformAdminApi.ContractorResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorCreateRequest} request ContractorCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#update}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorResponse} [response] ContractorResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorUpdateRequest} request ContractorUpdateRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.UpdateCallback} callback Node-style callback called with the error, if any, and ContractorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.ContractorUpdateRequest, $root.PlatformAdminApi.ContractorResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorUpdateRequest} request ContractorUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#search}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorSearchResponse} [response] ContractorSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorSearchRequest} request ContractorSearchRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.SearchCallback} callback Node-style callback called with the error, if any, and ContractorSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.ContractorSearchRequest, $root.PlatformAdminApi.ContractorSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorSearchRequest} request ContractorSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#findById}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorResponse} [response] ContractorResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.FindByIdCallback} callback Node-style callback called with the error, if any, and ContractorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#remove}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorResponse} [response] ContractorResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.RemoveCallback} callback Node-style callback called with the error, if any, and ContractorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#restore}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorResponse} [response] ContractorResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.RestoreCallback} callback Node-style callback called with the error, if any, and ContractorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#block}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef BlockCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorResponse} [response] ContractorResponse
         */

        /**
         * Calls Block.
         * @function block
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorBlockRequest} request ContractorBlockRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.BlockCallback} callback Node-style callback called with the error, if any, and ContractorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.block = function block(request, callback) {
            return this.rpcCall(block, $root.PlatformAdminApi.ContractorBlockRequest, $root.PlatformAdminApi.ContractorResponse, request, callback);
        }, "name", { value: "Block" });

        /**
         * Calls Block.
         * @function block
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorBlockRequest} request ContractorBlockRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#activate}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef ActivateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorResponse} [response] ContractorResponse
         */

        /**
         * Calls Activate.
         * @function activate
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorActivateRequest} request ContractorActivateRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.ActivateCallback} callback Node-style callback called with the error, if any, and ContractorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.activate = function activate(request, callback) {
            return this.rpcCall(activate, $root.PlatformAdminApi.ContractorActivateRequest, $root.PlatformAdminApi.ContractorResponse, request, callback);
        }, "name", { value: "Activate" });

        /**
         * Calls Activate.
         * @function activate
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IContractorActivateRequest} request ContractorActivateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorService#addTask}.
         * @memberof PlatformAdminApi.ContractorService
         * @typedef AddTaskCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.HrmContractorAddTaskResponse} [response] HrmContractorAddTaskResponse
         */

        /**
         * Calls AddTask.
         * @function addTask
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IHrmContractorAddTaskRequest} request HrmContractorAddTaskRequest message or plain object
         * @param {PlatformAdminApi.ContractorService.AddTaskCallback} callback Node-style callback called with the error, if any, and HrmContractorAddTaskResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorService.prototype.addTask = function addTask(request, callback) {
            return this.rpcCall(addTask, $root.PlatformAdminApi.HrmContractorAddTaskRequest, $root.PlatformAdminApi.HrmContractorAddTaskResponse, request, callback);
        }, "name", { value: "AddTask" });

        /**
         * Calls AddTask.
         * @function addTask
         * @memberof PlatformAdminApi.ContractorService
         * @instance
         * @param {PlatformAdminApi.IHrmContractorAddTaskRequest} request HrmContractorAddTaskRequest message or plain object
         * @returns {Promise<PlatformAdminApi.HrmContractorAddTaskResponse>} Promise
         * @variation 2
         */

        return ContractorService;
    })();

    PlatformAdminApi.Contractor = (function() {

        /**
         * Properties of a Contractor.
         * @memberof PlatformAdminApi
         * @interface IContractor
         * @property {string|null} [id] Contractor id
         * @property {string|null} [userId] Contractor userId
         * @property {string|null} [status] Contractor status
         * @property {string|null} [workStatus] Contractor workStatus
         * @property {number|null} [rating] Contractor rating
         * @property {Array.<PlatformAdminApi.IContractRelation>|null} [contracts] Contractor contracts
         * @property {string|null} [regionId] Contractor regionId
         * @property {string|null} [createdAt] Contractor createdAt
         * @property {string|null} [updatedAt] Contractor updatedAt
         * @property {boolean|null} [isDeleted] Contractor isDeleted
         * @property {string|null} [deletedAt] Contractor deletedAt
         * @property {PlatformAdminApi.IContractorProfile|null} [profile] Contractor profile
         * @property {string|null} [blockedAt] Contractor blockedAt
         * @property {PlatformAdminApi.IBlockingReason|null} [blockingReason] Contractor blockingReason
         * @property {PlatformAdminApi.Contractor.IRegionRel|null} [region] Contractor region
         * @property {PlatformAdminApi.IFio|null} [fio] Contractor fio
         * @property {PlatformAdminApi.ICovidCertificate|null} [covidCertificate] Contractor covidCertificate
         * @property {PlatformAdminApi.IUser|null} [user] Contractor user
         * @property {PlatformAdminApi.ITenant|null} [tenant] Contractor tenant
         */

        /**
         * Constructs a new Contractor.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Contractor.
         * @implements IContractor
         * @constructor
         * @param {PlatformAdminApi.IContractor=} [properties] Properties to set
         */
        function Contractor(properties) {
            this.contracts = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Contractor id.
         * @member {string} id
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.id = "";

        /**
         * Contractor userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.userId = "";

        /**
         * Contractor status.
         * @member {string} status
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.status = "";

        /**
         * Contractor workStatus.
         * @member {string} workStatus
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.workStatus = "";

        /**
         * Contractor rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.rating = 0;

        /**
         * Contractor contracts.
         * @member {Array.<PlatformAdminApi.IContractRelation>} contracts
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.contracts = $util.emptyArray;

        /**
         * Contractor regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.regionId = "";

        /**
         * Contractor createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.createdAt = "";

        /**
         * Contractor updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.updatedAt = "";

        /**
         * Contractor isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.isDeleted = false;

        /**
         * Contractor deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.deletedAt = "";

        /**
         * Contractor profile.
         * @member {PlatformAdminApi.IContractorProfile|null|undefined} profile
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.profile = null;

        /**
         * Contractor blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.blockedAt = "";

        /**
         * Contractor blockingReason.
         * @member {PlatformAdminApi.IBlockingReason|null|undefined} blockingReason
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.blockingReason = null;

        /**
         * Contractor region.
         * @member {PlatformAdminApi.Contractor.IRegionRel|null|undefined} region
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.region = null;

        /**
         * Contractor fio.
         * @member {PlatformAdminApi.IFio|null|undefined} fio
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.fio = null;

        /**
         * Contractor covidCertificate.
         * @member {PlatformAdminApi.ICovidCertificate|null|undefined} covidCertificate
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.covidCertificate = null;

        /**
         * Contractor user.
         * @member {PlatformAdminApi.IUser|null|undefined} user
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.user = null;

        /**
         * Contractor tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.Contractor
         * @instance
         */
        Contractor.prototype.tenant = null;

        /**
         * Encodes the specified Contractor message. Does not implicitly {@link PlatformAdminApi.Contractor.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Contractor
         * @static
         * @param {PlatformAdminApi.IContractor} message Contractor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Contractor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.workStatus != null && Object.hasOwnProperty.call(message, "workStatus"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.workStatus);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.rating);
            if (message.contracts != null && message.contracts.length)
                for (var i = 0; i < message.contracts.length; ++i)
                    $root.PlatformAdminApi.ContractRelation.encode(message.contracts[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.regionId);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.deletedAt);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.PlatformAdminApi.ContractorProfile.encode(message.profile, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.blockedAt);
            if (message.blockingReason != null && Object.hasOwnProperty.call(message, "blockingReason"))
                $root.PlatformAdminApi.BlockingReason.encode(message.blockingReason, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                $root.PlatformAdminApi.Contractor.RegionRel.encode(message.region, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformAdminApi.Fio.encode(message.fio, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.covidCertificate != null && Object.hasOwnProperty.call(message, "covidCertificate"))
                $root.PlatformAdminApi.CovidCertificate.encode(message.covidCertificate, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.PlatformAdminApi.User.encode(message.user, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Contractor message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Contractor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Contractor} Contractor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Contractor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Contractor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.workStatus = reader.string();
                    break;
                case 5:
                    message.rating = reader.double();
                    break;
                case 6:
                    if (!(message.contracts && message.contracts.length))
                        message.contracts = [];
                    message.contracts.push($root.PlatformAdminApi.ContractRelation.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.regionId = reader.string();
                    break;
                case 8:
                    message.createdAt = reader.string();
                    break;
                case 9:
                    message.updatedAt = reader.string();
                    break;
                case 10:
                    message.isDeleted = reader.bool();
                    break;
                case 11:
                    message.deletedAt = reader.string();
                    break;
                case 12:
                    message.profile = $root.PlatformAdminApi.ContractorProfile.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.blockedAt = reader.string();
                    break;
                case 14:
                    message.blockingReason = $root.PlatformAdminApi.BlockingReason.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.region = $root.PlatformAdminApi.Contractor.RegionRel.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.fio = $root.PlatformAdminApi.Fio.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.covidCertificate = $root.PlatformAdminApi.CovidCertificate.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.user = $root.PlatformAdminApi.User.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Contractor.RegionRel = (function() {

            /**
             * Properties of a RegionRel.
             * @memberof PlatformAdminApi.Contractor
             * @interface IRegionRel
             * @property {string|null} [id] RegionRel id
             * @property {string|null} [name] RegionRel name
             */

            /**
             * Constructs a new RegionRel.
             * @memberof PlatformAdminApi.Contractor
             * @classdesc Represents a RegionRel.
             * @implements IRegionRel
             * @constructor
             * @param {PlatformAdminApi.Contractor.IRegionRel=} [properties] Properties to set
             */
            function RegionRel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RegionRel id.
             * @member {string} id
             * @memberof PlatformAdminApi.Contractor.RegionRel
             * @instance
             */
            RegionRel.prototype.id = "";

            /**
             * RegionRel name.
             * @member {string} name
             * @memberof PlatformAdminApi.Contractor.RegionRel
             * @instance
             */
            RegionRel.prototype.name = "";

            /**
             * Encodes the specified RegionRel message. Does not implicitly {@link PlatformAdminApi.Contractor.RegionRel.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.Contractor.RegionRel
             * @static
             * @param {PlatformAdminApi.Contractor.IRegionRel} message RegionRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegionRel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes a RegionRel message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.Contractor.RegionRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.Contractor.RegionRel} RegionRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegionRel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Contractor.RegionRel();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return RegionRel;
        })();

        return Contractor;
    })();

    PlatformAdminApi.ContractRelation = (function() {

        /**
         * Properties of a ContractRelation.
         * @memberof PlatformAdminApi
         * @interface IContractRelation
         * @property {string|null} [id] ContractRelation id
         * @property {string|null} [status] ContractRelation status
         * @property {string|null} [productSlug] ContractRelation productSlug
         * @property {PlatformAdminApi.ISpecializationRelation|null} [specialization] ContractRelation specialization
         * @property {PlatformAdminApi.IGrade|null} [grade] ContractRelation grade
         * @property {PlatformAdminApi.IWageRelation|null} [wage] ContractRelation wage
         * @property {Array.<PlatformAdminApi.ISkillRelation>|null} [skills] ContractRelation skills
         * @property {string|null} [blockedAt] ContractRelation blockedAt
         * @property {PlatformAdminApi.IBlockingReason|null} [blockingReason] ContractRelation blockingReason
         * @property {PlatformAdminApi.IProduct|null} [product] ContractRelation product
         */

        /**
         * Constructs a new ContractRelation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractRelation.
         * @implements IContractRelation
         * @constructor
         * @param {PlatformAdminApi.IContractRelation=} [properties] Properties to set
         */
        function ContractRelation(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractRelation id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.id = "";

        /**
         * ContractRelation status.
         * @member {string} status
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.status = "";

        /**
         * ContractRelation productSlug.
         * @member {string} productSlug
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.productSlug = "";

        /**
         * ContractRelation specialization.
         * @member {PlatformAdminApi.ISpecializationRelation|null|undefined} specialization
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.specialization = null;

        /**
         * ContractRelation grade.
         * @member {PlatformAdminApi.IGrade|null|undefined} grade
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.grade = null;

        /**
         * ContractRelation wage.
         * @member {PlatformAdminApi.IWageRelation|null|undefined} wage
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.wage = null;

        /**
         * ContractRelation skills.
         * @member {Array.<PlatformAdminApi.ISkillRelation>} skills
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.skills = $util.emptyArray;

        /**
         * ContractRelation blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.blockedAt = "";

        /**
         * ContractRelation blockingReason.
         * @member {PlatformAdminApi.IBlockingReason|null|undefined} blockingReason
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.blockingReason = null;

        /**
         * ContractRelation product.
         * @member {PlatformAdminApi.IProduct|null|undefined} product
         * @memberof PlatformAdminApi.ContractRelation
         * @instance
         */
        ContractRelation.prototype.product = null;

        /**
         * Encodes the specified ContractRelation message. Does not implicitly {@link PlatformAdminApi.ContractRelation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractRelation
         * @static
         * @param {PlatformAdminApi.IContractRelation} message ContractRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.productSlug);
            if (message.specialization != null && Object.hasOwnProperty.call(message, "specialization"))
                $root.PlatformAdminApi.SpecializationRelation.encode(message.specialization, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                $root.PlatformAdminApi.Grade.encode(message.grade, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                $root.PlatformAdminApi.WageRelation.encode(message.wage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.SkillRelation.encode(message.skills[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.blockedAt);
            if (message.blockingReason != null && Object.hasOwnProperty.call(message, "blockingReason"))
                $root.PlatformAdminApi.BlockingReason.encode(message.blockingReason, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.PlatformAdminApi.Product.encode(message.product, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractRelation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractRelation} ContractRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                case 3:
                    message.productSlug = reader.string();
                    break;
                case 4:
                    message.specialization = $root.PlatformAdminApi.SpecializationRelation.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.grade = $root.PlatformAdminApi.Grade.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.wage = $root.PlatformAdminApi.WageRelation.decode(reader, reader.uint32());
                    break;
                case 7:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.SkillRelation.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.blockedAt = reader.string();
                    break;
                case 9:
                    message.blockingReason = $root.PlatformAdminApi.BlockingReason.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.product = $root.PlatformAdminApi.Product.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractRelation;
    })();

    PlatformAdminApi.ContractorBlockRequest = (function() {

        /**
         * Properties of a ContractorBlockRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorBlockRequest
         * @property {string|null} [id] ContractorBlockRequest id
         * @property {PlatformAdminApi.IBlockingReasonRelation|null} [reason] ContractorBlockRequest reason
         * @property {string|null} [startBlockDate] ContractorBlockRequest startBlockDate
         * @property {string|null} [endBlockDate] ContractorBlockRequest endBlockDate
         * @property {string|null} [changerId] ContractorBlockRequest changerId
         */

        /**
         * Constructs a new ContractorBlockRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorBlockRequest.
         * @implements IContractorBlockRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorBlockRequest=} [properties] Properties to set
         */
        function ContractorBlockRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorBlockRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorBlockRequest
         * @instance
         */
        ContractorBlockRequest.prototype.id = "";

        /**
         * ContractorBlockRequest reason.
         * @member {PlatformAdminApi.IBlockingReasonRelation|null|undefined} reason
         * @memberof PlatformAdminApi.ContractorBlockRequest
         * @instance
         */
        ContractorBlockRequest.prototype.reason = null;

        /**
         * ContractorBlockRequest startBlockDate.
         * @member {string} startBlockDate
         * @memberof PlatformAdminApi.ContractorBlockRequest
         * @instance
         */
        ContractorBlockRequest.prototype.startBlockDate = "";

        /**
         * ContractorBlockRequest endBlockDate.
         * @member {string} endBlockDate
         * @memberof PlatformAdminApi.ContractorBlockRequest
         * @instance
         */
        ContractorBlockRequest.prototype.endBlockDate = "";

        /**
         * ContractorBlockRequest changerId.
         * @member {string} changerId
         * @memberof PlatformAdminApi.ContractorBlockRequest
         * @instance
         */
        ContractorBlockRequest.prototype.changerId = "";

        /**
         * Encodes the specified ContractorBlockRequest message. Does not implicitly {@link PlatformAdminApi.ContractorBlockRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorBlockRequest
         * @static
         * @param {PlatformAdminApi.IContractorBlockRequest} message ContractorBlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorBlockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                $root.PlatformAdminApi.BlockingReasonRelation.encode(message.reason, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.startBlockDate != null && Object.hasOwnProperty.call(message, "startBlockDate"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startBlockDate);
            if (message.endBlockDate != null && Object.hasOwnProperty.call(message, "endBlockDate"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.endBlockDate);
            if (message.changerId != null && Object.hasOwnProperty.call(message, "changerId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.changerId);
            return writer;
        };

        /**
         * Decodes a ContractorBlockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorBlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorBlockRequest} ContractorBlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorBlockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorBlockRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.reason = $root.PlatformAdminApi.BlockingReasonRelation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.startBlockDate = reader.string();
                    break;
                case 4:
                    message.endBlockDate = reader.string();
                    break;
                case 5:
                    message.changerId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorBlockRequest;
    })();

    PlatformAdminApi.ContractorActivateRequest = (function() {

        /**
         * Properties of a ContractorActivateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorActivateRequest
         * @property {string|null} [id] ContractorActivateRequest id
         * @property {string|null} [changerId] ContractorActivateRequest changerId
         */

        /**
         * Constructs a new ContractorActivateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorActivateRequest.
         * @implements IContractorActivateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorActivateRequest=} [properties] Properties to set
         */
        function ContractorActivateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorActivateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorActivateRequest
         * @instance
         */
        ContractorActivateRequest.prototype.id = "";

        /**
         * ContractorActivateRequest changerId.
         * @member {string} changerId
         * @memberof PlatformAdminApi.ContractorActivateRequest
         * @instance
         */
        ContractorActivateRequest.prototype.changerId = "";

        /**
         * Encodes the specified ContractorActivateRequest message. Does not implicitly {@link PlatformAdminApi.ContractorActivateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorActivateRequest
         * @static
         * @param {PlatformAdminApi.IContractorActivateRequest} message ContractorActivateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorActivateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.changerId != null && Object.hasOwnProperty.call(message, "changerId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.changerId);
            return writer;
        };

        /**
         * Decodes a ContractorActivateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorActivateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorActivateRequest} ContractorActivateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorActivateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorActivateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.changerId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorActivateRequest;
    })();

    PlatformAdminApi.ContractorCreateRequest = (function() {

        /**
         * Properties of a ContractorCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorCreateRequest
         * @property {string|null} [userId] ContractorCreateRequest userId
         * @property {string|null} [status] ContractorCreateRequest status
         * @property {string|null} [workStatus] ContractorCreateRequest workStatus
         * @property {number|null} [rating] ContractorCreateRequest rating
         * @property {string|null} [regionId] ContractorCreateRequest regionId
         * @property {PlatformAdminApi.IProfileCreateRequest|null} [profile] ContractorCreateRequest profile
         * @property {PlatformAdminApi.ICovidCertificate|null} [covidCertificate] ContractorCreateRequest covidCertificate
         */

        /**
         * Constructs a new ContractorCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorCreateRequest.
         * @implements IContractorCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorCreateRequest=} [properties] Properties to set
         */
        function ContractorCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorCreateRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @instance
         */
        ContractorCreateRequest.prototype.userId = "";

        /**
         * ContractorCreateRequest status.
         * @member {string} status
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @instance
         */
        ContractorCreateRequest.prototype.status = "";

        /**
         * ContractorCreateRequest workStatus.
         * @member {string} workStatus
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @instance
         */
        ContractorCreateRequest.prototype.workStatus = "";

        /**
         * ContractorCreateRequest rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @instance
         */
        ContractorCreateRequest.prototype.rating = 0;

        /**
         * ContractorCreateRequest regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @instance
         */
        ContractorCreateRequest.prototype.regionId = "";

        /**
         * ContractorCreateRequest profile.
         * @member {PlatformAdminApi.IProfileCreateRequest|null|undefined} profile
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @instance
         */
        ContractorCreateRequest.prototype.profile = null;

        /**
         * ContractorCreateRequest covidCertificate.
         * @member {PlatformAdminApi.ICovidCertificate|null|undefined} covidCertificate
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @instance
         */
        ContractorCreateRequest.prototype.covidCertificate = null;

        /**
         * Encodes the specified ContractorCreateRequest message. Does not implicitly {@link PlatformAdminApi.ContractorCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @static
         * @param {PlatformAdminApi.IContractorCreateRequest} message ContractorCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.workStatus != null && Object.hasOwnProperty.call(message, "workStatus"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.workStatus);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.rating);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.regionId);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.PlatformAdminApi.ProfileCreateRequest.encode(message.profile, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.covidCertificate != null && Object.hasOwnProperty.call(message, "covidCertificate"))
                $root.PlatformAdminApi.CovidCertificate.encode(message.covidCertificate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorCreateRequest} ContractorCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                case 3:
                    message.workStatus = reader.string();
                    break;
                case 4:
                    message.rating = reader.double();
                    break;
                case 5:
                    message.regionId = reader.string();
                    break;
                case 6:
                    message.profile = $root.PlatformAdminApi.ProfileCreateRequest.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.covidCertificate = $root.PlatformAdminApi.CovidCertificate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorCreateRequest;
    })();

    PlatformAdminApi.ContractorUpdateRequest = (function() {

        /**
         * Properties of a ContractorUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorUpdateRequest
         * @property {string|null} [id] ContractorUpdateRequest id
         * @property {string|null} [userId] ContractorUpdateRequest userId
         * @property {string|null} [status] ContractorUpdateRequest status
         * @property {string|null} [workStatus] ContractorUpdateRequest workStatus
         * @property {number|null} [rating] ContractorUpdateRequest rating
         * @property {string|null} [regionId] ContractorUpdateRequest regionId
         * @property {PlatformAdminApi.IProfileUpdateRequest|null} [profile] ContractorUpdateRequest profile
         * @property {string|null} [changerId] ContractorUpdateRequest changerId
         * @property {PlatformAdminApi.ICovidCertificate|null} [covidCertificate] ContractorUpdateRequest covidCertificate
         */

        /**
         * Constructs a new ContractorUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorUpdateRequest.
         * @implements IContractorUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorUpdateRequest=} [properties] Properties to set
         */
        function ContractorUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.id = "";

        /**
         * ContractorUpdateRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.userId = "";

        /**
         * ContractorUpdateRequest status.
         * @member {string} status
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.status = "";

        /**
         * ContractorUpdateRequest workStatus.
         * @member {string} workStatus
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.workStatus = "";

        /**
         * ContractorUpdateRequest rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.rating = 0;

        /**
         * ContractorUpdateRequest regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.regionId = "";

        /**
         * ContractorUpdateRequest profile.
         * @member {PlatformAdminApi.IProfileUpdateRequest|null|undefined} profile
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.profile = null;

        /**
         * ContractorUpdateRequest changerId.
         * @member {string} changerId
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.changerId = "";

        /**
         * ContractorUpdateRequest covidCertificate.
         * @member {PlatformAdminApi.ICovidCertificate|null|undefined} covidCertificate
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @instance
         */
        ContractorUpdateRequest.prototype.covidCertificate = null;

        /**
         * Encodes the specified ContractorUpdateRequest message. Does not implicitly {@link PlatformAdminApi.ContractorUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @static
         * @param {PlatformAdminApi.IContractorUpdateRequest} message ContractorUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.workStatus != null && Object.hasOwnProperty.call(message, "workStatus"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.workStatus);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.rating);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.regionId);
            if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
                $root.PlatformAdminApi.ProfileUpdateRequest.encode(message.profile, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.changerId != null && Object.hasOwnProperty.call(message, "changerId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.changerId);
            if (message.covidCertificate != null && Object.hasOwnProperty.call(message, "covidCertificate"))
                $root.PlatformAdminApi.CovidCertificate.encode(message.covidCertificate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorUpdateRequest} ContractorUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.workStatus = reader.string();
                    break;
                case 5:
                    message.rating = reader.double();
                    break;
                case 6:
                    message.regionId = reader.string();
                    break;
                case 7:
                    message.profile = $root.PlatformAdminApi.ProfileUpdateRequest.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.changerId = reader.string();
                    break;
                case 9:
                    message.covidCertificate = $root.PlatformAdminApi.CovidCertificate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorUpdateRequest;
    })();

    PlatformAdminApi.ContractorResponse = (function() {

        /**
         * Properties of a ContractorResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorResponse
         * @property {PlatformAdminApi.IContractor|null} [data] ContractorResponse data
         */

        /**
         * Constructs a new ContractorResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorResponse.
         * @implements IContractorResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorResponse=} [properties] Properties to set
         */
        function ContractorResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorResponse data.
         * @member {PlatformAdminApi.IContractor|null|undefined} data
         * @memberof PlatformAdminApi.ContractorResponse
         * @instance
         */
        ContractorResponse.prototype.data = null;

        /**
         * Encodes the specified ContractorResponse message. Does not implicitly {@link PlatformAdminApi.ContractorResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorResponse
         * @static
         * @param {PlatformAdminApi.IContractorResponse} message ContractorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Contractor.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorResponse} ContractorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Contractor.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorResponse;
    })();

    PlatformAdminApi.ContractorSearchResponse = (function() {

        /**
         * Properties of a ContractorSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorSearchResponse
         * @property {Array.<PlatformAdminApi.IContractor>|null} [data] ContractorSearchResponse data
         * @property {number|null} [page] ContractorSearchResponse page
         * @property {number|null} [limit] ContractorSearchResponse limit
         * @property {number|null} [total] ContractorSearchResponse total
         */

        /**
         * Constructs a new ContractorSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorSearchResponse.
         * @implements IContractorSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorSearchResponse=} [properties] Properties to set
         */
        function ContractorSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorSearchResponse data.
         * @member {Array.<PlatformAdminApi.IContractor>} data
         * @memberof PlatformAdminApi.ContractorSearchResponse
         * @instance
         */
        ContractorSearchResponse.prototype.data = $util.emptyArray;

        /**
         * ContractorSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractorSearchResponse
         * @instance
         */
        ContractorSearchResponse.prototype.page = 0;

        /**
         * ContractorSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractorSearchResponse
         * @instance
         */
        ContractorSearchResponse.prototype.limit = 0;

        /**
         * ContractorSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ContractorSearchResponse
         * @instance
         */
        ContractorSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified ContractorSearchResponse message. Does not implicitly {@link PlatformAdminApi.ContractorSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorSearchResponse
         * @static
         * @param {PlatformAdminApi.IContractorSearchResponse} message ContractorSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Contractor.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ContractorSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorSearchResponse} ContractorSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Contractor.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorSearchResponse;
    })();

    PlatformAdminApi.ContractorSearchRequest = (function() {

        /**
         * Properties of a ContractorSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorSearchRequest
         * @property {number|null} [page] ContractorSearchRequest page
         * @property {number|null} [limit] ContractorSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] ContractorSearchRequest sort
         * @property {Array.<PlatformAdminApi.ContractorSearchRequest.IWhereFields>|null} [where] ContractorSearchRequest where
         */

        /**
         * Constructs a new ContractorSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorSearchRequest.
         * @implements IContractorSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorSearchRequest=} [properties] Properties to set
         */
        function ContractorSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractorSearchRequest
         * @instance
         */
        ContractorSearchRequest.prototype.page = 0;

        /**
         * ContractorSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractorSearchRequest
         * @instance
         */
        ContractorSearchRequest.prototype.limit = 0;

        /**
         * ContractorSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.ContractorSearchRequest
         * @instance
         */
        ContractorSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * ContractorSearchRequest where.
         * @member {Array.<PlatformAdminApi.ContractorSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.ContractorSearchRequest
         * @instance
         */
        ContractorSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified ContractorSearchRequest message. Does not implicitly {@link PlatformAdminApi.ContractorSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorSearchRequest
         * @static
         * @param {PlatformAdminApi.IContractorSearchRequest} message ContractorSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.ContractorSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorSearchRequest} ContractorSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.ContractorSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ContractorSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.ContractorSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IUuidCondition|null} [userId] WhereFields userId
             * @property {PlatformAdminApi.IStringCondition|null} [workStatus] WhereFields workStatus
             * @property {PlatformAdminApi.IStringCondition|null} [status] WhereFields status
             * @property {Array.<PlatformAdminApi.ContractorSearchRequest.WhereFields.IProfileWhere>|null} [profile] WhereFields profile
             * @property {Array.<PlatformAdminApi.ContractorSearchRequest.WhereFields.ICovidCertificateWhere>|null} [covidCertificate] WhereFields covidCertificate
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.ContractorSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.ContractorSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                this.profile = [];
                this.covidCertificate = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields userId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} userId
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.userId = null;

            /**
             * WhereFields workStatus.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} workStatus
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.workStatus = null;

            /**
             * WhereFields status.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} status
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.status = null;

            /**
             * WhereFields profile.
             * @member {Array.<PlatformAdminApi.ContractorSearchRequest.WhereFields.IProfileWhere>} profile
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.profile = $util.emptyArray;

            /**
             * WhereFields covidCertificate.
             * @member {Array.<PlatformAdminApi.ContractorSearchRequest.WhereFields.ICovidCertificateWhere>} covidCertificate
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.covidCertificate = $util.emptyArray;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.ContractorSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.ContractorSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.userId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.workStatus != null && Object.hasOwnProperty.call(message, "workStatus"))
                    $root.PlatformAdminApi.StringCondition.encode(message.workStatus, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.PlatformAdminApi.StringCondition.encode(message.status, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.profile != null && message.profile.length)
                    for (var i = 0; i < message.profile.length; ++i)
                        $root.PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere.encode(message.profile[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.covidCertificate != null && message.covidCertificate.length)
                    for (var i = 0; i < message.covidCertificate.length; ++i)
                        $root.PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere.encode(message.covidCertificate[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ContractorSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.isDeleted = reader.bool();
                        break;
                    case 3:
                        message.userId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.workStatus = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.status = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        if (!(message.profile && message.profile.length))
                            message.profile = [];
                        message.profile.push($root.PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        if (!(message.covidCertificate && message.covidCertificate.length))
                            message.covidCertificate = [];
                        message.covidCertificate.push($root.PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            WhereFields.ProfileWhere = (function() {

                /**
                 * Properties of a ProfileWhere.
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
                 * @interface IProfileWhere
                 * @property {PlatformAdminApi.IStringCondition|null} [firstName] ProfileWhere firstName
                 * @property {PlatformAdminApi.IStringCondition|null} [middleName] ProfileWhere middleName
                 * @property {PlatformAdminApi.IStringCondition|null} [lastName] ProfileWhere lastName
                 * @property {Array.<PlatformAdminApi.ContractorSearchRequest.WhereFields.IContactsWhereFields>|null} [contacts] ProfileWhere contacts
                 */

                /**
                 * Constructs a new ProfileWhere.
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
                 * @classdesc Represents a ProfileWhere.
                 * @implements IProfileWhere
                 * @constructor
                 * @param {PlatformAdminApi.ContractorSearchRequest.WhereFields.IProfileWhere=} [properties] Properties to set
                 */
                function ProfileWhere(properties) {
                    this.contacts = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProfileWhere firstName.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} firstName
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere
                 * @instance
                 */
                ProfileWhere.prototype.firstName = null;

                /**
                 * ProfileWhere middleName.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} middleName
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere
                 * @instance
                 */
                ProfileWhere.prototype.middleName = null;

                /**
                 * ProfileWhere lastName.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} lastName
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere
                 * @instance
                 */
                ProfileWhere.prototype.lastName = null;

                /**
                 * ProfileWhere contacts.
                 * @member {Array.<PlatformAdminApi.ContractorSearchRequest.WhereFields.IContactsWhereFields>} contacts
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere
                 * @instance
                 */
                ProfileWhere.prototype.contacts = $util.emptyArray;

                /**
                 * Encodes the specified ProfileWhere message. Does not implicitly {@link PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere.verify|verify} messages.
                 * @function encode
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere
                 * @static
                 * @param {PlatformAdminApi.ContractorSearchRequest.WhereFields.IProfileWhere} message ProfileWhere message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileWhere.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        $root.PlatformAdminApi.StringCondition.encode(message.firstName, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                        $root.PlatformAdminApi.StringCondition.encode(message.middleName, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        $root.PlatformAdminApi.StringCondition.encode(message.lastName, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.contacts != null && message.contacts.length)
                        for (var i = 0; i < message.contacts.length; ++i)
                            $root.PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields.encode(message.contacts[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a ProfileWhere message from the specified reader or buffer.
                 * @function decode
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere} ProfileWhere
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileWhere.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSearchRequest.WhereFields.ProfileWhere();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.firstName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.middleName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.lastName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 4:
                            if (!(message.contacts && message.contacts.length))
                                message.contacts = [];
                            message.contacts.push($root.PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return ProfileWhere;
            })();

            WhereFields.ContactsWhereFields = (function() {

                /**
                 * Properties of a ContactsWhereFields.
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
                 * @interface IContactsWhereFields
                 * @property {boolean|null} [isDeleted] ContactsWhereFields isDeleted
                 * @property {PlatformAdminApi.IStringCondition|null} [value] ContactsWhereFields value
                 * @property {PlatformAdminApi.IStringCondition|null} [type] ContactsWhereFields type
                 * @property {boolean|null} [isVerified] ContactsWhereFields isVerified
                 * @property {boolean|null} [isMain] ContactsWhereFields isMain
                 */

                /**
                 * Constructs a new ContactsWhereFields.
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
                 * @classdesc Represents a ContactsWhereFields.
                 * @implements IContactsWhereFields
                 * @constructor
                 * @param {PlatformAdminApi.ContractorSearchRequest.WhereFields.IContactsWhereFields=} [properties] Properties to set
                 */
                function ContactsWhereFields(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ContactsWhereFields isDeleted.
                 * @member {boolean} isDeleted
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.isDeleted = false;

                /**
                 * ContactsWhereFields value.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} value
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.value = null;

                /**
                 * ContactsWhereFields type.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} type
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.type = null;

                /**
                 * ContactsWhereFields isVerified.
                 * @member {boolean} isVerified
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.isVerified = false;

                /**
                 * ContactsWhereFields isMain.
                 * @member {boolean} isMain
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.isMain = false;

                /**
                 * Encodes the specified ContactsWhereFields message. Does not implicitly {@link PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields.verify|verify} messages.
                 * @function encode
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields
                 * @static
                 * @param {PlatformAdminApi.ContractorSearchRequest.WhereFields.IContactsWhereFields} message ContactsWhereFields message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ContactsWhereFields.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isDeleted);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        $root.PlatformAdminApi.StringCondition.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        $root.PlatformAdminApi.StringCondition.encode(message.type, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVerified);
                    if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isMain);
                    return writer;
                };

                /**
                 * Decodes a ContactsWhereFields message from the specified reader or buffer.
                 * @function decode
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields} ContactsWhereFields
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ContactsWhereFields.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSearchRequest.WhereFields.ContactsWhereFields();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isDeleted = reader.bool();
                            break;
                        case 2:
                            message.value = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.type = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.isVerified = reader.bool();
                            break;
                        case 5:
                            message.isMain = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return ContactsWhereFields;
            })();

            WhereFields.CovidCertificateWhere = (function() {

                /**
                 * Properties of a CovidCertificateWhere.
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
                 * @interface ICovidCertificateWhere
                 * @property {PlatformAdminApi.IStringCondition|null} [type] CovidCertificateWhere type
                 * @property {PlatformAdminApi.IDateCondition|null} [validUntil] CovidCertificateWhere validUntil
                 */

                /**
                 * Constructs a new CovidCertificateWhere.
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields
                 * @classdesc Represents a CovidCertificateWhere.
                 * @implements ICovidCertificateWhere
                 * @constructor
                 * @param {PlatformAdminApi.ContractorSearchRequest.WhereFields.ICovidCertificateWhere=} [properties] Properties to set
                 */
                function CovidCertificateWhere(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CovidCertificateWhere type.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} type
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere
                 * @instance
                 */
                CovidCertificateWhere.prototype.type = null;

                /**
                 * CovidCertificateWhere validUntil.
                 * @member {PlatformAdminApi.IDateCondition|null|undefined} validUntil
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere
                 * @instance
                 */
                CovidCertificateWhere.prototype.validUntil = null;

                /**
                 * Encodes the specified CovidCertificateWhere message. Does not implicitly {@link PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere.verify|verify} messages.
                 * @function encode
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere
                 * @static
                 * @param {PlatformAdminApi.ContractorSearchRequest.WhereFields.ICovidCertificateWhere} message CovidCertificateWhere message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CovidCertificateWhere.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        $root.PlatformAdminApi.StringCondition.encode(message.type, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.validUntil != null && Object.hasOwnProperty.call(message, "validUntil"))
                        $root.PlatformAdminApi.DateCondition.encode(message.validUntil, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a CovidCertificateWhere message from the specified reader or buffer.
                 * @function decode
                 * @memberof PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere} CovidCertificateWhere
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CovidCertificateWhere.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSearchRequest.WhereFields.CovidCertificateWhere();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.type = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.validUntil = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return CovidCertificateWhere;
            })();

            return WhereFields;
        })();

        return ContractorSearchRequest;
    })();

    PlatformAdminApi.HrmContractorAddTaskRequest = (function() {

        /**
         * Properties of a HrmContractorAddTaskRequest.
         * @memberof PlatformAdminApi
         * @interface IHrmContractorAddTaskRequest
         * @property {string|null} [userSsoId] HrmContractorAddTaskRequest userSsoId
         */

        /**
         * Constructs a new HrmContractorAddTaskRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a HrmContractorAddTaskRequest.
         * @implements IHrmContractorAddTaskRequest
         * @constructor
         * @param {PlatformAdminApi.IHrmContractorAddTaskRequest=} [properties] Properties to set
         */
        function HrmContractorAddTaskRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HrmContractorAddTaskRequest userSsoId.
         * @member {string} userSsoId
         * @memberof PlatformAdminApi.HrmContractorAddTaskRequest
         * @instance
         */
        HrmContractorAddTaskRequest.prototype.userSsoId = "";

        /**
         * Encodes the specified HrmContractorAddTaskRequest message. Does not implicitly {@link PlatformAdminApi.HrmContractorAddTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.HrmContractorAddTaskRequest
         * @static
         * @param {PlatformAdminApi.IHrmContractorAddTaskRequest} message HrmContractorAddTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HrmContractorAddTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userSsoId != null && Object.hasOwnProperty.call(message, "userSsoId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userSsoId);
            return writer;
        };

        /**
         * Decodes a HrmContractorAddTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.HrmContractorAddTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.HrmContractorAddTaskRequest} HrmContractorAddTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HrmContractorAddTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.HrmContractorAddTaskRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.userSsoId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return HrmContractorAddTaskRequest;
    })();

    PlatformAdminApi.HrmContractorAddTaskResponse = (function() {

        /**
         * Properties of a HrmContractorAddTaskResponse.
         * @memberof PlatformAdminApi
         * @interface IHrmContractorAddTaskResponse
         * @property {string|null} [taskId] HrmContractorAddTaskResponse taskId
         */

        /**
         * Constructs a new HrmContractorAddTaskResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a HrmContractorAddTaskResponse.
         * @implements IHrmContractorAddTaskResponse
         * @constructor
         * @param {PlatformAdminApi.IHrmContractorAddTaskResponse=} [properties] Properties to set
         */
        function HrmContractorAddTaskResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HrmContractorAddTaskResponse taskId.
         * @member {string} taskId
         * @memberof PlatformAdminApi.HrmContractorAddTaskResponse
         * @instance
         */
        HrmContractorAddTaskResponse.prototype.taskId = "";

        /**
         * Encodes the specified HrmContractorAddTaskResponse message. Does not implicitly {@link PlatformAdminApi.HrmContractorAddTaskResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.HrmContractorAddTaskResponse
         * @static
         * @param {PlatformAdminApi.IHrmContractorAddTaskResponse} message HrmContractorAddTaskResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HrmContractorAddTaskResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.taskId);
            return writer;
        };

        /**
         * Decodes a HrmContractorAddTaskResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.HrmContractorAddTaskResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.HrmContractorAddTaskResponse} HrmContractorAddTaskResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HrmContractorAddTaskResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.HrmContractorAddTaskResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 4:
                    message.taskId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return HrmContractorAddTaskResponse;
    })();

    PlatformAdminApi.ProfileManagementService = (function() {

        /**
         * Constructs a new ProfileManagementService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileManagementService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ProfileManagementService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ProfileManagementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProfileManagementService;

        /**
         * Callback as used by {@link PlatformAdminApi.ProfileManagementService#create}.
         * @memberof PlatformAdminApi.ProfileManagementService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProfileResponse} [response] ProfileResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IProfileManagementCreateRequest} request ProfileManagementCreateRequest message or plain object
         * @param {PlatformAdminApi.ProfileManagementService.CreateCallback} callback Node-style callback called with the error, if any, and ProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileManagementService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.ProfileManagementCreateRequest, $root.PlatformAdminApi.ProfileResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IProfileManagementCreateRequest} request ProfileManagementCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProfileManagementService#findById}.
         * @memberof PlatformAdminApi.ProfileManagementService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProfileResponse} [response] ProfileResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ProfileManagementService.FindByIdCallback} callback Node-style callback called with the error, if any, and ProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileManagementService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ProfileResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProfileManagementService#remove}.
         * @memberof PlatformAdminApi.ProfileManagementService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProfileResponse} [response] ProfileResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ProfileManagementService.RemoveCallback} callback Node-style callback called with the error, if any, and ProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileManagementService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ProfileResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProfileManagementService#restore}.
         * @memberof PlatformAdminApi.ProfileManagementService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProfileResponse} [response] ProfileResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ProfileManagementService.RestoreCallback} callback Node-style callback called with the error, if any, and ProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileManagementService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ProfileResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProfileManagementService#update}.
         * @memberof PlatformAdminApi.ProfileManagementService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProfileResponse} [response] ProfileResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IProfileManagementUpdateRequest} request ProfileManagementUpdateRequest message or plain object
         * @param {PlatformAdminApi.ProfileManagementService.UpdateCallback} callback Node-style callback called with the error, if any, and ProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileManagementService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.ProfileManagementUpdateRequest, $root.PlatformAdminApi.ProfileResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IProfileManagementUpdateRequest} request ProfileManagementUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProfileManagementService#search}.
         * @memberof PlatformAdminApi.ProfileManagementService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProfileSearchResponse} [response] ProfileSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IProfileManagementSearchRequest} request ProfileManagementSearchRequest message or plain object
         * @param {PlatformAdminApi.ProfileManagementService.SearchCallback} callback Node-style callback called with the error, if any, and ProfileSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileManagementService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.ProfileManagementSearchRequest, $root.PlatformAdminApi.ProfileSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ProfileManagementService
         * @instance
         * @param {PlatformAdminApi.IProfileManagementSearchRequest} request ProfileManagementSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProfileSearchResponse>} Promise
         * @variation 2
         */

        return ProfileManagementService;
    })();

    PlatformAdminApi.User = (function() {

        /**
         * Properties of a User.
         * @memberof PlatformAdminApi
         * @interface IUser
         * @property {string|null} [id] User id
         * @property {string|null} [createdAt] User createdAt
         * @property {string|null} [updatedAt] User updatedAt
         * @property {boolean|null} [isDeleted] User isDeleted
         * @property {string|null} [deletedAt] User deletedAt
         * @property {string|null} [tenantId] User tenantId
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] User userType
         * @property {boolean|null} [isVerified] User isVerified
         * @property {boolean|null} [isBlocked] User isBlocked
         * @property {string|null} [firstName] User firstName
         * @property {string|null} [middleName] User middleName
         * @property {string|null} [lastName] User lastName
         * @property {boolean|null} [isValid] User isValid
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] User gender
         * @property {string|null} [birthday] User birthday
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] User object
         * @property {Array.<PlatformAdminApi.IContact>|null} [contacts] User contacts
         * @property {Array.<PlatformAdminApi.IPassport>|null} [passports] User passports
         * @property {Array.<PlatformAdminApi.IAddress>|null} [addresses] User addresses
         * @property {boolean|null} [isAdmin] User isAdmin
         * @property {string|null} [blockingReasonSlug] User blockingReasonSlug
         * @property {PlatformAdminApi.ITenant|null} [tenant] User tenant
         */

        /**
         * Constructs a new User.
         * @memberof PlatformAdminApi
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {PlatformAdminApi.IUser=} [properties] Properties to set
         */
        function User(properties) {
            this.contacts = [];
            this.passports = [];
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User id.
         * @member {string} id
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.id = "";

        /**
         * User createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.createdAt = "";

        /**
         * User updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.updatedAt = "";

        /**
         * User isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.isDeleted = false;

        /**
         * User deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.deletedAt = "";

        /**
         * User tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.tenantId = "";

        /**
         * User userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.userType = 0;

        /**
         * User isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.isVerified = false;

        /**
         * User isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.isBlocked = false;

        /**
         * User firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.firstName = "";

        /**
         * User middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.middleName = "";

        /**
         * User lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.lastName = "";

        /**
         * User isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.isValid = false;

        /**
         * User gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.gender = 0;

        /**
         * User birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.birthday = "";

        /**
         * User object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.object = null;

        /**
         * User contacts.
         * @member {Array.<PlatformAdminApi.IContact>} contacts
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.contacts = $util.emptyArray;

        /**
         * User passports.
         * @member {Array.<PlatformAdminApi.IPassport>} passports
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.passports = $util.emptyArray;

        /**
         * User addresses.
         * @member {Array.<PlatformAdminApi.IAddress>} addresses
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.addresses = $util.emptyArray;

        /**
         * User isAdmin.
         * @member {boolean} isAdmin
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.isAdmin = false;

        /**
         * User blockingReasonSlug.
         * @member {string} blockingReasonSlug
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.blockingReasonSlug = "";

        /**
         * User tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.User
         * @instance
         */
        User.prototype.tenant = null;

        /**
         * Encodes the specified User message. Does not implicitly {@link PlatformAdminApi.User.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.User
         * @static
         * @param {PlatformAdminApi.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.tenantId);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.userType);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isVerified);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isBlocked);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.middleName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.lastName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.contacts != null && message.contacts.length)
                for (var i = 0; i < message.contacts.length; ++i)
                    $root.PlatformAdminApi.Contact.encode(message.contacts[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.passports != null && message.passports.length)
                for (var i = 0; i < message.passports.length; ++i)
                    $root.PlatformAdminApi.Passport.encode(message.passports[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.Address.encode(message.addresses[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.isAdmin);
            if (message.blockingReasonSlug != null && Object.hasOwnProperty.call(message, "blockingReasonSlug"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.blockingReasonSlug);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.User();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.tenantId = reader.string();
                    break;
                case 7:
                    message.userType = reader.int32();
                    break;
                case 8:
                    message.isVerified = reader.bool();
                    break;
                case 9:
                    message.isBlocked = reader.bool();
                    break;
                case 10:
                    message.firstName = reader.string();
                    break;
                case 11:
                    message.middleName = reader.string();
                    break;
                case 12:
                    message.lastName = reader.string();
                    break;
                case 13:
                    message.isValid = reader.bool();
                    break;
                case 14:
                    message.gender = reader.int32();
                    break;
                case 15:
                    message.birthday = reader.string();
                    break;
                case 16:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 17:
                    if (!(message.contacts && message.contacts.length))
                        message.contacts = [];
                    message.contacts.push($root.PlatformAdminApi.Contact.decode(reader, reader.uint32()));
                    break;
                case 18:
                    if (!(message.passports && message.passports.length))
                        message.passports = [];
                    message.passports.push($root.PlatformAdminApi.Passport.decode(reader, reader.uint32()));
                    break;
                case 19:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.Address.decode(reader, reader.uint32()));
                    break;
                case 20:
                    message.isAdmin = reader.bool();
                    break;
                case 21:
                    message.blockingReasonSlug = reader.string();
                    break;
                case 22:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return User;
    })();

    PlatformAdminApi.ContactRel = (function() {

        /**
         * Properties of a ContactRel.
         * @memberof PlatformAdminApi
         * @interface IContactRel
         * @property {string|null} [id] ContactRel id
         * @property {string|null} [value] ContactRel value
         * @property {PlatformAdminApi.CONTACT_TYPE|null} [type] ContactRel type
         * @property {boolean|null} [isVerified] ContactRel isVerified
         * @property {boolean|null} [isMain] ContactRel isMain
         */

        /**
         * Constructs a new ContactRel.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContactRel.
         * @implements IContactRel
         * @constructor
         * @param {PlatformAdminApi.IContactRel=} [properties] Properties to set
         */
        function ContactRel(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactRel id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContactRel
         * @instance
         */
        ContactRel.prototype.id = "";

        /**
         * ContactRel value.
         * @member {string} value
         * @memberof PlatformAdminApi.ContactRel
         * @instance
         */
        ContactRel.prototype.value = "";

        /**
         * ContactRel type.
         * @member {PlatformAdminApi.CONTACT_TYPE} type
         * @memberof PlatformAdminApi.ContactRel
         * @instance
         */
        ContactRel.prototype.type = 0;

        /**
         * ContactRel isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ContactRel
         * @instance
         */
        ContactRel.prototype.isVerified = false;

        /**
         * ContactRel isMain.
         * @member {boolean} isMain
         * @memberof PlatformAdminApi.ContactRel
         * @instance
         */
        ContactRel.prototype.isMain = false;

        /**
         * Encodes the specified ContactRel message. Does not implicitly {@link PlatformAdminApi.ContactRel.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContactRel
         * @static
         * @param {PlatformAdminApi.IContactRel} message ContactRel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContactRel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVerified);
            if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isMain);
            return writer;
        };

        /**
         * Decodes a ContactRel message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContactRel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContactRel} ContactRel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContactRel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContactRel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.isVerified = reader.bool();
                    break;
                case 5:
                    message.isMain = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContactRel;
    })();

    PlatformAdminApi.AddressRel = (function() {

        /**
         * Properties of an AddressRel.
         * @memberof PlatformAdminApi
         * @interface IAddressRel
         * @property {string|null} [id] AddressRel id
         * @property {string|null} [label] AddressRel label
         * @property {string|null} [value] AddressRel value
         * @property {string|null} [unrestrictedValue] AddressRel unrestrictedValue
         * @property {string|null} [object] AddressRel object
         * @property {PlatformAdminApi.IAddressDetail|null} [detail] AddressRel detail
         * @property {PlatformAdminApi.ICoordinates|null} [coordinates] AddressRel coordinates
         * @property {Array.<PlatformAdminApi.IMetro>|null} [metro] AddressRel metro
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] AddressRel type
         */

        /**
         * Constructs a new AddressRel.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AddressRel.
         * @implements IAddressRel
         * @constructor
         * @param {PlatformAdminApi.IAddressRel=} [properties] Properties to set
         */
        function AddressRel(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressRel id.
         * @member {string} id
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.id = "";

        /**
         * AddressRel label.
         * @member {string} label
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.label = "";

        /**
         * AddressRel value.
         * @member {string} value
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.value = "";

        /**
         * AddressRel unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.unrestrictedValue = "";

        /**
         * AddressRel object.
         * @member {string} object
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.object = "";

        /**
         * AddressRel detail.
         * @member {PlatformAdminApi.IAddressDetail|null|undefined} detail
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.detail = null;

        /**
         * AddressRel coordinates.
         * @member {PlatformAdminApi.ICoordinates|null|undefined} coordinates
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.coordinates = null;

        /**
         * AddressRel metro.
         * @member {Array.<PlatformAdminApi.IMetro>} metro
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.metro = $util.emptyArray;

        /**
         * AddressRel type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.AddressRel
         * @instance
         */
        AddressRel.prototype.type = 0;

        /**
         * Encodes the specified AddressRel message. Does not implicitly {@link PlatformAdminApi.AddressRel.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AddressRel
         * @static
         * @param {PlatformAdminApi.IAddressRel} message AddressRel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressRel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.PlatformAdminApi.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.PlatformAdminApi.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.PlatformAdminApi.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.type);
            return writer;
        };

        /**
         * Decodes an AddressRel message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AddressRel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AddressRel} AddressRel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressRel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AddressRel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.PlatformAdminApi.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.PlatformAdminApi.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.PlatformAdminApi.Metro.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressRel;
    })();

    PlatformAdminApi.PassportAttachments = (function() {

        /**
         * Properties of a PassportAttachments.
         * @memberof PlatformAdminApi
         * @interface IPassportAttachments
         * @property {string|null} [id] PassportAttachments id
         * @property {string|null} [name] PassportAttachments name
         * @property {string|null} [url] PassportAttachments url
         * @property {boolean|null} [isDeleted] PassportAttachments isDeleted
         * @property {string|null} [deletedAd] PassportAttachments deletedAd
         * @property {string|null} [createdAt] PassportAttachments createdAt
         * @property {string|null} [updatedAt] PassportAttachments updatedAt
         */

        /**
         * Constructs a new PassportAttachments.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PassportAttachments.
         * @implements IPassportAttachments
         * @constructor
         * @param {PlatformAdminApi.IPassportAttachments=} [properties] Properties to set
         */
        function PassportAttachments(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PassportAttachments id.
         * @member {string} id
         * @memberof PlatformAdminApi.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.id = "";

        /**
         * PassportAttachments name.
         * @member {string} name
         * @memberof PlatformAdminApi.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.name = "";

        /**
         * PassportAttachments url.
         * @member {string} url
         * @memberof PlatformAdminApi.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.url = "";

        /**
         * PassportAttachments isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.isDeleted = false;

        /**
         * PassportAttachments deletedAd.
         * @member {string} deletedAd
         * @memberof PlatformAdminApi.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.deletedAd = "";

        /**
         * PassportAttachments createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.createdAt = "";

        /**
         * PassportAttachments updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.updatedAt = "";

        /**
         * Encodes the specified PassportAttachments message. Does not implicitly {@link PlatformAdminApi.PassportAttachments.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PassportAttachments
         * @static
         * @param {PlatformAdminApi.IPassportAttachments} message PassportAttachments message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PassportAttachments.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAd != null && Object.hasOwnProperty.call(message, "deletedAd"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAd);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.updatedAt);
            return writer;
        };

        /**
         * Decodes a PassportAttachments message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PassportAttachments
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PassportAttachments} PassportAttachments
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PassportAttachments.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PassportAttachments();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.url = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAd = reader.string();
                    break;
                case 6:
                    message.createdAt = reader.string();
                    break;
                case 7:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PassportAttachments;
    })();

    PlatformAdminApi.Passport = (function() {

        /**
         * Properties of a Passport.
         * @memberof PlatformAdminApi
         * @interface IPassport
         * @property {string|null} [id] Passport id
         * @property {string|null} [createdAt] Passport createdAt
         * @property {string|null} [updatedAt] Passport updatedAt
         * @property {boolean|null} [isDeleted] Passport isDeleted
         * @property {string|null} [deletedAt] Passport deletedAt
         * @property {string|null} [firstName] Passport firstName
         * @property {string|null} [lastName] Passport lastName
         * @property {string|null} [middleName] Passport middleName
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] Passport gender
         * @property {string|null} [citizenship] Passport citizenship
         * @property {string|null} [number] Passport number
         * @property {string|null} [dateStart] Passport dateStart
         * @property {string|null} [dateEnd] Passport dateEnd
         * @property {string|null} [issuePlace] Passport issuePlace
         * @property {string|null} [birthDate] Passport birthDate
         * @property {PlatformAdminApi.IAddressRel|null} [registrationAddress] Passport registrationAddress
         * @property {PlatformAdminApi.IAddressRel|null} [birthAddress] Passport birthAddress
         * @property {boolean|null} [isValid] Passport isValid
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] Passport object
         * @property {Array.<PlatformAdminApi.IPassportAttachments>|null} [attachments] Passport attachments
         */

        /**
         * Constructs a new Passport.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Passport.
         * @implements IPassport
         * @constructor
         * @param {PlatformAdminApi.IPassport=} [properties] Properties to set
         */
        function Passport(properties) {
            this.attachments = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Passport id.
         * @member {string} id
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.id = "";

        /**
         * Passport createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.createdAt = "";

        /**
         * Passport updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.updatedAt = "";

        /**
         * Passport isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.isDeleted = false;

        /**
         * Passport deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.deletedAt = "";

        /**
         * Passport firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.firstName = "";

        /**
         * Passport lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.lastName = "";

        /**
         * Passport middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.middleName = "";

        /**
         * Passport gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.gender = 0;

        /**
         * Passport citizenship.
         * @member {string} citizenship
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.citizenship = "";

        /**
         * Passport number.
         * @member {string} number
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.number = "";

        /**
         * Passport dateStart.
         * @member {string} dateStart
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.dateStart = "";

        /**
         * Passport dateEnd.
         * @member {string} dateEnd
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.dateEnd = "";

        /**
         * Passport issuePlace.
         * @member {string} issuePlace
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.issuePlace = "";

        /**
         * Passport birthDate.
         * @member {string} birthDate
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.birthDate = "";

        /**
         * Passport registrationAddress.
         * @member {PlatformAdminApi.IAddressRel|null|undefined} registrationAddress
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.registrationAddress = null;

        /**
         * Passport birthAddress.
         * @member {PlatformAdminApi.IAddressRel|null|undefined} birthAddress
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.birthAddress = null;

        /**
         * Passport isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.isValid = false;

        /**
         * Passport object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.object = null;

        /**
         * Passport attachments.
         * @member {Array.<PlatformAdminApi.IPassportAttachments>} attachments
         * @memberof PlatformAdminApi.Passport
         * @instance
         */
        Passport.prototype.attachments = $util.emptyArray;

        /**
         * Encodes the specified Passport message. Does not implicitly {@link PlatformAdminApi.Passport.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Passport
         * @static
         * @param {PlatformAdminApi.IPassport} message Passport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Passport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.middleName);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.gender);
            if (message.citizenship != null && Object.hasOwnProperty.call(message, "citizenship"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.citizenship);
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.number);
            if (message.dateStart != null && Object.hasOwnProperty.call(message, "dateStart"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.dateStart);
            if (message.dateEnd != null && Object.hasOwnProperty.call(message, "dateEnd"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.dateEnd);
            if (message.issuePlace != null && Object.hasOwnProperty.call(message, "issuePlace"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.issuePlace);
            if (message.birthDate != null && Object.hasOwnProperty.call(message, "birthDate"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.birthDate);
            if (message.registrationAddress != null && Object.hasOwnProperty.call(message, "registrationAddress"))
                $root.PlatformAdminApi.AddressRel.encode(message.registrationAddress, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.birthAddress != null && Object.hasOwnProperty.call(message, "birthAddress"))
                $root.PlatformAdminApi.AddressRel.encode(message.birthAddress, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isValid);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.attachments != null && message.attachments.length)
                for (var i = 0; i < message.attachments.length; ++i)
                    $root.PlatformAdminApi.PassportAttachments.encode(message.attachments[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Passport message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Passport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Passport} Passport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Passport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Passport();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.firstName = reader.string();
                    break;
                case 7:
                    message.lastName = reader.string();
                    break;
                case 8:
                    message.middleName = reader.string();
                    break;
                case 9:
                    message.gender = reader.int32();
                    break;
                case 10:
                    message.citizenship = reader.string();
                    break;
                case 11:
                    message.number = reader.string();
                    break;
                case 12:
                    message.dateStart = reader.string();
                    break;
                case 13:
                    message.dateEnd = reader.string();
                    break;
                case 14:
                    message.issuePlace = reader.string();
                    break;
                case 15:
                    message.birthDate = reader.string();
                    break;
                case 16:
                    message.registrationAddress = $root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.birthAddress = $root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.isValid = reader.bool();
                    break;
                case 19:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 20:
                    if (!(message.attachments && message.attachments.length))
                        message.attachments = [];
                    message.attachments.push($root.PlatformAdminApi.PassportAttachments.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Passport;
    })();

    PlatformAdminApi.PassportRel = (function() {

        /**
         * Properties of a PassportRel.
         * @memberof PlatformAdminApi
         * @interface IPassportRel
         * @property {string|null} [firstName] PassportRel firstName
         * @property {string|null} [lastName] PassportRel lastName
         * @property {string|null} [middleName] PassportRel middleName
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] PassportRel gender
         * @property {string|null} [citizenship] PassportRel citizenship
         * @property {string|null} [number] PassportRel number
         * @property {string|null} [dateStart] PassportRel dateStart
         * @property {string|null} [dateEnd] PassportRel dateEnd
         * @property {string|null} [issuePlace] PassportRel issuePlace
         * @property {string|null} [birthDate] PassportRel birthDate
         * @property {PlatformAdminApi.IAddressRel|null} [registrationAddress] PassportRel registrationAddress
         * @property {PlatformAdminApi.IAddressRel|null} [birthAddress] PassportRel birthAddress
         * @property {boolean|null} [isValid] PassportRel isValid
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] PassportRel object
         * @property {Array.<PlatformAdminApi.IPassportAttachments>|null} [attachments] PassportRel attachments
         * @property {string|null} [id] PassportRel id
         */

        /**
         * Constructs a new PassportRel.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PassportRel.
         * @implements IPassportRel
         * @constructor
         * @param {PlatformAdminApi.IPassportRel=} [properties] Properties to set
         */
        function PassportRel(properties) {
            this.attachments = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PassportRel firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.firstName = "";

        /**
         * PassportRel lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.lastName = "";

        /**
         * PassportRel middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.middleName = "";

        /**
         * PassportRel gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.gender = 0;

        /**
         * PassportRel citizenship.
         * @member {string} citizenship
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.citizenship = "";

        /**
         * PassportRel number.
         * @member {string} number
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.number = "";

        /**
         * PassportRel dateStart.
         * @member {string} dateStart
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.dateStart = "";

        /**
         * PassportRel dateEnd.
         * @member {string} dateEnd
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.dateEnd = "";

        /**
         * PassportRel issuePlace.
         * @member {string} issuePlace
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.issuePlace = "";

        /**
         * PassportRel birthDate.
         * @member {string} birthDate
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.birthDate = "";

        /**
         * PassportRel registrationAddress.
         * @member {PlatformAdminApi.IAddressRel|null|undefined} registrationAddress
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.registrationAddress = null;

        /**
         * PassportRel birthAddress.
         * @member {PlatformAdminApi.IAddressRel|null|undefined} birthAddress
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.birthAddress = null;

        /**
         * PassportRel isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.isValid = false;

        /**
         * PassportRel object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.object = null;

        /**
         * PassportRel attachments.
         * @member {Array.<PlatformAdminApi.IPassportAttachments>} attachments
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.attachments = $util.emptyArray;

        /**
         * PassportRel id.
         * @member {string} id
         * @memberof PlatformAdminApi.PassportRel
         * @instance
         */
        PassportRel.prototype.id = "";

        /**
         * Encodes the specified PassportRel message. Does not implicitly {@link PlatformAdminApi.PassportRel.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PassportRel
         * @static
         * @param {PlatformAdminApi.IPassportRel} message PassportRel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PassportRel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.middleName);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.gender);
            if (message.citizenship != null && Object.hasOwnProperty.call(message, "citizenship"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.citizenship);
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.number);
            if (message.dateStart != null && Object.hasOwnProperty.call(message, "dateStart"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.dateStart);
            if (message.dateEnd != null && Object.hasOwnProperty.call(message, "dateEnd"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.dateEnd);
            if (message.issuePlace != null && Object.hasOwnProperty.call(message, "issuePlace"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.issuePlace);
            if (message.birthDate != null && Object.hasOwnProperty.call(message, "birthDate"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.birthDate);
            if (message.registrationAddress != null && Object.hasOwnProperty.call(message, "registrationAddress"))
                $root.PlatformAdminApi.AddressRel.encode(message.registrationAddress, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.birthAddress != null && Object.hasOwnProperty.call(message, "birthAddress"))
                $root.PlatformAdminApi.AddressRel.encode(message.birthAddress, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isValid);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.attachments != null && message.attachments.length)
                for (var i = 0; i < message.attachments.length; ++i)
                    $root.PlatformAdminApi.PassportAttachments.encode(message.attachments[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.id);
            return writer;
        };

        /**
         * Decodes a PassportRel message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PassportRel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PassportRel} PassportRel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PassportRel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PassportRel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.firstName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.middleName = reader.string();
                    break;
                case 4:
                    message.gender = reader.int32();
                    break;
                case 5:
                    message.citizenship = reader.string();
                    break;
                case 6:
                    message.number = reader.string();
                    break;
                case 7:
                    message.dateStart = reader.string();
                    break;
                case 8:
                    message.dateEnd = reader.string();
                    break;
                case 9:
                    message.issuePlace = reader.string();
                    break;
                case 10:
                    message.birthDate = reader.string();
                    break;
                case 11:
                    message.registrationAddress = $root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.birthAddress = $root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.isValid = reader.bool();
                    break;
                case 14:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 15:
                    if (!(message.attachments && message.attachments.length))
                        message.attachments = [];
                    message.attachments.push($root.PlatformAdminApi.PassportAttachments.decode(reader, reader.uint32()));
                    break;
                case 16:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PassportRel;
    })();

    PlatformAdminApi.ProfileManagementCreateRequest = (function() {

        /**
         * Properties of a ProfileManagementCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IProfileManagementCreateRequest
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] ProfileManagementCreateRequest userType
         * @property {boolean|null} [isVerified] ProfileManagementCreateRequest isVerified
         * @property {boolean|null} [isBlocked] ProfileManagementCreateRequest isBlocked
         * @property {string|null} [firstName] ProfileManagementCreateRequest firstName
         * @property {string|null} [middleName] ProfileManagementCreateRequest middleName
         * @property {string|null} [lastName] ProfileManagementCreateRequest lastName
         * @property {boolean|null} [isValid] ProfileManagementCreateRequest isValid
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] ProfileManagementCreateRequest gender
         * @property {string|null} [birthday] ProfileManagementCreateRequest birthday
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] ProfileManagementCreateRequest object
         * @property {Array.<PlatformAdminApi.IContactRel>|null} [contacts] ProfileManagementCreateRequest contacts
         * @property {PlatformAdminApi.IPassportRel|null} [passport] ProfileManagementCreateRequest passport
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] ProfileManagementCreateRequest addresses
         */

        /**
         * Constructs a new ProfileManagementCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileManagementCreateRequest.
         * @implements IProfileManagementCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IProfileManagementCreateRequest=} [properties] Properties to set
         */
        function ProfileManagementCreateRequest(properties) {
            this.contacts = [];
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileManagementCreateRequest userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.userType = 0;

        /**
         * ProfileManagementCreateRequest isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.isVerified = false;

        /**
         * ProfileManagementCreateRequest isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.isBlocked = false;

        /**
         * ProfileManagementCreateRequest firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.firstName = "";

        /**
         * ProfileManagementCreateRequest middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.middleName = "";

        /**
         * ProfileManagementCreateRequest lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.lastName = "";

        /**
         * ProfileManagementCreateRequest isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.isValid = false;

        /**
         * ProfileManagementCreateRequest gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.gender = 0;

        /**
         * ProfileManagementCreateRequest birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.birthday = "";

        /**
         * ProfileManagementCreateRequest object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.object = null;

        /**
         * ProfileManagementCreateRequest contacts.
         * @member {Array.<PlatformAdminApi.IContactRel>} contacts
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.contacts = $util.emptyArray;

        /**
         * ProfileManagementCreateRequest passport.
         * @member {PlatformAdminApi.IPassportRel|null|undefined} passport
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.passport = null;

        /**
         * ProfileManagementCreateRequest addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @instance
         */
        ProfileManagementCreateRequest.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified ProfileManagementCreateRequest message. Does not implicitly {@link PlatformAdminApi.ProfileManagementCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @static
         * @param {PlatformAdminApi.IProfileManagementCreateRequest} message ProfileManagementCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileManagementCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.userType);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isVerified);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isBlocked);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.middleName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.lastName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.contacts != null && message.contacts.length)
                for (var i = 0; i < message.contacts.length; ++i)
                    $root.PlatformAdminApi.ContactRel.encode(message.contacts[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.passport != null && Object.hasOwnProperty.call(message, "passport"))
                $root.PlatformAdminApi.PassportRel.encode(message.passport, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfileManagementCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileManagementCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileManagementCreateRequest} ProfileManagementCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileManagementCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileManagementCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userType = reader.int32();
                    break;
                case 2:
                    message.isVerified = reader.bool();
                    break;
                case 3:
                    message.isBlocked = reader.bool();
                    break;
                case 4:
                    message.firstName = reader.string();
                    break;
                case 5:
                    message.middleName = reader.string();
                    break;
                case 6:
                    message.lastName = reader.string();
                    break;
                case 7:
                    message.isValid = reader.bool();
                    break;
                case 8:
                    message.gender = reader.int32();
                    break;
                case 9:
                    message.birthday = reader.string();
                    break;
                case 10:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 11:
                    if (!(message.contacts && message.contacts.length))
                        message.contacts = [];
                    message.contacts.push($root.PlatformAdminApi.ContactRel.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.passport = $root.PlatformAdminApi.PassportRel.decode(reader, reader.uint32());
                    break;
                case 13:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProfileManagementCreateRequest;
    })();

    PlatformAdminApi.ProfileResponse = (function() {

        /**
         * Properties of a ProfileResponse.
         * @memberof PlatformAdminApi
         * @interface IProfileResponse
         * @property {PlatformAdminApi.IUser|null} [data] ProfileResponse data
         */

        /**
         * Constructs a new ProfileResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileResponse.
         * @implements IProfileResponse
         * @constructor
         * @param {PlatformAdminApi.IProfileResponse=} [properties] Properties to set
         */
        function ProfileResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileResponse data.
         * @member {PlatformAdminApi.IUser|null|undefined} data
         * @memberof PlatformAdminApi.ProfileResponse
         * @instance
         */
        ProfileResponse.prototype.data = null;

        /**
         * Encodes the specified ProfileResponse message. Does not implicitly {@link PlatformAdminApi.ProfileResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileResponse
         * @static
         * @param {PlatformAdminApi.IProfileResponse} message ProfileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.User.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileResponse} ProfileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.User.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProfileResponse;
    })();

    PlatformAdminApi.ProfileManagementUpdateRequest = (function() {

        /**
         * Properties of a ProfileManagementUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IProfileManagementUpdateRequest
         * @property {string|null} [id] ProfileManagementUpdateRequest id
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] ProfileManagementUpdateRequest userType
         * @property {boolean|null} [isVerified] ProfileManagementUpdateRequest isVerified
         * @property {boolean|null} [isBlocked] ProfileManagementUpdateRequest isBlocked
         * @property {string|null} [firstName] ProfileManagementUpdateRequest firstName
         * @property {string|null} [middleName] ProfileManagementUpdateRequest middleName
         * @property {string|null} [lastName] ProfileManagementUpdateRequest lastName
         * @property {boolean|null} [isValid] ProfileManagementUpdateRequest isValid
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] ProfileManagementUpdateRequest gender
         * @property {string|null} [birthday] ProfileManagementUpdateRequest birthday
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] ProfileManagementUpdateRequest object
         * @property {Array.<PlatformAdminApi.IContactRel>|null} [contacts] ProfileManagementUpdateRequest contacts
         * @property {PlatformAdminApi.IPassportRel|null} [passport] ProfileManagementUpdateRequest passport
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] ProfileManagementUpdateRequest addresses
         */

        /**
         * Constructs a new ProfileManagementUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileManagementUpdateRequest.
         * @implements IProfileManagementUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IProfileManagementUpdateRequest=} [properties] Properties to set
         */
        function ProfileManagementUpdateRequest(properties) {
            this.contacts = [];
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileManagementUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.id = "";

        /**
         * ProfileManagementUpdateRequest userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.userType = 0;

        /**
         * ProfileManagementUpdateRequest isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.isVerified = false;

        /**
         * ProfileManagementUpdateRequest isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.isBlocked = false;

        /**
         * ProfileManagementUpdateRequest firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.firstName = "";

        /**
         * ProfileManagementUpdateRequest middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.middleName = "";

        /**
         * ProfileManagementUpdateRequest lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.lastName = "";

        /**
         * ProfileManagementUpdateRequest isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.isValid = false;

        /**
         * ProfileManagementUpdateRequest gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.gender = 0;

        /**
         * ProfileManagementUpdateRequest birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.birthday = "";

        /**
         * ProfileManagementUpdateRequest object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.object = null;

        /**
         * ProfileManagementUpdateRequest contacts.
         * @member {Array.<PlatformAdminApi.IContactRel>} contacts
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.contacts = $util.emptyArray;

        /**
         * ProfileManagementUpdateRequest passport.
         * @member {PlatformAdminApi.IPassportRel|null|undefined} passport
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.passport = null;

        /**
         * ProfileManagementUpdateRequest addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @instance
         */
        ProfileManagementUpdateRequest.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified ProfileManagementUpdateRequest message. Does not implicitly {@link PlatformAdminApi.ProfileManagementUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @static
         * @param {PlatformAdminApi.IProfileManagementUpdateRequest} message ProfileManagementUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileManagementUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userType);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isVerified);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isBlocked);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.middleName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.lastName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.contacts != null && message.contacts.length)
                for (var i = 0; i < message.contacts.length; ++i)
                    $root.PlatformAdminApi.ContactRel.encode(message.contacts[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.passport != null && Object.hasOwnProperty.call(message, "passport"))
                $root.PlatformAdminApi.PassportRel.encode(message.passport, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfileManagementUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileManagementUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileManagementUpdateRequest} ProfileManagementUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileManagementUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileManagementUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userType = reader.int32();
                    break;
                case 3:
                    message.isVerified = reader.bool();
                    break;
                case 4:
                    message.isBlocked = reader.bool();
                    break;
                case 5:
                    message.firstName = reader.string();
                    break;
                case 6:
                    message.middleName = reader.string();
                    break;
                case 7:
                    message.lastName = reader.string();
                    break;
                case 8:
                    message.isValid = reader.bool();
                    break;
                case 9:
                    message.gender = reader.int32();
                    break;
                case 10:
                    message.birthday = reader.string();
                    break;
                case 11:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 12:
                    if (!(message.contacts && message.contacts.length))
                        message.contacts = [];
                    message.contacts.push($root.PlatformAdminApi.ContactRel.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.passport = $root.PlatformAdminApi.PassportRel.decode(reader, reader.uint32());
                    break;
                case 14:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProfileManagementUpdateRequest;
    })();

    PlatformAdminApi.ProfileManagementSearchRequest = (function() {

        /**
         * Properties of a ProfileManagementSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IProfileManagementSearchRequest
         * @property {number|null} [page] ProfileManagementSearchRequest page
         * @property {number|null} [limit] ProfileManagementSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] ProfileManagementSearchRequest sort
         * @property {Array.<PlatformAdminApi.ProfileManagementSearchRequest.IWhereFields>|null} [where] ProfileManagementSearchRequest where
         */

        /**
         * Constructs a new ProfileManagementSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileManagementSearchRequest.
         * @implements IProfileManagementSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IProfileManagementSearchRequest=} [properties] Properties to set
         */
        function ProfileManagementSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileManagementSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.ProfileManagementSearchRequest
         * @instance
         */
        ProfileManagementSearchRequest.prototype.page = 0;

        /**
         * ProfileManagementSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ProfileManagementSearchRequest
         * @instance
         */
        ProfileManagementSearchRequest.prototype.limit = 0;

        /**
         * ProfileManagementSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.ProfileManagementSearchRequest
         * @instance
         */
        ProfileManagementSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * ProfileManagementSearchRequest where.
         * @member {Array.<PlatformAdminApi.ProfileManagementSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.ProfileManagementSearchRequest
         * @instance
         */
        ProfileManagementSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified ProfileManagementSearchRequest message. Does not implicitly {@link PlatformAdminApi.ProfileManagementSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileManagementSearchRequest
         * @static
         * @param {PlatformAdminApi.IProfileManagementSearchRequest} message ProfileManagementSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileManagementSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfileManagementSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileManagementSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileManagementSearchRequest} ProfileManagementSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileManagementSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileManagementSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ProfileManagementSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IUuidCondition|null} [tenantId] WhereFields tenantId
             * @property {PlatformAdminApi.IStringCondition|null} [userType] WhereFields userType
             * @property {boolean|null} [isVerified] WhereFields isVerified
             * @property {PlatformAdminApi.IStringCondition|null} [firstName] WhereFields firstName
             * @property {PlatformAdminApi.IStringCondition|null} [middleName] WhereFields middleName
             * @property {PlatformAdminApi.IStringCondition|null} [lastName] WhereFields lastName
             * @property {PlatformAdminApi.IDateCondition|null} [birthday] WhereFields birthday
             * @property {PlatformAdminApi.IStringCondition|null} [gender] WhereFields gender
             * @property {boolean|null} [isValid] WhereFields isValid
             * @property {boolean|null} [isBlocked] WhereFields isBlocked
             * @property {Array.<PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IContactsWhereFields>|null} [contacts] WhereFields contacts
             * @property {Array.<PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IPassportsWhereFields>|null} [passports] WhereFields passports
             * @property {Array.<PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IAddressesWhereFields>|null} [addresses] WhereFields addresses
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.ProfileManagementSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                this.contacts = [];
                this.passports = [];
                this.addresses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields tenantId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} tenantId
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.tenantId = null;

            /**
             * WhereFields userType.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} userType
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.userType = null;

            /**
             * WhereFields isVerified.
             * @member {boolean} isVerified
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isVerified = false;

            /**
             * WhereFields firstName.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} firstName
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.firstName = null;

            /**
             * WhereFields middleName.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} middleName
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.middleName = null;

            /**
             * WhereFields lastName.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} lastName
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.lastName = null;

            /**
             * WhereFields birthday.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} birthday
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.birthday = null;

            /**
             * WhereFields gender.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} gender
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.gender = null;

            /**
             * WhereFields isValid.
             * @member {boolean} isValid
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isValid = false;

            /**
             * WhereFields isBlocked.
             * @member {boolean} isBlocked
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isBlocked = false;

            /**
             * WhereFields contacts.
             * @member {Array.<PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IContactsWhereFields>} contacts
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.contacts = $util.emptyArray;

            /**
             * WhereFields passports.
             * @member {Array.<PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IPassportsWhereFields>} passports
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.passports = $util.emptyArray;

            /**
             * WhereFields addresses.
             * @member {Array.<PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IAddressesWhereFields>} addresses
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.addresses = $util.emptyArray;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.ProfileManagementSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.tenantId, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                    $root.PlatformAdminApi.StringCondition.encode(message.userType, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isVerified);
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    $root.PlatformAdminApi.StringCondition.encode(message.firstName, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                    $root.PlatformAdminApi.StringCondition.encode(message.middleName, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    $root.PlatformAdminApi.StringCondition.encode(message.lastName, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                    $root.PlatformAdminApi.DateCondition.encode(message.birthday, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                    $root.PlatformAdminApi.StringCondition.encode(message.gender, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                    writer.uint32(/* id 14, wireType 0 =*/112).bool(message.isValid);
                if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                    writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isBlocked);
                if (message.contacts != null && message.contacts.length)
                    for (var i = 0; i < message.contacts.length; ++i)
                        $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields.encode(message.contacts[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.passports != null && message.passports.length)
                    for (var i = 0; i < message.passports.length; ++i)
                        $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields.encode(message.passports[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.addresses != null && message.addresses.length)
                    for (var i = 0; i < message.addresses.length; ++i)
                        $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields.encode(message.addresses[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.isDeleted = reader.bool();
                        break;
                    case 6:
                        message.tenantId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.userType = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.isVerified = reader.bool();
                        break;
                    case 9:
                        message.firstName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.middleName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.lastName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.birthday = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.gender = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.isValid = reader.bool();
                        break;
                    case 15:
                        message.isBlocked = reader.bool();
                        break;
                    case 16:
                        if (!(message.contacts && message.contacts.length))
                            message.contacts = [];
                        message.contacts.push($root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields.decode(reader, reader.uint32()));
                        break;
                    case 17:
                        if (!(message.passports && message.passports.length))
                            message.passports = [];
                        message.passports.push($root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields.decode(reader, reader.uint32()));
                        break;
                    case 18:
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            WhereFields.ContactsWhereFields = (function() {

                /**
                 * Properties of a ContactsWhereFields.
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
                 * @interface IContactsWhereFields
                 * @property {boolean|null} [isDeleted] ContactsWhereFields isDeleted
                 * @property {PlatformAdminApi.IStringCondition|null} [value] ContactsWhereFields value
                 * @property {PlatformAdminApi.IStringCondition|null} [type] ContactsWhereFields type
                 * @property {boolean|null} [isVerified] ContactsWhereFields isVerified
                 * @property {boolean|null} [isMain] ContactsWhereFields isMain
                 */

                /**
                 * Constructs a new ContactsWhereFields.
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
                 * @classdesc Represents a ContactsWhereFields.
                 * @implements IContactsWhereFields
                 * @constructor
                 * @param {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IContactsWhereFields=} [properties] Properties to set
                 */
                function ContactsWhereFields(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ContactsWhereFields isDeleted.
                 * @member {boolean} isDeleted
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.isDeleted = false;

                /**
                 * ContactsWhereFields value.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} value
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.value = null;

                /**
                 * ContactsWhereFields type.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} type
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.type = null;

                /**
                 * ContactsWhereFields isVerified.
                 * @member {boolean} isVerified
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.isVerified = false;

                /**
                 * ContactsWhereFields isMain.
                 * @member {boolean} isMain
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields
                 * @instance
                 */
                ContactsWhereFields.prototype.isMain = false;

                /**
                 * Encodes the specified ContactsWhereFields message. Does not implicitly {@link PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields.verify|verify} messages.
                 * @function encode
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields
                 * @static
                 * @param {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IContactsWhereFields} message ContactsWhereFields message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ContactsWhereFields.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isDeleted);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        $root.PlatformAdminApi.StringCondition.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        $root.PlatformAdminApi.StringCondition.encode(message.type, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVerified);
                    if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isMain);
                    return writer;
                };

                /**
                 * Decodes a ContactsWhereFields message from the specified reader or buffer.
                 * @function decode
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields} ContactsWhereFields
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ContactsWhereFields.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.ContactsWhereFields();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isDeleted = reader.bool();
                            break;
                        case 2:
                            message.value = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.type = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.isVerified = reader.bool();
                            break;
                        case 5:
                            message.isMain = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return ContactsWhereFields;
            })();

            WhereFields.PassportsWhereFields = (function() {

                /**
                 * Properties of a PassportsWhereFields.
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
                 * @interface IPassportsWhereFields
                 * @property {boolean|null} [isDeleted] PassportsWhereFields isDeleted
                 * @property {PlatformAdminApi.IStringCondition|null} [firstName] PassportsWhereFields firstName
                 * @property {PlatformAdminApi.IStringCondition|null} [middleName] PassportsWhereFields middleName
                 * @property {PlatformAdminApi.IStringCondition|null} [lastName] PassportsWhereFields lastName
                 * @property {PlatformAdminApi.IStringCondition|null} [number] PassportsWhereFields number
                 * @property {PlatformAdminApi.IStringCondition|null} [gender] PassportsWhereFields gender
                 * @property {PlatformAdminApi.IStringCondition|null} [citizenship] PassportsWhereFields citizenship
                 * @property {PlatformAdminApi.IDateCondition|null} [dateStart] PassportsWhereFields dateStart
                 * @property {PlatformAdminApi.IDateCondition|null} [dateEnd] PassportsWhereFields dateEnd
                 * @property {PlatformAdminApi.IStringCondition|null} [issuePlace] PassportsWhereFields issuePlace
                 * @property {PlatformAdminApi.IDateCondition|null} [birthDate] PassportsWhereFields birthDate
                 */

                /**
                 * Constructs a new PassportsWhereFields.
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
                 * @classdesc Represents a PassportsWhereFields.
                 * @implements IPassportsWhereFields
                 * @constructor
                 * @param {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IPassportsWhereFields=} [properties] Properties to set
                 */
                function PassportsWhereFields(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PassportsWhereFields isDeleted.
                 * @member {boolean} isDeleted
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.isDeleted = false;

                /**
                 * PassportsWhereFields firstName.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} firstName
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.firstName = null;

                /**
                 * PassportsWhereFields middleName.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} middleName
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.middleName = null;

                /**
                 * PassportsWhereFields lastName.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} lastName
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.lastName = null;

                /**
                 * PassportsWhereFields number.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} number
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.number = null;

                /**
                 * PassportsWhereFields gender.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} gender
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.gender = null;

                /**
                 * PassportsWhereFields citizenship.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} citizenship
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.citizenship = null;

                /**
                 * PassportsWhereFields dateStart.
                 * @member {PlatformAdminApi.IDateCondition|null|undefined} dateStart
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.dateStart = null;

                /**
                 * PassportsWhereFields dateEnd.
                 * @member {PlatformAdminApi.IDateCondition|null|undefined} dateEnd
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.dateEnd = null;

                /**
                 * PassportsWhereFields issuePlace.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} issuePlace
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.issuePlace = null;

                /**
                 * PassportsWhereFields birthDate.
                 * @member {PlatformAdminApi.IDateCondition|null|undefined} birthDate
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @instance
                 */
                PassportsWhereFields.prototype.birthDate = null;

                /**
                 * Encodes the specified PassportsWhereFields message. Does not implicitly {@link PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields.verify|verify} messages.
                 * @function encode
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @static
                 * @param {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IPassportsWhereFields} message PassportsWhereFields message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PassportsWhereFields.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isDeleted);
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        $root.PlatformAdminApi.StringCondition.encode(message.firstName, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                        $root.PlatformAdminApi.StringCondition.encode(message.middleName, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        $root.PlatformAdminApi.StringCondition.encode(message.lastName, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        $root.PlatformAdminApi.StringCondition.encode(message.number, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                        $root.PlatformAdminApi.StringCondition.encode(message.gender, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.citizenship != null && Object.hasOwnProperty.call(message, "citizenship"))
                        $root.PlatformAdminApi.StringCondition.encode(message.citizenship, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.dateStart != null && Object.hasOwnProperty.call(message, "dateStart"))
                        $root.PlatformAdminApi.DateCondition.encode(message.dateStart, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.dateEnd != null && Object.hasOwnProperty.call(message, "dateEnd"))
                        $root.PlatformAdminApi.DateCondition.encode(message.dateEnd, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.issuePlace != null && Object.hasOwnProperty.call(message, "issuePlace"))
                        $root.PlatformAdminApi.StringCondition.encode(message.issuePlace, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.birthDate != null && Object.hasOwnProperty.call(message, "birthDate"))
                        $root.PlatformAdminApi.DateCondition.encode(message.birthDate, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a PassportsWhereFields message from the specified reader or buffer.
                 * @function decode
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields} PassportsWhereFields
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PassportsWhereFields.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.PassportsWhereFields();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isDeleted = reader.bool();
                            break;
                        case 2:
                            message.firstName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.middleName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.lastName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.number = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.gender = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.citizenship = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 8:
                            message.dateStart = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                            break;
                        case 9:
                            message.dateEnd = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                            break;
                        case 10:
                            message.issuePlace = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 11:
                            message.birthDate = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return PassportsWhereFields;
            })();

            WhereFields.AddressesWhereFields = (function() {

                /**
                 * Properties of an AddressesWhereFields.
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
                 * @interface IAddressesWhereFields
                 * @property {boolean|null} [isDeleted] AddressesWhereFields isDeleted
                 * @property {PlatformAdminApi.IStringCondition|null} [label] AddressesWhereFields label
                 * @property {PlatformAdminApi.IStringCondition|null} [value] AddressesWhereFields value
                 * @property {PlatformAdminApi.IStringCondition|null} [unrestrictedValue] AddressesWhereFields unrestrictedValue
                 * @property {PlatformAdminApi.IStringCondition|null} [type] AddressesWhereFields type
                 */

                /**
                 * Constructs a new AddressesWhereFields.
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields
                 * @classdesc Represents an AddressesWhereFields.
                 * @implements IAddressesWhereFields
                 * @constructor
                 * @param {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IAddressesWhereFields=} [properties] Properties to set
                 */
                function AddressesWhereFields(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AddressesWhereFields isDeleted.
                 * @member {boolean} isDeleted
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields
                 * @instance
                 */
                AddressesWhereFields.prototype.isDeleted = false;

                /**
                 * AddressesWhereFields label.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} label
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields
                 * @instance
                 */
                AddressesWhereFields.prototype.label = null;

                /**
                 * AddressesWhereFields value.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} value
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields
                 * @instance
                 */
                AddressesWhereFields.prototype.value = null;

                /**
                 * AddressesWhereFields unrestrictedValue.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} unrestrictedValue
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields
                 * @instance
                 */
                AddressesWhereFields.prototype.unrestrictedValue = null;

                /**
                 * AddressesWhereFields type.
                 * @member {PlatformAdminApi.IStringCondition|null|undefined} type
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields
                 * @instance
                 */
                AddressesWhereFields.prototype.type = null;

                /**
                 * Encodes the specified AddressesWhereFields message. Does not implicitly {@link PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields.verify|verify} messages.
                 * @function encode
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields
                 * @static
                 * @param {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.IAddressesWhereFields} message AddressesWhereFields message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressesWhereFields.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isDeleted);
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        $root.PlatformAdminApi.StringCondition.encode(message.label, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        $root.PlatformAdminApi.StringCondition.encode(message.value, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                        $root.PlatformAdminApi.StringCondition.encode(message.unrestrictedValue, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        $root.PlatformAdminApi.StringCondition.encode(message.type, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes an AddressesWhereFields message from the specified reader or buffer.
                 * @function decode
                 * @memberof PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields} AddressesWhereFields
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressesWhereFields.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileManagementSearchRequest.WhereFields.AddressesWhereFields();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isDeleted = reader.bool();
                            break;
                        case 2:
                            message.label = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.value = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.unrestrictedValue = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.type = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return AddressesWhereFields;
            })();

            return WhereFields;
        })();

        return ProfileManagementSearchRequest;
    })();

    PlatformAdminApi.ProfileSearchResponse = (function() {

        /**
         * Properties of a ProfileSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IProfileSearchResponse
         * @property {Array.<PlatformAdminApi.IUser>|null} [data] ProfileSearchResponse data
         * @property {number|null} [page] ProfileSearchResponse page
         * @property {number|null} [limit] ProfileSearchResponse limit
         * @property {number|null} [total] ProfileSearchResponse total
         */

        /**
         * Constructs a new ProfileSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileSearchResponse.
         * @implements IProfileSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IProfileSearchResponse=} [properties] Properties to set
         */
        function ProfileSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileSearchResponse data.
         * @member {Array.<PlatformAdminApi.IUser>} data
         * @memberof PlatformAdminApi.ProfileSearchResponse
         * @instance
         */
        ProfileSearchResponse.prototype.data = $util.emptyArray;

        /**
         * ProfileSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ProfileSearchResponse
         * @instance
         */
        ProfileSearchResponse.prototype.page = 0;

        /**
         * ProfileSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ProfileSearchResponse
         * @instance
         */
        ProfileSearchResponse.prototype.limit = 0;

        /**
         * ProfileSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ProfileSearchResponse
         * @instance
         */
        ProfileSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified ProfileSearchResponse message. Does not implicitly {@link PlatformAdminApi.ProfileSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileSearchResponse
         * @static
         * @param {PlatformAdminApi.IProfileSearchResponse} message ProfileSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.User.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ProfileSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileSearchResponse} ProfileSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.User.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProfileSearchResponse;
    })();

    PlatformAdminApi.ProfileCreateRequest = (function() {

        /**
         * Properties of a ProfileCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IProfileCreateRequest
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] ProfileCreateRequest userType
         * @property {boolean|null} [isVerified] ProfileCreateRequest isVerified
         * @property {boolean|null} [isBlocked] ProfileCreateRequest isBlocked
         * @property {string|null} [firstName] ProfileCreateRequest firstName
         * @property {string|null} [middleName] ProfileCreateRequest middleName
         * @property {string|null} [lastName] ProfileCreateRequest lastName
         * @property {boolean|null} [isValid] ProfileCreateRequest isValid
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] ProfileCreateRequest gender
         * @property {string|null} [birthday] ProfileCreateRequest birthday
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] ProfileCreateRequest object
         * @property {Array.<PlatformAdminApi.IContactRel>|null} [contacts] ProfileCreateRequest contacts
         * @property {PlatformAdminApi.IPassportRel|null} [passport] ProfileCreateRequest passport
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] ProfileCreateRequest addresses
         * @property {PlatformAdminApi.IAddPhotoManagementRequest|null} [photo] ProfileCreateRequest photo
         */

        /**
         * Constructs a new ProfileCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileCreateRequest.
         * @implements IProfileCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IProfileCreateRequest=} [properties] Properties to set
         */
        function ProfileCreateRequest(properties) {
            this.contacts = [];
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileCreateRequest userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.userType = 0;

        /**
         * ProfileCreateRequest isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.isVerified = false;

        /**
         * ProfileCreateRequest isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.isBlocked = false;

        /**
         * ProfileCreateRequest firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.firstName = "";

        /**
         * ProfileCreateRequest middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.middleName = "";

        /**
         * ProfileCreateRequest lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.lastName = "";

        /**
         * ProfileCreateRequest isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.isValid = false;

        /**
         * ProfileCreateRequest gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.gender = 0;

        /**
         * ProfileCreateRequest birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.birthday = "";

        /**
         * ProfileCreateRequest object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.object = null;

        /**
         * ProfileCreateRequest contacts.
         * @member {Array.<PlatformAdminApi.IContactRel>} contacts
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.contacts = $util.emptyArray;

        /**
         * ProfileCreateRequest passport.
         * @member {PlatformAdminApi.IPassportRel|null|undefined} passport
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.passport = null;

        /**
         * ProfileCreateRequest addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.addresses = $util.emptyArray;

        /**
         * ProfileCreateRequest photo.
         * @member {PlatformAdminApi.IAddPhotoManagementRequest|null|undefined} photo
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @instance
         */
        ProfileCreateRequest.prototype.photo = null;

        /**
         * Encodes the specified ProfileCreateRequest message. Does not implicitly {@link PlatformAdminApi.ProfileCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @static
         * @param {PlatformAdminApi.IProfileCreateRequest} message ProfileCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.userType);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isVerified);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isBlocked);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.middleName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.lastName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.contacts != null && message.contacts.length)
                for (var i = 0; i < message.contacts.length; ++i)
                    $root.PlatformAdminApi.ContactRel.encode(message.contacts[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.passport != null && Object.hasOwnProperty.call(message, "passport"))
                $root.PlatformAdminApi.PassportRel.encode(message.passport, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.photo != null && Object.hasOwnProperty.call(message, "photo"))
                $root.PlatformAdminApi.AddPhotoManagementRequest.encode(message.photo, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfileCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileCreateRequest} ProfileCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userType = reader.int32();
                    break;
                case 2:
                    message.isVerified = reader.bool();
                    break;
                case 3:
                    message.isBlocked = reader.bool();
                    break;
                case 4:
                    message.firstName = reader.string();
                    break;
                case 5:
                    message.middleName = reader.string();
                    break;
                case 6:
                    message.lastName = reader.string();
                    break;
                case 7:
                    message.isValid = reader.bool();
                    break;
                case 8:
                    message.gender = reader.int32();
                    break;
                case 9:
                    message.birthday = reader.string();
                    break;
                case 10:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 11:
                    if (!(message.contacts && message.contacts.length))
                        message.contacts = [];
                    message.contacts.push($root.PlatformAdminApi.ContactRel.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.passport = $root.PlatformAdminApi.PassportRel.decode(reader, reader.uint32());
                    break;
                case 13:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.photo = $root.PlatformAdminApi.AddPhotoManagementRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProfileCreateRequest;
    })();

    PlatformAdminApi.ProfileUpdateRequest = (function() {

        /**
         * Properties of a ProfileUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IProfileUpdateRequest
         * @property {string|null} [id] ProfileUpdateRequest id
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] ProfileUpdateRequest userType
         * @property {boolean|null} [isVerified] ProfileUpdateRequest isVerified
         * @property {boolean|null} [isBlocked] ProfileUpdateRequest isBlocked
         * @property {string|null} [firstName] ProfileUpdateRequest firstName
         * @property {string|null} [middleName] ProfileUpdateRequest middleName
         * @property {string|null} [lastName] ProfileUpdateRequest lastName
         * @property {boolean|null} [isValid] ProfileUpdateRequest isValid
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] ProfileUpdateRequest gender
         * @property {string|null} [birthday] ProfileUpdateRequest birthday
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] ProfileUpdateRequest object
         * @property {Array.<PlatformAdminApi.IContactRel>|null} [contacts] ProfileUpdateRequest contacts
         * @property {Array.<PlatformAdminApi.IPassportRel>|null} [passports] ProfileUpdateRequest passports
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] ProfileUpdateRequest addresses
         * @property {PlatformAdminApi.IAddPhotoManagementRequest|null} [photo] ProfileUpdateRequest photo
         * @property {google.protobuf.IFieldMask|null} [updateMask] ProfileUpdateRequest updateMask
         */

        /**
         * Constructs a new ProfileUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProfileUpdateRequest.
         * @implements IProfileUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IProfileUpdateRequest=} [properties] Properties to set
         */
        function ProfileUpdateRequest(properties) {
            this.contacts = [];
            this.passports = [];
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.id = "";

        /**
         * ProfileUpdateRequest userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.userType = 0;

        /**
         * ProfileUpdateRequest isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.isVerified = false;

        /**
         * ProfileUpdateRequest isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.isBlocked = false;

        /**
         * ProfileUpdateRequest firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.firstName = "";

        /**
         * ProfileUpdateRequest middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.middleName = "";

        /**
         * ProfileUpdateRequest lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.lastName = "";

        /**
         * ProfileUpdateRequest isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.isValid = false;

        /**
         * ProfileUpdateRequest gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.gender = 0;

        /**
         * ProfileUpdateRequest birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.birthday = "";

        /**
         * ProfileUpdateRequest object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.object = null;

        /**
         * ProfileUpdateRequest contacts.
         * @member {Array.<PlatformAdminApi.IContactRel>} contacts
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.contacts = $util.emptyArray;

        /**
         * ProfileUpdateRequest passports.
         * @member {Array.<PlatformAdminApi.IPassportRel>} passports
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.passports = $util.emptyArray;

        /**
         * ProfileUpdateRequest addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.addresses = $util.emptyArray;

        /**
         * ProfileUpdateRequest photo.
         * @member {PlatformAdminApi.IAddPhotoManagementRequest|null|undefined} photo
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.photo = null;

        /**
         * ProfileUpdateRequest updateMask.
         * @member {google.protobuf.IFieldMask|null|undefined} updateMask
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @instance
         */
        ProfileUpdateRequest.prototype.updateMask = null;

        /**
         * Encodes the specified ProfileUpdateRequest message. Does not implicitly {@link PlatformAdminApi.ProfileUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @static
         * @param {PlatformAdminApi.IProfileUpdateRequest} message ProfileUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userType);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isVerified);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isBlocked);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.middleName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.lastName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.contacts != null && message.contacts.length)
                for (var i = 0; i < message.contacts.length; ++i)
                    $root.PlatformAdminApi.ContactRel.encode(message.contacts[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.passports != null && message.passports.length)
                for (var i = 0; i < message.passports.length; ++i)
                    $root.PlatformAdminApi.PassportRel.encode(message.passports[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.photo != null && Object.hasOwnProperty.call(message, "photo"))
                $root.PlatformAdminApi.AddPhotoManagementRequest.encode(message.photo, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.updateMask != null && Object.hasOwnProperty.call(message, "updateMask"))
                $root.google.protobuf.FieldMask.encode(message.updateMask, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProfileUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProfileUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProfileUpdateRequest} ProfileUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProfileUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userType = reader.int32();
                    break;
                case 3:
                    message.isVerified = reader.bool();
                    break;
                case 4:
                    message.isBlocked = reader.bool();
                    break;
                case 5:
                    message.firstName = reader.string();
                    break;
                case 6:
                    message.middleName = reader.string();
                    break;
                case 7:
                    message.lastName = reader.string();
                    break;
                case 8:
                    message.isValid = reader.bool();
                    break;
                case 9:
                    message.gender = reader.int32();
                    break;
                case 10:
                    message.birthday = reader.string();
                    break;
                case 11:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 12:
                    if (!(message.contacts && message.contacts.length))
                        message.contacts = [];
                    message.contacts.push($root.PlatformAdminApi.ContactRel.decode(reader, reader.uint32()));
                    break;
                case 13:
                    if (!(message.passports && message.passports.length))
                        message.passports = [];
                    message.passports.push($root.PlatformAdminApi.PassportRel.decode(reader, reader.uint32()));
                    break;
                case 14:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                case 15:
                    message.photo = $root.PlatformAdminApi.AddPhotoManagementRequest.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.updateMask = $root.google.protobuf.FieldMask.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProfileUpdateRequest;
    })();

    PlatformAdminApi.AddPhotoManagementRequest = (function() {

        /**
         * Properties of an AddPhotoManagementRequest.
         * @memberof PlatformAdminApi
         * @interface IAddPhotoManagementRequest
         * @property {string|null} [name] AddPhotoManagementRequest name
         * @property {string|null} [original] AddPhotoManagementRequest original
         * @property {string|null} [id] AddPhotoManagementRequest id
         */

        /**
         * Constructs a new AddPhotoManagementRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AddPhotoManagementRequest.
         * @implements IAddPhotoManagementRequest
         * @constructor
         * @param {PlatformAdminApi.IAddPhotoManagementRequest=} [properties] Properties to set
         */
        function AddPhotoManagementRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddPhotoManagementRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.AddPhotoManagementRequest
         * @instance
         */
        AddPhotoManagementRequest.prototype.name = "";

        /**
         * AddPhotoManagementRequest original.
         * @member {string} original
         * @memberof PlatformAdminApi.AddPhotoManagementRequest
         * @instance
         */
        AddPhotoManagementRequest.prototype.original = "";

        /**
         * AddPhotoManagementRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.AddPhotoManagementRequest
         * @instance
         */
        AddPhotoManagementRequest.prototype.id = "";

        /**
         * Encodes the specified AddPhotoManagementRequest message. Does not implicitly {@link PlatformAdminApi.AddPhotoManagementRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AddPhotoManagementRequest
         * @static
         * @param {PlatformAdminApi.IAddPhotoManagementRequest} message AddPhotoManagementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPhotoManagementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.original);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            return writer;
        };

        /**
         * Decodes an AddPhotoManagementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AddPhotoManagementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AddPhotoManagementRequest} AddPhotoManagementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPhotoManagementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AddPhotoManagementRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.original = reader.string();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddPhotoManagementRequest;
    })();

    PlatformAdminApi.PhotoManagement = (function() {

        /**
         * Properties of a PhotoManagement.
         * @memberof PlatformAdminApi
         * @interface IPhotoManagement
         * @property {string|null} [id] PhotoManagement id
         * @property {string|null} [tag] PhotoManagement tag
         * @property {string|null} [userId] PhotoManagement userId
         * @property {string|null} [original] PhotoManagement original
         * @property {string|null} [name] PhotoManagement name
         * @property {string|null} [url] PhotoManagement url
         * @property {string|null} [thumb] PhotoManagement thumb
         * @property {boolean|null} [isDeleted] PhotoManagement isDeleted
         */

        /**
         * Constructs a new PhotoManagement.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PhotoManagement.
         * @implements IPhotoManagement
         * @constructor
         * @param {PlatformAdminApi.IPhotoManagement=} [properties] Properties to set
         */
        function PhotoManagement(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhotoManagement id.
         * @member {string} id
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.id = "";

        /**
         * PhotoManagement tag.
         * @member {string} tag
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.tag = "";

        /**
         * PhotoManagement userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.userId = "";

        /**
         * PhotoManagement original.
         * @member {string} original
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.original = "";

        /**
         * PhotoManagement name.
         * @member {string} name
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.name = "";

        /**
         * PhotoManagement url.
         * @member {string} url
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.url = "";

        /**
         * PhotoManagement thumb.
         * @member {string} thumb
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.thumb = "";

        /**
         * PhotoManagement isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.isDeleted = false;

        /**
         * Encodes the specified PhotoManagement message. Does not implicitly {@link PlatformAdminApi.PhotoManagement.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PhotoManagement
         * @static
         * @param {PlatformAdminApi.IPhotoManagement} message PhotoManagement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhotoManagement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tag);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.original);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
            if (message.thumb != null && Object.hasOwnProperty.call(message, "thumb"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.thumb);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a PhotoManagement message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PhotoManagement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PhotoManagement} PhotoManagement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhotoManagement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PhotoManagement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.tag = reader.string();
                    break;
                case 3:
                    message.userId = reader.string();
                    break;
                case 4:
                    message.original = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.url = reader.string();
                    break;
                case 7:
                    message.thumb = reader.string();
                    break;
                case 8:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PhotoManagement;
    })();

    PlatformAdminApi.ContractorProfile = (function() {

        /**
         * Properties of a ContractorProfile.
         * @memberof PlatformAdminApi
         * @interface IContractorProfile
         * @property {string|null} [createdAt] ContractorProfile createdAt
         * @property {string|null} [updatedAt] ContractorProfile updatedAt
         * @property {boolean|null} [isDeleted] ContractorProfile isDeleted
         * @property {string|null} [deletedAt] ContractorProfile deletedAt
         * @property {string|null} [tenantId] ContractorProfile tenantId
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] ContractorProfile userType
         * @property {boolean|null} [isVerified] ContractorProfile isVerified
         * @property {boolean|null} [isBlocked] ContractorProfile isBlocked
         * @property {string|null} [firstName] ContractorProfile firstName
         * @property {string|null} [middleName] ContractorProfile middleName
         * @property {string|null} [lastName] ContractorProfile lastName
         * @property {boolean|null} [isValid] ContractorProfile isValid
         * @property {string|null} [gender] ContractorProfile gender
         * @property {string|null} [birthday] ContractorProfile birthday
         * @property {PlatformAdminApi.IDadataFioObject|null} [object] ContractorProfile object
         * @property {Array.<PlatformAdminApi.IContact>|null} [contacts] ContractorProfile contacts
         * @property {Array.<PlatformAdminApi.IPassport>|null} [passports] ContractorProfile passports
         * @property {Array.<PlatformAdminApi.IAddress>|null} [addresses] ContractorProfile addresses
         * @property {PlatformAdminApi.IPhotoManagement|null} [photo] ContractorProfile photo
         */

        /**
         * Constructs a new ContractorProfile.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorProfile.
         * @implements IContractorProfile
         * @constructor
         * @param {PlatformAdminApi.IContractorProfile=} [properties] Properties to set
         */
        function ContractorProfile(properties) {
            this.contacts = [];
            this.passports = [];
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorProfile createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.createdAt = "";

        /**
         * ContractorProfile updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.updatedAt = "";

        /**
         * ContractorProfile isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.isDeleted = false;

        /**
         * ContractorProfile deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.deletedAt = "";

        /**
         * ContractorProfile tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.tenantId = "";

        /**
         * ContractorProfile userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.userType = 0;

        /**
         * ContractorProfile isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.isVerified = false;

        /**
         * ContractorProfile isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.isBlocked = false;

        /**
         * ContractorProfile firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.firstName = "";

        /**
         * ContractorProfile middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.middleName = "";

        /**
         * ContractorProfile lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.lastName = "";

        /**
         * ContractorProfile isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.isValid = false;

        /**
         * ContractorProfile gender.
         * @member {string} gender
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.gender = "";

        /**
         * ContractorProfile birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.birthday = "";

        /**
         * ContractorProfile object.
         * @member {PlatformAdminApi.IDadataFioObject|null|undefined} object
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.object = null;

        /**
         * ContractorProfile contacts.
         * @member {Array.<PlatformAdminApi.IContact>} contacts
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.contacts = $util.emptyArray;

        /**
         * ContractorProfile passports.
         * @member {Array.<PlatformAdminApi.IPassport>} passports
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.passports = $util.emptyArray;

        /**
         * ContractorProfile addresses.
         * @member {Array.<PlatformAdminApi.IAddress>} addresses
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.addresses = $util.emptyArray;

        /**
         * ContractorProfile photo.
         * @member {PlatformAdminApi.IPhotoManagement|null|undefined} photo
         * @memberof PlatformAdminApi.ContractorProfile
         * @instance
         */
        ContractorProfile.prototype.photo = null;

        /**
         * Encodes the specified ContractorProfile message. Does not implicitly {@link PlatformAdminApi.ContractorProfile.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorProfile
         * @static
         * @param {PlatformAdminApi.IContractorProfile} message ContractorProfile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorProfile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.tenantId);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.userType);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isVerified);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isBlocked);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.middleName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.lastName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.gender);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFioObject.encode(message.object, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.contacts != null && message.contacts.length)
                for (var i = 0; i < message.contacts.length; ++i)
                    $root.PlatformAdminApi.Contact.encode(message.contacts[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.passports != null && message.passports.length)
                for (var i = 0; i < message.passports.length; ++i)
                    $root.PlatformAdminApi.Passport.encode(message.passports[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.Address.encode(message.addresses[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.photo != null && Object.hasOwnProperty.call(message, "photo"))
                $root.PlatformAdminApi.PhotoManagement.encode(message.photo, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorProfile message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorProfile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorProfile} ContractorProfile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorProfile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorProfile();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.tenantId = reader.string();
                    break;
                case 7:
                    message.userType = reader.int32();
                    break;
                case 8:
                    message.isVerified = reader.bool();
                    break;
                case 9:
                    message.isBlocked = reader.bool();
                    break;
                case 10:
                    message.firstName = reader.string();
                    break;
                case 11:
                    message.middleName = reader.string();
                    break;
                case 12:
                    message.lastName = reader.string();
                    break;
                case 13:
                    message.isValid = reader.bool();
                    break;
                case 14:
                    message.gender = reader.string();
                    break;
                case 15:
                    message.birthday = reader.string();
                    break;
                case 16:
                    message.object = $root.PlatformAdminApi.DadataFioObject.decode(reader, reader.uint32());
                    break;
                case 17:
                    if (!(message.contacts && message.contacts.length))
                        message.contacts = [];
                    message.contacts.push($root.PlatformAdminApi.Contact.decode(reader, reader.uint32()));
                    break;
                case 18:
                    if (!(message.passports && message.passports.length))
                        message.passports = [];
                    message.passports.push($root.PlatformAdminApi.Passport.decode(reader, reader.uint32()));
                    break;
                case 19:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.Address.decode(reader, reader.uint32()));
                    break;
                case 20:
                    message.photo = $root.PlatformAdminApi.PhotoManagement.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorProfile;
    })();

    PlatformAdminApi.TenantService = (function() {

        /**
         * Constructs a new TenantService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TenantService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function TenantService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (TenantService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TenantService;

        /**
         * Callback as used by {@link PlatformAdminApi.TenantService#search}.
         * @memberof PlatformAdminApi.TenantService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantSearchResponse} [response] TenantSearchResponse
         */

        /**
         * Запрос на поиск массива объектов Tenant по параметрам
         * @function search
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.ITenantSearchRequest} request TenantSearchRequest message or plain object
         * @param {PlatformAdminApi.TenantService.SearchCallback} callback Node-style callback called with the error, if any, and TenantSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TenantService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.TenantSearchRequest, $root.PlatformAdminApi.TenantSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Запрос на поиск массива объектов Tenant по параметрам
         * @function search
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.ITenantSearchRequest} request TenantSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TenantSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TenantService#create}.
         * @memberof PlatformAdminApi.TenantService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantResponse} [response] TenantResponse
         */

        /**
         * Запрос на создание объекта Tenant
         * @function create
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.ITenantCreateRequest} request TenantCreateRequest message or plain object
         * @param {PlatformAdminApi.TenantService.CreateCallback} callback Node-style callback called with the error, if any, and TenantResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TenantService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.TenantCreateRequest, $root.PlatformAdminApi.TenantResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Запрос на создание объекта Tenant
         * @function create
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.ITenantCreateRequest} request TenantCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TenantResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TenantService#update}.
         * @memberof PlatformAdminApi.TenantService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantResponse} [response] TenantResponse
         */

        /**
         * Запрос на изменение объекта Tenant по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.ITenantUpdateRequest} request TenantUpdateRequest message or plain object
         * @param {PlatformAdminApi.TenantService.UpdateCallback} callback Node-style callback called with the error, if any, and TenantResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TenantService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.TenantUpdateRequest, $root.PlatformAdminApi.TenantResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Запрос на изменение объекта Tenant по указанному в аргументе id
         * @function update
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.ITenantUpdateRequest} request TenantUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TenantResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TenantService#findById}.
         * @memberof PlatformAdminApi.TenantService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantResponse} [response] TenantResponse
         */

        /**
         * Запрос на получение объекта Tenant по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.TenantService.FindByIdCallback} callback Node-style callback called with the error, if any, and TenantResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TenantService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.TenantResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Запрос на получение объекта Tenant по указанному в аргументе id
         * @function findById
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TenantResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TenantService#remove}.
         * @memberof PlatformAdminApi.TenantService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantResponse} [response] TenantResponse
         */

        /**
         * Запрос на удаление объекта Tenant по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.TenantService.RemoveCallback} callback Node-style callback called with the error, if any, and TenantResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TenantService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.TenantResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Запрос на удаление объекта Tenant по указанному в аргументе id
         * @function remove
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TenantResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TenantService#restore}.
         * @memberof PlatformAdminApi.TenantService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantResponse} [response] TenantResponse
         */

        /**
         * Запрос на получение объекта Tenant по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.TenantService.RestoreCallback} callback Node-style callback called with the error, if any, and TenantResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TenantService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.TenantResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Запрос на получение объекта Tenant по указанному в аргументе id
         * @function restore
         * @memberof PlatformAdminApi.TenantService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TenantResponse>} Promise
         * @variation 2
         */

        return TenantService;
    })();

    PlatformAdminApi.TenantSearchRequest = (function() {

        /**
         * Properties of a TenantSearchRequest.
         * @memberof PlatformAdminApi
         * @interface ITenantSearchRequest
         * @property {number|null} [page] TenantSearchRequest page
         * @property {number|null} [limit] TenantSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] TenantSearchRequest sort
         * @property {Array.<PlatformAdminApi.TenantSearchRequest.IWhereFields>|null} [where] TenantSearchRequest where
         */

        /**
         * Constructs a new TenantSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса списка арендаторов
         * @implements ITenantSearchRequest
         * @constructor
         * @param {PlatformAdminApi.ITenantSearchRequest=} [properties] Properties to set
         */
        function TenantSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.TenantSearchRequest
         * @instance
         */
        TenantSearchRequest.prototype.page = 0;

        /**
         * TenantSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.TenantSearchRequest
         * @instance
         */
        TenantSearchRequest.prototype.limit = 0;

        /**
         * TenantSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.TenantSearchRequest
         * @instance
         */
        TenantSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * TenantSearchRequest where.
         * @member {Array.<PlatformAdminApi.TenantSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.TenantSearchRequest
         * @instance
         */
        TenantSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified TenantSearchRequest message. Does not implicitly {@link PlatformAdminApi.TenantSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantSearchRequest
         * @static
         * @param {PlatformAdminApi.ITenantSearchRequest} message TenantSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.TenantSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TenantSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantSearchRequest} TenantSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.TenantSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        TenantSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.TenantSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {PlatformAdminApi.IStringCondition|null} [email] WhereFields email
             * @property {PlatformAdminApi.IStringCondition|null} [type] WhereFields type
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.TenantSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.TenantSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields email.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} email
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.email = null;

            /**
             * WhereFields type.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} type
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.type = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.TenantSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.TenantSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    $root.PlatformAdminApi.StringCondition.encode(message.email, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    $root.PlatformAdminApi.StringCondition.encode(message.type, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.TenantSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.TenantSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.isDeleted = reader.bool();
                        break;
                    case 6:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.email = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.type = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return TenantSearchRequest;
    })();

    PlatformAdminApi.TenantSearchResponse = (function() {

        /**
         * Properties of a TenantSearchResponse.
         * @memberof PlatformAdminApi
         * @interface ITenantSearchResponse
         * @property {Array.<PlatformAdminApi.ITenant>|null} [data] TenantSearchResponse data
         * @property {number|null} [page] TenantSearchResponse page
         * @property {number|null} [limit] TenantSearchResponse limit
         * @property {number|null} [total] TenantSearchResponse total
         */

        /**
         * Constructs a new TenantSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE при запросе для всех арендаторов
         * @implements ITenantSearchResponse
         * @constructor
         * @param {PlatformAdminApi.ITenantSearchResponse=} [properties] Properties to set
         */
        function TenantSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantSearchResponse data.
         * @member {Array.<PlatformAdminApi.ITenant>} data
         * @memberof PlatformAdminApi.TenantSearchResponse
         * @instance
         */
        TenantSearchResponse.prototype.data = $util.emptyArray;

        /**
         * TenantSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.TenantSearchResponse
         * @instance
         */
        TenantSearchResponse.prototype.page = 0;

        /**
         * TenantSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.TenantSearchResponse
         * @instance
         */
        TenantSearchResponse.prototype.limit = 0;

        /**
         * TenantSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.TenantSearchResponse
         * @instance
         */
        TenantSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified TenantSearchResponse message. Does not implicitly {@link PlatformAdminApi.TenantSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantSearchResponse
         * @static
         * @param {PlatformAdminApi.ITenantSearchResponse} message TenantSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Tenant.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a TenantSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantSearchResponse} TenantSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Tenant.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TenantSearchResponse;
    })();

    PlatformAdminApi.TenantResponse = (function() {

        /**
         * Properties of a TenantResponse.
         * @memberof PlatformAdminApi
         * @interface ITenantResponse
         * @property {PlatformAdminApi.ITenant|null} [data] TenantResponse data
         */

        /**
         * Constructs a new TenantResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TenantResponse.
         * @implements ITenantResponse
         * @constructor
         * @param {PlatformAdminApi.ITenantResponse=} [properties] Properties to set
         */
        function TenantResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantResponse data.
         * @member {PlatformAdminApi.ITenant|null|undefined} data
         * @memberof PlatformAdminApi.TenantResponse
         * @instance
         */
        TenantResponse.prototype.data = null;

        /**
         * Encodes the specified TenantResponse message. Does not implicitly {@link PlatformAdminApi.TenantResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantResponse
         * @static
         * @param {PlatformAdminApi.ITenantResponse} message TenantResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Tenant.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TenantResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantResponse} TenantResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TenantResponse;
    })();

    PlatformAdminApi.Tenant = (function() {

        /**
         * Properties of a Tenant.
         * @memberof PlatformAdminApi
         * @interface ITenant
         * @property {string|null} [name] Tenant name
         * @property {string|null} [email] Tenant email
         * @property {string|null} [parentId] Tenant parentId
         * @property {string|null} [createdAt] Tenant createdAt
         * @property {string|null} [updatedAt] Tenant updatedAt
         * @property {boolean|null} [isDeleted] Tenant isDeleted
         * @property {string|null} [deletedAt] Tenant deletedAt
         * @property {string|null} [id] Tenant id
         * @property {number|null} [type] Tenant type
         * @property {string|null} [jwksUrl] Tenant jwksUrl
         * @property {PlatformAdminApi.IJWKS|null} [jwks] Tenant jwks
         * @property {string|null} [ecosystemId] Tenant ecosystemId
         */

        /**
         * Constructs a new Tenant.
         * @memberof PlatformAdminApi
         * @classdesc Модель Tenant
         * @implements ITenant
         * @constructor
         * @param {PlatformAdminApi.ITenant=} [properties] Properties to set
         */
        function Tenant(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Tenant name.
         * @member {string} name
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.name = "";

        /**
         * Tenant email.
         * @member {string} email
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.email = "";

        /**
         * Tenant parentId.
         * @member {string} parentId
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.parentId = "";

        /**
         * Tenant createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.createdAt = "";

        /**
         * Tenant updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.updatedAt = "";

        /**
         * Tenant isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.isDeleted = false;

        /**
         * Tenant deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.deletedAt = "";

        /**
         * Tenant id.
         * @member {string} id
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.id = "";

        /**
         * Tenant type.
         * @member {number} type
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.type = 0;

        /**
         * Tenant jwksUrl.
         * @member {string} jwksUrl
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.jwksUrl = "";

        /**
         * Tenant jwks.
         * @member {PlatformAdminApi.IJWKS|null|undefined} jwks
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.jwks = null;

        /**
         * Tenant ecosystemId.
         * @member {string} ecosystemId
         * @memberof PlatformAdminApi.Tenant
         * @instance
         */
        Tenant.prototype.ecosystemId = "";

        /**
         * Encodes the specified Tenant message. Does not implicitly {@link PlatformAdminApi.Tenant.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Tenant
         * @static
         * @param {PlatformAdminApi.ITenant} message Tenant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tenant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.parentId != null && Object.hasOwnProperty.call(message, "parentId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.parentId);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.type);
            if (message.jwksUrl != null && Object.hasOwnProperty.call(message, "jwksUrl"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.jwksUrl);
            if (message.jwks != null && Object.hasOwnProperty.call(message, "jwks"))
                $root.PlatformAdminApi.JWKS.encode(message.jwks, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.ecosystemId != null && Object.hasOwnProperty.call(message, "ecosystemId"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.ecosystemId);
            return writer;
        };

        /**
         * Decodes a Tenant message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Tenant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Tenant} Tenant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tenant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Tenant();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.parentId = reader.string();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                case 8:
                    message.id = reader.string();
                    break;
                case 9:
                    message.type = reader.int32();
                    break;
                case 11:
                    message.jwksUrl = reader.string();
                    break;
                case 12:
                    message.jwks = $root.PlatformAdminApi.JWKS.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.ecosystemId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Tenant;
    })();

    PlatformAdminApi.JWKS = (function() {

        /**
         * Properties of a JWKS.
         * @memberof PlatformAdminApi
         * @interface IJWKS
         * @property {Array.<PlatformAdminApi.IKeys>|null} [keys] JWKS keys
         */

        /**
         * Constructs a new JWKS.
         * @memberof PlatformAdminApi
         * @classdesc Represents a JWKS.
         * @implements IJWKS
         * @constructor
         * @param {PlatformAdminApi.IJWKS=} [properties] Properties to set
         */
        function JWKS(properties) {
            this.keys = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JWKS keys.
         * @member {Array.<PlatformAdminApi.IKeys>} keys
         * @memberof PlatformAdminApi.JWKS
         * @instance
         */
        JWKS.prototype.keys = $util.emptyArray;

        /**
         * Encodes the specified JWKS message. Does not implicitly {@link PlatformAdminApi.JWKS.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.JWKS
         * @static
         * @param {PlatformAdminApi.IJWKS} message JWKS message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JWKS.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keys != null && message.keys.length)
                for (var i = 0; i < message.keys.length; ++i)
                    $root.PlatformAdminApi.Keys.encode(message.keys[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a JWKS message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.JWKS
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.JWKS} JWKS
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JWKS.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.JWKS();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.keys && message.keys.length))
                        message.keys = [];
                    message.keys.push($root.PlatformAdminApi.Keys.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return JWKS;
    })();

    PlatformAdminApi.Keys = (function() {

        /**
         * Properties of a Keys.
         * @memberof PlatformAdminApi
         * @interface IKeys
         * @property {string|null} [e] Keys e
         * @property {string|null} [n] Keys n
         * @property {string|null} [d] Keys d
         * @property {string|null} [p] Keys p
         * @property {string|null} [q] Keys q
         * @property {string|null} [dp] Keys dp
         * @property {string|null} [dq] Keys dq
         * @property {string|null} [qi] Keys qi
         * @property {string|null} [kty] Keys kty
         * @property {string|null} [kid] Keys kid
         * @property {string|null} [alg] Keys alg
         * @property {Array.<string>|null} [keyOps] Keys keyOps
         * @property {string|null} [use] Keys use
         */

        /**
         * Constructs a new Keys.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Keys.
         * @implements IKeys
         * @constructor
         * @param {PlatformAdminApi.IKeys=} [properties] Properties to set
         */
        function Keys(properties) {
            this.keyOps = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Keys e.
         * @member {string} e
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.e = "";

        /**
         * Keys n.
         * @member {string} n
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.n = "";

        /**
         * Keys d.
         * @member {string} d
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.d = "";

        /**
         * Keys p.
         * @member {string} p
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.p = "";

        /**
         * Keys q.
         * @member {string} q
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.q = "";

        /**
         * Keys dp.
         * @member {string} dp
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.dp = "";

        /**
         * Keys dq.
         * @member {string} dq
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.dq = "";

        /**
         * Keys qi.
         * @member {string} qi
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.qi = "";

        /**
         * Keys kty.
         * @member {string} kty
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.kty = "";

        /**
         * Keys kid.
         * @member {string} kid
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.kid = "";

        /**
         * Keys alg.
         * @member {string} alg
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.alg = "";

        /**
         * Keys keyOps.
         * @member {Array.<string>} keyOps
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.keyOps = $util.emptyArray;

        /**
         * Keys use.
         * @member {string} use
         * @memberof PlatformAdminApi.Keys
         * @instance
         */
        Keys.prototype.use = "";

        /**
         * Encodes the specified Keys message. Does not implicitly {@link PlatformAdminApi.Keys.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Keys
         * @static
         * @param {PlatformAdminApi.IKeys} message Keys message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Keys.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.e != null && Object.hasOwnProperty.call(message, "e"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.e);
            if (message.n != null && Object.hasOwnProperty.call(message, "n"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.n);
            if (message.d != null && Object.hasOwnProperty.call(message, "d"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.d);
            if (message.p != null && Object.hasOwnProperty.call(message, "p"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.p);
            if (message.q != null && Object.hasOwnProperty.call(message, "q"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.q);
            if (message.dp != null && Object.hasOwnProperty.call(message, "dp"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.dp);
            if (message.dq != null && Object.hasOwnProperty.call(message, "dq"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.dq);
            if (message.qi != null && Object.hasOwnProperty.call(message, "qi"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.qi);
            if (message.kty != null && Object.hasOwnProperty.call(message, "kty"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.kty);
            if (message.kid != null && Object.hasOwnProperty.call(message, "kid"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.kid);
            if (message.alg != null && Object.hasOwnProperty.call(message, "alg"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.alg);
            if (message.keyOps != null && message.keyOps.length)
                for (var i = 0; i < message.keyOps.length; ++i)
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.keyOps[i]);
            if (message.use != null && Object.hasOwnProperty.call(message, "use"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.use);
            return writer;
        };

        /**
         * Decodes a Keys message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Keys
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Keys} Keys
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Keys.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Keys();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.e = reader.string();
                    break;
                case 2:
                    message.n = reader.string();
                    break;
                case 3:
                    message.d = reader.string();
                    break;
                case 4:
                    message.p = reader.string();
                    break;
                case 5:
                    message.q = reader.string();
                    break;
                case 6:
                    message.dp = reader.string();
                    break;
                case 7:
                    message.dq = reader.string();
                    break;
                case 8:
                    message.qi = reader.string();
                    break;
                case 9:
                    message.kty = reader.string();
                    break;
                case 10:
                    message.kid = reader.string();
                    break;
                case 11:
                    message.alg = reader.string();
                    break;
                case 12:
                    if (!(message.keyOps && message.keyOps.length))
                        message.keyOps = [];
                    message.keyOps.push(reader.string());
                    break;
                case 13:
                    message.use = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Keys;
    })();

    PlatformAdminApi.TenantUpdateRequest = (function() {

        /**
         * Properties of a TenantUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface ITenantUpdateRequest
         * @property {string|null} [id] TenantUpdateRequest id
         * @property {string|null} [name] TenantUpdateRequest name
         * @property {string|null} [email] TenantUpdateRequest email
         * @property {string|null} [parentId] TenantUpdateRequest parentId
         */

        /**
         * Constructs a new TenantUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса редактирования арендаторов
         * @implements ITenantUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.ITenantUpdateRequest=} [properties] Properties to set
         */
        function TenantUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.TenantUpdateRequest
         * @instance
         */
        TenantUpdateRequest.prototype.id = "";

        /**
         * TenantUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.TenantUpdateRequest
         * @instance
         */
        TenantUpdateRequest.prototype.name = "";

        /**
         * TenantUpdateRequest email.
         * @member {string} email
         * @memberof PlatformAdminApi.TenantUpdateRequest
         * @instance
         */
        TenantUpdateRequest.prototype.email = "";

        /**
         * TenantUpdateRequest parentId.
         * @member {string} parentId
         * @memberof PlatformAdminApi.TenantUpdateRequest
         * @instance
         */
        TenantUpdateRequest.prototype.parentId = "";

        /**
         * Encodes the specified TenantUpdateRequest message. Does not implicitly {@link PlatformAdminApi.TenantUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantUpdateRequest
         * @static
         * @param {PlatformAdminApi.ITenantUpdateRequest} message TenantUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.parentId != null && Object.hasOwnProperty.call(message, "parentId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.parentId);
            return writer;
        };

        /**
         * Decodes a TenantUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantUpdateRequest} TenantUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.email = reader.string();
                    break;
                case 4:
                    message.parentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TenantUpdateRequest;
    })();

    PlatformAdminApi.TenantCreateRequest = (function() {

        /**
         * Properties of a TenantCreateRequest.
         * @memberof PlatformAdminApi
         * @interface ITenantCreateRequest
         * @property {string|null} [name] TenantCreateRequest name
         * @property {string|null} [email] TenantCreateRequest email
         * @property {string|null} [parentId] TenantCreateRequest parentId
         */

        /**
         * Constructs a new TenantCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса создания арендатора
         * @implements ITenantCreateRequest
         * @constructor
         * @param {PlatformAdminApi.ITenantCreateRequest=} [properties] Properties to set
         */
        function TenantCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.TenantCreateRequest
         * @instance
         */
        TenantCreateRequest.prototype.name = "";

        /**
         * TenantCreateRequest email.
         * @member {string} email
         * @memberof PlatformAdminApi.TenantCreateRequest
         * @instance
         */
        TenantCreateRequest.prototype.email = "";

        /**
         * TenantCreateRequest parentId.
         * @member {string} parentId
         * @memberof PlatformAdminApi.TenantCreateRequest
         * @instance
         */
        TenantCreateRequest.prototype.parentId = "";

        /**
         * Encodes the specified TenantCreateRequest message. Does not implicitly {@link PlatformAdminApi.TenantCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantCreateRequest
         * @static
         * @param {PlatformAdminApi.ITenantCreateRequest} message TenantCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.parentId != null && Object.hasOwnProperty.call(message, "parentId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.parentId);
            return writer;
        };

        /**
         * Decodes a TenantCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantCreateRequest} TenantCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.parentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TenantCreateRequest;
    })();

    /**
     * TypeTenant enum.
     * @name PlatformAdminApi.TypeTenant
     * @enum {number}
     * @property {number} TENANT=0 TENANT value
     * @property {number} ECOSYSTEM=1 ECOSYSTEM value
     * @property {number} SUBTENANT=2 SUBTENANT value
     */
    PlatformAdminApi.TypeTenant = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TENANT"] = 0;
        values[valuesById[1] = "ECOSYSTEM"] = 1;
        values[valuesById[2] = "SUBTENANT"] = 2;
        return values;
    })();

    PlatformAdminApi.Fio = (function() {

        /**
         * Properties of a Fio.
         * @memberof PlatformAdminApi
         * @interface IFio
         * @property {string|null} [fullName] Fio fullName
         * @property {string|null} [lastName] Fio lastName
         * @property {string|null} [middleName] Fio middleName
         * @property {string|null} [firstName] Fio firstName
         * @property {boolean|null} [isValid] Fio isValid
         * @property {number|null} [gender] Fio gender
         * @property {PlatformAdminApi.IDadataFIO|null} [object] Fio object
         */

        /**
         * Constructs a new Fio.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Fio.
         * @implements IFio
         * @constructor
         * @param {PlatformAdminApi.IFio=} [properties] Properties to set
         */
        function Fio(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Fio fullName.
         * @member {string} fullName
         * @memberof PlatformAdminApi.Fio
         * @instance
         */
        Fio.prototype.fullName = "";

        /**
         * Fio lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.Fio
         * @instance
         */
        Fio.prototype.lastName = "";

        /**
         * Fio middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.Fio
         * @instance
         */
        Fio.prototype.middleName = "";

        /**
         * Fio firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.Fio
         * @instance
         */
        Fio.prototype.firstName = "";

        /**
         * Fio isValid.
         * @member {boolean} isValid
         * @memberof PlatformAdminApi.Fio
         * @instance
         */
        Fio.prototype.isValid = false;

        /**
         * Fio gender.
         * @member {number} gender
         * @memberof PlatformAdminApi.Fio
         * @instance
         */
        Fio.prototype.gender = 0;

        /**
         * Fio object.
         * @member {PlatformAdminApi.IDadataFIO|null|undefined} object
         * @memberof PlatformAdminApi.Fio
         * @instance
         */
        Fio.prototype.object = null;

        /**
         * Encodes the specified Fio message. Does not implicitly {@link PlatformAdminApi.Fio.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Fio
         * @static
         * @param {PlatformAdminApi.IFio} message Fio message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fio.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fullName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.middleName);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.gender);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.DadataFIO.encode(message.object, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Fio message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Fio
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Fio} Fio
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fio.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Fio();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fullName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.middleName = reader.string();
                    break;
                case 4:
                    message.firstName = reader.string();
                    break;
                case 5:
                    message.isValid = reader.bool();
                    break;
                case 6:
                    message.gender = reader.int32();
                    break;
                case 7:
                    message.object = $root.PlatformAdminApi.DadataFIO.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Fio;
    })();

    PlatformAdminApi.DadataFIO = (function() {

        /**
         * Properties of a DadataFIO.
         * @memberof PlatformAdminApi
         * @interface IDadataFIO
         * @property {string|null} [source] DadataFIO source
         * @property {string|null} [result] DadataFIO result
         * @property {string|null} [resultGenitive] DadataFIO resultGenitive
         * @property {string|null} [resultDative] DadataFIO resultDative
         * @property {string|null} [resultAblative] DadataFIO resultAblative
         * @property {string|null} [surname] DadataFIO surname
         * @property {string|null} [name] DadataFIO name
         * @property {string|null} [patronymic] DadataFIO patronymic
         * @property {string|null} [gender] DadataFIO gender
         * @property {string|null} [qc] DadataFIO qc
         */

        /**
         * Constructs a new DadataFIO.
         * @memberof PlatformAdminApi
         * @classdesc Represents a DadataFIO.
         * @implements IDadataFIO
         * @constructor
         * @param {PlatformAdminApi.IDadataFIO=} [properties] Properties to set
         */
        function DadataFIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DadataFIO source.
         * @member {string} source
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.source = "";

        /**
         * DadataFIO result.
         * @member {string} result
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.result = "";

        /**
         * DadataFIO resultGenitive.
         * @member {string} resultGenitive
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.resultGenitive = "";

        /**
         * DadataFIO resultDative.
         * @member {string} resultDative
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.resultDative = "";

        /**
         * DadataFIO resultAblative.
         * @member {string} resultAblative
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.resultAblative = "";

        /**
         * DadataFIO surname.
         * @member {string} surname
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.surname = "";

        /**
         * DadataFIO name.
         * @member {string} name
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.name = "";

        /**
         * DadataFIO patronymic.
         * @member {string} patronymic
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.patronymic = "";

        /**
         * DadataFIO gender.
         * @member {string} gender
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.gender = "";

        /**
         * DadataFIO qc.
         * @member {string} qc
         * @memberof PlatformAdminApi.DadataFIO
         * @instance
         */
        DadataFIO.prototype.qc = "";

        /**
         * Encodes the specified DadataFIO message. Does not implicitly {@link PlatformAdminApi.DadataFIO.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.DadataFIO
         * @static
         * @param {PlatformAdminApi.IDadataFIO} message DadataFIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DadataFIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.source);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.result);
            if (message.resultGenitive != null && Object.hasOwnProperty.call(message, "resultGenitive"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.resultGenitive);
            if (message.resultDative != null && Object.hasOwnProperty.call(message, "resultDative"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.resultDative);
            if (message.resultAblative != null && Object.hasOwnProperty.call(message, "resultAblative"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.resultAblative);
            if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.surname);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
            if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.patronymic);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.gender);
            if (message.qc != null && Object.hasOwnProperty.call(message, "qc"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.qc);
            return writer;
        };

        /**
         * Decodes a DadataFIO message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.DadataFIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.DadataFIO} DadataFIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DadataFIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.DadataFIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.source = reader.string();
                    break;
                case 2:
                    message.result = reader.string();
                    break;
                case 3:
                    message.resultGenitive = reader.string();
                    break;
                case 4:
                    message.resultDative = reader.string();
                    break;
                case 5:
                    message.resultAblative = reader.string();
                    break;
                case 6:
                    message.surname = reader.string();
                    break;
                case 7:
                    message.name = reader.string();
                    break;
                case 8:
                    message.patronymic = reader.string();
                    break;
                case 9:
                    message.gender = reader.string();
                    break;
                case 10:
                    message.qc = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DadataFIO;
    })();

    PlatformAdminApi.RevisionHistoryService = (function() {

        /**
         * Constructs a new RevisionHistoryService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RevisionHistoryService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function RevisionHistoryService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (RevisionHistoryService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RevisionHistoryService;

        /**
         * Callback as used by {@link PlatformAdminApi.RevisionHistoryService#search}.
         * @memberof PlatformAdminApi.RevisionHistoryService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RevisionHistorySearchResponse} [response] RevisionHistorySearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.RevisionHistoryService
         * @instance
         * @param {PlatformAdminApi.IRevisionHistorySearchRequest} request RevisionHistorySearchRequest message or plain object
         * @param {PlatformAdminApi.RevisionHistoryService.SearchCallback} callback Node-style callback called with the error, if any, and RevisionHistorySearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(RevisionHistoryService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.RevisionHistorySearchRequest, $root.PlatformAdminApi.RevisionHistorySearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.RevisionHistoryService
         * @instance
         * @param {PlatformAdminApi.IRevisionHistorySearchRequest} request RevisionHistorySearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RevisionHistorySearchResponse>} Promise
         * @variation 2
         */

        return RevisionHistoryService;
    })();

    PlatformAdminApi.RevisionHistorySearchRequest = (function() {

        /**
         * Properties of a RevisionHistorySearchRequest.
         * @memberof PlatformAdminApi
         * @interface IRevisionHistorySearchRequest
         * @property {number|null} [page] RevisionHistorySearchRequest page
         * @property {number|null} [limit] RevisionHistorySearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] RevisionHistorySearchRequest sort
         * @property {Array.<PlatformAdminApi.RevisionHistorySearchRequest.IWhereFields>|null} [where] RevisionHistorySearchRequest where
         */

        /**
         * Constructs a new RevisionHistorySearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RevisionHistorySearchRequest.
         * @implements IRevisionHistorySearchRequest
         * @constructor
         * @param {PlatformAdminApi.IRevisionHistorySearchRequest=} [properties] Properties to set
         */
        function RevisionHistorySearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RevisionHistorySearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.RevisionHistorySearchRequest
         * @instance
         */
        RevisionHistorySearchRequest.prototype.page = 0;

        /**
         * RevisionHistorySearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.RevisionHistorySearchRequest
         * @instance
         */
        RevisionHistorySearchRequest.prototype.limit = 0;

        /**
         * RevisionHistorySearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.RevisionHistorySearchRequest
         * @instance
         */
        RevisionHistorySearchRequest.prototype.sort = $util.emptyArray;

        /**
         * RevisionHistorySearchRequest where.
         * @member {Array.<PlatformAdminApi.RevisionHistorySearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.RevisionHistorySearchRequest
         * @instance
         */
        RevisionHistorySearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified RevisionHistorySearchRequest message. Does not implicitly {@link PlatformAdminApi.RevisionHistorySearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RevisionHistorySearchRequest
         * @static
         * @param {PlatformAdminApi.IRevisionHistorySearchRequest} message RevisionHistorySearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RevisionHistorySearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.RevisionHistorySearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RevisionHistorySearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RevisionHistorySearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RevisionHistorySearchRequest} RevisionHistorySearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RevisionHistorySearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RevisionHistorySearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.RevisionHistorySearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RevisionHistorySearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [userId] WhereFields userId
             * @property {PlatformAdminApi.IUuidCondition|null} [entityId] WhereFields entityId
             * @property {PlatformAdminApi.IStringCondition|null} [entityName] WhereFields entityName
             * @property {PlatformAdminApi.IDateCondition|null} [entityUpdatedAt] WhereFields entityUpdatedAt
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.RevisionHistorySearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields userId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} userId
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.userId = null;

            /**
             * WhereFields entityId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} entityId
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.entityId = null;

            /**
             * WhereFields entityName.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} entityName
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.entityName = null;

            /**
             * WhereFields entityUpdatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} entityUpdatedAt
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.entityUpdatedAt = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.RevisionHistorySearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.RevisionHistorySearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.entityId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.entityName != null && Object.hasOwnProperty.call(message, "entityName"))
                    $root.PlatformAdminApi.StringCondition.encode(message.entityName, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.entityUpdatedAt != null && Object.hasOwnProperty.call(message, "entityUpdatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.entityUpdatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.RevisionHistorySearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.RevisionHistorySearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RevisionHistorySearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.userId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.entityId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.entityName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.entityUpdatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return RevisionHistorySearchRequest;
    })();

    PlatformAdminApi.RevisionHistorySearchResponse = (function() {

        /**
         * Properties of a RevisionHistorySearchResponse.
         * @memberof PlatformAdminApi
         * @interface IRevisionHistorySearchResponse
         * @property {Array.<PlatformAdminApi.IRevisionHistoryUnit>|null} [data] RevisionHistorySearchResponse data
         * @property {number|null} [page] RevisionHistorySearchResponse page
         * @property {number|null} [limit] RevisionHistorySearchResponse limit
         * @property {number|null} [pageCount] RevisionHistorySearchResponse pageCount
         * @property {number|null} [count] RevisionHistorySearchResponse count
         * @property {number|null} [total] RevisionHistorySearchResponse total
         */

        /**
         * Constructs a new RevisionHistorySearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RevisionHistorySearchResponse.
         * @implements IRevisionHistorySearchResponse
         * @constructor
         * @param {PlatformAdminApi.IRevisionHistorySearchResponse=} [properties] Properties to set
         */
        function RevisionHistorySearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RevisionHistorySearchResponse data.
         * @member {Array.<PlatformAdminApi.IRevisionHistoryUnit>} data
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @instance
         */
        RevisionHistorySearchResponse.prototype.data = $util.emptyArray;

        /**
         * RevisionHistorySearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @instance
         */
        RevisionHistorySearchResponse.prototype.page = 0;

        /**
         * RevisionHistorySearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @instance
         */
        RevisionHistorySearchResponse.prototype.limit = 0;

        /**
         * RevisionHistorySearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @instance
         */
        RevisionHistorySearchResponse.prototype.pageCount = 0;

        /**
         * RevisionHistorySearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @instance
         */
        RevisionHistorySearchResponse.prototype.count = 0;

        /**
         * RevisionHistorySearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @instance
         */
        RevisionHistorySearchResponse.prototype.total = 0;

        /**
         * Encodes the specified RevisionHistorySearchResponse message. Does not implicitly {@link PlatformAdminApi.RevisionHistorySearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @static
         * @param {PlatformAdminApi.IRevisionHistorySearchResponse} message RevisionHistorySearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RevisionHistorySearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.RevisionHistoryUnit.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageCount);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a RevisionHistorySearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RevisionHistorySearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RevisionHistorySearchResponse} RevisionHistorySearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RevisionHistorySearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RevisionHistorySearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.RevisionHistoryUnit.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.pageCount = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RevisionHistorySearchResponse;
    })();

    PlatformAdminApi.RevisionHistoryUnit = (function() {

        /**
         * Properties of a RevisionHistoryUnit.
         * @memberof PlatformAdminApi
         * @interface IRevisionHistoryUnit
         * @property {string|null} [id] RevisionHistoryUnit id
         * @property {string|null} [userId] RevisionHistoryUnit userId
         * @property {string|null} [entityId] RevisionHistoryUnit entityId
         * @property {string|null} [entityName] RevisionHistoryUnit entityName
         * @property {string|null} [change] RevisionHistoryUnit change
         * @property {string|null} [previousValue] RevisionHistoryUnit previousValue
         * @property {string|null} [fieldName] RevisionHistoryUnit fieldName
         * @property {string|null} [reasonId] RevisionHistoryUnit reasonId
         * @property {string|null} [entityUpdatedAt] RevisionHistoryUnit entityUpdatedAt
         */

        /**
         * Constructs a new RevisionHistoryUnit.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RevisionHistoryUnit.
         * @implements IRevisionHistoryUnit
         * @constructor
         * @param {PlatformAdminApi.IRevisionHistoryUnit=} [properties] Properties to set
         */
        function RevisionHistoryUnit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RevisionHistoryUnit id.
         * @member {string} id
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.id = "";

        /**
         * RevisionHistoryUnit userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.userId = "";

        /**
         * RevisionHistoryUnit entityId.
         * @member {string} entityId
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.entityId = "";

        /**
         * RevisionHistoryUnit entityName.
         * @member {string} entityName
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.entityName = "";

        /**
         * RevisionHistoryUnit change.
         * @member {string} change
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.change = "";

        /**
         * RevisionHistoryUnit previousValue.
         * @member {string} previousValue
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.previousValue = "";

        /**
         * RevisionHistoryUnit fieldName.
         * @member {string} fieldName
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.fieldName = "";

        /**
         * RevisionHistoryUnit reasonId.
         * @member {string} reasonId
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.reasonId = "";

        /**
         * RevisionHistoryUnit entityUpdatedAt.
         * @member {string} entityUpdatedAt
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @instance
         */
        RevisionHistoryUnit.prototype.entityUpdatedAt = "";

        /**
         * Encodes the specified RevisionHistoryUnit message. Does not implicitly {@link PlatformAdminApi.RevisionHistoryUnit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @static
         * @param {PlatformAdminApi.IRevisionHistoryUnit} message RevisionHistoryUnit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RevisionHistoryUnit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.entityId);
            if (message.entityName != null && Object.hasOwnProperty.call(message, "entityName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.entityName);
            if (message.change != null && Object.hasOwnProperty.call(message, "change"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.change);
            if (message.previousValue != null && Object.hasOwnProperty.call(message, "previousValue"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.previousValue);
            if (message.fieldName != null && Object.hasOwnProperty.call(message, "fieldName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.fieldName);
            if (message.reasonId != null && Object.hasOwnProperty.call(message, "reasonId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.reasonId);
            if (message.entityUpdatedAt != null && Object.hasOwnProperty.call(message, "entityUpdatedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.entityUpdatedAt);
            return writer;
        };

        /**
         * Decodes a RevisionHistoryUnit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RevisionHistoryUnit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RevisionHistoryUnit} RevisionHistoryUnit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RevisionHistoryUnit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RevisionHistoryUnit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.entityId = reader.string();
                    break;
                case 4:
                    message.entityName = reader.string();
                    break;
                case 5:
                    message.change = reader.string();
                    break;
                case 6:
                    message.previousValue = reader.string();
                    break;
                case 7:
                    message.fieldName = reader.string();
                    break;
                case 8:
                    message.reasonId = reader.string();
                    break;
                case 9:
                    message.entityUpdatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RevisionHistoryUnit;
    })();

    PlatformAdminApi.ApiService = (function() {

        /**
         * Constructs a new ApiService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ApiService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ApiService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ApiService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ApiService;

        /**
         * Callback as used by {@link PlatformAdminApi.ApiService#upsert}.
         * @memberof PlatformAdminApi.ApiService
         * @typedef UpsertCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ApiResponse} [response] ApiResponse
         */

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IApiUpsertRequest} request ApiUpsertRequest message or plain object
         * @param {PlatformAdminApi.ApiService.UpsertCallback} callback Node-style callback called with the error, if any, and ApiResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ApiService.prototype.upsert = function upsert(request, callback) {
            return this.rpcCall(upsert, $root.PlatformAdminApi.ApiUpsertRequest, $root.PlatformAdminApi.ApiResponse, request, callback);
        }, "name", { value: "Upsert" });

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IApiUpsertRequest} request ApiUpsertRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ApiResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ApiService#search}.
         * @memberof PlatformAdminApi.ApiService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ApiSearchResponse} [response] ApiSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IApiSearchRequest} request ApiSearchRequest message or plain object
         * @param {PlatformAdminApi.ApiService.SearchCallback} callback Node-style callback called with the error, if any, and ApiSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ApiService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.ApiSearchRequest, $root.PlatformAdminApi.ApiSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IApiSearchRequest} request ApiSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ApiSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ApiService#findById}.
         * @memberof PlatformAdminApi.ApiService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ApiResponse} [response] ApiResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ApiService.FindByIdCallback} callback Node-style callback called with the error, if any, and ApiResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ApiService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ApiResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ApiResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ApiService#remove}.
         * @memberof PlatformAdminApi.ApiService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ApiResponse} [response] ApiResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ApiService.RemoveCallback} callback Node-style callback called with the error, if any, and ApiResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ApiService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ApiResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ApiResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ApiService#restore}.
         * @memberof PlatformAdminApi.ApiService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ApiResponse} [response] ApiResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ApiService.RestoreCallback} callback Node-style callback called with the error, if any, and ApiResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ApiService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ApiResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ApiService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ApiResponse>} Promise
         * @variation 2
         */

        return ApiService;
    })();

    PlatformAdminApi.ApiResponse = (function() {

        /**
         * Properties of an ApiResponse.
         * @memberof PlatformAdminApi
         * @interface IApiResponse
         * @property {PlatformAdminApi.IApi|null} [data] ApiResponse data
         */

        /**
         * Constructs a new ApiResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ApiResponse.
         * @implements IApiResponse
         * @constructor
         * @param {PlatformAdminApi.IApiResponse=} [properties] Properties to set
         */
        function ApiResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiResponse data.
         * @member {PlatformAdminApi.IApi|null|undefined} data
         * @memberof PlatformAdminApi.ApiResponse
         * @instance
         */
        ApiResponse.prototype.data = null;

        /**
         * Encodes the specified ApiResponse message. Does not implicitly {@link PlatformAdminApi.ApiResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ApiResponse
         * @static
         * @param {PlatformAdminApi.IApiResponse} message ApiResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Api.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ApiResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ApiResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ApiResponse} ApiResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ApiResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Api.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ApiResponse;
    })();

    PlatformAdminApi.Api = (function() {

        /**
         * Properties of an Api.
         * @memberof PlatformAdminApi
         * @interface IApi
         * @property {string|null} [id] Api id
         * @property {string|null} [createdAt] Api createdAt
         * @property {string|null} [updatedAt] Api updatedAt
         * @property {boolean|null} [isDeleted] Api isDeleted
         * @property {string|null} [deletedAt] Api deletedAt
         * @property {string|null} [name] Api name
         * @property {string|null} [identifier] Api identifier
         * @property {string|null} [icon] Api icon
         * @property {string|null} [type] Api type
         * @property {Array.<PlatformAdminApi.IApiPermission>|null} [apiPermissions] Api apiPermissions
         * @property {PlatformAdminApi.ITenant|null} [tenant] Api tenant
         */

        /**
         * Constructs a new Api.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Api.
         * @implements IApi
         * @constructor
         * @param {PlatformAdminApi.IApi=} [properties] Properties to set
         */
        function Api(properties) {
            this.apiPermissions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Api id.
         * @member {string} id
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.id = "";

        /**
         * Api createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.createdAt = "";

        /**
         * Api updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.updatedAt = "";

        /**
         * Api isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.isDeleted = false;

        /**
         * Api deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.deletedAt = "";

        /**
         * Api name.
         * @member {string} name
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.name = "";

        /**
         * Api identifier.
         * @member {string} identifier
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.identifier = "";

        /**
         * Api icon.
         * @member {string} icon
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.icon = "";

        /**
         * Api type.
         * @member {string} type
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.type = "";

        /**
         * Api apiPermissions.
         * @member {Array.<PlatformAdminApi.IApiPermission>} apiPermissions
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.apiPermissions = $util.emptyArray;

        /**
         * Api tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.Api
         * @instance
         */
        Api.prototype.tenant = null;

        /**
         * Encodes the specified Api message. Does not implicitly {@link PlatformAdminApi.Api.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Api
         * @static
         * @param {PlatformAdminApi.IApi} message Api message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Api.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.identifier);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.icon);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
            if (message.apiPermissions != null && message.apiPermissions.length)
                for (var i = 0; i < message.apiPermissions.length; ++i)
                    $root.PlatformAdminApi.ApiPermission.encode(message.apiPermissions[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Api message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Api
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Api} Api
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Api.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Api();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.identifier = reader.string();
                    break;
                case 8:
                    message.icon = reader.string();
                    break;
                case 9:
                    message.type = reader.string();
                    break;
                case 10:
                    if (!(message.apiPermissions && message.apiPermissions.length))
                        message.apiPermissions = [];
                    message.apiPermissions.push($root.PlatformAdminApi.ApiPermission.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Api;
    })();

    PlatformAdminApi.ApiPermission = (function() {

        /**
         * Properties of an ApiPermission.
         * @memberof PlatformAdminApi
         * @interface IApiPermission
         * @property {string|null} [id] ApiPermission id
         * @property {string|null} [createdAt] ApiPermission createdAt
         * @property {string|null} [updatedAt] ApiPermission updatedAt
         * @property {boolean|null} [isDeleted] ApiPermission isDeleted
         * @property {string|null} [deletedAt] ApiPermission deletedAt
         * @property {string|null} [name] ApiPermission name
         * @property {string|null} [key] ApiPermission key
         * @property {string|null} [description] ApiPermission description
         */

        /**
         * Constructs a new ApiPermission.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ApiPermission.
         * @implements IApiPermission
         * @constructor
         * @param {PlatformAdminApi.IApiPermission=} [properties] Properties to set
         */
        function ApiPermission(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPermission id.
         * @member {string} id
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.id = "";

        /**
         * ApiPermission createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.createdAt = "";

        /**
         * ApiPermission updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.updatedAt = "";

        /**
         * ApiPermission isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.isDeleted = false;

        /**
         * ApiPermission deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.deletedAt = "";

        /**
         * ApiPermission name.
         * @member {string} name
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.name = "";

        /**
         * ApiPermission key.
         * @member {string} key
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.key = "";

        /**
         * ApiPermission description.
         * @member {string} description
         * @memberof PlatformAdminApi.ApiPermission
         * @instance
         */
        ApiPermission.prototype.description = "";

        /**
         * Encodes the specified ApiPermission message. Does not implicitly {@link PlatformAdminApi.ApiPermission.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ApiPermission
         * @static
         * @param {PlatformAdminApi.IApiPermission} message ApiPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.key);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.description);
            return writer;
        };

        /**
         * Decodes an ApiPermission message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ApiPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ApiPermission} ApiPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ApiPermission();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.key = reader.string();
                    break;
                case 8:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ApiPermission;
    })();

    PlatformAdminApi.ApiUpsertRequest = (function() {

        /**
         * Properties of an ApiUpsertRequest.
         * @memberof PlatformAdminApi
         * @interface IApiUpsertRequest
         * @property {string|null} [id] ApiUpsertRequest id
         * @property {string|null} [marketSvsId] ApiUpsertRequest marketSvsId
         * @property {string|null} [name] ApiUpsertRequest name
         * @property {string|null} [identifier] ApiUpsertRequest identifier
         * @property {string|null} [icon] ApiUpsertRequest icon
         * @property {Array.<PlatformAdminApi.IApiPermissionUpsert>|null} [apiPermissions] ApiUpsertRequest apiPermissions
         * @property {string|null} [tenantId] ApiUpsertRequest tenantId
         */

        /**
         * Constructs a new ApiUpsertRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ApiUpsertRequest.
         * @implements IApiUpsertRequest
         * @constructor
         * @param {PlatformAdminApi.IApiUpsertRequest=} [properties] Properties to set
         */
        function ApiUpsertRequest(properties) {
            this.apiPermissions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUpsertRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @instance
         */
        ApiUpsertRequest.prototype.id = "";

        /**
         * ApiUpsertRequest marketSvsId.
         * @member {string} marketSvsId
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @instance
         */
        ApiUpsertRequest.prototype.marketSvsId = "";

        /**
         * ApiUpsertRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @instance
         */
        ApiUpsertRequest.prototype.name = "";

        /**
         * ApiUpsertRequest identifier.
         * @member {string} identifier
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @instance
         */
        ApiUpsertRequest.prototype.identifier = "";

        /**
         * ApiUpsertRequest icon.
         * @member {string} icon
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @instance
         */
        ApiUpsertRequest.prototype.icon = "";

        /**
         * ApiUpsertRequest apiPermissions.
         * @member {Array.<PlatformAdminApi.IApiPermissionUpsert>} apiPermissions
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @instance
         */
        ApiUpsertRequest.prototype.apiPermissions = $util.emptyArray;

        /**
         * ApiUpsertRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @instance
         */
        ApiUpsertRequest.prototype.tenantId = "";

        /**
         * Encodes the specified ApiUpsertRequest message. Does not implicitly {@link PlatformAdminApi.ApiUpsertRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @static
         * @param {PlatformAdminApi.IApiUpsertRequest} message ApiUpsertRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUpsertRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.marketSvsId != null && Object.hasOwnProperty.call(message, "marketSvsId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.marketSvsId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.identifier);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.icon);
            if (message.apiPermissions != null && message.apiPermissions.length)
                for (var i = 0; i < message.apiPermissions.length; ++i)
                    $root.PlatformAdminApi.ApiPermissionUpsert.encode(message.apiPermissions[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.tenantId);
            return writer;
        };

        /**
         * Decodes an ApiUpsertRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ApiUpsertRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ApiUpsertRequest} ApiUpsertRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUpsertRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ApiUpsertRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.marketSvsId = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.identifier = reader.string();
                    break;
                case 5:
                    message.icon = reader.string();
                    break;
                case 6:
                    if (!(message.apiPermissions && message.apiPermissions.length))
                        message.apiPermissions = [];
                    message.apiPermissions.push($root.PlatformAdminApi.ApiPermissionUpsert.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.tenantId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ApiUpsertRequest;
    })();

    PlatformAdminApi.ApiPermissionUpsert = (function() {

        /**
         * Properties of an ApiPermissionUpsert.
         * @memberof PlatformAdminApi
         * @interface IApiPermissionUpsert
         * @property {string|null} [id] ApiPermissionUpsert id
         * @property {string|null} [name] ApiPermissionUpsert name
         * @property {string|null} [key] ApiPermissionUpsert key
         * @property {string|null} [description] ApiPermissionUpsert description
         */

        /**
         * Constructs a new ApiPermissionUpsert.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ApiPermissionUpsert.
         * @implements IApiPermissionUpsert
         * @constructor
         * @param {PlatformAdminApi.IApiPermissionUpsert=} [properties] Properties to set
         */
        function ApiPermissionUpsert(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPermissionUpsert id.
         * @member {string} id
         * @memberof PlatformAdminApi.ApiPermissionUpsert
         * @instance
         */
        ApiPermissionUpsert.prototype.id = "";

        /**
         * ApiPermissionUpsert name.
         * @member {string} name
         * @memberof PlatformAdminApi.ApiPermissionUpsert
         * @instance
         */
        ApiPermissionUpsert.prototype.name = "";

        /**
         * ApiPermissionUpsert key.
         * @member {string} key
         * @memberof PlatformAdminApi.ApiPermissionUpsert
         * @instance
         */
        ApiPermissionUpsert.prototype.key = "";

        /**
         * ApiPermissionUpsert description.
         * @member {string} description
         * @memberof PlatformAdminApi.ApiPermissionUpsert
         * @instance
         */
        ApiPermissionUpsert.prototype.description = "";

        /**
         * Encodes the specified ApiPermissionUpsert message. Does not implicitly {@link PlatformAdminApi.ApiPermissionUpsert.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ApiPermissionUpsert
         * @static
         * @param {PlatformAdminApi.IApiPermissionUpsert} message ApiPermissionUpsert message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPermissionUpsert.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.key);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
            return writer;
        };

        /**
         * Decodes an ApiPermissionUpsert message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ApiPermissionUpsert
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ApiPermissionUpsert} ApiPermissionUpsert
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPermissionUpsert.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ApiPermissionUpsert();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ApiPermissionUpsert;
    })();

    PlatformAdminApi.ApiSearchRequest = (function() {

        /**
         * Properties of an ApiSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IApiSearchRequest
         * @property {number|null} [page] ApiSearchRequest page
         * @property {number|null} [limit] ApiSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] ApiSearchRequest sort
         * @property {Array.<PlatformAdminApi.ApiSearchRequest.IWhereFields>|null} [where] ApiSearchRequest where
         */

        /**
         * Constructs a new ApiSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ApiSearchRequest.
         * @implements IApiSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IApiSearchRequest=} [properties] Properties to set
         */
        function ApiSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.ApiSearchRequest
         * @instance
         */
        ApiSearchRequest.prototype.page = 0;

        /**
         * ApiSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ApiSearchRequest
         * @instance
         */
        ApiSearchRequest.prototype.limit = 0;

        /**
         * ApiSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.ApiSearchRequest
         * @instance
         */
        ApiSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * ApiSearchRequest where.
         * @member {Array.<PlatformAdminApi.ApiSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.ApiSearchRequest
         * @instance
         */
        ApiSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified ApiSearchRequest message. Does not implicitly {@link PlatformAdminApi.ApiSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ApiSearchRequest
         * @static
         * @param {PlatformAdminApi.IApiSearchRequest} message ApiSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.ApiSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ApiSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ApiSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ApiSearchRequest} ApiSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ApiSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.ApiSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ApiSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.ApiSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {PlatformAdminApi.IStringCondition|null} [identifier] WhereFields identifier
             * @property {PlatformAdminApi.IStringCondition|null} [tenantId] WhereFields tenantId
             * @property {PlatformAdminApi.IStringCondition|null} [marketSvsId] WhereFields marketSvsId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.ApiSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.ApiSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields identifier.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} identifier
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.identifier = null;

            /**
             * WhereFields tenantId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} tenantId
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.tenantId = null;

            /**
             * WhereFields marketSvsId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} marketSvsId
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.marketSvsId = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.ApiSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.ApiSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
                    $root.PlatformAdminApi.StringCondition.encode(message.identifier, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.tenantId, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.marketSvsId != null && Object.hasOwnProperty.call(message, "marketSvsId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.marketSvsId, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ApiSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ApiSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ApiSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.isDeleted = reader.bool();
                        break;
                    case 6:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.identifier = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.tenantId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.marketSvsId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return ApiSearchRequest;
    })();

    PlatformAdminApi.ApiSearchResponse = (function() {

        /**
         * Properties of an ApiSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IApiSearchResponse
         * @property {Array.<PlatformAdminApi.IApi>|null} [data] ApiSearchResponse data
         * @property {number|null} [page] ApiSearchResponse page
         * @property {number|null} [pageCount] ApiSearchResponse pageCount
         * @property {number|null} [limit] ApiSearchResponse limit
         * @property {number|null} [count] ApiSearchResponse count
         * @property {number|null} [total] ApiSearchResponse total
         */

        /**
         * Constructs a new ApiSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ApiSearchResponse.
         * @implements IApiSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IApiSearchResponse=} [properties] Properties to set
         */
        function ApiSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSearchResponse data.
         * @member {Array.<PlatformAdminApi.IApi>} data
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @instance
         */
        ApiSearchResponse.prototype.data = $util.emptyArray;

        /**
         * ApiSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @instance
         */
        ApiSearchResponse.prototype.page = 0;

        /**
         * ApiSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @instance
         */
        ApiSearchResponse.prototype.pageCount = 0;

        /**
         * ApiSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @instance
         */
        ApiSearchResponse.prototype.limit = 0;

        /**
         * ApiSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @instance
         */
        ApiSearchResponse.prototype.count = 0;

        /**
         * ApiSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @instance
         */
        ApiSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified ApiSearchResponse message. Does not implicitly {@link PlatformAdminApi.ApiSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @static
         * @param {PlatformAdminApi.IApiSearchResponse} message ApiSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Api.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes an ApiSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ApiSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ApiSearchResponse} ApiSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ApiSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Api.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ApiSearchResponse;
    })();

    PlatformAdminApi.MarketService = (function() {

        /**
         * Constructs a new MarketService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a MarketService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MarketService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MarketService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MarketService;

        /**
         * Callback as used by {@link PlatformAdminApi.MarketService#create}.
         * @memberof PlatformAdminApi.MarketService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ServiceMarketResponse} [response] ServiceMarketResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.ICreateServiceMarketRequest} request CreateServiceMarketRequest message or plain object
         * @param {PlatformAdminApi.MarketService.CreateCallback} callback Node-style callback called with the error, if any, and ServiceMarketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MarketService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreateServiceMarketRequest, $root.PlatformAdminApi.ServiceMarketResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.ICreateServiceMarketRequest} request CreateServiceMarketRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ServiceMarketResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.MarketService#update}.
         * @memberof PlatformAdminApi.MarketService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ServiceMarketResponse} [response] ServiceMarketResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUpdateServiceMarketRequest} request UpdateServiceMarketRequest message or plain object
         * @param {PlatformAdminApi.MarketService.UpdateCallback} callback Node-style callback called with the error, if any, and ServiceMarketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MarketService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdateServiceMarketRequest, $root.PlatformAdminApi.ServiceMarketResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUpdateServiceMarketRequest} request UpdateServiceMarketRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ServiceMarketResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.MarketService#search}.
         * @memberof PlatformAdminApi.MarketService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ServicesMarketList} [response] ServicesMarketList
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IMarketSearchRequest} request MarketSearchRequest message or plain object
         * @param {PlatformAdminApi.MarketService.SearchCallback} callback Node-style callback called with the error, if any, and ServicesMarketList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MarketService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.MarketSearchRequest, $root.PlatformAdminApi.ServicesMarketList, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IMarketSearchRequest} request MarketSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ServicesMarketList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.MarketService#findById}.
         * @memberof PlatformAdminApi.MarketService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ServiceMarketResponse} [response] ServiceMarketResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.MarketService.FindByIdCallback} callback Node-style callback called with the error, if any, and ServiceMarketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MarketService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ServiceMarketResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ServiceMarketResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.MarketService#remove}.
         * @memberof PlatformAdminApi.MarketService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ServiceMarketResponse} [response] ServiceMarketResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.MarketService.RemoveCallback} callback Node-style callback called with the error, if any, and ServiceMarketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MarketService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ServiceMarketResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ServiceMarketResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.MarketService#restore}.
         * @memberof PlatformAdminApi.MarketService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ServiceMarketResponse} [response] ServiceMarketResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.MarketService.RestoreCallback} callback Node-style callback called with the error, if any, and ServiceMarketResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MarketService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ServiceMarketResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.MarketService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ServiceMarketResponse>} Promise
         * @variation 2
         */

        return MarketService;
    })();

    PlatformAdminApi.ServiceMarketItem = (function() {

        /**
         * Properties of a ServiceMarketItem.
         * @memberof PlatformAdminApi
         * @interface IServiceMarketItem
         * @property {string|null} [id] ServiceMarketItem id
         * @property {string|null} [key] ServiceMarketItem key
         * @property {string|null} [name] ServiceMarketItem name
         * @property {string|null} [description] ServiceMarketItem description
         * @property {boolean|null} [isDeleted] ServiceMarketItem isDeleted
         */

        /**
         * Constructs a new ServiceMarketItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ServiceMarketItem.
         * @implements IServiceMarketItem
         * @constructor
         * @param {PlatformAdminApi.IServiceMarketItem=} [properties] Properties to set
         */
        function ServiceMarketItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceMarketItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.ServiceMarketItem
         * @instance
         */
        ServiceMarketItem.prototype.id = "";

        /**
         * ServiceMarketItem key.
         * @member {string} key
         * @memberof PlatformAdminApi.ServiceMarketItem
         * @instance
         */
        ServiceMarketItem.prototype.key = "";

        /**
         * ServiceMarketItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.ServiceMarketItem
         * @instance
         */
        ServiceMarketItem.prototype.name = "";

        /**
         * ServiceMarketItem description.
         * @member {string} description
         * @memberof PlatformAdminApi.ServiceMarketItem
         * @instance
         */
        ServiceMarketItem.prototype.description = "";

        /**
         * ServiceMarketItem isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ServiceMarketItem
         * @instance
         */
        ServiceMarketItem.prototype.isDeleted = false;

        /**
         * Encodes the specified ServiceMarketItem message. Does not implicitly {@link PlatformAdminApi.ServiceMarketItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ServiceMarketItem
         * @static
         * @param {PlatformAdminApi.IServiceMarketItem} message ServiceMarketItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceMarketItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a ServiceMarketItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ServiceMarketItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ServiceMarketItem} ServiceMarketItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceMarketItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ServiceMarketItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                case 5:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ServiceMarketItem;
    })();

    PlatformAdminApi.ServiceMarketResponse = (function() {

        /**
         * Properties of a ServiceMarketResponse.
         * @memberof PlatformAdminApi
         * @interface IServiceMarketResponse
         * @property {PlatformAdminApi.IServiceMarket|null} [data] ServiceMarketResponse data
         */

        /**
         * Constructs a new ServiceMarketResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ServiceMarketResponse.
         * @implements IServiceMarketResponse
         * @constructor
         * @param {PlatformAdminApi.IServiceMarketResponse=} [properties] Properties to set
         */
        function ServiceMarketResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceMarketResponse data.
         * @member {PlatformAdminApi.IServiceMarket|null|undefined} data
         * @memberof PlatformAdminApi.ServiceMarketResponse
         * @instance
         */
        ServiceMarketResponse.prototype.data = null;

        /**
         * Encodes the specified ServiceMarketResponse message. Does not implicitly {@link PlatformAdminApi.ServiceMarketResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ServiceMarketResponse
         * @static
         * @param {PlatformAdminApi.IServiceMarketResponse} message ServiceMarketResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceMarketResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.ServiceMarket.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ServiceMarketResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ServiceMarketResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ServiceMarketResponse} ServiceMarketResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceMarketResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ServiceMarketResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.ServiceMarket.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ServiceMarketResponse;
    })();

    PlatformAdminApi.CreateServiceMarketRequest = (function() {

        /**
         * Properties of a CreateServiceMarketRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateServiceMarketRequest
         * @property {string|null} [title] CreateServiceMarketRequest title
         * @property {string|null} [description] CreateServiceMarketRequest description
         * @property {string|null} [icon] CreateServiceMarketRequest icon
         * @property {Array.<PlatformAdminApi.IServiceMarketItem>|null} [items] CreateServiceMarketRequest items
         */

        /**
         * Constructs a new CreateServiceMarketRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания сервиса в маркете
         * @implements ICreateServiceMarketRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateServiceMarketRequest=} [properties] Properties to set
         */
        function CreateServiceMarketRequest(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateServiceMarketRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreateServiceMarketRequest
         * @instance
         */
        CreateServiceMarketRequest.prototype.title = "";

        /**
         * CreateServiceMarketRequest description.
         * @member {string} description
         * @memberof PlatformAdminApi.CreateServiceMarketRequest
         * @instance
         */
        CreateServiceMarketRequest.prototype.description = "";

        /**
         * CreateServiceMarketRequest icon.
         * @member {string} icon
         * @memberof PlatformAdminApi.CreateServiceMarketRequest
         * @instance
         */
        CreateServiceMarketRequest.prototype.icon = "";

        /**
         * CreateServiceMarketRequest items.
         * @member {Array.<PlatformAdminApi.IServiceMarketItem>} items
         * @memberof PlatformAdminApi.CreateServiceMarketRequest
         * @instance
         */
        CreateServiceMarketRequest.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified CreateServiceMarketRequest message. Does not implicitly {@link PlatformAdminApi.CreateServiceMarketRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateServiceMarketRequest
         * @static
         * @param {PlatformAdminApi.ICreateServiceMarketRequest} message CreateServiceMarketRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateServiceMarketRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.icon);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformAdminApi.ServiceMarketItem.encode(message.items[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreateServiceMarketRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateServiceMarketRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateServiceMarketRequest} CreateServiceMarketRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateServiceMarketRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateServiceMarketRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.icon = reader.string();
                    break;
                case 4:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformAdminApi.ServiceMarketItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateServiceMarketRequest;
    })();

    PlatformAdminApi.UpdateServiceMarketRequest = (function() {

        /**
         * Properties of an UpdateServiceMarketRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateServiceMarketRequest
         * @property {string|null} [id] UpdateServiceMarketRequest id
         * @property {string|null} [title] UpdateServiceMarketRequest title
         * @property {string|null} [description] UpdateServiceMarketRequest description
         * @property {string|null} [icon] UpdateServiceMarketRequest icon
         * @property {Array.<PlatformAdminApi.IServiceMarketItem>|null} [items] UpdateServiceMarketRequest items
         */

        /**
         * Constructs a new UpdateServiceMarketRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновления сервиса в маркете
         * @implements IUpdateServiceMarketRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateServiceMarketRequest=} [properties] Properties to set
         */
        function UpdateServiceMarketRequest(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateServiceMarketRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdateServiceMarketRequest
         * @instance
         */
        UpdateServiceMarketRequest.prototype.id = "";

        /**
         * UpdateServiceMarketRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdateServiceMarketRequest
         * @instance
         */
        UpdateServiceMarketRequest.prototype.title = "";

        /**
         * UpdateServiceMarketRequest description.
         * @member {string} description
         * @memberof PlatformAdminApi.UpdateServiceMarketRequest
         * @instance
         */
        UpdateServiceMarketRequest.prototype.description = "";

        /**
         * UpdateServiceMarketRequest icon.
         * @member {string} icon
         * @memberof PlatformAdminApi.UpdateServiceMarketRequest
         * @instance
         */
        UpdateServiceMarketRequest.prototype.icon = "";

        /**
         * UpdateServiceMarketRequest items.
         * @member {Array.<PlatformAdminApi.IServiceMarketItem>} items
         * @memberof PlatformAdminApi.UpdateServiceMarketRequest
         * @instance
         */
        UpdateServiceMarketRequest.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified UpdateServiceMarketRequest message. Does not implicitly {@link PlatformAdminApi.UpdateServiceMarketRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateServiceMarketRequest
         * @static
         * @param {PlatformAdminApi.IUpdateServiceMarketRequest} message UpdateServiceMarketRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateServiceMarketRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.icon);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformAdminApi.ServiceMarketItem.encode(message.items[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateServiceMarketRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateServiceMarketRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateServiceMarketRequest} UpdateServiceMarketRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateServiceMarketRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateServiceMarketRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 4:
                    message.icon = reader.string();
                    break;
                case 5:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformAdminApi.ServiceMarketItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateServiceMarketRequest;
    })();

    PlatformAdminApi.ServiceMarket = (function() {

        /**
         * Properties of a ServiceMarket.
         * @memberof PlatformAdminApi
         * @interface IServiceMarket
         * @property {string|null} [id] ServiceMarket id
         * @property {string|null} [title] ServiceMarket title
         * @property {string|null} [description] ServiceMarket description
         * @property {string|null} [icon] ServiceMarket icon
         * @property {Array.<PlatformAdminApi.IServiceMarketItem>|null} [items] ServiceMarket items
         * @property {string|null} [createdAt] ServiceMarket createdAt
         * @property {string|null} [updatedAt] ServiceMarket updatedAt
         * @property {boolean|null} [isDeleted] ServiceMarket isDeleted
         * @property {string|null} [deletedAt] ServiceMarket deletedAt
         */

        /**
         * Constructs a new ServiceMarket.
         * @memberof PlatformAdminApi
         * @classdesc Объект сервиса сервиса из каталога
         * @implements IServiceMarket
         * @constructor
         * @param {PlatformAdminApi.IServiceMarket=} [properties] Properties to set
         */
        function ServiceMarket(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceMarket id.
         * @member {string} id
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.id = "";

        /**
         * ServiceMarket title.
         * @member {string} title
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.title = "";

        /**
         * ServiceMarket description.
         * @member {string} description
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.description = "";

        /**
         * ServiceMarket icon.
         * @member {string} icon
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.icon = "";

        /**
         * ServiceMarket items.
         * @member {Array.<PlatformAdminApi.IServiceMarketItem>} items
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.items = $util.emptyArray;

        /**
         * ServiceMarket createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.createdAt = "";

        /**
         * ServiceMarket updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.updatedAt = "";

        /**
         * ServiceMarket isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.isDeleted = false;

        /**
         * ServiceMarket deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.ServiceMarket
         * @instance
         */
        ServiceMarket.prototype.deletedAt = "";

        /**
         * Encodes the specified ServiceMarket message. Does not implicitly {@link PlatformAdminApi.ServiceMarket.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ServiceMarket
         * @static
         * @param {PlatformAdminApi.IServiceMarket} message ServiceMarket message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceMarket.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.icon != null && Object.hasOwnProperty.call(message, "icon"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.icon);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformAdminApi.ServiceMarketItem.encode(message.items[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a ServiceMarket message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ServiceMarket
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ServiceMarket} ServiceMarket
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceMarket.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ServiceMarket();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 4:
                    message.icon = reader.string();
                    break;
                case 5:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformAdminApi.ServiceMarketItem.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createdAt = reader.string();
                    break;
                case 7:
                    message.updatedAt = reader.string();
                    break;
                case 8:
                    message.isDeleted = reader.bool();
                    break;
                case 9:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ServiceMarket;
    })();

    PlatformAdminApi.MarketSearchRequest = (function() {

        /**
         * Properties of a MarketSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IMarketSearchRequest
         * @property {number|null} [page] MarketSearchRequest page
         * @property {number|null} [limit] MarketSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] MarketSearchRequest sort
         * @property {Array.<PlatformAdminApi.MarketSearchRequest.IWhereFields>|null} [where] MarketSearchRequest where
         */

        /**
         * Constructs a new MarketSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных сервисов в маркете
         * @implements IMarketSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IMarketSearchRequest=} [properties] Properties to set
         */
        function MarketSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MarketSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.MarketSearchRequest
         * @instance
         */
        MarketSearchRequest.prototype.page = 0;

        /**
         * MarketSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.MarketSearchRequest
         * @instance
         */
        MarketSearchRequest.prototype.limit = 0;

        /**
         * MarketSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.MarketSearchRequest
         * @instance
         */
        MarketSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * MarketSearchRequest where.
         * @member {Array.<PlatformAdminApi.MarketSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.MarketSearchRequest
         * @instance
         */
        MarketSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified MarketSearchRequest message. Does not implicitly {@link PlatformAdminApi.MarketSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.MarketSearchRequest
         * @static
         * @param {PlatformAdminApi.IMarketSearchRequest} message MarketSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MarketSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.MarketSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a MarketSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.MarketSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.MarketSearchRequest} MarketSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MarketSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.MarketSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.MarketSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MarketSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.MarketSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [description] WhereFields description
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.MarketSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.MarketSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields description.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} description
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.description = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.MarketSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.MarketSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    $root.PlatformAdminApi.StringCondition.encode(message.description, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.MarketSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.MarketSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.MarketSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.isDeleted = reader.bool();
                        break;
                    case 6:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.description = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return MarketSearchRequest;
    })();

    PlatformAdminApi.ServicesMarketList = (function() {

        /**
         * Properties of a ServicesMarketList.
         * @memberof PlatformAdminApi
         * @interface IServicesMarketList
         * @property {Array.<PlatformAdminApi.IServiceMarket>|null} [data] ServicesMarketList data
         * @property {number|null} [page] ServicesMarketList page
         * @property {number|null} [limit] ServicesMarketList limit
         * @property {number|null} [total] ServicesMarketList total
         */

        /**
         * Constructs a new ServicesMarketList.
         * @memberof PlatformAdminApi
         * @classdesc Список доступных сервисов в маркете
         * @implements IServicesMarketList
         * @constructor
         * @param {PlatformAdminApi.IServicesMarketList=} [properties] Properties to set
         */
        function ServicesMarketList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServicesMarketList data.
         * @member {Array.<PlatformAdminApi.IServiceMarket>} data
         * @memberof PlatformAdminApi.ServicesMarketList
         * @instance
         */
        ServicesMarketList.prototype.data = $util.emptyArray;

        /**
         * ServicesMarketList page.
         * @member {number} page
         * @memberof PlatformAdminApi.ServicesMarketList
         * @instance
         */
        ServicesMarketList.prototype.page = 0;

        /**
         * ServicesMarketList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ServicesMarketList
         * @instance
         */
        ServicesMarketList.prototype.limit = 0;

        /**
         * ServicesMarketList total.
         * @member {number} total
         * @memberof PlatformAdminApi.ServicesMarketList
         * @instance
         */
        ServicesMarketList.prototype.total = 0;

        /**
         * Encodes the specified ServicesMarketList message. Does not implicitly {@link PlatformAdminApi.ServicesMarketList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ServicesMarketList
         * @static
         * @param {PlatformAdminApi.IServicesMarketList} message ServicesMarketList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServicesMarketList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.ServiceMarket.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ServicesMarketList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ServicesMarketList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ServicesMarketList} ServicesMarketList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServicesMarketList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ServicesMarketList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.ServiceMarket.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ServicesMarketList;
    })();

    PlatformAdminApi.AppService = (function() {

        /**
         * Constructs a new AppService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AppService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function AppService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (AppService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AppService;

        /**
         * Callback as used by {@link PlatformAdminApi.AppService#create}.
         * @memberof PlatformAdminApi.AppService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.AppResponse} [response] AppResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IAppCreateRequest} request AppCreateRequest message or plain object
         * @param {PlatformAdminApi.AppService.CreateCallback} callback Node-style callback called with the error, if any, and AppResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AppService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.AppCreateRequest, $root.PlatformAdminApi.AppResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IAppCreateRequest} request AppCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.AppResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.AppService#update}.
         * @memberof PlatformAdminApi.AppService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.AppResponse} [response] AppResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IAppUpdateRequest} request AppUpdateRequest message or plain object
         * @param {PlatformAdminApi.AppService.UpdateCallback} callback Node-style callback called with the error, if any, and AppResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AppService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.AppUpdateRequest, $root.PlatformAdminApi.AppResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IAppUpdateRequest} request AppUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.AppResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.AppService#search}.
         * @memberof PlatformAdminApi.AppService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.AppSearchResponse} [response] AppSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IAppSearchRequest} request AppSearchRequest message or plain object
         * @param {PlatformAdminApi.AppService.SearchCallback} callback Node-style callback called with the error, if any, and AppSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AppService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.AppSearchRequest, $root.PlatformAdminApi.AppSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IAppSearchRequest} request AppSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.AppSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.AppService#remove}.
         * @memberof PlatformAdminApi.AppService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.AppResponse} [response] AppResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.AppService.RemoveCallback} callback Node-style callback called with the error, if any, and AppResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AppService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.AppResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.AppResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.AppService#restore}.
         * @memberof PlatformAdminApi.AppService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.AppResponse} [response] AppResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.AppService.RestoreCallback} callback Node-style callback called with the error, if any, and AppResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AppService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.AppResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.AppResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.AppService#findById}.
         * @memberof PlatformAdminApi.AppService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.AppResponse} [response] AppResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.AppService.FindByIdCallback} callback Node-style callback called with the error, if any, and AppResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AppService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.AppResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.AppResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.AppService#regenerateSecret}.
         * @memberof PlatformAdminApi.AppService
         * @typedef RegenerateSecretCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.AppResponse} [response] AppResponse
         */

        /**
         * Calls RegenerateSecret.
         * @function regenerateSecret
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.AppService.RegenerateSecretCallback} callback Node-style callback called with the error, if any, and AppResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AppService.prototype.regenerateSecret = function regenerateSecret(request, callback) {
            return this.rpcCall(regenerateSecret, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.AppResponse, request, callback);
        }, "name", { value: "RegenerateSecret" });

        /**
         * Calls RegenerateSecret.
         * @function regenerateSecret
         * @memberof PlatformAdminApi.AppService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.AppResponse>} Promise
         * @variation 2
         */

        return AppService;
    })();

    PlatformAdminApi.AppCreateRequest = (function() {

        /**
         * Properties of an AppCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IAppCreateRequest
         * @property {string|null} [name] AppCreateRequest name
         * @property {PlatformAdminApi.APP_TYPE|null} [type] AppCreateRequest type
         * @property {PlatformAdminApi.USER_TYPE|null} [defaultUserType] AppCreateRequest defaultUserType
         * @property {Array.<PlatformAdminApi.USER_TYPE>|null} [allowedUserType] AppCreateRequest allowedUserType
         * @property {Array.<PlatformAdminApi.CONNECTION>|null} [allowedConnection] AppCreateRequest allowedConnection
         * @property {boolean|null} [allowedAutoSignUp] AppCreateRequest allowedAutoSignUp
         * @property {boolean|null} [allowedPublicSignUp] AppCreateRequest allowedPublicSignUp
         * @property {string|null} [successRedirectUrl] AppCreateRequest successRedirectUrl
         * @property {string|null} [failRedirectUrl] AppCreateRequest failRedirectUrl
         * @property {string|null} [tenantId] AppCreateRequest tenantId
         * @property {PlatformAdminApi.ITenant|null} [tenant] AppCreateRequest tenant
         * @property {Array.<PlatformAdminApi.IGrant>|null} [grants] AppCreateRequest grants
         */

        /**
         * Constructs a new AppCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AppCreateRequest.
         * @implements IAppCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IAppCreateRequest=} [properties] Properties to set
         */
        function AppCreateRequest(properties) {
            this.allowedUserType = [];
            this.allowedConnection = [];
            this.grants = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppCreateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.name = "";

        /**
         * AppCreateRequest type.
         * @member {PlatformAdminApi.APP_TYPE} type
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.type = 0;

        /**
         * AppCreateRequest defaultUserType.
         * @member {PlatformAdminApi.USER_TYPE} defaultUserType
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.defaultUserType = 0;

        /**
         * AppCreateRequest allowedUserType.
         * @member {Array.<PlatformAdminApi.USER_TYPE>} allowedUserType
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.allowedUserType = $util.emptyArray;

        /**
         * AppCreateRequest allowedConnection.
         * @member {Array.<PlatformAdminApi.CONNECTION>} allowedConnection
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.allowedConnection = $util.emptyArray;

        /**
         * AppCreateRequest allowedAutoSignUp.
         * @member {boolean} allowedAutoSignUp
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.allowedAutoSignUp = false;

        /**
         * AppCreateRequest allowedPublicSignUp.
         * @member {boolean} allowedPublicSignUp
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.allowedPublicSignUp = false;

        /**
         * AppCreateRequest successRedirectUrl.
         * @member {string} successRedirectUrl
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.successRedirectUrl = "";

        /**
         * AppCreateRequest failRedirectUrl.
         * @member {string} failRedirectUrl
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.failRedirectUrl = "";

        /**
         * AppCreateRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.tenantId = "";

        /**
         * AppCreateRequest tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.tenant = null;

        /**
         * AppCreateRequest grants.
         * @member {Array.<PlatformAdminApi.IGrant>} grants
         * @memberof PlatformAdminApi.AppCreateRequest
         * @instance
         */
        AppCreateRequest.prototype.grants = $util.emptyArray;

        /**
         * Encodes the specified AppCreateRequest message. Does not implicitly {@link PlatformAdminApi.AppCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AppCreateRequest
         * @static
         * @param {PlatformAdminApi.IAppCreateRequest} message AppCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.defaultUserType != null && Object.hasOwnProperty.call(message, "defaultUserType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.defaultUserType);
            if (message.allowedUserType != null && message.allowedUserType.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.allowedUserType.length; ++i)
                    writer.int32(message.allowedUserType[i]);
                writer.ldelim();
            }
            if (message.allowedConnection != null && message.allowedConnection.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.allowedConnection.length; ++i)
                    writer.int32(message.allowedConnection[i]);
                writer.ldelim();
            }
            if (message.allowedAutoSignUp != null && Object.hasOwnProperty.call(message, "allowedAutoSignUp"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.allowedAutoSignUp);
            if (message.allowedPublicSignUp != null && Object.hasOwnProperty.call(message, "allowedPublicSignUp"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.allowedPublicSignUp);
            if (message.successRedirectUrl != null && Object.hasOwnProperty.call(message, "successRedirectUrl"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.successRedirectUrl);
            if (message.failRedirectUrl != null && Object.hasOwnProperty.call(message, "failRedirectUrl"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.failRedirectUrl);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.tenantId);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.grants != null && message.grants.length)
                for (var i = 0; i < message.grants.length; ++i)
                    $root.PlatformAdminApi.Grant.encode(message.grants[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AppCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AppCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AppCreateRequest} AppCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AppCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.defaultUserType = reader.int32();
                    break;
                case 4:
                    if (!(message.allowedUserType && message.allowedUserType.length))
                        message.allowedUserType = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.allowedUserType.push(reader.int32());
                    } else
                        message.allowedUserType.push(reader.int32());
                    break;
                case 5:
                    if (!(message.allowedConnection && message.allowedConnection.length))
                        message.allowedConnection = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.allowedConnection.push(reader.int32());
                    } else
                        message.allowedConnection.push(reader.int32());
                    break;
                case 6:
                    message.allowedAutoSignUp = reader.bool();
                    break;
                case 7:
                    message.allowedPublicSignUp = reader.bool();
                    break;
                case 8:
                    message.successRedirectUrl = reader.string();
                    break;
                case 9:
                    message.failRedirectUrl = reader.string();
                    break;
                case 10:
                    message.tenantId = reader.string();
                    break;
                case 11:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                case 12:
                    if (!(message.grants && message.grants.length))
                        message.grants = [];
                    message.grants.push($root.PlatformAdminApi.Grant.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AppCreateRequest;
    })();

    PlatformAdminApi.AppUpdateRequest = (function() {

        /**
         * Properties of an AppUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IAppUpdateRequest
         * @property {string|null} [id] AppUpdateRequest id
         * @property {string|null} [name] AppUpdateRequest name
         * @property {PlatformAdminApi.APP_TYPE|null} [type] AppUpdateRequest type
         * @property {PlatformAdminApi.USER_TYPE|null} [defaultUserType] AppUpdateRequest defaultUserType
         * @property {Array.<PlatformAdminApi.USER_TYPE>|null} [allowedUserType] AppUpdateRequest allowedUserType
         * @property {Array.<PlatformAdminApi.CONNECTION>|null} [allowedConnection] AppUpdateRequest allowedConnection
         * @property {boolean|null} [allowedAutoSignUp] AppUpdateRequest allowedAutoSignUp
         * @property {boolean|null} [allowedPublicSignUp] AppUpdateRequest allowedPublicSignUp
         * @property {string|null} [successRedirectUrl] AppUpdateRequest successRedirectUrl
         * @property {string|null} [failRedirectUrl] AppUpdateRequest failRedirectUrl
         * @property {string|null} [tenantId] AppUpdateRequest tenantId
         * @property {PlatformAdminApi.ITenant|null} [tenant] AppUpdateRequest tenant
         * @property {Array.<PlatformAdminApi.IGrant>|null} [grants] AppUpdateRequest grants
         */

        /**
         * Constructs a new AppUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AppUpdateRequest.
         * @implements IAppUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IAppUpdateRequest=} [properties] Properties to set
         */
        function AppUpdateRequest(properties) {
            this.allowedUserType = [];
            this.allowedConnection = [];
            this.grants = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.id = "";

        /**
         * AppUpdateRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.name = "";

        /**
         * AppUpdateRequest type.
         * @member {PlatformAdminApi.APP_TYPE} type
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.type = 0;

        /**
         * AppUpdateRequest defaultUserType.
         * @member {PlatformAdminApi.USER_TYPE} defaultUserType
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.defaultUserType = 0;

        /**
         * AppUpdateRequest allowedUserType.
         * @member {Array.<PlatformAdminApi.USER_TYPE>} allowedUserType
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.allowedUserType = $util.emptyArray;

        /**
         * AppUpdateRequest allowedConnection.
         * @member {Array.<PlatformAdminApi.CONNECTION>} allowedConnection
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.allowedConnection = $util.emptyArray;

        /**
         * AppUpdateRequest allowedAutoSignUp.
         * @member {boolean} allowedAutoSignUp
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.allowedAutoSignUp = false;

        /**
         * AppUpdateRequest allowedPublicSignUp.
         * @member {boolean} allowedPublicSignUp
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.allowedPublicSignUp = false;

        /**
         * AppUpdateRequest successRedirectUrl.
         * @member {string} successRedirectUrl
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.successRedirectUrl = "";

        /**
         * AppUpdateRequest failRedirectUrl.
         * @member {string} failRedirectUrl
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.failRedirectUrl = "";

        /**
         * AppUpdateRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.tenantId = "";

        /**
         * AppUpdateRequest tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.tenant = null;

        /**
         * AppUpdateRequest grants.
         * @member {Array.<PlatformAdminApi.IGrant>} grants
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @instance
         */
        AppUpdateRequest.prototype.grants = $util.emptyArray;

        /**
         * Encodes the specified AppUpdateRequest message. Does not implicitly {@link PlatformAdminApi.AppUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @static
         * @param {PlatformAdminApi.IAppUpdateRequest} message AppUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.defaultUserType != null && Object.hasOwnProperty.call(message, "defaultUserType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.defaultUserType);
            if (message.allowedUserType != null && message.allowedUserType.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.allowedUserType.length; ++i)
                    writer.int32(message.allowedUserType[i]);
                writer.ldelim();
            }
            if (message.allowedConnection != null && message.allowedConnection.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.allowedConnection.length; ++i)
                    writer.int32(message.allowedConnection[i]);
                writer.ldelim();
            }
            if (message.allowedAutoSignUp != null && Object.hasOwnProperty.call(message, "allowedAutoSignUp"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.allowedAutoSignUp);
            if (message.allowedPublicSignUp != null && Object.hasOwnProperty.call(message, "allowedPublicSignUp"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.allowedPublicSignUp);
            if (message.successRedirectUrl != null && Object.hasOwnProperty.call(message, "successRedirectUrl"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.successRedirectUrl);
            if (message.failRedirectUrl != null && Object.hasOwnProperty.call(message, "failRedirectUrl"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.failRedirectUrl);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.tenantId);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.grants != null && message.grants.length)
                for (var i = 0; i < message.grants.length; ++i)
                    $root.PlatformAdminApi.Grant.encode(message.grants[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AppUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AppUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AppUpdateRequest} AppUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AppUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.defaultUserType = reader.int32();
                    break;
                case 5:
                    if (!(message.allowedUserType && message.allowedUserType.length))
                        message.allowedUserType = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.allowedUserType.push(reader.int32());
                    } else
                        message.allowedUserType.push(reader.int32());
                    break;
                case 6:
                    if (!(message.allowedConnection && message.allowedConnection.length))
                        message.allowedConnection = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.allowedConnection.push(reader.int32());
                    } else
                        message.allowedConnection.push(reader.int32());
                    break;
                case 7:
                    message.allowedAutoSignUp = reader.bool();
                    break;
                case 8:
                    message.allowedPublicSignUp = reader.bool();
                    break;
                case 9:
                    message.successRedirectUrl = reader.string();
                    break;
                case 10:
                    message.failRedirectUrl = reader.string();
                    break;
                case 11:
                    message.tenantId = reader.string();
                    break;
                case 12:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                case 13:
                    if (!(message.grants && message.grants.length))
                        message.grants = [];
                    message.grants.push($root.PlatformAdminApi.Grant.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AppUpdateRequest;
    })();

    PlatformAdminApi.AppSearchRequest = (function() {

        /**
         * Properties of an AppSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IAppSearchRequest
         * @property {number|null} [page] AppSearchRequest page
         * @property {number|null} [limit] AppSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] AppSearchRequest sort
         * @property {Array.<PlatformAdminApi.AppSearchRequest.IWhereFields>|null} [where] AppSearchRequest where
         */

        /**
         * Constructs a new AppSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AppSearchRequest.
         * @implements IAppSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IAppSearchRequest=} [properties] Properties to set
         */
        function AppSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.AppSearchRequest
         * @instance
         */
        AppSearchRequest.prototype.page = 0;

        /**
         * AppSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.AppSearchRequest
         * @instance
         */
        AppSearchRequest.prototype.limit = 0;

        /**
         * AppSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.AppSearchRequest
         * @instance
         */
        AppSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * AppSearchRequest where.
         * @member {Array.<PlatformAdminApi.AppSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.AppSearchRequest
         * @instance
         */
        AppSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified AppSearchRequest message. Does not implicitly {@link PlatformAdminApi.AppSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AppSearchRequest
         * @static
         * @param {PlatformAdminApi.IAppSearchRequest} message AppSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.AppSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AppSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AppSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AppSearchRequest} AppSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AppSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.AppSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        AppSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.AppSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {boolean|null} [isSudo] WhereFields isSudo
             * @property {PlatformAdminApi.INumberCondition|null} [type] WhereFields type
             * @property {PlatformAdminApi.IStringCondition|null} [clientId] WhereFields clientId
             * @property {boolean|null} [allowedAutoSignUp] WhereFields allowedAutoSignUp
             * @property {boolean|null} [allowedPublicSignUp] WhereFields allowedPublicSignUp
             * @property {PlatformAdminApi.IUuidCondition|null} [tenantId] WhereFields tenantId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.AppSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.AppSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields isSudo.
             * @member {boolean} isSudo
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isSudo = false;

            /**
             * WhereFields type.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} type
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.type = null;

            /**
             * WhereFields clientId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} clientId
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.clientId = null;

            /**
             * WhereFields allowedAutoSignUp.
             * @member {boolean} allowedAutoSignUp
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.allowedAutoSignUp = false;

            /**
             * WhereFields allowedPublicSignUp.
             * @member {boolean} allowedPublicSignUp
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.allowedPublicSignUp = false;

            /**
             * WhereFields tenantId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} tenantId
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.tenantId = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.AppSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.AppSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.isSudo != null && Object.hasOwnProperty.call(message, "isSudo"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isSudo);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.type, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.clientId, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.allowedAutoSignUp != null && Object.hasOwnProperty.call(message, "allowedAutoSignUp"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.allowedAutoSignUp);
                if (message.allowedPublicSignUp != null && Object.hasOwnProperty.call(message, "allowedPublicSignUp"))
                    writer.uint32(/* id 11, wireType 0 =*/88).bool(message.allowedPublicSignUp);
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.tenantId, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.AppSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.AppSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AppSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.isDeleted = reader.bool();
                        break;
                    case 6:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.isSudo = reader.bool();
                        break;
                    case 8:
                        message.type = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.clientId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.allowedAutoSignUp = reader.bool();
                        break;
                    case 11:
                        message.allowedPublicSignUp = reader.bool();
                        break;
                    case 12:
                        message.tenantId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return AppSearchRequest;
    })();

    PlatformAdminApi.AppResponse = (function() {

        /**
         * Properties of an AppResponse.
         * @memberof PlatformAdminApi
         * @interface IAppResponse
         * @property {PlatformAdminApi.IApp|null} [data] AppResponse data
         */

        /**
         * Constructs a new AppResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AppResponse.
         * @implements IAppResponse
         * @constructor
         * @param {PlatformAdminApi.IAppResponse=} [properties] Properties to set
         */
        function AppResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppResponse data.
         * @member {PlatformAdminApi.IApp|null|undefined} data
         * @memberof PlatformAdminApi.AppResponse
         * @instance
         */
        AppResponse.prototype.data = null;

        /**
         * Encodes the specified AppResponse message. Does not implicitly {@link PlatformAdminApi.AppResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AppResponse
         * @static
         * @param {PlatformAdminApi.IAppResponse} message AppResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.App.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AppResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AppResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AppResponse} AppResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AppResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.App.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AppResponse;
    })();

    PlatformAdminApi.AppSearchResponse = (function() {

        /**
         * Properties of an AppSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IAppSearchResponse
         * @property {Array.<PlatformAdminApi.IApp>|null} [data] AppSearchResponse data
         * @property {number|null} [page] AppSearchResponse page
         * @property {number|null} [limit] AppSearchResponse limit
         * @property {number|null} [total] AppSearchResponse total
         */

        /**
         * Constructs a new AppSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AppSearchResponse.
         * @implements IAppSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IAppSearchResponse=} [properties] Properties to set
         */
        function AppSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppSearchResponse data.
         * @member {Array.<PlatformAdminApi.IApp>} data
         * @memberof PlatformAdminApi.AppSearchResponse
         * @instance
         */
        AppSearchResponse.prototype.data = $util.emptyArray;

        /**
         * AppSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.AppSearchResponse
         * @instance
         */
        AppSearchResponse.prototype.page = 0;

        /**
         * AppSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.AppSearchResponse
         * @instance
         */
        AppSearchResponse.prototype.limit = 0;

        /**
         * AppSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.AppSearchResponse
         * @instance
         */
        AppSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified AppSearchResponse message. Does not implicitly {@link PlatformAdminApi.AppSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AppSearchResponse
         * @static
         * @param {PlatformAdminApi.IAppSearchResponse} message AppSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.App.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes an AppSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AppSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AppSearchResponse} AppSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AppSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.App.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AppSearchResponse;
    })();

    PlatformAdminApi.App = (function() {

        /**
         * Properties of an App.
         * @memberof PlatformAdminApi
         * @interface IApp
         * @property {string|null} [id] App id
         * @property {string|null} [name] App name
         * @property {boolean|null} [isSudo] App isSudo
         * @property {PlatformAdminApi.APP_TYPE|null} [type] App type
         * @property {PlatformAdminApi.USER_TYPE|null} [defaultUserType] App defaultUserType
         * @property {Array.<PlatformAdminApi.USER_TYPE>|null} [allowedUserType] App allowedUserType
         * @property {Array.<PlatformAdminApi.CONNECTION>|null} [allowedConnection] App allowedConnection
         * @property {boolean|null} [allowedAutoSignUp] App allowedAutoSignUp
         * @property {boolean|null} [allowedPublicSignUp] App allowedPublicSignUp
         * @property {string|null} [clientId] App clientId
         * @property {string|null} [clientSecret] App clientSecret
         * @property {string|null} [successRedirectUrl] App successRedirectUrl
         * @property {string|null} [failRedirectUrl] App failRedirectUrl
         * @property {string|null} [tenantId] App tenantId
         * @property {PlatformAdminApi.ITenant|null} [tenant] App tenant
         * @property {string|null} [createdAt] App createdAt
         * @property {string|null} [updatedAt] App updatedAt
         * @property {boolean|null} [isDeleted] App isDeleted
         * @property {string|null} [deletedAt] App deletedAt
         * @property {Array.<PlatformAdminApi.IGrant>|null} [grants] App grants
         */

        /**
         * Constructs a new App.
         * @memberof PlatformAdminApi
         * @classdesc Represents an App.
         * @implements IApp
         * @constructor
         * @param {PlatformAdminApi.IApp=} [properties] Properties to set
         */
        function App(properties) {
            this.allowedUserType = [];
            this.allowedConnection = [];
            this.grants = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * App id.
         * @member {string} id
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.id = "";

        /**
         * App name.
         * @member {string} name
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.name = "";

        /**
         * App isSudo.
         * @member {boolean} isSudo
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.isSudo = false;

        /**
         * App type.
         * @member {PlatformAdminApi.APP_TYPE} type
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.type = 0;

        /**
         * App defaultUserType.
         * @member {PlatformAdminApi.USER_TYPE} defaultUserType
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.defaultUserType = 0;

        /**
         * App allowedUserType.
         * @member {Array.<PlatformAdminApi.USER_TYPE>} allowedUserType
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.allowedUserType = $util.emptyArray;

        /**
         * App allowedConnection.
         * @member {Array.<PlatformAdminApi.CONNECTION>} allowedConnection
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.allowedConnection = $util.emptyArray;

        /**
         * App allowedAutoSignUp.
         * @member {boolean} allowedAutoSignUp
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.allowedAutoSignUp = false;

        /**
         * App allowedPublicSignUp.
         * @member {boolean} allowedPublicSignUp
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.allowedPublicSignUp = false;

        /**
         * App clientId.
         * @member {string} clientId
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.clientId = "";

        /**
         * App clientSecret.
         * @member {string} clientSecret
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.clientSecret = "";

        /**
         * App successRedirectUrl.
         * @member {string} successRedirectUrl
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.successRedirectUrl = "";

        /**
         * App failRedirectUrl.
         * @member {string} failRedirectUrl
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.failRedirectUrl = "";

        /**
         * App tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.tenantId = "";

        /**
         * App tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.tenant = null;

        /**
         * App createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.createdAt = "";

        /**
         * App updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.updatedAt = "";

        /**
         * App isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.isDeleted = false;

        /**
         * App deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.deletedAt = "";

        /**
         * App grants.
         * @member {Array.<PlatformAdminApi.IGrant>} grants
         * @memberof PlatformAdminApi.App
         * @instance
         */
        App.prototype.grants = $util.emptyArray;

        /**
         * Encodes the specified App message. Does not implicitly {@link PlatformAdminApi.App.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.App
         * @static
         * @param {PlatformAdminApi.IApp} message App message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        App.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.isSudo != null && Object.hasOwnProperty.call(message, "isSudo"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isSudo);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
            if (message.defaultUserType != null && Object.hasOwnProperty.call(message, "defaultUserType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.defaultUserType);
            if (message.allowedUserType != null && message.allowedUserType.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.allowedUserType.length; ++i)
                    writer.int32(message.allowedUserType[i]);
                writer.ldelim();
            }
            if (message.allowedConnection != null && message.allowedConnection.length) {
                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                for (var i = 0; i < message.allowedConnection.length; ++i)
                    writer.int32(message.allowedConnection[i]);
                writer.ldelim();
            }
            if (message.allowedAutoSignUp != null && Object.hasOwnProperty.call(message, "allowedAutoSignUp"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.allowedAutoSignUp);
            if (message.allowedPublicSignUp != null && Object.hasOwnProperty.call(message, "allowedPublicSignUp"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.allowedPublicSignUp);
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.clientId);
            if (message.clientSecret != null && Object.hasOwnProperty.call(message, "clientSecret"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.clientSecret);
            if (message.successRedirectUrl != null && Object.hasOwnProperty.call(message, "successRedirectUrl"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.successRedirectUrl);
            if (message.failRedirectUrl != null && Object.hasOwnProperty.call(message, "failRedirectUrl"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.failRedirectUrl);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.tenantId);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.deletedAt);
            if (message.grants != null && message.grants.length)
                for (var i = 0; i < message.grants.length; ++i)
                    $root.PlatformAdminApi.Grant.encode(message.grants[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an App message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.App
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.App} App
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        App.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.App();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.isSudo = reader.bool();
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                case 5:
                    message.defaultUserType = reader.int32();
                    break;
                case 6:
                    if (!(message.allowedUserType && message.allowedUserType.length))
                        message.allowedUserType = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.allowedUserType.push(reader.int32());
                    } else
                        message.allowedUserType.push(reader.int32());
                    break;
                case 7:
                    if (!(message.allowedConnection && message.allowedConnection.length))
                        message.allowedConnection = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.allowedConnection.push(reader.int32());
                    } else
                        message.allowedConnection.push(reader.int32());
                    break;
                case 8:
                    message.allowedAutoSignUp = reader.bool();
                    break;
                case 9:
                    message.allowedPublicSignUp = reader.bool();
                    break;
                case 10:
                    message.clientId = reader.string();
                    break;
                case 11:
                    message.clientSecret = reader.string();
                    break;
                case 12:
                    message.successRedirectUrl = reader.string();
                    break;
                case 13:
                    message.failRedirectUrl = reader.string();
                    break;
                case 14:
                    message.tenantId = reader.string();
                    break;
                case 15:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.createdAt = reader.string();
                    break;
                case 17:
                    message.updatedAt = reader.string();
                    break;
                case 18:
                    message.isDeleted = reader.bool();
                    break;
                case 19:
                    message.deletedAt = reader.string();
                    break;
                case 20:
                    if (!(message.grants && message.grants.length))
                        message.grants = [];
                    message.grants.push($root.PlatformAdminApi.Grant.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return App;
    })();

    PlatformAdminApi.Grant = (function() {

        /**
         * Properties of a Grant.
         * @memberof PlatformAdminApi
         * @interface IGrant
         * @property {string|null} [id] Grant id
         * @property {string|null} [createdAt] Grant createdAt
         * @property {string|null} [updatedAt] Grant updatedAt
         * @property {boolean|null} [isDeleted] Grant isDeleted
         * @property {string|null} [deletedAt] Grant deletedAt
         * @property {string|null} [slug] Grant slug
         * @property {string|null} [appId] Grant appId
         * @property {string|null} [apiId] Grant apiId
         * @property {PlatformAdminApi.IApi|null} [api] Grant api
         * @property {Array.<PlatformAdminApi.IGrantPermission>|null} [grantPermissions] Grant grantPermissions
         */

        /**
         * Constructs a new Grant.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Grant.
         * @implements IGrant
         * @constructor
         * @param {PlatformAdminApi.IGrant=} [properties] Properties to set
         */
        function Grant(properties) {
            this.grantPermissions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Grant id.
         * @member {string} id
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.id = "";

        /**
         * Grant createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.createdAt = "";

        /**
         * Grant updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.updatedAt = "";

        /**
         * Grant isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.isDeleted = false;

        /**
         * Grant deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.deletedAt = "";

        /**
         * Grant slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.slug = "";

        /**
         * Grant appId.
         * @member {string} appId
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.appId = "";

        /**
         * Grant apiId.
         * @member {string} apiId
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.apiId = "";

        /**
         * Grant api.
         * @member {PlatformAdminApi.IApi|null|undefined} api
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.api = null;

        /**
         * Grant grantPermissions.
         * @member {Array.<PlatformAdminApi.IGrantPermission>} grantPermissions
         * @memberof PlatformAdminApi.Grant
         * @instance
         */
        Grant.prototype.grantPermissions = $util.emptyArray;

        /**
         * Encodes the specified Grant message. Does not implicitly {@link PlatformAdminApi.Grant.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Grant
         * @static
         * @param {PlatformAdminApi.IGrant} message Grant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Grant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.slug);
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.appId);
            if (message.apiId != null && Object.hasOwnProperty.call(message, "apiId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.apiId);
            if (message.api != null && Object.hasOwnProperty.call(message, "api"))
                $root.PlatformAdminApi.Api.encode(message.api, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.grantPermissions != null && message.grantPermissions.length)
                for (var i = 0; i < message.grantPermissions.length; ++i)
                    $root.PlatformAdminApi.GrantPermission.encode(message.grantPermissions[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Grant message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Grant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Grant} Grant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Grant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Grant();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.slug = reader.string();
                    break;
                case 7:
                    message.appId = reader.string();
                    break;
                case 8:
                    message.apiId = reader.string();
                    break;
                case 9:
                    message.api = $root.PlatformAdminApi.Api.decode(reader, reader.uint32());
                    break;
                case 10:
                    if (!(message.grantPermissions && message.grantPermissions.length))
                        message.grantPermissions = [];
                    message.grantPermissions.push($root.PlatformAdminApi.GrantPermission.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Grant;
    })();

    PlatformAdminApi.GrantPermission = (function() {

        /**
         * Properties of a GrantPermission.
         * @memberof PlatformAdminApi
         * @interface IGrantPermission
         * @property {string|null} [id] GrantPermission id
         * @property {string|null} [createdAt] GrantPermission createdAt
         * @property {string|null} [updatedAt] GrantPermission updatedAt
         * @property {boolean|null} [isDeleted] GrantPermission isDeleted
         * @property {string|null} [deletedAt] GrantPermission deletedAt
         * @property {string|null} [name] GrantPermission name
         * @property {string|null} [key] GrantPermission key
         * @property {string|null} [description] GrantPermission description
         */

        /**
         * Constructs a new GrantPermission.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GrantPermission.
         * @implements IGrantPermission
         * @constructor
         * @param {PlatformAdminApi.IGrantPermission=} [properties] Properties to set
         */
        function GrantPermission(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GrantPermission id.
         * @member {string} id
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.id = "";

        /**
         * GrantPermission createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.createdAt = "";

        /**
         * GrantPermission updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.updatedAt = "";

        /**
         * GrantPermission isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.isDeleted = false;

        /**
         * GrantPermission deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.deletedAt = "";

        /**
         * GrantPermission name.
         * @member {string} name
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.name = "";

        /**
         * GrantPermission key.
         * @member {string} key
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.key = "";

        /**
         * GrantPermission description.
         * @member {string} description
         * @memberof PlatformAdminApi.GrantPermission
         * @instance
         */
        GrantPermission.prototype.description = "";

        /**
         * Encodes the specified GrantPermission message. Does not implicitly {@link PlatformAdminApi.GrantPermission.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GrantPermission
         * @static
         * @param {PlatformAdminApi.IGrantPermission} message GrantPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GrantPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAt);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.key);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.description);
            return writer;
        };

        /**
         * Decodes a GrantPermission message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GrantPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GrantPermission} GrantPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GrantPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GrantPermission();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAt = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.key = reader.string();
                    break;
                case 8:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GrantPermission;
    })();

    PlatformAdminApi.GrantUpsertRequest = (function() {

        /**
         * Properties of a GrantUpsertRequest.
         * @memberof PlatformAdminApi
         * @interface IGrantUpsertRequest
         * @property {string|null} [id] GrantUpsertRequest id
         * @property {string|null} [appId] GrantUpsertRequest appId
         * @property {string|null} [apiId] GrantUpsertRequest apiId
         * @property {Array.<string>|null} [apiPermissionIds] GrantUpsertRequest apiPermissionIds
         */

        /**
         * Constructs a new GrantUpsertRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GrantUpsertRequest.
         * @implements IGrantUpsertRequest
         * @constructor
         * @param {PlatformAdminApi.IGrantUpsertRequest=} [properties] Properties to set
         */
        function GrantUpsertRequest(properties) {
            this.apiPermissionIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GrantUpsertRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.GrantUpsertRequest
         * @instance
         */
        GrantUpsertRequest.prototype.id = "";

        /**
         * GrantUpsertRequest appId.
         * @member {string} appId
         * @memberof PlatformAdminApi.GrantUpsertRequest
         * @instance
         */
        GrantUpsertRequest.prototype.appId = "";

        /**
         * GrantUpsertRequest apiId.
         * @member {string} apiId
         * @memberof PlatformAdminApi.GrantUpsertRequest
         * @instance
         */
        GrantUpsertRequest.prototype.apiId = "";

        /**
         * GrantUpsertRequest apiPermissionIds.
         * @member {Array.<string>} apiPermissionIds
         * @memberof PlatformAdminApi.GrantUpsertRequest
         * @instance
         */
        GrantUpsertRequest.prototype.apiPermissionIds = $util.emptyArray;

        /**
         * Encodes the specified GrantUpsertRequest message. Does not implicitly {@link PlatformAdminApi.GrantUpsertRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GrantUpsertRequest
         * @static
         * @param {PlatformAdminApi.IGrantUpsertRequest} message GrantUpsertRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GrantUpsertRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.appId);
            if (message.apiId != null && Object.hasOwnProperty.call(message, "apiId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.apiId);
            if (message.apiPermissionIds != null && message.apiPermissionIds.length)
                for (var i = 0; i < message.apiPermissionIds.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.apiPermissionIds[i]);
            return writer;
        };

        /**
         * Decodes a GrantUpsertRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GrantUpsertRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GrantUpsertRequest} GrantUpsertRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GrantUpsertRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GrantUpsertRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.appId = reader.string();
                    break;
                case 3:
                    message.apiId = reader.string();
                    break;
                case 4:
                    if (!(message.apiPermissionIds && message.apiPermissionIds.length))
                        message.apiPermissionIds = [];
                    message.apiPermissionIds.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GrantUpsertRequest;
    })();

    PlatformAdminApi.FeedbackService = (function() {

        /**
         * Constructs a new FeedbackService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FeedbackService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function FeedbackService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (FeedbackService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = FeedbackService;

        /**
         * Callback as used by {@link PlatformAdminApi.FeedbackService#create}.
         * @memberof PlatformAdminApi.FeedbackService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FeedbackResponse} [response] FeedbackResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IFeedbackCreateRequest} request FeedbackCreateRequest message or plain object
         * @param {PlatformAdminApi.FeedbackService.CreateCallback} callback Node-style callback called with the error, if any, and FeedbackResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FeedbackService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.FeedbackCreateRequest, $root.PlatformAdminApi.FeedbackResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IFeedbackCreateRequest} request FeedbackCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FeedbackResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.FeedbackService#update}.
         * @memberof PlatformAdminApi.FeedbackService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FeedbackResponse} [response] FeedbackResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IFeedbackUpdateRequest} request FeedbackUpdateRequest message or plain object
         * @param {PlatformAdminApi.FeedbackService.UpdateCallback} callback Node-style callback called with the error, if any, and FeedbackResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FeedbackService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.FeedbackUpdateRequest, $root.PlatformAdminApi.FeedbackResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IFeedbackUpdateRequest} request FeedbackUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FeedbackResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.FeedbackService#search}.
         * @memberof PlatformAdminApi.FeedbackService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FeedbackSearchResponse} [response] FeedbackSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IFeedbackSearchRequest} request FeedbackSearchRequest message or plain object
         * @param {PlatformAdminApi.FeedbackService.SearchCallback} callback Node-style callback called with the error, if any, and FeedbackSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FeedbackService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.FeedbackSearchRequest, $root.PlatformAdminApi.FeedbackSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IFeedbackSearchRequest} request FeedbackSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FeedbackSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.FeedbackService#findById}.
         * @memberof PlatformAdminApi.FeedbackService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FeedbackResponse} [response] FeedbackResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.FeedbackService.FindByIdCallback} callback Node-style callback called with the error, if any, and FeedbackResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FeedbackService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.FeedbackResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FeedbackResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.FeedbackService#remove}.
         * @memberof PlatformAdminApi.FeedbackService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FeedbackResponse} [response] FeedbackResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.FeedbackService.RemoveCallback} callback Node-style callback called with the error, if any, and FeedbackResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FeedbackService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.FeedbackResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FeedbackResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.FeedbackService#restore}.
         * @memberof PlatformAdminApi.FeedbackService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FeedbackResponse} [response] FeedbackResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.FeedbackService.RestoreCallback} callback Node-style callback called with the error, if any, and FeedbackResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FeedbackService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.FeedbackResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.FeedbackService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FeedbackResponse>} Promise
         * @variation 2
         */

        return FeedbackService;
    })();

    PlatformAdminApi.FeedbackCreateRequest = (function() {

        /**
         * Properties of a FeedbackCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IFeedbackCreateRequest
         * @property {number|null} [source] FeedbackCreateRequest source
         * @property {number|null} [ratingType] FeedbackCreateRequest ratingType
         * @property {number|null} [rating] FeedbackCreateRequest rating
         * @property {string|null} [clientId] FeedbackCreateRequest clientId
         * @property {string|null} [reviewerName] FeedbackCreateRequest reviewerName
         * @property {string|null} [comment] FeedbackCreateRequest comment
         * @property {string|null} [reviewerContact] FeedbackCreateRequest reviewerContact
         * @property {string|null} [relationId] FeedbackCreateRequest relationId
         * @property {number|null} [relationType] FeedbackCreateRequest relationType
         * @property {Array.<PlatformAdminApi.IFeedbackLink>|null} [links] FeedbackCreateRequest links
         * @property {string|null} [tenantId] FeedbackCreateRequest tenantId
         * @property {PlatformAdminApi.ITenant|null} [tenant] FeedbackCreateRequest tenant
         * @property {PlatformAdminApi.IUuidRequest|null} [user] FeedbackCreateRequest user
         * @property {PlatformAdminApi.IUuidRequest|null} [relation] FeedbackCreateRequest relation
         */

        /**
         * Constructs a new FeedbackCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FeedbackCreateRequest.
         * @implements IFeedbackCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IFeedbackCreateRequest=} [properties] Properties to set
         */
        function FeedbackCreateRequest(properties) {
            this.links = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeedbackCreateRequest source.
         * @member {number} source
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.source = 0;

        /**
         * FeedbackCreateRequest ratingType.
         * @member {number} ratingType
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.ratingType = 0;

        /**
         * FeedbackCreateRequest rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.rating = 0;

        /**
         * FeedbackCreateRequest clientId.
         * @member {string} clientId
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.clientId = "";

        /**
         * FeedbackCreateRequest reviewerName.
         * @member {string} reviewerName
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.reviewerName = "";

        /**
         * FeedbackCreateRequest comment.
         * @member {string} comment
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.comment = "";

        /**
         * FeedbackCreateRequest reviewerContact.
         * @member {string} reviewerContact
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.reviewerContact = "";

        /**
         * FeedbackCreateRequest relationId.
         * @member {string} relationId
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.relationId = "";

        /**
         * FeedbackCreateRequest relationType.
         * @member {number} relationType
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.relationType = 0;

        /**
         * FeedbackCreateRequest links.
         * @member {Array.<PlatformAdminApi.IFeedbackLink>} links
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.links = $util.emptyArray;

        /**
         * FeedbackCreateRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.tenantId = "";

        /**
         * FeedbackCreateRequest tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.tenant = null;

        /**
         * FeedbackCreateRequest user.
         * @member {PlatformAdminApi.IUuidRequest|null|undefined} user
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.user = null;

        /**
         * FeedbackCreateRequest relation.
         * @member {PlatformAdminApi.IUuidRequest|null|undefined} relation
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @instance
         */
        FeedbackCreateRequest.prototype.relation = null;

        /**
         * Encodes the specified FeedbackCreateRequest message. Does not implicitly {@link PlatformAdminApi.FeedbackCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @static
         * @param {PlatformAdminApi.IFeedbackCreateRequest} message FeedbackCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedbackCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
            if (message.ratingType != null && Object.hasOwnProperty.call(message, "ratingType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ratingType);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rating);
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.clientId);
            if (message.reviewerName != null && Object.hasOwnProperty.call(message, "reviewerName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.reviewerName);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.comment);
            if (message.reviewerContact != null && Object.hasOwnProperty.call(message, "reviewerContact"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.reviewerContact);
            if (message.relationId != null && Object.hasOwnProperty.call(message, "relationId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.relationId);
            if (message.relationType != null && Object.hasOwnProperty.call(message, "relationType"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.relationType);
            if (message.links != null && message.links.length)
                for (var i = 0; i < message.links.length; ++i)
                    $root.PlatformAdminApi.FeedbackLink.encode(message.links[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.tenantId);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.PlatformAdminApi.UuidRequest.encode(message.user, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.relation != null && Object.hasOwnProperty.call(message, "relation"))
                $root.PlatformAdminApi.UuidRequest.encode(message.relation, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FeedbackCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FeedbackCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FeedbackCreateRequest} FeedbackCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedbackCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FeedbackCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.source = reader.int32();
                    break;
                case 2:
                    message.ratingType = reader.int32();
                    break;
                case 3:
                    message.rating = reader.int32();
                    break;
                case 4:
                    message.clientId = reader.string();
                    break;
                case 5:
                    message.reviewerName = reader.string();
                    break;
                case 6:
                    message.comment = reader.string();
                    break;
                case 7:
                    message.reviewerContact = reader.string();
                    break;
                case 8:
                    message.relationId = reader.string();
                    break;
                case 9:
                    message.relationType = reader.int32();
                    break;
                case 10:
                    if (!(message.links && message.links.length))
                        message.links = [];
                    message.links.push($root.PlatformAdminApi.FeedbackLink.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.tenantId = reader.string();
                    break;
                case 12:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.user = $root.PlatformAdminApi.UuidRequest.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.relation = $root.PlatformAdminApi.UuidRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FeedbackCreateRequest;
    })();

    PlatformAdminApi.FeedbackUpdateRequest = (function() {

        /**
         * Properties of a FeedbackUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IFeedbackUpdateRequest
         * @property {string|null} [id] FeedbackUpdateRequest id
         * @property {number|null} [source] FeedbackUpdateRequest source
         * @property {number|null} [ratingType] FeedbackUpdateRequest ratingType
         * @property {number|null} [rating] FeedbackUpdateRequest rating
         * @property {string|null} [clientId] FeedbackUpdateRequest clientId
         * @property {string|null} [reviewerName] FeedbackUpdateRequest reviewerName
         * @property {string|null} [comment] FeedbackUpdateRequest comment
         * @property {string|null} [reviewerContact] FeedbackUpdateRequest reviewerContact
         * @property {string|null} [relationId] FeedbackUpdateRequest relationId
         * @property {number|null} [relationType] FeedbackUpdateRequest relationType
         * @property {Array.<PlatformAdminApi.IFeedbackLink>|null} [links] FeedbackUpdateRequest links
         * @property {PlatformAdminApi.IUuidRequest|null} [user] FeedbackUpdateRequest user
         * @property {PlatformAdminApi.IUuidRequest|null} [relation] FeedbackUpdateRequest relation
         */

        /**
         * Constructs a new FeedbackUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FeedbackUpdateRequest.
         * @implements IFeedbackUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IFeedbackUpdateRequest=} [properties] Properties to set
         */
        function FeedbackUpdateRequest(properties) {
            this.links = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeedbackUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.id = "";

        /**
         * FeedbackUpdateRequest source.
         * @member {number} source
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.source = 0;

        /**
         * FeedbackUpdateRequest ratingType.
         * @member {number} ratingType
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.ratingType = 0;

        /**
         * FeedbackUpdateRequest rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.rating = 0;

        /**
         * FeedbackUpdateRequest clientId.
         * @member {string} clientId
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.clientId = "";

        /**
         * FeedbackUpdateRequest reviewerName.
         * @member {string} reviewerName
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.reviewerName = "";

        /**
         * FeedbackUpdateRequest comment.
         * @member {string} comment
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.comment = "";

        /**
         * FeedbackUpdateRequest reviewerContact.
         * @member {string} reviewerContact
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.reviewerContact = "";

        /**
         * FeedbackUpdateRequest relationId.
         * @member {string} relationId
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.relationId = "";

        /**
         * FeedbackUpdateRequest relationType.
         * @member {number} relationType
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.relationType = 0;

        /**
         * FeedbackUpdateRequest links.
         * @member {Array.<PlatformAdminApi.IFeedbackLink>} links
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.links = $util.emptyArray;

        /**
         * FeedbackUpdateRequest user.
         * @member {PlatformAdminApi.IUuidRequest|null|undefined} user
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.user = null;

        /**
         * FeedbackUpdateRequest relation.
         * @member {PlatformAdminApi.IUuidRequest|null|undefined} relation
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @instance
         */
        FeedbackUpdateRequest.prototype.relation = null;

        /**
         * Encodes the specified FeedbackUpdateRequest message. Does not implicitly {@link PlatformAdminApi.FeedbackUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @static
         * @param {PlatformAdminApi.IFeedbackUpdateRequest} message FeedbackUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedbackUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.source);
            if (message.ratingType != null && Object.hasOwnProperty.call(message, "ratingType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ratingType);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.rating);
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.clientId);
            if (message.reviewerName != null && Object.hasOwnProperty.call(message, "reviewerName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.reviewerName);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.comment);
            if (message.reviewerContact != null && Object.hasOwnProperty.call(message, "reviewerContact"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.reviewerContact);
            if (message.relationId != null && Object.hasOwnProperty.call(message, "relationId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.relationId);
            if (message.relationType != null && Object.hasOwnProperty.call(message, "relationType"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.relationType);
            if (message.links != null && message.links.length)
                for (var i = 0; i < message.links.length; ++i)
                    $root.PlatformAdminApi.FeedbackLink.encode(message.links[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.PlatformAdminApi.UuidRequest.encode(message.user, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.relation != null && Object.hasOwnProperty.call(message, "relation"))
                $root.PlatformAdminApi.UuidRequest.encode(message.relation, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FeedbackUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FeedbackUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FeedbackUpdateRequest} FeedbackUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedbackUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FeedbackUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.source = reader.int32();
                    break;
                case 3:
                    message.ratingType = reader.int32();
                    break;
                case 4:
                    message.rating = reader.int32();
                    break;
                case 5:
                    message.clientId = reader.string();
                    break;
                case 6:
                    message.reviewerName = reader.string();
                    break;
                case 7:
                    message.comment = reader.string();
                    break;
                case 8:
                    message.reviewerContact = reader.string();
                    break;
                case 9:
                    message.relationId = reader.string();
                    break;
                case 10:
                    message.relationType = reader.int32();
                    break;
                case 11:
                    if (!(message.links && message.links.length))
                        message.links = [];
                    message.links.push($root.PlatformAdminApi.FeedbackLink.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.user = $root.PlatformAdminApi.UuidRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.relation = $root.PlatformAdminApi.UuidRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FeedbackUpdateRequest;
    })();

    PlatformAdminApi.FeedbackSearchRequest = (function() {

        /**
         * Properties of a FeedbackSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IFeedbackSearchRequest
         * @property {number|null} [page] FeedbackSearchRequest page
         * @property {number|null} [limit] FeedbackSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] FeedbackSearchRequest sort
         * @property {Array.<PlatformAdminApi.FeedbackSearchRequest.IWhereFields>|null} [where] FeedbackSearchRequest where
         */

        /**
         * Constructs a new FeedbackSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FeedbackSearchRequest.
         * @implements IFeedbackSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IFeedbackSearchRequest=} [properties] Properties to set
         */
        function FeedbackSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeedbackSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.FeedbackSearchRequest
         * @instance
         */
        FeedbackSearchRequest.prototype.page = 0;

        /**
         * FeedbackSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.FeedbackSearchRequest
         * @instance
         */
        FeedbackSearchRequest.prototype.limit = 0;

        /**
         * FeedbackSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.FeedbackSearchRequest
         * @instance
         */
        FeedbackSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * FeedbackSearchRequest where.
         * @member {Array.<PlatformAdminApi.FeedbackSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.FeedbackSearchRequest
         * @instance
         */
        FeedbackSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified FeedbackSearchRequest message. Does not implicitly {@link PlatformAdminApi.FeedbackSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FeedbackSearchRequest
         * @static
         * @param {PlatformAdminApi.IFeedbackSearchRequest} message FeedbackSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedbackSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.FeedbackSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FeedbackSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FeedbackSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FeedbackSearchRequest} FeedbackSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedbackSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FeedbackSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.FeedbackSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        FeedbackSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.FeedbackSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.INumberCondition|null} [relationType] WhereFields relationType
             * @property {PlatformAdminApi.INumberCondition|null} [source] WhereFields source
             * @property {PlatformAdminApi.IStringCondition|null} [clientId] WhereFields clientId
             * @property {PlatformAdminApi.INumberCondition|null} [ratingType] WhereFields ratingType
             * @property {PlatformAdminApi.INumberCondition|null} [ratingPercent] WhereFields ratingPercent
             * @property {PlatformAdminApi.INumberCondition|null} [rating] WhereFields rating
             * @property {PlatformAdminApi.IStringCondition|null} [reviewerName] WhereFields reviewerName
             * @property {PlatformAdminApi.IStringCondition|null} [comment] WhereFields comment
             * @property {PlatformAdminApi.IStringCondition|null} [reviewerContact] WhereFields reviewerContact
             * @property {PlatformAdminApi.IStringCondition|null} [relationId] WhereFields relationId
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {PlatformAdminApi.IUuidCondition|null} [tenantId] WhereFields tenantId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.FeedbackSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.FeedbackSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields relationType.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} relationType
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.relationType = null;

            /**
             * WhereFields source.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} source
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.source = null;

            /**
             * WhereFields clientId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} clientId
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.clientId = null;

            /**
             * WhereFields ratingType.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} ratingType
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.ratingType = null;

            /**
             * WhereFields ratingPercent.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} ratingPercent
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.ratingPercent = null;

            /**
             * WhereFields rating.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} rating
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.rating = null;

            /**
             * WhereFields reviewerName.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} reviewerName
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.reviewerName = null;

            /**
             * WhereFields comment.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} comment
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.comment = null;

            /**
             * WhereFields reviewerContact.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} reviewerContact
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.reviewerContact = null;

            /**
             * WhereFields relationId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} relationId
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.relationId = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields tenantId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} tenantId
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.tenantId = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.FeedbackSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.FeedbackSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.relationType != null && Object.hasOwnProperty.call(message, "relationType"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.relationType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.source, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.clientId, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.ratingType != null && Object.hasOwnProperty.call(message, "ratingType"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.ratingType, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.ratingPercent != null && Object.hasOwnProperty.call(message, "ratingPercent"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.ratingPercent, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.rating, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.reviewerName != null && Object.hasOwnProperty.call(message, "reviewerName"))
                    $root.PlatformAdminApi.StringCondition.encode(message.reviewerName, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                    $root.PlatformAdminApi.StringCondition.encode(message.comment, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reviewerContact != null && Object.hasOwnProperty.call(message, "reviewerContact"))
                    $root.PlatformAdminApi.StringCondition.encode(message.reviewerContact, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.relationId != null && Object.hasOwnProperty.call(message, "relationId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.relationId, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isDeleted);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.tenantId, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.FeedbackSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.FeedbackSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FeedbackSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.relationType = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.source = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.clientId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.ratingType = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.ratingPercent = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.rating = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.reviewerName = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.comment = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.reviewerContact = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.relationId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.isDeleted = reader.bool();
                        break;
                    case 13:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.tenantId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return FeedbackSearchRequest;
    })();

    PlatformAdminApi.FeedbackResponse = (function() {

        /**
         * Properties of a FeedbackResponse.
         * @memberof PlatformAdminApi
         * @interface IFeedbackResponse
         * @property {PlatformAdminApi.IFeedback|null} [data] FeedbackResponse data
         */

        /**
         * Constructs a new FeedbackResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FeedbackResponse.
         * @implements IFeedbackResponse
         * @constructor
         * @param {PlatformAdminApi.IFeedbackResponse=} [properties] Properties to set
         */
        function FeedbackResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeedbackResponse data.
         * @member {PlatformAdminApi.IFeedback|null|undefined} data
         * @memberof PlatformAdminApi.FeedbackResponse
         * @instance
         */
        FeedbackResponse.prototype.data = null;

        /**
         * Encodes the specified FeedbackResponse message. Does not implicitly {@link PlatformAdminApi.FeedbackResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FeedbackResponse
         * @static
         * @param {PlatformAdminApi.IFeedbackResponse} message FeedbackResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedbackResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Feedback.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FeedbackResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FeedbackResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FeedbackResponse} FeedbackResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedbackResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FeedbackResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Feedback.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FeedbackResponse;
    })();

    PlatformAdminApi.FeedbackSearchResponse = (function() {

        /**
         * Properties of a FeedbackSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IFeedbackSearchResponse
         * @property {Array.<PlatformAdminApi.IFeedback>|null} [data] FeedbackSearchResponse data
         * @property {number|null} [page] FeedbackSearchResponse page
         * @property {number|null} [pageCount] FeedbackSearchResponse pageCount
         * @property {number|null} [limit] FeedbackSearchResponse limit
         * @property {number|null} [count] FeedbackSearchResponse count
         * @property {number|null} [total] FeedbackSearchResponse total
         */

        /**
         * Constructs a new FeedbackSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FeedbackSearchResponse.
         * @implements IFeedbackSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IFeedbackSearchResponse=} [properties] Properties to set
         */
        function FeedbackSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeedbackSearchResponse data.
         * @member {Array.<PlatformAdminApi.IFeedback>} data
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @instance
         */
        FeedbackSearchResponse.prototype.data = $util.emptyArray;

        /**
         * FeedbackSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @instance
         */
        FeedbackSearchResponse.prototype.page = 0;

        /**
         * FeedbackSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @instance
         */
        FeedbackSearchResponse.prototype.pageCount = 0;

        /**
         * FeedbackSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @instance
         */
        FeedbackSearchResponse.prototype.limit = 0;

        /**
         * FeedbackSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @instance
         */
        FeedbackSearchResponse.prototype.count = 0;

        /**
         * FeedbackSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @instance
         */
        FeedbackSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified FeedbackSearchResponse message. Does not implicitly {@link PlatformAdminApi.FeedbackSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @static
         * @param {PlatformAdminApi.IFeedbackSearchResponse} message FeedbackSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedbackSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Feedback.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a FeedbackSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FeedbackSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FeedbackSearchResponse} FeedbackSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedbackSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FeedbackSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Feedback.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FeedbackSearchResponse;
    })();

    PlatformAdminApi.Feedback = (function() {

        /**
         * Properties of a Feedback.
         * @memberof PlatformAdminApi
         * @interface IFeedback
         * @property {string|null} [id] Feedback id
         * @property {number|null} [source] Feedback source
         * @property {number|null} [ratingPercent] Feedback ratingPercent
         * @property {number|null} [ratingType] Feedback ratingType
         * @property {number|null} [rating] Feedback rating
         * @property {number|null} [relationType] Feedback relationType
         * @property {string|null} [relationId] Feedback relationId
         * @property {string|null} [clientId] Feedback clientId
         * @property {string|null} [tenantId] Feedback tenantId
         * @property {string|null} [reviewerName] Feedback reviewerName
         * @property {string|null} [reviewerContact] Feedback reviewerContact
         * @property {string|null} [comment] Feedback comment
         * @property {Array.<PlatformAdminApi.IFeedbackLink>|null} [links] Feedback links
         * @property {string|null} [createdAt] Feedback createdAt
         * @property {string|null} [updatedAt] Feedback updatedAt
         * @property {string|null} [deletedAt] Feedback deletedAt
         * @property {boolean|null} [isDeleted] Feedback isDeleted
         * @property {PlatformAdminApi.ITenant|null} [tenant] Feedback tenant
         * @property {PlatformAdminApi.IUuidRequest|null} [user] Feedback user
         * @property {PlatformAdminApi.IUuidRequest|null} [relation] Feedback relation
         */

        /**
         * Constructs a new Feedback.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Feedback.
         * @implements IFeedback
         * @constructor
         * @param {PlatformAdminApi.IFeedback=} [properties] Properties to set
         */
        function Feedback(properties) {
            this.links = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Feedback id.
         * @member {string} id
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.id = "";

        /**
         * Feedback source.
         * @member {number} source
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.source = 0;

        /**
         * Feedback ratingPercent.
         * @member {number} ratingPercent
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.ratingPercent = 0;

        /**
         * Feedback ratingType.
         * @member {number} ratingType
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.ratingType = 0;

        /**
         * Feedback rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.rating = 0;

        /**
         * Feedback relationType.
         * @member {number} relationType
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.relationType = 0;

        /**
         * Feedback relationId.
         * @member {string} relationId
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.relationId = "";

        /**
         * Feedback clientId.
         * @member {string} clientId
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.clientId = "";

        /**
         * Feedback tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.tenantId = "";

        /**
         * Feedback reviewerName.
         * @member {string} reviewerName
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.reviewerName = "";

        /**
         * Feedback reviewerContact.
         * @member {string} reviewerContact
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.reviewerContact = "";

        /**
         * Feedback comment.
         * @member {string} comment
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.comment = "";

        /**
         * Feedback links.
         * @member {Array.<PlatformAdminApi.IFeedbackLink>} links
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.links = $util.emptyArray;

        /**
         * Feedback createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.createdAt = "";

        /**
         * Feedback updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.updatedAt = "";

        /**
         * Feedback deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.deletedAt = "";

        /**
         * Feedback isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.isDeleted = false;

        /**
         * Feedback tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.tenant = null;

        /**
         * Feedback user.
         * @member {PlatformAdminApi.IUuidRequest|null|undefined} user
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.user = null;

        /**
         * Feedback relation.
         * @member {PlatformAdminApi.IUuidRequest|null|undefined} relation
         * @memberof PlatformAdminApi.Feedback
         * @instance
         */
        Feedback.prototype.relation = null;

        /**
         * Encodes the specified Feedback message. Does not implicitly {@link PlatformAdminApi.Feedback.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Feedback
         * @static
         * @param {PlatformAdminApi.IFeedback} message Feedback message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Feedback.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.source);
            if (message.ratingPercent != null && Object.hasOwnProperty.call(message, "ratingPercent"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.ratingPercent);
            if (message.ratingType != null && Object.hasOwnProperty.call(message, "ratingType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ratingType);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.rating);
            if (message.relationType != null && Object.hasOwnProperty.call(message, "relationType"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.relationType);
            if (message.relationId != null && Object.hasOwnProperty.call(message, "relationId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.relationId);
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.clientId);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.tenantId);
            if (message.reviewerName != null && Object.hasOwnProperty.call(message, "reviewerName"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.reviewerName);
            if (message.reviewerContact != null && Object.hasOwnProperty.call(message, "reviewerContact"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.reviewerContact);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.comment);
            if (message.links != null && message.links.length)
                for (var i = 0; i < message.links.length; ++i)
                    $root.PlatformAdminApi.FeedbackLink.encode(message.links[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.deletedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.isDeleted);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.PlatformAdminApi.UuidRequest.encode(message.user, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.relation != null && Object.hasOwnProperty.call(message, "relation"))
                $root.PlatformAdminApi.UuidRequest.encode(message.relation, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Feedback message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Feedback
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Feedback} Feedback
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Feedback.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Feedback();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.source = reader.int32();
                    break;
                case 3:
                    message.ratingPercent = reader.double();
                    break;
                case 4:
                    message.ratingType = reader.int32();
                    break;
                case 5:
                    message.rating = reader.int32();
                    break;
                case 6:
                    message.relationType = reader.int32();
                    break;
                case 7:
                    message.relationId = reader.string();
                    break;
                case 8:
                    message.clientId = reader.string();
                    break;
                case 9:
                    message.tenantId = reader.string();
                    break;
                case 10:
                    message.reviewerName = reader.string();
                    break;
                case 11:
                    message.reviewerContact = reader.string();
                    break;
                case 12:
                    message.comment = reader.string();
                    break;
                case 13:
                    if (!(message.links && message.links.length))
                        message.links = [];
                    message.links.push($root.PlatformAdminApi.FeedbackLink.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.createdAt = reader.string();
                    break;
                case 15:
                    message.updatedAt = reader.string();
                    break;
                case 16:
                    message.deletedAt = reader.string();
                    break;
                case 17:
                    message.isDeleted = reader.bool();
                    break;
                case 18:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.user = $root.PlatformAdminApi.UuidRequest.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.relation = $root.PlatformAdminApi.UuidRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Feedback;
    })();

    PlatformAdminApi.FeedbackLink = (function() {

        /**
         * Properties of a FeedbackLink.
         * @memberof PlatformAdminApi
         * @interface IFeedbackLink
         * @property {string|null} [id] FeedbackLink id
         * @property {string|null} [linkType] FeedbackLink linkType
         * @property {string|null} [linkName] FeedbackLink linkName
         * @property {string|null} [linkId] FeedbackLink linkId
         */

        /**
         * Constructs a new FeedbackLink.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FeedbackLink.
         * @implements IFeedbackLink
         * @constructor
         * @param {PlatformAdminApi.IFeedbackLink=} [properties] Properties to set
         */
        function FeedbackLink(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeedbackLink id.
         * @member {string} id
         * @memberof PlatformAdminApi.FeedbackLink
         * @instance
         */
        FeedbackLink.prototype.id = "";

        /**
         * FeedbackLink linkType.
         * @member {string} linkType
         * @memberof PlatformAdminApi.FeedbackLink
         * @instance
         */
        FeedbackLink.prototype.linkType = "";

        /**
         * FeedbackLink linkName.
         * @member {string} linkName
         * @memberof PlatformAdminApi.FeedbackLink
         * @instance
         */
        FeedbackLink.prototype.linkName = "";

        /**
         * FeedbackLink linkId.
         * @member {string} linkId
         * @memberof PlatformAdminApi.FeedbackLink
         * @instance
         */
        FeedbackLink.prototype.linkId = "";

        /**
         * Encodes the specified FeedbackLink message. Does not implicitly {@link PlatformAdminApi.FeedbackLink.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FeedbackLink
         * @static
         * @param {PlatformAdminApi.IFeedbackLink} message FeedbackLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedbackLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.linkType != null && Object.hasOwnProperty.call(message, "linkType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.linkType);
            if (message.linkName != null && Object.hasOwnProperty.call(message, "linkName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.linkName);
            if (message.linkId != null && Object.hasOwnProperty.call(message, "linkId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.linkId);
            return writer;
        };

        /**
         * Decodes a FeedbackLink message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FeedbackLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FeedbackLink} FeedbackLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedbackLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FeedbackLink();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.linkType = reader.string();
                    break;
                case 3:
                    message.linkName = reader.string();
                    break;
                case 4:
                    message.linkId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FeedbackLink;
    })();

    /**
     * RATING_TYPE enum.
     * @name PlatformAdminApi.RATING_TYPE
     * @enum {number}
     * @property {number} RANGE_1_5=1 RANGE_1_5 value
     * @property {number} RANGE_1_10=2 RANGE_1_10 value
     */
    PlatformAdminApi.RATING_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "RANGE_1_5"] = 1;
        values[valuesById[2] = "RANGE_1_10"] = 2;
        return values;
    })();

    /**
     * RELATION_TYPE enum.
     * @name PlatformAdminApi.RELATION_TYPE
     * @enum {number}
     * @property {number} ORDER=1 ORDER value
     */
    PlatformAdminApi.RELATION_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "ORDER"] = 1;
        return values;
    })();

    /**
     * FEEDBACK_SOURCE enum.
     * @name PlatformAdminApi.FEEDBACK_SOURCE
     * @enum {number}
     * @property {number} APP=1 APP value
     * @property {number} KOSMOS_APP=2 KOSMOS_APP value
     */
    PlatformAdminApi.FEEDBACK_SOURCE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "APP"] = 1;
        values[valuesById[2] = "KOSMOS_APP"] = 2;
        return values;
    })();

    /**
     * ManagerState enum.
     * @name PlatformAdminApi.ManagerState
     * @enum {number}
     * @property {number} ONLINE=0 ONLINE value
     * @property {number} OFFLINE=1 OFFLINE value
     */
    PlatformAdminApi.ManagerState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ONLINE"] = 0;
        values[valuesById[1] = "OFFLINE"] = 1;
        return values;
    })();

    PlatformAdminApi.ManagementApiManagersService = (function() {

        /**
         * Constructs a new ManagementApiManagersService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagementApiManagersService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ManagementApiManagersService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ManagementApiManagersService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ManagementApiManagersService;

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiManagersService#create}.
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerFullResponse} [response] ManagerFullResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IManagerChangeRequest} request ManagerChangeRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiManagersService.CreateCallback} callback Node-style callback called with the error, if any, and ManagerFullResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiManagersService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.ManagerChangeRequest, $root.PlatformAdminApi.ManagerFullResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IManagerChangeRequest} request ManagerChangeRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerFullResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiManagersService#update}.
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerFullResponse} [response] ManagerFullResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IManagerChangeRequest} request ManagerChangeRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiManagersService.UpdateCallback} callback Node-style callback called with the error, if any, and ManagerFullResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiManagersService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.ManagerChangeRequest, $root.PlatformAdminApi.ManagerFullResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IManagerChangeRequest} request ManagerChangeRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerFullResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiManagersService#search}.
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerSearchResponse} [response] ManagerSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IManagerSearchRequest} request ManagerSearchRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiManagersService.SearchCallback} callback Node-style callback called with the error, if any, and ManagerSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiManagersService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.ManagerSearchRequest, $root.PlatformAdminApi.ManagerSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IManagerSearchRequest} request ManagerSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiManagersService#findById}.
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerFullResponse} [response] ManagerFullResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiManagersService.FindByIdCallback} callback Node-style callback called with the error, if any, and ManagerFullResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiManagersService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ManagerFullResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerFullResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiManagersService#remove}.
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerFullResponse} [response] ManagerFullResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiManagersService.RemoveCallback} callback Node-style callback called with the error, if any, and ManagerFullResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiManagersService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ManagerFullResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerFullResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiManagersService#restore}.
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerFullResponse} [response] ManagerFullResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiManagersService.RestoreCallback} callback Node-style callback called with the error, if any, and ManagerFullResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiManagersService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ManagerFullResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ManagementApiManagersService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerFullResponse>} Promise
         * @variation 2
         */

        return ManagementApiManagersService;
    })();

    PlatformAdminApi.ManagerChangeRequest = (function() {

        /**
         * Properties of a ManagerChangeRequest.
         * @memberof PlatformAdminApi
         * @interface IManagerChangeRequest
         * @property {string|null} [id] ManagerChangeRequest id
         * @property {string|null} [name] ManagerChangeRequest name
         * @property {Array.<PlatformAdminApi.IManagerSkill>|null} [skills] ManagerChangeRequest skills
         * @property {boolean|null} [isInactive] ManagerChangeRequest isInactive
         * @property {string|null} [ssoUid] ManagerChangeRequest ssoUid
         * @property {string|null} [state] ManagerChangeRequest state
         */

        /**
         * Constructs a new ManagerChangeRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerChangeRequest.
         * @implements IManagerChangeRequest
         * @constructor
         * @param {PlatformAdminApi.IManagerChangeRequest=} [properties] Properties to set
         */
        function ManagerChangeRequest(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerChangeRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @instance
         */
        ManagerChangeRequest.prototype.id = "";

        /**
         * ManagerChangeRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @instance
         */
        ManagerChangeRequest.prototype.name = "";

        /**
         * ManagerChangeRequest skills.
         * @member {Array.<PlatformAdminApi.IManagerSkill>} skills
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @instance
         */
        ManagerChangeRequest.prototype.skills = $util.emptyArray;

        /**
         * ManagerChangeRequest isInactive.
         * @member {boolean} isInactive
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @instance
         */
        ManagerChangeRequest.prototype.isInactive = false;

        /**
         * ManagerChangeRequest ssoUid.
         * @member {string} ssoUid
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @instance
         */
        ManagerChangeRequest.prototype.ssoUid = "";

        /**
         * ManagerChangeRequest state.
         * @member {string} state
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @instance
         */
        ManagerChangeRequest.prototype.state = "";

        /**
         * Encodes the specified ManagerChangeRequest message. Does not implicitly {@link PlatformAdminApi.ManagerChangeRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @static
         * @param {PlatformAdminApi.IManagerChangeRequest} message ManagerChangeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerChangeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.ManagerSkill.encode(message.skills[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.isInactive != null && Object.hasOwnProperty.call(message, "isInactive"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isInactive);
            if (message.ssoUid != null && Object.hasOwnProperty.call(message, "ssoUid"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ssoUid);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.state);
            return writer;
        };

        /**
         * Decodes a ManagerChangeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerChangeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerChangeRequest} ManagerChangeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerChangeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerChangeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.ManagerSkill.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.isInactive = reader.bool();
                    break;
                case 5:
                    message.ssoUid = reader.string();
                    break;
                case 6:
                    message.state = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerChangeRequest;
    })();

    PlatformAdminApi.ManagerResponse = (function() {

        /**
         * Properties of a ManagerResponse.
         * @memberof PlatformAdminApi
         * @interface IManagerResponse
         * @property {PlatformAdminApi.IManager|null} [data] ManagerResponse data
         */

        /**
         * Constructs a new ManagerResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerResponse.
         * @implements IManagerResponse
         * @constructor
         * @param {PlatformAdminApi.IManagerResponse=} [properties] Properties to set
         */
        function ManagerResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerResponse data.
         * @member {PlatformAdminApi.IManager|null|undefined} data
         * @memberof PlatformAdminApi.ManagerResponse
         * @instance
         */
        ManagerResponse.prototype.data = null;

        /**
         * Encodes the specified ManagerResponse message. Does not implicitly {@link PlatformAdminApi.ManagerResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerResponse
         * @static
         * @param {PlatformAdminApi.IManagerResponse} message ManagerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Manager.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ManagerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerResponse} ManagerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Manager.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerResponse;
    })();

    PlatformAdminApi.ManagerFullResponse = (function() {

        /**
         * Properties of a ManagerFullResponse.
         * @memberof PlatformAdminApi
         * @interface IManagerFullResponse
         * @property {PlatformAdminApi.IManagerFull|null} [data] ManagerFullResponse data
         */

        /**
         * Constructs a new ManagerFullResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerFullResponse.
         * @implements IManagerFullResponse
         * @constructor
         * @param {PlatformAdminApi.IManagerFullResponse=} [properties] Properties to set
         */
        function ManagerFullResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerFullResponse data.
         * @member {PlatformAdminApi.IManagerFull|null|undefined} data
         * @memberof PlatformAdminApi.ManagerFullResponse
         * @instance
         */
        ManagerFullResponse.prototype.data = null;

        /**
         * Encodes the specified ManagerFullResponse message. Does not implicitly {@link PlatformAdminApi.ManagerFullResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerFullResponse
         * @static
         * @param {PlatformAdminApi.IManagerFullResponse} message ManagerFullResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerFullResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.ManagerFull.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ManagerFullResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerFullResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerFullResponse} ManagerFullResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerFullResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerFullResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.ManagerFull.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerFullResponse;
    })();

    PlatformAdminApi.ManagerSearchRequest = (function() {

        /**
         * Properties of a ManagerSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IManagerSearchRequest
         * @property {number|null} [page] ManagerSearchRequest page
         * @property {number|null} [limit] ManagerSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] ManagerSearchRequest sort
         * @property {Array.<PlatformAdminApi.ManagerSearchRequest.IWhereFields>|null} [where] ManagerSearchRequest where
         */

        /**
         * Constructs a new ManagerSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerSearchRequest.
         * @implements IManagerSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IManagerSearchRequest=} [properties] Properties to set
         */
        function ManagerSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.ManagerSearchRequest
         * @instance
         */
        ManagerSearchRequest.prototype.page = 0;

        /**
         * ManagerSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ManagerSearchRequest
         * @instance
         */
        ManagerSearchRequest.prototype.limit = 0;

        /**
         * ManagerSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.ManagerSearchRequest
         * @instance
         */
        ManagerSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * ManagerSearchRequest where.
         * @member {Array.<PlatformAdminApi.ManagerSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.ManagerSearchRequest
         * @instance
         */
        ManagerSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified ManagerSearchRequest message. Does not implicitly {@link PlatformAdminApi.ManagerSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerSearchRequest
         * @static
         * @param {PlatformAdminApi.IManagerSearchRequest} message ManagerSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.ManagerSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ManagerSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerSearchRequest} ManagerSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.ManagerSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ManagerSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.ManagerSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {PlatformAdminApi.IStringCondition|null} [ssoUid] WhereFields ssoUid
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.ManagerSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.ManagerSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.ManagerSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.ManagerSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields ssoUid.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} ssoUid
             * @memberof PlatformAdminApi.ManagerSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.ssoUid = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.ManagerSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ManagerSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.ManagerSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.ssoUid != null && Object.hasOwnProperty.call(message, "ssoUid"))
                    $root.PlatformAdminApi.StringCondition.encode(message.ssoUid, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ManagerSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ManagerSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.ssoUid = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return ManagerSearchRequest;
    })();

    PlatformAdminApi.ManagerSearchResponse = (function() {

        /**
         * Properties of a ManagerSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IManagerSearchResponse
         * @property {Array.<PlatformAdminApi.IManager>|null} [data] ManagerSearchResponse data
         * @property {number|null} [page] ManagerSearchResponse page
         * @property {number|null} [limit] ManagerSearchResponse limit
         * @property {number|null} [total] ManagerSearchResponse total
         */

        /**
         * Constructs a new ManagerSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerSearchResponse.
         * @implements IManagerSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IManagerSearchResponse=} [properties] Properties to set
         */
        function ManagerSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerSearchResponse data.
         * @member {Array.<PlatformAdminApi.IManager>} data
         * @memberof PlatformAdminApi.ManagerSearchResponse
         * @instance
         */
        ManagerSearchResponse.prototype.data = $util.emptyArray;

        /**
         * ManagerSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ManagerSearchResponse
         * @instance
         */
        ManagerSearchResponse.prototype.page = 0;

        /**
         * ManagerSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ManagerSearchResponse
         * @instance
         */
        ManagerSearchResponse.prototype.limit = 0;

        /**
         * ManagerSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ManagerSearchResponse
         * @instance
         */
        ManagerSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified ManagerSearchResponse message. Does not implicitly {@link PlatformAdminApi.ManagerSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerSearchResponse
         * @static
         * @param {PlatformAdminApi.IManagerSearchResponse} message ManagerSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Manager.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ManagerSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerSearchResponse} ManagerSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Manager.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerSearchResponse;
    })();

    PlatformAdminApi.Manager = (function() {

        /**
         * Properties of a Manager.
         * @memberof PlatformAdminApi
         * @interface IManager
         * @property {string|null} [id] Manager id
         * @property {string|null} [name] Manager name
         * @property {Array.<PlatformAdminApi.IManagerSkill>|null} [skills] Manager skills
         * @property {boolean|null} [isInactive] Manager isInactive
         * @property {string|null} [ssoUid] Manager ssoUid
         * @property {string|null} [state] Manager state
         * @property {string|null} [createdAt] Manager createdAt
         * @property {string|null} [updatedAt] Manager updatedAt
         * @property {boolean|null} [isDeleted] Manager isDeleted
         * @property {string|null} [deletedAt] Manager deletedAt
         */

        /**
         * Constructs a new Manager.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Manager.
         * @implements IManager
         * @constructor
         * @param {PlatformAdminApi.IManager=} [properties] Properties to set
         */
        function Manager(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Manager id.
         * @member {string} id
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.id = "";

        /**
         * Manager name.
         * @member {string} name
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.name = "";

        /**
         * Manager skills.
         * @member {Array.<PlatformAdminApi.IManagerSkill>} skills
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.skills = $util.emptyArray;

        /**
         * Manager isInactive.
         * @member {boolean} isInactive
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.isInactive = false;

        /**
         * Manager ssoUid.
         * @member {string} ssoUid
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.ssoUid = "";

        /**
         * Manager state.
         * @member {string} state
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.state = "";

        /**
         * Manager createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.createdAt = "";

        /**
         * Manager updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.updatedAt = "";

        /**
         * Manager isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.isDeleted = false;

        /**
         * Manager deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Manager
         * @instance
         */
        Manager.prototype.deletedAt = "";

        /**
         * Encodes the specified Manager message. Does not implicitly {@link PlatformAdminApi.Manager.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Manager
         * @static
         * @param {PlatformAdminApi.IManager} message Manager message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Manager.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.ManagerSkill.encode(message.skills[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.isInactive != null && Object.hasOwnProperty.call(message, "isInactive"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isInactive);
            if (message.ssoUid != null && Object.hasOwnProperty.call(message, "ssoUid"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ssoUid);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.state);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Manager message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Manager
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Manager} Manager
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Manager.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Manager();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.ManagerSkill.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.isInactive = reader.bool();
                    break;
                case 5:
                    message.ssoUid = reader.string();
                    break;
                case 6:
                    message.state = reader.string();
                    break;
                case 7:
                    message.createdAt = reader.string();
                    break;
                case 8:
                    message.updatedAt = reader.string();
                    break;
                case 9:
                    message.isDeleted = reader.bool();
                    break;
                case 10:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Manager;
    })();

    PlatformAdminApi.ManagerFull = (function() {

        /**
         * Properties of a ManagerFull.
         * @memberof PlatformAdminApi
         * @interface IManagerFull
         * @property {string|null} [id] ManagerFull id
         * @property {string|null} [name] ManagerFull name
         * @property {Array.<PlatformAdminApi.IManagerSkill>|null} [skills] ManagerFull skills
         * @property {PlatformAdminApi.IUser|null} [user] ManagerFull user
         * @property {boolean|null} [isInactive] ManagerFull isInactive
         * @property {string|null} [ssoUid] ManagerFull ssoUid
         * @property {string|null} [state] ManagerFull state
         * @property {string|null} [createdAt] ManagerFull createdAt
         * @property {string|null} [updatedAt] ManagerFull updatedAt
         * @property {boolean|null} [isDeleted] ManagerFull isDeleted
         * @property {string|null} [deletedAt] ManagerFull deletedAt
         * @property {PlatformAdminApi.ITenant|null} [tenant] ManagerFull tenant
         */

        /**
         * Constructs a new ManagerFull.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerFull.
         * @implements IManagerFull
         * @constructor
         * @param {PlatformAdminApi.IManagerFull=} [properties] Properties to set
         */
        function ManagerFull(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerFull id.
         * @member {string} id
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.id = "";

        /**
         * ManagerFull name.
         * @member {string} name
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.name = "";

        /**
         * ManagerFull skills.
         * @member {Array.<PlatformAdminApi.IManagerSkill>} skills
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.skills = $util.emptyArray;

        /**
         * ManagerFull user.
         * @member {PlatformAdminApi.IUser|null|undefined} user
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.user = null;

        /**
         * ManagerFull isInactive.
         * @member {boolean} isInactive
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.isInactive = false;

        /**
         * ManagerFull ssoUid.
         * @member {string} ssoUid
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.ssoUid = "";

        /**
         * ManagerFull state.
         * @member {string} state
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.state = "";

        /**
         * ManagerFull createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.createdAt = "";

        /**
         * ManagerFull updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.updatedAt = "";

        /**
         * ManagerFull isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.isDeleted = false;

        /**
         * ManagerFull deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.deletedAt = "";

        /**
         * ManagerFull tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.ManagerFull
         * @instance
         */
        ManagerFull.prototype.tenant = null;

        /**
         * Encodes the specified ManagerFull message. Does not implicitly {@link PlatformAdminApi.ManagerFull.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerFull
         * @static
         * @param {PlatformAdminApi.IManagerFull} message ManagerFull message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerFull.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.ManagerSkill.encode(message.skills[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.PlatformAdminApi.User.encode(message.user, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.isInactive != null && Object.hasOwnProperty.call(message, "isInactive"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isInactive);
            if (message.ssoUid != null && Object.hasOwnProperty.call(message, "ssoUid"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.ssoUid);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.state);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.deletedAt);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ManagerFull message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerFull
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerFull} ManagerFull
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerFull.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerFull();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.ManagerSkill.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.user = $root.PlatformAdminApi.User.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.isInactive = reader.bool();
                    break;
                case 6:
                    message.ssoUid = reader.string();
                    break;
                case 7:
                    message.state = reader.string();
                    break;
                case 8:
                    message.createdAt = reader.string();
                    break;
                case 9:
                    message.updatedAt = reader.string();
                    break;
                case 10:
                    message.isDeleted = reader.bool();
                    break;
                case 11:
                    message.deletedAt = reader.string();
                    break;
                case 12:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerFull;
    })();

    PlatformAdminApi.ManagementApiSkillsService = (function() {

        /**
         * Constructs a new ManagementApiSkillsService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagementApiSkillsService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ManagementApiSkillsService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ManagementApiSkillsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ManagementApiSkillsService;

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiSkillsService#create}.
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerSkillResponse} [response] ManagerSkillResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IManagerSkill} request ManagerSkill message or plain object
         * @param {PlatformAdminApi.ManagementApiSkillsService.CreateCallback} callback Node-style callback called with the error, if any, and ManagerSkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiSkillsService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.ManagerSkill, $root.PlatformAdminApi.ManagerSkillResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IManagerSkill} request ManagerSkill message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerSkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiSkillsService#update}.
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerSkillResponse} [response] ManagerSkillResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IManagerSkill} request ManagerSkill message or plain object
         * @param {PlatformAdminApi.ManagementApiSkillsService.UpdateCallback} callback Node-style callback called with the error, if any, and ManagerSkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiSkillsService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.ManagerSkill, $root.PlatformAdminApi.ManagerSkillResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IManagerSkill} request ManagerSkill message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerSkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiSkillsService#search}.
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchManagerSkillResponse} [response] SearchManagerSkillResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.ISearchManagerSkillRequest} request SearchManagerSkillRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiSkillsService.SearchCallback} callback Node-style callback called with the error, if any, and SearchManagerSkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiSkillsService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchManagerSkillRequest, $root.PlatformAdminApi.SearchManagerSkillResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.ISearchManagerSkillRequest} request SearchManagerSkillRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchManagerSkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiSkillsService#findById}.
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerSkillResponse} [response] ManagerSkillResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiSkillsService.FindByIdCallback} callback Node-style callback called with the error, if any, and ManagerSkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiSkillsService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ManagerSkillResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerSkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiSkillsService#remove}.
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerSkillResponse} [response] ManagerSkillResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiSkillsService.RemoveCallback} callback Node-style callback called with the error, if any, and ManagerSkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiSkillsService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ManagerSkillResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerSkillResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ManagementApiSkillsService#restore}.
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ManagerSkillResponse} [response] ManagerSkillResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ManagementApiSkillsService.RestoreCallback} callback Node-style callback called with the error, if any, and ManagerSkillResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagementApiSkillsService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ManagerSkillResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.ManagementApiSkillsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ManagerSkillResponse>} Promise
         * @variation 2
         */

        return ManagementApiSkillsService;
    })();

    PlatformAdminApi.ManagerSkill = (function() {

        /**
         * Properties of a ManagerSkill.
         * @memberof PlatformAdminApi
         * @interface IManagerSkill
         * @property {string|null} [id] ManagerSkill id
         * @property {string|null} [name] ManagerSkill name
         * @property {number|null} [priority] ManagerSkill priority
         * @property {string|null} [createdAt] ManagerSkill createdAt
         * @property {string|null} [updatedAt] ManagerSkill updatedAt
         * @property {boolean|null} [isDeleted] ManagerSkill isDeleted
         * @property {string|null} [deletedAt] ManagerSkill deletedAt
         */

        /**
         * Constructs a new ManagerSkill.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerSkill.
         * @implements IManagerSkill
         * @constructor
         * @param {PlatformAdminApi.IManagerSkill=} [properties] Properties to set
         */
        function ManagerSkill(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerSkill id.
         * @member {string} id
         * @memberof PlatformAdminApi.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.id = "";

        /**
         * ManagerSkill name.
         * @member {string} name
         * @memberof PlatformAdminApi.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.name = "";

        /**
         * ManagerSkill priority.
         * @member {number} priority
         * @memberof PlatformAdminApi.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.priority = 0;

        /**
         * ManagerSkill createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.createdAt = "";

        /**
         * ManagerSkill updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.updatedAt = "";

        /**
         * ManagerSkill isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.isDeleted = false;

        /**
         * ManagerSkill deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.deletedAt = "";

        /**
         * Encodes the specified ManagerSkill message. Does not implicitly {@link PlatformAdminApi.ManagerSkill.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerSkill
         * @static
         * @param {PlatformAdminApi.IManagerSkill} message ManagerSkill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerSkill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a ManagerSkill message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerSkill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerSkill} ManagerSkill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerSkill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerSkill();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.priority = reader.int32();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerSkill;
    })();

    PlatformAdminApi.SearchManagerSkillRequest = (function() {

        /**
         * Properties of a SearchManagerSkillRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchManagerSkillRequest
         * @property {number|null} [page] SearchManagerSkillRequest page
         * @property {number|null} [limit] SearchManagerSkillRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchManagerSkillRequest sort
         * @property {Array.<PlatformAdminApi.SearchManagerSkillRequest.IWhereFields>|null} [where] SearchManagerSkillRequest where
         */

        /**
         * Constructs a new SearchManagerSkillRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchManagerSkillRequest.
         * @implements ISearchManagerSkillRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchManagerSkillRequest=} [properties] Properties to set
         */
        function SearchManagerSkillRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchManagerSkillRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchManagerSkillRequest
         * @instance
         */
        SearchManagerSkillRequest.prototype.page = 0;

        /**
         * SearchManagerSkillRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchManagerSkillRequest
         * @instance
         */
        SearchManagerSkillRequest.prototype.limit = 0;

        /**
         * SearchManagerSkillRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchManagerSkillRequest
         * @instance
         */
        SearchManagerSkillRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchManagerSkillRequest where.
         * @member {Array.<PlatformAdminApi.SearchManagerSkillRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchManagerSkillRequest
         * @instance
         */
        SearchManagerSkillRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchManagerSkillRequest message. Does not implicitly {@link PlatformAdminApi.SearchManagerSkillRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchManagerSkillRequest
         * @static
         * @param {PlatformAdminApi.ISearchManagerSkillRequest} message SearchManagerSkillRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchManagerSkillRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchManagerSkillRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchManagerSkillRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchManagerSkillRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchManagerSkillRequest} SearchManagerSkillRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchManagerSkillRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchManagerSkillRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchManagerSkillRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchManagerSkillRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchManagerSkillRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchManagerSkillRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchManagerSkillRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SearchManagerSkillRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.SearchManagerSkillRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchManagerSkillRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchManagerSkillRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchManagerSkillRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchManagerSkillRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchManagerSkillRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchManagerSkillRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchManagerSkillRequest;
    })();

    PlatformAdminApi.SearchManagerSkillResponse = (function() {

        /**
         * Properties of a SearchManagerSkillResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchManagerSkillResponse
         * @property {Array.<PlatformAdminApi.IManagerSkill>|null} [data] SearchManagerSkillResponse data
         * @property {number|null} [page] SearchManagerSkillResponse page
         * @property {number|null} [limit] SearchManagerSkillResponse limit
         * @property {number|null} [total] SearchManagerSkillResponse total
         */

        /**
         * Constructs a new SearchManagerSkillResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchManagerSkillResponse.
         * @implements ISearchManagerSkillResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchManagerSkillResponse=} [properties] Properties to set
         */
        function SearchManagerSkillResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchManagerSkillResponse data.
         * @member {Array.<PlatformAdminApi.IManagerSkill>} data
         * @memberof PlatformAdminApi.SearchManagerSkillResponse
         * @instance
         */
        SearchManagerSkillResponse.prototype.data = $util.emptyArray;

        /**
         * SearchManagerSkillResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchManagerSkillResponse
         * @instance
         */
        SearchManagerSkillResponse.prototype.page = 0;

        /**
         * SearchManagerSkillResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchManagerSkillResponse
         * @instance
         */
        SearchManagerSkillResponse.prototype.limit = 0;

        /**
         * SearchManagerSkillResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchManagerSkillResponse
         * @instance
         */
        SearchManagerSkillResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchManagerSkillResponse message. Does not implicitly {@link PlatformAdminApi.SearchManagerSkillResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchManagerSkillResponse
         * @static
         * @param {PlatformAdminApi.ISearchManagerSkillResponse} message SearchManagerSkillResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchManagerSkillResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.ManagerSkill.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchManagerSkillResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchManagerSkillResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchManagerSkillResponse} SearchManagerSkillResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchManagerSkillResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchManagerSkillResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.ManagerSkill.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchManagerSkillResponse;
    })();

    PlatformAdminApi.ManagerSkillResponse = (function() {

        /**
         * Properties of a ManagerSkillResponse.
         * @memberof PlatformAdminApi
         * @interface IManagerSkillResponse
         * @property {PlatformAdminApi.IManagerSkill|null} [data] ManagerSkillResponse data
         */

        /**
         * Constructs a new ManagerSkillResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerSkillResponse.
         * @implements IManagerSkillResponse
         * @constructor
         * @param {PlatformAdminApi.IManagerSkillResponse=} [properties] Properties to set
         */
        function ManagerSkillResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerSkillResponse data.
         * @member {PlatformAdminApi.IManagerSkill|null|undefined} data
         * @memberof PlatformAdminApi.ManagerSkillResponse
         * @instance
         */
        ManagerSkillResponse.prototype.data = null;

        /**
         * Encodes the specified ManagerSkillResponse message. Does not implicitly {@link PlatformAdminApi.ManagerSkillResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerSkillResponse
         * @static
         * @param {PlatformAdminApi.IManagerSkillResponse} message ManagerSkillResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerSkillResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.ManagerSkill.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ManagerSkillResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerSkillResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerSkillResponse} ManagerSkillResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerSkillResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerSkillResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.ManagerSkill.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerSkillResponse;
    })();

    PlatformAdminApi.ManagerSkillId = (function() {

        /**
         * Properties of a ManagerSkillId.
         * @memberof PlatformAdminApi
         * @interface IManagerSkillId
         * @property {string|null} [id] ManagerSkillId id
         */

        /**
         * Constructs a new ManagerSkillId.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ManagerSkillId.
         * @implements IManagerSkillId
         * @constructor
         * @param {PlatformAdminApi.IManagerSkillId=} [properties] Properties to set
         */
        function ManagerSkillId(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerSkillId id.
         * @member {string} id
         * @memberof PlatformAdminApi.ManagerSkillId
         * @instance
         */
        ManagerSkillId.prototype.id = "";

        /**
         * Encodes the specified ManagerSkillId message. Does not implicitly {@link PlatformAdminApi.ManagerSkillId.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ManagerSkillId
         * @static
         * @param {PlatformAdminApi.IManagerSkillId} message ManagerSkillId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerSkillId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a ManagerSkillId message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ManagerSkillId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ManagerSkillId} ManagerSkillId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerSkillId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ManagerSkillId();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerSkillId;
    })();

    PlatformAdminApi.OfferManagementService = (function() {

        /**
         * Constructs a new OfferManagementService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OfferManagementService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function OfferManagementService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (OfferManagementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OfferManagementService;

        /**
         * Callback as used by {@link PlatformAdminApi.OfferManagementService#create}.
         * @memberof PlatformAdminApi.OfferManagementService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OfferResponse} [response] OfferResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IOfferCreateRequest} request OfferCreateRequest message or plain object
         * @param {PlatformAdminApi.OfferManagementService.CreateCallback} callback Node-style callback called with the error, if any, and OfferResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OfferManagementService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.OfferCreateRequest, $root.PlatformAdminApi.OfferResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IOfferCreateRequest} request OfferCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OfferResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OfferManagementService#update}.
         * @memberof PlatformAdminApi.OfferManagementService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OfferResponse} [response] OfferResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IOfferUpdateRequest} request OfferUpdateRequest message or plain object
         * @param {PlatformAdminApi.OfferManagementService.UpdateCallback} callback Node-style callback called with the error, if any, and OfferResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OfferManagementService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.OfferUpdateRequest, $root.PlatformAdminApi.OfferResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IOfferUpdateRequest} request OfferUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OfferResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OfferManagementService#search}.
         * @memberof PlatformAdminApi.OfferManagementService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OfferSearchResponse} [response] OfferSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IOfferSearchRequest} request OfferSearchRequest message or plain object
         * @param {PlatformAdminApi.OfferManagementService.SearchCallback} callback Node-style callback called with the error, if any, and OfferSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OfferManagementService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.OfferSearchRequest, $root.PlatformAdminApi.OfferSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IOfferSearchRequest} request OfferSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OfferSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OfferManagementService#findById}.
         * @memberof PlatformAdminApi.OfferManagementService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OfferResponse} [response] OfferResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OfferManagementService.FindByIdCallback} callback Node-style callback called with the error, if any, and OfferResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OfferManagementService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.OfferResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OfferResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OfferManagementService#remove}.
         * @memberof PlatformAdminApi.OfferManagementService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OfferResponse} [response] OfferResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OfferManagementService.RemoveCallback} callback Node-style callback called with the error, if any, and OfferResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OfferManagementService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.OfferResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OfferResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OfferManagementService#restore}.
         * @memberof PlatformAdminApi.OfferManagementService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OfferResponse} [response] OfferResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OfferManagementService.RestoreCallback} callback Node-style callback called with the error, if any, and OfferResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OfferManagementService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.OfferResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.OfferManagementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OfferResponse>} Promise
         * @variation 2
         */

        return OfferManagementService;
    })();

    PlatformAdminApi.OfferCreateRequest = (function() {

        /**
         * Properties of an OfferCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IOfferCreateRequest
         * @property {string|null} [siteUrl] OfferCreateRequest siteUrl
         * @property {string|null} [serviceType] OfferCreateRequest serviceType
         * @property {string|null} [distributionChannel] OfferCreateRequest distributionChannel
         * @property {string|null} [title] OfferCreateRequest title
         * @property {string|null} [desc] OfferCreateRequest desc
         * @property {string|null} [promocode] OfferCreateRequest promocode
         */

        /**
         * Constructs a new OfferCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OfferCreateRequest.
         * @implements IOfferCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IOfferCreateRequest=} [properties] Properties to set
         */
        function OfferCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OfferCreateRequest siteUrl.
         * @member {string} siteUrl
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @instance
         */
        OfferCreateRequest.prototype.siteUrl = "";

        /**
         * OfferCreateRequest serviceType.
         * @member {string} serviceType
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @instance
         */
        OfferCreateRequest.prototype.serviceType = "";

        /**
         * OfferCreateRequest distributionChannel.
         * @member {string} distributionChannel
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @instance
         */
        OfferCreateRequest.prototype.distributionChannel = "";

        /**
         * OfferCreateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @instance
         */
        OfferCreateRequest.prototype.title = "";

        /**
         * OfferCreateRequest desc.
         * @member {string} desc
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @instance
         */
        OfferCreateRequest.prototype.desc = "";

        /**
         * OfferCreateRequest promocode.
         * @member {string} promocode
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @instance
         */
        OfferCreateRequest.prototype.promocode = "";

        /**
         * Encodes the specified OfferCreateRequest message. Does not implicitly {@link PlatformAdminApi.OfferCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @static
         * @param {PlatformAdminApi.IOfferCreateRequest} message OfferCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OfferCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.siteUrl != null && Object.hasOwnProperty.call(message, "siteUrl"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.siteUrl);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.serviceType);
            if (message.distributionChannel != null && Object.hasOwnProperty.call(message, "distributionChannel"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.distributionChannel);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
            if (message.desc != null && Object.hasOwnProperty.call(message, "desc"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.desc);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.promocode);
            return writer;
        };

        /**
         * Decodes an OfferCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OfferCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OfferCreateRequest} OfferCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OfferCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OfferCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.siteUrl = reader.string();
                    break;
                case 2:
                    message.serviceType = reader.string();
                    break;
                case 3:
                    message.distributionChannel = reader.string();
                    break;
                case 4:
                    message.title = reader.string();
                    break;
                case 5:
                    message.desc = reader.string();
                    break;
                case 6:
                    message.promocode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OfferCreateRequest;
    })();

    PlatformAdminApi.OfferUpdateRequest = (function() {

        /**
         * Properties of an OfferUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IOfferUpdateRequest
         * @property {string|null} [id] OfferUpdateRequest id
         * @property {string|null} [siteUrl] OfferUpdateRequest siteUrl
         * @property {string|null} [serviceType] OfferUpdateRequest serviceType
         * @property {string|null} [distributionChannel] OfferUpdateRequest distributionChannel
         * @property {string|null} [title] OfferUpdateRequest title
         * @property {string|null} [desc] OfferUpdateRequest desc
         * @property {string|null} [promocode] OfferUpdateRequest promocode
         */

        /**
         * Constructs a new OfferUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OfferUpdateRequest.
         * @implements IOfferUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IOfferUpdateRequest=} [properties] Properties to set
         */
        function OfferUpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OfferUpdateRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @instance
         */
        OfferUpdateRequest.prototype.id = "";

        /**
         * OfferUpdateRequest siteUrl.
         * @member {string} siteUrl
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @instance
         */
        OfferUpdateRequest.prototype.siteUrl = "";

        /**
         * OfferUpdateRequest serviceType.
         * @member {string} serviceType
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @instance
         */
        OfferUpdateRequest.prototype.serviceType = "";

        /**
         * OfferUpdateRequest distributionChannel.
         * @member {string} distributionChannel
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @instance
         */
        OfferUpdateRequest.prototype.distributionChannel = "";

        /**
         * OfferUpdateRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @instance
         */
        OfferUpdateRequest.prototype.title = "";

        /**
         * OfferUpdateRequest desc.
         * @member {string} desc
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @instance
         */
        OfferUpdateRequest.prototype.desc = "";

        /**
         * OfferUpdateRequest promocode.
         * @member {string} promocode
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @instance
         */
        OfferUpdateRequest.prototype.promocode = "";

        /**
         * Encodes the specified OfferUpdateRequest message. Does not implicitly {@link PlatformAdminApi.OfferUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @static
         * @param {PlatformAdminApi.IOfferUpdateRequest} message OfferUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OfferUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.siteUrl != null && Object.hasOwnProperty.call(message, "siteUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.siteUrl);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.serviceType);
            if (message.distributionChannel != null && Object.hasOwnProperty.call(message, "distributionChannel"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.distributionChannel);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.title);
            if (message.desc != null && Object.hasOwnProperty.call(message, "desc"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.desc);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.promocode);
            return writer;
        };

        /**
         * Decodes an OfferUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OfferUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OfferUpdateRequest} OfferUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OfferUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OfferUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.siteUrl = reader.string();
                    break;
                case 3:
                    message.serviceType = reader.string();
                    break;
                case 4:
                    message.distributionChannel = reader.string();
                    break;
                case 5:
                    message.title = reader.string();
                    break;
                case 6:
                    message.desc = reader.string();
                    break;
                case 7:
                    message.promocode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OfferUpdateRequest;
    })();

    PlatformAdminApi.OfferSearchRequest = (function() {

        /**
         * Properties of an OfferSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IOfferSearchRequest
         * @property {number|null} [page] OfferSearchRequest page
         * @property {number|null} [limit] OfferSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] OfferSearchRequest sort
         * @property {Array.<PlatformAdminApi.OfferSearchRequest.IWhereFields>|null} [where] OfferSearchRequest where
         */

        /**
         * Constructs a new OfferSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OfferSearchRequest.
         * @implements IOfferSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IOfferSearchRequest=} [properties] Properties to set
         */
        function OfferSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OfferSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.OfferSearchRequest
         * @instance
         */
        OfferSearchRequest.prototype.page = 0;

        /**
         * OfferSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.OfferSearchRequest
         * @instance
         */
        OfferSearchRequest.prototype.limit = 0;

        /**
         * OfferSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.OfferSearchRequest
         * @instance
         */
        OfferSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * OfferSearchRequest where.
         * @member {Array.<PlatformAdminApi.OfferSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.OfferSearchRequest
         * @instance
         */
        OfferSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified OfferSearchRequest message. Does not implicitly {@link PlatformAdminApi.OfferSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OfferSearchRequest
         * @static
         * @param {PlatformAdminApi.IOfferSearchRequest} message OfferSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OfferSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.OfferSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OfferSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OfferSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OfferSearchRequest} OfferSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OfferSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OfferSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.OfferSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OfferSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.OfferSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [promocode] WhereFields promocode
             * @property {PlatformAdminApi.IStringCondition|null} [distributionChannel] WhereFields distributionChannel
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [serviceType] WhereFields serviceType
             * @property {PlatformAdminApi.IStringCondition|null} [siteUrl] WhereFields siteUrl
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {boolean|null} [isBefore] WhereFields isBefore
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.OfferSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.OfferSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields promocode.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} promocode
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.promocode = null;

            /**
             * WhereFields distributionChannel.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} distributionChannel
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.distributionChannel = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields serviceType.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} serviceType
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.serviceType = null;

            /**
             * WhereFields siteUrl.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} siteUrl
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.siteUrl = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields isBefore.
             * @member {boolean} isBefore
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isBefore = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.OfferSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.OfferSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                    $root.PlatformAdminApi.StringCondition.encode(message.promocode, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.distributionChannel != null && Object.hasOwnProperty.call(message, "distributionChannel"))
                    $root.PlatformAdminApi.StringCondition.encode(message.distributionChannel, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                    $root.PlatformAdminApi.StringCondition.encode(message.serviceType, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.siteUrl != null && Object.hasOwnProperty.call(message, "siteUrl"))
                    $root.PlatformAdminApi.StringCondition.encode(message.siteUrl, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
                if (message.isBefore != null && Object.hasOwnProperty.call(message, "isBefore"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isBefore);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.OfferSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.OfferSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OfferSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.promocode = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.distributionChannel = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.serviceType = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.siteUrl = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.isDeleted = reader.bool();
                        break;
                    case 8:
                        message.isBefore = reader.bool();
                        break;
                    case 9:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return OfferSearchRequest;
    })();

    PlatformAdminApi.OfferResponse = (function() {

        /**
         * Properties of an OfferResponse.
         * @memberof PlatformAdminApi
         * @interface IOfferResponse
         * @property {PlatformAdminApi.IOffer|null} [data] OfferResponse data
         */

        /**
         * Constructs a new OfferResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OfferResponse.
         * @implements IOfferResponse
         * @constructor
         * @param {PlatformAdminApi.IOfferResponse=} [properties] Properties to set
         */
        function OfferResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OfferResponse data.
         * @member {PlatformAdminApi.IOffer|null|undefined} data
         * @memberof PlatformAdminApi.OfferResponse
         * @instance
         */
        OfferResponse.prototype.data = null;

        /**
         * Encodes the specified OfferResponse message. Does not implicitly {@link PlatformAdminApi.OfferResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OfferResponse
         * @static
         * @param {PlatformAdminApi.IOfferResponse} message OfferResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OfferResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Offer.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OfferResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OfferResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OfferResponse} OfferResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OfferResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OfferResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Offer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OfferResponse;
    })();

    PlatformAdminApi.OfferSearchResponse = (function() {

        /**
         * Properties of an OfferSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IOfferSearchResponse
         * @property {Array.<PlatformAdminApi.IOffer>|null} [data] OfferSearchResponse data
         * @property {number|null} [page] OfferSearchResponse page
         * @property {number|null} [pageCount] OfferSearchResponse pageCount
         * @property {number|null} [limit] OfferSearchResponse limit
         * @property {number|null} [count] OfferSearchResponse count
         * @property {number|null} [total] OfferSearchResponse total
         */

        /**
         * Constructs a new OfferSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OfferSearchResponse.
         * @implements IOfferSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IOfferSearchResponse=} [properties] Properties to set
         */
        function OfferSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OfferSearchResponse data.
         * @member {Array.<PlatformAdminApi.IOffer>} data
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @instance
         */
        OfferSearchResponse.prototype.data = $util.emptyArray;

        /**
         * OfferSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @instance
         */
        OfferSearchResponse.prototype.page = 0;

        /**
         * OfferSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @instance
         */
        OfferSearchResponse.prototype.pageCount = 0;

        /**
         * OfferSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @instance
         */
        OfferSearchResponse.prototype.limit = 0;

        /**
         * OfferSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @instance
         */
        OfferSearchResponse.prototype.count = 0;

        /**
         * OfferSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @instance
         */
        OfferSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified OfferSearchResponse message. Does not implicitly {@link PlatformAdminApi.OfferSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @static
         * @param {PlatformAdminApi.IOfferSearchResponse} message OfferSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OfferSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Offer.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes an OfferSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OfferSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OfferSearchResponse} OfferSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OfferSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OfferSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Offer.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OfferSearchResponse;
    })();

    PlatformAdminApi.Offer = (function() {

        /**
         * Properties of an Offer.
         * @memberof PlatformAdminApi
         * @interface IOffer
         * @property {string|null} [id] Offer id
         * @property {string|null} [siteUrl] Offer siteUrl
         * @property {string|null} [serviceType] Offer serviceType
         * @property {string|null} [distributionChannel] Offer distributionChannel
         * @property {string|null} [title] Offer title
         * @property {string|null} [desc] Offer desc
         * @property {string|null} [promocode] Offer promocode
         * @property {string|null} [createdAt] Offer createdAt
         * @property {string|null} [updatedAt] Offer updatedAt
         * @property {string|null} [deletedAt] Offer deletedAt
         * @property {boolean|null} [isDeleted] Offer isDeleted
         */

        /**
         * Constructs a new Offer.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Offer.
         * @implements IOffer
         * @constructor
         * @param {PlatformAdminApi.IOffer=} [properties] Properties to set
         */
        function Offer(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Offer id.
         * @member {string} id
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.id = "";

        /**
         * Offer siteUrl.
         * @member {string} siteUrl
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.siteUrl = "";

        /**
         * Offer serviceType.
         * @member {string} serviceType
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.serviceType = "";

        /**
         * Offer distributionChannel.
         * @member {string} distributionChannel
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.distributionChannel = "";

        /**
         * Offer title.
         * @member {string} title
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.title = "";

        /**
         * Offer desc.
         * @member {string} desc
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.desc = "";

        /**
         * Offer promocode.
         * @member {string} promocode
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.promocode = "";

        /**
         * Offer createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.createdAt = "";

        /**
         * Offer updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.updatedAt = "";

        /**
         * Offer deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.deletedAt = "";

        /**
         * Offer isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Offer
         * @instance
         */
        Offer.prototype.isDeleted = false;

        /**
         * Encodes the specified Offer message. Does not implicitly {@link PlatformAdminApi.Offer.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Offer
         * @static
         * @param {PlatformAdminApi.IOffer} message Offer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Offer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.siteUrl != null && Object.hasOwnProperty.call(message, "siteUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.siteUrl);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceType);
            if (message.distributionChannel != null && Object.hasOwnProperty.call(message, "distributionChannel"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.distributionChannel);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.title);
            if (message.desc != null && Object.hasOwnProperty.call(message, "desc"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.desc);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.promocode);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.deletedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes an Offer message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Offer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Offer} Offer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Offer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Offer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 3:
                    message.siteUrl = reader.string();
                    break;
                case 4:
                    message.serviceType = reader.string();
                    break;
                case 5:
                    message.distributionChannel = reader.string();
                    break;
                case 6:
                    message.title = reader.string();
                    break;
                case 7:
                    message.desc = reader.string();
                    break;
                case 8:
                    message.promocode = reader.string();
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.updatedAt = reader.string();
                    break;
                case 11:
                    message.deletedAt = reader.string();
                    break;
                case 12:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Offer;
    })();

    /**
     * ChannelType enum.
     * @name PlatformAdminApi.ChannelType
     * @enum {number}
     * @property {number} crm=0 crm value
     * @property {number} app=1 app value
     * @property {number} web=2 web value
     */
    PlatformAdminApi.ChannelType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "crm"] = 0;
        values[valuesById[1] = "app"] = 1;
        values[valuesById[2] = "web"] = 2;
        return values;
    })();

    PlatformAdminApi.OrdersService = (function() {

        /**
         * Constructs a new OrdersService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrdersService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function OrdersService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (OrdersService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OrdersService;

        /**
         * Callback as used by {@link PlatformAdminApi.OrdersService#search}.
         * @memberof PlatformAdminApi.OrdersService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderSearchResponse} [response] OrderSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OrdersService
         * @instance
         * @param {PlatformAdminApi.IOrderSearchRequest} request OrderSearchRequest message or plain object
         * @param {PlatformAdminApi.OrdersService.SearchCallback} callback Node-style callback called with the error, if any, and OrderSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrdersService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.OrderSearchRequest, $root.PlatformAdminApi.OrderSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OrdersService
         * @instance
         * @param {PlatformAdminApi.IOrderSearchRequest} request OrderSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderSearchResponse>} Promise
         * @variation 2
         */

        return OrdersService;
    })();

    PlatformAdminApi.Order = (function() {

        /**
         * Properties of an Order.
         * @memberof PlatformAdminApi
         * @interface IOrder
         * @property {string|null} [id] Order id
         * @property {number|null} [orderNumber] Order orderNumber
         * @property {string|null} [status] Order status
         * @property {PlatformAdminApi.IAddress|null} [address] Order address
         * @property {number|null} [regionId] Order regionId
         * @property {string|null} [startDate] Order startDate
         * @property {string|null} [serviceType] Order serviceType
         * @property {string|null} [qleanOrderId] Order qleanOrderId
         */

        /**
         * Constructs a new Order.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Order.
         * @implements IOrder
         * @constructor
         * @param {PlatformAdminApi.IOrder=} [properties] Properties to set
         */
        function Order(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Order id.
         * @member {string} id
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.id = "";

        /**
         * Order orderNumber.
         * @member {number} orderNumber
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.orderNumber = 0;

        /**
         * Order status.
         * @member {string} status
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.status = "";

        /**
         * Order address.
         * @member {PlatformAdminApi.IAddress|null|undefined} address
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.address = null;

        /**
         * Order regionId.
         * @member {number} regionId
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.regionId = 0;

        /**
         * Order startDate.
         * @member {string} startDate
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.startDate = "";

        /**
         * Order serviceType.
         * @member {string} serviceType
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.serviceType = "";

        /**
         * Order qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformAdminApi.Order
         * @instance
         */
        Order.prototype.qleanOrderId = "";

        /**
         * Encodes the specified Order message. Does not implicitly {@link PlatformAdminApi.Order.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Order
         * @static
         * @param {PlatformAdminApi.IOrder} message Order message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Order.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderNumber);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.Address.encode(message.address, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.regionId);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.startDate);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.serviceType);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.qleanOrderId);
            return writer;
        };

        /**
         * Decodes an Order message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Order
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Order} Order
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Order.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Order();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.orderNumber = reader.int32();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.address = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.regionId = reader.int32();
                    break;
                case 6:
                    message.startDate = reader.string();
                    break;
                case 7:
                    message.serviceType = reader.string();
                    break;
                case 8:
                    message.qleanOrderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Order;
    })();

    PlatformAdminApi.OrderSearchRequest = (function() {

        /**
         * Properties of an OrderSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IOrderSearchRequest
         * @property {number|null} [page] OrderSearchRequest page
         * @property {number|null} [limit] OrderSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] OrderSearchRequest sort
         * @property {Array.<PlatformAdminApi.OrderSearchRequest.IWhereFields>|null} [where] OrderSearchRequest where
         */

        /**
         * Constructs a new OrderSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderSearchRequest.
         * @implements IOrderSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IOrderSearchRequest=} [properties] Properties to set
         */
        function OrderSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.OrderSearchRequest
         * @instance
         */
        OrderSearchRequest.prototype.page = 0;

        /**
         * OrderSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.OrderSearchRequest
         * @instance
         */
        OrderSearchRequest.prototype.limit = 0;

        /**
         * OrderSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.OrderSearchRequest
         * @instance
         */
        OrderSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * OrderSearchRequest where.
         * @member {Array.<PlatformAdminApi.OrderSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.OrderSearchRequest
         * @instance
         */
        OrderSearchRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified OrderSearchRequest message. Does not implicitly {@link PlatformAdminApi.OrderSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderSearchRequest
         * @static
         * @param {PlatformAdminApi.IOrderSearchRequest} message OrderSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.OrderSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderSearchRequest} OrderSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.OrderSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.OrderSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IStringCondition|null} [qleanOrderId] WhereFields qleanOrderId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.OrderSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.OrderSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields qleanOrderId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} qleanOrderId
             * @memberof PlatformAdminApi.OrderSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.qleanOrderId = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.OrderSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.OrderSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.OrderSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.qleanOrderId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.OrderSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.OrderSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.qleanOrderId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return OrderSearchRequest;
    })();

    PlatformAdminApi.OrderSearchResponse = (function() {

        /**
         * Properties of an OrderSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IOrderSearchResponse
         * @property {Array.<PlatformAdminApi.IOrder>|null} [data] OrderSearchResponse data
         * @property {number|null} [page] OrderSearchResponse page
         * @property {number|null} [limit] OrderSearchResponse limit
         * @property {number|null} [total] OrderSearchResponse total
         */

        /**
         * Constructs a new OrderSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderSearchResponse.
         * @implements IOrderSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IOrderSearchResponse=} [properties] Properties to set
         */
        function OrderSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderSearchResponse data.
         * @member {Array.<PlatformAdminApi.IOrder>} data
         * @memberof PlatformAdminApi.OrderSearchResponse
         * @instance
         */
        OrderSearchResponse.prototype.data = $util.emptyArray;

        /**
         * OrderSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.OrderSearchResponse
         * @instance
         */
        OrderSearchResponse.prototype.page = 0;

        /**
         * OrderSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.OrderSearchResponse
         * @instance
         */
        OrderSearchResponse.prototype.limit = 0;

        /**
         * OrderSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.OrderSearchResponse
         * @instance
         */
        OrderSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified OrderSearchResponse message. Does not implicitly {@link PlatformAdminApi.OrderSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderSearchResponse
         * @static
         * @param {PlatformAdminApi.IOrderSearchResponse} message OrderSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Order.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes an OrderSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderSearchResponse} OrderSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Order.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderSearchResponse;
    })();

    PlatformAdminApi.CRMPersonService = (function() {

        /**
         * Constructs a new CRMPersonService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CRMPersonService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CRMPersonService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CRMPersonService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CRMPersonService;

        /**
         * Callback as used by {@link PlatformAdminApi.CRMPersonService#create}.
         * @memberof PlatformAdminApi.CRMPersonService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PersonResponse} [response] PersonResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.ICreatePersonRequest} request CreatePersonRequest message or plain object
         * @param {PlatformAdminApi.CRMPersonService.CreateCallback} callback Node-style callback called with the error, if any, and PersonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CRMPersonService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreatePersonRequest, $root.PlatformAdminApi.PersonResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.ICreatePersonRequest} request CreatePersonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PersonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CRMPersonService#update}.
         * @memberof PlatformAdminApi.CRMPersonService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PersonResponse} [response] PersonResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUpdatePersonRequest} request UpdatePersonRequest message or plain object
         * @param {PlatformAdminApi.CRMPersonService.UpdateCallback} callback Node-style callback called with the error, if any, and PersonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CRMPersonService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdatePersonRequest, $root.PlatformAdminApi.PersonResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUpdatePersonRequest} request UpdatePersonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PersonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CRMPersonService#findById}.
         * @memberof PlatformAdminApi.CRMPersonService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PersonResponse} [response] PersonResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CRMPersonService.FindByIdCallback} callback Node-style callback called with the error, if any, and PersonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CRMPersonService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PersonResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PersonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CRMPersonService#searchPerson}.
         * @memberof PlatformAdminApi.CRMPersonService
         * @typedef SearchPersonCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchPersonResponse} [response] SearchPersonResponse
         */

        /**
         * Calls SearchPerson.
         * @function searchPerson
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.ISearchPersonRequest} request SearchPersonRequest message or plain object
         * @param {PlatformAdminApi.CRMPersonService.SearchPersonCallback} callback Node-style callback called with the error, if any, and SearchPersonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CRMPersonService.prototype.searchPerson = function searchPerson(request, callback) {
            return this.rpcCall(searchPerson, $root.PlatformAdminApi.SearchPersonRequest, $root.PlatformAdminApi.SearchPersonResponse, request, callback);
        }, "name", { value: "SearchPerson" });

        /**
         * Calls SearchPerson.
         * @function searchPerson
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.ISearchPersonRequest} request SearchPersonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchPersonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CRMPersonService#remove}.
         * @memberof PlatformAdminApi.CRMPersonService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PersonResponse} [response] PersonResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CRMPersonService.RemoveCallback} callback Node-style callback called with the error, if any, and PersonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CRMPersonService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PersonResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PersonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CRMPersonService#restore}.
         * @memberof PlatformAdminApi.CRMPersonService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PersonResponse} [response] PersonResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CRMPersonService.RestoreCallback} callback Node-style callback called with the error, if any, and PersonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CRMPersonService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PersonResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.CRMPersonService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PersonResponse>} Promise
         * @variation 2
         */

        return CRMPersonService;
    })();

    PlatformAdminApi.CreatePersonRequest = (function() {

        /**
         * Properties of a CreatePersonRequest.
         * @memberof PlatformAdminApi
         * @interface ICreatePersonRequest
         * @property {string|null} [email] CreatePersonRequest email
         * @property {string|null} [phone] CreatePersonRequest phone
         * @property {PlatformAdminApi.IFio|null} [fio] CreatePersonRequest fio
         * @property {string|null} [status] CreatePersonRequest status
         * @property {string|null} [commentForExecutor] CreatePersonRequest commentForExecutor
         * @property {string|null} [commentForSupport] CreatePersonRequest commentForSupport
         * @property {string|null} [qleanClientId] CreatePersonRequest qleanClientId
         */

        /**
         * Constructs a new CreatePersonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CreatePersonRequest.
         * @implements ICreatePersonRequest
         * @constructor
         * @param {PlatformAdminApi.ICreatePersonRequest=} [properties] Properties to set
         */
        function CreatePersonRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreatePersonRequest email.
         * @member {string} email
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @instance
         */
        CreatePersonRequest.prototype.email = "";

        /**
         * CreatePersonRequest phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @instance
         */
        CreatePersonRequest.prototype.phone = "";

        /**
         * CreatePersonRequest fio.
         * @member {PlatformAdminApi.IFio|null|undefined} fio
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @instance
         */
        CreatePersonRequest.prototype.fio = null;

        /**
         * CreatePersonRequest status.
         * @member {string} status
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @instance
         */
        CreatePersonRequest.prototype.status = "";

        /**
         * CreatePersonRequest commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @instance
         */
        CreatePersonRequest.prototype.commentForExecutor = "";

        /**
         * CreatePersonRequest commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @instance
         */
        CreatePersonRequest.prototype.commentForSupport = "";

        /**
         * CreatePersonRequest qleanClientId.
         * @member {string} qleanClientId
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @instance
         */
        CreatePersonRequest.prototype.qleanClientId = "";

        /**
         * Encodes the specified CreatePersonRequest message. Does not implicitly {@link PlatformAdminApi.CreatePersonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @static
         * @param {PlatformAdminApi.ICreatePersonRequest} message CreatePersonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePersonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.phone);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformAdminApi.Fio.encode(message.fio, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.status);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.commentForSupport);
            if (message.qleanClientId != null && Object.hasOwnProperty.call(message, "qleanClientId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.qleanClientId);
            return writer;
        };

        /**
         * Decodes a CreatePersonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreatePersonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreatePersonRequest} CreatePersonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePersonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreatePersonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.email = reader.string();
                    break;
                case 2:
                    message.phone = reader.string();
                    break;
                case 3:
                    message.fio = $root.PlatformAdminApi.Fio.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.status = reader.string();
                    break;
                case 5:
                    message.commentForExecutor = reader.string();
                    break;
                case 6:
                    message.commentForSupport = reader.string();
                    break;
                case 7:
                    message.qleanClientId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreatePersonRequest;
    })();

    PlatformAdminApi.UpdatePersonRequest = (function() {

        /**
         * Properties of an UpdatePersonRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdatePersonRequest
         * @property {string|null} [id] UpdatePersonRequest id
         * @property {string|null} [email] UpdatePersonRequest email
         * @property {string|null} [phone] UpdatePersonRequest phone
         * @property {PlatformAdminApi.IFio|null} [fio] UpdatePersonRequest fio
         * @property {string|null} [status] UpdatePersonRequest status
         * @property {string|null} [commentForExecutor] UpdatePersonRequest commentForExecutor
         * @property {string|null} [commentForSupport] UpdatePersonRequest commentForSupport
         * @property {string|null} [qleanClientId] UpdatePersonRequest qleanClientId
         */

        /**
         * Constructs a new UpdatePersonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpdatePersonRequest.
         * @implements IUpdatePersonRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdatePersonRequest=} [properties] Properties to set
         */
        function UpdatePersonRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePersonRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.id = "";

        /**
         * UpdatePersonRequest email.
         * @member {string} email
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.email = "";

        /**
         * UpdatePersonRequest phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.phone = "";

        /**
         * UpdatePersonRequest fio.
         * @member {PlatformAdminApi.IFio|null|undefined} fio
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.fio = null;

        /**
         * UpdatePersonRequest status.
         * @member {string} status
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.status = "";

        /**
         * UpdatePersonRequest commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.commentForExecutor = "";

        /**
         * UpdatePersonRequest commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.commentForSupport = "";

        /**
         * UpdatePersonRequest qleanClientId.
         * @member {string} qleanClientId
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @instance
         */
        UpdatePersonRequest.prototype.qleanClientId = "";

        /**
         * Encodes the specified UpdatePersonRequest message. Does not implicitly {@link PlatformAdminApi.UpdatePersonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @static
         * @param {PlatformAdminApi.IUpdatePersonRequest} message UpdatePersonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePersonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformAdminApi.Fio.encode(message.fio, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.status);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.commentForSupport);
            if (message.qleanClientId != null && Object.hasOwnProperty.call(message, "qleanClientId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.qleanClientId);
            return writer;
        };

        /**
         * Decodes an UpdatePersonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdatePersonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdatePersonRequest} UpdatePersonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePersonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdatePersonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.phone = reader.string();
                    break;
                case 4:
                    message.fio = $root.PlatformAdminApi.Fio.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.status = reader.string();
                    break;
                case 6:
                    message.commentForExecutor = reader.string();
                    break;
                case 7:
                    message.commentForSupport = reader.string();
                    break;
                case 8:
                    message.qleanClientId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePersonRequest;
    })();

    PlatformAdminApi.PersonResponse = (function() {

        /**
         * Properties of a PersonResponse.
         * @memberof PlatformAdminApi
         * @interface IPersonResponse
         * @property {PlatformAdminApi.IPerson|null} [data] PersonResponse data
         */

        /**
         * Constructs a new PersonResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PersonResponse.
         * @implements IPersonResponse
         * @constructor
         * @param {PlatformAdminApi.IPersonResponse=} [properties] Properties to set
         */
        function PersonResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PersonResponse data.
         * @member {PlatformAdminApi.IPerson|null|undefined} data
         * @memberof PlatformAdminApi.PersonResponse
         * @instance
         */
        PersonResponse.prototype.data = null;

        /**
         * Encodes the specified PersonResponse message. Does not implicitly {@link PlatformAdminApi.PersonResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PersonResponse
         * @static
         * @param {PlatformAdminApi.IPersonResponse} message PersonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PersonResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Person.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PersonResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PersonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PersonResponse} PersonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PersonResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PersonResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Person.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PersonResponse;
    })();

    PlatformAdminApi.SearchPersonRequest = (function() {

        /**
         * Properties of a SearchPersonRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchPersonRequest
         * @property {number|null} [page] SearchPersonRequest page
         * @property {number|null} [limit] SearchPersonRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchPersonRequest sort
         * @property {Array.<PlatformAdminApi.SearchPersonRequest.IWhereFields>|null} [where] SearchPersonRequest where
         * @property {string|null} [userId] SearchPersonRequest userId
         */

        /**
         * Constructs a new SearchPersonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchPersonRequest.
         * @implements ISearchPersonRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchPersonRequest=} [properties] Properties to set
         */
        function SearchPersonRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPersonRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPersonRequest
         * @instance
         */
        SearchPersonRequest.prototype.page = 0;

        /**
         * SearchPersonRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPersonRequest
         * @instance
         */
        SearchPersonRequest.prototype.limit = 0;

        /**
         * SearchPersonRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchPersonRequest
         * @instance
         */
        SearchPersonRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchPersonRequest where.
         * @member {Array.<PlatformAdminApi.SearchPersonRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchPersonRequest
         * @instance
         */
        SearchPersonRequest.prototype.where = $util.emptyArray;

        /**
         * SearchPersonRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.SearchPersonRequest
         * @instance
         */
        SearchPersonRequest.prototype.userId = "";

        /**
         * Encodes the specified SearchPersonRequest message. Does not implicitly {@link PlatformAdminApi.SearchPersonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPersonRequest
         * @static
         * @param {PlatformAdminApi.ISearchPersonRequest} message SearchPersonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPersonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchPersonRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.userId);
            return writer;
        };

        /**
         * Decodes a SearchPersonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPersonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPersonRequest} SearchPersonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPersonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPersonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchPersonRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchPersonRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchPersonRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [phone] WhereFields phone
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchPersonRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchPersonRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SearchPersonRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields phone.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} phone
             * @memberof PlatformAdminApi.SearchPersonRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.phone = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchPersonRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchPersonRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchPersonRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    $root.PlatformAdminApi.StringCondition.encode(message.phone, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchPersonRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchPersonRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPersonRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.phone = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchPersonRequest;
    })();

    PlatformAdminApi.SearchPersonResponse = (function() {

        /**
         * Properties of a SearchPersonResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchPersonResponse
         * @property {Array.<PlatformAdminApi.SearchPersonResponse.IPerson>|null} [data] SearchPersonResponse data
         * @property {number|null} [page] SearchPersonResponse page
         * @property {number|null} [limit] SearchPersonResponse limit
         * @property {number|null} [total] SearchPersonResponse total
         */

        /**
         * Constructs a new SearchPersonResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchPersonResponse.
         * @implements ISearchPersonResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchPersonResponse=} [properties] Properties to set
         */
        function SearchPersonResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPersonResponse data.
         * @member {Array.<PlatformAdminApi.SearchPersonResponse.IPerson>} data
         * @memberof PlatformAdminApi.SearchPersonResponse
         * @instance
         */
        SearchPersonResponse.prototype.data = $util.emptyArray;

        /**
         * SearchPersonResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPersonResponse
         * @instance
         */
        SearchPersonResponse.prototype.page = 0;

        /**
         * SearchPersonResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPersonResponse
         * @instance
         */
        SearchPersonResponse.prototype.limit = 0;

        /**
         * SearchPersonResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchPersonResponse
         * @instance
         */
        SearchPersonResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchPersonResponse message. Does not implicitly {@link PlatformAdminApi.SearchPersonResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPersonResponse
         * @static
         * @param {PlatformAdminApi.ISearchPersonResponse} message SearchPersonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPersonResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.SearchPersonResponse.Person.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchPersonResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPersonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPersonResponse} SearchPersonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPersonResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPersonResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.SearchPersonResponse.Person.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchPersonResponse.Person = (function() {

            /**
             * Properties of a Person.
             * @memberof PlatformAdminApi.SearchPersonResponse
             * @interface IPerson
             * @property {string|null} [id] Person id
             * @property {PlatformAdminApi.IFio|null} [fio] Person fio
             * @property {string|null} [ssoId] Person ssoId
             * @property {string|null} [phone] Person phone
             */

            /**
             * Constructs a new Person.
             * @memberof PlatformAdminApi.SearchPersonResponse
             * @classdesc Represents a Person.
             * @implements IPerson
             * @constructor
             * @param {PlatformAdminApi.SearchPersonResponse.IPerson=} [properties] Properties to set
             */
            function Person(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Person id.
             * @member {string} id
             * @memberof PlatformAdminApi.SearchPersonResponse.Person
             * @instance
             */
            Person.prototype.id = "";

            /**
             * Person fio.
             * @member {PlatformAdminApi.IFio|null|undefined} fio
             * @memberof PlatformAdminApi.SearchPersonResponse.Person
             * @instance
             */
            Person.prototype.fio = null;

            /**
             * Person ssoId.
             * @member {string} ssoId
             * @memberof PlatformAdminApi.SearchPersonResponse.Person
             * @instance
             */
            Person.prototype.ssoId = "";

            /**
             * Person phone.
             * @member {string} phone
             * @memberof PlatformAdminApi.SearchPersonResponse.Person
             * @instance
             */
            Person.prototype.phone = "";

            /**
             * Encodes the specified Person message. Does not implicitly {@link PlatformAdminApi.SearchPersonResponse.Person.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchPersonResponse.Person
             * @static
             * @param {PlatformAdminApi.SearchPersonResponse.IPerson} message Person message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Person.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                    $root.PlatformAdminApi.Fio.encode(message.fio, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.ssoId);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                return writer;
            };

            /**
             * Decodes a Person message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchPersonResponse.Person
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchPersonResponse.Person} Person
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Person.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPersonResponse.Person();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.fio = $root.PlatformAdminApi.Fio.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.ssoId = reader.string();
                        break;
                    case 4:
                        message.phone = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Person;
        })();

        return SearchPersonResponse;
    })();

    PlatformAdminApi.Person = (function() {

        /**
         * Properties of a Person.
         * @memberof PlatformAdminApi
         * @interface IPerson
         * @property {string|null} [id] Person id
         * @property {number|null} [personNumber] Person personNumber
         * @property {string|null} [email] Person email
         * @property {string|null} [phone] Person phone
         * @property {string|null} [ssoId] Person ssoId
         * @property {PlatformAdminApi.IFio|null} [fio] Person fio
         * @property {string|null} [status] Person status
         * @property {string|null} [commentForExecutor] Person commentForExecutor
         * @property {string|null} [commentForSupport] Person commentForSupport
         * @property {string|null} [qleanClientId] Person qleanClientId
         * @property {string|null} [qleanCreditCardNumber] Person qleanCreditCardNumber
         * @property {string|null} [createdAt] Person createdAt
         * @property {string|null} [updatedAt] Person updatedAt
         * @property {boolean|null} [isDeleted] Person isDeleted
         * @property {string|null} [deletedAt] Person deletedAt
         */

        /**
         * Constructs a new Person.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Person.
         * @implements IPerson
         * @constructor
         * @param {PlatformAdminApi.IPerson=} [properties] Properties to set
         */
        function Person(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Person id.
         * @member {string} id
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.id = "";

        /**
         * Person personNumber.
         * @member {number} personNumber
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.personNumber = 0;

        /**
         * Person email.
         * @member {string} email
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.email = "";

        /**
         * Person phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.phone = "";

        /**
         * Person ssoId.
         * @member {string} ssoId
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.ssoId = "";

        /**
         * Person fio.
         * @member {PlatformAdminApi.IFio|null|undefined} fio
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.fio = null;

        /**
         * Person status.
         * @member {string} status
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.status = "";

        /**
         * Person commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.commentForExecutor = "";

        /**
         * Person commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.commentForSupport = "";

        /**
         * Person qleanClientId.
         * @member {string} qleanClientId
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.qleanClientId = "";

        /**
         * Person qleanCreditCardNumber.
         * @member {string} qleanCreditCardNumber
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.qleanCreditCardNumber = "";

        /**
         * Person createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.createdAt = "";

        /**
         * Person updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.updatedAt = "";

        /**
         * Person isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.isDeleted = false;

        /**
         * Person deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Person
         * @instance
         */
        Person.prototype.deletedAt = "";

        /**
         * Encodes the specified Person message. Does not implicitly {@link PlatformAdminApi.Person.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Person
         * @static
         * @param {PlatformAdminApi.IPerson} message Person message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Person.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.personNumber != null && Object.hasOwnProperty.call(message, "personNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.personNumber);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ssoId);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformAdminApi.Fio.encode(message.fio, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.status);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.commentForSupport);
            if (message.qleanClientId != null && Object.hasOwnProperty.call(message, "qleanClientId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.qleanClientId);
            if (message.qleanCreditCardNumber != null && Object.hasOwnProperty.call(message, "qleanCreditCardNumber"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.qleanCreditCardNumber);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Person message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Person
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Person} Person
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Person.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Person();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.personNumber = reader.int32();
                    break;
                case 3:
                    message.email = reader.string();
                    break;
                case 4:
                    message.phone = reader.string();
                    break;
                case 5:
                    message.ssoId = reader.string();
                    break;
                case 6:
                    message.fio = $root.PlatformAdminApi.Fio.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.status = reader.string();
                    break;
                case 8:
                    message.commentForExecutor = reader.string();
                    break;
                case 9:
                    message.commentForSupport = reader.string();
                    break;
                case 10:
                    message.qleanClientId = reader.string();
                    break;
                case 11:
                    message.qleanCreditCardNumber = reader.string();
                    break;
                case 12:
                    message.createdAt = reader.string();
                    break;
                case 13:
                    message.updatedAt = reader.string();
                    break;
                case 14:
                    message.isDeleted = reader.bool();
                    break;
                case 15:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Person;
    })();

    PlatformAdminApi.ProspectsService = (function() {

        /**
         * Constructs a new ProspectsService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectsService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ProspectsService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ProspectsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProspectsService;

        /**
         * Callback as used by {@link PlatformAdminApi.ProspectsService#create}.
         * @memberof PlatformAdminApi.ProspectsService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProspectResponse} [response] ProspectResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspect} request Prospect message or plain object
         * @param {PlatformAdminApi.ProspectsService.CreateCallback} callback Node-style callback called with the error, if any, and ProspectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectsService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.Prospect, $root.PlatformAdminApi.ProspectResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspect} request Prospect message or plain object
         * @returns {Promise<PlatformAdminApi.ProspectResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProspectsService#update}.
         * @memberof PlatformAdminApi.ProspectsService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProspectResponse} [response] ProspectResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspect} request Prospect message or plain object
         * @param {PlatformAdminApi.ProspectsService.UpdateCallback} callback Node-style callback called with the error, if any, and ProspectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectsService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.Prospect, $root.PlatformAdminApi.ProspectResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspect} request Prospect message or plain object
         * @returns {Promise<PlatformAdminApi.ProspectResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProspectsService#search}.
         * @memberof PlatformAdminApi.ProspectsService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProspectSearchResponse} [response] ProspectSearchResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectSearchRequest} request ProspectSearchRequest message or plain object
         * @param {PlatformAdminApi.ProspectsService.SearchCallback} callback Node-style callback called with the error, if any, and ProspectSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectsService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.ProspectSearchRequest, $root.PlatformAdminApi.ProspectSearchResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectSearchRequest} request ProspectSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ProspectSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProspectsService#findById}.
         * @memberof PlatformAdminApi.ProspectsService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProspectResponse} [response] ProspectResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @param {PlatformAdminApi.ProspectsService.FindByIdCallback} callback Node-style callback called with the error, if any, and ProspectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectsService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.ProspectId, $root.PlatformAdminApi.ProspectResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @returns {Promise<PlatformAdminApi.ProspectResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProspectsService#remove}.
         * @memberof PlatformAdminApi.ProspectsService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProspectResponse} [response] ProspectResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @param {PlatformAdminApi.ProspectsService.RemoveCallback} callback Node-style callback called with the error, if any, and ProspectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectsService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.ProspectId, $root.PlatformAdminApi.ProspectResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @returns {Promise<PlatformAdminApi.ProspectResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProspectsService#logicRemove}.
         * @memberof PlatformAdminApi.ProspectsService
         * @typedef LogicRemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProspectResponse} [response] ProspectResponse
         */

        /**
         * Calls LogicRemove.
         * @function logicRemove
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @param {PlatformAdminApi.ProspectsService.LogicRemoveCallback} callback Node-style callback called with the error, if any, and ProspectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectsService.prototype.logicRemove = function logicRemove(request, callback) {
            return this.rpcCall(logicRemove, $root.PlatformAdminApi.ProspectId, $root.PlatformAdminApi.ProspectResponse, request, callback);
        }, "name", { value: "LogicRemove" });

        /**
         * Calls LogicRemove.
         * @function logicRemove
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @returns {Promise<PlatformAdminApi.ProspectResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ProspectsService#logicRestore}.
         * @memberof PlatformAdminApi.ProspectsService
         * @typedef LogicRestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ProspectResponse} [response] ProspectResponse
         */

        /**
         * Calls LogicRestore.
         * @function logicRestore
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @param {PlatformAdminApi.ProspectsService.LogicRestoreCallback} callback Node-style callback called with the error, if any, and ProspectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectsService.prototype.logicRestore = function logicRestore(request, callback) {
            return this.rpcCall(logicRestore, $root.PlatformAdminApi.ProspectId, $root.PlatformAdminApi.ProspectResponse, request, callback);
        }, "name", { value: "LogicRestore" });

        /**
         * Calls LogicRestore.
         * @function logicRestore
         * @memberof PlatformAdminApi.ProspectsService
         * @instance
         * @param {PlatformAdminApi.IProspectId} request ProspectId message or plain object
         * @returns {Promise<PlatformAdminApi.ProspectResponse>} Promise
         * @variation 2
         */

        return ProspectsService;
    })();

    PlatformAdminApi.ProspectId = (function() {

        /**
         * Properties of a ProspectId.
         * @memberof PlatformAdminApi
         * @interface IProspectId
         * @property {string|null} [id] ProspectId id
         */

        /**
         * Constructs a new ProspectId.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectId.
         * @implements IProspectId
         * @constructor
         * @param {PlatformAdminApi.IProspectId=} [properties] Properties to set
         */
        function ProspectId(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProspectId id.
         * @member {string} id
         * @memberof PlatformAdminApi.ProspectId
         * @instance
         */
        ProspectId.prototype.id = "";

        /**
         * Encodes the specified ProspectId message. Does not implicitly {@link PlatformAdminApi.ProspectId.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProspectId
         * @static
         * @param {PlatformAdminApi.IProspectId} message ProspectId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProspectId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a ProspectId message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProspectId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProspectId} ProspectId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProspectId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectId();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProspectId;
    })();

    PlatformAdminApi.ProspectResponse = (function() {

        /**
         * Properties of a ProspectResponse.
         * @memberof PlatformAdminApi
         * @interface IProspectResponse
         * @property {PlatformAdminApi.IProspect|null} [data] ProspectResponse data
         */

        /**
         * Constructs a new ProspectResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectResponse.
         * @implements IProspectResponse
         * @constructor
         * @param {PlatformAdminApi.IProspectResponse=} [properties] Properties to set
         */
        function ProspectResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProspectResponse data.
         * @member {PlatformAdminApi.IProspect|null|undefined} data
         * @memberof PlatformAdminApi.ProspectResponse
         * @instance
         */
        ProspectResponse.prototype.data = null;

        /**
         * Encodes the specified ProspectResponse message. Does not implicitly {@link PlatformAdminApi.ProspectResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProspectResponse
         * @static
         * @param {PlatformAdminApi.IProspectResponse} message ProspectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProspectResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Prospect.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProspectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProspectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProspectResponse} ProspectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProspectResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Prospect.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProspectResponse;
    })();

    PlatformAdminApi.ProspectSearchRequest = (function() {

        /**
         * Properties of a ProspectSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IProspectSearchRequest
         * @property {number|null} [page] ProspectSearchRequest page
         * @property {number|null} [limit] ProspectSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] ProspectSearchRequest sort
         * @property {Array.<PlatformAdminApi.ProspectSearchRequest.IWhereFields>|null} [where] ProspectSearchRequest where
         * @property {Array.<string>|null} [relations] ProspectSearchRequest relations
         */

        /**
         * Constructs a new ProspectSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectSearchRequest.
         * @implements IProspectSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IProspectSearchRequest=} [properties] Properties to set
         */
        function ProspectSearchRequest(properties) {
            this.sort = [];
            this.where = [];
            this.relations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProspectSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.ProspectSearchRequest
         * @instance
         */
        ProspectSearchRequest.prototype.page = 0;

        /**
         * ProspectSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ProspectSearchRequest
         * @instance
         */
        ProspectSearchRequest.prototype.limit = 0;

        /**
         * ProspectSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.ProspectSearchRequest
         * @instance
         */
        ProspectSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * ProspectSearchRequest where.
         * @member {Array.<PlatformAdminApi.ProspectSearchRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.ProspectSearchRequest
         * @instance
         */
        ProspectSearchRequest.prototype.where = $util.emptyArray;

        /**
         * ProspectSearchRequest relations.
         * @member {Array.<string>} relations
         * @memberof PlatformAdminApi.ProspectSearchRequest
         * @instance
         */
        ProspectSearchRequest.prototype.relations = $util.emptyArray;

        /**
         * Encodes the specified ProspectSearchRequest message. Does not implicitly {@link PlatformAdminApi.ProspectSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProspectSearchRequest
         * @static
         * @param {PlatformAdminApi.IProspectSearchRequest} message ProspectSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProspectSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.ProspectSearchRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.relations != null && message.relations.length)
                for (var i = 0; i < message.relations.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.relations[i]);
            return writer;
        };

        /**
         * Decodes a ProspectSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProspectSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProspectSearchRequest} ProspectSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProspectSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.ProspectSearchRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.relations && message.relations.length))
                        message.relations = [];
                    message.relations.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ProspectSearchRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.ProspectSearchRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [slug] WhereFields slug
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {boolean|null} [isBefore] WhereFields isBefore
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.ProspectSearchRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.ProspectSearchRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields slug.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} slug
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.slug = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields isBefore.
             * @member {boolean} isBefore
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isBefore = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.ProspectSearchRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.ProspectSearchRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                    $root.PlatformAdminApi.StringCondition.encode(message.slug, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.isBefore != null && Object.hasOwnProperty.call(message, "isBefore"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isBefore);
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ProspectSearchRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ProspectSearchRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectSearchRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.slug = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 8:
                        message.isBefore = reader.bool();
                        break;
                    case 5:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return ProspectSearchRequest;
    })();

    PlatformAdminApi.ProspectSearchResponse = (function() {

        /**
         * Properties of a ProspectSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IProspectSearchResponse
         * @property {Array.<PlatformAdminApi.IProspect>|null} [data] ProspectSearchResponse data
         * @property {number|null} [page] ProspectSearchResponse page
         * @property {number|null} [pageCount] ProspectSearchResponse pageCount
         * @property {number|null} [limit] ProspectSearchResponse limit
         * @property {number|null} [count] ProspectSearchResponse count
         * @property {number|null} [total] ProspectSearchResponse total
         */

        /**
         * Constructs a new ProspectSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectSearchResponse.
         * @implements IProspectSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IProspectSearchResponse=} [properties] Properties to set
         */
        function ProspectSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProspectSearchResponse data.
         * @member {Array.<PlatformAdminApi.IProspect>} data
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @instance
         */
        ProspectSearchResponse.prototype.data = $util.emptyArray;

        /**
         * ProspectSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @instance
         */
        ProspectSearchResponse.prototype.page = 0;

        /**
         * ProspectSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @instance
         */
        ProspectSearchResponse.prototype.pageCount = 0;

        /**
         * ProspectSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @instance
         */
        ProspectSearchResponse.prototype.limit = 0;

        /**
         * ProspectSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @instance
         */
        ProspectSearchResponse.prototype.count = 0;

        /**
         * ProspectSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @instance
         */
        ProspectSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified ProspectSearchResponse message. Does not implicitly {@link PlatformAdminApi.ProspectSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @static
         * @param {PlatformAdminApi.IProspectSearchResponse} message ProspectSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProspectSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Prospect.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ProspectSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProspectSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProspectSearchResponse} ProspectSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProspectSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Prospect.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProspectSearchResponse;
    })();

    PlatformAdminApi.ProspectsMoveToCrmRequest = (function() {

        /**
         * Properties of a ProspectsMoveToCrmRequest.
         * @memberof PlatformAdminApi
         * @interface IProspectsMoveToCrmRequest
         * @property {Array.<string>|null} [ids] ProspectsMoveToCrmRequest ids
         * @property {string|null} [managerUid] ProspectsMoveToCrmRequest managerUid
         */

        /**
         * Constructs a new ProspectsMoveToCrmRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectsMoveToCrmRequest.
         * @implements IProspectsMoveToCrmRequest
         * @constructor
         * @param {PlatformAdminApi.IProspectsMoveToCrmRequest=} [properties] Properties to set
         */
        function ProspectsMoveToCrmRequest(properties) {
            this.ids = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProspectsMoveToCrmRequest ids.
         * @member {Array.<string>} ids
         * @memberof PlatformAdminApi.ProspectsMoveToCrmRequest
         * @instance
         */
        ProspectsMoveToCrmRequest.prototype.ids = $util.emptyArray;

        /**
         * ProspectsMoveToCrmRequest managerUid.
         * @member {string} managerUid
         * @memberof PlatformAdminApi.ProspectsMoveToCrmRequest
         * @instance
         */
        ProspectsMoveToCrmRequest.prototype.managerUid = "";

        /**
         * Encodes the specified ProspectsMoveToCrmRequest message. Does not implicitly {@link PlatformAdminApi.ProspectsMoveToCrmRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProspectsMoveToCrmRequest
         * @static
         * @param {PlatformAdminApi.IProspectsMoveToCrmRequest} message ProspectsMoveToCrmRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProspectsMoveToCrmRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ids != null && message.ids.length)
                for (var i = 0; i < message.ids.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ids[i]);
            if (message.managerUid != null && Object.hasOwnProperty.call(message, "managerUid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.managerUid);
            return writer;
        };

        /**
         * Decodes a ProspectsMoveToCrmRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProspectsMoveToCrmRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProspectsMoveToCrmRequest} ProspectsMoveToCrmRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProspectsMoveToCrmRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectsMoveToCrmRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.ids && message.ids.length))
                        message.ids = [];
                    message.ids.push(reader.string());
                    break;
                case 2:
                    message.managerUid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProspectsMoveToCrmRequest;
    })();

    PlatformAdminApi.CreateOrderResponse = (function() {

        /**
         * Properties of a CreateOrderResponse.
         * @memberof PlatformAdminApi
         * @interface ICreateOrderResponse
         * @property {string|null} [orderId] CreateOrderResponse orderId
         * @property {string|null} [contractId] CreateOrderResponse contractId
         */

        /**
         * Constructs a new CreateOrderResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CreateOrderResponse.
         * @implements ICreateOrderResponse
         * @constructor
         * @param {PlatformAdminApi.ICreateOrderResponse=} [properties] Properties to set
         */
        function CreateOrderResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateOrderResponse orderId.
         * @member {string} orderId
         * @memberof PlatformAdminApi.CreateOrderResponse
         * @instance
         */
        CreateOrderResponse.prototype.orderId = "";

        /**
         * CreateOrderResponse contractId.
         * @member {string} contractId
         * @memberof PlatformAdminApi.CreateOrderResponse
         * @instance
         */
        CreateOrderResponse.prototype.contractId = "";

        /**
         * Encodes the specified CreateOrderResponse message. Does not implicitly {@link PlatformAdminApi.CreateOrderResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateOrderResponse
         * @static
         * @param {PlatformAdminApi.ICreateOrderResponse} message CreateOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.contractId != null && Object.hasOwnProperty.call(message, "contractId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contractId);
            return writer;
        };

        /**
         * Decodes a CreateOrderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateOrderResponse} CreateOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateOrderResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    message.contractId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateOrderResponse;
    })();

    PlatformAdminApi.ProspectUpsertRequest = (function() {

        /**
         * Properties of a ProspectUpsertRequest.
         * @memberof PlatformAdminApi
         * @interface IProspectUpsertRequest
         * @property {string|null} [publicId] ProspectUpsertRequest publicId
         * @property {string|null} [phone] ProspectUpsertRequest phone
         * @property {string|null} [email] ProspectUpsertRequest email
         * @property {PlatformAdminApi.IFio|null} [fio] ProspectUpsertRequest fio
         * @property {string|null} [startDate] ProspectUpsertRequest startDate
         * @property {Array.<PlatformAdminApi.ICRMProduct>|null} [products] ProspectUpsertRequest products
         * @property {number|null} [regionId] ProspectUpsertRequest regionId
         * @property {string|null} [qleanAddress] ProspectUpsertRequest qleanAddress
         * @property {PlatformAdminApi.IAddress|null} [address] ProspectUpsertRequest address
         * @property {string|null} [paymentType] ProspectUpsertRequest paymentType
         * @property {string|null} [serviceType] ProspectUpsertRequest serviceType
         * @property {string|null} [promocode] ProspectUpsertRequest promocode
         * @property {string|null} [regularity] ProspectUpsertRequest regularity
         * @property {string|null} [utmCampaign] ProspectUpsertRequest utmCampaign
         * @property {string|null} [utmContent] ProspectUpsertRequest utmContent
         * @property {string|null} [utmData] ProspectUpsertRequest utmData
         * @property {string|null} [utmMedium] ProspectUpsertRequest utmMedium
         * @property {string|null} [utmSource] ProspectUpsertRequest utmSource
         * @property {string|null} [utmTerm] ProspectUpsertRequest utmTerm
         * @property {boolean|null} [isShortForm] ProspectUpsertRequest isShortForm
         * @property {string|null} [status] ProspectUpsertRequest status
         * @property {string|null} [commentForExecutor] ProspectUpsertRequest commentForExecutor
         * @property {string|null} [commentForSupport] ProspectUpsertRequest commentForSupport
         * @property {string|null} [strategy] ProspectUpsertRequest strategy
         * @property {string|null} [qleanPromocodeId] ProspectUpsertRequest qleanPromocodeId
         * @property {string|null} [id] ProspectUpsertRequest id
         * @property {number|null} [discount] ProspectUpsertRequest discount
         * @property {PlatformAdminApi.IAddress|null} [keysDelivery] ProspectUpsertRequest keysDelivery
         * @property {PlatformAdminApi.IAddress|null} [keysPickup] ProspectUpsertRequest keysPickup
         * @property {string|null} [creationMean] ProspectUpsertRequest creationMean
         * @property {string|null} [communicationChannel] ProspectUpsertRequest communicationChannel
         * @property {string|null} [externalId] ProspectUpsertRequest externalId
         * @property {string|null} [segment] ProspectUpsertRequest segment
         * @property {string|null} [taskId] ProspectUpsertRequest taskId
         * @property {string|null} [qleanOrderId] ProspectUpsertRequest qleanOrderId
         * @property {boolean|null} [isVerified] ProspectUpsertRequest isVerified
         */

        /**
         * Constructs a new ProspectUpsertRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectUpsertRequest.
         * @implements IProspectUpsertRequest
         * @constructor
         * @param {PlatformAdminApi.IProspectUpsertRequest=} [properties] Properties to set
         */
        function ProspectUpsertRequest(properties) {
            this.products = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProspectUpsertRequest publicId.
         * @member {string} publicId
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.publicId = "";

        /**
         * ProspectUpsertRequest phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.phone = "";

        /**
         * ProspectUpsertRequest email.
         * @member {string} email
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.email = "";

        /**
         * ProspectUpsertRequest fio.
         * @member {PlatformAdminApi.IFio|null|undefined} fio
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.fio = null;

        /**
         * ProspectUpsertRequest startDate.
         * @member {string} startDate
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.startDate = "";

        /**
         * ProspectUpsertRequest products.
         * @member {Array.<PlatformAdminApi.ICRMProduct>} products
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.products = $util.emptyArray;

        /**
         * ProspectUpsertRequest regionId.
         * @member {number} regionId
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.regionId = 0;

        /**
         * ProspectUpsertRequest qleanAddress.
         * @member {string} qleanAddress
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.qleanAddress = "";

        /**
         * ProspectUpsertRequest address.
         * @member {PlatformAdminApi.IAddress|null|undefined} address
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.address = null;

        /**
         * ProspectUpsertRequest paymentType.
         * @member {string} paymentType
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.paymentType = "";

        /**
         * ProspectUpsertRequest serviceType.
         * @member {string} serviceType
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.serviceType = "";

        /**
         * ProspectUpsertRequest promocode.
         * @member {string} promocode
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.promocode = "";

        /**
         * ProspectUpsertRequest regularity.
         * @member {string} regularity
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.regularity = "";

        /**
         * ProspectUpsertRequest utmCampaign.
         * @member {string} utmCampaign
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.utmCampaign = "";

        /**
         * ProspectUpsertRequest utmContent.
         * @member {string} utmContent
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.utmContent = "";

        /**
         * ProspectUpsertRequest utmData.
         * @member {string} utmData
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.utmData = "";

        /**
         * ProspectUpsertRequest utmMedium.
         * @member {string} utmMedium
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.utmMedium = "";

        /**
         * ProspectUpsertRequest utmSource.
         * @member {string} utmSource
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.utmSource = "";

        /**
         * ProspectUpsertRequest utmTerm.
         * @member {string} utmTerm
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.utmTerm = "";

        /**
         * ProspectUpsertRequest isShortForm.
         * @member {boolean} isShortForm
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.isShortForm = false;

        /**
         * ProspectUpsertRequest status.
         * @member {string} status
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.status = "";

        /**
         * ProspectUpsertRequest commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.commentForExecutor = "";

        /**
         * ProspectUpsertRequest commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.commentForSupport = "";

        /**
         * ProspectUpsertRequest strategy.
         * @member {string} strategy
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.strategy = "";

        /**
         * ProspectUpsertRequest qleanPromocodeId.
         * @member {string} qleanPromocodeId
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.qleanPromocodeId = "";

        /**
         * ProspectUpsertRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.id = "";

        /**
         * ProspectUpsertRequest discount.
         * @member {number} discount
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.discount = 0;

        /**
         * ProspectUpsertRequest keysDelivery.
         * @member {PlatformAdminApi.IAddress|null|undefined} keysDelivery
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.keysDelivery = null;

        /**
         * ProspectUpsertRequest keysPickup.
         * @member {PlatformAdminApi.IAddress|null|undefined} keysPickup
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.keysPickup = null;

        /**
         * ProspectUpsertRequest creationMean.
         * @member {string} creationMean
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.creationMean = "";

        /**
         * ProspectUpsertRequest communicationChannel.
         * @member {string} communicationChannel
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.communicationChannel = "";

        /**
         * ProspectUpsertRequest externalId.
         * @member {string} externalId
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.externalId = "";

        /**
         * ProspectUpsertRequest segment.
         * @member {string} segment
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.segment = "";

        /**
         * ProspectUpsertRequest taskId.
         * @member {string} taskId
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.taskId = "";

        /**
         * ProspectUpsertRequest qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.qleanOrderId = "";

        /**
         * ProspectUpsertRequest isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @instance
         */
        ProspectUpsertRequest.prototype.isVerified = false;

        /**
         * Encodes the specified ProspectUpsertRequest message. Does not implicitly {@link PlatformAdminApi.ProspectUpsertRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @static
         * @param {PlatformAdminApi.IProspectUpsertRequest} message ProspectUpsertRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProspectUpsertRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.publicId != null && Object.hasOwnProperty.call(message, "publicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.publicId);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.phone);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformAdminApi.Fio.encode(message.fio, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.startDate);
            if (message.products != null && message.products.length)
                for (var i = 0; i < message.products.length; ++i)
                    $root.PlatformAdminApi.CRMProduct.encode(message.products[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.regionId);
            if (message.qleanAddress != null && Object.hasOwnProperty.call(message, "qleanAddress"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.qleanAddress);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.Address.encode(message.address, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.paymentType);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.serviceType);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.promocode);
            if (message.regularity != null && Object.hasOwnProperty.call(message, "regularity"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.regularity);
            if (message.utmCampaign != null && Object.hasOwnProperty.call(message, "utmCampaign"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.utmCampaign);
            if (message.utmContent != null && Object.hasOwnProperty.call(message, "utmContent"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.utmContent);
            if (message.utmData != null && Object.hasOwnProperty.call(message, "utmData"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.utmData);
            if (message.utmMedium != null && Object.hasOwnProperty.call(message, "utmMedium"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.utmMedium);
            if (message.utmSource != null && Object.hasOwnProperty.call(message, "utmSource"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.utmSource);
            if (message.utmTerm != null && Object.hasOwnProperty.call(message, "utmTerm"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.utmTerm);
            if (message.isShortForm != null && Object.hasOwnProperty.call(message, "isShortForm"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.isShortForm);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.status);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.commentForSupport);
            if (message.strategy != null && Object.hasOwnProperty.call(message, "strategy"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.strategy);
            if (message.qleanPromocodeId != null && Object.hasOwnProperty.call(message, "qleanPromocodeId"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.qleanPromocodeId);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.id);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 27, wireType 0 =*/216).int32(message.discount);
            if (message.keysDelivery != null && Object.hasOwnProperty.call(message, "keysDelivery"))
                $root.PlatformAdminApi.Address.encode(message.keysDelivery, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.keysPickup != null && Object.hasOwnProperty.call(message, "keysPickup"))
                $root.PlatformAdminApi.Address.encode(message.keysPickup, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.creationMean != null && Object.hasOwnProperty.call(message, "creationMean"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.creationMean);
            if (message.communicationChannel != null && Object.hasOwnProperty.call(message, "communicationChannel"))
                writer.uint32(/* id 31, wireType 2 =*/250).string(message.communicationChannel);
            if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.externalId);
            if (message.segment != null && Object.hasOwnProperty.call(message, "segment"))
                writer.uint32(/* id 33, wireType 2 =*/266).string(message.segment);
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 34, wireType 2 =*/274).string(message.taskId);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 35, wireType 2 =*/282).string(message.qleanOrderId);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 36, wireType 0 =*/288).bool(message.isVerified);
            return writer;
        };

        /**
         * Decodes a ProspectUpsertRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProspectUpsertRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProspectUpsertRequest} ProspectUpsertRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProspectUpsertRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectUpsertRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.publicId = reader.string();
                    break;
                case 2:
                    message.phone = reader.string();
                    break;
                case 3:
                    message.email = reader.string();
                    break;
                case 4:
                    message.fio = $root.PlatformAdminApi.Fio.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.startDate = reader.string();
                    break;
                case 6:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.PlatformAdminApi.CRMProduct.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.regionId = reader.int32();
                    break;
                case 8:
                    message.qleanAddress = reader.string();
                    break;
                case 9:
                    message.address = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.paymentType = reader.string();
                    break;
                case 11:
                    message.serviceType = reader.string();
                    break;
                case 12:
                    message.promocode = reader.string();
                    break;
                case 13:
                    message.regularity = reader.string();
                    break;
                case 14:
                    message.utmCampaign = reader.string();
                    break;
                case 15:
                    message.utmContent = reader.string();
                    break;
                case 16:
                    message.utmData = reader.string();
                    break;
                case 17:
                    message.utmMedium = reader.string();
                    break;
                case 18:
                    message.utmSource = reader.string();
                    break;
                case 19:
                    message.utmTerm = reader.string();
                    break;
                case 20:
                    message.isShortForm = reader.bool();
                    break;
                case 21:
                    message.status = reader.string();
                    break;
                case 22:
                    message.commentForExecutor = reader.string();
                    break;
                case 23:
                    message.commentForSupport = reader.string();
                    break;
                case 24:
                    message.strategy = reader.string();
                    break;
                case 25:
                    message.qleanPromocodeId = reader.string();
                    break;
                case 26:
                    message.id = reader.string();
                    break;
                case 27:
                    message.discount = reader.int32();
                    break;
                case 28:
                    message.keysDelivery = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.keysPickup = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.creationMean = reader.string();
                    break;
                case 31:
                    message.communicationChannel = reader.string();
                    break;
                case 32:
                    message.externalId = reader.string();
                    break;
                case 33:
                    message.segment = reader.string();
                    break;
                case 34:
                    message.taskId = reader.string();
                    break;
                case 35:
                    message.qleanOrderId = reader.string();
                    break;
                case 36:
                    message.isVerified = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProspectUpsertRequest;
    })();

    PlatformAdminApi.CRMProduct = (function() {

        /**
         * Properties of a CRMProduct.
         * @memberof PlatformAdminApi
         * @interface ICRMProduct
         * @property {string|null} [productSlug] CRMProduct productSlug
         * @property {Array.<PlatformAdminApi.IProductOption>|null} [options] CRMProduct options
         */

        /**
         * Constructs a new CRMProduct.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CRMProduct.
         * @implements ICRMProduct
         * @constructor
         * @param {PlatformAdminApi.ICRMProduct=} [properties] Properties to set
         */
        function CRMProduct(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CRMProduct productSlug.
         * @member {string} productSlug
         * @memberof PlatformAdminApi.CRMProduct
         * @instance
         */
        CRMProduct.prototype.productSlug = "";

        /**
         * CRMProduct options.
         * @member {Array.<PlatformAdminApi.IProductOption>} options
         * @memberof PlatformAdminApi.CRMProduct
         * @instance
         */
        CRMProduct.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified CRMProduct message. Does not implicitly {@link PlatformAdminApi.CRMProduct.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CRMProduct
         * @static
         * @param {PlatformAdminApi.ICRMProduct} message CRMProduct message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CRMProduct.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.ProductOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CRMProduct message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CRMProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CRMProduct} CRMProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CRMProduct.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CRMProduct();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                case 2:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.ProductOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CRMProduct;
    })();

    PlatformAdminApi.ProductOption = (function() {

        /**
         * Properties of a ProductOption.
         * @memberof PlatformAdminApi
         * @interface IProductOption
         * @property {string|null} [slug] ProductOption slug
         * @property {number|null} [value] ProductOption value
         * @property {boolean|null} [isFree] ProductOption isFree
         * @property {number|null} [factor] ProductOption factor
         */

        /**
         * Constructs a new ProductOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProductOption.
         * @implements IProductOption
         * @constructor
         * @param {PlatformAdminApi.IProductOption=} [properties] Properties to set
         */
        function ProductOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductOption slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.ProductOption
         * @instance
         */
        ProductOption.prototype.slug = "";

        /**
         * ProductOption value.
         * @member {number} value
         * @memberof PlatformAdminApi.ProductOption
         * @instance
         */
        ProductOption.prototype.value = 0;

        /**
         * ProductOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.ProductOption
         * @instance
         */
        ProductOption.prototype.isFree = false;

        /**
         * ProductOption factor.
         * @member {number} factor
         * @memberof PlatformAdminApi.ProductOption
         * @instance
         */
        ProductOption.prototype.factor = 0;

        /**
         * Encodes the specified ProductOption message. Does not implicitly {@link PlatformAdminApi.ProductOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProductOption
         * @static
         * @param {PlatformAdminApi.IProductOption} message ProductOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slug);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isFree);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.factor);
            return writer;
        };

        /**
         * Decodes a ProductOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProductOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProductOption} ProductOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProductOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slug = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.isFree = reader.bool();
                    break;
                case 4:
                    message.factor = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProductOption;
    })();

    PlatformAdminApi.Prospect = (function() {

        /**
         * Properties of a Prospect.
         * @memberof PlatformAdminApi
         * @interface IProspect
         * @property {string|null} [id] Prospect id
         * @property {number|null} [prospectNumber] Prospect prospectNumber
         * @property {string|null} [publicId] Prospect publicId
         * @property {boolean|null} [isShortForm] Prospect isShortForm
         * @property {string|null} [phone] Prospect phone
         * @property {PlatformAdminApi.IFio|null} [fio] Prospect fio
         * @property {string|null} [email] Prospect email
         * @property {PlatformAdminApi.ICalculateResponse|null} [price] Prospect price
         * @property {string|null} [status] Prospect status
         * @property {string|null} [cancelReason] Prospect cancelReason
         * @property {string|null} [platform] Prospect platform
         * @property {string|null} [startDate] Prospect startDate
         * @property {string|null} [orderId] Prospect orderId
         * @property {boolean|null} [isManualProcessing] Prospect isManualProcessing
         * @property {PlatformAdminApi.IAddress|null} [address] Prospect address
         * @property {string|null} [qleanAddress] Prospect qleanAddress
         * @property {number|null} [regionId] Prospect regionId
         * @property {PlatformAdminApi.IAddress|null} [keysDelivery] Prospect keysDelivery
         * @property {PlatformAdminApi.IAddress|null} [keysPickup] Prospect keysPickup
         * @property {string|null} [promocode] Prospect promocode
         * @property {string|null} [regularity] Prospect regularity
         * @property {boolean|null} [isOvertime] Prospect isOvertime
         * @property {string|null} [strategy] Prospect strategy
         * @property {string|null} [serviceType] Prospect serviceType
         * @property {string|null} [paymentType] Prospect paymentType
         * @property {number|null} [totalPrice] Prospect totalPrice
         * @property {number|null} [totalDuration] Prospect totalDuration
         * @property {string|null} [commentForExecutor] Prospect commentForExecutor
         * @property {string|null} [commentForSupport] Prospect commentForSupport
         * @property {string|null} [qleanClientId] Prospect qleanClientId
         * @property {string|null} [qleanOrderId] Prospect qleanOrderId
         * @property {string|null} [qleanPromocodeId] Prospect qleanPromocodeId
         * @property {string|null} [qleanLeadId] Prospect qleanLeadId
         * @property {string|null} [qleanWebOrderId] Prospect qleanWebOrderId
         * @property {string|null} [qleanPreferredCleanerId] Prospect qleanPreferredCleanerId
         * @property {string|null} [utmCampaign] Prospect utmCampaign
         * @property {string|null} [utmContent] Prospect utmContent
         * @property {string|null} [utmData] Prospect utmData
         * @property {string|null} [utmMedium] Prospect utmMedium
         * @property {string|null} [utmSource] Prospect utmSource
         * @property {string|null} [utmTerm] Prospect utmTerm
         * @property {PlatformAdminApi.ICRMContract|null} [contract] Prospect contract
         * @property {string|null} [taskId] Prospect taskId
         * @property {PlatformAdminApi.IPerson|null} [person] Prospect person
         * @property {number|null} [discount] Prospect discount
         * @property {string|null} [creationMean] Prospect creationMean
         * @property {string|null} [communicationChannel] Prospect communicationChannel
         * @property {string|null} [externalId] Prospect externalId
         * @property {string|null} [segment] Prospect segment
         * @property {string|null} [createdAt] Prospect createdAt
         * @property {string|null} [updatedAt] Prospect updatedAt
         * @property {string|null} [deletedAt] Prospect deletedAt
         * @property {boolean|null} [isDeleted] Prospect isDeleted
         */

        /**
         * Constructs a new Prospect.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Prospect.
         * @implements IProspect
         * @constructor
         * @param {PlatformAdminApi.IProspect=} [properties] Properties to set
         */
        function Prospect(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Prospect id.
         * @member {string} id
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.id = "";

        /**
         * Prospect prospectNumber.
         * @member {number} prospectNumber
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.prospectNumber = 0;

        /**
         * Prospect publicId.
         * @member {string} publicId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.publicId = "";

        /**
         * Prospect isShortForm.
         * @member {boolean} isShortForm
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.isShortForm = false;

        /**
         * Prospect phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.phone = "";

        /**
         * Prospect fio.
         * @member {PlatformAdminApi.IFio|null|undefined} fio
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.fio = null;

        /**
         * Prospect email.
         * @member {string} email
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.email = "";

        /**
         * Prospect price.
         * @member {PlatformAdminApi.ICalculateResponse|null|undefined} price
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.price = null;

        /**
         * Prospect status.
         * @member {string} status
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.status = "";

        /**
         * Prospect cancelReason.
         * @member {string} cancelReason
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.cancelReason = "";

        /**
         * Prospect platform.
         * @member {string} platform
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.platform = "";

        /**
         * Prospect startDate.
         * @member {string} startDate
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.startDate = "";

        /**
         * Prospect orderId.
         * @member {string} orderId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.orderId = "";

        /**
         * Prospect isManualProcessing.
         * @member {boolean} isManualProcessing
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.isManualProcessing = false;

        /**
         * Prospect address.
         * @member {PlatformAdminApi.IAddress|null|undefined} address
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.address = null;

        /**
         * Prospect qleanAddress.
         * @member {string} qleanAddress
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.qleanAddress = "";

        /**
         * Prospect regionId.
         * @member {number} regionId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.regionId = 0;

        /**
         * Prospect keysDelivery.
         * @member {PlatformAdminApi.IAddress|null|undefined} keysDelivery
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.keysDelivery = null;

        /**
         * Prospect keysPickup.
         * @member {PlatformAdminApi.IAddress|null|undefined} keysPickup
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.keysPickup = null;

        /**
         * Prospect promocode.
         * @member {string} promocode
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.promocode = "";

        /**
         * Prospect regularity.
         * @member {string} regularity
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.regularity = "";

        /**
         * Prospect isOvertime.
         * @member {boolean} isOvertime
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.isOvertime = false;

        /**
         * Prospect strategy.
         * @member {string} strategy
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.strategy = "";

        /**
         * Prospect serviceType.
         * @member {string} serviceType
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.serviceType = "";

        /**
         * Prospect paymentType.
         * @member {string} paymentType
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.paymentType = "";

        /**
         * Prospect totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.totalPrice = 0;

        /**
         * Prospect totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.totalDuration = 0;

        /**
         * Prospect commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.commentForExecutor = "";

        /**
         * Prospect commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.commentForSupport = "";

        /**
         * Prospect qleanClientId.
         * @member {string} qleanClientId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.qleanClientId = "";

        /**
         * Prospect qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.qleanOrderId = "";

        /**
         * Prospect qleanPromocodeId.
         * @member {string} qleanPromocodeId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.qleanPromocodeId = "";

        /**
         * Prospect qleanLeadId.
         * @member {string} qleanLeadId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.qleanLeadId = "";

        /**
         * Prospect qleanWebOrderId.
         * @member {string} qleanWebOrderId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.qleanWebOrderId = "";

        /**
         * Prospect qleanPreferredCleanerId.
         * @member {string} qleanPreferredCleanerId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.qleanPreferredCleanerId = "";

        /**
         * Prospect utmCampaign.
         * @member {string} utmCampaign
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.utmCampaign = "";

        /**
         * Prospect utmContent.
         * @member {string} utmContent
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.utmContent = "";

        /**
         * Prospect utmData.
         * @member {string} utmData
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.utmData = "";

        /**
         * Prospect utmMedium.
         * @member {string} utmMedium
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.utmMedium = "";

        /**
         * Prospect utmSource.
         * @member {string} utmSource
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.utmSource = "";

        /**
         * Prospect utmTerm.
         * @member {string} utmTerm
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.utmTerm = "";

        /**
         * Prospect contract.
         * @member {PlatformAdminApi.ICRMContract|null|undefined} contract
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.contract = null;

        /**
         * Prospect taskId.
         * @member {string} taskId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.taskId = "";

        /**
         * Prospect person.
         * @member {PlatformAdminApi.IPerson|null|undefined} person
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.person = null;

        /**
         * Prospect discount.
         * @member {number} discount
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.discount = 0;

        /**
         * Prospect creationMean.
         * @member {string} creationMean
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.creationMean = "";

        /**
         * Prospect communicationChannel.
         * @member {string} communicationChannel
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.communicationChannel = "";

        /**
         * Prospect externalId.
         * @member {string} externalId
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.externalId = "";

        /**
         * Prospect segment.
         * @member {string} segment
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.segment = "";

        /**
         * Prospect createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.createdAt = "";

        /**
         * Prospect updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.updatedAt = "";

        /**
         * Prospect deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.deletedAt = "";

        /**
         * Prospect isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Prospect
         * @instance
         */
        Prospect.prototype.isDeleted = false;

        /**
         * Encodes the specified Prospect message. Does not implicitly {@link PlatformAdminApi.Prospect.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Prospect
         * @static
         * @param {PlatformAdminApi.IProspect} message Prospect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Prospect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.prospectNumber != null && Object.hasOwnProperty.call(message, "prospectNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.prospectNumber);
            if (message.publicId != null && Object.hasOwnProperty.call(message, "publicId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.publicId);
            if (message.isShortForm != null && Object.hasOwnProperty.call(message, "isShortForm"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isShortForm);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformAdminApi.Fio.encode(message.fio, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.email);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.PlatformAdminApi.CalculateResponse.encode(message.price, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.status);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.cancelReason);
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.platform);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.startDate);
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.orderId);
            if (message.isManualProcessing != null && Object.hasOwnProperty.call(message, "isManualProcessing"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.isManualProcessing);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.Address.encode(message.address, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.qleanAddress != null && Object.hasOwnProperty.call(message, "qleanAddress"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.qleanAddress);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 17, wireType 0 =*/136).int32(message.regionId);
            if (message.keysDelivery != null && Object.hasOwnProperty.call(message, "keysDelivery"))
                $root.PlatformAdminApi.Address.encode(message.keysDelivery, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.keysPickup != null && Object.hasOwnProperty.call(message, "keysPickup"))
                $root.PlatformAdminApi.Address.encode(message.keysPickup, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.promocode);
            if (message.regularity != null && Object.hasOwnProperty.call(message, "regularity"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.regularity);
            if (message.isOvertime != null && Object.hasOwnProperty.call(message, "isOvertime"))
                writer.uint32(/* id 22, wireType 0 =*/176).bool(message.isOvertime);
            if (message.strategy != null && Object.hasOwnProperty.call(message, "strategy"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.strategy);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.serviceType);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.paymentType);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 26, wireType 0 =*/208).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 27, wireType 0 =*/216).int32(message.totalDuration);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 28, wireType 2 =*/226).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 29, wireType 2 =*/234).string(message.commentForSupport);
            if (message.qleanClientId != null && Object.hasOwnProperty.call(message, "qleanClientId"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.qleanClientId);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 31, wireType 2 =*/250).string(message.qleanOrderId);
            if (message.qleanPromocodeId != null && Object.hasOwnProperty.call(message, "qleanPromocodeId"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.qleanPromocodeId);
            if (message.qleanLeadId != null && Object.hasOwnProperty.call(message, "qleanLeadId"))
                writer.uint32(/* id 33, wireType 2 =*/266).string(message.qleanLeadId);
            if (message.qleanWebOrderId != null && Object.hasOwnProperty.call(message, "qleanWebOrderId"))
                writer.uint32(/* id 34, wireType 2 =*/274).string(message.qleanWebOrderId);
            if (message.qleanPreferredCleanerId != null && Object.hasOwnProperty.call(message, "qleanPreferredCleanerId"))
                writer.uint32(/* id 35, wireType 2 =*/282).string(message.qleanPreferredCleanerId);
            if (message.utmCampaign != null && Object.hasOwnProperty.call(message, "utmCampaign"))
                writer.uint32(/* id 36, wireType 2 =*/290).string(message.utmCampaign);
            if (message.utmContent != null && Object.hasOwnProperty.call(message, "utmContent"))
                writer.uint32(/* id 37, wireType 2 =*/298).string(message.utmContent);
            if (message.utmData != null && Object.hasOwnProperty.call(message, "utmData"))
                writer.uint32(/* id 38, wireType 2 =*/306).string(message.utmData);
            if (message.utmMedium != null && Object.hasOwnProperty.call(message, "utmMedium"))
                writer.uint32(/* id 39, wireType 2 =*/314).string(message.utmMedium);
            if (message.utmSource != null && Object.hasOwnProperty.call(message, "utmSource"))
                writer.uint32(/* id 40, wireType 2 =*/322).string(message.utmSource);
            if (message.utmTerm != null && Object.hasOwnProperty.call(message, "utmTerm"))
                writer.uint32(/* id 41, wireType 2 =*/330).string(message.utmTerm);
            if (message.contract != null && Object.hasOwnProperty.call(message, "contract"))
                $root.PlatformAdminApi.CRMContract.encode(message.contract, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 43, wireType 2 =*/346).string(message.taskId);
            if (message.person != null && Object.hasOwnProperty.call(message, "person"))
                $root.PlatformAdminApi.Person.encode(message.person, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 45, wireType 0 =*/360).int32(message.discount);
            if (message.creationMean != null && Object.hasOwnProperty.call(message, "creationMean"))
                writer.uint32(/* id 46, wireType 2 =*/370).string(message.creationMean);
            if (message.communicationChannel != null && Object.hasOwnProperty.call(message, "communicationChannel"))
                writer.uint32(/* id 47, wireType 2 =*/378).string(message.communicationChannel);
            if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                writer.uint32(/* id 48, wireType 2 =*/386).string(message.externalId);
            if (message.segment != null && Object.hasOwnProperty.call(message, "segment"))
                writer.uint32(/* id 49, wireType 2 =*/394).string(message.segment);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 50, wireType 2 =*/402).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 51, wireType 2 =*/410).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 52, wireType 2 =*/418).string(message.deletedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 53, wireType 0 =*/424).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a Prospect message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Prospect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Prospect} Prospect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Prospect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Prospect();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.prospectNumber = reader.int32();
                    break;
                case 3:
                    message.publicId = reader.string();
                    break;
                case 4:
                    message.isShortForm = reader.bool();
                    break;
                case 5:
                    message.phone = reader.string();
                    break;
                case 6:
                    message.fio = $root.PlatformAdminApi.Fio.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.email = reader.string();
                    break;
                case 8:
                    message.price = $root.PlatformAdminApi.CalculateResponse.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.status = reader.string();
                    break;
                case 10:
                    message.cancelReason = reader.string();
                    break;
                case 11:
                    message.platform = reader.string();
                    break;
                case 12:
                    message.startDate = reader.string();
                    break;
                case 13:
                    message.orderId = reader.string();
                    break;
                case 14:
                    message.isManualProcessing = reader.bool();
                    break;
                case 15:
                    message.address = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.qleanAddress = reader.string();
                    break;
                case 17:
                    message.regionId = reader.int32();
                    break;
                case 18:
                    message.keysDelivery = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.keysPickup = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.promocode = reader.string();
                    break;
                case 21:
                    message.regularity = reader.string();
                    break;
                case 22:
                    message.isOvertime = reader.bool();
                    break;
                case 23:
                    message.strategy = reader.string();
                    break;
                case 24:
                    message.serviceType = reader.string();
                    break;
                case 25:
                    message.paymentType = reader.string();
                    break;
                case 26:
                    message.totalPrice = reader.int32();
                    break;
                case 27:
                    message.totalDuration = reader.int32();
                    break;
                case 28:
                    message.commentForExecutor = reader.string();
                    break;
                case 29:
                    message.commentForSupport = reader.string();
                    break;
                case 30:
                    message.qleanClientId = reader.string();
                    break;
                case 31:
                    message.qleanOrderId = reader.string();
                    break;
                case 32:
                    message.qleanPromocodeId = reader.string();
                    break;
                case 33:
                    message.qleanLeadId = reader.string();
                    break;
                case 34:
                    message.qleanWebOrderId = reader.string();
                    break;
                case 35:
                    message.qleanPreferredCleanerId = reader.string();
                    break;
                case 36:
                    message.utmCampaign = reader.string();
                    break;
                case 37:
                    message.utmContent = reader.string();
                    break;
                case 38:
                    message.utmData = reader.string();
                    break;
                case 39:
                    message.utmMedium = reader.string();
                    break;
                case 40:
                    message.utmSource = reader.string();
                    break;
                case 41:
                    message.utmTerm = reader.string();
                    break;
                case 42:
                    message.contract = $root.PlatformAdminApi.CRMContract.decode(reader, reader.uint32());
                    break;
                case 43:
                    message.taskId = reader.string();
                    break;
                case 44:
                    message.person = $root.PlatformAdminApi.Person.decode(reader, reader.uint32());
                    break;
                case 45:
                    message.discount = reader.int32();
                    break;
                case 46:
                    message.creationMean = reader.string();
                    break;
                case 47:
                    message.communicationChannel = reader.string();
                    break;
                case 48:
                    message.externalId = reader.string();
                    break;
                case 49:
                    message.segment = reader.string();
                    break;
                case 50:
                    message.createdAt = reader.string();
                    break;
                case 51:
                    message.updatedAt = reader.string();
                    break;
                case 52:
                    message.deletedAt = reader.string();
                    break;
                case 53:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Prospect;
    })();

    PlatformAdminApi.CalculateResponse = (function() {

        /**
         * Properties of a CalculateResponse.
         * @memberof PlatformAdminApi
         * @interface ICalculateResponse
         * @property {number|null} [regionId] CalculateResponse regionId
         * @property {Array.<PlatformAdminApi.ICalculateProductResponse>|null} [products] CalculateResponse products
         * @property {number|null} [totalPrice] CalculateResponse totalPrice
         * @property {number|null} [totalDuration] CalculateResponse totalDuration
         * @property {number|null} [subTotalPrice] CalculateResponse subTotalPrice
         * @property {string|null} [relevantUntil] CalculateResponse relevantUntil
         * @property {string|null} [priceListTitle] CalculateResponse priceListTitle
         * @property {string|null} [priceListId] CalculateResponse priceListId
         * @property {number|null} [bonusesUsed] CalculateResponse bonusesUsed
         */

        /**
         * Constructs a new CalculateResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CalculateResponse.
         * @implements ICalculateResponse
         * @constructor
         * @param {PlatformAdminApi.ICalculateResponse=} [properties] Properties to set
         */
        function CalculateResponse(properties) {
            this.products = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculateResponse regionId.
         * @member {number} regionId
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.regionId = 0;

        /**
         * CalculateResponse products.
         * @member {Array.<PlatformAdminApi.ICalculateProductResponse>} products
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.products = $util.emptyArray;

        /**
         * CalculateResponse totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.totalPrice = 0;

        /**
         * CalculateResponse totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.totalDuration = 0;

        /**
         * CalculateResponse subTotalPrice.
         * @member {number} subTotalPrice
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.subTotalPrice = 0;

        /**
         * CalculateResponse relevantUntil.
         * @member {string} relevantUntil
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.relevantUntil = "";

        /**
         * CalculateResponse priceListTitle.
         * @member {string} priceListTitle
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.priceListTitle = "";

        /**
         * CalculateResponse priceListId.
         * @member {string} priceListId
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.priceListId = "";

        /**
         * CalculateResponse bonusesUsed.
         * @member {number} bonusesUsed
         * @memberof PlatformAdminApi.CalculateResponse
         * @instance
         */
        CalculateResponse.prototype.bonusesUsed = 0;

        /**
         * Encodes the specified CalculateResponse message. Does not implicitly {@link PlatformAdminApi.CalculateResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CalculateResponse
         * @static
         * @param {PlatformAdminApi.ICalculateResponse} message CalculateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.regionId);
            if (message.products != null && message.products.length)
                for (var i = 0; i < message.products.length; ++i)
                    $root.PlatformAdminApi.CalculateProductResponse.encode(message.products[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalDuration);
            if (message.subTotalPrice != null && Object.hasOwnProperty.call(message, "subTotalPrice"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.subTotalPrice);
            if (message.relevantUntil != null && Object.hasOwnProperty.call(message, "relevantUntil"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.relevantUntil);
            if (message.priceListTitle != null && Object.hasOwnProperty.call(message, "priceListTitle"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.priceListTitle);
            if (message.priceListId != null && Object.hasOwnProperty.call(message, "priceListId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.priceListId);
            if (message.bonusesUsed != null && Object.hasOwnProperty.call(message, "bonusesUsed"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.bonusesUsed);
            return writer;
        };

        /**
         * Decodes a CalculateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CalculateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CalculateResponse} CalculateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CalculateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.regionId = reader.int32();
                    break;
                case 2:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.PlatformAdminApi.CalculateProductResponse.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.totalPrice = reader.int32();
                    break;
                case 4:
                    message.totalDuration = reader.int32();
                    break;
                case 5:
                    message.subTotalPrice = reader.int32();
                    break;
                case 6:
                    message.relevantUntil = reader.string();
                    break;
                case 7:
                    message.priceListTitle = reader.string();
                    break;
                case 8:
                    message.priceListId = reader.string();
                    break;
                case 9:
                    message.bonusesUsed = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculateResponse;
    })();

    PlatformAdminApi.CalculateProductResponse = (function() {

        /**
         * Properties of a CalculateProductResponse.
         * @memberof PlatformAdminApi
         * @interface ICalculateProductResponse
         * @property {string|null} [productSlug] CalculateProductResponse productSlug
         * @property {number|null} [totalPrice] CalculateProductResponse totalPrice
         * @property {number|null} [totalDuration] CalculateProductResponse totalDuration
         * @property {Array.<PlatformAdminApi.ICalculateProductItem>|null} [options] CalculateProductResponse options
         * @property {number|null} [baseCost] CalculateProductResponse baseCost
         * @property {number|null} [baseDuration] CalculateProductResponse baseDuration
         */

        /**
         * Constructs a new CalculateProductResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CalculateProductResponse.
         * @implements ICalculateProductResponse
         * @constructor
         * @param {PlatformAdminApi.ICalculateProductResponse=} [properties] Properties to set
         */
        function CalculateProductResponse(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculateProductResponse productSlug.
         * @member {string} productSlug
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @instance
         */
        CalculateProductResponse.prototype.productSlug = "";

        /**
         * CalculateProductResponse totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @instance
         */
        CalculateProductResponse.prototype.totalPrice = 0;

        /**
         * CalculateProductResponse totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @instance
         */
        CalculateProductResponse.prototype.totalDuration = 0;

        /**
         * CalculateProductResponse options.
         * @member {Array.<PlatformAdminApi.ICalculateProductItem>} options
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @instance
         */
        CalculateProductResponse.prototype.options = $util.emptyArray;

        /**
         * CalculateProductResponse baseCost.
         * @member {number} baseCost
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @instance
         */
        CalculateProductResponse.prototype.baseCost = 0;

        /**
         * CalculateProductResponse baseDuration.
         * @member {number} baseDuration
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @instance
         */
        CalculateProductResponse.prototype.baseDuration = 0;

        /**
         * Encodes the specified CalculateProductResponse message. Does not implicitly {@link PlatformAdminApi.CalculateProductResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @static
         * @param {PlatformAdminApi.ICalculateProductResponse} message CalculateProductResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculateProductResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalDuration);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.CalculateProductItem.encode(message.options[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.baseCost != null && Object.hasOwnProperty.call(message, "baseCost"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.baseCost);
            if (message.baseDuration != null && Object.hasOwnProperty.call(message, "baseDuration"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.baseDuration);
            return writer;
        };

        /**
         * Decodes a CalculateProductResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CalculateProductResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CalculateProductResponse} CalculateProductResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculateProductResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CalculateProductResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                case 2:
                    message.totalPrice = reader.int32();
                    break;
                case 3:
                    message.totalDuration = reader.int32();
                    break;
                case 4:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.CalculateProductItem.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.baseCost = reader.int32();
                    break;
                case 6:
                    message.baseDuration = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculateProductResponse;
    })();

    PlatformAdminApi.CalculateProductItem = (function() {

        /**
         * Properties of a CalculateProductItem.
         * @memberof PlatformAdminApi
         * @interface ICalculateProductItem
         * @property {string|null} [slug] CalculateProductItem slug
         * @property {number|null} [value] CalculateProductItem value
         * @property {number|null} [totalPrice] CalculateProductItem totalPrice
         * @property {number|null} [totalDuration] CalculateProductItem totalDuration
         * @property {PlatformAdminApi.PriceElementOptionType|null} [type] CalculateProductItem type
         * @property {string|null} [title] CalculateProductItem title
         * @property {boolean|null} [isFree] CalculateProductItem isFree
         * @property {number|null} [factor] CalculateProductItem factor
         * @property {number|null} [defaultFactor] CalculateProductItem defaultFactor
         */

        /**
         * Constructs a new CalculateProductItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CalculateProductItem.
         * @implements ICalculateProductItem
         * @constructor
         * @param {PlatformAdminApi.ICalculateProductItem=} [properties] Properties to set
         */
        function CalculateProductItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculateProductItem slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.slug = "";

        /**
         * CalculateProductItem value.
         * @member {number} value
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.value = 0;

        /**
         * CalculateProductItem totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.totalPrice = 0;

        /**
         * CalculateProductItem totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.totalDuration = 0;

        /**
         * CalculateProductItem type.
         * @member {PlatformAdminApi.PriceElementOptionType} type
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.type = 0;

        /**
         * CalculateProductItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.title = "";

        /**
         * CalculateProductItem isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.isFree = false;

        /**
         * CalculateProductItem factor.
         * @member {number} factor
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.factor = 0;

        /**
         * CalculateProductItem defaultFactor.
         * @member {number} defaultFactor
         * @memberof PlatformAdminApi.CalculateProductItem
         * @instance
         */
        CalculateProductItem.prototype.defaultFactor = 0;

        /**
         * Encodes the specified CalculateProductItem message. Does not implicitly {@link PlatformAdminApi.CalculateProductItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CalculateProductItem
         * @static
         * @param {PlatformAdminApi.ICalculateProductItem} message CalculateProductItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculateProductItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slug);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalDuration);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.title);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isFree);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 8, wireType 5 =*/69).float(message.factor);
            if (message.defaultFactor != null && Object.hasOwnProperty.call(message, "defaultFactor"))
                writer.uint32(/* id 9, wireType 5 =*/77).float(message.defaultFactor);
            return writer;
        };

        /**
         * Decodes a CalculateProductItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CalculateProductItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CalculateProductItem} CalculateProductItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculateProductItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CalculateProductItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slug = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.totalPrice = reader.int32();
                    break;
                case 4:
                    message.totalDuration = reader.int32();
                    break;
                case 5:
                    message.type = reader.int32();
                    break;
                case 6:
                    message.title = reader.string();
                    break;
                case 7:
                    message.isFree = reader.bool();
                    break;
                case 8:
                    message.factor = reader.float();
                    break;
                case 9:
                    message.defaultFactor = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculateProductItem;
    })();

    /**
     * PriceElementOptionType enum.
     * @name PlatformAdminApi.PriceElementOptionType
     * @enum {number}
     * @property {number} UNDEF=0 UNDEF value
     * @property {number} BASE=1 BASE value
     * @property {number} OPTION=2 OPTION value
     * @property {number} CROSS_SALE=3 CROSS_SALE value
     */
    PlatformAdminApi.PriceElementOptionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEF"] = 0;
        values[valuesById[1] = "BASE"] = 1;
        values[valuesById[2] = "OPTION"] = 2;
        values[valuesById[3] = "CROSS_SALE"] = 3;
        return values;
    })();

    PlatformAdminApi.CRMContract = (function() {

        /**
         * Properties of a CRMContract.
         * @memberof PlatformAdminApi
         * @interface ICRMContract
         * @property {string|null} [id] CRMContract id
         * @property {number|null} [contractNumber] CRMContract contractNumber
         * @property {string|null} [status] CRMContract status
         * @property {number|null} [type] CRMContract type
         * @property {boolean|null} [isFirstTime] CRMContract isFirstTime
         * @property {number|null} [paymentsOrder] CRMContract paymentsOrder
         * @property {string|null} [regularity] CRMContract regularity
         */

        /**
         * Constructs a new CRMContract.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CRMContract.
         * @implements ICRMContract
         * @constructor
         * @param {PlatformAdminApi.ICRMContract=} [properties] Properties to set
         */
        function CRMContract(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CRMContract id.
         * @member {string} id
         * @memberof PlatformAdminApi.CRMContract
         * @instance
         */
        CRMContract.prototype.id = "";

        /**
         * CRMContract contractNumber.
         * @member {number} contractNumber
         * @memberof PlatformAdminApi.CRMContract
         * @instance
         */
        CRMContract.prototype.contractNumber = 0;

        /**
         * CRMContract status.
         * @member {string} status
         * @memberof PlatformAdminApi.CRMContract
         * @instance
         */
        CRMContract.prototype.status = "";

        /**
         * CRMContract type.
         * @member {number} type
         * @memberof PlatformAdminApi.CRMContract
         * @instance
         */
        CRMContract.prototype.type = 0;

        /**
         * CRMContract isFirstTime.
         * @member {boolean} isFirstTime
         * @memberof PlatformAdminApi.CRMContract
         * @instance
         */
        CRMContract.prototype.isFirstTime = false;

        /**
         * CRMContract paymentsOrder.
         * @member {number} paymentsOrder
         * @memberof PlatformAdminApi.CRMContract
         * @instance
         */
        CRMContract.prototype.paymentsOrder = 0;

        /**
         * CRMContract regularity.
         * @member {string} regularity
         * @memberof PlatformAdminApi.CRMContract
         * @instance
         */
        CRMContract.prototype.regularity = "";

        /**
         * Encodes the specified CRMContract message. Does not implicitly {@link PlatformAdminApi.CRMContract.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CRMContract
         * @static
         * @param {PlatformAdminApi.ICRMContract} message CRMContract message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CRMContract.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.contractNumber != null && Object.hasOwnProperty.call(message, "contractNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.contractNumber);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
            if (message.isFirstTime != null && Object.hasOwnProperty.call(message, "isFirstTime"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isFirstTime);
            if (message.paymentsOrder != null && Object.hasOwnProperty.call(message, "paymentsOrder"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.paymentsOrder);
            if (message.regularity != null && Object.hasOwnProperty.call(message, "regularity"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.regularity);
            return writer;
        };

        /**
         * Decodes a CRMContract message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CRMContract
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CRMContract} CRMContract
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CRMContract.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CRMContract();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.contractNumber = reader.int32();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                case 5:
                    message.isFirstTime = reader.bool();
                    break;
                case 6:
                    message.paymentsOrder = reader.int32();
                    break;
                case 7:
                    message.regularity = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CRMContract;
    })();

    PlatformAdminApi.ChangeProspectStatusRequest = (function() {

        /**
         * Properties of a ChangeProspectStatusRequest.
         * @memberof PlatformAdminApi
         * @interface IChangeProspectStatusRequest
         * @property {string|null} [id] ChangeProspectStatusRequest id
         * @property {string|null} [status] ChangeProspectStatusRequest status
         * @property {string|null} [cancelReason] ChangeProspectStatusRequest cancelReason
         */

        /**
         * Constructs a new ChangeProspectStatusRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ChangeProspectStatusRequest.
         * @implements IChangeProspectStatusRequest
         * @constructor
         * @param {PlatformAdminApi.IChangeProspectStatusRequest=} [properties] Properties to set
         */
        function ChangeProspectStatusRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeProspectStatusRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ChangeProspectStatusRequest
         * @instance
         */
        ChangeProspectStatusRequest.prototype.id = "";

        /**
         * ChangeProspectStatusRequest status.
         * @member {string} status
         * @memberof PlatformAdminApi.ChangeProspectStatusRequest
         * @instance
         */
        ChangeProspectStatusRequest.prototype.status = "";

        /**
         * ChangeProspectStatusRequest cancelReason.
         * @member {string} cancelReason
         * @memberof PlatformAdminApi.ChangeProspectStatusRequest
         * @instance
         */
        ChangeProspectStatusRequest.prototype.cancelReason = "";

        /**
         * Encodes the specified ChangeProspectStatusRequest message. Does not implicitly {@link PlatformAdminApi.ChangeProspectStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ChangeProspectStatusRequest
         * @static
         * @param {PlatformAdminApi.IChangeProspectStatusRequest} message ChangeProspectStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeProspectStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cancelReason);
            return writer;
        };

        /**
         * Decodes a ChangeProspectStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ChangeProspectStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ChangeProspectStatusRequest} ChangeProspectStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeProspectStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChangeProspectStatusRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                case 3:
                    message.cancelReason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ChangeProspectStatusRequest;
    })();

    PlatformAdminApi.ProspectPublicId = (function() {

        /**
         * Properties of a ProspectPublicId.
         * @memberof PlatformAdminApi
         * @interface IProspectPublicId
         * @property {string|null} [publicId] ProspectPublicId publicId
         */

        /**
         * Constructs a new ProspectPublicId.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ProspectPublicId.
         * @implements IProspectPublicId
         * @constructor
         * @param {PlatformAdminApi.IProspectPublicId=} [properties] Properties to set
         */
        function ProspectPublicId(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProspectPublicId publicId.
         * @member {string} publicId
         * @memberof PlatformAdminApi.ProspectPublicId
         * @instance
         */
        ProspectPublicId.prototype.publicId = "";

        /**
         * Encodes the specified ProspectPublicId message. Does not implicitly {@link PlatformAdminApi.ProspectPublicId.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ProspectPublicId
         * @static
         * @param {PlatformAdminApi.IProspectPublicId} message ProspectPublicId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProspectPublicId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.publicId != null && Object.hasOwnProperty.call(message, "publicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.publicId);
            return writer;
        };

        /**
         * Decodes a ProspectPublicId message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ProspectPublicId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ProspectPublicId} ProspectPublicId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProspectPublicId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ProspectPublicId();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.publicId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProspectPublicId;
    })();

    PlatformAdminApi.LoyaltyBonusService = (function() {

        /**
         * Constructs a new LoyaltyBonusService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a LoyaltyBonusService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function LoyaltyBonusService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (LoyaltyBonusService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LoyaltyBonusService;

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#getUserOperations}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef GetUserOperationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GetLoyaltyBonusOperationsResponse} [response] GetLoyaltyBonusOperationsResponse
         */

        /**
         * Calls GetUserOperations.
         * @function getUserOperations
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IGetUserOperationsRequest} request GetUserOperationsRequest message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.GetUserOperationsCallback} callback Node-style callback called with the error, if any, and GetLoyaltyBonusOperationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.getUserOperations = function getUserOperations(request, callback) {
            return this.rpcCall(getUserOperations, $root.PlatformAdminApi.GetUserOperationsRequest, $root.PlatformAdminApi.GetLoyaltyBonusOperationsResponse, request, callback);
        }, "name", { value: "GetUserOperations" });

        /**
         * Calls GetUserOperations.
         * @function getUserOperations
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IGetUserOperationsRequest} request GetUserOperationsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.GetLoyaltyBonusOperationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#searchOperations}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef SearchOperationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OperationsListResponse} [response] OperationsListResponse
         */

        /**
         * Calls SearchOperations.
         * @function searchOperations
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.ISearchOperationsRequest} request SearchOperationsRequest message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.SearchOperationsCallback} callback Node-style callback called with the error, if any, and OperationsListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.searchOperations = function searchOperations(request, callback) {
            return this.rpcCall(searchOperations, $root.PlatformAdminApi.SearchOperationsRequest, $root.PlatformAdminApi.OperationsListResponse, request, callback);
        }, "name", { value: "SearchOperations" });

        /**
         * Calls SearchOperations.
         * @function searchOperations
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.ISearchOperationsRequest} request SearchOperationsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OperationsListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#findAccount}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef FindAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GetAccountResponse} [response] GetAccountResponse
         */

        /**
         * Calls FindAccount.
         * @function findAccount
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IGetAccountRequest} request GetAccountRequest message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.FindAccountCallback} callback Node-style callback called with the error, if any, and GetAccountResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.findAccount = function findAccount(request, callback) {
            return this.rpcCall(findAccount, $root.PlatformAdminApi.GetAccountRequest, $root.PlatformAdminApi.GetAccountResponse, request, callback);
        }, "name", { value: "FindAccount" });

        /**
         * Calls FindAccount.
         * @function findAccount
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IGetAccountRequest} request GetAccountRequest message or plain object
         * @returns {Promise<PlatformAdminApi.GetAccountResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#findOperationById}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef FindOperationByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FindOperationByIdResponse} [response] FindOperationByIdResponse
         */

        /**
         * Calls FindOperationById.
         * @function findOperationById
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IFindOperationByIdRequest} request FindOperationByIdRequest message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.FindOperationByIdCallback} callback Node-style callback called with the error, if any, and FindOperationByIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.findOperationById = function findOperationById(request, callback) {
            return this.rpcCall(findOperationById, $root.PlatformAdminApi.FindOperationByIdRequest, $root.PlatformAdminApi.FindOperationByIdResponse, request, callback);
        }, "name", { value: "FindOperationById" });

        /**
         * Calls FindOperationById.
         * @function findOperationById
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IFindOperationByIdRequest} request FindOperationByIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FindOperationByIdResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#accrue}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef AccrueCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CreateOperationResponse} [response] CreateOperationResponse
         */

        /**
         * Calls Accrue.
         * @function accrue
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.ICreateOperationRequest} request CreateOperationRequest message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.AccrueCallback} callback Node-style callback called with the error, if any, and CreateOperationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.accrue = function accrue(request, callback) {
            return this.rpcCall(accrue, $root.PlatformAdminApi.CreateOperationRequest, $root.PlatformAdminApi.CreateOperationResponse, request, callback);
        }, "name", { value: "Accrue" });

        /**
         * Calls Accrue.
         * @function accrue
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.ICreateOperationRequest} request CreateOperationRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CreateOperationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#chargeOff}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef ChargeOffCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CreateOperationResponse} [response] CreateOperationResponse
         */

        /**
         * Calls ChargeOff.
         * @function chargeOff
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.ICreateOperationRequest} request CreateOperationRequest message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.ChargeOffCallback} callback Node-style callback called with the error, if any, and CreateOperationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.chargeOff = function chargeOff(request, callback) {
            return this.rpcCall(chargeOff, $root.PlatformAdminApi.CreateOperationRequest, $root.PlatformAdminApi.CreateOperationResponse, request, callback);
        }, "name", { value: "ChargeOff" });

        /**
         * Calls ChargeOff.
         * @function chargeOff
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.ICreateOperationRequest} request CreateOperationRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CreateOperationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#getBonusAccountInfo}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef GetBonusAccountInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GetBonusAccountInfoResponse} [response] GetBonusAccountInfoResponse
         */

        /**
         * Calls GetBonusAccountInfo.
         * @function getBonusAccountInfo
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IGetBonusAccountInfoRequest} request GetBonusAccountInfoRequest message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.GetBonusAccountInfoCallback} callback Node-style callback called with the error, if any, and GetBonusAccountInfoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.getBonusAccountInfo = function getBonusAccountInfo(request, callback) {
            return this.rpcCall(getBonusAccountInfo, $root.PlatformAdminApi.GetBonusAccountInfoRequest, $root.PlatformAdminApi.GetBonusAccountInfoResponse, request, callback);
        }, "name", { value: "GetBonusAccountInfo" });

        /**
         * Calls GetBonusAccountInfo.
         * @function getBonusAccountInfo
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IGetBonusAccountInfoRequest} request GetBonusAccountInfoRequest message or plain object
         * @returns {Promise<PlatformAdminApi.GetBonusAccountInfoResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.LoyaltyBonusService#getAvailableOperationReasons}.
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @typedef GetAvailableOperationReasonsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GetAvailableOperationReasonsResponse} [response] GetAvailableOperationReasonsResponse
         */

        /**
         * Получить список причин, доступных при изменении БС в ручную.
         * @function getAvailableOperationReasons
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IEmpty} request Empty message or plain object
         * @param {PlatformAdminApi.LoyaltyBonusService.GetAvailableOperationReasonsCallback} callback Node-style callback called with the error, if any, and GetAvailableOperationReasonsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoyaltyBonusService.prototype.getAvailableOperationReasons = function getAvailableOperationReasons(request, callback) {
            return this.rpcCall(getAvailableOperationReasons, $root.PlatformAdminApi.Empty, $root.PlatformAdminApi.GetAvailableOperationReasonsResponse, request, callback);
        }, "name", { value: "GetAvailableOperationReasons" });

        /**
         * Получить список причин, доступных при изменении БС в ручную.
         * @function getAvailableOperationReasons
         * @memberof PlatformAdminApi.LoyaltyBonusService
         * @instance
         * @param {PlatformAdminApi.IEmpty} request Empty message or plain object
         * @returns {Promise<PlatformAdminApi.GetAvailableOperationReasonsResponse>} Promise
         * @variation 2
         */

        return LoyaltyBonusService;
    })();

    PlatformAdminApi.LoyaltyBonusOperation = (function() {

        /**
         * Properties of a LoyaltyBonusOperation.
         * @memberof PlatformAdminApi
         * @interface ILoyaltyBonusOperation
         * @property {string|null} [id] LoyaltyBonusOperation id
         * @property {string|null} [createdAt] LoyaltyBonusOperation createdAt
         * @property {PlatformAdminApi.LoyaltyBonusOperation.Type|null} [type] LoyaltyBonusOperation type
         * @property {string|null} [typeText] LoyaltyBonusOperation typeText
         * @property {PlatformAdminApi.LoyaltyBonusOperation.Reason|null} [reason] LoyaltyBonusOperation reason
         * @property {string|null} [reasonText] LoyaltyBonusOperation reasonText
         * @property {number|null} [amount] LoyaltyBonusOperation amount
         * @property {PlatformAdminApi.IPersonReference|null} [cleaner] LoyaltyBonusOperation cleaner
         * @property {PlatformAdminApi.IPersonReference|null} [initiator] LoyaltyBonusOperation initiator
         * @property {PlatformAdminApi.IOperationRelatedEntity|null} [relatedEntity] LoyaltyBonusOperation relatedEntity
         * @property {string|null} [comment] LoyaltyBonusOperation comment
         */

        /**
         * Constructs a new LoyaltyBonusOperation.
         * @memberof PlatformAdminApi
         * @classdesc Represents a LoyaltyBonusOperation.
         * @implements ILoyaltyBonusOperation
         * @constructor
         * @param {PlatformAdminApi.ILoyaltyBonusOperation=} [properties] Properties to set
         */
        function LoyaltyBonusOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoyaltyBonusOperation id.
         * @member {string} id
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.id = "";

        /**
         * LoyaltyBonusOperation createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.createdAt = "";

        /**
         * LoyaltyBonusOperation type.
         * @member {PlatformAdminApi.LoyaltyBonusOperation.Type} type
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.type = 0;

        /**
         * LoyaltyBonusOperation typeText.
         * @member {string} typeText
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.typeText = "";

        /**
         * LoyaltyBonusOperation reason.
         * @member {PlatformAdminApi.LoyaltyBonusOperation.Reason} reason
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.reason = 0;

        /**
         * LoyaltyBonusOperation reasonText.
         * @member {string} reasonText
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.reasonText = "";

        /**
         * LoyaltyBonusOperation amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.amount = 0;

        /**
         * LoyaltyBonusOperation cleaner.
         * @member {PlatformAdminApi.IPersonReference|null|undefined} cleaner
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.cleaner = null;

        /**
         * LoyaltyBonusOperation initiator.
         * @member {PlatformAdminApi.IPersonReference|null|undefined} initiator
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.initiator = null;

        /**
         * LoyaltyBonusOperation relatedEntity.
         * @member {PlatformAdminApi.IOperationRelatedEntity|null|undefined} relatedEntity
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.relatedEntity = null;

        /**
         * LoyaltyBonusOperation comment.
         * @member {string} comment
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @instance
         */
        LoyaltyBonusOperation.prototype.comment = "";

        /**
         * Encodes the specified LoyaltyBonusOperation message. Does not implicitly {@link PlatformAdminApi.LoyaltyBonusOperation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @static
         * @param {PlatformAdminApi.ILoyaltyBonusOperation} message LoyaltyBonusOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoyaltyBonusOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.typeText != null && Object.hasOwnProperty.call(message, "typeText"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.typeText);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.reason);
            if (message.reasonText != null && Object.hasOwnProperty.call(message, "reasonText"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.reasonText);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.amount);
            if (message.cleaner != null && Object.hasOwnProperty.call(message, "cleaner"))
                $root.PlatformAdminApi.PersonReference.encode(message.cleaner, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.initiator != null && Object.hasOwnProperty.call(message, "initiator"))
                $root.PlatformAdminApi.PersonReference.encode(message.initiator, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.relatedEntity != null && Object.hasOwnProperty.call(message, "relatedEntity"))
                $root.PlatformAdminApi.OperationRelatedEntity.encode(message.relatedEntity, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.comment);
            return writer;
        };

        /**
         * Decodes a LoyaltyBonusOperation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.LoyaltyBonusOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.LoyaltyBonusOperation} LoyaltyBonusOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoyaltyBonusOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.LoyaltyBonusOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.typeText = reader.string();
                    break;
                case 5:
                    message.reason = reader.int32();
                    break;
                case 6:
                    message.reasonText = reader.string();
                    break;
                case 7:
                    message.amount = reader.int32();
                    break;
                case 8:
                    message.cleaner = $root.PlatformAdminApi.PersonReference.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.initiator = $root.PlatformAdminApi.PersonReference.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.relatedEntity = $root.PlatformAdminApi.OperationRelatedEntity.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Reason enum.
         * @name PlatformAdminApi.LoyaltyBonusOperation.Reason
         * @enum {number}
         * @property {number} Cashback=0 Cashback value
         * @property {number} ChangedBySupport=1 ChangedBySupport value
         * @property {number} Marketing=2 Marketing value
         * @property {number} Order=3 Order value
         * @property {number} OrderProblemTicket=4 OrderProblemTicket value
         * @property {number} Referral=5 Referral value
         * @property {number} WrongAccrual=6 WrongAccrual value
         * @property {number} Loyalty=7 Loyalty value
         * @property {number} Feedback=8 Feedback value
         * @property {number} ArrivingEarly=9 ArrivingEarly value
         * @property {number} DidNotFindContractor=10 DidNotFindContractor value
         * @property {number} DidNotCome=11 DidNotCome value
         * @property {number} Annual=12 Annual value
         * @property {number} AnotherProduct=13 AnotherProduct value
         */
        LoyaltyBonusOperation.Reason = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Cashback"] = 0;
            values[valuesById[1] = "ChangedBySupport"] = 1;
            values[valuesById[2] = "Marketing"] = 2;
            values[valuesById[3] = "Order"] = 3;
            values[valuesById[4] = "OrderProblemTicket"] = 4;
            values[valuesById[5] = "Referral"] = 5;
            values[valuesById[6] = "WrongAccrual"] = 6;
            values[valuesById[7] = "Loyalty"] = 7;
            values[valuesById[8] = "Feedback"] = 8;
            values[valuesById[9] = "ArrivingEarly"] = 9;
            values[valuesById[10] = "DidNotFindContractor"] = 10;
            values[valuesById[11] = "DidNotCome"] = 11;
            values[valuesById[12] = "Annual"] = 12;
            values[valuesById[13] = "AnotherProduct"] = 13;
            return values;
        })();

        /**
         * Type enum.
         * @name PlatformAdminApi.LoyaltyBonusOperation.Type
         * @enum {number}
         * @property {number} Accrual=0 Accrual value
         * @property {number} ChargeOff=1 ChargeOff value
         * @property {number} Block=2 Block value
         */
        LoyaltyBonusOperation.Type = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Accrual"] = 0;
            values[valuesById[1] = "ChargeOff"] = 1;
            values[valuesById[2] = "Block"] = 2;
            return values;
        })();

        return LoyaltyBonusOperation;
    })();

    PlatformAdminApi.PersonReference = (function() {

        /**
         * Properties of a PersonReference.
         * @memberof PlatformAdminApi
         * @interface IPersonReference
         * @property {string|null} [id] PersonReference id
         * @property {string|null} [name] PersonReference name
         */

        /**
         * Constructs a new PersonReference.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PersonReference.
         * @implements IPersonReference
         * @constructor
         * @param {PlatformAdminApi.IPersonReference=} [properties] Properties to set
         */
        function PersonReference(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PersonReference id.
         * @member {string} id
         * @memberof PlatformAdminApi.PersonReference
         * @instance
         */
        PersonReference.prototype.id = "";

        /**
         * PersonReference name.
         * @member {string} name
         * @memberof PlatformAdminApi.PersonReference
         * @instance
         */
        PersonReference.prototype.name = "";

        /**
         * Encodes the specified PersonReference message. Does not implicitly {@link PlatformAdminApi.PersonReference.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PersonReference
         * @static
         * @param {PlatformAdminApi.IPersonReference} message PersonReference message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PersonReference.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a PersonReference message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PersonReference
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PersonReference} PersonReference
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PersonReference.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PersonReference();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PersonReference;
    })();

    PlatformAdminApi.OperationRelatedEntity = (function() {

        /**
         * Properties of an OperationRelatedEntity.
         * @memberof PlatformAdminApi
         * @interface IOperationRelatedEntity
         * @property {PlatformAdminApi.OperationRelatedEntity.Type|null} [type] OperationRelatedEntity type
         * @property {string|null} [id] OperationRelatedEntity id
         * @property {string|null} [subject] OperationRelatedEntity subject
         */

        /**
         * Constructs a new OperationRelatedEntity.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OperationRelatedEntity.
         * @implements IOperationRelatedEntity
         * @constructor
         * @param {PlatformAdminApi.IOperationRelatedEntity=} [properties] Properties to set
         */
        function OperationRelatedEntity(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OperationRelatedEntity type.
         * @member {PlatformAdminApi.OperationRelatedEntity.Type} type
         * @memberof PlatformAdminApi.OperationRelatedEntity
         * @instance
         */
        OperationRelatedEntity.prototype.type = 0;

        /**
         * OperationRelatedEntity id.
         * @member {string} id
         * @memberof PlatformAdminApi.OperationRelatedEntity
         * @instance
         */
        OperationRelatedEntity.prototype.id = "";

        /**
         * OperationRelatedEntity subject.
         * @member {string} subject
         * @memberof PlatformAdminApi.OperationRelatedEntity
         * @instance
         */
        OperationRelatedEntity.prototype.subject = "";

        /**
         * Encodes the specified OperationRelatedEntity message. Does not implicitly {@link PlatformAdminApi.OperationRelatedEntity.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OperationRelatedEntity
         * @static
         * @param {PlatformAdminApi.IOperationRelatedEntity} message OperationRelatedEntity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OperationRelatedEntity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            if (message.subject != null && Object.hasOwnProperty.call(message, "subject"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.subject);
            return writer;
        };

        /**
         * Decodes an OperationRelatedEntity message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OperationRelatedEntity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OperationRelatedEntity} OperationRelatedEntity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OperationRelatedEntity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OperationRelatedEntity();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.subject = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Type enum.
         * @name PlatformAdminApi.OperationRelatedEntity.Type
         * @enum {number}
         * @property {number} Order=0 Order value
         * @property {number} Ticket=1 Ticket value
         */
        OperationRelatedEntity.Type = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Order"] = 0;
            values[valuesById[1] = "Ticket"] = 1;
            return values;
        })();

        return OperationRelatedEntity;
    })();

    PlatformAdminApi.ReasonOption = (function() {

        /**
         * Properties of a ReasonOption.
         * @memberof PlatformAdminApi
         * @interface IReasonOption
         * @property {PlatformAdminApi.LoyaltyBonusOperation.Reason|null} [value] ReasonOption value
         * @property {string|null} [label] ReasonOption label
         * @property {PlatformAdminApi.ReasonOption.OperationType|null} [possibleOperationType] ReasonOption possibleOperationType
         * @property {PlatformAdminApi.OperationRelatedEntity.Type|null} [possibleEntityType] ReasonOption possibleEntityType
         */

        /**
         * Constructs a new ReasonOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ReasonOption.
         * @implements IReasonOption
         * @constructor
         * @param {PlatformAdminApi.IReasonOption=} [properties] Properties to set
         */
        function ReasonOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReasonOption value.
         * @member {PlatformAdminApi.LoyaltyBonusOperation.Reason} value
         * @memberof PlatformAdminApi.ReasonOption
         * @instance
         */
        ReasonOption.prototype.value = 0;

        /**
         * ReasonOption label.
         * @member {string} label
         * @memberof PlatformAdminApi.ReasonOption
         * @instance
         */
        ReasonOption.prototype.label = "";

        /**
         * ReasonOption possibleOperationType.
         * @member {PlatformAdminApi.ReasonOption.OperationType} possibleOperationType
         * @memberof PlatformAdminApi.ReasonOption
         * @instance
         */
        ReasonOption.prototype.possibleOperationType = 0;

        /**
         * ReasonOption possibleEntityType.
         * @member {PlatformAdminApi.OperationRelatedEntity.Type} possibleEntityType
         * @memberof PlatformAdminApi.ReasonOption
         * @instance
         */
        ReasonOption.prototype.possibleEntityType = 0;

        /**
         * Encodes the specified ReasonOption message. Does not implicitly {@link PlatformAdminApi.ReasonOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ReasonOption
         * @static
         * @param {PlatformAdminApi.IReasonOption} message ReasonOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReasonOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.possibleOperationType != null && Object.hasOwnProperty.call(message, "possibleOperationType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.possibleOperationType);
            if (message.possibleEntityType != null && Object.hasOwnProperty.call(message, "possibleEntityType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.possibleEntityType);
            return writer;
        };

        /**
         * Decodes a ReasonOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ReasonOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ReasonOption} ReasonOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReasonOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ReasonOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.int32();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.possibleOperationType = reader.int32();
                    break;
                case 4:
                    message.possibleEntityType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * OperationType enum.
         * @name PlatformAdminApi.ReasonOption.OperationType
         * @enum {number}
         * @property {number} Accrual=0 Accrual value
         * @property {number} ChargeOff=1 ChargeOff value
         * @property {number} Both=2 Both value
         */
        ReasonOption.OperationType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Accrual"] = 0;
            values[valuesById[1] = "ChargeOff"] = 1;
            values[valuesById[2] = "Both"] = 2;
            return values;
        })();

        return ReasonOption;
    })();

    PlatformAdminApi.LoyaltyBonusCommon = (function() {

        /**
         * Properties of a LoyaltyBonusCommon.
         * @memberof PlatformAdminApi
         * @interface ILoyaltyBonusCommon
         */

        /**
         * Constructs a new LoyaltyBonusCommon.
         * @memberof PlatformAdminApi
         * @classdesc Represents a LoyaltyBonusCommon.
         * @implements ILoyaltyBonusCommon
         * @constructor
         * @param {PlatformAdminApi.ILoyaltyBonusCommon=} [properties] Properties to set
         */
        function LoyaltyBonusCommon(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified LoyaltyBonusCommon message. Does not implicitly {@link PlatformAdminApi.LoyaltyBonusCommon.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.LoyaltyBonusCommon
         * @static
         * @param {PlatformAdminApi.ILoyaltyBonusCommon} message LoyaltyBonusCommon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoyaltyBonusCommon.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a LoyaltyBonusCommon message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.LoyaltyBonusCommon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.LoyaltyBonusCommon} LoyaltyBonusCommon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoyaltyBonusCommon.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.LoyaltyBonusCommon();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LoyaltyBonusCommon.Operation = (function() {

            /**
             * Properties of an Operation.
             * @memberof PlatformAdminApi.LoyaltyBonusCommon
             * @interface IOperation
             * @property {string|null} [id] Operation id
             * @property {number|null} [amountCents] Operation amountCents
             * @property {PlatformAdminApi.LoyaltyBonusCommon.OperationType|null} [operationType] Operation operationType
             * @property {string|null} [clientId] Operation clientId
             * @property {string|null} [tenantId] Operation tenantId
             * @property {string|null} [createdAt] Operation createdAt
             * @property {string|null} [userId] Operation userId
             */

            /**
             * Constructs a new Operation.
             * @memberof PlatformAdminApi.LoyaltyBonusCommon
             * @classdesc Represents an Operation.
             * @implements IOperation
             * @constructor
             * @param {PlatformAdminApi.LoyaltyBonusCommon.IOperation=} [properties] Properties to set
             */
            function Operation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Operation id.
             * @member {string} id
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @instance
             */
            Operation.prototype.id = "";

            /**
             * Operation amountCents.
             * @member {number} amountCents
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @instance
             */
            Operation.prototype.amountCents = 0;

            /**
             * Operation operationType.
             * @member {PlatformAdminApi.LoyaltyBonusCommon.OperationType} operationType
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @instance
             */
            Operation.prototype.operationType = 0;

            /**
             * Operation clientId.
             * @member {string} clientId
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @instance
             */
            Operation.prototype.clientId = "";

            /**
             * Operation tenantId.
             * @member {string} tenantId
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @instance
             */
            Operation.prototype.tenantId = "";

            /**
             * Operation createdAt.
             * @member {string} createdAt
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @instance
             */
            Operation.prototype.createdAt = "";

            /**
             * Operation userId.
             * @member {string} userId
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @instance
             */
            Operation.prototype.userId = "";

            /**
             * Encodes the specified Operation message. Does not implicitly {@link PlatformAdminApi.LoyaltyBonusCommon.Operation.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @static
             * @param {PlatformAdminApi.LoyaltyBonusCommon.IOperation} message Operation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Operation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.amountCents != null && Object.hasOwnProperty.call(message, "amountCents"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amountCents);
                if (message.operationType != null && Object.hasOwnProperty.call(message, "operationType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.operationType);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.clientId);
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.tenantId);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.userId);
                return writer;
            };

            /**
             * Decodes an Operation message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.Operation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.LoyaltyBonusCommon.Operation} Operation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Operation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.LoyaltyBonusCommon.Operation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.amountCents = reader.int32();
                        break;
                    case 3:
                        message.operationType = reader.int32();
                        break;
                    case 4:
                        message.clientId = reader.string();
                        break;
                    case 5:
                        message.tenantId = reader.string();
                        break;
                    case 6:
                        message.createdAt = reader.string();
                        break;
                    case 7:
                        message.userId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Operation;
        })();

        /**
         * OperationType enum.
         * @name PlatformAdminApi.LoyaltyBonusCommon.OperationType
         * @enum {number}
         * @property {number} CashbackOperation_ACCRUAL=0 CashbackOperation_ACCRUAL value
         * @property {number} CashbackOperation_CHARGE_OFF=1 CashbackOperation_CHARGE_OFF value
         * @property {number} CashbackOperation_BLOCK=2 CashbackOperation_BLOCK value
         * @property {number} CashbackOperation_UNBLOCK=3 CashbackOperation_UNBLOCK value
         */
        LoyaltyBonusCommon.OperationType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CashbackOperation_ACCRUAL"] = 0;
            values[valuesById[1] = "CashbackOperation_CHARGE_OFF"] = 1;
            values[valuesById[2] = "CashbackOperation_BLOCK"] = 2;
            values[valuesById[3] = "CashbackOperation_UNBLOCK"] = 3;
            return values;
        })();

        /**
         * AccountTicker enum.
         * @name PlatformAdminApi.LoyaltyBonusCommon.AccountTicker
         * @enum {number}
         * @property {number} BONUS=0 BONUS value
         * @property {number} MONEY=1 MONEY value
         */
        LoyaltyBonusCommon.AccountTicker = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BONUS"] = 0;
            values[valuesById[1] = "MONEY"] = 1;
            return values;
        })();

        LoyaltyBonusCommon.FullOperation = (function() {

            /**
             * Properties of a FullOperation.
             * @memberof PlatformAdminApi.LoyaltyBonusCommon
             * @interface IFullOperation
             * @property {string|null} [id] FullOperation id
             * @property {number|null} [amountCents] FullOperation amountCents
             * @property {PlatformAdminApi.LoyaltyBonusCommon.OperationType|null} [operationType] FullOperation operationType
             * @property {string|null} [clientId] FullOperation clientId
             * @property {string|null} [tenantId] FullOperation tenantId
             * @property {string|null} [createdAt] FullOperation createdAt
             * @property {string|null} [reason] FullOperation reason
             * @property {string|null} [extId] FullOperation extId
             * @property {string|null} [annualDate] FullOperation annualDate
             * @property {string|null} [userId] FullOperation userId
             */

            /**
             * Constructs a new FullOperation.
             * @memberof PlatformAdminApi.LoyaltyBonusCommon
             * @classdesc Represents a FullOperation.
             * @implements IFullOperation
             * @constructor
             * @param {PlatformAdminApi.LoyaltyBonusCommon.IFullOperation=} [properties] Properties to set
             */
            function FullOperation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FullOperation id.
             * @member {string} id
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.id = "";

            /**
             * FullOperation amountCents.
             * @member {number} amountCents
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.amountCents = 0;

            /**
             * FullOperation operationType.
             * @member {PlatformAdminApi.LoyaltyBonusCommon.OperationType} operationType
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.operationType = 0;

            /**
             * FullOperation clientId.
             * @member {string} clientId
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.clientId = "";

            /**
             * FullOperation tenantId.
             * @member {string} tenantId
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.tenantId = "";

            /**
             * FullOperation createdAt.
             * @member {string} createdAt
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.createdAt = "";

            /**
             * FullOperation reason.
             * @member {string} reason
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.reason = "";

            /**
             * FullOperation extId.
             * @member {string} extId
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.extId = "";

            /**
             * FullOperation annualDate.
             * @member {string} annualDate
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.annualDate = "";

            /**
             * FullOperation userId.
             * @member {string} userId
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @instance
             */
            FullOperation.prototype.userId = "";

            /**
             * Encodes the specified FullOperation message. Does not implicitly {@link PlatformAdminApi.LoyaltyBonusCommon.FullOperation.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @static
             * @param {PlatformAdminApi.LoyaltyBonusCommon.IFullOperation} message FullOperation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FullOperation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.amountCents != null && Object.hasOwnProperty.call(message, "amountCents"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amountCents);
                if (message.operationType != null && Object.hasOwnProperty.call(message, "operationType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.operationType);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.clientId);
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.tenantId);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
                if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.reason);
                if (message.extId != null && Object.hasOwnProperty.call(message, "extId"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.extId);
                if (message.annualDate != null && Object.hasOwnProperty.call(message, "annualDate"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.annualDate);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.userId);
                return writer;
            };

            /**
             * Decodes a FullOperation message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.LoyaltyBonusCommon.FullOperation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.LoyaltyBonusCommon.FullOperation} FullOperation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FullOperation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.LoyaltyBonusCommon.FullOperation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.amountCents = reader.int32();
                        break;
                    case 3:
                        message.operationType = reader.int32();
                        break;
                    case 4:
                        message.clientId = reader.string();
                        break;
                    case 5:
                        message.tenantId = reader.string();
                        break;
                    case 6:
                        message.createdAt = reader.string();
                        break;
                    case 7:
                        message.reason = reader.string();
                        break;
                    case 8:
                        message.extId = reader.string();
                        break;
                    case 9:
                        message.annualDate = reader.string();
                        break;
                    case 10:
                        message.userId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return FullOperation;
        })();

        return LoyaltyBonusCommon;
    })();

    PlatformAdminApi.GetUserOperationsRequest = (function() {

        /**
         * Properties of a GetUserOperationsRequest.
         * @memberof PlatformAdminApi
         * @interface IGetUserOperationsRequest
         * @property {number|null} [page] GetUserOperationsRequest page
         * @property {number|null} [limit] GetUserOperationsRequest limit
         * @property {string|null} [userId] GetUserOperationsRequest userId
         */

        /**
         * Constructs a new GetUserOperationsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetUserOperationsRequest.
         * @implements IGetUserOperationsRequest
         * @constructor
         * @param {PlatformAdminApi.IGetUserOperationsRequest=} [properties] Properties to set
         */
        function GetUserOperationsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserOperationsRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.GetUserOperationsRequest
         * @instance
         */
        GetUserOperationsRequest.prototype.page = 0;

        /**
         * GetUserOperationsRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.GetUserOperationsRequest
         * @instance
         */
        GetUserOperationsRequest.prototype.limit = 0;

        /**
         * GetUserOperationsRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.GetUserOperationsRequest
         * @instance
         */
        GetUserOperationsRequest.prototype.userId = "";

        /**
         * Encodes the specified GetUserOperationsRequest message. Does not implicitly {@link PlatformAdminApi.GetUserOperationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetUserOperationsRequest
         * @static
         * @param {PlatformAdminApi.IGetUserOperationsRequest} message GetUserOperationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserOperationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
            return writer;
        };

        /**
         * Decodes a GetUserOperationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetUserOperationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetUserOperationsRequest} GetUserOperationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserOperationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetUserOperationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetUserOperationsRequest;
    })();

    PlatformAdminApi.GetLoyaltyBonusOperationsResponse = (function() {

        /**
         * Properties of a GetLoyaltyBonusOperationsResponse.
         * @memberof PlatformAdminApi
         * @interface IGetLoyaltyBonusOperationsResponse
         * @property {Array.<PlatformAdminApi.ILoyaltyBonusOperation>|null} [data] GetLoyaltyBonusOperationsResponse data
         * @property {number|null} [page] GetLoyaltyBonusOperationsResponse page
         * @property {number|null} [pageCount] GetLoyaltyBonusOperationsResponse pageCount
         * @property {number|null} [limit] GetLoyaltyBonusOperationsResponse limit
         * @property {number|null} [count] GetLoyaltyBonusOperationsResponse count
         * @property {number|null} [total] GetLoyaltyBonusOperationsResponse total
         */

        /**
         * Constructs a new GetLoyaltyBonusOperationsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetLoyaltyBonusOperationsResponse.
         * @implements IGetLoyaltyBonusOperationsResponse
         * @constructor
         * @param {PlatformAdminApi.IGetLoyaltyBonusOperationsResponse=} [properties] Properties to set
         */
        function GetLoyaltyBonusOperationsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetLoyaltyBonusOperationsResponse data.
         * @member {Array.<PlatformAdminApi.ILoyaltyBonusOperation>} data
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @instance
         */
        GetLoyaltyBonusOperationsResponse.prototype.data = $util.emptyArray;

        /**
         * GetLoyaltyBonusOperationsResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @instance
         */
        GetLoyaltyBonusOperationsResponse.prototype.page = 0;

        /**
         * GetLoyaltyBonusOperationsResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @instance
         */
        GetLoyaltyBonusOperationsResponse.prototype.pageCount = 0;

        /**
         * GetLoyaltyBonusOperationsResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @instance
         */
        GetLoyaltyBonusOperationsResponse.prototype.limit = 0;

        /**
         * GetLoyaltyBonusOperationsResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @instance
         */
        GetLoyaltyBonusOperationsResponse.prototype.count = 0;

        /**
         * GetLoyaltyBonusOperationsResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @instance
         */
        GetLoyaltyBonusOperationsResponse.prototype.total = 0;

        /**
         * Encodes the specified GetLoyaltyBonusOperationsResponse message. Does not implicitly {@link PlatformAdminApi.GetLoyaltyBonusOperationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @static
         * @param {PlatformAdminApi.IGetLoyaltyBonusOperationsResponse} message GetLoyaltyBonusOperationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetLoyaltyBonusOperationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.LoyaltyBonusOperation.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a GetLoyaltyBonusOperationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetLoyaltyBonusOperationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetLoyaltyBonusOperationsResponse} GetLoyaltyBonusOperationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetLoyaltyBonusOperationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetLoyaltyBonusOperationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.LoyaltyBonusOperation.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetLoyaltyBonusOperationsResponse;
    })();

    PlatformAdminApi.SearchOperationsRequest = (function() {

        /**
         * Properties of a SearchOperationsRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchOperationsRequest
         * @property {number|null} [page] SearchOperationsRequest page
         * @property {number|null} [limit] SearchOperationsRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchOperationsRequest sort
         * @property {Array.<PlatformAdminApi.SearchOperationsRequest.IWhereFields>|null} [where] SearchOperationsRequest where
         * @property {string|null} [userId] SearchOperationsRequest userId
         */

        /**
         * Constructs a new SearchOperationsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchOperationsRequest.
         * @implements ISearchOperationsRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchOperationsRequest=} [properties] Properties to set
         */
        function SearchOperationsRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOperationsRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchOperationsRequest
         * @instance
         */
        SearchOperationsRequest.prototype.page = 0;

        /**
         * SearchOperationsRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchOperationsRequest
         * @instance
         */
        SearchOperationsRequest.prototype.limit = 0;

        /**
         * SearchOperationsRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchOperationsRequest
         * @instance
         */
        SearchOperationsRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchOperationsRequest where.
         * @member {Array.<PlatformAdminApi.SearchOperationsRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchOperationsRequest
         * @instance
         */
        SearchOperationsRequest.prototype.where = $util.emptyArray;

        /**
         * SearchOperationsRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.SearchOperationsRequest
         * @instance
         */
        SearchOperationsRequest.prototype.userId = "";

        /**
         * Encodes the specified SearchOperationsRequest message. Does not implicitly {@link PlatformAdminApi.SearchOperationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchOperationsRequest
         * @static
         * @param {PlatformAdminApi.ISearchOperationsRequest} message SearchOperationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOperationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchOperationsRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.userId);
            return writer;
        };

        /**
         * Decodes a SearchOperationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchOperationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchOperationsRequest} SearchOperationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOperationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOperationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchOperationsRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchOperationsRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchOperationsRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [clientId] WhereFields clientId
             * @property {PlatformAdminApi.IStringCondition|null} [tenantId] WhereFields tenantId
             * @property {PlatformAdminApi.INumberCondition|null} [operationType] WhereFields operationType
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchOperationsRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchOperationsRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SearchOperationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields clientId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} clientId
             * @memberof PlatformAdminApi.SearchOperationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.clientId = null;

            /**
             * WhereFields tenantId.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} tenantId
             * @memberof PlatformAdminApi.SearchOperationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.tenantId = null;

            /**
             * WhereFields operationType.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} operationType
             * @memberof PlatformAdminApi.SearchOperationsRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.operationType = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchOperationsRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchOperationsRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchOperationsRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.clientId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    $root.PlatformAdminApi.StringCondition.encode(message.tenantId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.operationType != null && Object.hasOwnProperty.call(message, "operationType"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.operationType, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchOperationsRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchOperationsRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOperationsRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.clientId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.tenantId = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.operationType = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchOperationsRequest;
    })();

    PlatformAdminApi.SearchOperationsResponse = (function() {

        /**
         * Properties of a SearchOperationsResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchOperationsResponse
         * @property {Array.<PlatformAdminApi.LoyaltyBonusCommon.IOperation>|null} [data] SearchOperationsResponse data
         * @property {number|null} [page] SearchOperationsResponse page
         * @property {number|null} [pageCount] SearchOperationsResponse pageCount
         * @property {number|null} [limit] SearchOperationsResponse limit
         * @property {number|null} [count] SearchOperationsResponse count
         * @property {number|null} [total] SearchOperationsResponse total
         */

        /**
         * Constructs a new SearchOperationsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchOperationsResponse.
         * @implements ISearchOperationsResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchOperationsResponse=} [properties] Properties to set
         */
        function SearchOperationsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOperationsResponse data.
         * @member {Array.<PlatformAdminApi.LoyaltyBonusCommon.IOperation>} data
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @instance
         */
        SearchOperationsResponse.prototype.data = $util.emptyArray;

        /**
         * SearchOperationsResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @instance
         */
        SearchOperationsResponse.prototype.page = 0;

        /**
         * SearchOperationsResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @instance
         */
        SearchOperationsResponse.prototype.pageCount = 0;

        /**
         * SearchOperationsResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @instance
         */
        SearchOperationsResponse.prototype.limit = 0;

        /**
         * SearchOperationsResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @instance
         */
        SearchOperationsResponse.prototype.count = 0;

        /**
         * SearchOperationsResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @instance
         */
        SearchOperationsResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchOperationsResponse message. Does not implicitly {@link PlatformAdminApi.SearchOperationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @static
         * @param {PlatformAdminApi.ISearchOperationsResponse} message SearchOperationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOperationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.LoyaltyBonusCommon.Operation.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchOperationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchOperationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchOperationsResponse} SearchOperationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOperationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOperationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.LoyaltyBonusCommon.Operation.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchOperationsResponse;
    })();

    PlatformAdminApi.GetAccountRequest = (function() {

        /**
         * Properties of a GetAccountRequest.
         * @memberof PlatformAdminApi
         * @interface IGetAccountRequest
         * @property {string|null} [userId] GetAccountRequest userId
         * @property {string|null} [tenantId] GetAccountRequest tenantId
         * @property {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker|null} [ticker] GetAccountRequest ticker
         */

        /**
         * Constructs a new GetAccountRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetAccountRequest.
         * @implements IGetAccountRequest
         * @constructor
         * @param {PlatformAdminApi.IGetAccountRequest=} [properties] Properties to set
         */
        function GetAccountRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAccountRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.GetAccountRequest
         * @instance
         */
        GetAccountRequest.prototype.userId = "";

        /**
         * GetAccountRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.GetAccountRequest
         * @instance
         */
        GetAccountRequest.prototype.tenantId = "";

        /**
         * GetAccountRequest ticker.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker} ticker
         * @memberof PlatformAdminApi.GetAccountRequest
         * @instance
         */
        GetAccountRequest.prototype.ticker = 0;

        /**
         * Encodes the specified GetAccountRequest message. Does not implicitly {@link PlatformAdminApi.GetAccountRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetAccountRequest
         * @static
         * @param {PlatformAdminApi.IGetAccountRequest} message GetAccountRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAccountRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tenantId);
            if (message.ticker != null && Object.hasOwnProperty.call(message, "ticker"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ticker);
            return writer;
        };

        /**
         * Decodes a GetAccountRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetAccountRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetAccountRequest} GetAccountRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAccountRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetAccountRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.tenantId = reader.string();
                    break;
                case 3:
                    message.ticker = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAccountRequest;
    })();

    PlatformAdminApi.GetAccountResponse = (function() {

        /**
         * Properties of a GetAccountResponse.
         * @memberof PlatformAdminApi
         * @interface IGetAccountResponse
         * @property {number|null} [id] GetAccountResponse id
         * @property {number|null} [total] GetAccountResponse total
         * @property {number|null} [blocked] GetAccountResponse blocked
         */

        /**
         * Constructs a new GetAccountResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetAccountResponse.
         * @implements IGetAccountResponse
         * @constructor
         * @param {PlatformAdminApi.IGetAccountResponse=} [properties] Properties to set
         */
        function GetAccountResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAccountResponse id.
         * @member {number} id
         * @memberof PlatformAdminApi.GetAccountResponse
         * @instance
         */
        GetAccountResponse.prototype.id = 0;

        /**
         * GetAccountResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.GetAccountResponse
         * @instance
         */
        GetAccountResponse.prototype.total = 0;

        /**
         * GetAccountResponse blocked.
         * @member {number} blocked
         * @memberof PlatformAdminApi.GetAccountResponse
         * @instance
         */
        GetAccountResponse.prototype.blocked = 0;

        /**
         * Encodes the specified GetAccountResponse message. Does not implicitly {@link PlatformAdminApi.GetAccountResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetAccountResponse
         * @static
         * @param {PlatformAdminApi.IGetAccountResponse} message GetAccountResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAccountResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
            if (message.blocked != null && Object.hasOwnProperty.call(message, "blocked"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.blocked);
            return writer;
        };

        /**
         * Decodes a GetAccountResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetAccountResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetAccountResponse} GetAccountResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAccountResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetAccountResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.total = reader.int32();
                    break;
                case 3:
                    message.blocked = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAccountResponse;
    })();

    PlatformAdminApi.FindOperationByIdRequest = (function() {

        /**
         * Properties of a FindOperationByIdRequest.
         * @memberof PlatformAdminApi
         * @interface IFindOperationByIdRequest
         * @property {string|null} [id] FindOperationByIdRequest id
         * @property {PlatformAdminApi.LoyaltyBonusCommon.OperationType|null} [operationType] FindOperationByIdRequest operationType
         */

        /**
         * Constructs a new FindOperationByIdRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FindOperationByIdRequest.
         * @implements IFindOperationByIdRequest
         * @constructor
         * @param {PlatformAdminApi.IFindOperationByIdRequest=} [properties] Properties to set
         */
        function FindOperationByIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindOperationByIdRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.FindOperationByIdRequest
         * @instance
         */
        FindOperationByIdRequest.prototype.id = "";

        /**
         * FindOperationByIdRequest operationType.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.OperationType} operationType
         * @memberof PlatformAdminApi.FindOperationByIdRequest
         * @instance
         */
        FindOperationByIdRequest.prototype.operationType = 0;

        /**
         * Encodes the specified FindOperationByIdRequest message. Does not implicitly {@link PlatformAdminApi.FindOperationByIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FindOperationByIdRequest
         * @static
         * @param {PlatformAdminApi.IFindOperationByIdRequest} message FindOperationByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindOperationByIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.operationType != null && Object.hasOwnProperty.call(message, "operationType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.operationType);
            return writer;
        };

        /**
         * Decodes a FindOperationByIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FindOperationByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FindOperationByIdRequest} FindOperationByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindOperationByIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FindOperationByIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.operationType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindOperationByIdRequest;
    })();

    PlatformAdminApi.FindOperationByIdResponse = (function() {

        /**
         * Properties of a FindOperationByIdResponse.
         * @memberof PlatformAdminApi
         * @interface IFindOperationByIdResponse
         * @property {PlatformAdminApi.LoyaltyBonusCommon.IFullOperation|null} [data] FindOperationByIdResponse data
         */

        /**
         * Constructs a new FindOperationByIdResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FindOperationByIdResponse.
         * @implements IFindOperationByIdResponse
         * @constructor
         * @param {PlatformAdminApi.IFindOperationByIdResponse=} [properties] Properties to set
         */
        function FindOperationByIdResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindOperationByIdResponse data.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.IFullOperation|null|undefined} data
         * @memberof PlatformAdminApi.FindOperationByIdResponse
         * @instance
         */
        FindOperationByIdResponse.prototype.data = null;

        /**
         * Encodes the specified FindOperationByIdResponse message. Does not implicitly {@link PlatformAdminApi.FindOperationByIdResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FindOperationByIdResponse
         * @static
         * @param {PlatformAdminApi.IFindOperationByIdResponse} message FindOperationByIdResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindOperationByIdResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.LoyaltyBonusCommon.FullOperation.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FindOperationByIdResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FindOperationByIdResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FindOperationByIdResponse} FindOperationByIdResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindOperationByIdResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FindOperationByIdResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.LoyaltyBonusCommon.FullOperation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindOperationByIdResponse;
    })();

    PlatformAdminApi.AccrueRequest = (function() {

        /**
         * Properties of an AccrueRequest.
         * @memberof PlatformAdminApi
         * @interface IAccrueRequest
         * @property {string|null} [userId] AccrueRequest userId
         * @property {string|null} [reason] AccrueRequest reason
         * @property {number|null} [amount] AccrueRequest amount
         * @property {string|null} [extId] AccrueRequest extId
         * @property {string|null} [signature] AccrueRequest signature
         * @property {string|null} [annualDate] AccrueRequest annualDate
         * @property {string|null} [tenantId] AccrueRequest tenantId
         * @property {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker|null} [ticker] AccrueRequest ticker
         */

        /**
         * Constructs a new AccrueRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AccrueRequest.
         * @implements IAccrueRequest
         * @constructor
         * @param {PlatformAdminApi.IAccrueRequest=} [properties] Properties to set
         */
        function AccrueRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AccrueRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.userId = "";

        /**
         * AccrueRequest reason.
         * @member {string} reason
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.reason = "";

        /**
         * AccrueRequest amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.amount = 0;

        /**
         * AccrueRequest extId.
         * @member {string} extId
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.extId = "";

        /**
         * AccrueRequest signature.
         * @member {string} signature
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.signature = "";

        /**
         * AccrueRequest annualDate.
         * @member {string} annualDate
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.annualDate = "";

        /**
         * AccrueRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.tenantId = "";

        /**
         * AccrueRequest ticker.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker} ticker
         * @memberof PlatformAdminApi.AccrueRequest
         * @instance
         */
        AccrueRequest.prototype.ticker = 0;

        /**
         * Encodes the specified AccrueRequest message. Does not implicitly {@link PlatformAdminApi.AccrueRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AccrueRequest
         * @static
         * @param {PlatformAdminApi.IAccrueRequest} message AccrueRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AccrueRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.extId != null && Object.hasOwnProperty.call(message, "extId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.extId);
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.signature);
            if (message.annualDate != null && Object.hasOwnProperty.call(message, "annualDate"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.annualDate);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.tenantId);
            if (message.ticker != null && Object.hasOwnProperty.call(message, "ticker"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.ticker);
            return writer;
        };

        /**
         * Decodes an AccrueRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AccrueRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AccrueRequest} AccrueRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AccrueRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AccrueRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.reason = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.extId = reader.string();
                    break;
                case 5:
                    message.signature = reader.string();
                    break;
                case 6:
                    message.annualDate = reader.string();
                    break;
                case 7:
                    message.tenantId = reader.string();
                    break;
                case 8:
                    message.ticker = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AccrueRequest;
    })();

    PlatformAdminApi.AccrueResponse = (function() {

        /**
         * Properties of an AccrueResponse.
         * @memberof PlatformAdminApi
         * @interface IAccrueResponse
         * @property {number|null} [total] AccrueResponse total
         * @property {number|null} [amount] AccrueResponse amount
         * @property {string|null} [operationId] AccrueResponse operationId
         */

        /**
         * Constructs a new AccrueResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AccrueResponse.
         * @implements IAccrueResponse
         * @constructor
         * @param {PlatformAdminApi.IAccrueResponse=} [properties] Properties to set
         */
        function AccrueResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AccrueResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.AccrueResponse
         * @instance
         */
        AccrueResponse.prototype.total = 0;

        /**
         * AccrueResponse amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AccrueResponse
         * @instance
         */
        AccrueResponse.prototype.amount = 0;

        /**
         * AccrueResponse operationId.
         * @member {string} operationId
         * @memberof PlatformAdminApi.AccrueResponse
         * @instance
         */
        AccrueResponse.prototype.operationId = "";

        /**
         * Encodes the specified AccrueResponse message. Does not implicitly {@link PlatformAdminApi.AccrueResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AccrueResponse
         * @static
         * @param {PlatformAdminApi.IAccrueResponse} message AccrueResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AccrueResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.total);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.operationId != null && Object.hasOwnProperty.call(message, "operationId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.operationId);
            return writer;
        };

        /**
         * Decodes an AccrueResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AccrueResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AccrueResponse} AccrueResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AccrueResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AccrueResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.total = reader.int32();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                case 3:
                    message.operationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AccrueResponse;
    })();

    PlatformAdminApi.BlockRequest = (function() {

        /**
         * Properties of a BlockRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockRequest
         * @property {string|null} [userId] BlockRequest userId
         * @property {number|null} [amount] BlockRequest amount
         * @property {string|null} [extId] BlockRequest extId
         * @property {string|null} [signature] BlockRequest signature
         * @property {string|null} [tenantId] BlockRequest tenantId
         * @property {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker|null} [ticker] BlockRequest ticker
         */

        /**
         * Constructs a new BlockRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockRequest.
         * @implements IBlockRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockRequest=} [properties] Properties to set
         */
        function BlockRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.BlockRequest
         * @instance
         */
        BlockRequest.prototype.userId = "";

        /**
         * BlockRequest amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.BlockRequest
         * @instance
         */
        BlockRequest.prototype.amount = 0;

        /**
         * BlockRequest extId.
         * @member {string} extId
         * @memberof PlatformAdminApi.BlockRequest
         * @instance
         */
        BlockRequest.prototype.extId = "";

        /**
         * BlockRequest signature.
         * @member {string} signature
         * @memberof PlatformAdminApi.BlockRequest
         * @instance
         */
        BlockRequest.prototype.signature = "";

        /**
         * BlockRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.BlockRequest
         * @instance
         */
        BlockRequest.prototype.tenantId = "";

        /**
         * BlockRequest ticker.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker} ticker
         * @memberof PlatformAdminApi.BlockRequest
         * @instance
         */
        BlockRequest.prototype.ticker = 0;

        /**
         * Encodes the specified BlockRequest message. Does not implicitly {@link PlatformAdminApi.BlockRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockRequest
         * @static
         * @param {PlatformAdminApi.IBlockRequest} message BlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.extId != null && Object.hasOwnProperty.call(message, "extId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.extId);
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.signature);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.tenantId);
            if (message.ticker != null && Object.hasOwnProperty.call(message, "ticker"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.ticker);
            return writer;
        };

        /**
         * Decodes a BlockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockRequest} BlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                case 3:
                    message.extId = reader.string();
                    break;
                case 4:
                    message.signature = reader.string();
                    break;
                case 5:
                    message.tenantId = reader.string();
                    break;
                case 6:
                    message.ticker = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockRequest;
    })();

    PlatformAdminApi.BlockResponse = (function() {

        /**
         * Properties of a BlockResponse.
         * @memberof PlatformAdminApi
         * @interface IBlockResponse
         * @property {string|null} [operationId] BlockResponse operationId
         */

        /**
         * Constructs a new BlockResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockResponse.
         * @implements IBlockResponse
         * @constructor
         * @param {PlatformAdminApi.IBlockResponse=} [properties] Properties to set
         */
        function BlockResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockResponse operationId.
         * @member {string} operationId
         * @memberof PlatformAdminApi.BlockResponse
         * @instance
         */
        BlockResponse.prototype.operationId = "";

        /**
         * Encodes the specified BlockResponse message. Does not implicitly {@link PlatformAdminApi.BlockResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockResponse
         * @static
         * @param {PlatformAdminApi.IBlockResponse} message BlockResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.operationId != null && Object.hasOwnProperty.call(message, "operationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.operationId);
            return writer;
        };

        /**
         * Decodes a BlockResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockResponse} BlockResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.operationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockResponse;
    })();

    PlatformAdminApi.UnBlockRequest = (function() {

        /**
         * Properties of an UnBlockRequest.
         * @memberof PlatformAdminApi
         * @interface IUnBlockRequest
         * @property {string|null} [userId] UnBlockRequest userId
         * @property {string|null} [operationId] UnBlockRequest operationId
         * @property {string|null} [tenantId] UnBlockRequest tenantId
         * @property {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker|null} [ticker] UnBlockRequest ticker
         */

        /**
         * Constructs a new UnBlockRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UnBlockRequest.
         * @implements IUnBlockRequest
         * @constructor
         * @param {PlatformAdminApi.IUnBlockRequest=} [properties] Properties to set
         */
        function UnBlockRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnBlockRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.UnBlockRequest
         * @instance
         */
        UnBlockRequest.prototype.userId = "";

        /**
         * UnBlockRequest operationId.
         * @member {string} operationId
         * @memberof PlatformAdminApi.UnBlockRequest
         * @instance
         */
        UnBlockRequest.prototype.operationId = "";

        /**
         * UnBlockRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.UnBlockRequest
         * @instance
         */
        UnBlockRequest.prototype.tenantId = "";

        /**
         * UnBlockRequest ticker.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker} ticker
         * @memberof PlatformAdminApi.UnBlockRequest
         * @instance
         */
        UnBlockRequest.prototype.ticker = 0;

        /**
         * Encodes the specified UnBlockRequest message. Does not implicitly {@link PlatformAdminApi.UnBlockRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UnBlockRequest
         * @static
         * @param {PlatformAdminApi.IUnBlockRequest} message UnBlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnBlockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.operationId != null && Object.hasOwnProperty.call(message, "operationId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operationId);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tenantId);
            if (message.ticker != null && Object.hasOwnProperty.call(message, "ticker"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ticker);
            return writer;
        };

        /**
         * Decodes an UnBlockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UnBlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UnBlockRequest} UnBlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnBlockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UnBlockRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.operationId = reader.string();
                    break;
                case 3:
                    message.tenantId = reader.string();
                    break;
                case 4:
                    message.ticker = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UnBlockRequest;
    })();

    PlatformAdminApi.UnBlockResponse = (function() {

        /**
         * Properties of an UnBlockResponse.
         * @memberof PlatformAdminApi
         * @interface IUnBlockResponse
         * @property {number|null} [total] UnBlockResponse total
         * @property {number|null} [amount] UnBlockResponse amount
         */

        /**
         * Constructs a new UnBlockResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UnBlockResponse.
         * @implements IUnBlockResponse
         * @constructor
         * @param {PlatformAdminApi.IUnBlockResponse=} [properties] Properties to set
         */
        function UnBlockResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnBlockResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.UnBlockResponse
         * @instance
         */
        UnBlockResponse.prototype.total = 0;

        /**
         * UnBlockResponse amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.UnBlockResponse
         * @instance
         */
        UnBlockResponse.prototype.amount = 0;

        /**
         * Encodes the specified UnBlockResponse message. Does not implicitly {@link PlatformAdminApi.UnBlockResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UnBlockResponse
         * @static
         * @param {PlatformAdminApi.IUnBlockResponse} message UnBlockResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnBlockResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.total);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            return writer;
        };

        /**
         * Decodes an UnBlockResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UnBlockResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UnBlockResponse} UnBlockResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnBlockResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UnBlockResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.total = reader.int32();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UnBlockResponse;
    })();

    PlatformAdminApi.ChargeOffRequest = (function() {

        /**
         * Properties of a ChargeOffRequest.
         * @memberof PlatformAdminApi
         * @interface IChargeOffRequest
         * @property {string|null} [userId] ChargeOffRequest userId
         * @property {string|null} [operationId] ChargeOffRequest operationId
         * @property {string|null} [tenantId] ChargeOffRequest tenantId
         * @property {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker|null} [ticker] ChargeOffRequest ticker
         */

        /**
         * Constructs a new ChargeOffRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ChargeOffRequest.
         * @implements IChargeOffRequest
         * @constructor
         * @param {PlatformAdminApi.IChargeOffRequest=} [properties] Properties to set
         */
        function ChargeOffRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChargeOffRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.ChargeOffRequest
         * @instance
         */
        ChargeOffRequest.prototype.userId = "";

        /**
         * ChargeOffRequest operationId.
         * @member {string} operationId
         * @memberof PlatformAdminApi.ChargeOffRequest
         * @instance
         */
        ChargeOffRequest.prototype.operationId = "";

        /**
         * ChargeOffRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.ChargeOffRequest
         * @instance
         */
        ChargeOffRequest.prototype.tenantId = "";

        /**
         * ChargeOffRequest ticker.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.AccountTicker} ticker
         * @memberof PlatformAdminApi.ChargeOffRequest
         * @instance
         */
        ChargeOffRequest.prototype.ticker = 0;

        /**
         * Encodes the specified ChargeOffRequest message. Does not implicitly {@link PlatformAdminApi.ChargeOffRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ChargeOffRequest
         * @static
         * @param {PlatformAdminApi.IChargeOffRequest} message ChargeOffRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChargeOffRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.operationId != null && Object.hasOwnProperty.call(message, "operationId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operationId);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tenantId);
            if (message.ticker != null && Object.hasOwnProperty.call(message, "ticker"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ticker);
            return writer;
        };

        /**
         * Decodes a ChargeOffRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ChargeOffRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ChargeOffRequest} ChargeOffRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChargeOffRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChargeOffRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.operationId = reader.string();
                    break;
                case 3:
                    message.tenantId = reader.string();
                    break;
                case 4:
                    message.ticker = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ChargeOffRequest;
    })();

    PlatformAdminApi.ChargeOffResponse = (function() {

        /**
         * Properties of a ChargeOffResponse.
         * @memberof PlatformAdminApi
         * @interface IChargeOffResponse
         * @property {number|null} [total] ChargeOffResponse total
         * @property {number|null} [amount] ChargeOffResponse amount
         */

        /**
         * Constructs a new ChargeOffResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ChargeOffResponse.
         * @implements IChargeOffResponse
         * @constructor
         * @param {PlatformAdminApi.IChargeOffResponse=} [properties] Properties to set
         */
        function ChargeOffResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChargeOffResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ChargeOffResponse
         * @instance
         */
        ChargeOffResponse.prototype.total = 0;

        /**
         * ChargeOffResponse amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.ChargeOffResponse
         * @instance
         */
        ChargeOffResponse.prototype.amount = 0;

        /**
         * Encodes the specified ChargeOffResponse message. Does not implicitly {@link PlatformAdminApi.ChargeOffResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ChargeOffResponse
         * @static
         * @param {PlatformAdminApi.IChargeOffResponse} message ChargeOffResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChargeOffResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.total);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            return writer;
        };

        /**
         * Decodes a ChargeOffResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ChargeOffResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ChargeOffResponse} ChargeOffResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChargeOffResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChargeOffResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.total = reader.int32();
                    break;
                case 2:
                    message.amount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ChargeOffResponse;
    })();

    PlatformAdminApi.OperationsListItem = (function() {

        /**
         * Properties of an OperationsListItem.
         * @memberof PlatformAdminApi
         * @interface IOperationsListItem
         * @property {string|null} [id] OperationsListItem id
         * @property {number|null} [amountCents] OperationsListItem amountCents
         * @property {PlatformAdminApi.LoyaltyBonusCommon.OperationType|null} [operationType] OperationsListItem operationType
         * @property {string|null} [operationTypeString] OperationsListItem operationTypeString
         * @property {string|null} [createdAt] OperationsListItem createdAt
         * @property {string|null} [reason] OperationsListItem reason
         * @property {string|null} [userName] OperationsListItem userName
         * @property {string|null} [extId] OperationsListItem extId
         */

        /**
         * Constructs a new OperationsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OperationsListItem.
         * @implements IOperationsListItem
         * @constructor
         * @param {PlatformAdminApi.IOperationsListItem=} [properties] Properties to set
         */
        function OperationsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OperationsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.id = "";

        /**
         * OperationsListItem amountCents.
         * @member {number} amountCents
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.amountCents = 0;

        /**
         * OperationsListItem operationType.
         * @member {PlatformAdminApi.LoyaltyBonusCommon.OperationType} operationType
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.operationType = 0;

        /**
         * OperationsListItem operationTypeString.
         * @member {string} operationTypeString
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.operationTypeString = "";

        /**
         * OperationsListItem createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.createdAt = "";

        /**
         * OperationsListItem reason.
         * @member {string} reason
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.reason = "";

        /**
         * OperationsListItem userName.
         * @member {string} userName
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.userName = "";

        /**
         * OperationsListItem extId.
         * @member {string} extId
         * @memberof PlatformAdminApi.OperationsListItem
         * @instance
         */
        OperationsListItem.prototype.extId = "";

        /**
         * Encodes the specified OperationsListItem message. Does not implicitly {@link PlatformAdminApi.OperationsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OperationsListItem
         * @static
         * @param {PlatformAdminApi.IOperationsListItem} message OperationsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OperationsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.amountCents != null && Object.hasOwnProperty.call(message, "amountCents"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amountCents);
            if (message.operationType != null && Object.hasOwnProperty.call(message, "operationType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.operationType);
            if (message.operationTypeString != null && Object.hasOwnProperty.call(message, "operationTypeString"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.operationTypeString);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdAt);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.reason);
            if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.userName);
            if (message.extId != null && Object.hasOwnProperty.call(message, "extId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.extId);
            return writer;
        };

        /**
         * Decodes an OperationsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OperationsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OperationsListItem} OperationsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OperationsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OperationsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.amountCents = reader.int32();
                    break;
                case 3:
                    message.operationType = reader.int32();
                    break;
                case 4:
                    message.operationTypeString = reader.string();
                    break;
                case 5:
                    message.createdAt = reader.string();
                    break;
                case 6:
                    message.reason = reader.string();
                    break;
                case 7:
                    message.userName = reader.string();
                    break;
                case 8:
                    message.extId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OperationsListItem;
    })();

    PlatformAdminApi.OperationsListResponse = (function() {

        /**
         * Properties of an OperationsListResponse.
         * @memberof PlatformAdminApi
         * @interface IOperationsListResponse
         * @property {Array.<PlatformAdminApi.IOperationsListItem>|null} [operations] OperationsListResponse operations
         * @property {number|null} [page] OperationsListResponse page
         * @property {number|null} [pageCount] OperationsListResponse pageCount
         * @property {number|null} [limit] OperationsListResponse limit
         * @property {number|null} [count] OperationsListResponse count
         * @property {number|null} [total] OperationsListResponse total
         */

        /**
         * Constructs a new OperationsListResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OperationsListResponse.
         * @implements IOperationsListResponse
         * @constructor
         * @param {PlatformAdminApi.IOperationsListResponse=} [properties] Properties to set
         */
        function OperationsListResponse(properties) {
            this.operations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OperationsListResponse operations.
         * @member {Array.<PlatformAdminApi.IOperationsListItem>} operations
         * @memberof PlatformAdminApi.OperationsListResponse
         * @instance
         */
        OperationsListResponse.prototype.operations = $util.emptyArray;

        /**
         * OperationsListResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.OperationsListResponse
         * @instance
         */
        OperationsListResponse.prototype.page = 0;

        /**
         * OperationsListResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.OperationsListResponse
         * @instance
         */
        OperationsListResponse.prototype.pageCount = 0;

        /**
         * OperationsListResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.OperationsListResponse
         * @instance
         */
        OperationsListResponse.prototype.limit = 0;

        /**
         * OperationsListResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.OperationsListResponse
         * @instance
         */
        OperationsListResponse.prototype.count = 0;

        /**
         * OperationsListResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.OperationsListResponse
         * @instance
         */
        OperationsListResponse.prototype.total = 0;

        /**
         * Encodes the specified OperationsListResponse message. Does not implicitly {@link PlatformAdminApi.OperationsListResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OperationsListResponse
         * @static
         * @param {PlatformAdminApi.IOperationsListResponse} message OperationsListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OperationsListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.operations != null && message.operations.length)
                for (var i = 0; i < message.operations.length; ++i)
                    $root.PlatformAdminApi.OperationsListItem.encode(message.operations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes an OperationsListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OperationsListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OperationsListResponse} OperationsListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OperationsListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OperationsListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.operations && message.operations.length))
                        message.operations = [];
                    message.operations.push($root.PlatformAdminApi.OperationsListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OperationsListResponse;
    })();

    PlatformAdminApi.CreateOperationRequest = (function() {

        /**
         * Properties of a CreateOperationRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateOperationRequest
         * @property {string|null} [userId] CreateOperationRequest userId
         * @property {PlatformAdminApi.IReasonOption|null} [reason] CreateOperationRequest reason
         * @property {string|null} [amount] CreateOperationRequest amount
         * @property {PlatformAdminApi.CreateOperationRequest.IIssue|null} [issue] CreateOperationRequest issue
         * @property {string|null} [comment] CreateOperationRequest comment
         */

        /**
         * Constructs a new CreateOperationRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CreateOperationRequest.
         * @implements ICreateOperationRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateOperationRequest=} [properties] Properties to set
         */
        function CreateOperationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateOperationRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.CreateOperationRequest
         * @instance
         */
        CreateOperationRequest.prototype.userId = "";

        /**
         * CreateOperationRequest reason.
         * @member {PlatformAdminApi.IReasonOption|null|undefined} reason
         * @memberof PlatformAdminApi.CreateOperationRequest
         * @instance
         */
        CreateOperationRequest.prototype.reason = null;

        /**
         * CreateOperationRequest amount.
         * @member {string} amount
         * @memberof PlatformAdminApi.CreateOperationRequest
         * @instance
         */
        CreateOperationRequest.prototype.amount = "";

        /**
         * CreateOperationRequest issue.
         * @member {PlatformAdminApi.CreateOperationRequest.IIssue|null|undefined} issue
         * @memberof PlatformAdminApi.CreateOperationRequest
         * @instance
         */
        CreateOperationRequest.prototype.issue = null;

        /**
         * CreateOperationRequest comment.
         * @member {string} comment
         * @memberof PlatformAdminApi.CreateOperationRequest
         * @instance
         */
        CreateOperationRequest.prototype.comment = "";

        /**
         * Encodes the specified CreateOperationRequest message. Does not implicitly {@link PlatformAdminApi.CreateOperationRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateOperationRequest
         * @static
         * @param {PlatformAdminApi.ICreateOperationRequest} message CreateOperationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateOperationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                $root.PlatformAdminApi.ReasonOption.encode(message.reason, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.amount);
            if (message.issue != null && Object.hasOwnProperty.call(message, "issue"))
                $root.PlatformAdminApi.CreateOperationRequest.Issue.encode(message.issue, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.comment);
            return writer;
        };

        /**
         * Decodes a CreateOperationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateOperationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateOperationRequest} CreateOperationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateOperationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateOperationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.reason = $root.PlatformAdminApi.ReasonOption.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.amount = reader.string();
                    break;
                case 4:
                    message.issue = $root.PlatformAdminApi.CreateOperationRequest.Issue.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * OperationType enum.
         * @name PlatformAdminApi.CreateOperationRequest.OperationType
         * @enum {number}
         * @property {number} Accrual=0 Accrual value
         * @property {number} ChargeOff=1 ChargeOff value
         */
        CreateOperationRequest.OperationType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Accrual"] = 0;
            values[valuesById[1] = "ChargeOff"] = 1;
            return values;
        })();

        CreateOperationRequest.Issue = (function() {

            /**
             * Properties of an Issue.
             * @memberof PlatformAdminApi.CreateOperationRequest
             * @interface IIssue
             * @property {string|null} [id] Issue id
             */

            /**
             * Constructs a new Issue.
             * @memberof PlatformAdminApi.CreateOperationRequest
             * @classdesc Represents an Issue.
             * @implements IIssue
             * @constructor
             * @param {PlatformAdminApi.CreateOperationRequest.IIssue=} [properties] Properties to set
             */
            function Issue(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Issue id.
             * @member {string} id
             * @memberof PlatformAdminApi.CreateOperationRequest.Issue
             * @instance
             */
            Issue.prototype.id = "";

            /**
             * Encodes the specified Issue message. Does not implicitly {@link PlatformAdminApi.CreateOperationRequest.Issue.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.CreateOperationRequest.Issue
             * @static
             * @param {PlatformAdminApi.CreateOperationRequest.IIssue} message Issue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Issue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                return writer;
            };

            /**
             * Decodes an Issue message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.CreateOperationRequest.Issue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.CreateOperationRequest.Issue} Issue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Issue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateOperationRequest.Issue();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Issue;
        })();

        return CreateOperationRequest;
    })();

    PlatformAdminApi.CreateOperationResponse = (function() {

        /**
         * Properties of a CreateOperationResponse.
         * @memberof PlatformAdminApi
         * @interface ICreateOperationResponse
         * @property {PlatformAdminApi.CreateOperationResponse.IAccountData|null} [account] CreateOperationResponse account
         * @property {Array.<PlatformAdminApi.ILoyaltyBonusOperation>|null} [operations] CreateOperationResponse operations
         */

        /**
         * Constructs a new CreateOperationResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CreateOperationResponse.
         * @implements ICreateOperationResponse
         * @constructor
         * @param {PlatformAdminApi.ICreateOperationResponse=} [properties] Properties to set
         */
        function CreateOperationResponse(properties) {
            this.operations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateOperationResponse account.
         * @member {PlatformAdminApi.CreateOperationResponse.IAccountData|null|undefined} account
         * @memberof PlatformAdminApi.CreateOperationResponse
         * @instance
         */
        CreateOperationResponse.prototype.account = null;

        /**
         * CreateOperationResponse operations.
         * @member {Array.<PlatformAdminApi.ILoyaltyBonusOperation>} operations
         * @memberof PlatformAdminApi.CreateOperationResponse
         * @instance
         */
        CreateOperationResponse.prototype.operations = $util.emptyArray;

        /**
         * Encodes the specified CreateOperationResponse message. Does not implicitly {@link PlatformAdminApi.CreateOperationResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateOperationResponse
         * @static
         * @param {PlatformAdminApi.ICreateOperationResponse} message CreateOperationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateOperationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                $root.PlatformAdminApi.CreateOperationResponse.AccountData.encode(message.account, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.operations != null && message.operations.length)
                for (var i = 0; i < message.operations.length; ++i)
                    $root.PlatformAdminApi.LoyaltyBonusOperation.encode(message.operations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreateOperationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateOperationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateOperationResponse} CreateOperationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateOperationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateOperationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.account = $root.PlatformAdminApi.CreateOperationResponse.AccountData.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.operations && message.operations.length))
                        message.operations = [];
                    message.operations.push($root.PlatformAdminApi.LoyaltyBonusOperation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CreateOperationResponse.AccountData = (function() {

            /**
             * Properties of an AccountData.
             * @memberof PlatformAdminApi.CreateOperationResponse
             * @interface IAccountData
             * @property {number|null} [total] AccountData total
             * @property {number|null} [blocked] AccountData blocked
             */

            /**
             * Constructs a new AccountData.
             * @memberof PlatformAdminApi.CreateOperationResponse
             * @classdesc Represents an AccountData.
             * @implements IAccountData
             * @constructor
             * @param {PlatformAdminApi.CreateOperationResponse.IAccountData=} [properties] Properties to set
             */
            function AccountData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AccountData total.
             * @member {number} total
             * @memberof PlatformAdminApi.CreateOperationResponse.AccountData
             * @instance
             */
            AccountData.prototype.total = 0;

            /**
             * AccountData blocked.
             * @member {number} blocked
             * @memberof PlatformAdminApi.CreateOperationResponse.AccountData
             * @instance
             */
            AccountData.prototype.blocked = 0;

            /**
             * Encodes the specified AccountData message. Does not implicitly {@link PlatformAdminApi.CreateOperationResponse.AccountData.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.CreateOperationResponse.AccountData
             * @static
             * @param {PlatformAdminApi.CreateOperationResponse.IAccountData} message AccountData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AccountData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
                if (message.blocked != null && Object.hasOwnProperty.call(message, "blocked"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.blocked);
                return writer;
            };

            /**
             * Decodes an AccountData message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.CreateOperationResponse.AccountData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.CreateOperationResponse.AccountData} AccountData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AccountData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateOperationResponse.AccountData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.total = reader.int32();
                        break;
                    case 3:
                        message.blocked = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return AccountData;
        })();

        return CreateOperationResponse;
    })();

    PlatformAdminApi.GetAvailableOperationReasonsResponse = (function() {

        /**
         * Properties of a GetAvailableOperationReasonsResponse.
         * @memberof PlatformAdminApi
         * @interface IGetAvailableOperationReasonsResponse
         * @property {Array.<PlatformAdminApi.IReasonOption>|null} [reasons] GetAvailableOperationReasonsResponse reasons
         */

        /**
         * Constructs a new GetAvailableOperationReasonsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetAvailableOperationReasonsResponse.
         * @implements IGetAvailableOperationReasonsResponse
         * @constructor
         * @param {PlatformAdminApi.IGetAvailableOperationReasonsResponse=} [properties] Properties to set
         */
        function GetAvailableOperationReasonsResponse(properties) {
            this.reasons = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableOperationReasonsResponse reasons.
         * @member {Array.<PlatformAdminApi.IReasonOption>} reasons
         * @memberof PlatformAdminApi.GetAvailableOperationReasonsResponse
         * @instance
         */
        GetAvailableOperationReasonsResponse.prototype.reasons = $util.emptyArray;

        /**
         * Encodes the specified GetAvailableOperationReasonsResponse message. Does not implicitly {@link PlatformAdminApi.GetAvailableOperationReasonsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetAvailableOperationReasonsResponse
         * @static
         * @param {PlatformAdminApi.IGetAvailableOperationReasonsResponse} message GetAvailableOperationReasonsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableOperationReasonsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reasons != null && message.reasons.length)
                for (var i = 0; i < message.reasons.length; ++i)
                    $root.PlatformAdminApi.ReasonOption.encode(message.reasons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetAvailableOperationReasonsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetAvailableOperationReasonsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetAvailableOperationReasonsResponse} GetAvailableOperationReasonsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableOperationReasonsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetAvailableOperationReasonsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.reasons && message.reasons.length))
                        message.reasons = [];
                    message.reasons.push($root.PlatformAdminApi.ReasonOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableOperationReasonsResponse;
    })();

    PlatformAdminApi.GetBonusAccountInfoRequest = (function() {

        /**
         * Properties of a GetBonusAccountInfoRequest.
         * @memberof PlatformAdminApi
         * @interface IGetBonusAccountInfoRequest
         * @property {string|null} [userId] GetBonusAccountInfoRequest userId
         */

        /**
         * Constructs a new GetBonusAccountInfoRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetBonusAccountInfoRequest.
         * @implements IGetBonusAccountInfoRequest
         * @constructor
         * @param {PlatformAdminApi.IGetBonusAccountInfoRequest=} [properties] Properties to set
         */
        function GetBonusAccountInfoRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBonusAccountInfoRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.GetBonusAccountInfoRequest
         * @instance
         */
        GetBonusAccountInfoRequest.prototype.userId = "";

        /**
         * Encodes the specified GetBonusAccountInfoRequest message. Does not implicitly {@link PlatformAdminApi.GetBonusAccountInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetBonusAccountInfoRequest
         * @static
         * @param {PlatformAdminApi.IGetBonusAccountInfoRequest} message GetBonusAccountInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBonusAccountInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            return writer;
        };

        /**
         * Decodes a GetBonusAccountInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetBonusAccountInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetBonusAccountInfoRequest} GetBonusAccountInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBonusAccountInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetBonusAccountInfoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetBonusAccountInfoRequest;
    })();

    PlatformAdminApi.GetBonusAccountInfoResponse = (function() {

        /**
         * Properties of a GetBonusAccountInfoResponse.
         * @memberof PlatformAdminApi
         * @interface IGetBonusAccountInfoResponse
         * @property {number|null} [total] GetBonusAccountInfoResponse total
         * @property {number|null} [blocked] GetBonusAccountInfoResponse blocked
         */

        /**
         * Constructs a new GetBonusAccountInfoResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetBonusAccountInfoResponse.
         * @implements IGetBonusAccountInfoResponse
         * @constructor
         * @param {PlatformAdminApi.IGetBonusAccountInfoResponse=} [properties] Properties to set
         */
        function GetBonusAccountInfoResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBonusAccountInfoResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.GetBonusAccountInfoResponse
         * @instance
         */
        GetBonusAccountInfoResponse.prototype.total = 0;

        /**
         * GetBonusAccountInfoResponse blocked.
         * @member {number} blocked
         * @memberof PlatformAdminApi.GetBonusAccountInfoResponse
         * @instance
         */
        GetBonusAccountInfoResponse.prototype.blocked = 0;

        /**
         * Encodes the specified GetBonusAccountInfoResponse message. Does not implicitly {@link PlatformAdminApi.GetBonusAccountInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetBonusAccountInfoResponse
         * @static
         * @param {PlatformAdminApi.IGetBonusAccountInfoResponse} message GetBonusAccountInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBonusAccountInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.total);
            if (message.blocked != null && Object.hasOwnProperty.call(message, "blocked"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.blocked);
            return writer;
        };

        /**
         * Decodes a GetBonusAccountInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetBonusAccountInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetBonusAccountInfoResponse} GetBonusAccountInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBonusAccountInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetBonusAccountInfoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.total = reader.int32();
                    break;
                case 2:
                    message.blocked = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetBonusAccountInfoResponse;
    })();

    PlatformAdminApi.OptionService = (function() {

        /**
         * Constructs a new OptionService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OptionService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function OptionService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (OptionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OptionService;

        /**
         * Callback as used by {@link PlatformAdminApi.OptionService#create}.
         * @memberof PlatformAdminApi.OptionService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OptionResponse} [response] OptionResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.ICreateOptionRequest} request CreateOptionRequest message or plain object
         * @param {PlatformAdminApi.OptionService.CreateCallback} callback Node-style callback called with the error, if any, and OptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OptionService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreateOptionRequest, $root.PlatformAdminApi.OptionResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.ICreateOptionRequest} request CreateOptionRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OptionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OptionService#update}.
         * @memberof PlatformAdminApi.OptionService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OptionResponse} [response] OptionResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUpdateOptionRequest} request UpdateOptionRequest message or plain object
         * @param {PlatformAdminApi.OptionService.UpdateCallback} callback Node-style callback called with the error, if any, and OptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OptionService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdateOptionRequest, $root.PlatformAdminApi.OptionResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUpdateOptionRequest} request UpdateOptionRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OptionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OptionService#search}.
         * @memberof PlatformAdminApi.OptionService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchOptionResponse} [response] SearchOptionResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.ISearchOptionRequest} request SearchOptionRequest message or plain object
         * @param {PlatformAdminApi.OptionService.SearchCallback} callback Node-style callback called with the error, if any, and SearchOptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OptionService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchOptionRequest, $root.PlatformAdminApi.SearchOptionResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.ISearchOptionRequest} request SearchOptionRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchOptionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OptionService#findById}.
         * @memberof PlatformAdminApi.OptionService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OptionResponse} [response] OptionResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OptionService.FindByIdCallback} callback Node-style callback called with the error, if any, and OptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OptionService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.OptionResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OptionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OptionService#remove}.
         * @memberof PlatformAdminApi.OptionService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OptionResponse} [response] OptionResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OptionService.RemoveCallback} callback Node-style callback called with the error, if any, and OptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OptionService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.OptionResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OptionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OptionService#restore}.
         * @memberof PlatformAdminApi.OptionService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OptionResponse} [response] OptionResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OptionService.RestoreCallback} callback Node-style callback called with the error, if any, and OptionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OptionService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.OptionResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.OptionService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OptionResponse>} Promise
         * @variation 2
         */

        return OptionService;
    })();

    PlatformAdminApi.CreateOptionRequest = (function() {

        /**
         * Properties of a CreateOptionRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateOptionRequest
         * @property {string|null} [title] CreateOptionRequest title
         * @property {string|null} [name] CreateOptionRequest name
         */

        /**
         * Constructs a new CreateOptionRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для создания услуги
         * @implements ICreateOptionRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateOptionRequest=} [properties] Properties to set
         */
        function CreateOptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateOptionRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreateOptionRequest
         * @instance
         */
        CreateOptionRequest.prototype.title = "";

        /**
         * CreateOptionRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.CreateOptionRequest
         * @instance
         */
        CreateOptionRequest.prototype.name = "";

        /**
         * Encodes the specified CreateOptionRequest message. Does not implicitly {@link PlatformAdminApi.CreateOptionRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateOptionRequest
         * @static
         * @param {PlatformAdminApi.ICreateOptionRequest} message CreateOptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateOptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a CreateOptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateOptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateOptionRequest} CreateOptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateOptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateOptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateOptionRequest;
    })();

    PlatformAdminApi.UpdateOptionRequest = (function() {

        /**
         * Properties of an UpdateOptionRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateOptionRequest
         * @property {string|null} [id] UpdateOptionRequest id
         * @property {string|null} [title] UpdateOptionRequest title
         * @property {string|null} [name] UpdateOptionRequest name
         */

        /**
         * Constructs a new UpdateOptionRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для обновления услуги
         * @implements IUpdateOptionRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateOptionRequest=} [properties] Properties to set
         */
        function UpdateOptionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateOptionRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdateOptionRequest
         * @instance
         */
        UpdateOptionRequest.prototype.id = "";

        /**
         * UpdateOptionRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdateOptionRequest
         * @instance
         */
        UpdateOptionRequest.prototype.title = "";

        /**
         * UpdateOptionRequest name.
         * @member {string} name
         * @memberof PlatformAdminApi.UpdateOptionRequest
         * @instance
         */
        UpdateOptionRequest.prototype.name = "";

        /**
         * Encodes the specified UpdateOptionRequest message. Does not implicitly {@link PlatformAdminApi.UpdateOptionRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateOptionRequest
         * @static
         * @param {PlatformAdminApi.IUpdateOptionRequest} message UpdateOptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateOptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes an UpdateOptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateOptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateOptionRequest} UpdateOptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateOptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateOptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateOptionRequest;
    })();

    PlatformAdminApi.SearchOptionRequest = (function() {

        /**
         * Properties of a SearchOptionRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchOptionRequest
         * @property {number|null} [page] SearchOptionRequest page
         * @property {number|null} [limit] SearchOptionRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchOptionRequest sort
         * @property {Array.<PlatformAdminApi.SearchOptionRequest.IWhereFields>|null} [where] SearchOptionRequest where
         */

        /**
         * Constructs a new SearchOptionRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение доступных услуг
         * @implements ISearchOptionRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchOptionRequest=} [properties] Properties to set
         */
        function SearchOptionRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOptionRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchOptionRequest
         * @instance
         */
        SearchOptionRequest.prototype.page = 0;

        /**
         * SearchOptionRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchOptionRequest
         * @instance
         */
        SearchOptionRequest.prototype.limit = 0;

        /**
         * SearchOptionRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchOptionRequest
         * @instance
         */
        SearchOptionRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchOptionRequest where.
         * @member {Array.<PlatformAdminApi.SearchOptionRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchOptionRequest
         * @instance
         */
        SearchOptionRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchOptionRequest message. Does not implicitly {@link PlatformAdminApi.SearchOptionRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchOptionRequest
         * @static
         * @param {PlatformAdminApi.ISearchOptionRequest} message SearchOptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchOptionRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchOptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchOptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchOptionRequest} SearchOptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOptionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchOptionRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchOptionRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchOptionRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {boolean|null} [isDeleted] WhereFields isDeleted
             * @property {PlatformAdminApi.IDateCondition|null} [createdAt] WhereFields createdAt
             * @property {PlatformAdminApi.IDateCondition|null} [updatedAt] WhereFields updatedAt
             * @property {PlatformAdminApi.IDateCondition|null} [deletedAt] WhereFields deletedAt
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.IStringCondition|null} [name] WhereFields name
             * @property {PlatformAdminApi.INumberCondition|null} [duration] WhereFields duration
             * @property {PlatformAdminApi.IUuidCondition|null} [regulationsId] WhereFields regulationsId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchOptionRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchOptionRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields isDeleted.
             * @member {boolean} isDeleted
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.isDeleted = false;

            /**
             * WhereFields createdAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} createdAt
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.createdAt = null;

            /**
             * WhereFields updatedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} updatedAt
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.updatedAt = null;

            /**
             * WhereFields deletedAt.
             * @member {PlatformAdminApi.IDateCondition|null|undefined} deletedAt
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.deletedAt = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields name.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} name
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.name = null;

            /**
             * WhereFields duration.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} duration
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.duration = null;

            /**
             * WhereFields regulationsId.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} regulationsId
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.regulationsId = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchOptionRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchOptionRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
                if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.createdAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.updatedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                    $root.PlatformAdminApi.DateCondition.encode(message.deletedAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.PlatformAdminApi.StringCondition.encode(message.name, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.duration, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.regulationsId != null && Object.hasOwnProperty.call(message, "regulationsId"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.regulationsId, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchOptionRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchOptionRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOptionRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.isDeleted = reader.bool();
                        break;
                    case 3:
                        message.createdAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.updatedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.deletedAt = $root.PlatformAdminApi.DateCondition.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.name = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.duration = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.regulationsId = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchOptionRequest;
    })();

    PlatformAdminApi.OptionResponse = (function() {

        /**
         * Properties of an OptionResponse.
         * @memberof PlatformAdminApi
         * @interface IOptionResponse
         * @property {PlatformAdminApi.IOption|null} [data] OptionResponse data
         */

        /**
         * Constructs a new OptionResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OptionResponse.
         * @implements IOptionResponse
         * @constructor
         * @param {PlatformAdminApi.IOptionResponse=} [properties] Properties to set
         */
        function OptionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OptionResponse data.
         * @member {PlatformAdminApi.IOption|null|undefined} data
         * @memberof PlatformAdminApi.OptionResponse
         * @instance
         */
        OptionResponse.prototype.data = null;

        /**
         * Encodes the specified OptionResponse message. Does not implicitly {@link PlatformAdminApi.OptionResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OptionResponse
         * @static
         * @param {PlatformAdminApi.IOptionResponse} message OptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Option.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OptionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OptionResponse} OptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OptionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Option.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OptionResponse;
    })();

    PlatformAdminApi.SearchOptionResponse = (function() {

        /**
         * Properties of a SearchOptionResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchOptionResponse
         * @property {Array.<PlatformAdminApi.IOption>|null} [data] SearchOptionResponse data
         * @property {number|null} [page] SearchOptionResponse page
         * @property {number|null} [pageCount] SearchOptionResponse pageCount
         * @property {number|null} [limit] SearchOptionResponse limit
         * @property {number|null} [count] SearchOptionResponse count
         * @property {number|null} [total] SearchOptionResponse total
         */

        /**
         * Constructs a new SearchOptionResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchOptionResponse.
         * @implements ISearchOptionResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchOptionResponse=} [properties] Properties to set
         */
        function SearchOptionResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOptionResponse data.
         * @member {Array.<PlatformAdminApi.IOption>} data
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @instance
         */
        SearchOptionResponse.prototype.data = $util.emptyArray;

        /**
         * SearchOptionResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @instance
         */
        SearchOptionResponse.prototype.page = 0;

        /**
         * SearchOptionResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @instance
         */
        SearchOptionResponse.prototype.pageCount = 0;

        /**
         * SearchOptionResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @instance
         */
        SearchOptionResponse.prototype.limit = 0;

        /**
         * SearchOptionResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @instance
         */
        SearchOptionResponse.prototype.count = 0;

        /**
         * SearchOptionResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @instance
         */
        SearchOptionResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchOptionResponse message. Does not implicitly {@link PlatformAdminApi.SearchOptionResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @static
         * @param {PlatformAdminApi.ISearchOptionResponse} message SearchOptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOptionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Option.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchOptionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchOptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchOptionResponse} SearchOptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOptionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOptionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Option.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchOptionResponse;
    })();

    PlatformAdminApi.ElementService = (function() {

        /**
         * Constructs a new ElementService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ElementService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ElementService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ElementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ElementService;

        /**
         * Callback as used by {@link PlatformAdminApi.ElementService#search}.
         * @memberof PlatformAdminApi.ElementService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchElementResponse} [response] SearchElementResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ElementService
         * @instance
         * @param {PlatformAdminApi.ISearchElementRequest} request SearchElementRequest message or plain object
         * @param {PlatformAdminApi.ElementService.SearchCallback} callback Node-style callback called with the error, if any, and SearchElementResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ElementService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchElementRequest, $root.PlatformAdminApi.SearchElementResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.ElementService
         * @instance
         * @param {PlatformAdminApi.ISearchElementRequest} request SearchElementRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchElementResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ElementService#findById}.
         * @memberof PlatformAdminApi.ElementService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ElementResponse} [response] ElementResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ElementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ElementService.FindByIdCallback} callback Node-style callback called with the error, if any, and ElementResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ElementService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ElementResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ElementService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ElementResponse>} Promise
         * @variation 2
         */

        return ElementService;
    })();

    PlatformAdminApi.SearchElementRequest = (function() {

        /**
         * Properties of a SearchElementRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchElementRequest
         * @property {number|null} [page] SearchElementRequest page
         * @property {number|null} [limit] SearchElementRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchElementRequest sort
         * @property {PlatformAdminApi.SearchPresencePolygonRequest.IWhereFields|null} [where] SearchElementRequest where
         */

        /**
         * Constructs a new SearchElementRequest.
         * @memberof PlatformAdminApi
         * @classdesc Объект REQUEST для запроса на получение единиц
         * @implements ISearchElementRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchElementRequest=} [properties] Properties to set
         */
        function SearchElementRequest(properties) {
            this.sort = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchElementRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchElementRequest
         * @instance
         */
        SearchElementRequest.prototype.page = 0;

        /**
         * SearchElementRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchElementRequest
         * @instance
         */
        SearchElementRequest.prototype.limit = 0;

        /**
         * SearchElementRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchElementRequest
         * @instance
         */
        SearchElementRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchElementRequest where.
         * @member {PlatformAdminApi.SearchPresencePolygonRequest.IWhereFields|null|undefined} where
         * @memberof PlatformAdminApi.SearchElementRequest
         * @instance
         */
        SearchElementRequest.prototype.where = null;

        /**
         * Encodes the specified SearchElementRequest message. Does not implicitly {@link PlatformAdminApi.SearchElementRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchElementRequest
         * @static
         * @param {PlatformAdminApi.ISearchElementRequest} message SearchElementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchElementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && Object.hasOwnProperty.call(message, "where"))
                $root.PlatformAdminApi.SearchPresencePolygonRequest.WhereFields.encode(message.where, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchElementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchElementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchElementRequest} SearchElementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchElementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchElementRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.where = $root.PlatformAdminApi.SearchPresencePolygonRequest.WhereFields.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchElementRequest;
    })();

    PlatformAdminApi.OrderedTag = (function() {

        /**
         * Properties of an OrderedTag.
         * @memberof PlatformAdminApi
         * @interface IOrderedTag
         * @property {number|null} [order] OrderedTag order
         * @property {PlatformAdminApi.ITag|null} [tag] OrderedTag tag
         */

        /**
         * Constructs a new OrderedTag.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderedTag.
         * @implements IOrderedTag
         * @constructor
         * @param {PlatformAdminApi.IOrderedTag=} [properties] Properties to set
         */
        function OrderedTag(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderedTag order.
         * @member {number} order
         * @memberof PlatformAdminApi.OrderedTag
         * @instance
         */
        OrderedTag.prototype.order = 0;

        /**
         * OrderedTag tag.
         * @member {PlatformAdminApi.ITag|null|undefined} tag
         * @memberof PlatformAdminApi.OrderedTag
         * @instance
         */
        OrderedTag.prototype.tag = null;

        /**
         * Encodes the specified OrderedTag message. Does not implicitly {@link PlatformAdminApi.OrderedTag.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderedTag
         * @static
         * @param {PlatformAdminApi.IOrderedTag} message OrderedTag message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderedTag.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.order);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                $root.PlatformAdminApi.Tag.encode(message.tag, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderedTag message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderedTag
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderedTag} OrderedTag
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderedTag.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderedTag();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.order = reader.int32();
                    break;
                case 2:
                    message.tag = $root.PlatformAdminApi.Tag.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderedTag;
    })();

    PlatformAdminApi.Element = (function() {

        /**
         * Properties of an Element.
         * @memberof PlatformAdminApi
         * @interface IElement
         * @property {string|null} [id] Element id
         * @property {string|null} [createdAt] Element createdAt
         * @property {string|null} [updatedAt] Element updatedAt
         * @property {string|null} [deletedAt] Element deletedAt
         * @property {boolean|null} [isDeleted] Element isDeleted
         * @property {string|null} [name] Element name
         * @property {string|null} [title] Element title
         * @property {Array.<PlatformAdminApi.IOrderedTag>|null} [tags] Element tags
         * @property {Array.<PlatformAdminApi.IOption>|null} [options] Element options
         * @property {string|null} [shortDesc] Element shortDesc
         * @property {PlatformAdminApi.IPriceElement|null} [activePrice] Element activePrice
         */

        /**
         * Constructs a new Element.
         * @memberof PlatformAdminApi
         * @classdesc Represents an Element.
         * @implements IElement
         * @constructor
         * @param {PlatformAdminApi.IElement=} [properties] Properties to set
         */
        function Element(properties) {
            this.tags = [];
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Element id.
         * @member {string} id
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.id = "";

        /**
         * Element createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.createdAt = "";

        /**
         * Element updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.updatedAt = "";

        /**
         * Element deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.deletedAt = "";

        /**
         * Element isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.isDeleted = false;

        /**
         * Element name.
         * @member {string} name
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.name = "";

        /**
         * Element title.
         * @member {string} title
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.title = "";

        /**
         * Element tags.
         * @member {Array.<PlatformAdminApi.IOrderedTag>} tags
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.tags = $util.emptyArray;

        /**
         * Element options.
         * @member {Array.<PlatformAdminApi.IOption>} options
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.options = $util.emptyArray;

        /**
         * Element shortDesc.
         * @member {string} shortDesc
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.shortDesc = "";

        /**
         * Element activePrice.
         * @member {PlatformAdminApi.IPriceElement|null|undefined} activePrice
         * @memberof PlatformAdminApi.Element
         * @instance
         */
        Element.prototype.activePrice = null;

        /**
         * Encodes the specified Element message. Does not implicitly {@link PlatformAdminApi.Element.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Element
         * @static
         * @param {PlatformAdminApi.IElement} message Element message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Element.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.deletedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.title);
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.PlatformAdminApi.OrderedTag.encode(message.tags[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.Option.encode(message.options[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.shortDesc != null && Object.hasOwnProperty.call(message, "shortDesc"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.shortDesc);
            if (message.activePrice != null && Object.hasOwnProperty.call(message, "activePrice"))
                $root.PlatformAdminApi.PriceElement.encode(message.activePrice, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an Element message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Element
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Element} Element
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Element.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Element();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.deletedAt = reader.string();
                    break;
                case 5:
                    message.isDeleted = reader.bool();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.title = reader.string();
                    break;
                case 8:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.PlatformAdminApi.OrderedTag.decode(reader, reader.uint32()));
                    break;
                case 9:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.Option.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.shortDesc = reader.string();
                    break;
                case 11:
                    message.activePrice = $root.PlatformAdminApi.PriceElement.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Element;
    })();

    PlatformAdminApi.SearchElementResponse = (function() {

        /**
         * Properties of a SearchElementResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchElementResponse
         * @property {Array.<PlatformAdminApi.IElement>|null} [data] SearchElementResponse data
         * @property {number|null} [page] SearchElementResponse page
         * @property {number|null} [pageCount] SearchElementResponse pageCount
         * @property {number|null} [limit] SearchElementResponse limit
         * @property {number|null} [count] SearchElementResponse count
         * @property {number|null} [total] SearchElementResponse total
         */

        /**
         * Constructs a new SearchElementResponse.
         * @memberof PlatformAdminApi
         * @classdesc Объект RESPONSE для ответа
         * @implements ISearchElementResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchElementResponse=} [properties] Properties to set
         */
        function SearchElementResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchElementResponse data.
         * @member {Array.<PlatformAdminApi.IElement>} data
         * @memberof PlatformAdminApi.SearchElementResponse
         * @instance
         */
        SearchElementResponse.prototype.data = $util.emptyArray;

        /**
         * SearchElementResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchElementResponse
         * @instance
         */
        SearchElementResponse.prototype.page = 0;

        /**
         * SearchElementResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SearchElementResponse
         * @instance
         */
        SearchElementResponse.prototype.pageCount = 0;

        /**
         * SearchElementResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchElementResponse
         * @instance
         */
        SearchElementResponse.prototype.limit = 0;

        /**
         * SearchElementResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SearchElementResponse
         * @instance
         */
        SearchElementResponse.prototype.count = 0;

        /**
         * SearchElementResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchElementResponse
         * @instance
         */
        SearchElementResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchElementResponse message. Does not implicitly {@link PlatformAdminApi.SearchElementResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchElementResponse
         * @static
         * @param {PlatformAdminApi.ISearchElementResponse} message SearchElementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchElementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Element.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchElementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchElementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchElementResponse} SearchElementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchElementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchElementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Element.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchElementResponse;
    })();

    PlatformAdminApi.ElementResponse = (function() {

        /**
         * Properties of an ElementResponse.
         * @memberof PlatformAdminApi
         * @interface IElementResponse
         * @property {PlatformAdminApi.IElement|null} [data] ElementResponse data
         */

        /**
         * Constructs a new ElementResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an ElementResponse.
         * @implements IElementResponse
         * @constructor
         * @param {PlatformAdminApi.IElementResponse=} [properties] Properties to set
         */
        function ElementResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ElementResponse data.
         * @member {PlatformAdminApi.IElement|null|undefined} data
         * @memberof PlatformAdminApi.ElementResponse
         * @instance
         */
        ElementResponse.prototype.data = null;

        /**
         * Encodes the specified ElementResponse message. Does not implicitly {@link PlatformAdminApi.ElementResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ElementResponse
         * @static
         * @param {PlatformAdminApi.IElementResponse} message ElementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ElementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Element.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an ElementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ElementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ElementResponse} ElementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ElementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ElementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Element.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ElementResponse;
    })();

    /**
     * Type enum.
     * @name PlatformAdminApi.Type
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} TOKEN=1 TOKEN value
     * @property {number} PHONE=2 PHONE value
     * @property {number} EMAIL=3 EMAIL value
     * @property {number} USER=4 USER value
     */
    PlatformAdminApi.Type = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "TOKEN"] = 1;
        values[valuesById[2] = "PHONE"] = 2;
        values[valuesById[3] = "EMAIL"] = 3;
        values[valuesById[4] = "USER"] = 4;
        return values;
    })();

    PlatformAdminApi.NotificationService = (function() {

        /**
         * Constructs a new NotificationService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a NotificationService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function NotificationService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (NotificationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NotificationService;

        /**
         * Callback as used by {@link PlatformAdminApi.NotificationService#upsert}.
         * @memberof PlatformAdminApi.NotificationService
         * @typedef UpsertCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Notification} [response] Notification
         */

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.INotificationRequest} request NotificationRequest message or plain object
         * @param {PlatformAdminApi.NotificationService.UpsertCallback} callback Node-style callback called with the error, if any, and Notification
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NotificationService.prototype.upsert = function upsert(request, callback) {
            return this.rpcCall(upsert, $root.PlatformAdminApi.NotificationRequest, $root.PlatformAdminApi.Notification, request, callback);
        }, "name", { value: "Upsert" });

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.INotificationRequest} request NotificationRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Notification>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.NotificationService#search}.
         * @memberof PlatformAdminApi.NotificationService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchNotificationResponse} [response] SearchNotificationResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.ISearchNotificationRequest} request SearchNotificationRequest message or plain object
         * @param {PlatformAdminApi.NotificationService.SearchCallback} callback Node-style callback called with the error, if any, and SearchNotificationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NotificationService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchNotificationRequest, $root.PlatformAdminApi.SearchNotificationResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.ISearchNotificationRequest} request SearchNotificationRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchNotificationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.NotificationService#findById}.
         * @memberof PlatformAdminApi.NotificationService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Notification} [response] Notification
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.NotificationService.FindByIdCallback} callback Node-style callback called with the error, if any, and Notification
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NotificationService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Notification, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Notification>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.NotificationService#remove}.
         * @memberof PlatformAdminApi.NotificationService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Notification} [response] Notification
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.NotificationService.RemoveCallback} callback Node-style callback called with the error, if any, and Notification
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NotificationService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Notification, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Notification>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.NotificationService#restore}.
         * @memberof PlatformAdminApi.NotificationService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Notification} [response] Notification
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.NotificationService.RestoreCallback} callback Node-style callback called with the error, if any, and Notification
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NotificationService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Notification, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Notification>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.NotificationService#send}.
         * @memberof PlatformAdminApi.NotificationService
         * @typedef SendCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SendResponse} [response] SendResponse
         */

        /**
         * Calls Send.
         * @function send
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.INotificationRequest} request NotificationRequest message or plain object
         * @param {PlatformAdminApi.NotificationService.SendCallback} callback Node-style callback called with the error, if any, and SendResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NotificationService.prototype.send = function send(request, callback) {
            return this.rpcCall(send, $root.PlatformAdminApi.NotificationRequest, $root.PlatformAdminApi.SendResponse, request, callback);
        }, "name", { value: "Send" });

        /**
         * Calls Send.
         * @function send
         * @memberof PlatformAdminApi.NotificationService
         * @instance
         * @param {PlatformAdminApi.INotificationRequest} request NotificationRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SendResponse>} Promise
         * @variation 2
         */

        return NotificationService;
    })();

    PlatformAdminApi.NotificationRequest = (function() {

        /**
         * Properties of a NotificationRequest.
         * @memberof PlatformAdminApi
         * @interface INotificationRequest
         * @property {string|null} [id] NotificationRequest id
         * @property {Array.<PlatformAdminApi.IRecipient>|null} [recipients] NotificationRequest recipients
         * @property {Object.<string,string>|null} [payload] NotificationRequest payload
         */

        /**
         * Constructs a new NotificationRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a NotificationRequest.
         * @implements INotificationRequest
         * @constructor
         * @param {PlatformAdminApi.INotificationRequest=} [properties] Properties to set
         */
        function NotificationRequest(properties) {
            this.recipients = [];
            this.payload = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.id = "";

        /**
         * NotificationRequest recipients.
         * @member {Array.<PlatformAdminApi.IRecipient>} recipients
         * @memberof PlatformAdminApi.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.recipients = $util.emptyArray;

        /**
         * NotificationRequest payload.
         * @member {Object.<string,string>} payload
         * @memberof PlatformAdminApi.NotificationRequest
         * @instance
         */
        NotificationRequest.prototype.payload = $util.emptyObject;

        /**
         * Encodes the specified NotificationRequest message. Does not implicitly {@link PlatformAdminApi.NotificationRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.NotificationRequest
         * @static
         * @param {PlatformAdminApi.INotificationRequest} message NotificationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.recipients != null && message.recipients.length)
                for (var i = 0; i < message.recipients.length; ++i)
                    $root.PlatformAdminApi.Recipient.encode(message.recipients[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                for (var keys = Object.keys(message.payload), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.payload[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Decodes a NotificationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.NotificationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.NotificationRequest} NotificationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.NotificationRequest(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    if (!(message.recipients && message.recipients.length))
                        message.recipients = [];
                    message.recipients.push($root.PlatformAdminApi.Recipient.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (message.payload === $util.emptyObject)
                        message.payload = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.payload[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NotificationRequest;
    })();

    PlatformAdminApi.SearchNotificationRequest = (function() {

        /**
         * Properties of a SearchNotificationRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchNotificationRequest
         * @property {number|null} [page] SearchNotificationRequest page
         * @property {number|null} [limit] SearchNotificationRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchNotificationRequest sort
         * @property {Array.<PlatformAdminApi.SearchNotificationRequest.IWhereFields>|null} [where] SearchNotificationRequest where
         */

        /**
         * Constructs a new SearchNotificationRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchNotificationRequest.
         * @implements ISearchNotificationRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchNotificationRequest=} [properties] Properties to set
         */
        function SearchNotificationRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchNotificationRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchNotificationRequest
         * @instance
         */
        SearchNotificationRequest.prototype.page = 0;

        /**
         * SearchNotificationRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchNotificationRequest
         * @instance
         */
        SearchNotificationRequest.prototype.limit = 0;

        /**
         * SearchNotificationRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchNotificationRequest
         * @instance
         */
        SearchNotificationRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchNotificationRequest where.
         * @member {Array.<PlatformAdminApi.SearchNotificationRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchNotificationRequest
         * @instance
         */
        SearchNotificationRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchNotificationRequest message. Does not implicitly {@link PlatformAdminApi.SearchNotificationRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchNotificationRequest
         * @static
         * @param {PlatformAdminApi.ISearchNotificationRequest} message SearchNotificationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchNotificationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchNotificationRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchNotificationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchNotificationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchNotificationRequest} SearchNotificationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchNotificationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchNotificationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchNotificationRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchNotificationRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchNotificationRequest
             * @interface IWhereFields
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchNotificationRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchNotificationRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchNotificationRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchNotificationRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchNotificationRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchNotificationRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchNotificationRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchNotificationRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchNotificationRequest;
    })();

    PlatformAdminApi.SearchNotificationResponse = (function() {

        /**
         * Properties of a SearchNotificationResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchNotificationResponse
         * @property {number|null} [page] SearchNotificationResponse page
         * @property {number|null} [limit] SearchNotificationResponse limit
         * @property {number|null} [total] SearchNotificationResponse total
         * @property {Array.<PlatformAdminApi.INotification>|null} [data] SearchNotificationResponse data
         */

        /**
         * Constructs a new SearchNotificationResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchNotificationResponse.
         * @implements ISearchNotificationResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchNotificationResponse=} [properties] Properties to set
         */
        function SearchNotificationResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchNotificationResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchNotificationResponse
         * @instance
         */
        SearchNotificationResponse.prototype.page = 0;

        /**
         * SearchNotificationResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchNotificationResponse
         * @instance
         */
        SearchNotificationResponse.prototype.limit = 0;

        /**
         * SearchNotificationResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchNotificationResponse
         * @instance
         */
        SearchNotificationResponse.prototype.total = 0;

        /**
         * SearchNotificationResponse data.
         * @member {Array.<PlatformAdminApi.INotification>} data
         * @memberof PlatformAdminApi.SearchNotificationResponse
         * @instance
         */
        SearchNotificationResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified SearchNotificationResponse message. Does not implicitly {@link PlatformAdminApi.SearchNotificationResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchNotificationResponse
         * @static
         * @param {PlatformAdminApi.ISearchNotificationResponse} message SearchNotificationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchNotificationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.total);
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Notification.encode(message.data[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchNotificationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchNotificationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchNotificationResponse} SearchNotificationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchNotificationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchNotificationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    message.total = reader.int32();
                    break;
                case 4:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Notification.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchNotificationResponse;
    })();

    PlatformAdminApi.Recipient = (function() {

        /**
         * Properties of a Recipient.
         * @memberof PlatformAdminApi
         * @interface IRecipient
         * @property {PlatformAdminApi.Type|null} [type] Recipient type
         * @property {string|null} [id] Recipient id
         * @property {Object.<string,string>|null} [payload] Recipient payload
         */

        /**
         * Constructs a new Recipient.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Recipient.
         * @implements IRecipient
         * @constructor
         * @param {PlatformAdminApi.IRecipient=} [properties] Properties to set
         */
        function Recipient(properties) {
            this.payload = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Recipient type.
         * @member {PlatformAdminApi.Type} type
         * @memberof PlatformAdminApi.Recipient
         * @instance
         */
        Recipient.prototype.type = 0;

        /**
         * Recipient id.
         * @member {string} id
         * @memberof PlatformAdminApi.Recipient
         * @instance
         */
        Recipient.prototype.id = "";

        /**
         * Recipient payload.
         * @member {Object.<string,string>} payload
         * @memberof PlatformAdminApi.Recipient
         * @instance
         */
        Recipient.prototype.payload = $util.emptyObject;

        /**
         * Encodes the specified Recipient message. Does not implicitly {@link PlatformAdminApi.Recipient.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Recipient
         * @static
         * @param {PlatformAdminApi.IRecipient} message Recipient message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Recipient.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                for (var keys = Object.keys(message.payload), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.payload[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Decodes a Recipient message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Recipient
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Recipient} Recipient
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Recipient.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Recipient(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    if (message.payload === $util.emptyObject)
                        message.payload = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.payload[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Recipient;
    })();

    PlatformAdminApi.SendResponse = (function() {

        /**
         * Properties of a SendResponse.
         * @memberof PlatformAdminApi
         * @interface ISendResponse
         * @property {number|null} [status] SendResponse status
         * @property {Array.<string>|null} [ids] SendResponse ids
         * @property {string|null} [message] SendResponse message
         */

        /**
         * Constructs a new SendResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SendResponse.
         * @implements ISendResponse
         * @constructor
         * @param {PlatformAdminApi.ISendResponse=} [properties] Properties to set
         */
        function SendResponse(properties) {
            this.ids = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendResponse status.
         * @member {number} status
         * @memberof PlatformAdminApi.SendResponse
         * @instance
         */
        SendResponse.prototype.status = 0;

        /**
         * SendResponse ids.
         * @member {Array.<string>} ids
         * @memberof PlatformAdminApi.SendResponse
         * @instance
         */
        SendResponse.prototype.ids = $util.emptyArray;

        /**
         * SendResponse message.
         * @member {string} message
         * @memberof PlatformAdminApi.SendResponse
         * @instance
         */
        SendResponse.prototype.message = "";

        /**
         * Encodes the specified SendResponse message. Does not implicitly {@link PlatformAdminApi.SendResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SendResponse
         * @static
         * @param {PlatformAdminApi.ISendResponse} message SendResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.ids != null && message.ids.length)
                for (var i = 0; i < message.ids.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.ids[i]);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
            return writer;
        };

        /**
         * Decodes a SendResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SendResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SendResponse} SendResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SendResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    if (!(message.ids && message.ids.length))
                        message.ids = [];
                    message.ids.push(reader.string());
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendResponse;
    })();

    PlatformAdminApi.Notification = (function() {

        /**
         * Properties of a Notification.
         * @memberof PlatformAdminApi
         * @interface INotification
         * @property {string|null} [id] Notification id
         * @property {Array.<PlatformAdminApi.IRecipient>|null} [recipients] Notification recipients
         * @property {Object.<string,string>|null} [payload] Notification payload
         * @property {string|null} [createdAt] Notification createdAt
         * @property {string|null} [updatedAt] Notification updatedAt
         * @property {boolean|null} [isDeleted] Notification isDeleted
         * @property {string|null} [deletedAt] Notification deletedAt
         */

        /**
         * Constructs a new Notification.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Notification.
         * @implements INotification
         * @constructor
         * @param {PlatformAdminApi.INotification=} [properties] Properties to set
         */
        function Notification(properties) {
            this.recipients = [];
            this.payload = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Notification id.
         * @member {string} id
         * @memberof PlatformAdminApi.Notification
         * @instance
         */
        Notification.prototype.id = "";

        /**
         * Notification recipients.
         * @member {Array.<PlatformAdminApi.IRecipient>} recipients
         * @memberof PlatformAdminApi.Notification
         * @instance
         */
        Notification.prototype.recipients = $util.emptyArray;

        /**
         * Notification payload.
         * @member {Object.<string,string>} payload
         * @memberof PlatformAdminApi.Notification
         * @instance
         */
        Notification.prototype.payload = $util.emptyObject;

        /**
         * Notification createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Notification
         * @instance
         */
        Notification.prototype.createdAt = "";

        /**
         * Notification updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Notification
         * @instance
         */
        Notification.prototype.updatedAt = "";

        /**
         * Notification isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Notification
         * @instance
         */
        Notification.prototype.isDeleted = false;

        /**
         * Notification deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Notification
         * @instance
         */
        Notification.prototype.deletedAt = "";

        /**
         * Encodes the specified Notification message. Does not implicitly {@link PlatformAdminApi.Notification.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Notification
         * @static
         * @param {PlatformAdminApi.INotification} message Notification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Notification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.recipients != null && message.recipients.length)
                for (var i = 0; i < message.recipients.length; ++i)
                    $root.PlatformAdminApi.Recipient.encode(message.recipients[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                for (var keys = Object.keys(message.payload), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.payload[keys[i]]).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Notification message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Notification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Notification} Notification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Notification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Notification(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    if (!(message.recipients && message.recipients.length))
                        message.recipients = [];
                    message.recipients.push($root.PlatformAdminApi.Recipient.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (message.payload === $util.emptyObject)
                        message.payload = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.payload[key] = value;
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Notification;
    })();

    /**
     * Platform enum.
     * @name PlatformAdminApi.Platform
     * @enum {number}
     * @property {number} WEB=0 WEB value
     * @property {number} IOS=1 IOS value
     * @property {number} ANDROID=2 ANDROID value
     */
    PlatformAdminApi.Platform = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WEB"] = 0;
        values[valuesById[1] = "IOS"] = 1;
        values[valuesById[2] = "ANDROID"] = 2;
        return values;
    })();

    PlatformAdminApi.TokenService = (function() {

        /**
         * Constructs a new TokenService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TokenService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function TokenService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (TokenService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TokenService;

        /**
         * Callback as used by {@link PlatformAdminApi.TokenService#upsert}.
         * @memberof PlatformAdminApi.TokenService
         * @typedef UpsertCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Token} [response] Token
         */

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUpsertTokenRequest} request UpsertTokenRequest message or plain object
         * @param {PlatformAdminApi.TokenService.UpsertCallback} callback Node-style callback called with the error, if any, and Token
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TokenService.prototype.upsert = function upsert(request, callback) {
            return this.rpcCall(upsert, $root.PlatformAdminApi.UpsertTokenRequest, $root.PlatformAdminApi.Token, request, callback);
        }, "name", { value: "Upsert" });

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUpsertTokenRequest} request UpsertTokenRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Token>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TokenService#search}.
         * @memberof PlatformAdminApi.TokenService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchTokenResponse} [response] SearchTokenResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.ISearchTokenRequest} request SearchTokenRequest message or plain object
         * @param {PlatformAdminApi.TokenService.SearchCallback} callback Node-style callback called with the error, if any, and SearchTokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TokenService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchTokenRequest, $root.PlatformAdminApi.SearchTokenResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.ISearchTokenRequest} request SearchTokenRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchTokenResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TokenService#findById}.
         * @memberof PlatformAdminApi.TokenService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Token} [response] Token
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.TokenService.FindByIdCallback} callback Node-style callback called with the error, if any, and Token
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TokenService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Token, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Token>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TokenService#remove}.
         * @memberof PlatformAdminApi.TokenService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Token} [response] Token
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.TokenService.RemoveCallback} callback Node-style callback called with the error, if any, and Token
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TokenService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Token, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Token>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TokenService#restore}.
         * @memberof PlatformAdminApi.TokenService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Token} [response] Token
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.TokenService.RestoreCallback} callback Node-style callback called with the error, if any, and Token
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TokenService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Token, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.TokenService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Token>} Promise
         * @variation 2
         */

        return TokenService;
    })();

    PlatformAdminApi.UpsertTokenRequest = (function() {

        /**
         * Properties of an UpsertTokenRequest.
         * @memberof PlatformAdminApi
         * @interface IUpsertTokenRequest
         * @property {string|null} [token] UpsertTokenRequest token
         * @property {string|null} [userId] UpsertTokenRequest userId
         * @property {PlatformAdminApi.Platform|null} [platform] UpsertTokenRequest platform
         * @property {string|null} [appName] UpsertTokenRequest appName
         * @property {string|null} [appVersion] UpsertTokenRequest appVersion
         * @property {string|null} [deviceId] UpsertTokenRequest deviceId
         */

        /**
         * Constructs a new UpsertTokenRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpsertTokenRequest.
         * @implements IUpsertTokenRequest
         * @constructor
         * @param {PlatformAdminApi.IUpsertTokenRequest=} [properties] Properties to set
         */
        function UpsertTokenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertTokenRequest token.
         * @member {string} token
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @instance
         */
        UpsertTokenRequest.prototype.token = "";

        /**
         * UpsertTokenRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @instance
         */
        UpsertTokenRequest.prototype.userId = "";

        /**
         * UpsertTokenRequest platform.
         * @member {PlatformAdminApi.Platform} platform
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @instance
         */
        UpsertTokenRequest.prototype.platform = 0;

        /**
         * UpsertTokenRequest appName.
         * @member {string} appName
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @instance
         */
        UpsertTokenRequest.prototype.appName = "";

        /**
         * UpsertTokenRequest appVersion.
         * @member {string} appVersion
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @instance
         */
        UpsertTokenRequest.prototype.appVersion = "";

        /**
         * UpsertTokenRequest deviceId.
         * @member {string} deviceId
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @instance
         */
        UpsertTokenRequest.prototype.deviceId = "";

        /**
         * Encodes the specified UpsertTokenRequest message. Does not implicitly {@link PlatformAdminApi.UpsertTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @static
         * @param {PlatformAdminApi.IUpsertTokenRequest} message UpsertTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.platform);
            if (message.appName != null && Object.hasOwnProperty.call(message, "appName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.appName);
            if (message.appVersion != null && Object.hasOwnProperty.call(message, "appVersion"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appVersion);
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.deviceId);
            return writer;
        };

        /**
         * Decodes an UpsertTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpsertTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpsertTokenRequest} UpsertTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpsertTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.platform = reader.int32();
                    break;
                case 4:
                    message.appName = reader.string();
                    break;
                case 5:
                    message.appVersion = reader.string();
                    break;
                case 6:
                    message.deviceId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertTokenRequest;
    })();

    PlatformAdminApi.SearchTokenRequest = (function() {

        /**
         * Properties of a SearchTokenRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchTokenRequest
         * @property {number|null} [page] SearchTokenRequest page
         * @property {number|null} [limit] SearchTokenRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchTokenRequest sort
         * @property {Array.<PlatformAdminApi.SearchTokenRequest.IWhereFields>|null} [where] SearchTokenRequest where
         */

        /**
         * Constructs a new SearchTokenRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchTokenRequest.
         * @implements ISearchTokenRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchTokenRequest=} [properties] Properties to set
         */
        function SearchTokenRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchTokenRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchTokenRequest
         * @instance
         */
        SearchTokenRequest.prototype.page = 0;

        /**
         * SearchTokenRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchTokenRequest
         * @instance
         */
        SearchTokenRequest.prototype.limit = 0;

        /**
         * SearchTokenRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchTokenRequest
         * @instance
         */
        SearchTokenRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchTokenRequest where.
         * @member {Array.<PlatformAdminApi.SearchTokenRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchTokenRequest
         * @instance
         */
        SearchTokenRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchTokenRequest message. Does not implicitly {@link PlatformAdminApi.SearchTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchTokenRequest
         * @static
         * @param {PlatformAdminApi.ISearchTokenRequest} message SearchTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchTokenRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchTokenRequest} SearchTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchTokenRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchTokenRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchTokenRequest
             * @interface IWhereFields
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchTokenRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchTokenRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchTokenRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchTokenRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchTokenRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchTokenRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchTokenRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchTokenRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchTokenRequest;
    })();

    PlatformAdminApi.SearchTokenResponse = (function() {

        /**
         * Properties of a SearchTokenResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchTokenResponse
         * @property {number|null} [page] SearchTokenResponse page
         * @property {number|null} [limit] SearchTokenResponse limit
         * @property {number|null} [total] SearchTokenResponse total
         * @property {Array.<PlatformAdminApi.IToken>|null} [data] SearchTokenResponse data
         */

        /**
         * Constructs a new SearchTokenResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchTokenResponse.
         * @implements ISearchTokenResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchTokenResponse=} [properties] Properties to set
         */
        function SearchTokenResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchTokenResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchTokenResponse
         * @instance
         */
        SearchTokenResponse.prototype.page = 0;

        /**
         * SearchTokenResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchTokenResponse
         * @instance
         */
        SearchTokenResponse.prototype.limit = 0;

        /**
         * SearchTokenResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchTokenResponse
         * @instance
         */
        SearchTokenResponse.prototype.total = 0;

        /**
         * SearchTokenResponse data.
         * @member {Array.<PlatformAdminApi.IToken>} data
         * @memberof PlatformAdminApi.SearchTokenResponse
         * @instance
         */
        SearchTokenResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified SearchTokenResponse message. Does not implicitly {@link PlatformAdminApi.SearchTokenResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchTokenResponse
         * @static
         * @param {PlatformAdminApi.ISearchTokenResponse} message SearchTokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchTokenResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.total);
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Token.encode(message.data[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchTokenResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchTokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchTokenResponse} SearchTokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchTokenResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchTokenResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    message.total = reader.int32();
                    break;
                case 4:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Token.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchTokenResponse;
    })();

    PlatformAdminApi.Token = (function() {

        /**
         * Properties of a Token.
         * @memberof PlatformAdminApi
         * @interface IToken
         * @property {string|null} [id] Token id
         * @property {string|null} [token] Token token
         * @property {string|null} [userId] Token userId
         * @property {PlatformAdminApi.Platform|null} [platform] Token platform
         * @property {string|null} [appName] Token appName
         * @property {string|null} [appVersion] Token appVersion
         * @property {string|null} [deviceId] Token deviceId
         * @property {string|null} [createdAt] Token createdAt
         * @property {string|null} [updatedAt] Token updatedAt
         * @property {boolean|null} [isDeleted] Token isDeleted
         * @property {string|null} [deletedAt] Token deletedAt
         */

        /**
         * Constructs a new Token.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Token.
         * @implements IToken
         * @constructor
         * @param {PlatformAdminApi.IToken=} [properties] Properties to set
         */
        function Token(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Token id.
         * @member {string} id
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.id = "";

        /**
         * Token token.
         * @member {string} token
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.token = "";

        /**
         * Token userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.userId = "";

        /**
         * Token platform.
         * @member {PlatformAdminApi.Platform} platform
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.platform = 0;

        /**
         * Token appName.
         * @member {string} appName
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.appName = "";

        /**
         * Token appVersion.
         * @member {string} appVersion
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.appVersion = "";

        /**
         * Token deviceId.
         * @member {string} deviceId
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.deviceId = "";

        /**
         * Token createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.createdAt = "";

        /**
         * Token updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.updatedAt = "";

        /**
         * Token isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.isDeleted = false;

        /**
         * Token deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Token
         * @instance
         */
        Token.prototype.deletedAt = "";

        /**
         * Encodes the specified Token message. Does not implicitly {@link PlatformAdminApi.Token.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Token
         * @static
         * @param {PlatformAdminApi.IToken} message Token message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Token.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.platform);
            if (message.appName != null && Object.hasOwnProperty.call(message, "appName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.appName);
            if (message.appVersion != null && Object.hasOwnProperty.call(message, "appVersion"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.appVersion);
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deviceId);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Token message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Token
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Token} Token
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Token.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Token();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.userId = reader.string();
                    break;
                case 4:
                    message.platform = reader.int32();
                    break;
                case 5:
                    message.appName = reader.string();
                    break;
                case 6:
                    message.appVersion = reader.string();
                    break;
                case 7:
                    message.deviceId = reader.string();
                    break;
                case 8:
                    message.createdAt = reader.string();
                    break;
                case 9:
                    message.updatedAt = reader.string();
                    break;
                case 10:
                    message.isDeleted = reader.bool();
                    break;
                case 11:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Token;
    })();

    PlatformAdminApi.PromocodeService = (function() {

        /**
         * Constructs a new PromocodeService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PromocodeService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PromocodeService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PromocodeService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PromocodeService;

        /**
         * Callback as used by {@link PlatformAdminApi.PromocodeService#create}.
         * @memberof PlatformAdminApi.PromocodeService
         * @typedef CreateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PromocodeResponse} [response] PromocodeResponse
         */

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.ICreatePromocodeRequest} request CreatePromocodeRequest message or plain object
         * @param {PlatformAdminApi.PromocodeService.CreateCallback} callback Node-style callback called with the error, if any, and PromocodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PromocodeService.prototype.create = function create(request, callback) {
            return this.rpcCall(create, $root.PlatformAdminApi.CreatePromocodeRequest, $root.PlatformAdminApi.PromocodeResponse, request, callback);
        }, "name", { value: "Create" });

        /**
         * Calls Create.
         * @function create
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.ICreatePromocodeRequest} request CreatePromocodeRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PromocodeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PromocodeService#update}.
         * @memberof PlatformAdminApi.PromocodeService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PromocodeResponse} [response] PromocodeResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUpdatePromocodeRequest} request UpdatePromocodeRequest message or plain object
         * @param {PlatformAdminApi.PromocodeService.UpdateCallback} callback Node-style callback called with the error, if any, and PromocodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PromocodeService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.UpdatePromocodeRequest, $root.PlatformAdminApi.PromocodeResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUpdatePromocodeRequest} request UpdatePromocodeRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PromocodeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PromocodeService#search}.
         * @memberof PlatformAdminApi.PromocodeService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchPromocodeResponse} [response] SearchPromocodeResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.ISearchPromocodeRequest} request SearchPromocodeRequest message or plain object
         * @param {PlatformAdminApi.PromocodeService.SearchCallback} callback Node-style callback called with the error, if any, and SearchPromocodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PromocodeService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchPromocodeRequest, $root.PlatformAdminApi.SearchPromocodeResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.ISearchPromocodeRequest} request SearchPromocodeRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchPromocodeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PromocodeService#findById}.
         * @memberof PlatformAdminApi.PromocodeService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PromocodeResponse} [response] PromocodeResponse
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PromocodeService.FindByIdCallback} callback Node-style callback called with the error, if any, and PromocodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PromocodeService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PromocodeResponse, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PromocodeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PromocodeService#remove}.
         * @memberof PlatformAdminApi.PromocodeService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PromocodeResponse} [response] PromocodeResponse
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PromocodeService.RemoveCallback} callback Node-style callback called with the error, if any, and PromocodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PromocodeService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PromocodeResponse, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PromocodeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PromocodeService#restore}.
         * @memberof PlatformAdminApi.PromocodeService
         * @typedef RestoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PromocodeResponse} [response] PromocodeResponse
         */

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.PromocodeService.RestoreCallback} callback Node-style callback called with the error, if any, and PromocodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PromocodeService.prototype.restore = function restore(request, callback) {
            return this.rpcCall(restore, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.PromocodeResponse, request, callback);
        }, "name", { value: "Restore" });

        /**
         * Calls Restore.
         * @function restore
         * @memberof PlatformAdminApi.PromocodeService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PromocodeResponse>} Promise
         * @variation 2
         */

        return PromocodeService;
    })();

    /**
     * OperationType enum.
     * @name PlatformAdminApi.OperationType
     * @enum {number}
     * @property {number} OperationType_IN=1 OperationType_IN value
     * @property {number} OperationType_NIN=2 OperationType_NIN value
     * @property {number} OperationType_LT=3 OperationType_LT value
     * @property {number} OperationType_LTE=4 OperationType_LTE value
     * @property {number} OperationType_BTW=5 OperationType_BTW value
     * @property {number} OperationType_EQ=6 OperationType_EQ value
     * @property {number} OperationType_NEQ=7 OperationType_NEQ value
     * @property {number} OperationType_GT=8 OperationType_GT value
     * @property {number} OperationType_GTE=9 OperationType_GTE value
     */
    PlatformAdminApi.OperationType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "OperationType_IN"] = 1;
        values[valuesById[2] = "OperationType_NIN"] = 2;
        values[valuesById[3] = "OperationType_LT"] = 3;
        values[valuesById[4] = "OperationType_LTE"] = 4;
        values[valuesById[5] = "OperationType_BTW"] = 5;
        values[valuesById[6] = "OperationType_EQ"] = 6;
        values[valuesById[7] = "OperationType_NEQ"] = 7;
        values[valuesById[8] = "OperationType_GT"] = 8;
        values[valuesById[9] = "OperationType_GTE"] = 9;
        return values;
    })();

    /**
     * RestrictionType enum.
     * @name PlatformAdminApi.RestrictionType
     * @enum {number}
     * @property {number} RestrictionType_USER=1 RestrictionType_USER value
     * @property {number} RestrictionType_DATE=2 RestrictionType_DATE value
     * @property {number} RestrictionType_WEEK_DAY=3 RestrictionType_WEEK_DAY value
     * @property {number} RestrictionType_ORDER_COUNT=4 RestrictionType_ORDER_COUNT value
     * @property {number} RestrictionType_REGION=5 RestrictionType_REGION value
     * @property {number} RestrictionType_SERVICE_TYPE=6 RestrictionType_SERVICE_TYPE value
     * @property {number} RestrictionType_USAGE_COUNT_PER_USER=7 RestrictionType_USAGE_COUNT_PER_USER value
     * @property {number} RestrictionType_USER_IN_GROUP=8 RestrictionType_USER_IN_GROUP value
     */
    PlatformAdminApi.RestrictionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "RestrictionType_USER"] = 1;
        values[valuesById[2] = "RestrictionType_DATE"] = 2;
        values[valuesById[3] = "RestrictionType_WEEK_DAY"] = 3;
        values[valuesById[4] = "RestrictionType_ORDER_COUNT"] = 4;
        values[valuesById[5] = "RestrictionType_REGION"] = 5;
        values[valuesById[6] = "RestrictionType_SERVICE_TYPE"] = 6;
        values[valuesById[7] = "RestrictionType_USAGE_COUNT_PER_USER"] = 7;
        values[valuesById[8] = "RestrictionType_USER_IN_GROUP"] = 8;
        return values;
    })();

    /**
     * RewardType enum.
     * @name PlatformAdminApi.RewardType
     * @enum {number}
     * @property {number} RewardType_DISCOUNT_PERCENT=1 RewardType_DISCOUNT_PERCENT value
     * @property {number} RewardType_DISCOUNT_ABSOLUTE=2 RewardType_DISCOUNT_ABSOLUTE value
     * @property {number} RewardType_BONUS=3 RewardType_BONUS value
     * @property {number} RewardType_SERVICE=4 RewardType_SERVICE value
     */
    PlatformAdminApi.RewardType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "RewardType_DISCOUNT_PERCENT"] = 1;
        values[valuesById[2] = "RewardType_DISCOUNT_ABSOLUTE"] = 2;
        values[valuesById[3] = "RewardType_BONUS"] = 3;
        values[valuesById[4] = "RewardType_SERVICE"] = 4;
        return values;
    })();

    /**
     * LimitType enum.
     * @name PlatformAdminApi.LimitType
     * @enum {number}
     * @property {number} LimitType_USAGE_COUNT=1 LimitType_USAGE_COUNT value
     * @property {number} LimitType_ACTIVATION_COUNT=2 LimitType_ACTIVATION_COUNT value
     * @property {number} LimitType_USER_COUNT=3 LimitType_USER_COUNT value
     * @property {number} LimitType_DATE=4 LimitType_DATE value
     */
    PlatformAdminApi.LimitType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "LimitType_USAGE_COUNT"] = 1;
        values[valuesById[2] = "LimitType_ACTIVATION_COUNT"] = 2;
        values[valuesById[3] = "LimitType_USER_COUNT"] = 3;
        values[valuesById[4] = "LimitType_DATE"] = 4;
        return values;
    })();

    PlatformAdminApi.EditValueRestriction = (function() {

        /**
         * Properties of an EditValueRestriction.
         * @memberof PlatformAdminApi
         * @interface IEditValueRestriction
         * @property {string|null} [value] EditValueRestriction value
         */

        /**
         * Constructs a new EditValueRestriction.
         * @memberof PlatformAdminApi
         * @classdesc Represents an EditValueRestriction.
         * @implements IEditValueRestriction
         * @constructor
         * @param {PlatformAdminApi.IEditValueRestriction=} [properties] Properties to set
         */
        function EditValueRestriction(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EditValueRestriction value.
         * @member {string} value
         * @memberof PlatformAdminApi.EditValueRestriction
         * @instance
         */
        EditValueRestriction.prototype.value = "";

        /**
         * Encodes the specified EditValueRestriction message. Does not implicitly {@link PlatformAdminApi.EditValueRestriction.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.EditValueRestriction
         * @static
         * @param {PlatformAdminApi.IEditValueRestriction} message EditValueRestriction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EditValueRestriction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            return writer;
        };

        /**
         * Decodes an EditValueRestriction message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.EditValueRestriction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.EditValueRestriction} EditValueRestriction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EditValueRestriction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.EditValueRestriction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return EditValueRestriction;
    })();

    PlatformAdminApi.Restriction = (function() {

        /**
         * Properties of a Restriction.
         * @memberof PlatformAdminApi
         * @interface IRestriction
         * @property {string|null} [id] Restriction id
         * @property {boolean|null} [isDeleted] Restriction isDeleted
         * @property {PlatformAdminApi.RestrictionType|null} [type] Restriction type
         * @property {Array.<string>|null} [values] Restriction values
         * @property {Array.<PlatformAdminApi.OperationType>|null} [operations] Restriction operations
         * @property {Array.<PlatformAdminApi.IEditValueRestriction>|null} [editValuesList] Restriction editValuesList
         */

        /**
         * Constructs a new Restriction.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Restriction.
         * @implements IRestriction
         * @constructor
         * @param {PlatformAdminApi.IRestriction=} [properties] Properties to set
         */
        function Restriction(properties) {
            this.values = [];
            this.operations = [];
            this.editValuesList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Restriction id.
         * @member {string} id
         * @memberof PlatformAdminApi.Restriction
         * @instance
         */
        Restriction.prototype.id = "";

        /**
         * Restriction isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Restriction
         * @instance
         */
        Restriction.prototype.isDeleted = false;

        /**
         * Restriction type.
         * @member {PlatformAdminApi.RestrictionType} type
         * @memberof PlatformAdminApi.Restriction
         * @instance
         */
        Restriction.prototype.type = 1;

        /**
         * Restriction values.
         * @member {Array.<string>} values
         * @memberof PlatformAdminApi.Restriction
         * @instance
         */
        Restriction.prototype.values = $util.emptyArray;

        /**
         * Restriction operations.
         * @member {Array.<PlatformAdminApi.OperationType>} operations
         * @memberof PlatformAdminApi.Restriction
         * @instance
         */
        Restriction.prototype.operations = $util.emptyArray;

        /**
         * Restriction editValuesList.
         * @member {Array.<PlatformAdminApi.IEditValueRestriction>} editValuesList
         * @memberof PlatformAdminApi.Restriction
         * @instance
         */
        Restriction.prototype.editValuesList = $util.emptyArray;

        /**
         * Encodes the specified Restriction message. Does not implicitly {@link PlatformAdminApi.Restriction.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Restriction
         * @static
         * @param {PlatformAdminApi.IRestriction} message Restriction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Restriction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.values != null && message.values.length)
                for (var i = 0; i < message.values.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.values[i]);
            if (message.operations != null && message.operations.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.operations.length; ++i)
                    writer.int32(message.operations[i]);
                writer.ldelim();
            }
            if (message.editValuesList != null && message.editValuesList.length)
                for (var i = 0; i < message.editValuesList.length; ++i)
                    $root.PlatformAdminApi.EditValueRestriction.encode(message.editValuesList[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Restriction message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Restriction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Restriction} Restriction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Restriction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Restriction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push(reader.string());
                    break;
                case 5:
                    if (!(message.operations && message.operations.length))
                        message.operations = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.operations.push(reader.int32());
                    } else
                        message.operations.push(reader.int32());
                    break;
                case 6:
                    if (!(message.editValuesList && message.editValuesList.length))
                        message.editValuesList = [];
                    message.editValuesList.push($root.PlatformAdminApi.EditValueRestriction.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Restriction;
    })();

    PlatformAdminApi.Limit = (function() {

        /**
         * Properties of a Limit.
         * @memberof PlatformAdminApi
         * @interface ILimit
         * @property {string|null} [id] Limit id
         * @property {boolean|null} [isDeleted] Limit isDeleted
         * @property {PlatformAdminApi.LimitType|null} [type] Limit type
         * @property {string|null} [value] Limit value
         * @property {string|null} [createdAt] Limit createdAt
         * @property {string|null} [updatedAt] Limit updatedAt
         * @property {string|null} [deletedAt] Limit deletedAt
         */

        /**
         * Constructs a new Limit.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Limit.
         * @implements ILimit
         * @constructor
         * @param {PlatformAdminApi.ILimit=} [properties] Properties to set
         */
        function Limit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Limit id.
         * @member {string} id
         * @memberof PlatformAdminApi.Limit
         * @instance
         */
        Limit.prototype.id = "";

        /**
         * Limit isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Limit
         * @instance
         */
        Limit.prototype.isDeleted = false;

        /**
         * Limit type.
         * @member {PlatformAdminApi.LimitType} type
         * @memberof PlatformAdminApi.Limit
         * @instance
         */
        Limit.prototype.type = 1;

        /**
         * Limit value.
         * @member {string} value
         * @memberof PlatformAdminApi.Limit
         * @instance
         */
        Limit.prototype.value = "";

        /**
         * Limit createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Limit
         * @instance
         */
        Limit.prototype.createdAt = "";

        /**
         * Limit updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Limit
         * @instance
         */
        Limit.prototype.updatedAt = "";

        /**
         * Limit deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Limit
         * @instance
         */
        Limit.prototype.deletedAt = "";

        /**
         * Encodes the specified Limit message. Does not implicitly {@link PlatformAdminApi.Limit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Limit
         * @static
         * @param {PlatformAdminApi.ILimit} message Limit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Limit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.value);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Limit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Limit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Limit} Limit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Limit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Limit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.value = reader.string();
                    break;
                case 5:
                    message.createdAt = reader.string();
                    break;
                case 6:
                    message.updatedAt = reader.string();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Limit;
    })();

    PlatformAdminApi.Reward = (function() {

        /**
         * Properties of a Reward.
         * @memberof PlatformAdminApi
         * @interface IReward
         * @property {string|null} [id] Reward id
         * @property {boolean|null} [isDeleted] Reward isDeleted
         * @property {PlatformAdminApi.RewardType|null} [type] Reward type
         * @property {string|null} [value] Reward value
         * @property {string|null} [createdAt] Reward createdAt
         * @property {string|null} [updatedAt] Reward updatedAt
         * @property {string|null} [deletedAt] Reward deletedAt
         */

        /**
         * Constructs a new Reward.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Reward.
         * @implements IReward
         * @constructor
         * @param {PlatformAdminApi.IReward=} [properties] Properties to set
         */
        function Reward(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Reward id.
         * @member {string} id
         * @memberof PlatformAdminApi.Reward
         * @instance
         */
        Reward.prototype.id = "";

        /**
         * Reward isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Reward
         * @instance
         */
        Reward.prototype.isDeleted = false;

        /**
         * Reward type.
         * @member {PlatformAdminApi.RewardType} type
         * @memberof PlatformAdminApi.Reward
         * @instance
         */
        Reward.prototype.type = 1;

        /**
         * Reward value.
         * @member {string} value
         * @memberof PlatformAdminApi.Reward
         * @instance
         */
        Reward.prototype.value = "";

        /**
         * Reward createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Reward
         * @instance
         */
        Reward.prototype.createdAt = "";

        /**
         * Reward updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Reward
         * @instance
         */
        Reward.prototype.updatedAt = "";

        /**
         * Reward deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Reward
         * @instance
         */
        Reward.prototype.deletedAt = "";

        /**
         * Encodes the specified Reward message. Does not implicitly {@link PlatformAdminApi.Reward.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Reward
         * @static
         * @param {PlatformAdminApi.IReward} message Reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.value);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Reward message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Reward} Reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Reward();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.value = reader.string();
                    break;
                case 5:
                    message.createdAt = reader.string();
                    break;
                case 6:
                    message.updatedAt = reader.string();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Reward;
    })();

    PlatformAdminApi.Promocode = (function() {

        /**
         * Properties of a Promocode.
         * @memberof PlatformAdminApi
         * @interface IPromocode
         * @property {string|null} [id] Promocode id
         * @property {string|null} [title] Promocode title
         * @property {string|null} [code] Promocode code
         * @property {Array.<PlatformAdminApi.IRestriction>|null} [restrictions] Promocode restrictions
         * @property {Array.<PlatformAdminApi.ILimit>|null} [limits] Promocode limits
         * @property {Array.<PlatformAdminApi.IReward>|null} [rewards] Promocode rewards
         * @property {string|null} [createdAt] Promocode createdAt
         * @property {string|null} [updatedAt] Promocode updatedAt
         * @property {boolean|null} [isDeleted] Promocode isDeleted
         * @property {string|null} [deletedAt] Promocode deletedAt
         */

        /**
         * Constructs a new Promocode.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Promocode.
         * @implements IPromocode
         * @constructor
         * @param {PlatformAdminApi.IPromocode=} [properties] Properties to set
         */
        function Promocode(properties) {
            this.restrictions = [];
            this.limits = [];
            this.rewards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Promocode id.
         * @member {string} id
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.id = "";

        /**
         * Promocode title.
         * @member {string} title
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.title = "";

        /**
         * Promocode code.
         * @member {string} code
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.code = "";

        /**
         * Promocode restrictions.
         * @member {Array.<PlatformAdminApi.IRestriction>} restrictions
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.restrictions = $util.emptyArray;

        /**
         * Promocode limits.
         * @member {Array.<PlatformAdminApi.ILimit>} limits
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.limits = $util.emptyArray;

        /**
         * Promocode rewards.
         * @member {Array.<PlatformAdminApi.IReward>} rewards
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.rewards = $util.emptyArray;

        /**
         * Promocode createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.createdAt = "";

        /**
         * Promocode updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.updatedAt = "";

        /**
         * Promocode isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.isDeleted = false;

        /**
         * Promocode deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.Promocode
         * @instance
         */
        Promocode.prototype.deletedAt = "";

        /**
         * Encodes the specified Promocode message. Does not implicitly {@link PlatformAdminApi.Promocode.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Promocode
         * @static
         * @param {PlatformAdminApi.IPromocode} message Promocode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Promocode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.code);
            if (message.restrictions != null && message.restrictions.length)
                for (var i = 0; i < message.restrictions.length; ++i)
                    $root.PlatformAdminApi.Restriction.encode(message.restrictions[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.limits != null && message.limits.length)
                for (var i = 0; i < message.limits.length; ++i)
                    $root.PlatformAdminApi.Limit.encode(message.limits[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.rewards != null && message.rewards.length)
                for (var i = 0; i < message.rewards.length; ++i)
                    $root.PlatformAdminApi.Reward.encode(message.rewards[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Promocode message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Promocode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Promocode} Promocode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Promocode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Promocode();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.code = reader.string();
                    break;
                case 4:
                    if (!(message.restrictions && message.restrictions.length))
                        message.restrictions = [];
                    message.restrictions.push($root.PlatformAdminApi.Restriction.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.limits && message.limits.length))
                        message.limits = [];
                    message.limits.push($root.PlatformAdminApi.Limit.decode(reader, reader.uint32()));
                    break;
                case 6:
                    if (!(message.rewards && message.rewards.length))
                        message.rewards = [];
                    message.rewards.push($root.PlatformAdminApi.Reward.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.createdAt = reader.string();
                    break;
                case 8:
                    message.updatedAt = reader.string();
                    break;
                case 9:
                    message.isDeleted = reader.bool();
                    break;
                case 10:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Promocode;
    })();

    PlatformAdminApi.CreatePromocodeRequest = (function() {

        /**
         * Properties of a CreatePromocodeRequest.
         * @memberof PlatformAdminApi
         * @interface ICreatePromocodeRequest
         * @property {string|null} [title] CreatePromocodeRequest title
         * @property {string|null} [code] CreatePromocodeRequest code
         * @property {Array.<PlatformAdminApi.IRestriction>|null} [restrictions] CreatePromocodeRequest restrictions
         * @property {Array.<PlatformAdminApi.ILimit>|null} [limits] CreatePromocodeRequest limits
         * @property {Array.<PlatformAdminApi.IReward>|null} [rewards] CreatePromocodeRequest rewards
         */

        /**
         * Constructs a new CreatePromocodeRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CreatePromocodeRequest.
         * @implements ICreatePromocodeRequest
         * @constructor
         * @param {PlatformAdminApi.ICreatePromocodeRequest=} [properties] Properties to set
         */
        function CreatePromocodeRequest(properties) {
            this.restrictions = [];
            this.limits = [];
            this.rewards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreatePromocodeRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.CreatePromocodeRequest
         * @instance
         */
        CreatePromocodeRequest.prototype.title = "";

        /**
         * CreatePromocodeRequest code.
         * @member {string} code
         * @memberof PlatformAdminApi.CreatePromocodeRequest
         * @instance
         */
        CreatePromocodeRequest.prototype.code = "";

        /**
         * CreatePromocodeRequest restrictions.
         * @member {Array.<PlatformAdminApi.IRestriction>} restrictions
         * @memberof PlatformAdminApi.CreatePromocodeRequest
         * @instance
         */
        CreatePromocodeRequest.prototype.restrictions = $util.emptyArray;

        /**
         * CreatePromocodeRequest limits.
         * @member {Array.<PlatformAdminApi.ILimit>} limits
         * @memberof PlatformAdminApi.CreatePromocodeRequest
         * @instance
         */
        CreatePromocodeRequest.prototype.limits = $util.emptyArray;

        /**
         * CreatePromocodeRequest rewards.
         * @member {Array.<PlatformAdminApi.IReward>} rewards
         * @memberof PlatformAdminApi.CreatePromocodeRequest
         * @instance
         */
        CreatePromocodeRequest.prototype.rewards = $util.emptyArray;

        /**
         * Encodes the specified CreatePromocodeRequest message. Does not implicitly {@link PlatformAdminApi.CreatePromocodeRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreatePromocodeRequest
         * @static
         * @param {PlatformAdminApi.ICreatePromocodeRequest} message CreatePromocodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreatePromocodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            if (message.restrictions != null && message.restrictions.length)
                for (var i = 0; i < message.restrictions.length; ++i)
                    $root.PlatformAdminApi.Restriction.encode(message.restrictions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.limits != null && message.limits.length)
                for (var i = 0; i < message.limits.length; ++i)
                    $root.PlatformAdminApi.Limit.encode(message.limits[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.rewards != null && message.rewards.length)
                for (var i = 0; i < message.rewards.length; ++i)
                    $root.PlatformAdminApi.Reward.encode(message.rewards[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CreatePromocodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreatePromocodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreatePromocodeRequest} CreatePromocodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreatePromocodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreatePromocodeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    if (!(message.restrictions && message.restrictions.length))
                        message.restrictions = [];
                    message.restrictions.push($root.PlatformAdminApi.Restriction.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.limits && message.limits.length))
                        message.limits = [];
                    message.limits.push($root.PlatformAdminApi.Limit.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.rewards && message.rewards.length))
                        message.rewards = [];
                    message.rewards.push($root.PlatformAdminApi.Reward.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreatePromocodeRequest;
    })();

    PlatformAdminApi.UpdatePromocodeRequest = (function() {

        /**
         * Properties of an UpdatePromocodeRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdatePromocodeRequest
         * @property {string|null} [id] UpdatePromocodeRequest id
         * @property {string|null} [title] UpdatePromocodeRequest title
         * @property {string|null} [code] UpdatePromocodeRequest code
         * @property {Array.<PlatformAdminApi.IRestriction>|null} [restrictions] UpdatePromocodeRequest restrictions
         * @property {Array.<PlatformAdminApi.ILimit>|null} [limits] UpdatePromocodeRequest limits
         * @property {Array.<PlatformAdminApi.IReward>|null} [rewards] UpdatePromocodeRequest rewards
         */

        /**
         * Constructs a new UpdatePromocodeRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpdatePromocodeRequest.
         * @implements IUpdatePromocodeRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdatePromocodeRequest=} [properties] Properties to set
         */
        function UpdatePromocodeRequest(properties) {
            this.restrictions = [];
            this.limits = [];
            this.rewards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePromocodeRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @instance
         */
        UpdatePromocodeRequest.prototype.id = "";

        /**
         * UpdatePromocodeRequest title.
         * @member {string} title
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @instance
         */
        UpdatePromocodeRequest.prototype.title = "";

        /**
         * UpdatePromocodeRequest code.
         * @member {string} code
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @instance
         */
        UpdatePromocodeRequest.prototype.code = "";

        /**
         * UpdatePromocodeRequest restrictions.
         * @member {Array.<PlatformAdminApi.IRestriction>} restrictions
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @instance
         */
        UpdatePromocodeRequest.prototype.restrictions = $util.emptyArray;

        /**
         * UpdatePromocodeRequest limits.
         * @member {Array.<PlatformAdminApi.ILimit>} limits
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @instance
         */
        UpdatePromocodeRequest.prototype.limits = $util.emptyArray;

        /**
         * UpdatePromocodeRequest rewards.
         * @member {Array.<PlatformAdminApi.IReward>} rewards
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @instance
         */
        UpdatePromocodeRequest.prototype.rewards = $util.emptyArray;

        /**
         * Encodes the specified UpdatePromocodeRequest message. Does not implicitly {@link PlatformAdminApi.UpdatePromocodeRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @static
         * @param {PlatformAdminApi.IUpdatePromocodeRequest} message UpdatePromocodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePromocodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.code);
            if (message.restrictions != null && message.restrictions.length)
                for (var i = 0; i < message.restrictions.length; ++i)
                    $root.PlatformAdminApi.Restriction.encode(message.restrictions[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.limits != null && message.limits.length)
                for (var i = 0; i < message.limits.length; ++i)
                    $root.PlatformAdminApi.Limit.encode(message.limits[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.rewards != null && message.rewards.length)
                for (var i = 0; i < message.rewards.length; ++i)
                    $root.PlatformAdminApi.Reward.encode(message.rewards[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdatePromocodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdatePromocodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdatePromocodeRequest} UpdatePromocodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePromocodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdatePromocodeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.code = reader.string();
                    break;
                case 4:
                    if (!(message.restrictions && message.restrictions.length))
                        message.restrictions = [];
                    message.restrictions.push($root.PlatformAdminApi.Restriction.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.limits && message.limits.length))
                        message.limits = [];
                    message.limits.push($root.PlatformAdminApi.Limit.decode(reader, reader.uint32()));
                    break;
                case 6:
                    if (!(message.rewards && message.rewards.length))
                        message.rewards = [];
                    message.rewards.push($root.PlatformAdminApi.Reward.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePromocodeRequest;
    })();

    PlatformAdminApi.SearchPromocodeRequest = (function() {

        /**
         * Properties of a SearchPromocodeRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchPromocodeRequest
         * @property {number|null} [page] SearchPromocodeRequest page
         * @property {number|null} [limit] SearchPromocodeRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] SearchPromocodeRequest sort
         * @property {Array.<PlatformAdminApi.SearchPromocodeRequest.IWhereFields>|null} [where] SearchPromocodeRequest where
         */

        /**
         * Constructs a new SearchPromocodeRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchPromocodeRequest.
         * @implements ISearchPromocodeRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchPromocodeRequest=} [properties] Properties to set
         */
        function SearchPromocodeRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPromocodeRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPromocodeRequest
         * @instance
         */
        SearchPromocodeRequest.prototype.page = 0;

        /**
         * SearchPromocodeRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPromocodeRequest
         * @instance
         */
        SearchPromocodeRequest.prototype.limit = 0;

        /**
         * SearchPromocodeRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.SearchPromocodeRequest
         * @instance
         */
        SearchPromocodeRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchPromocodeRequest where.
         * @member {Array.<PlatformAdminApi.SearchPromocodeRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.SearchPromocodeRequest
         * @instance
         */
        SearchPromocodeRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchPromocodeRequest message. Does not implicitly {@link PlatformAdminApi.SearchPromocodeRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPromocodeRequest
         * @static
         * @param {PlatformAdminApi.ISearchPromocodeRequest} message SearchPromocodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPromocodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.SearchPromocodeRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchPromocodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPromocodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPromocodeRequest} SearchPromocodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPromocodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPromocodeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.SearchPromocodeRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchPromocodeRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.SearchPromocodeRequest
             * @interface IWhereFields
             * @property {PlatformAdminApi.IUuidCondition|null} [id] WhereFields id
             * @property {PlatformAdminApi.IStringCondition|null} [title] WhereFields title
             * @property {PlatformAdminApi.INumberCondition|null} [usageCount] WhereFields usageCount
             * @property {PlatformAdminApi.INumberCondition|null} [activationCount] WhereFields activationCount
             * @property {PlatformAdminApi.IStringCondition|null} [code] WhereFields code
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.SearchPromocodeRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.SearchPromocodeRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields id.
             * @member {PlatformAdminApi.IUuidCondition|null|undefined} id
             * @memberof PlatformAdminApi.SearchPromocodeRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.id = null;

            /**
             * WhereFields title.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} title
             * @memberof PlatformAdminApi.SearchPromocodeRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.title = null;

            /**
             * WhereFields usageCount.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} usageCount
             * @memberof PlatformAdminApi.SearchPromocodeRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.usageCount = null;

            /**
             * WhereFields activationCount.
             * @member {PlatformAdminApi.INumberCondition|null|undefined} activationCount
             * @memberof PlatformAdminApi.SearchPromocodeRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.activationCount = null;

            /**
             * WhereFields code.
             * @member {PlatformAdminApi.IStringCondition|null|undefined} code
             * @memberof PlatformAdminApi.SearchPromocodeRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.code = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.SearchPromocodeRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SearchPromocodeRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.SearchPromocodeRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.PlatformAdminApi.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    $root.PlatformAdminApi.StringCondition.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.usageCount != null && Object.hasOwnProperty.call(message, "usageCount"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.usageCount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.activationCount != null && Object.hasOwnProperty.call(message, "activationCount"))
                    $root.PlatformAdminApi.NumberCondition.encode(message.activationCount, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    $root.PlatformAdminApi.StringCondition.encode(message.code, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SearchPromocodeRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SearchPromocodeRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPromocodeRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = $root.PlatformAdminApi.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.title = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.usageCount = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.activationCount = $root.PlatformAdminApi.NumberCondition.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.code = $root.PlatformAdminApi.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchPromocodeRequest;
    })();

    PlatformAdminApi.PromocodeResponse = (function() {

        /**
         * Properties of a PromocodeResponse.
         * @memberof PlatformAdminApi
         * @interface IPromocodeResponse
         * @property {PlatformAdminApi.IPromocode|null} [data] PromocodeResponse data
         */

        /**
         * Constructs a new PromocodeResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PromocodeResponse.
         * @implements IPromocodeResponse
         * @constructor
         * @param {PlatformAdminApi.IPromocodeResponse=} [properties] Properties to set
         */
        function PromocodeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PromocodeResponse data.
         * @member {PlatformAdminApi.IPromocode|null|undefined} data
         * @memberof PlatformAdminApi.PromocodeResponse
         * @instance
         */
        PromocodeResponse.prototype.data = null;

        /**
         * Encodes the specified PromocodeResponse message. Does not implicitly {@link PlatformAdminApi.PromocodeResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PromocodeResponse
         * @static
         * @param {PlatformAdminApi.IPromocodeResponse} message PromocodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PromocodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformAdminApi.Promocode.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PromocodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PromocodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PromocodeResponse} PromocodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PromocodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PromocodeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformAdminApi.Promocode.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PromocodeResponse;
    })();

    PlatformAdminApi.SearchPromocodeResponse = (function() {

        /**
         * Properties of a SearchPromocodeResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchPromocodeResponse
         * @property {Array.<PlatformAdminApi.IPromocode>|null} [data] SearchPromocodeResponse data
         * @property {number|null} [page] SearchPromocodeResponse page
         * @property {number|null} [pageCount] SearchPromocodeResponse pageCount
         * @property {number|null} [limit] SearchPromocodeResponse limit
         * @property {number|null} [count] SearchPromocodeResponse count
         * @property {number|null} [total] SearchPromocodeResponse total
         */

        /**
         * Constructs a new SearchPromocodeResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchPromocodeResponse.
         * @implements ISearchPromocodeResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchPromocodeResponse=} [properties] Properties to set
         */
        function SearchPromocodeResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPromocodeResponse data.
         * @member {Array.<PlatformAdminApi.IPromocode>} data
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.data = $util.emptyArray;

        /**
         * SearchPromocodeResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.page = 0;

        /**
         * SearchPromocodeResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.pageCount = 0;

        /**
         * SearchPromocodeResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.limit = 0;

        /**
         * SearchPromocodeResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.count = 0;

        /**
         * SearchPromocodeResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchPromocodeResponse message. Does not implicitly {@link PlatformAdminApi.SearchPromocodeResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @static
         * @param {PlatformAdminApi.ISearchPromocodeResponse} message SearchPromocodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPromocodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.Promocode.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchPromocodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchPromocodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchPromocodeResponse} SearchPromocodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPromocodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchPromocodeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.Promocode.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchPromocodeResponse;
    })();

    PlatformAdminApi.CustomerService = (function() {

        /**
         * Constructs a new CustomerService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CustomerService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CustomerService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CustomerService;

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#search}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomersList} [response] CustomersList
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerSearchRequest} request CustomerSearchRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.SearchCallback} callback Node-style callback called with the error, if any, and CustomersList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.CustomerSearchRequest, $root.PlatformAdminApi.CustomersList, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerSearchRequest} request CustomerSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomersList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#findById}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Customer} [response] Customer
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.FindByIdCallback} callback Node-style callback called with the error, if any, and Customer
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Customer, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Customer>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#update}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Customer} [response] Customer
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerEditFrontend} request CustomerEditFrontend message or plain object
         * @param {PlatformAdminApi.CustomerService.UpdateCallback} callback Node-style callback called with the error, if any, and Customer
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.CustomerEditFrontend, $root.PlatformAdminApi.Customer, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerEditFrontend} request CustomerEditFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.Customer>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#retreiveEditCustomer}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RetreiveEditCustomerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerEditFrontend} [response] CustomerEditFrontend
         */

        /**
         * Calls RetreiveEditCustomer.
         * @function retreiveEditCustomer
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RetreiveEditCustomerCallback} callback Node-style callback called with the error, if any, and CustomerEditFrontend
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.retreiveEditCustomer = function retreiveEditCustomer(request, callback) {
            return this.rpcCall(retreiveEditCustomer, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.CustomerEditFrontend, request, callback);
        }, "name", { value: "RetreiveEditCustomer" });

        /**
         * Calls RetreiveEditCustomer.
         * @function retreiveEditCustomer
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerEditFrontend>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#searchAddressesById}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef SearchAddressesByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressesList} [response] CustomerAddressesList
         */

        /**
         * Calls SearchAddressesById.
         * @function searchAddressesById
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.SearchAddressesByIdCallback} callback Node-style callback called with the error, if any, and CustomerAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.searchAddressesById = function searchAddressesById(request, callback) {
            return this.rpcCall(searchAddressesById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.CustomerAddressesList, request, callback);
        }, "name", { value: "SearchAddressesById" });

        /**
         * Calls SearchAddressesById.
         * @function searchAddressesById
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#createAddress}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef CreateAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressesList} [response] CustomerAddressesList
         */

        /**
         * Calls CreateAddress.
         * @function createAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerAddressCreateFrontend} request CustomerAddressCreateFrontend message or plain object
         * @param {PlatformAdminApi.CustomerService.CreateAddressCallback} callback Node-style callback called with the error, if any, and CustomerAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.createAddress = function createAddress(request, callback) {
            return this.rpcCall(createAddress, $root.PlatformAdminApi.CustomerAddressCreateFrontend, $root.PlatformAdminApi.CustomerAddressesList, request, callback);
        }, "name", { value: "CreateAddress" });

        /**
         * Calls CreateAddress.
         * @function createAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerAddressCreateFrontend} request CustomerAddressCreateFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#updateAddress}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef UpdateAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressesList} [response] CustomerAddressesList
         */

        /**
         * Calls UpdateAddress.
         * @function updateAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerAddressUpdateFrontend} request CustomerAddressUpdateFrontend message or plain object
         * @param {PlatformAdminApi.CustomerService.UpdateAddressCallback} callback Node-style callback called with the error, if any, and CustomerAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.updateAddress = function updateAddress(request, callback) {
            return this.rpcCall(updateAddress, $root.PlatformAdminApi.CustomerAddressUpdateFrontend, $root.PlatformAdminApi.CustomerAddressesList, request, callback);
        }, "name", { value: "UpdateAddress" });

        /**
         * Calls UpdateAddress.
         * @function updateAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerAddressUpdateFrontend} request CustomerAddressUpdateFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#deleteAddress}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef DeleteAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressesList} [response] CustomerAddressesList
         */

        /**
         * Calls DeleteAddress.
         * @function deleteAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.DeleteAddressCallback} callback Node-style callback called with the error, if any, and CustomerAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.deleteAddress = function deleteAddress(request, callback) {
            return this.rpcCall(deleteAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.CustomerAddressesList, request, callback);
        }, "name", { value: "DeleteAddress" });

        /**
         * Calls DeleteAddress.
         * @function deleteAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#restoreAddress}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RestoreAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressesList} [response] CustomerAddressesList
         */

        /**
         * Calls RestoreAddress.
         * @function restoreAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RestoreAddressCallback} callback Node-style callback called with the error, if any, and CustomerAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.restoreAddress = function restoreAddress(request, callback) {
            return this.rpcCall(restoreAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.CustomerAddressesList, request, callback);
        }, "name", { value: "RestoreAddress" });

        /**
         * Calls RestoreAddress.
         * @function restoreAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#retrieveEditAddress}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RetrieveEditAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressEditForm} [response] CustomerAddressEditForm
         */

        /**
         * Calls RetrieveEditAddress.
         * @function retrieveEditAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RetrieveEditAddressCallback} callback Node-style callback called with the error, if any, and CustomerAddressEditForm
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.retrieveEditAddress = function retrieveEditAddress(request, callback) {
            return this.rpcCall(retrieveEditAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.CustomerAddressEditForm, request, callback);
        }, "name", { value: "RetrieveEditAddress" });

        /**
         * Calls RetrieveEditAddress.
         * @function retrieveEditAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressEditForm>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#retrieveViewAddress}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RetrieveViewAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewAddress} [response] CustomerViewAddress
         */

        /**
         * Calls RetrieveViewAddress.
         * @function retrieveViewAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RetrieveViewAddressCallback} callback Node-style callback called with the error, if any, and CustomerViewAddress
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.retrieveViewAddress = function retrieveViewAddress(request, callback) {
            return this.rpcCall(retrieveViewAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.CustomerViewAddress, request, callback);
        }, "name", { value: "RetrieveViewAddress" });

        /**
         * Calls RetrieveViewAddress.
         * @function retrieveViewAddress
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewAddress>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#blockProfile}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef BlockProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Customer} [response] Customer
         */

        /**
         * Calls BlockProfile.
         * @function blockProfile
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IBlockCustomerRequest} request BlockCustomerRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.BlockProfileCallback} callback Node-style callback called with the error, if any, and Customer
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.blockProfile = function blockProfile(request, callback) {
            return this.rpcCall(blockProfile, $root.PlatformAdminApi.BlockCustomerRequest, $root.PlatformAdminApi.Customer, request, callback);
        }, "name", { value: "BlockProfile" });

        /**
         * Calls BlockProfile.
         * @function blockProfile
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IBlockCustomerRequest} request BlockCustomerRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Customer>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#unblockProfile}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef UnblockProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Customer} [response] Customer
         */

        /**
         * Calls UnblockProfile.
         * @function unblockProfile
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUnblockCustomerRequest} request UnblockCustomerRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.UnblockProfileCallback} callback Node-style callback called with the error, if any, and Customer
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.unblockProfile = function unblockProfile(request, callback) {
            return this.rpcCall(unblockProfile, $root.PlatformAdminApi.UnblockCustomerRequest, $root.PlatformAdminApi.Customer, request, callback);
        }, "name", { value: "UnblockProfile" });

        /**
         * Calls UnblockProfile.
         * @function unblockProfile
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUnblockCustomerRequest} request UnblockCustomerRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Customer>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#searchCustomerBlockingReasons}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef SearchCustomerBlockingReasonsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerBlockingReasonResponse} [response] CustomerBlockingReasonResponse
         */

        /**
         * Calls SearchCustomerBlockingReasons.
         * @function searchCustomerBlockingReasons
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerBlockingReasonRequest} request CustomerBlockingReasonRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.SearchCustomerBlockingReasonsCallback} callback Node-style callback called with the error, if any, and CustomerBlockingReasonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.searchCustomerBlockingReasons = function searchCustomerBlockingReasons(request, callback) {
            return this.rpcCall(searchCustomerBlockingReasons, $root.PlatformAdminApi.CustomerBlockingReasonRequest, $root.PlatformAdminApi.CustomerBlockingReasonResponse, request, callback);
        }, "name", { value: "SearchCustomerBlockingReasons" });

        /**
         * Calls SearchCustomerBlockingReasons.
         * @function searchCustomerBlockingReasons
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerBlockingReasonRequest} request CustomerBlockingReasonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerBlockingReasonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#remove}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RemoveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Customer} [response] Customer
         */

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RemoveCallback} callback Node-style callback called with the error, if any, and Customer
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.remove = function remove(request, callback) {
            return this.rpcCall(remove, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.Customer, request, callback);
        }, "name", { value: "Remove" });

        /**
         * Calls Remove.
         * @function remove
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Customer>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#searchCustomerContacts}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef SearchCustomerContactsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerContactResponse} [response] CustomerContactResponse
         */

        /**
         * Calls SearchCustomerContacts.
         * @function searchCustomerContacts
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.SearchCustomerContactsCallback} callback Node-style callback called with the error, if any, and CustomerContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.searchCustomerContacts = function searchCustomerContacts(request, callback) {
            return this.rpcCall(searchCustomerContacts, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.CustomerContactResponse, request, callback);
        }, "name", { value: "SearchCustomerContacts" });

        /**
         * Calls SearchCustomerContacts.
         * @function searchCustomerContacts
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#retrieveEditCustomerContact}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RetrieveEditCustomerContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerContactResponse} [response] CustomerContactResponse
         */

        /**
         * Calls RetrieveEditCustomerContact.
         * @function retrieveEditCustomerContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IFindCustomerContactByIdRequest} request FindCustomerContactByIdRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RetrieveEditCustomerContactCallback} callback Node-style callback called with the error, if any, and CustomerContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.retrieveEditCustomerContact = function retrieveEditCustomerContact(request, callback) {
            return this.rpcCall(retrieveEditCustomerContact, $root.PlatformAdminApi.FindCustomerContactByIdRequest, $root.PlatformAdminApi.CustomerContactResponse, request, callback);
        }, "name", { value: "RetrieveEditCustomerContact" });

        /**
         * Calls RetrieveEditCustomerContact.
         * @function retrieveEditCustomerContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IFindCustomerContactByIdRequest} request FindCustomerContactByIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#updateContact}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef UpdateContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerContactResponse} [response] CustomerContactResponse
         */

        /**
         * Calls UpdateContact.
         * @function updateContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerContactUpdateRequest} request CustomerContactUpdateRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.UpdateContactCallback} callback Node-style callback called with the error, if any, and CustomerContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.updateContact = function updateContact(request, callback) {
            return this.rpcCall(updateContact, $root.PlatformAdminApi.CustomerContactUpdateRequest, $root.PlatformAdminApi.CustomerContactResponse, request, callback);
        }, "name", { value: "UpdateContact" });

        /**
         * Calls UpdateContact.
         * @function updateContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerContactUpdateRequest} request CustomerContactUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#setContactMain}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef SetContactMainCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerContactResponse} [response] CustomerContactResponse
         */

        /**
         * Calls SetContactMain.
         * @function setContactMain
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ISetContactMainRequest} request SetContactMainRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.SetContactMainCallback} callback Node-style callback called with the error, if any, and CustomerContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.setContactMain = function setContactMain(request, callback) {
            return this.rpcCall(setContactMain, $root.PlatformAdminApi.SetContactMainRequest, $root.PlatformAdminApi.CustomerContactResponse, request, callback);
        }, "name", { value: "SetContactMain" });

        /**
         * Calls SetContactMain.
         * @function setContactMain
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ISetContactMainRequest} request SetContactMainRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#deleteContact}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef DeleteContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerContactResponse} [response] CustomerContactResponse
         */

        /**
         * Calls DeleteContact.
         * @function deleteContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerContactDeleteRequest} request CustomerContactDeleteRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.DeleteContactCallback} callback Node-style callback called with the error, if any, and CustomerContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.deleteContact = function deleteContact(request, callback) {
            return this.rpcCall(deleteContact, $root.PlatformAdminApi.CustomerContactDeleteRequest, $root.PlatformAdminApi.CustomerContactResponse, request, callback);
        }, "name", { value: "DeleteContact" });

        /**
         * Calls DeleteContact.
         * @function deleteContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerContactDeleteRequest} request CustomerContactDeleteRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#createContact}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef CreateContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerContactResponse} [response] CustomerContactResponse
         */

        /**
         * Calls CreateContact.
         * @function createContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerContactCreateRequest} request CustomerContactCreateRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.CreateContactCallback} callback Node-style callback called with the error, if any, and CustomerContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.createContact = function createContact(request, callback) {
            return this.rpcCall(createContact, $root.PlatformAdminApi.CustomerContactCreateRequest, $root.PlatformAdminApi.CustomerContactResponse, request, callback);
        }, "name", { value: "CreateContact" });

        /**
         * Calls CreateContact.
         * @function createContact
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICustomerContactCreateRequest} request CustomerContactCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#searchTenant}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef SearchTenantCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantSearchResponse} [response] TenantSearchResponse
         */

        /**
         * Calls SearchTenant.
         * @function searchTenant
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ITenantSearchRequestFrontend} request TenantSearchRequestFrontend message or plain object
         * @param {PlatformAdminApi.CustomerService.SearchTenantCallback} callback Node-style callback called with the error, if any, and TenantSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.searchTenant = function searchTenant(request, callback) {
            return this.rpcCall(searchTenant, $root.PlatformAdminApi.TenantSearchRequestFrontend, $root.PlatformAdminApi.TenantSearchResponse, request, callback);
        }, "name", { value: "SearchTenant" });

        /**
         * Calls SearchTenant.
         * @function searchTenant
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ITenantSearchRequestFrontend} request TenantSearchRequestFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.TenantSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#attachCard}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef AttachCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Empty} [response] Empty
         */

        /**
         * Calls AttachCard.
         * @function attachCard
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IAttachCardRequest} request AttachCardRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.AttachCardCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.attachCard = function attachCard(request, callback) {
            return this.rpcCall(attachCard, $root.PlatformAdminApi.AttachCardRequest, $root.PlatformAdminApi.Empty, request, callback);
        }, "name", { value: "AttachCard" });

        /**
         * Calls AttachCard.
         * @function attachCard
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IAttachCardRequest} request AttachCardRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#getCardsList}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef GetCardsListCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CardsListFrontend} [response] CardsListFrontend
         */

        /**
         * Calls GetCardsList.
         * @function getCardsList
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICardsListRequest} request CardsListRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.GetCardsListCallback} callback Node-style callback called with the error, if any, and CardsListFrontend
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.getCardsList = function getCardsList(request, callback) {
            return this.rpcCall(getCardsList, $root.PlatformAdminApi.CardsListRequest, $root.PlatformAdminApi.CardsListFrontend, request, callback);
        }, "name", { value: "GetCardsList" });

        /**
         * Calls GetCardsList.
         * @function getCardsList
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICardsListRequest} request CardsListRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CardsListFrontend>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#getCardDetails}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef GetCardDetailsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CardViewResponse} [response] CardViewResponse
         */

        /**
         * Calls GetCardDetails.
         * @function getCardDetails
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICardDetailsRequest} request CardDetailsRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.GetCardDetailsCallback} callback Node-style callback called with the error, if any, and CardViewResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.getCardDetails = function getCardDetails(request, callback) {
            return this.rpcCall(getCardDetails, $root.PlatformAdminApi.CardDetailsRequest, $root.PlatformAdminApi.CardViewResponse, request, callback);
        }, "name", { value: "GetCardDetails" });

        /**
         * Calls GetCardDetails.
         * @function getCardDetails
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.ICardDetailsRequest} request CardDetailsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CardViewResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#removeCard}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RemoveCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RemoveCardResponse} [response] RemoveCardResponse
         */

        /**
         * Calls RemoveCard.
         * @function removeCard
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRemoveCardRequest} request RemoveCardRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RemoveCardCallback} callback Node-style callback called with the error, if any, and RemoveCardResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.removeCard = function removeCard(request, callback) {
            return this.rpcCall(removeCard, $root.PlatformAdminApi.RemoveCardRequest, $root.PlatformAdminApi.RemoveCardResponse, request, callback);
        }, "name", { value: "RemoveCard" });

        /**
         * Calls RemoveCard.
         * @function removeCard
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRemoveCardRequest} request RemoveCardRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RemoveCardResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#rootCard}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RootCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RootCardChangeResponse} [response] RootCardChangeResponse
         */

        /**
         * Calls RootCard.
         * @function rootCard
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRootCardRequest} request RootCardRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RootCardCallback} callback Node-style callback called with the error, if any, and RootCardChangeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.rootCard = function rootCard(request, callback) {
            return this.rpcCall(rootCard, $root.PlatformAdminApi.RootCardRequest, $root.PlatformAdminApi.RootCardChangeResponse, request, callback);
        }, "name", { value: "RootCard" });

        /**
         * Calls RootCard.
         * @function rootCard
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRootCardRequest} request RootCardRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RootCardChangeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#retrieveOrders}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RetrieveOrdersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerOrdersListResponse} [response] CustomerOrdersListResponse
         */

        /**
         * Calls RetrieveOrders.
         * @function retrieveOrders
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRetrieveCrmOrdersRequest} request RetrieveCrmOrdersRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RetrieveOrdersCallback} callback Node-style callback called with the error, if any, and CustomerOrdersListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.retrieveOrders = function retrieveOrders(request, callback) {
            return this.rpcCall(retrieveOrders, $root.PlatformAdminApi.RetrieveCrmOrdersRequest, $root.PlatformAdminApi.CustomerOrdersListResponse, request, callback);
        }, "name", { value: "RetrieveOrders" });

        /**
         * Calls RetrieveOrders.
         * @function retrieveOrders
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRetrieveCrmOrdersRequest} request RetrieveCrmOrdersRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerOrdersListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#retrieveCrmOrders}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RetrieveCrmOrdersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerOrdersListResponse} [response] CustomerOrdersListResponse
         */

        /**
         * Calls RetrieveCrmOrders.
         * @function retrieveCrmOrders
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRetrieveCrmOrdersRequest} request RetrieveCrmOrdersRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RetrieveCrmOrdersCallback} callback Node-style callback called with the error, if any, and CustomerOrdersListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.retrieveCrmOrders = function retrieveCrmOrders(request, callback) {
            return this.rpcCall(retrieveCrmOrders, $root.PlatformAdminApi.RetrieveCrmOrdersRequest, $root.PlatformAdminApi.CustomerOrdersListResponse, request, callback);
        }, "name", { value: "RetrieveCrmOrders" });

        /**
         * Calls RetrieveCrmOrders.
         * @function retrieveCrmOrders
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IRetrieveCrmOrdersRequest} request RetrieveCrmOrdersRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerOrdersListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#retrieveViewOrder}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef RetrieveViewOrderCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls RetrieveViewOrder.
         * @function retrieveViewOrder
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.RetrieveViewOrderCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.retrieveViewOrder = function retrieveViewOrder(request, callback) {
            return this.rpcCall(retrieveViewOrder, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "RetrieveViewOrder" });

        /**
         * Calls RetrieveViewOrder.
         * @function retrieveViewOrder
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#changeCommunicationChannels}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef ChangeCommunicationChannelsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ChangeCommunicationChannelsResponse} [response] ChangeCommunicationChannelsResponse
         */

        /**
         * Calls ChangeCommunicationChannels.
         * @function changeCommunicationChannels
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IChangeCommunicationChannelsRequest} request ChangeCommunicationChannelsRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.ChangeCommunicationChannelsCallback} callback Node-style callback called with the error, if any, and ChangeCommunicationChannelsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.changeCommunicationChannels = function changeCommunicationChannels(request, callback) {
            return this.rpcCall(changeCommunicationChannels, $root.PlatformAdminApi.ChangeCommunicationChannelsRequest, $root.PlatformAdminApi.ChangeCommunicationChannelsResponse, request, callback);
        }, "name", { value: "ChangeCommunicationChannels" });

        /**
         * Calls ChangeCommunicationChannels.
         * @function changeCommunicationChannels
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IChangeCommunicationChannelsRequest} request ChangeCommunicationChannelsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ChangeCommunicationChannelsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CustomerService#getPreferencesByUserId}.
         * @memberof PlatformAdminApi.CustomerService
         * @typedef GetPreferencesByUserIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FindUserPreferencesByIdResponse} [response] FindUserPreferencesByIdResponse
         */

        /**
         * Calls GetPreferencesByUserId.
         * @function getPreferencesByUserId
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest} request FindUserPreferencesByIdRequest message or plain object
         * @param {PlatformAdminApi.CustomerService.GetPreferencesByUserIdCallback} callback Node-style callback called with the error, if any, and FindUserPreferencesByIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.getPreferencesByUserId = function getPreferencesByUserId(request, callback) {
            return this.rpcCall(getPreferencesByUserId, $root.PlatformAdminApi.FindUserPreferencesByIdRequest, $root.PlatformAdminApi.FindUserPreferencesByIdResponse, request, callback);
        }, "name", { value: "GetPreferencesByUserId" });

        /**
         * Calls GetPreferencesByUserId.
         * @function getPreferencesByUserId
         * @memberof PlatformAdminApi.CustomerService
         * @instance
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest} request FindUserPreferencesByIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FindUserPreferencesByIdResponse>} Promise
         * @variation 2
         */

        return CustomerService;
    })();

    PlatformAdminApi.UserBlockInfo = (function() {

        /**
         * Properties of a UserBlockInfo.
         * @memberof PlatformAdminApi
         * @interface IUserBlockInfo
         * @property {string|null} [status] UserBlockInfo status
         * @property {string|null} [reason] UserBlockInfo reason
         * @property {string|null} [reasonDescription] UserBlockInfo reasonDescription
         * @property {string|null} [date] UserBlockInfo date
         */

        /**
         * Constructs a new UserBlockInfo.
         * @memberof PlatformAdminApi
         * @classdesc Represents a UserBlockInfo.
         * @implements IUserBlockInfo
         * @constructor
         * @param {PlatformAdminApi.IUserBlockInfo=} [properties] Properties to set
         */
        function UserBlockInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserBlockInfo status.
         * @member {string} status
         * @memberof PlatformAdminApi.UserBlockInfo
         * @instance
         */
        UserBlockInfo.prototype.status = "";

        /**
         * UserBlockInfo reason.
         * @member {string} reason
         * @memberof PlatformAdminApi.UserBlockInfo
         * @instance
         */
        UserBlockInfo.prototype.reason = "";

        /**
         * UserBlockInfo reasonDescription.
         * @member {string} reasonDescription
         * @memberof PlatformAdminApi.UserBlockInfo
         * @instance
         */
        UserBlockInfo.prototype.reasonDescription = "";

        /**
         * UserBlockInfo date.
         * @member {string} date
         * @memberof PlatformAdminApi.UserBlockInfo
         * @instance
         */
        UserBlockInfo.prototype.date = "";

        /**
         * Encodes the specified UserBlockInfo message. Does not implicitly {@link PlatformAdminApi.UserBlockInfo.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UserBlockInfo
         * @static
         * @param {PlatformAdminApi.IUserBlockInfo} message UserBlockInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserBlockInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
            if (message.reasonDescription != null && Object.hasOwnProperty.call(message, "reasonDescription"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reasonDescription);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.date);
            return writer;
        };

        /**
         * Decodes a UserBlockInfo message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UserBlockInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UserBlockInfo} UserBlockInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserBlockInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UserBlockInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.string();
                    break;
                case 2:
                    message.reason = reader.string();
                    break;
                case 3:
                    message.reasonDescription = reader.string();
                    break;
                case 4:
                    message.date = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UserBlockInfo;
    })();

    PlatformAdminApi.TenantSearchRequestFrontend = (function() {

        /**
         * Properties of a TenantSearchRequestFrontend.
         * @memberof PlatformAdminApi
         * @interface ITenantSearchRequestFrontend
         * @property {string|null} [searchString] TenantSearchRequestFrontend searchString
         */

        /**
         * Constructs a new TenantSearchRequestFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TenantSearchRequestFrontend.
         * @implements ITenantSearchRequestFrontend
         * @constructor
         * @param {PlatformAdminApi.ITenantSearchRequestFrontend=} [properties] Properties to set
         */
        function TenantSearchRequestFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantSearchRequestFrontend searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.TenantSearchRequestFrontend
         * @instance
         */
        TenantSearchRequestFrontend.prototype.searchString = "";

        /**
         * Encodes the specified TenantSearchRequestFrontend message. Does not implicitly {@link PlatformAdminApi.TenantSearchRequestFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantSearchRequestFrontend
         * @static
         * @param {PlatformAdminApi.ITenantSearchRequestFrontend} message TenantSearchRequestFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantSearchRequestFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a TenantSearchRequestFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantSearchRequestFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantSearchRequestFrontend} TenantSearchRequestFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantSearchRequestFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantSearchRequestFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TenantSearchRequestFrontend;
    })();

    PlatformAdminApi.TenantsListItem = (function() {

        /**
         * Properties of a TenantsListItem.
         * @memberof PlatformAdminApi
         * @interface ITenantsListItem
         * @property {string|null} [id] TenantsListItem id
         * @property {string|null} [name] TenantsListItem name
         */

        /**
         * Constructs a new TenantsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TenantsListItem.
         * @implements ITenantsListItem
         * @constructor
         * @param {PlatformAdminApi.ITenantsListItem=} [properties] Properties to set
         */
        function TenantsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantsListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.TenantsListItem
         * @instance
         */
        TenantsListItem.prototype.id = "";

        /**
         * TenantsListItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.TenantsListItem
         * @instance
         */
        TenantsListItem.prototype.name = "";

        /**
         * Encodes the specified TenantsListItem message. Does not implicitly {@link PlatformAdminApi.TenantsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantsListItem
         * @static
         * @param {PlatformAdminApi.ITenantsListItem} message TenantsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a TenantsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantsListItem} TenantsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TenantsListItem;
    })();

    PlatformAdminApi.TenantsList = (function() {

        /**
         * Properties of a TenantsList.
         * @memberof PlatformAdminApi
         * @interface ITenantsList
         * @property {Array.<PlatformAdminApi.ITenantsListItem>|null} [data] TenantsList data
         * @property {number|null} [page] TenantsList page
         * @property {number|null} [pageCount] TenantsList pageCount
         * @property {number|null} [limit] TenantsList limit
         * @property {number|null} [count] TenantsList count
         * @property {number|null} [total] TenantsList total
         */

        /**
         * Constructs a new TenantsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TenantsList.
         * @implements ITenantsList
         * @constructor
         * @param {PlatformAdminApi.ITenantsList=} [properties] Properties to set
         */
        function TenantsList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TenantsList data.
         * @member {Array.<PlatformAdminApi.ITenantsListItem>} data
         * @memberof PlatformAdminApi.TenantsList
         * @instance
         */
        TenantsList.prototype.data = $util.emptyArray;

        /**
         * TenantsList page.
         * @member {number} page
         * @memberof PlatformAdminApi.TenantsList
         * @instance
         */
        TenantsList.prototype.page = 0;

        /**
         * TenantsList pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.TenantsList
         * @instance
         */
        TenantsList.prototype.pageCount = 0;

        /**
         * TenantsList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.TenantsList
         * @instance
         */
        TenantsList.prototype.limit = 0;

        /**
         * TenantsList count.
         * @member {number} count
         * @memberof PlatformAdminApi.TenantsList
         * @instance
         */
        TenantsList.prototype.count = 0;

        /**
         * TenantsList total.
         * @member {number} total
         * @memberof PlatformAdminApi.TenantsList
         * @instance
         */
        TenantsList.prototype.total = 0;

        /**
         * Encodes the specified TenantsList message. Does not implicitly {@link PlatformAdminApi.TenantsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TenantsList
         * @static
         * @param {PlatformAdminApi.ITenantsList} message TenantsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TenantsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.TenantsListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a TenantsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TenantsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TenantsList} TenantsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TenantsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TenantsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.TenantsListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TenantsList;
    })();

    PlatformAdminApi.AttachCardRequest = (function() {

        /**
         * Properties of an AttachCardRequest.
         * @memberof PlatformAdminApi
         * @interface IAttachCardRequest
         * @property {string|null} [userId] AttachCardRequest userId
         */

        /**
         * Constructs a new AttachCardRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AttachCardRequest.
         * @implements IAttachCardRequest
         * @constructor
         * @param {PlatformAdminApi.IAttachCardRequest=} [properties] Properties to set
         */
        function AttachCardRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttachCardRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.AttachCardRequest
         * @instance
         */
        AttachCardRequest.prototype.userId = "";

        /**
         * Encodes the specified AttachCardRequest message. Does not implicitly {@link PlatformAdminApi.AttachCardRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AttachCardRequest
         * @static
         * @param {PlatformAdminApi.IAttachCardRequest} message AttachCardRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachCardRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            return writer;
        };

        /**
         * Decodes an AttachCardRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AttachCardRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AttachCardRequest} AttachCardRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachCardRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AttachCardRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AttachCardRequest;
    })();

    PlatformAdminApi.CardsListFrontend = (function() {

        /**
         * Properties of a CardsListFrontend.
         * @memberof PlatformAdminApi
         * @interface ICardsListFrontend
         * @property {Array.<PlatformAdminApi.ICardInfo>|null} [cards] CardsListFrontend cards
         */

        /**
         * Constructs a new CardsListFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CardsListFrontend.
         * @implements ICardsListFrontend
         * @constructor
         * @param {PlatformAdminApi.ICardsListFrontend=} [properties] Properties to set
         */
        function CardsListFrontend(properties) {
            this.cards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CardsListFrontend cards.
         * @member {Array.<PlatformAdminApi.ICardInfo>} cards
         * @memberof PlatformAdminApi.CardsListFrontend
         * @instance
         */
        CardsListFrontend.prototype.cards = $util.emptyArray;

        /**
         * Encodes the specified CardsListFrontend message. Does not implicitly {@link PlatformAdminApi.CardsListFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CardsListFrontend
         * @static
         * @param {PlatformAdminApi.ICardsListFrontend} message CardsListFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CardsListFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cards != null && message.cards.length)
                for (var i = 0; i < message.cards.length; ++i)
                    $root.PlatformAdminApi.CardInfo.encode(message.cards[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CardsListFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CardsListFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CardsListFrontend} CardsListFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CardsListFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CardsListFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    message.cards.push($root.PlatformAdminApi.CardInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CardsListFrontend;
    })();

    PlatformAdminApi.CardsListRequest = (function() {

        /**
         * Properties of a CardsListRequest.
         * @memberof PlatformAdminApi
         * @interface ICardsListRequest
         * @property {string|null} [userId] CardsListRequest userId
         */

        /**
         * Constructs a new CardsListRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CardsListRequest.
         * @implements ICardsListRequest
         * @constructor
         * @param {PlatformAdminApi.ICardsListRequest=} [properties] Properties to set
         */
        function CardsListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CardsListRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.CardsListRequest
         * @instance
         */
        CardsListRequest.prototype.userId = "";

        /**
         * Encodes the specified CardsListRequest message. Does not implicitly {@link PlatformAdminApi.CardsListRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CardsListRequest
         * @static
         * @param {PlatformAdminApi.ICardsListRequest} message CardsListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CardsListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            return writer;
        };

        /**
         * Decodes a CardsListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CardsListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CardsListRequest} CardsListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CardsListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CardsListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CardsListRequest;
    })();

    PlatformAdminApi.CardInfo = (function() {

        /**
         * Properties of a CardInfo.
         * @memberof PlatformAdminApi
         * @interface ICardInfo
         * @property {string|null} [id] CardInfo id
         * @property {string|null} [paymentSystem] CardInfo paymentSystem
         * @property {string|null} [cardNumber] CardInfo cardNumber
         * @property {boolean|null} [isRoot] CardInfo isRoot
         * @property {string|null} [expirationDate] CardInfo expirationDate
         */

        /**
         * Constructs a new CardInfo.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CardInfo.
         * @implements ICardInfo
         * @constructor
         * @param {PlatformAdminApi.ICardInfo=} [properties] Properties to set
         */
        function CardInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CardInfo id.
         * @member {string} id
         * @memberof PlatformAdminApi.CardInfo
         * @instance
         */
        CardInfo.prototype.id = "";

        /**
         * CardInfo paymentSystem.
         * @member {string} paymentSystem
         * @memberof PlatformAdminApi.CardInfo
         * @instance
         */
        CardInfo.prototype.paymentSystem = "";

        /**
         * CardInfo cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformAdminApi.CardInfo
         * @instance
         */
        CardInfo.prototype.cardNumber = "";

        /**
         * CardInfo isRoot.
         * @member {boolean} isRoot
         * @memberof PlatformAdminApi.CardInfo
         * @instance
         */
        CardInfo.prototype.isRoot = false;

        /**
         * CardInfo expirationDate.
         * @member {string} expirationDate
         * @memberof PlatformAdminApi.CardInfo
         * @instance
         */
        CardInfo.prototype.expirationDate = "";

        /**
         * Encodes the specified CardInfo message. Does not implicitly {@link PlatformAdminApi.CardInfo.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CardInfo
         * @static
         * @param {PlatformAdminApi.ICardInfo} message CardInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CardInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.paymentSystem != null && Object.hasOwnProperty.call(message, "paymentSystem"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentSystem);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cardNumber);
            if (message.isRoot != null && Object.hasOwnProperty.call(message, "isRoot"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isRoot);
            if (message.expirationDate != null && Object.hasOwnProperty.call(message, "expirationDate"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.expirationDate);
            return writer;
        };

        /**
         * Decodes a CardInfo message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CardInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CardInfo} CardInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CardInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CardInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.paymentSystem = reader.string();
                    break;
                case 3:
                    message.cardNumber = reader.string();
                    break;
                case 4:
                    message.isRoot = reader.bool();
                    break;
                case 5:
                    message.expirationDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CardInfo;
    })();

    PlatformAdminApi.CardViewResponse = (function() {

        /**
         * Properties of a CardViewResponse.
         * @memberof PlatformAdminApi
         * @interface ICardViewResponse
         * @property {string|null} [id] CardViewResponse id
         * @property {string|null} [cardNumber] CardViewResponse cardNumber
         * @property {string|null} [expirationDate] CardViewResponse expirationDate
         */

        /**
         * Constructs a new CardViewResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CardViewResponse.
         * @implements ICardViewResponse
         * @constructor
         * @param {PlatformAdminApi.ICardViewResponse=} [properties] Properties to set
         */
        function CardViewResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CardViewResponse id.
         * @member {string} id
         * @memberof PlatformAdminApi.CardViewResponse
         * @instance
         */
        CardViewResponse.prototype.id = "";

        /**
         * CardViewResponse cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformAdminApi.CardViewResponse
         * @instance
         */
        CardViewResponse.prototype.cardNumber = "";

        /**
         * CardViewResponse expirationDate.
         * @member {string} expirationDate
         * @memberof PlatformAdminApi.CardViewResponse
         * @instance
         */
        CardViewResponse.prototype.expirationDate = "";

        /**
         * Encodes the specified CardViewResponse message. Does not implicitly {@link PlatformAdminApi.CardViewResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CardViewResponse
         * @static
         * @param {PlatformAdminApi.ICardViewResponse} message CardViewResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CardViewResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardNumber);
            if (message.expirationDate != null && Object.hasOwnProperty.call(message, "expirationDate"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.expirationDate);
            return writer;
        };

        /**
         * Decodes a CardViewResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CardViewResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CardViewResponse} CardViewResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CardViewResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CardViewResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.cardNumber = reader.string();
                    break;
                case 3:
                    message.expirationDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CardViewResponse;
    })();

    PlatformAdminApi.CardDetailsRequest = (function() {

        /**
         * Properties of a CardDetailsRequest.
         * @memberof PlatformAdminApi
         * @interface ICardDetailsRequest
         * @property {string|null} [cardId] CardDetailsRequest cardId
         * @property {string|null} [userId] CardDetailsRequest userId
         */

        /**
         * Constructs a new CardDetailsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CardDetailsRequest.
         * @implements ICardDetailsRequest
         * @constructor
         * @param {PlatformAdminApi.ICardDetailsRequest=} [properties] Properties to set
         */
        function CardDetailsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CardDetailsRequest cardId.
         * @member {string} cardId
         * @memberof PlatformAdminApi.CardDetailsRequest
         * @instance
         */
        CardDetailsRequest.prototype.cardId = "";

        /**
         * CardDetailsRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.CardDetailsRequest
         * @instance
         */
        CardDetailsRequest.prototype.userId = "";

        /**
         * Encodes the specified CardDetailsRequest message. Does not implicitly {@link PlatformAdminApi.CardDetailsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CardDetailsRequest
         * @static
         * @param {PlatformAdminApi.ICardDetailsRequest} message CardDetailsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CardDetailsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cardId != null && Object.hasOwnProperty.call(message, "cardId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cardId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            return writer;
        };

        /**
         * Decodes a CardDetailsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CardDetailsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CardDetailsRequest} CardDetailsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CardDetailsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CardDetailsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cardId = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CardDetailsRequest;
    })();

    PlatformAdminApi.RemoveCardRequest = (function() {

        /**
         * Properties of a RemoveCardRequest.
         * @memberof PlatformAdminApi
         * @interface IRemoveCardRequest
         * @property {string|null} [userId] RemoveCardRequest userId
         * @property {string|null} [cardId] RemoveCardRequest cardId
         */

        /**
         * Constructs a new RemoveCardRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RemoveCardRequest.
         * @implements IRemoveCardRequest
         * @constructor
         * @param {PlatformAdminApi.IRemoveCardRequest=} [properties] Properties to set
         */
        function RemoveCardRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveCardRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.RemoveCardRequest
         * @instance
         */
        RemoveCardRequest.prototype.userId = "";

        /**
         * RemoveCardRequest cardId.
         * @member {string} cardId
         * @memberof PlatformAdminApi.RemoveCardRequest
         * @instance
         */
        RemoveCardRequest.prototype.cardId = "";

        /**
         * Encodes the specified RemoveCardRequest message. Does not implicitly {@link PlatformAdminApi.RemoveCardRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RemoveCardRequest
         * @static
         * @param {PlatformAdminApi.IRemoveCardRequest} message RemoveCardRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveCardRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.cardId != null && Object.hasOwnProperty.call(message, "cardId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardId);
            return writer;
        };

        /**
         * Decodes a RemoveCardRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RemoveCardRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RemoveCardRequest} RemoveCardRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveCardRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RemoveCardRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.cardId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RemoveCardRequest;
    })();

    PlatformAdminApi.RemoveCardResponse = (function() {

        /**
         * Properties of a RemoveCardResponse.
         * @memberof PlatformAdminApi
         * @interface IRemoveCardResponse
         * @property {boolean|null} [success] RemoveCardResponse success
         */

        /**
         * Constructs a new RemoveCardResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RemoveCardResponse.
         * @implements IRemoveCardResponse
         * @constructor
         * @param {PlatformAdminApi.IRemoveCardResponse=} [properties] Properties to set
         */
        function RemoveCardResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveCardResponse success.
         * @member {boolean} success
         * @memberof PlatformAdminApi.RemoveCardResponse
         * @instance
         */
        RemoveCardResponse.prototype.success = false;

        /**
         * Encodes the specified RemoveCardResponse message. Does not implicitly {@link PlatformAdminApi.RemoveCardResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RemoveCardResponse
         * @static
         * @param {PlatformAdminApi.IRemoveCardResponse} message RemoveCardResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveCardResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            return writer;
        };

        /**
         * Decodes a RemoveCardResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RemoveCardResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RemoveCardResponse} RemoveCardResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveCardResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RemoveCardResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RemoveCardResponse;
    })();

    PlatformAdminApi.RootCardRequest = (function() {

        /**
         * Properties of a RootCardRequest.
         * @memberof PlatformAdminApi
         * @interface IRootCardRequest
         * @property {string|null} [userId] RootCardRequest userId
         * @property {string|null} [cardId] RootCardRequest cardId
         */

        /**
         * Constructs a new RootCardRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RootCardRequest.
         * @implements IRootCardRequest
         * @constructor
         * @param {PlatformAdminApi.IRootCardRequest=} [properties] Properties to set
         */
        function RootCardRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RootCardRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.RootCardRequest
         * @instance
         */
        RootCardRequest.prototype.userId = "";

        /**
         * RootCardRequest cardId.
         * @member {string} cardId
         * @memberof PlatformAdminApi.RootCardRequest
         * @instance
         */
        RootCardRequest.prototype.cardId = "";

        /**
         * Encodes the specified RootCardRequest message. Does not implicitly {@link PlatformAdminApi.RootCardRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RootCardRequest
         * @static
         * @param {PlatformAdminApi.IRootCardRequest} message RootCardRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RootCardRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.cardId != null && Object.hasOwnProperty.call(message, "cardId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardId);
            return writer;
        };

        /**
         * Decodes a RootCardRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RootCardRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RootCardRequest} RootCardRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RootCardRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RootCardRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.cardId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RootCardRequest;
    })();

    PlatformAdminApi.RootCardChangeResponse = (function() {

        /**
         * Properties of a RootCardChangeResponse.
         * @memberof PlatformAdminApi
         * @interface IRootCardChangeResponse
         * @property {boolean|null} [success] RootCardChangeResponse success
         */

        /**
         * Constructs a new RootCardChangeResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RootCardChangeResponse.
         * @implements IRootCardChangeResponse
         * @constructor
         * @param {PlatformAdminApi.IRootCardChangeResponse=} [properties] Properties to set
         */
        function RootCardChangeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RootCardChangeResponse success.
         * @member {boolean} success
         * @memberof PlatformAdminApi.RootCardChangeResponse
         * @instance
         */
        RootCardChangeResponse.prototype.success = false;

        /**
         * Encodes the specified RootCardChangeResponse message. Does not implicitly {@link PlatformAdminApi.RootCardChangeResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RootCardChangeResponse
         * @static
         * @param {PlatformAdminApi.IRootCardChangeResponse} message RootCardChangeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RootCardChangeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            return writer;
        };

        /**
         * Decodes a RootCardChangeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RootCardChangeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RootCardChangeResponse} RootCardChangeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RootCardChangeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RootCardChangeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RootCardChangeResponse;
    })();

    PlatformAdminApi.CustomerOrderOptionTag = (function() {

        /**
         * Properties of a CustomerOrderOptionTag.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderOptionTag
         * @property {string|null} [id] CustomerOrderOptionTag id
         * @property {boolean|null} [isDeleted] CustomerOrderOptionTag isDeleted
         * @property {string|null} [title] CustomerOrderOptionTag title
         * @property {string|null} [name] CustomerOrderOptionTag name
         */

        /**
         * Constructs a new CustomerOrderOptionTag.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderOptionTag.
         * @implements ICustomerOrderOptionTag
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderOptionTag=} [properties] Properties to set
         */
        function CustomerOrderOptionTag(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderOptionTag id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerOrderOptionTag
         * @instance
         */
        CustomerOrderOptionTag.prototype.id = "";

        /**
         * CustomerOrderOptionTag isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.CustomerOrderOptionTag
         * @instance
         */
        CustomerOrderOptionTag.prototype.isDeleted = false;

        /**
         * CustomerOrderOptionTag title.
         * @member {string} title
         * @memberof PlatformAdminApi.CustomerOrderOptionTag
         * @instance
         */
        CustomerOrderOptionTag.prototype.title = "";

        /**
         * CustomerOrderOptionTag name.
         * @member {string} name
         * @memberof PlatformAdminApi.CustomerOrderOptionTag
         * @instance
         */
        CustomerOrderOptionTag.prototype.name = "";

        /**
         * Encodes the specified CustomerOrderOptionTag message. Does not implicitly {@link PlatformAdminApi.CustomerOrderOptionTag.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderOptionTag
         * @static
         * @param {PlatformAdminApi.ICustomerOrderOptionTag} message CustomerOrderOptionTag message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderOptionTag.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            return writer;
        };

        /**
         * Decodes a CustomerOrderOptionTag message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderOptionTag
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderOptionTag} CustomerOrderOptionTag
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderOptionTag.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderOptionTag();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                case 4:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderOptionTag;
    })();

    PlatformAdminApi.CustomerOrderOptionUnit = (function() {

        /**
         * Properties of a CustomerOrderOptionUnit.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderOptionUnit
         * @property {string|null} [id] CustomerOrderOptionUnit id
         * @property {boolean|null} [isDeleted] CustomerOrderOptionUnit isDeleted
         * @property {string|null} [title] CustomerOrderOptionUnit title
         * @property {string|null} [name] CustomerOrderOptionUnit name
         * @property {string|null} [abbreviation] CustomerOrderOptionUnit abbreviation
         */

        /**
         * Constructs a new CustomerOrderOptionUnit.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderOptionUnit.
         * @implements ICustomerOrderOptionUnit
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderOptionUnit=} [properties] Properties to set
         */
        function CustomerOrderOptionUnit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderOptionUnit id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerOrderOptionUnit
         * @instance
         */
        CustomerOrderOptionUnit.prototype.id = "";

        /**
         * CustomerOrderOptionUnit isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.CustomerOrderOptionUnit
         * @instance
         */
        CustomerOrderOptionUnit.prototype.isDeleted = false;

        /**
         * CustomerOrderOptionUnit title.
         * @member {string} title
         * @memberof PlatformAdminApi.CustomerOrderOptionUnit
         * @instance
         */
        CustomerOrderOptionUnit.prototype.title = "";

        /**
         * CustomerOrderOptionUnit name.
         * @member {string} name
         * @memberof PlatformAdminApi.CustomerOrderOptionUnit
         * @instance
         */
        CustomerOrderOptionUnit.prototype.name = "";

        /**
         * CustomerOrderOptionUnit abbreviation.
         * @member {string} abbreviation
         * @memberof PlatformAdminApi.CustomerOrderOptionUnit
         * @instance
         */
        CustomerOrderOptionUnit.prototype.abbreviation = "";

        /**
         * Encodes the specified CustomerOrderOptionUnit message. Does not implicitly {@link PlatformAdminApi.CustomerOrderOptionUnit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderOptionUnit
         * @static
         * @param {PlatformAdminApi.ICustomerOrderOptionUnit} message CustomerOrderOptionUnit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderOptionUnit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.abbreviation != null && Object.hasOwnProperty.call(message, "abbreviation"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.abbreviation);
            return writer;
        };

        /**
         * Decodes a CustomerOrderOptionUnit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderOptionUnit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderOptionUnit} CustomerOrderOptionUnit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderOptionUnit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderOptionUnit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                case 4:
                    message.name = reader.string();
                    break;
                case 5:
                    message.abbreviation = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderOptionUnit;
    })();

    /**
     * OrderCartStatus enum.
     * @name PlatformAdminApi.OrderCartStatus
     * @enum {number}
     * @property {number} Created=0 Created value
     * @property {number} Confirmed=1 Confirmed value
     * @property {number} Completed=2 Completed value
     * @property {number} Canceled=3 Canceled value
     * @property {number} Unknown=4 Unknown value
     */
    PlatformAdminApi.OrderCartStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Created"] = 0;
        values[valuesById[1] = "Confirmed"] = 1;
        values[valuesById[2] = "Completed"] = 2;
        values[valuesById[3] = "Canceled"] = 3;
        values[valuesById[4] = "Unknown"] = 4;
        return values;
    })();

    /**
     * OrderStatusCategory enum.
     * @name PlatformAdminApi.OrderStatusCategory
     * @enum {number}
     * @property {number} Confirmed=0 Confirmed value
     * @property {number} InProgress=1 InProgress value
     * @property {number} Completed=2 Completed value
     * @property {number} Canceled=3 Canceled value
     * @property {number} Unknown=4 Unknown value
     * @property {number} Draft=5 Draft value
     * @property {number} OnCoordination=6 OnCoordination value
     * @property {number} WaitInPickPoint=7 WaitInPickPoint value
     */
    PlatformAdminApi.OrderStatusCategory = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Confirmed"] = 0;
        values[valuesById[1] = "InProgress"] = 1;
        values[valuesById[2] = "Completed"] = 2;
        values[valuesById[3] = "Canceled"] = 3;
        values[valuesById[4] = "Unknown"] = 4;
        values[valuesById[5] = "Draft"] = 5;
        values[valuesById[6] = "OnCoordination"] = 6;
        values[valuesById[7] = "WaitInPickPoint"] = 7;
        return values;
    })();

    /**
     * PaymentSystem enum.
     * @name PlatformAdminApi.PaymentSystem
     * @enum {number}
     * @property {number} AmericanExpress=0 AmericanExpress value
     * @property {number} DinersClub=1 DinersClub value
     * @property {number} Discover=2 Discover value
     * @property {number} Jcb=3 Jcb value
     * @property {number} Maestro=4 Maestro value
     * @property {number} MasterCard=5 MasterCard value
     * @property {number} Mir=6 Mir value
     * @property {number} Pro100=7 Pro100 value
     * @property {number} UnionPay=8 UnionPay value
     * @property {number} Visa=9 Visa value
     */
    PlatformAdminApi.PaymentSystem = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AmericanExpress"] = 0;
        values[valuesById[1] = "DinersClub"] = 1;
        values[valuesById[2] = "Discover"] = 2;
        values[valuesById[3] = "Jcb"] = 3;
        values[valuesById[4] = "Maestro"] = 4;
        values[valuesById[5] = "MasterCard"] = 5;
        values[valuesById[6] = "Mir"] = 6;
        values[valuesById[7] = "Pro100"] = 7;
        values[valuesById[8] = "UnionPay"] = 8;
        values[valuesById[9] = "Visa"] = 9;
        return values;
    })();

    /**
     * PaymentType enum.
     * @name PlatformAdminApi.PaymentType
     * @enum {number}
     * @property {number} Unknown=0 Unknown value
     * @property {number} Cash=1 Cash value
     * @property {number} Bonus=2 Bonus value
     * @property {number} Sms=3 Sms value
     * @property {number} ContractorCardLinked=4 ContractorCardLinked value
     * @property {number} InstallmentPlan=5 InstallmentPlan value
     * @property {number} Card=6 Card value
     * @property {number} SoftPos=7 SoftPos value
     * @property {number} Link=8 Link value
     */
    PlatformAdminApi.PaymentType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Unknown"] = 0;
        values[valuesById[1] = "Cash"] = 1;
        values[valuesById[2] = "Bonus"] = 2;
        values[valuesById[3] = "Sms"] = 3;
        values[valuesById[4] = "ContractorCardLinked"] = 4;
        values[valuesById[5] = "InstallmentPlan"] = 5;
        values[valuesById[6] = "Card"] = 6;
        values[valuesById[7] = "SoftPos"] = 7;
        values[valuesById[8] = "Link"] = 8;
        return values;
    })();

    /**
     * OrderAssignmentStatus enum.
     * @name PlatformAdminApi.OrderAssignmentStatus
     * @enum {number}
     * @property {number} Waiting=0 Waiting value
     * @property {number} Confirmed=1 Confirmed value
     * @property {number} Rejected=2 Rejected value
     * @property {number} AtWork=3 AtWork value
     * @property {number} Done=4 Done value
     */
    PlatformAdminApi.OrderAssignmentStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Waiting"] = 0;
        values[valuesById[1] = "Confirmed"] = 1;
        values[valuesById[2] = "Rejected"] = 2;
        values[valuesById[3] = "AtWork"] = 3;
        values[valuesById[4] = "Done"] = 4;
        return values;
    })();

    /**
     * OrderSystem enum.
     * @name PlatformAdminApi.OrderSystem
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} OMS=1 OMS value
     * @property {number} CRM=2 CRM value
     * @property {number} CLEANING_MONOLITH=3 CLEANING_MONOLITH value
     * @property {number} DRYCLEANING_MONOLITH=4 DRYCLEANING_MONOLITH value
     */
    PlatformAdminApi.OrderSystem = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "OMS"] = 1;
        values[valuesById[2] = "CRM"] = 2;
        values[valuesById[3] = "CLEANING_MONOLITH"] = 3;
        values[valuesById[4] = "DRYCLEANING_MONOLITH"] = 4;
        return values;
    })();

    PlatformAdminApi.ChangeCommunicationChannelsRequest = (function() {

        /**
         * Properties of a ChangeCommunicationChannelsRequest.
         * @memberof PlatformAdminApi
         * @interface IChangeCommunicationChannelsRequest
         * @property {string|null} [ssoId] ChangeCommunicationChannelsRequest ssoId
         * @property {Array.<PlatformAdminApi.ChangeCommunicationChannelsRequest.IChannel>|null} [channels] ChangeCommunicationChannelsRequest channels
         */

        /**
         * Constructs a new ChangeCommunicationChannelsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ChangeCommunicationChannelsRequest.
         * @implements IChangeCommunicationChannelsRequest
         * @constructor
         * @param {PlatformAdminApi.IChangeCommunicationChannelsRequest=} [properties] Properties to set
         */
        function ChangeCommunicationChannelsRequest(properties) {
            this.channels = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeCommunicationChannelsRequest ssoId.
         * @member {string} ssoId
         * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest
         * @instance
         */
        ChangeCommunicationChannelsRequest.prototype.ssoId = "";

        /**
         * ChangeCommunicationChannelsRequest channels.
         * @member {Array.<PlatformAdminApi.ChangeCommunicationChannelsRequest.IChannel>} channels
         * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest
         * @instance
         */
        ChangeCommunicationChannelsRequest.prototype.channels = $util.emptyArray;

        /**
         * Encodes the specified ChangeCommunicationChannelsRequest message. Does not implicitly {@link PlatformAdminApi.ChangeCommunicationChannelsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest
         * @static
         * @param {PlatformAdminApi.IChangeCommunicationChannelsRequest} message ChangeCommunicationChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeCommunicationChannelsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ssoId);
            if (message.channels != null && message.channels.length)
                for (var i = 0; i < message.channels.length; ++i)
                    $root.PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel.encode(message.channels[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ChangeCommunicationChannelsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ChangeCommunicationChannelsRequest} ChangeCommunicationChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeCommunicationChannelsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChangeCommunicationChannelsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ssoId = reader.string();
                    break;
                case 2:
                    if (!(message.channels && message.channels.length))
                        message.channels = [];
                    message.channels.push($root.PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ChangeCommunicationChannelsRequest.Channel = (function() {

            /**
             * Properties of a Channel.
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest
             * @interface IChannel
             * @property {string|null} [channel] Channel channel
             * @property {string|null} [group] Channel group
             * @property {boolean|null} [enable] Channel enable
             */

            /**
             * Constructs a new Channel.
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest
             * @classdesc Represents a Channel.
             * @implements IChannel
             * @constructor
             * @param {PlatformAdminApi.ChangeCommunicationChannelsRequest.IChannel=} [properties] Properties to set
             */
            function Channel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Channel channel.
             * @member {string} channel
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel
             * @instance
             */
            Channel.prototype.channel = "";

            /**
             * Channel group.
             * @member {string} group
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel
             * @instance
             */
            Channel.prototype.group = "";

            /**
             * Channel enable.
             * @member {boolean} enable
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel
             * @instance
             */
            Channel.prototype.enable = false;

            /**
             * Encodes the specified Channel message. Does not implicitly {@link PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel
             * @static
             * @param {PlatformAdminApi.ChangeCommunicationChannelsRequest.IChannel} message Channel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Channel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.channel);
                if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.group);
                if (message.enable != null && Object.hasOwnProperty.call(message, "enable"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.enable);
                return writer;
            };

            /**
             * Decodes a Channel message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel} Channel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Channel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChangeCommunicationChannelsRequest.Channel();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.channel = reader.string();
                        break;
                    case 2:
                        message.group = reader.string();
                        break;
                    case 3:
                        message.enable = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Channel;
        })();

        return ChangeCommunicationChannelsRequest;
    })();

    PlatformAdminApi.ChangeCommunicationChannelsResponse = (function() {

        /**
         * Properties of a ChangeCommunicationChannelsResponse.
         * @memberof PlatformAdminApi
         * @interface IChangeCommunicationChannelsResponse
         * @property {Array.<PlatformAdminApi.ChangeCommunicationChannelsResponse.IChannel>|null} [errored] ChangeCommunicationChannelsResponse errored
         */

        /**
         * Constructs a new ChangeCommunicationChannelsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ChangeCommunicationChannelsResponse.
         * @implements IChangeCommunicationChannelsResponse
         * @constructor
         * @param {PlatformAdminApi.IChangeCommunicationChannelsResponse=} [properties] Properties to set
         */
        function ChangeCommunicationChannelsResponse(properties) {
            this.errored = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeCommunicationChannelsResponse errored.
         * @member {Array.<PlatformAdminApi.ChangeCommunicationChannelsResponse.IChannel>} errored
         * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse
         * @instance
         */
        ChangeCommunicationChannelsResponse.prototype.errored = $util.emptyArray;

        /**
         * Encodes the specified ChangeCommunicationChannelsResponse message. Does not implicitly {@link PlatformAdminApi.ChangeCommunicationChannelsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse
         * @static
         * @param {PlatformAdminApi.IChangeCommunicationChannelsResponse} message ChangeCommunicationChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeCommunicationChannelsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errored != null && message.errored.length)
                for (var i = 0; i < message.errored.length; ++i)
                    $root.PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel.encode(message.errored[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ChangeCommunicationChannelsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ChangeCommunicationChannelsResponse} ChangeCommunicationChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeCommunicationChannelsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChangeCommunicationChannelsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.errored && message.errored.length))
                        message.errored = [];
                    message.errored.push($root.PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ChangeCommunicationChannelsResponse.Channel = (function() {

            /**
             * Properties of a Channel.
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse
             * @interface IChannel
             * @property {string|null} [channel] Channel channel
             * @property {string|null} [group] Channel group
             * @property {string|null} [reason] Channel reason
             */

            /**
             * Constructs a new Channel.
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse
             * @classdesc Represents a Channel.
             * @implements IChannel
             * @constructor
             * @param {PlatformAdminApi.ChangeCommunicationChannelsResponse.IChannel=} [properties] Properties to set
             */
            function Channel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Channel channel.
             * @member {string} channel
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel
             * @instance
             */
            Channel.prototype.channel = "";

            /**
             * Channel group.
             * @member {string} group
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel
             * @instance
             */
            Channel.prototype.group = "";

            /**
             * Channel reason.
             * @member {string} reason
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel
             * @instance
             */
            Channel.prototype.reason = "";

            /**
             * Encodes the specified Channel message. Does not implicitly {@link PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel
             * @static
             * @param {PlatformAdminApi.ChangeCommunicationChannelsResponse.IChannel} message Channel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Channel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.channel);
                if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.group);
                if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason);
                return writer;
            };

            /**
             * Decodes a Channel message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel} Channel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Channel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChangeCommunicationChannelsResponse.Channel();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.channel = reader.string();
                        break;
                    case 2:
                        message.group = reader.string();
                        break;
                    case 3:
                        message.reason = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Channel;
        })();

        return ChangeCommunicationChannelsResponse;
    })();

    PlatformAdminApi.RejectionReason = (function() {

        /**
         * Properties of a RejectionReason.
         * @memberof PlatformAdminApi
         * @interface IRejectionReason
         * @property {string|null} [id] RejectionReason id
         * @property {string|null} [slug] RejectionReason slug
         * @property {string|null} [title] RejectionReason title
         */

        /**
         * Constructs a new RejectionReason.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RejectionReason.
         * @implements IRejectionReason
         * @constructor
         * @param {PlatformAdminApi.IRejectionReason=} [properties] Properties to set
         */
        function RejectionReason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RejectionReason id.
         * @member {string} id
         * @memberof PlatformAdminApi.RejectionReason
         * @instance
         */
        RejectionReason.prototype.id = "";

        /**
         * RejectionReason slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.RejectionReason
         * @instance
         */
        RejectionReason.prototype.slug = "";

        /**
         * RejectionReason title.
         * @member {string} title
         * @memberof PlatformAdminApi.RejectionReason
         * @instance
         */
        RejectionReason.prototype.title = "";

        /**
         * Encodes the specified RejectionReason message. Does not implicitly {@link PlatformAdminApi.RejectionReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RejectionReason
         * @static
         * @param {PlatformAdminApi.IRejectionReason} message RejectionReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RejectionReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slug);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            return writer;
        };

        /**
         * Decodes a RejectionReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RejectionReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RejectionReason} RejectionReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RejectionReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RejectionReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RejectionReason;
    })();

    /**
     * OrderStatusType enum.
     * @name PlatformAdminApi.OrderStatusType
     * @enum {number}
     * @property {number} UNKNOWN_ORDER_STATUS_TYPE=0 UNKNOWN_ORDER_STATUS_TYPE value
     * @property {number} TODO=1 TODO value
     * @property {number} IN_PROGRESS=2 IN_PROGRESS value
     * @property {number} DONE=3 DONE value
     * @property {number} CANCELED=4 CANCELED value
     * @property {number} ON_COORDINATION=5 ON_COORDINATION value
     * @property {number} WAIT_IN_PICKPOINT=6 WAIT_IN_PICKPOINT value
     */
    PlatformAdminApi.OrderStatusType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ORDER_STATUS_TYPE"] = 0;
        values[valuesById[1] = "TODO"] = 1;
        values[valuesById[2] = "IN_PROGRESS"] = 2;
        values[valuesById[3] = "DONE"] = 3;
        values[valuesById[4] = "CANCELED"] = 4;
        values[valuesById[5] = "ON_COORDINATION"] = 5;
        values[valuesById[6] = "WAIT_IN_PICKPOINT"] = 6;
        return values;
    })();

    PlatformAdminApi.AddressTypeFormOption = (function() {

        /**
         * Properties of an AddressTypeFormOption.
         * @memberof PlatformAdminApi
         * @interface IAddressTypeFormOption
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [id] AddressTypeFormOption id
         * @property {string|null} [title] AddressTypeFormOption title
         */

        /**
         * Constructs a new AddressTypeFormOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AddressTypeFormOption.
         * @implements IAddressTypeFormOption
         * @constructor
         * @param {PlatformAdminApi.IAddressTypeFormOption=} [properties] Properties to set
         */
        function AddressTypeFormOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressTypeFormOption id.
         * @member {PlatformAdminApi.ADDRESS_TYPE} id
         * @memberof PlatformAdminApi.AddressTypeFormOption
         * @instance
         */
        AddressTypeFormOption.prototype.id = 0;

        /**
         * AddressTypeFormOption title.
         * @member {string} title
         * @memberof PlatformAdminApi.AddressTypeFormOption
         * @instance
         */
        AddressTypeFormOption.prototype.title = "";

        /**
         * Encodes the specified AddressTypeFormOption message. Does not implicitly {@link PlatformAdminApi.AddressTypeFormOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AddressTypeFormOption
         * @static
         * @param {PlatformAdminApi.IAddressTypeFormOption} message AddressTypeFormOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressTypeFormOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes an AddressTypeFormOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AddressTypeFormOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AddressTypeFormOption} AddressTypeFormOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressTypeFormOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AddressTypeFormOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressTypeFormOption;
    })();

    PlatformAdminApi.CustomerAddressForm = (function() {

        /**
         * Properties of a CustomerAddressForm.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressForm
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] CustomerAddressForm type
         * @property {PlatformAdminApi.IAddress|null} [value] CustomerAddressForm value
         */

        /**
         * Constructs a new CustomerAddressForm.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressForm.
         * @implements ICustomerAddressForm
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressForm=} [properties] Properties to set
         */
        function CustomerAddressForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressForm type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.CustomerAddressForm
         * @instance
         */
        CustomerAddressForm.prototype.type = 0;

        /**
         * CustomerAddressForm value.
         * @member {PlatformAdminApi.IAddress|null|undefined} value
         * @memberof PlatformAdminApi.CustomerAddressForm
         * @instance
         */
        CustomerAddressForm.prototype.value = null;

        /**
         * Encodes the specified CustomerAddressForm message. Does not implicitly {@link PlatformAdminApi.CustomerAddressForm.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressForm
         * @static
         * @param {PlatformAdminApi.ICustomerAddressForm} message CustomerAddressForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.PlatformAdminApi.Address.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressForm message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressForm} CustomerAddressForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressForm;
    })();

    PlatformAdminApi.CustomerAddressBackend = (function() {

        /**
         * Properties of a CustomerAddressBackend.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressBackend
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] CustomerAddressBackend addresses
         */

        /**
         * Constructs a new CustomerAddressBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressBackend.
         * @implements ICustomerAddressBackend
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressBackend=} [properties] Properties to set
         */
        function CustomerAddressBackend(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressBackend addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.CustomerAddressBackend
         * @instance
         */
        CustomerAddressBackend.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified CustomerAddressBackend message. Does not implicitly {@link PlatformAdminApi.CustomerAddressBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressBackend
         * @static
         * @param {PlatformAdminApi.ICustomerAddressBackend} message CustomerAddressBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressBackend} CustomerAddressBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressBackend;
    })();

    PlatformAdminApi.CustomerSearchRequest = (function() {

        /**
         * Properties of a CustomerSearchRequest.
         * @memberof PlatformAdminApi
         * @interface ICustomerSearchRequest
         * @property {number|null} [page] CustomerSearchRequest page
         * @property {number|null} [limit] CustomerSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] CustomerSearchRequest sort
         * @property {string|null} [searchString] CustomerSearchRequest searchString
         * @property {PlatformAdminApi.ICustomerSearchBlockingReason|null} [blockingReason] CustomerSearchRequest blockingReason
         */

        /**
         * Constructs a new CustomerSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerSearchRequest.
         * @implements ICustomerSearchRequest
         * @constructor
         * @param {PlatformAdminApi.ICustomerSearchRequest=} [properties] Properties to set
         */
        function CustomerSearchRequest(properties) {
            this.sort = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.CustomerSearchRequest
         * @instance
         */
        CustomerSearchRequest.prototype.page = 0;

        /**
         * CustomerSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.CustomerSearchRequest
         * @instance
         */
        CustomerSearchRequest.prototype.limit = 0;

        /**
         * CustomerSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.CustomerSearchRequest
         * @instance
         */
        CustomerSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * CustomerSearchRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.CustomerSearchRequest
         * @instance
         */
        CustomerSearchRequest.prototype.searchString = "";

        /**
         * CustomerSearchRequest blockingReason.
         * @member {PlatformAdminApi.ICustomerSearchBlockingReason|null|undefined} blockingReason
         * @memberof PlatformAdminApi.CustomerSearchRequest
         * @instance
         */
        CustomerSearchRequest.prototype.blockingReason = null;

        /**
         * Encodes the specified CustomerSearchRequest message. Does not implicitly {@link PlatformAdminApi.CustomerSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerSearchRequest
         * @static
         * @param {PlatformAdminApi.ICustomerSearchRequest} message CustomerSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.searchString);
            if (message.blockingReason != null && Object.hasOwnProperty.call(message, "blockingReason"))
                $root.PlatformAdminApi.CustomerSearchBlockingReason.encode(message.blockingReason, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerSearchRequest} CustomerSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.searchString = reader.string();
                    break;
                case 5:
                    message.blockingReason = $root.PlatformAdminApi.CustomerSearchBlockingReason.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerSearchRequest;
    })();

    PlatformAdminApi.CustomerSearchBlockingReason = (function() {

        /**
         * Properties of a CustomerSearchBlockingReason.
         * @memberof PlatformAdminApi
         * @interface ICustomerSearchBlockingReason
         * @property {string|null} [label] CustomerSearchBlockingReason label
         * @property {PlatformAdminApi.BLOCKING_REASON_SLUG|null} [name] CustomerSearchBlockingReason name
         */

        /**
         * Constructs a new CustomerSearchBlockingReason.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerSearchBlockingReason.
         * @implements ICustomerSearchBlockingReason
         * @constructor
         * @param {PlatformAdminApi.ICustomerSearchBlockingReason=} [properties] Properties to set
         */
        function CustomerSearchBlockingReason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerSearchBlockingReason label.
         * @member {string} label
         * @memberof PlatformAdminApi.CustomerSearchBlockingReason
         * @instance
         */
        CustomerSearchBlockingReason.prototype.label = "";

        /**
         * CustomerSearchBlockingReason name.
         * @member {PlatformAdminApi.BLOCKING_REASON_SLUG} name
         * @memberof PlatformAdminApi.CustomerSearchBlockingReason
         * @instance
         */
        CustomerSearchBlockingReason.prototype.name = 0;

        /**
         * Encodes the specified CustomerSearchBlockingReason message. Does not implicitly {@link PlatformAdminApi.CustomerSearchBlockingReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerSearchBlockingReason
         * @static
         * @param {PlatformAdminApi.ICustomerSearchBlockingReason} message CustomerSearchBlockingReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerSearchBlockingReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.name);
            return writer;
        };

        /**
         * Decodes a CustomerSearchBlockingReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerSearchBlockingReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerSearchBlockingReason} CustomerSearchBlockingReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerSearchBlockingReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerSearchBlockingReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.name = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerSearchBlockingReason;
    })();

    PlatformAdminApi.CustomersListItem = (function() {

        /**
         * Properties of a CustomersListItem.
         * @memberof PlatformAdminApi
         * @interface ICustomersListItem
         * @property {PlatformAdminApi.CustomersListItem.IIdWithName|null} [idWithName] CustomersListItem idWithName
         * @property {string|null} [userType] CustomersListItem userType
         * @property {string|null} [tenant] CustomersListItem tenant
         * @property {string|null} [lastName] CustomersListItem lastName
         * @property {string|null} [firstName] CustomersListItem firstName
         * @property {string|null} [middleName] CustomersListItem middleName
         * @property {string|null} [phone] CustomersListItem phone
         * @property {PlatformAdminApi.IUserBlockInfo|null} [block] CustomersListItem block
         * @property {string|null} [createdAt] CustomersListItem createdAt
         * @property {string|null} [isDeleted] CustomersListItem isDeleted
         */

        /**
         * Constructs a new CustomersListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomersListItem.
         * @implements ICustomersListItem
         * @constructor
         * @param {PlatformAdminApi.ICustomersListItem=} [properties] Properties to set
         */
        function CustomersListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomersListItem idWithName.
         * @member {PlatformAdminApi.CustomersListItem.IIdWithName|null|undefined} idWithName
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.idWithName = null;

        /**
         * CustomersListItem userType.
         * @member {string} userType
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.userType = "";

        /**
         * CustomersListItem tenant.
         * @member {string} tenant
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.tenant = "";

        /**
         * CustomersListItem lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.lastName = "";

        /**
         * CustomersListItem firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.firstName = "";

        /**
         * CustomersListItem middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.middleName = "";

        /**
         * CustomersListItem phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.phone = "";

        /**
         * CustomersListItem block.
         * @member {PlatformAdminApi.IUserBlockInfo|null|undefined} block
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.block = null;

        /**
         * CustomersListItem createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.createdAt = "";

        /**
         * CustomersListItem isDeleted.
         * @member {string} isDeleted
         * @memberof PlatformAdminApi.CustomersListItem
         * @instance
         */
        CustomersListItem.prototype.isDeleted = "";

        /**
         * Encodes the specified CustomersListItem message. Does not implicitly {@link PlatformAdminApi.CustomersListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomersListItem
         * @static
         * @param {PlatformAdminApi.ICustomersListItem} message CustomersListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomersListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idWithName != null && Object.hasOwnProperty.call(message, "idWithName"))
                $root.PlatformAdminApi.CustomersListItem.IdWithName.encode(message.idWithName, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userType);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tenant);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.middleName);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.phone);
            if (message.block != null && Object.hasOwnProperty.call(message, "block"))
                $root.PlatformAdminApi.UserBlockInfo.encode(message.block, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a CustomersListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomersListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomersListItem} CustomersListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomersListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomersListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.idWithName = $root.PlatformAdminApi.CustomersListItem.IdWithName.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userType = reader.string();
                    break;
                case 3:
                    message.tenant = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.firstName = reader.string();
                    break;
                case 6:
                    message.middleName = reader.string();
                    break;
                case 7:
                    message.phone = reader.string();
                    break;
                case 8:
                    message.block = $root.PlatformAdminApi.UserBlockInfo.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.isDeleted = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CustomersListItem.IdWithName = (function() {

            /**
             * Properties of an IdWithName.
             * @memberof PlatformAdminApi.CustomersListItem
             * @interface IIdWithName
             * @property {string|null} [id] IdWithName id
             * @property {string|null} [name] IdWithName name
             */

            /**
             * Constructs a new IdWithName.
             * @memberof PlatformAdminApi.CustomersListItem
             * @classdesc Represents an IdWithName.
             * @implements IIdWithName
             * @constructor
             * @param {PlatformAdminApi.CustomersListItem.IIdWithName=} [properties] Properties to set
             */
            function IdWithName(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IdWithName id.
             * @member {string} id
             * @memberof PlatformAdminApi.CustomersListItem.IdWithName
             * @instance
             */
            IdWithName.prototype.id = "";

            /**
             * IdWithName name.
             * @member {string} name
             * @memberof PlatformAdminApi.CustomersListItem.IdWithName
             * @instance
             */
            IdWithName.prototype.name = "";

            /**
             * Encodes the specified IdWithName message. Does not implicitly {@link PlatformAdminApi.CustomersListItem.IdWithName.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.CustomersListItem.IdWithName
             * @static
             * @param {PlatformAdminApi.CustomersListItem.IIdWithName} message IdWithName message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IdWithName.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes an IdWithName message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.CustomersListItem.IdWithName
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.CustomersListItem.IdWithName} IdWithName
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IdWithName.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomersListItem.IdWithName();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return IdWithName;
        })();

        return CustomersListItem;
    })();

    PlatformAdminApi.CustomersList = (function() {

        /**
         * Properties of a CustomersList.
         * @memberof PlatformAdminApi
         * @interface ICustomersList
         * @property {Array.<PlatformAdminApi.ICustomersListItem>|null} [data] CustomersList data
         * @property {number|null} [page] CustomersList page
         * @property {number|null} [pageCount] CustomersList pageCount
         * @property {number|null} [limit] CustomersList limit
         * @property {number|null} [count] CustomersList count
         * @property {number|null} [total] CustomersList total
         */

        /**
         * Constructs a new CustomersList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomersList.
         * @implements ICustomersList
         * @constructor
         * @param {PlatformAdminApi.ICustomersList=} [properties] Properties to set
         */
        function CustomersList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomersList data.
         * @member {Array.<PlatformAdminApi.ICustomersListItem>} data
         * @memberof PlatformAdminApi.CustomersList
         * @instance
         */
        CustomersList.prototype.data = $util.emptyArray;

        /**
         * CustomersList page.
         * @member {number} page
         * @memberof PlatformAdminApi.CustomersList
         * @instance
         */
        CustomersList.prototype.page = 0;

        /**
         * CustomersList pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.CustomersList
         * @instance
         */
        CustomersList.prototype.pageCount = 0;

        /**
         * CustomersList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.CustomersList
         * @instance
         */
        CustomersList.prototype.limit = 0;

        /**
         * CustomersList count.
         * @member {number} count
         * @memberof PlatformAdminApi.CustomersList
         * @instance
         */
        CustomersList.prototype.count = 0;

        /**
         * CustomersList total.
         * @member {number} total
         * @memberof PlatformAdminApi.CustomersList
         * @instance
         */
        CustomersList.prototype.total = 0;

        /**
         * Encodes the specified CustomersList message. Does not implicitly {@link PlatformAdminApi.CustomersList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomersList
         * @static
         * @param {PlatformAdminApi.ICustomersList} message CustomersList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomersList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.CustomersListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a CustomersList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomersList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomersList} CustomersList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomersList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomersList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.CustomersListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomersList;
    })();

    PlatformAdminApi.CustomerSearchResponse = (function() {

        /**
         * Properties of a CustomerSearchResponse.
         * @memberof PlatformAdminApi
         * @interface ICustomerSearchResponse
         * @property {Array.<PlatformAdminApi.IUser>|null} [data] CustomerSearchResponse data
         * @property {number|null} [page] CustomerSearchResponse page
         * @property {number|null} [pageCount] CustomerSearchResponse pageCount
         * @property {number|null} [limit] CustomerSearchResponse limit
         * @property {number|null} [count] CustomerSearchResponse count
         * @property {number|null} [total] CustomerSearchResponse total
         */

        /**
         * Constructs a new CustomerSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerSearchResponse.
         * @implements ICustomerSearchResponse
         * @constructor
         * @param {PlatformAdminApi.ICustomerSearchResponse=} [properties] Properties to set
         */
        function CustomerSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerSearchResponse data.
         * @member {Array.<PlatformAdminApi.IUser>} data
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @instance
         */
        CustomerSearchResponse.prototype.data = $util.emptyArray;

        /**
         * CustomerSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @instance
         */
        CustomerSearchResponse.prototype.page = 0;

        /**
         * CustomerSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @instance
         */
        CustomerSearchResponse.prototype.pageCount = 0;

        /**
         * CustomerSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @instance
         */
        CustomerSearchResponse.prototype.limit = 0;

        /**
         * CustomerSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @instance
         */
        CustomerSearchResponse.prototype.count = 0;

        /**
         * CustomerSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @instance
         */
        CustomerSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified CustomerSearchResponse message. Does not implicitly {@link PlatformAdminApi.CustomerSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @static
         * @param {PlatformAdminApi.ICustomerSearchResponse} message CustomerSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.User.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a CustomerSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerSearchResponse} CustomerSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.User.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerSearchResponse;
    })();

    PlatformAdminApi.Customer = (function() {

        /**
         * Properties of a Customer.
         * @memberof PlatformAdminApi
         * @interface ICustomer
         * @property {string|null} [id] Customer id
         * @property {string|null} [userType] Customer userType
         * @property {string|null} [tenantName] Customer tenantName
         * @property {string|null} [tenantId] Customer tenantId
         * @property {string|null} [fullName] Customer fullName
         * @property {string|null} [birthday] Customer birthday
         * @property {boolean|null} [isBlocked] Customer isBlocked
         * @property {string|null} [blockingReasonSlug] Customer blockingReasonSlug
         * @property {string|null} [firstName] Customer firstName
         * @property {string|null} [lastName] Customer lastName
         * @property {string|null} [middleName] Customer middleName
         * @property {string|null} [createdAt] Customer createdAt
         * @property {boolean|null} [isAdmin] Customer isAdmin
         * @property {boolean|null} [isDeleted] Customer isDeleted
         * @property {Array.<PlatformAdminApi.ISocialNetwork>|null} [socialNetworks] Customer socialNetworks
         * @property {PlatformAdminApi.IProfileDadataFIO|null} [object] Customer object
         * @property {string|null} [blockingReasonDescription] Customer blockingReasonDescription
         * @property {string|null} [blockedAt] Customer blockedAt
         * @property {string|null} [mainPhone] Customer mainPhone
         */

        /**
         * Constructs a new Customer.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Customer.
         * @implements ICustomer
         * @constructor
         * @param {PlatformAdminApi.ICustomer=} [properties] Properties to set
         */
        function Customer(properties) {
            this.socialNetworks = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Customer id.
         * @member {string} id
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.id = "";

        /**
         * Customer userType.
         * @member {string} userType
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.userType = "";

        /**
         * Customer tenantName.
         * @member {string} tenantName
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.tenantName = "";

        /**
         * Customer tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.tenantId = "";

        /**
         * Customer fullName.
         * @member {string} fullName
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.fullName = "";

        /**
         * Customer birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.birthday = "";

        /**
         * Customer isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.isBlocked = false;

        /**
         * Customer blockingReasonSlug.
         * @member {string} blockingReasonSlug
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.blockingReasonSlug = "";

        /**
         * Customer firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.firstName = "";

        /**
         * Customer lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.lastName = "";

        /**
         * Customer middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.middleName = "";

        /**
         * Customer createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.createdAt = "";

        /**
         * Customer isAdmin.
         * @member {boolean} isAdmin
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.isAdmin = false;

        /**
         * Customer isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.isDeleted = false;

        /**
         * Customer socialNetworks.
         * @member {Array.<PlatformAdminApi.ISocialNetwork>} socialNetworks
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.socialNetworks = $util.emptyArray;

        /**
         * Customer object.
         * @member {PlatformAdminApi.IProfileDadataFIO|null|undefined} object
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.object = null;

        /**
         * Customer blockingReasonDescription.
         * @member {string} blockingReasonDescription
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.blockingReasonDescription = "";

        /**
         * Customer blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.blockedAt = "";

        /**
         * Customer mainPhone.
         * @member {string} mainPhone
         * @memberof PlatformAdminApi.Customer
         * @instance
         */
        Customer.prototype.mainPhone = "";

        /**
         * Encodes the specified Customer message. Does not implicitly {@link PlatformAdminApi.Customer.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Customer
         * @static
         * @param {PlatformAdminApi.ICustomer} message Customer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Customer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userType);
            if (message.tenantName != null && Object.hasOwnProperty.call(message, "tenantName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tenantName);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.tenantId);
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.fullName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.birthday);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isBlocked);
            if (message.blockingReasonSlug != null && Object.hasOwnProperty.call(message, "blockingReasonSlug"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.blockingReasonSlug);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.middleName);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.createdAt);
            if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.isAdmin);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isDeleted);
            if (message.socialNetworks != null && message.socialNetworks.length)
                for (var i = 0; i < message.socialNetworks.length; ++i)
                    $root.PlatformAdminApi.SocialNetwork.encode(message.socialNetworks[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.ProfileDadataFIO.encode(message.object, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.blockingReasonDescription != null && Object.hasOwnProperty.call(message, "blockingReasonDescription"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.blockingReasonDescription);
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.blockedAt);
            if (message.mainPhone != null && Object.hasOwnProperty.call(message, "mainPhone"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.mainPhone);
            return writer;
        };

        /**
         * Decodes a Customer message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Customer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Customer} Customer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Customer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Customer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userType = reader.string();
                    break;
                case 3:
                    message.tenantName = reader.string();
                    break;
                case 4:
                    message.tenantId = reader.string();
                    break;
                case 5:
                    message.fullName = reader.string();
                    break;
                case 6:
                    message.birthday = reader.string();
                    break;
                case 11:
                    message.isBlocked = reader.bool();
                    break;
                case 12:
                    message.blockingReasonSlug = reader.string();
                    break;
                case 13:
                    message.firstName = reader.string();
                    break;
                case 14:
                    message.lastName = reader.string();
                    break;
                case 15:
                    message.middleName = reader.string();
                    break;
                case 16:
                    message.createdAt = reader.string();
                    break;
                case 17:
                    message.isAdmin = reader.bool();
                    break;
                case 18:
                    message.isDeleted = reader.bool();
                    break;
                case 19:
                    if (!(message.socialNetworks && message.socialNetworks.length))
                        message.socialNetworks = [];
                    message.socialNetworks.push($root.PlatformAdminApi.SocialNetwork.decode(reader, reader.uint32()));
                    break;
                case 20:
                    message.object = $root.PlatformAdminApi.ProfileDadataFIO.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.blockingReasonDescription = reader.string();
                    break;
                case 22:
                    message.blockedAt = reader.string();
                    break;
                case 23:
                    message.mainPhone = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Customer;
    })();

    PlatformAdminApi.CustomerEditFrontend = (function() {

        /**
         * Properties of a CustomerEditFrontend.
         * @memberof PlatformAdminApi
         * @interface ICustomerEditFrontend
         * @property {string|null} [id] CustomerEditFrontend id
         * @property {PlatformAdminApi.IFioObject|null} [fioData] CustomerEditFrontend fioData
         * @property {string|null} [birthday] CustomerEditFrontend birthday
         * @property {PlatformAdminApi.IUserTypeFrontend|null} [userType] CustomerEditFrontend userType
         * @property {string|null} [gender] CustomerEditFrontend gender
         * @property {PlatformAdminApi.ITenant|null} [tenant] CustomerEditFrontend tenant
         * @property {boolean|null} [isBlocked] CustomerEditFrontend isBlocked
         * @property {string|null} [blockingReasonDescription] CustomerEditFrontend blockingReasonDescription
         * @property {string|null} [blockedAt] CustomerEditFrontend blockedAt
         * @property {string|null} [createdAt] CustomerEditFrontend createdAt
         */

        /**
         * Constructs a new CustomerEditFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerEditFrontend.
         * @implements ICustomerEditFrontend
         * @constructor
         * @param {PlatformAdminApi.ICustomerEditFrontend=} [properties] Properties to set
         */
        function CustomerEditFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerEditFrontend id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.id = "";

        /**
         * CustomerEditFrontend fioData.
         * @member {PlatformAdminApi.IFioObject|null|undefined} fioData
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.fioData = null;

        /**
         * CustomerEditFrontend birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.birthday = "";

        /**
         * CustomerEditFrontend userType.
         * @member {PlatformAdminApi.IUserTypeFrontend|null|undefined} userType
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.userType = null;

        /**
         * CustomerEditFrontend gender.
         * @member {string} gender
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.gender = "";

        /**
         * CustomerEditFrontend tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.tenant = null;

        /**
         * CustomerEditFrontend isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.isBlocked = false;

        /**
         * CustomerEditFrontend blockingReasonDescription.
         * @member {string} blockingReasonDescription
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.blockingReasonDescription = "";

        /**
         * CustomerEditFrontend blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.blockedAt = "";

        /**
         * CustomerEditFrontend createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @instance
         */
        CustomerEditFrontend.prototype.createdAt = "";

        /**
         * Encodes the specified CustomerEditFrontend message. Does not implicitly {@link PlatformAdminApi.CustomerEditFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @static
         * @param {PlatformAdminApi.ICustomerEditFrontend} message CustomerEditFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerEditFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.fioData != null && Object.hasOwnProperty.call(message, "fioData"))
                $root.PlatformAdminApi.FioObject.encode(message.fioData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.birthday);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                $root.PlatformAdminApi.UserTypeFrontend.encode(message.userType, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gender);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isBlocked);
            if (message.blockingReasonDescription != null && Object.hasOwnProperty.call(message, "blockingReasonDescription"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.blockingReasonDescription);
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.blockedAt);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.createdAt);
            return writer;
        };

        /**
         * Decodes a CustomerEditFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerEditFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerEditFrontend} CustomerEditFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerEditFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerEditFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.fioData = $root.PlatformAdminApi.FioObject.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.birthday = reader.string();
                    break;
                case 4:
                    message.userType = $root.PlatformAdminApi.UserTypeFrontend.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.gender = reader.string();
                    break;
                case 6:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isBlocked = reader.bool();
                    break;
                case 8:
                    message.blockingReasonDescription = reader.string();
                    break;
                case 9:
                    message.blockedAt = reader.string();
                    break;
                case 10:
                    message.createdAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerEditFrontend;
    })();

    PlatformAdminApi.CustomerEditBackend = (function() {

        /**
         * Properties of a CustomerEditBackend.
         * @memberof PlatformAdminApi
         * @interface ICustomerEditBackend
         * @property {string|null} [id] CustomerEditBackend id
         * @property {string|null} [firstName] CustomerEditBackend firstName
         * @property {string|null} [lastName] CustomerEditBackend lastName
         * @property {string|null} [middleName] CustomerEditBackend middleName
         * @property {string|null} [birthday] CustomerEditBackend birthday
         * @property {PlatformAdminApi.IProfileDadataFIO|null} [object] CustomerEditBackend object
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] CustomerEditBackend userType
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] CustomerEditBackend gender
         * @property {string|null} [tenantName] CustomerEditBackend tenantName
         * @property {string|null} [tenantId] CustomerEditBackend tenantId
         */

        /**
         * Constructs a new CustomerEditBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerEditBackend.
         * @implements ICustomerEditBackend
         * @constructor
         * @param {PlatformAdminApi.ICustomerEditBackend=} [properties] Properties to set
         */
        function CustomerEditBackend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerEditBackend id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.id = "";

        /**
         * CustomerEditBackend firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.firstName = "";

        /**
         * CustomerEditBackend lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.lastName = "";

        /**
         * CustomerEditBackend middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.middleName = "";

        /**
         * CustomerEditBackend birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.birthday = "";

        /**
         * CustomerEditBackend object.
         * @member {PlatformAdminApi.IProfileDadataFIO|null|undefined} object
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.object = null;

        /**
         * CustomerEditBackend userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.userType = 0;

        /**
         * CustomerEditBackend gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.gender = 0;

        /**
         * CustomerEditBackend tenantName.
         * @member {string} tenantName
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.tenantName = "";

        /**
         * CustomerEditBackend tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @instance
         */
        CustomerEditBackend.prototype.tenantId = "";

        /**
         * Encodes the specified CustomerEditBackend message. Does not implicitly {@link PlatformAdminApi.CustomerEditBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @static
         * @param {PlatformAdminApi.ICustomerEditBackend} message CustomerEditBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerEditBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.middleName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.ProfileDadataFIO.encode(message.object, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.userType);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.gender);
            if (message.tenantName != null && Object.hasOwnProperty.call(message, "tenantName"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.tenantName);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.tenantId);
            return writer;
        };

        /**
         * Decodes a CustomerEditBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerEditBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerEditBackend} CustomerEditBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerEditBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerEditBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.firstName = reader.string();
                    break;
                case 3:
                    message.lastName = reader.string();
                    break;
                case 4:
                    message.middleName = reader.string();
                    break;
                case 5:
                    message.birthday = reader.string();
                    break;
                case 6:
                    message.object = $root.PlatformAdminApi.ProfileDadataFIO.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.userType = reader.int32();
                    break;
                case 8:
                    message.gender = reader.int32();
                    break;
                case 9:
                    message.tenantName = reader.string();
                    break;
                case 10:
                    message.tenantId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerEditBackend;
    })();

    PlatformAdminApi.FioObject = (function() {

        /**
         * Properties of a FioObject.
         * @memberof PlatformAdminApi
         * @interface IFioObject
         * @property {PlatformAdminApi.IProfileDadataFIO|null} [dadataFio] FioObject dadataFio
         * @property {string|null} [firstName] FioObject firstName
         * @property {string|null} [lastName] FioObject lastName
         * @property {string|null} [middleName] FioObject middleName
         */

        /**
         * Constructs a new FioObject.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FioObject.
         * @implements IFioObject
         * @constructor
         * @param {PlatformAdminApi.IFioObject=} [properties] Properties to set
         */
        function FioObject(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FioObject dadataFio.
         * @member {PlatformAdminApi.IProfileDadataFIO|null|undefined} dadataFio
         * @memberof PlatformAdminApi.FioObject
         * @instance
         */
        FioObject.prototype.dadataFio = null;

        /**
         * FioObject firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.FioObject
         * @instance
         */
        FioObject.prototype.firstName = "";

        /**
         * FioObject lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.FioObject
         * @instance
         */
        FioObject.prototype.lastName = "";

        /**
         * FioObject middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.FioObject
         * @instance
         */
        FioObject.prototype.middleName = "";

        /**
         * Encodes the specified FioObject message. Does not implicitly {@link PlatformAdminApi.FioObject.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FioObject
         * @static
         * @param {PlatformAdminApi.IFioObject} message FioObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FioObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dadataFio != null && Object.hasOwnProperty.call(message, "dadataFio"))
                $root.PlatformAdminApi.ProfileDadataFIO.encode(message.dadataFio, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.middleName);
            return writer;
        };

        /**
         * Decodes a FioObject message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FioObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FioObject} FioObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FioObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FioObject();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.dadataFio = $root.PlatformAdminApi.ProfileDadataFIO.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.firstName = reader.string();
                    break;
                case 3:
                    message.lastName = reader.string();
                    break;
                case 4:
                    message.middleName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FioObject;
    })();

    PlatformAdminApi.UserTypeFrontend = (function() {

        /**
         * Properties of a UserTypeFrontend.
         * @memberof PlatformAdminApi
         * @interface IUserTypeFrontend
         * @property {string|null} [label] UserTypeFrontend label
         * @property {PlatformAdminApi.USER_TYPE|null} [name] UserTypeFrontend name
         */

        /**
         * Constructs a new UserTypeFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a UserTypeFrontend.
         * @implements IUserTypeFrontend
         * @constructor
         * @param {PlatformAdminApi.IUserTypeFrontend=} [properties] Properties to set
         */
        function UserTypeFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserTypeFrontend label.
         * @member {string} label
         * @memberof PlatformAdminApi.UserTypeFrontend
         * @instance
         */
        UserTypeFrontend.prototype.label = "";

        /**
         * UserTypeFrontend name.
         * @member {PlatformAdminApi.USER_TYPE} name
         * @memberof PlatformAdminApi.UserTypeFrontend
         * @instance
         */
        UserTypeFrontend.prototype.name = 0;

        /**
         * Encodes the specified UserTypeFrontend message. Does not implicitly {@link PlatformAdminApi.UserTypeFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UserTypeFrontend
         * @static
         * @param {PlatformAdminApi.IUserTypeFrontend} message UserTypeFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserTypeFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.name);
            return writer;
        };

        /**
         * Decodes a UserTypeFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UserTypeFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UserTypeFrontend} UserTypeFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserTypeFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UserTypeFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.name = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UserTypeFrontend;
    })();

    PlatformAdminApi.BlockCustomerRequest = (function() {

        /**
         * Properties of a BlockCustomerRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockCustomerRequest
         * @property {string|null} [id] BlockCustomerRequest id
         * @property {PlatformAdminApi.BLOCKING_REASON_SLUG|null} [blockingReasonSlug] BlockCustomerRequest blockingReasonSlug
         */

        /**
         * Constructs a new BlockCustomerRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockCustomerRequest.
         * @implements IBlockCustomerRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockCustomerRequest=} [properties] Properties to set
         */
        function BlockCustomerRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockCustomerRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockCustomerRequest
         * @instance
         */
        BlockCustomerRequest.prototype.id = "";

        /**
         * BlockCustomerRequest blockingReasonSlug.
         * @member {PlatformAdminApi.BLOCKING_REASON_SLUG} blockingReasonSlug
         * @memberof PlatformAdminApi.BlockCustomerRequest
         * @instance
         */
        BlockCustomerRequest.prototype.blockingReasonSlug = 0;

        /**
         * Encodes the specified BlockCustomerRequest message. Does not implicitly {@link PlatformAdminApi.BlockCustomerRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockCustomerRequest
         * @static
         * @param {PlatformAdminApi.IBlockCustomerRequest} message BlockCustomerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockCustomerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.blockingReasonSlug != null && Object.hasOwnProperty.call(message, "blockingReasonSlug"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.blockingReasonSlug);
            return writer;
        };

        /**
         * Decodes a BlockCustomerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockCustomerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockCustomerRequest} BlockCustomerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockCustomerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockCustomerRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 21:
                    message.blockingReasonSlug = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockCustomerRequest;
    })();

    PlatformAdminApi.UnblockCustomerRequest = (function() {

        /**
         * Properties of an UnblockCustomerRequest.
         * @memberof PlatformAdminApi
         * @interface IUnblockCustomerRequest
         * @property {string|null} [id] UnblockCustomerRequest id
         */

        /**
         * Constructs a new UnblockCustomerRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UnblockCustomerRequest.
         * @implements IUnblockCustomerRequest
         * @constructor
         * @param {PlatformAdminApi.IUnblockCustomerRequest=} [properties] Properties to set
         */
        function UnblockCustomerRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnblockCustomerRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.UnblockCustomerRequest
         * @instance
         */
        UnblockCustomerRequest.prototype.id = "";

        /**
         * Encodes the specified UnblockCustomerRequest message. Does not implicitly {@link PlatformAdminApi.UnblockCustomerRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UnblockCustomerRequest
         * @static
         * @param {PlatformAdminApi.IUnblockCustomerRequest} message UnblockCustomerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnblockCustomerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes an UnblockCustomerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UnblockCustomerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UnblockCustomerRequest} UnblockCustomerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnblockCustomerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UnblockCustomerRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UnblockCustomerRequest;
    })();

    PlatformAdminApi.CustomerAddressesListItem = (function() {

        /**
         * Properties of a CustomerAddressesListItem.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressesListItem
         * @property {string|null} [id] CustomerAddressesListItem id
         * @property {string|null} [value] CustomerAddressesListItem value
         * @property {string|null} [label] CustomerAddressesListItem label
         * @property {boolean|null} [isDeleted] CustomerAddressesListItem isDeleted
         * @property {string|null} [isDeletedString] CustomerAddressesListItem isDeletedString
         * @property {string|null} [addressTypeLabel] CustomerAddressesListItem addressTypeLabel
         * @property {string|null} [link] CustomerAddressesListItem link
         * @property {string|null} [city] CustomerAddressesListItem city
         * @property {boolean|null} [serviceAvailable] CustomerAddressesListItem serviceAvailable
         * @property {boolean|null} [needsToComplete] CustomerAddressesListItem needsToComplete
         */

        /**
         * Constructs a new CustomerAddressesListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressesListItem.
         * @implements ICustomerAddressesListItem
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressesListItem=} [properties] Properties to set
         */
        function CustomerAddressesListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressesListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.id = "";

        /**
         * CustomerAddressesListItem value.
         * @member {string} value
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.value = "";

        /**
         * CustomerAddressesListItem label.
         * @member {string} label
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.label = "";

        /**
         * CustomerAddressesListItem isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.isDeleted = false;

        /**
         * CustomerAddressesListItem isDeletedString.
         * @member {string} isDeletedString
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.isDeletedString = "";

        /**
         * CustomerAddressesListItem addressTypeLabel.
         * @member {string} addressTypeLabel
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.addressTypeLabel = "";

        /**
         * CustomerAddressesListItem link.
         * @member {string} link
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.link = "";

        /**
         * CustomerAddressesListItem city.
         * @member {string} city
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.city = "";

        /**
         * CustomerAddressesListItem serviceAvailable.
         * @member {boolean} serviceAvailable
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.serviceAvailable = false;

        /**
         * CustomerAddressesListItem needsToComplete.
         * @member {boolean} needsToComplete
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @instance
         */
        CustomerAddressesListItem.prototype.needsToComplete = false;

        /**
         * Encodes the specified CustomerAddressesListItem message. Does not implicitly {@link PlatformAdminApi.CustomerAddressesListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @static
         * @param {PlatformAdminApi.ICustomerAddressesListItem} message CustomerAddressesListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressesListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.isDeletedString != null && Object.hasOwnProperty.call(message, "isDeletedString"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.isDeletedString);
            if (message.addressTypeLabel != null && Object.hasOwnProperty.call(message, "addressTypeLabel"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.addressTypeLabel);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.link);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.city);
            if (message.serviceAvailable != null && Object.hasOwnProperty.call(message, "serviceAvailable"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.serviceAvailable);
            if (message.needsToComplete != null && Object.hasOwnProperty.call(message, "needsToComplete"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.needsToComplete);
            return writer;
        };

        /**
         * Decodes a CustomerAddressesListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressesListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressesListItem} CustomerAddressesListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressesListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressesListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.label = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.isDeletedString = reader.string();
                    break;
                case 6:
                    message.addressTypeLabel = reader.string();
                    break;
                case 7:
                    message.link = reader.string();
                    break;
                case 8:
                    message.city = reader.string();
                    break;
                case 9:
                    message.serviceAvailable = reader.bool();
                    break;
                case 10:
                    message.needsToComplete = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressesListItem;
    })();

    PlatformAdminApi.CustomerAddressesList = (function() {

        /**
         * Properties of a CustomerAddressesList.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressesList
         * @property {Array.<PlatformAdminApi.ICustomerAddressesListItem>|null} [data] CustomerAddressesList data
         */

        /**
         * Constructs a new CustomerAddressesList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressesList.
         * @implements ICustomerAddressesList
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressesList=} [properties] Properties to set
         */
        function CustomerAddressesList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressesList data.
         * @member {Array.<PlatformAdminApi.ICustomerAddressesListItem>} data
         * @memberof PlatformAdminApi.CustomerAddressesList
         * @instance
         */
        CustomerAddressesList.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified CustomerAddressesList message. Does not implicitly {@link PlatformAdminApi.CustomerAddressesList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressesList
         * @static
         * @param {PlatformAdminApi.ICustomerAddressesList} message CustomerAddressesList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressesList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.CustomerAddressesListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressesList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressesList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressesList} CustomerAddressesList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressesList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressesList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.CustomerAddressesListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressesList;
    })();

    PlatformAdminApi.CustomerBlockingReasonRequest = (function() {

        /**
         * Properties of a CustomerBlockingReasonRequest.
         * @memberof PlatformAdminApi
         * @interface ICustomerBlockingReasonRequest
         * @property {string|null} [searchString] CustomerBlockingReasonRequest searchString
         */

        /**
         * Constructs a new CustomerBlockingReasonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerBlockingReasonRequest.
         * @implements ICustomerBlockingReasonRequest
         * @constructor
         * @param {PlatformAdminApi.ICustomerBlockingReasonRequest=} [properties] Properties to set
         */
        function CustomerBlockingReasonRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerBlockingReasonRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.CustomerBlockingReasonRequest
         * @instance
         */
        CustomerBlockingReasonRequest.prototype.searchString = "";

        /**
         * Encodes the specified CustomerBlockingReasonRequest message. Does not implicitly {@link PlatformAdminApi.CustomerBlockingReasonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerBlockingReasonRequest
         * @static
         * @param {PlatformAdminApi.ICustomerBlockingReasonRequest} message CustomerBlockingReasonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerBlockingReasonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a CustomerBlockingReasonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerBlockingReasonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerBlockingReasonRequest} CustomerBlockingReasonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerBlockingReasonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerBlockingReasonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerBlockingReasonRequest;
    })();

    PlatformAdminApi.CustomerBlockingReasonResponse = (function() {

        /**
         * Properties of a CustomerBlockingReasonResponse.
         * @memberof PlatformAdminApi
         * @interface ICustomerBlockingReasonResponse
         * @property {Array.<PlatformAdminApi.IUserBlockingReason>|null} [data] CustomerBlockingReasonResponse data
         */

        /**
         * Constructs a new CustomerBlockingReasonResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerBlockingReasonResponse.
         * @implements ICustomerBlockingReasonResponse
         * @constructor
         * @param {PlatformAdminApi.ICustomerBlockingReasonResponse=} [properties] Properties to set
         */
        function CustomerBlockingReasonResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerBlockingReasonResponse data.
         * @member {Array.<PlatformAdminApi.IUserBlockingReason>} data
         * @memberof PlatformAdminApi.CustomerBlockingReasonResponse
         * @instance
         */
        CustomerBlockingReasonResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified CustomerBlockingReasonResponse message. Does not implicitly {@link PlatformAdminApi.CustomerBlockingReasonResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerBlockingReasonResponse
         * @static
         * @param {PlatformAdminApi.ICustomerBlockingReasonResponse} message CustomerBlockingReasonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerBlockingReasonResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.UserBlockingReason.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerBlockingReasonResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerBlockingReasonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerBlockingReasonResponse} CustomerBlockingReasonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerBlockingReasonResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerBlockingReasonResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.UserBlockingReason.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerBlockingReasonResponse;
    })();

    PlatformAdminApi.CustomerAddressCreateFrontend = (function() {

        /**
         * Properties of a CustomerAddressCreateFrontend.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressCreateFrontend
         * @property {string|null} [id] CustomerAddressCreateFrontend id
         * @property {PlatformAdminApi.ICustomerAddressForm|null} [newAddress] CustomerAddressCreateFrontend newAddress
         */

        /**
         * Constructs a new CustomerAddressCreateFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressCreateFrontend.
         * @implements ICustomerAddressCreateFrontend
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressCreateFrontend=} [properties] Properties to set
         */
        function CustomerAddressCreateFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressCreateFrontend id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerAddressCreateFrontend
         * @instance
         */
        CustomerAddressCreateFrontend.prototype.id = "";

        /**
         * CustomerAddressCreateFrontend newAddress.
         * @member {PlatformAdminApi.ICustomerAddressForm|null|undefined} newAddress
         * @memberof PlatformAdminApi.CustomerAddressCreateFrontend
         * @instance
         */
        CustomerAddressCreateFrontend.prototype.newAddress = null;

        /**
         * Encodes the specified CustomerAddressCreateFrontend message. Does not implicitly {@link PlatformAdminApi.CustomerAddressCreateFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressCreateFrontend
         * @static
         * @param {PlatformAdminApi.ICustomerAddressCreateFrontend} message CustomerAddressCreateFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressCreateFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.newAddress != null && Object.hasOwnProperty.call(message, "newAddress"))
                $root.PlatformAdminApi.CustomerAddressForm.encode(message.newAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressCreateFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressCreateFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressCreateFrontend} CustomerAddressCreateFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressCreateFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressCreateFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.newAddress = $root.PlatformAdminApi.CustomerAddressForm.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressCreateFrontend;
    })();

    PlatformAdminApi.CustomerAddressCreateBackend = (function() {

        /**
         * Properties of a CustomerAddressCreateBackend.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressCreateBackend
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] CustomerAddressCreateBackend addresses
         */

        /**
         * Constructs a new CustomerAddressCreateBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressCreateBackend.
         * @implements ICustomerAddressCreateBackend
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressCreateBackend=} [properties] Properties to set
         */
        function CustomerAddressCreateBackend(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressCreateBackend addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.CustomerAddressCreateBackend
         * @instance
         */
        CustomerAddressCreateBackend.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified CustomerAddressCreateBackend message. Does not implicitly {@link PlatformAdminApi.CustomerAddressCreateBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressCreateBackend
         * @static
         * @param {PlatformAdminApi.ICustomerAddressCreateBackend} message CustomerAddressCreateBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressCreateBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressCreateBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressCreateBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressCreateBackend} CustomerAddressCreateBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressCreateBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressCreateBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressCreateBackend;
    })();

    PlatformAdminApi.CustomerAddressUpdateFrontend = (function() {

        /**
         * Properties of a CustomerAddressUpdateFrontend.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressUpdateFrontend
         * @property {string|null} [id] CustomerAddressUpdateFrontend id
         * @property {PlatformAdminApi.ICustomerAddressForm|null} [address] CustomerAddressUpdateFrontend address
         */

        /**
         * Constructs a new CustomerAddressUpdateFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressUpdateFrontend.
         * @implements ICustomerAddressUpdateFrontend
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressUpdateFrontend=} [properties] Properties to set
         */
        function CustomerAddressUpdateFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressUpdateFrontend id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerAddressUpdateFrontend
         * @instance
         */
        CustomerAddressUpdateFrontend.prototype.id = "";

        /**
         * CustomerAddressUpdateFrontend address.
         * @member {PlatformAdminApi.ICustomerAddressForm|null|undefined} address
         * @memberof PlatformAdminApi.CustomerAddressUpdateFrontend
         * @instance
         */
        CustomerAddressUpdateFrontend.prototype.address = null;

        /**
         * Encodes the specified CustomerAddressUpdateFrontend message. Does not implicitly {@link PlatformAdminApi.CustomerAddressUpdateFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressUpdateFrontend
         * @static
         * @param {PlatformAdminApi.ICustomerAddressUpdateFrontend} message CustomerAddressUpdateFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressUpdateFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.CustomerAddressForm.encode(message.address, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressUpdateFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressUpdateFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressUpdateFrontend} CustomerAddressUpdateFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressUpdateFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressUpdateFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 3:
                    message.address = $root.PlatformAdminApi.CustomerAddressForm.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressUpdateFrontend;
    })();

    PlatformAdminApi.CustomerAddressUpdateBackend = (function() {

        /**
         * Properties of a CustomerAddressUpdateBackend.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressUpdateBackend
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] CustomerAddressUpdateBackend addresses
         */

        /**
         * Constructs a new CustomerAddressUpdateBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressUpdateBackend.
         * @implements ICustomerAddressUpdateBackend
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressUpdateBackend=} [properties] Properties to set
         */
        function CustomerAddressUpdateBackend(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressUpdateBackend addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.CustomerAddressUpdateBackend
         * @instance
         */
        CustomerAddressUpdateBackend.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified CustomerAddressUpdateBackend message. Does not implicitly {@link PlatformAdminApi.CustomerAddressUpdateBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressUpdateBackend
         * @static
         * @param {PlatformAdminApi.ICustomerAddressUpdateBackend} message CustomerAddressUpdateBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressUpdateBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressUpdateBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressUpdateBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressUpdateBackend} CustomerAddressUpdateBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressUpdateBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressUpdateBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressUpdateBackend;
    })();

    PlatformAdminApi.CustomerAddressEditForm = (function() {

        /**
         * Properties of a CustomerAddressEditForm.
         * @memberof PlatformAdminApi
         * @interface ICustomerAddressEditForm
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] CustomerAddressEditForm type
         * @property {PlatformAdminApi.IAddress|null} [value] CustomerAddressEditForm value
         */

        /**
         * Constructs a new CustomerAddressEditForm.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAddressEditForm.
         * @implements ICustomerAddressEditForm
         * @constructor
         * @param {PlatformAdminApi.ICustomerAddressEditForm=} [properties] Properties to set
         */
        function CustomerAddressEditForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAddressEditForm type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.CustomerAddressEditForm
         * @instance
         */
        CustomerAddressEditForm.prototype.type = 0;

        /**
         * CustomerAddressEditForm value.
         * @member {PlatformAdminApi.IAddress|null|undefined} value
         * @memberof PlatformAdminApi.CustomerAddressEditForm
         * @instance
         */
        CustomerAddressEditForm.prototype.value = null;

        /**
         * Encodes the specified CustomerAddressEditForm message. Does not implicitly {@link PlatformAdminApi.CustomerAddressEditForm.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAddressEditForm
         * @static
         * @param {PlatformAdminApi.ICustomerAddressEditForm} message CustomerAddressEditForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAddressEditForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.PlatformAdminApi.Address.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAddressEditForm message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAddressEditForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAddressEditForm} CustomerAddressEditForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAddressEditForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAddressEditForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAddressEditForm;
    })();

    PlatformAdminApi.CustomerViewAddress = (function() {

        /**
         * Properties of a CustomerViewAddress.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewAddress
         * @property {PlatformAdminApi.IAddressLink|null} [addressLink] CustomerViewAddress addressLink
         * @property {string|null} [intercom] CustomerViewAddress intercom
         * @property {Array.<PlatformAdminApi.IMetroStation>|null} [metro] CustomerViewAddress metro
         * @property {boolean|null} [isDeleted] CustomerViewAddress isDeleted
         * @property {string|null} [isDeletedStatus] CustomerViewAddress isDeletedStatus
         * @property {string|null} [comment] CustomerViewAddress comment
         */

        /**
         * Constructs a new CustomerViewAddress.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewAddress.
         * @implements ICustomerViewAddress
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewAddress=} [properties] Properties to set
         */
        function CustomerViewAddress(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewAddress addressLink.
         * @member {PlatformAdminApi.IAddressLink|null|undefined} addressLink
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @instance
         */
        CustomerViewAddress.prototype.addressLink = null;

        /**
         * CustomerViewAddress intercom.
         * @member {string} intercom
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @instance
         */
        CustomerViewAddress.prototype.intercom = "";

        /**
         * CustomerViewAddress metro.
         * @member {Array.<PlatformAdminApi.IMetroStation>} metro
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @instance
         */
        CustomerViewAddress.prototype.metro = $util.emptyArray;

        /**
         * CustomerViewAddress isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @instance
         */
        CustomerViewAddress.prototype.isDeleted = false;

        /**
         * CustomerViewAddress isDeletedStatus.
         * @member {string} isDeletedStatus
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @instance
         */
        CustomerViewAddress.prototype.isDeletedStatus = "";

        /**
         * CustomerViewAddress comment.
         * @member {string|null|undefined} comment
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @instance
         */
        CustomerViewAddress.prototype.comment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CustomerViewAddress _comment.
         * @member {"comment"|undefined} _comment
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @instance
         */
        Object.defineProperty(CustomerViewAddress.prototype, "_comment", {
            get: $util.oneOfGetter($oneOfFields = ["comment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified CustomerViewAddress message. Does not implicitly {@link PlatformAdminApi.CustomerViewAddress.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @static
         * @param {PlatformAdminApi.ICustomerViewAddress} message CustomerViewAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addressLink != null && Object.hasOwnProperty.call(message, "addressLink"))
                $root.PlatformAdminApi.AddressLink.encode(message.addressLink, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.intercom);
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.PlatformAdminApi.MetroStation.encode(message.metro[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.isDeletedStatus != null && Object.hasOwnProperty.call(message, "isDeletedStatus"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.isDeletedStatus);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.comment);
            return writer;
        };

        /**
         * Decodes a CustomerViewAddress message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewAddress} CustomerViewAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewAddress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.addressLink = $root.PlatformAdminApi.AddressLink.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.intercom = reader.string();
                    break;
                case 3:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.PlatformAdminApi.MetroStation.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.isDeletedStatus = reader.string();
                    break;
                case 6:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewAddress;
    })();

    PlatformAdminApi.CustomerContactResponse = (function() {

        /**
         * Properties of a CustomerContactResponse.
         * @memberof PlatformAdminApi
         * @interface ICustomerContactResponse
         * @property {Array.<PlatformAdminApi.ICustomerContact>|null} [phone] CustomerContactResponse phone
         * @property {Array.<PlatformAdminApi.ICustomerContact>|null} [email] CustomerContactResponse email
         */

        /**
         * Constructs a new CustomerContactResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerContactResponse.
         * @implements ICustomerContactResponse
         * @constructor
         * @param {PlatformAdminApi.ICustomerContactResponse=} [properties] Properties to set
         */
        function CustomerContactResponse(properties) {
            this.phone = [];
            this.email = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerContactResponse phone.
         * @member {Array.<PlatformAdminApi.ICustomerContact>} phone
         * @memberof PlatformAdminApi.CustomerContactResponse
         * @instance
         */
        CustomerContactResponse.prototype.phone = $util.emptyArray;

        /**
         * CustomerContactResponse email.
         * @member {Array.<PlatformAdminApi.ICustomerContact>} email
         * @memberof PlatformAdminApi.CustomerContactResponse
         * @instance
         */
        CustomerContactResponse.prototype.email = $util.emptyArray;

        /**
         * Encodes the specified CustomerContactResponse message. Does not implicitly {@link PlatformAdminApi.CustomerContactResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerContactResponse
         * @static
         * @param {PlatformAdminApi.ICustomerContactResponse} message CustomerContactResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerContactResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && message.phone.length)
                for (var i = 0; i < message.phone.length; ++i)
                    $root.PlatformAdminApi.CustomerContact.encode(message.phone[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.email != null && message.email.length)
                for (var i = 0; i < message.email.length; ++i)
                    $root.PlatformAdminApi.CustomerContact.encode(message.email[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerContactResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerContactResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerContactResponse} CustomerContactResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerContactResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerContactResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.phone && message.phone.length))
                        message.phone = [];
                    message.phone.push($root.PlatformAdminApi.CustomerContact.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.email && message.email.length))
                        message.email = [];
                    message.email.push($root.PlatformAdminApi.CustomerContact.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerContactResponse;
    })();

    PlatformAdminApi.CustomerContact = (function() {

        /**
         * Properties of a CustomerContact.
         * @memberof PlatformAdminApi
         * @interface ICustomerContact
         * @property {string|null} [id] CustomerContact id
         * @property {PlatformAdminApi.CONTACT_TYPE|null} [type] CustomerContact type
         * @property {string|null} [value] CustomerContact value
         * @property {boolean|null} [isVerified] CustomerContact isVerified
         * @property {boolean|null} [isMain] CustomerContact isMain
         * @property {boolean|null} [isDeleted] CustomerContact isDeleted
         */

        /**
         * Constructs a new CustomerContact.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerContact.
         * @implements ICustomerContact
         * @constructor
         * @param {PlatformAdminApi.ICustomerContact=} [properties] Properties to set
         */
        function CustomerContact(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerContact id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerContact
         * @instance
         */
        CustomerContact.prototype.id = "";

        /**
         * CustomerContact type.
         * @member {PlatformAdminApi.CONTACT_TYPE} type
         * @memberof PlatformAdminApi.CustomerContact
         * @instance
         */
        CustomerContact.prototype.type = 0;

        /**
         * CustomerContact value.
         * @member {string} value
         * @memberof PlatformAdminApi.CustomerContact
         * @instance
         */
        CustomerContact.prototype.value = "";

        /**
         * CustomerContact isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.CustomerContact
         * @instance
         */
        CustomerContact.prototype.isVerified = false;

        /**
         * CustomerContact isMain.
         * @member {boolean} isMain
         * @memberof PlatformAdminApi.CustomerContact
         * @instance
         */
        CustomerContact.prototype.isMain = false;

        /**
         * CustomerContact isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.CustomerContact
         * @instance
         */
        CustomerContact.prototype.isDeleted = false;

        /**
         * Encodes the specified CustomerContact message. Does not implicitly {@link PlatformAdminApi.CustomerContact.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerContact
         * @static
         * @param {PlatformAdminApi.ICustomerContact} message CustomerContact message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerContact.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVerified);
            if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isMain);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a CustomerContact message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerContact
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerContact} CustomerContact
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerContact.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerContact();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.isVerified = reader.bool();
                    break;
                case 5:
                    message.isMain = reader.bool();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerContact;
    })();

    PlatformAdminApi.FindCustomerContactByIdRequest = (function() {

        /**
         * Properties of a FindCustomerContactByIdRequest.
         * @memberof PlatformAdminApi
         * @interface IFindCustomerContactByIdRequest
         * @property {string|null} [userId] FindCustomerContactByIdRequest userId
         * @property {string|null} [contactId] FindCustomerContactByIdRequest contactId
         */

        /**
         * Constructs a new FindCustomerContactByIdRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FindCustomerContactByIdRequest.
         * @implements IFindCustomerContactByIdRequest
         * @constructor
         * @param {PlatformAdminApi.IFindCustomerContactByIdRequest=} [properties] Properties to set
         */
        function FindCustomerContactByIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindCustomerContactByIdRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.FindCustomerContactByIdRequest
         * @instance
         */
        FindCustomerContactByIdRequest.prototype.userId = "";

        /**
         * FindCustomerContactByIdRequest contactId.
         * @member {string} contactId
         * @memberof PlatformAdminApi.FindCustomerContactByIdRequest
         * @instance
         */
        FindCustomerContactByIdRequest.prototype.contactId = "";

        /**
         * Encodes the specified FindCustomerContactByIdRequest message. Does not implicitly {@link PlatformAdminApi.FindCustomerContactByIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FindCustomerContactByIdRequest
         * @static
         * @param {PlatformAdminApi.IFindCustomerContactByIdRequest} message FindCustomerContactByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindCustomerContactByIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.contactId != null && Object.hasOwnProperty.call(message, "contactId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contactId);
            return writer;
        };

        /**
         * Decodes a FindCustomerContactByIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FindCustomerContactByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FindCustomerContactByIdRequest} FindCustomerContactByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindCustomerContactByIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FindCustomerContactByIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.contactId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindCustomerContactByIdRequest;
    })();

    PlatformAdminApi.CustomerContactEdit = (function() {

        /**
         * Properties of a CustomerContactEdit.
         * @memberof PlatformAdminApi
         * @interface ICustomerContactEdit
         * @property {Array.<PlatformAdminApi.IContactSelectOption>|null} [type] CustomerContactEdit type
         * @property {string|null} [value] CustomerContactEdit value
         * @property {boolean|null} [isMain] CustomerContactEdit isMain
         */

        /**
         * Constructs a new CustomerContactEdit.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerContactEdit.
         * @implements ICustomerContactEdit
         * @constructor
         * @param {PlatformAdminApi.ICustomerContactEdit=} [properties] Properties to set
         */
        function CustomerContactEdit(properties) {
            this.type = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerContactEdit type.
         * @member {Array.<PlatformAdminApi.IContactSelectOption>} type
         * @memberof PlatformAdminApi.CustomerContactEdit
         * @instance
         */
        CustomerContactEdit.prototype.type = $util.emptyArray;

        /**
         * CustomerContactEdit value.
         * @member {string} value
         * @memberof PlatformAdminApi.CustomerContactEdit
         * @instance
         */
        CustomerContactEdit.prototype.value = "";

        /**
         * CustomerContactEdit isMain.
         * @member {boolean} isMain
         * @memberof PlatformAdminApi.CustomerContactEdit
         * @instance
         */
        CustomerContactEdit.prototype.isMain = false;

        /**
         * Encodes the specified CustomerContactEdit message. Does not implicitly {@link PlatformAdminApi.CustomerContactEdit.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerContactEdit
         * @static
         * @param {PlatformAdminApi.ICustomerContactEdit} message CustomerContactEdit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerContactEdit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && message.type.length)
                for (var i = 0; i < message.type.length; ++i)
                    $root.PlatformAdminApi.ContactSelectOption.encode(message.type[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isMain);
            return writer;
        };

        /**
         * Decodes a CustomerContactEdit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerContactEdit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerContactEdit} CustomerContactEdit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerContactEdit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerContactEdit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.type && message.type.length))
                        message.type = [];
                    message.type.push($root.PlatformAdminApi.ContactSelectOption.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.isMain = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerContactEdit;
    })();

    PlatformAdminApi.ContactSelectOption = (function() {

        /**
         * Properties of a ContactSelectOption.
         * @memberof PlatformAdminApi
         * @interface IContactSelectOption
         * @property {string|null} [label] ContactSelectOption label
         * @property {PlatformAdminApi.CONTACT_TYPE|null} [name] ContactSelectOption name
         */

        /**
         * Constructs a new ContactSelectOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContactSelectOption.
         * @implements IContactSelectOption
         * @constructor
         * @param {PlatformAdminApi.IContactSelectOption=} [properties] Properties to set
         */
        function ContactSelectOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactSelectOption label.
         * @member {string} label
         * @memberof PlatformAdminApi.ContactSelectOption
         * @instance
         */
        ContactSelectOption.prototype.label = "";

        /**
         * ContactSelectOption name.
         * @member {PlatformAdminApi.CONTACT_TYPE} name
         * @memberof PlatformAdminApi.ContactSelectOption
         * @instance
         */
        ContactSelectOption.prototype.name = 0;

        /**
         * Encodes the specified ContactSelectOption message. Does not implicitly {@link PlatformAdminApi.ContactSelectOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContactSelectOption
         * @static
         * @param {PlatformAdminApi.IContactSelectOption} message ContactSelectOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContactSelectOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.name);
            return writer;
        };

        /**
         * Decodes a ContactSelectOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContactSelectOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContactSelectOption} ContactSelectOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContactSelectOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContactSelectOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.name = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContactSelectOption;
    })();

    PlatformAdminApi.CustomerContactUpdateRequest = (function() {

        /**
         * Properties of a CustomerContactUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface ICustomerContactUpdateRequest
         * @property {string|null} [customerId] CustomerContactUpdateRequest customerId
         * @property {Array.<PlatformAdminApi.ICustomerContact>|null} [contacts] CustomerContactUpdateRequest contacts
         */

        /**
         * Constructs a new CustomerContactUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerContactUpdateRequest.
         * @implements ICustomerContactUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.ICustomerContactUpdateRequest=} [properties] Properties to set
         */
        function CustomerContactUpdateRequest(properties) {
            this.contacts = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerContactUpdateRequest customerId.
         * @member {string} customerId
         * @memberof PlatformAdminApi.CustomerContactUpdateRequest
         * @instance
         */
        CustomerContactUpdateRequest.prototype.customerId = "";

        /**
         * CustomerContactUpdateRequest contacts.
         * @member {Array.<PlatformAdminApi.ICustomerContact>} contacts
         * @memberof PlatformAdminApi.CustomerContactUpdateRequest
         * @instance
         */
        CustomerContactUpdateRequest.prototype.contacts = $util.emptyArray;

        /**
         * Encodes the specified CustomerContactUpdateRequest message. Does not implicitly {@link PlatformAdminApi.CustomerContactUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerContactUpdateRequest
         * @static
         * @param {PlatformAdminApi.ICustomerContactUpdateRequest} message CustomerContactUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerContactUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
            if (message.contacts != null && message.contacts.length)
                for (var i = 0; i < message.contacts.length; ++i)
                    $root.PlatformAdminApi.CustomerContact.encode(message.contacts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerContactUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerContactUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerContactUpdateRequest} CustomerContactUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerContactUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerContactUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.customerId = reader.string();
                    break;
                case 2:
                    if (!(message.contacts && message.contacts.length))
                        message.contacts = [];
                    message.contacts.push($root.PlatformAdminApi.CustomerContact.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerContactUpdateRequest;
    })();

    PlatformAdminApi.CustomerContactDeleteRequest = (function() {

        /**
         * Properties of a CustomerContactDeleteRequest.
         * @memberof PlatformAdminApi
         * @interface ICustomerContactDeleteRequest
         * @property {string|null} [userId] CustomerContactDeleteRequest userId
         * @property {string|null} [contactId] CustomerContactDeleteRequest contactId
         */

        /**
         * Constructs a new CustomerContactDeleteRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerContactDeleteRequest.
         * @implements ICustomerContactDeleteRequest
         * @constructor
         * @param {PlatformAdminApi.ICustomerContactDeleteRequest=} [properties] Properties to set
         */
        function CustomerContactDeleteRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerContactDeleteRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.CustomerContactDeleteRequest
         * @instance
         */
        CustomerContactDeleteRequest.prototype.userId = "";

        /**
         * CustomerContactDeleteRequest contactId.
         * @member {string} contactId
         * @memberof PlatformAdminApi.CustomerContactDeleteRequest
         * @instance
         */
        CustomerContactDeleteRequest.prototype.contactId = "";

        /**
         * Encodes the specified CustomerContactDeleteRequest message. Does not implicitly {@link PlatformAdminApi.CustomerContactDeleteRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerContactDeleteRequest
         * @static
         * @param {PlatformAdminApi.ICustomerContactDeleteRequest} message CustomerContactDeleteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerContactDeleteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.contactId != null && Object.hasOwnProperty.call(message, "contactId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contactId);
            return writer;
        };

        /**
         * Decodes a CustomerContactDeleteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerContactDeleteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerContactDeleteRequest} CustomerContactDeleteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerContactDeleteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerContactDeleteRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.contactId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerContactDeleteRequest;
    })();

    PlatformAdminApi.CustomerContactCreateRequest = (function() {

        /**
         * Properties of a CustomerContactCreateRequest.
         * @memberof PlatformAdminApi
         * @interface ICustomerContactCreateRequest
         * @property {string|null} [userId] CustomerContactCreateRequest userId
         * @property {PlatformAdminApi.ICustomerContactCreate|null} [contact] CustomerContactCreateRequest contact
         */

        /**
         * Constructs a new CustomerContactCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerContactCreateRequest.
         * @implements ICustomerContactCreateRequest
         * @constructor
         * @param {PlatformAdminApi.ICustomerContactCreateRequest=} [properties] Properties to set
         */
        function CustomerContactCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerContactCreateRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.CustomerContactCreateRequest
         * @instance
         */
        CustomerContactCreateRequest.prototype.userId = "";

        /**
         * CustomerContactCreateRequest contact.
         * @member {PlatformAdminApi.ICustomerContactCreate|null|undefined} contact
         * @memberof PlatformAdminApi.CustomerContactCreateRequest
         * @instance
         */
        CustomerContactCreateRequest.prototype.contact = null;

        /**
         * Encodes the specified CustomerContactCreateRequest message. Does not implicitly {@link PlatformAdminApi.CustomerContactCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerContactCreateRequest
         * @static
         * @param {PlatformAdminApi.ICustomerContactCreateRequest} message CustomerContactCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerContactCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.contact != null && Object.hasOwnProperty.call(message, "contact"))
                $root.PlatformAdminApi.CustomerContactCreate.encode(message.contact, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerContactCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerContactCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerContactCreateRequest} CustomerContactCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerContactCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerContactCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.contact = $root.PlatformAdminApi.CustomerContactCreate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerContactCreateRequest;
    })();

    PlatformAdminApi.CustomerContactCreate = (function() {

        /**
         * Properties of a CustomerContactCreate.
         * @memberof PlatformAdminApi
         * @interface ICustomerContactCreate
         * @property {PlatformAdminApi.CONTACT_TYPE|null} [type] CustomerContactCreate type
         * @property {string|null} [value] CustomerContactCreate value
         */

        /**
         * Constructs a new CustomerContactCreate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerContactCreate.
         * @implements ICustomerContactCreate
         * @constructor
         * @param {PlatformAdminApi.ICustomerContactCreate=} [properties] Properties to set
         */
        function CustomerContactCreate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerContactCreate type.
         * @member {PlatformAdminApi.CONTACT_TYPE} type
         * @memberof PlatformAdminApi.CustomerContactCreate
         * @instance
         */
        CustomerContactCreate.prototype.type = 0;

        /**
         * CustomerContactCreate value.
         * @member {string} value
         * @memberof PlatformAdminApi.CustomerContactCreate
         * @instance
         */
        CustomerContactCreate.prototype.value = "";

        /**
         * Encodes the specified CustomerContactCreate message. Does not implicitly {@link PlatformAdminApi.CustomerContactCreate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerContactCreate
         * @static
         * @param {PlatformAdminApi.ICustomerContactCreate} message CustomerContactCreate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerContactCreate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Decodes a CustomerContactCreate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerContactCreate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerContactCreate} CustomerContactCreate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerContactCreate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerContactCreate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerContactCreate;
    })();

    PlatformAdminApi.RetrieveCrmOrdersRequest = (function() {

        /**
         * Properties of a RetrieveCrmOrdersRequest.
         * @memberof PlatformAdminApi
         * @interface IRetrieveCrmOrdersRequest
         * @property {number|null} [page] RetrieveCrmOrdersRequest page
         * @property {number|null} [limit] RetrieveCrmOrdersRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] RetrieveCrmOrdersRequest sort
         * @property {Array.<PlatformAdminApi.RetrieveCrmOrdersRequest.IWhereFields>|null} [where] RetrieveCrmOrdersRequest where
         */

        /**
         * Constructs a new RetrieveCrmOrdersRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RetrieveCrmOrdersRequest.
         * @implements IRetrieveCrmOrdersRequest
         * @constructor
         * @param {PlatformAdminApi.IRetrieveCrmOrdersRequest=} [properties] Properties to set
         */
        function RetrieveCrmOrdersRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveCrmOrdersRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
         * @instance
         */
        RetrieveCrmOrdersRequest.prototype.page = 0;

        /**
         * RetrieveCrmOrdersRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
         * @instance
         */
        RetrieveCrmOrdersRequest.prototype.limit = 0;

        /**
         * RetrieveCrmOrdersRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
         * @instance
         */
        RetrieveCrmOrdersRequest.prototype.sort = $util.emptyArray;

        /**
         * RetrieveCrmOrdersRequest where.
         * @member {Array.<PlatformAdminApi.RetrieveCrmOrdersRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
         * @instance
         */
        RetrieveCrmOrdersRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified RetrieveCrmOrdersRequest message. Does not implicitly {@link PlatformAdminApi.RetrieveCrmOrdersRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
         * @static
         * @param {PlatformAdminApi.IRetrieveCrmOrdersRequest} message RetrieveCrmOrdersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveCrmOrdersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RetrieveCrmOrdersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RetrieveCrmOrdersRequest} RetrieveCrmOrdersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveCrmOrdersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RetrieveCrmOrdersRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RetrieveCrmOrdersRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
             * @interface IWhereFields
             * @property {string|null} [customerId] WhereFields customerId
             * @property {Array.<plt.core.base.ORDER_STATUS_TYPE>|null} [currentStatusType] WhereFields currentStatusType
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.RetrieveCrmOrdersRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                this.currentStatusType = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields customerId.
             * @member {string} customerId
             * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.customerId = "";

            /**
             * WhereFields currentStatusType.
             * @member {Array.<plt.core.base.ORDER_STATUS_TYPE>} currentStatusType
             * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.currentStatusType = $util.emptyArray;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.RetrieveCrmOrdersRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
                if (message.currentStatusType != null && message.currentStatusType.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.currentStatusType.length; ++i)
                        writer.int32(message.currentStatusType[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RetrieveCrmOrdersRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.customerId = reader.string();
                        break;
                    case 2:
                        if (!(message.currentStatusType && message.currentStatusType.length))
                            message.currentStatusType = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.currentStatusType.push(reader.int32());
                        } else
                            message.currentStatusType.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return RetrieveCrmOrdersRequest;
    })();

    PlatformAdminApi.CustomerOrdersListResponse = (function() {

        /**
         * Properties of a CustomerOrdersListResponse.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrdersListResponse
         * @property {Array.<PlatformAdminApi.ICustomerOrdersListItem>|null} [orders] CustomerOrdersListResponse orders
         * @property {Array.<PlatformAdminApi.ICustomerOrdersListItem>|null} [data] CustomerOrdersListResponse data
         * @property {number|null} [page] CustomerOrdersListResponse page
         * @property {number|null} [limit] CustomerOrdersListResponse limit
         * @property {number|null} [total] CustomerOrdersListResponse total
         */

        /**
         * Constructs a new CustomerOrdersListResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrdersListResponse.
         * @implements ICustomerOrdersListResponse
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrdersListResponse=} [properties] Properties to set
         */
        function CustomerOrdersListResponse(properties) {
            this.orders = [];
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrdersListResponse orders.
         * @member {Array.<PlatformAdminApi.ICustomerOrdersListItem>} orders
         * @memberof PlatformAdminApi.CustomerOrdersListResponse
         * @instance
         */
        CustomerOrdersListResponse.prototype.orders = $util.emptyArray;

        /**
         * CustomerOrdersListResponse data.
         * @member {Array.<PlatformAdminApi.ICustomerOrdersListItem>} data
         * @memberof PlatformAdminApi.CustomerOrdersListResponse
         * @instance
         */
        CustomerOrdersListResponse.prototype.data = $util.emptyArray;

        /**
         * CustomerOrdersListResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.CustomerOrdersListResponse
         * @instance
         */
        CustomerOrdersListResponse.prototype.page = 0;

        /**
         * CustomerOrdersListResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.CustomerOrdersListResponse
         * @instance
         */
        CustomerOrdersListResponse.prototype.limit = 0;

        /**
         * CustomerOrdersListResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.CustomerOrdersListResponse
         * @instance
         */
        CustomerOrdersListResponse.prototype.total = 0;

        /**
         * Encodes the specified CustomerOrdersListResponse message. Does not implicitly {@link PlatformAdminApi.CustomerOrdersListResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrdersListResponse
         * @static
         * @param {PlatformAdminApi.ICustomerOrdersListResponse} message CustomerOrdersListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrdersListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orders != null && message.orders.length)
                for (var i = 0; i < message.orders.length; ++i)
                    $root.PlatformAdminApi.CustomerOrdersListItem.encode(message.orders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.CustomerOrdersListItem.encode(message.data[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.total);
            return writer;
        };

        /**
         * Decodes a CustomerOrdersListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrdersListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrdersListResponse} CustomerOrdersListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrdersListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrdersListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.orders && message.orders.length))
                        message.orders = [];
                    message.orders.push($root.PlatformAdminApi.CustomerOrdersListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.CustomerOrdersListItem.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.page = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrdersListResponse;
    })();

    PlatformAdminApi.CustomerOrdersListItem = (function() {

        /**
         * Properties of a CustomerOrdersListItem.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrdersListItem
         * @property {PlatformAdminApi.ICustomerOrderCart|null} [cart] CustomerOrdersListItem cart
         * @property {PlatformAdminApi.ICustomerOrderAddress|null} [address] CustomerOrdersListItem address
         * @property {PlatformAdminApi.ICustomerOrderItem|null} [order] CustomerOrdersListItem order
         * @property {Array.<PlatformAdminApi.ICustomerAssignmentItem>|null} [assignments] CustomerOrdersListItem assignments
         * @property {PlatformAdminApi.ICustomerOrderCost|null} [cost] CustomerOrdersListItem cost
         */

        /**
         * Constructs a new CustomerOrdersListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrdersListItem.
         * @implements ICustomerOrdersListItem
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrdersListItem=} [properties] Properties to set
         */
        function CustomerOrdersListItem(properties) {
            this.assignments = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrdersListItem cart.
         * @member {PlatformAdminApi.ICustomerOrderCart|null|undefined} cart
         * @memberof PlatformAdminApi.CustomerOrdersListItem
         * @instance
         */
        CustomerOrdersListItem.prototype.cart = null;

        /**
         * CustomerOrdersListItem address.
         * @member {PlatformAdminApi.ICustomerOrderAddress|null|undefined} address
         * @memberof PlatformAdminApi.CustomerOrdersListItem
         * @instance
         */
        CustomerOrdersListItem.prototype.address = null;

        /**
         * CustomerOrdersListItem order.
         * @member {PlatformAdminApi.ICustomerOrderItem|null|undefined} order
         * @memberof PlatformAdminApi.CustomerOrdersListItem
         * @instance
         */
        CustomerOrdersListItem.prototype.order = null;

        /**
         * CustomerOrdersListItem assignments.
         * @member {Array.<PlatformAdminApi.ICustomerAssignmentItem>} assignments
         * @memberof PlatformAdminApi.CustomerOrdersListItem
         * @instance
         */
        CustomerOrdersListItem.prototype.assignments = $util.emptyArray;

        /**
         * CustomerOrdersListItem cost.
         * @member {PlatformAdminApi.ICustomerOrderCost|null|undefined} cost
         * @memberof PlatformAdminApi.CustomerOrdersListItem
         * @instance
         */
        CustomerOrdersListItem.prototype.cost = null;

        /**
         * Encodes the specified CustomerOrdersListItem message. Does not implicitly {@link PlatformAdminApi.CustomerOrdersListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrdersListItem
         * @static
         * @param {PlatformAdminApi.ICustomerOrdersListItem} message CustomerOrdersListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrdersListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cart != null && Object.hasOwnProperty.call(message, "cart"))
                $root.PlatformAdminApi.CustomerOrderCart.encode(message.cart, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.CustomerOrderAddress.encode(message.address, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                $root.PlatformAdminApi.CustomerOrderItem.encode(message.order, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.assignments != null && message.assignments.length)
                for (var i = 0; i < message.assignments.length; ++i)
                    $root.PlatformAdminApi.CustomerAssignmentItem.encode(message.assignments[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                $root.PlatformAdminApi.CustomerOrderCost.encode(message.cost, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerOrdersListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrdersListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrdersListItem} CustomerOrdersListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrdersListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrdersListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cart = $root.PlatformAdminApi.CustomerOrderCart.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.address = $root.PlatformAdminApi.CustomerOrderAddress.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.order = $root.PlatformAdminApi.CustomerOrderItem.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.assignments && message.assignments.length))
                        message.assignments = [];
                    message.assignments.push($root.PlatformAdminApi.CustomerAssignmentItem.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.cost = $root.PlatformAdminApi.CustomerOrderCost.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrdersListItem;
    })();

    PlatformAdminApi.CustomerOrderCart = (function() {

        /**
         * Properties of a CustomerOrderCart.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderCart
         * @property {string|null} [cartId] CustomerOrderCart cartId
         * @property {string|null} [createdAt] CustomerOrderCart createdAt
         * @property {string|null} [updatedAt] CustomerOrderCart updatedAt
         * @property {string|null} [deletedAt] CustomerOrderCart deletedAt
         * @property {PlatformAdminApi.OrderCartStatus|null} [status] CustomerOrderCart status
         * @property {boolean|null} [isFirstOrderInCart] CustomerOrderCart isFirstOrderInCart
         * @property {number|null} [ordersCount] CustomerOrderCart ordersCount
         * @property {number|null} [serialNumber] CustomerOrderCart serialNumber
         */

        /**
         * Constructs a new CustomerOrderCart.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderCart.
         * @implements ICustomerOrderCart
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderCart=} [properties] Properties to set
         */
        function CustomerOrderCart(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderCart cartId.
         * @member {string} cartId
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.cartId = "";

        /**
         * CustomerOrderCart createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.createdAt = "";

        /**
         * CustomerOrderCart updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.updatedAt = "";

        /**
         * CustomerOrderCart deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.deletedAt = "";

        /**
         * CustomerOrderCart status.
         * @member {PlatformAdminApi.OrderCartStatus} status
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.status = 0;

        /**
         * CustomerOrderCart isFirstOrderInCart.
         * @member {boolean} isFirstOrderInCart
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.isFirstOrderInCart = false;

        /**
         * CustomerOrderCart ordersCount.
         * @member {number} ordersCount
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.ordersCount = 0;

        /**
         * CustomerOrderCart serialNumber.
         * @member {number} serialNumber
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @instance
         */
        CustomerOrderCart.prototype.serialNumber = 0;

        /**
         * Encodes the specified CustomerOrderCart message. Does not implicitly {@link PlatformAdminApi.CustomerOrderCart.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @static
         * @param {PlatformAdminApi.ICustomerOrderCart} message CustomerOrderCart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderCart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cartId != null && Object.hasOwnProperty.call(message, "cartId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cartId);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.deletedAt);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.isFirstOrderInCart != null && Object.hasOwnProperty.call(message, "isFirstOrderInCart"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isFirstOrderInCart);
            if (message.ordersCount != null && Object.hasOwnProperty.call(message, "ordersCount"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.ordersCount);
            if (message.serialNumber != null && Object.hasOwnProperty.call(message, "serialNumber"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.serialNumber);
            return writer;
        };

        /**
         * Decodes a CustomerOrderCart message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderCart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderCart} CustomerOrderCart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderCart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderCart();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cartId = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.deletedAt = reader.string();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.isFirstOrderInCart = reader.bool();
                    break;
                case 7:
                    message.ordersCount = reader.int32();
                    break;
                case 8:
                    message.serialNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderCart;
    })();

    PlatformAdminApi.CustomerOrderItem = (function() {

        /**
         * Properties of a CustomerOrderItem.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderItem
         * @property {string|null} [orderId] CustomerOrderItem orderId
         * @property {string|null} [title] CustomerOrderItem title
         * @property {string|null} [startDate] CustomerOrderItem startDate
         * @property {string|null} [completedAt] CustomerOrderItem completedAt
         * @property {Array.<PlatformAdminApi.ICustomerOrderOption>|null} [options] CustomerOrderItem options
         * @property {number|null} [remainingOptionsCount] CustomerOrderItem remainingOptionsCount
         * @property {string|null} [statusName] CustomerOrderItem statusName
         * @property {PlatformAdminApi.OrderStatusCategory|null} [statusCategory] CustomerOrderItem statusCategory
         * @property {number|null} [serialNumber] CustomerOrderItem serialNumber
         * @property {boolean|null} [isExternal] CustomerOrderItem isExternal
         * @property {string|null} [externalDetailsPageUrl] CustomerOrderItem externalDetailsPageUrl
         * @property {string|null} [customerId] CustomerOrderItem customerId
         * @property {PlatformAdminApi.ICustomerOrderMeta|null} [orderMeta] CustomerOrderItem orderMeta
         * @property {PlatformAdminApi.IRejectionReason|null} [rejectionReason] CustomerOrderItem rejectionReason
         * @property {plt.core.base.PAYMENT_STATUS_TYPE|null} [paymentStatus] CustomerOrderItem paymentStatus
         */

        /**
         * Constructs a new CustomerOrderItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderItem.
         * @implements ICustomerOrderItem
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderItem=} [properties] Properties to set
         */
        function CustomerOrderItem(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderItem orderId.
         * @member {string} orderId
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.orderId = "";

        /**
         * CustomerOrderItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.title = "";

        /**
         * CustomerOrderItem startDate.
         * @member {string} startDate
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.startDate = "";

        /**
         * CustomerOrderItem completedAt.
         * @member {string} completedAt
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.completedAt = "";

        /**
         * CustomerOrderItem options.
         * @member {Array.<PlatformAdminApi.ICustomerOrderOption>} options
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.options = $util.emptyArray;

        /**
         * CustomerOrderItem remainingOptionsCount.
         * @member {number} remainingOptionsCount
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.remainingOptionsCount = 0;

        /**
         * CustomerOrderItem statusName.
         * @member {string} statusName
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.statusName = "";

        /**
         * CustomerOrderItem statusCategory.
         * @member {PlatformAdminApi.OrderStatusCategory} statusCategory
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.statusCategory = 0;

        /**
         * CustomerOrderItem serialNumber.
         * @member {number} serialNumber
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.serialNumber = 0;

        /**
         * CustomerOrderItem isExternal.
         * @member {boolean} isExternal
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.isExternal = false;

        /**
         * CustomerOrderItem externalDetailsPageUrl.
         * @member {string} externalDetailsPageUrl
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.externalDetailsPageUrl = "";

        /**
         * CustomerOrderItem customerId.
         * @member {string} customerId
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.customerId = "";

        /**
         * CustomerOrderItem orderMeta.
         * @member {PlatformAdminApi.ICustomerOrderMeta|null|undefined} orderMeta
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.orderMeta = null;

        /**
         * CustomerOrderItem rejectionReason.
         * @member {PlatformAdminApi.IRejectionReason|null|undefined} rejectionReason
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.rejectionReason = null;

        /**
         * CustomerOrderItem paymentStatus.
         * @member {plt.core.base.PAYMENT_STATUS_TYPE} paymentStatus
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @instance
         */
        CustomerOrderItem.prototype.paymentStatus = 0;

        /**
         * Encodes the specified CustomerOrderItem message. Does not implicitly {@link PlatformAdminApi.CustomerOrderItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @static
         * @param {PlatformAdminApi.ICustomerOrderItem} message CustomerOrderItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDate);
            if (message.completedAt != null && Object.hasOwnProperty.call(message, "completedAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.completedAt);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.CustomerOrderOption.encode(message.options[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.remainingOptionsCount != null && Object.hasOwnProperty.call(message, "remainingOptionsCount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.remainingOptionsCount);
            if (message.statusName != null && Object.hasOwnProperty.call(message, "statusName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.statusName);
            if (message.statusCategory != null && Object.hasOwnProperty.call(message, "statusCategory"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.statusCategory);
            if (message.serialNumber != null && Object.hasOwnProperty.call(message, "serialNumber"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.serialNumber);
            if (message.isExternal != null && Object.hasOwnProperty.call(message, "isExternal"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isExternal);
            if (message.externalDetailsPageUrl != null && Object.hasOwnProperty.call(message, "externalDetailsPageUrl"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.externalDetailsPageUrl);
            if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.customerId);
            if (message.orderMeta != null && Object.hasOwnProperty.call(message, "orderMeta"))
                $root.PlatformAdminApi.CustomerOrderMeta.encode(message.orderMeta, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.rejectionReason != null && Object.hasOwnProperty.call(message, "rejectionReason"))
                $root.PlatformAdminApi.RejectionReason.encode(message.rejectionReason, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 15, wireType 0 =*/120).int32(message.paymentStatus);
            return writer;
        };

        /**
         * Decodes a CustomerOrderItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderItem} CustomerOrderItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.startDate = reader.string();
                    break;
                case 4:
                    message.completedAt = reader.string();
                    break;
                case 5:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.CustomerOrderOption.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.remainingOptionsCount = reader.int32();
                    break;
                case 7:
                    message.statusName = reader.string();
                    break;
                case 8:
                    message.statusCategory = reader.int32();
                    break;
                case 9:
                    message.serialNumber = reader.int32();
                    break;
                case 10:
                    message.isExternal = reader.bool();
                    break;
                case 11:
                    message.externalDetailsPageUrl = reader.string();
                    break;
                case 12:
                    message.customerId = reader.string();
                    break;
                case 13:
                    message.orderMeta = $root.PlatformAdminApi.CustomerOrderMeta.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.rejectionReason = $root.PlatformAdminApi.RejectionReason.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.paymentStatus = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderItem;
    })();

    PlatformAdminApi.CustomerOrderMeta = (function() {

        /**
         * Properties of a CustomerOrderMeta.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderMeta
         * @property {string|null} [drycleaningReturnAt] CustomerOrderMeta drycleaningReturnAt
         * @property {number|null} [drycleaningSerialNumber] CustomerOrderMeta drycleaningSerialNumber
         */

        /**
         * Constructs a new CustomerOrderMeta.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderMeta.
         * @implements ICustomerOrderMeta
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderMeta=} [properties] Properties to set
         */
        function CustomerOrderMeta(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderMeta drycleaningReturnAt.
         * @member {string} drycleaningReturnAt
         * @memberof PlatformAdminApi.CustomerOrderMeta
         * @instance
         */
        CustomerOrderMeta.prototype.drycleaningReturnAt = "";

        /**
         * CustomerOrderMeta drycleaningSerialNumber.
         * @member {number} drycleaningSerialNumber
         * @memberof PlatformAdminApi.CustomerOrderMeta
         * @instance
         */
        CustomerOrderMeta.prototype.drycleaningSerialNumber = 0;

        /**
         * Encodes the specified CustomerOrderMeta message. Does not implicitly {@link PlatformAdminApi.CustomerOrderMeta.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderMeta
         * @static
         * @param {PlatformAdminApi.ICustomerOrderMeta} message CustomerOrderMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.drycleaningReturnAt != null && Object.hasOwnProperty.call(message, "drycleaningReturnAt"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.drycleaningReturnAt);
            if (message.drycleaningSerialNumber != null && Object.hasOwnProperty.call(message, "drycleaningSerialNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.drycleaningSerialNumber);
            return writer;
        };

        /**
         * Decodes a CustomerOrderMeta message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderMeta} CustomerOrderMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderMeta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.drycleaningReturnAt = reader.string();
                    break;
                case 2:
                    message.drycleaningSerialNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderMeta;
    })();

    PlatformAdminApi.CustomerOrderOption = (function() {

        /**
         * Properties of a CustomerOrderOption.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderOption
         * @property {string|null} [name] CustomerOrderOption name
         * @property {number|null} [value] CustomerOrderOption value
         * @property {number|null} [factor] CustomerOrderOption factor
         * @property {number|null} [totalPrice] CustomerOrderOption totalPrice
         */

        /**
         * Constructs a new CustomerOrderOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderOption.
         * @implements ICustomerOrderOption
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderOption=} [properties] Properties to set
         */
        function CustomerOrderOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderOption name.
         * @member {string} name
         * @memberof PlatformAdminApi.CustomerOrderOption
         * @instance
         */
        CustomerOrderOption.prototype.name = "";

        /**
         * CustomerOrderOption value.
         * @member {number} value
         * @memberof PlatformAdminApi.CustomerOrderOption
         * @instance
         */
        CustomerOrderOption.prototype.value = 0;

        /**
         * CustomerOrderOption factor.
         * @member {number} factor
         * @memberof PlatformAdminApi.CustomerOrderOption
         * @instance
         */
        CustomerOrderOption.prototype.factor = 0;

        /**
         * CustomerOrderOption totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformAdminApi.CustomerOrderOption
         * @instance
         */
        CustomerOrderOption.prototype.totalPrice = 0;

        /**
         * Encodes the specified CustomerOrderOption message. Does not implicitly {@link PlatformAdminApi.CustomerOrderOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderOption
         * @static
         * @param {PlatformAdminApi.ICustomerOrderOption} message CustomerOrderOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.factor);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalPrice);
            return writer;
        };

        /**
         * Decodes a CustomerOrderOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderOption} CustomerOrderOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.factor = reader.int32();
                    break;
                case 4:
                    message.totalPrice = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderOption;
    })();

    PlatformAdminApi.CustomerAssignmentItem = (function() {

        /**
         * Properties of a CustomerAssignmentItem.
         * @memberof PlatformAdminApi
         * @interface ICustomerAssignmentItem
         * @property {PlatformAdminApi.ICustomerAssignmentContractor|null} [contractor] CustomerAssignmentItem contractor
         * @property {PlatformAdminApi.OrderAssignmentStatus|null} [status] CustomerAssignmentItem status
         * @property {string|null} [startAt] CustomerAssignmentItem startAt
         * @property {string|null} [endAt] CustomerAssignmentItem endAt
         * @property {PlatformAdminApi.ICustomerAssignmentWaypoint|null} [waypoint] CustomerAssignmentItem waypoint
         */

        /**
         * Constructs a new CustomerAssignmentItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAssignmentItem.
         * @implements ICustomerAssignmentItem
         * @constructor
         * @param {PlatformAdminApi.ICustomerAssignmentItem=} [properties] Properties to set
         */
        function CustomerAssignmentItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAssignmentItem contractor.
         * @member {PlatformAdminApi.ICustomerAssignmentContractor|null|undefined} contractor
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @instance
         */
        CustomerAssignmentItem.prototype.contractor = null;

        /**
         * CustomerAssignmentItem status.
         * @member {PlatformAdminApi.OrderAssignmentStatus} status
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @instance
         */
        CustomerAssignmentItem.prototype.status = 0;

        /**
         * CustomerAssignmentItem startAt.
         * @member {string} startAt
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @instance
         */
        CustomerAssignmentItem.prototype.startAt = "";

        /**
         * CustomerAssignmentItem endAt.
         * @member {string} endAt
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @instance
         */
        CustomerAssignmentItem.prototype.endAt = "";

        /**
         * CustomerAssignmentItem waypoint.
         * @member {PlatformAdminApi.ICustomerAssignmentWaypoint|null|undefined} waypoint
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @instance
         */
        CustomerAssignmentItem.prototype.waypoint = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CustomerAssignmentItem _waypoint.
         * @member {"waypoint"|undefined} _waypoint
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @instance
         */
        Object.defineProperty(CustomerAssignmentItem.prototype, "_waypoint", {
            get: $util.oneOfGetter($oneOfFields = ["waypoint"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified CustomerAssignmentItem message. Does not implicitly {@link PlatformAdminApi.CustomerAssignmentItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @static
         * @param {PlatformAdminApi.ICustomerAssignmentItem} message CustomerAssignmentItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAssignmentItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contractor != null && Object.hasOwnProperty.call(message, "contractor"))
                $root.PlatformAdminApi.CustomerAssignmentContractor.encode(message.contractor, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            if (message.startAt != null && Object.hasOwnProperty.call(message, "startAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startAt);
            if (message.endAt != null && Object.hasOwnProperty.call(message, "endAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.endAt);
            if (message.waypoint != null && Object.hasOwnProperty.call(message, "waypoint"))
                $root.PlatformAdminApi.CustomerAssignmentWaypoint.encode(message.waypoint, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerAssignmentItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAssignmentItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAssignmentItem} CustomerAssignmentItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAssignmentItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAssignmentItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.contractor = $root.PlatformAdminApi.CustomerAssignmentContractor.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.status = reader.int32();
                    break;
                case 3:
                    message.startAt = reader.string();
                    break;
                case 4:
                    message.endAt = reader.string();
                    break;
                case 5:
                    message.waypoint = $root.PlatformAdminApi.CustomerAssignmentWaypoint.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAssignmentItem;
    })();

    /**
     * CustomerAssignmentWaypointType enum.
     * @name PlatformAdminApi.CustomerAssignmentWaypointType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} PICKUP=1 PICKUP value
     * @property {number} DELIVERY=2 DELIVERY value
     */
    PlatformAdminApi.CustomerAssignmentWaypointType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "PICKUP"] = 1;
        values[valuesById[2] = "DELIVERY"] = 2;
        return values;
    })();

    PlatformAdminApi.CustomerAssignmentWaypoint = (function() {

        /**
         * Properties of a CustomerAssignmentWaypoint.
         * @memberof PlatformAdminApi
         * @interface ICustomerAssignmentWaypoint
         * @property {PlatformAdminApi.CustomerAssignmentWaypointType|null} [type] CustomerAssignmentWaypoint type
         */

        /**
         * Constructs a new CustomerAssignmentWaypoint.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAssignmentWaypoint.
         * @implements ICustomerAssignmentWaypoint
         * @constructor
         * @param {PlatformAdminApi.ICustomerAssignmentWaypoint=} [properties] Properties to set
         */
        function CustomerAssignmentWaypoint(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAssignmentWaypoint type.
         * @member {PlatformAdminApi.CustomerAssignmentWaypointType} type
         * @memberof PlatformAdminApi.CustomerAssignmentWaypoint
         * @instance
         */
        CustomerAssignmentWaypoint.prototype.type = 0;

        /**
         * Encodes the specified CustomerAssignmentWaypoint message. Does not implicitly {@link PlatformAdminApi.CustomerAssignmentWaypoint.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAssignmentWaypoint
         * @static
         * @param {PlatformAdminApi.ICustomerAssignmentWaypoint} message CustomerAssignmentWaypoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAssignmentWaypoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            return writer;
        };

        /**
         * Decodes a CustomerAssignmentWaypoint message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAssignmentWaypoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAssignmentWaypoint} CustomerAssignmentWaypoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAssignmentWaypoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAssignmentWaypoint();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAssignmentWaypoint;
    })();

    PlatformAdminApi.CustomerAssignmentContractor = (function() {

        /**
         * Properties of a CustomerAssignmentContractor.
         * @memberof PlatformAdminApi
         * @interface ICustomerAssignmentContractor
         * @property {string|null} [id] CustomerAssignmentContractor id
         * @property {string|null} [type] CustomerAssignmentContractor type
         * @property {string|null} [fullName] CustomerAssignmentContractor fullName
         */

        /**
         * Constructs a new CustomerAssignmentContractor.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerAssignmentContractor.
         * @implements ICustomerAssignmentContractor
         * @constructor
         * @param {PlatformAdminApi.ICustomerAssignmentContractor=} [properties] Properties to set
         */
        function CustomerAssignmentContractor(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerAssignmentContractor id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerAssignmentContractor
         * @instance
         */
        CustomerAssignmentContractor.prototype.id = "";

        /**
         * CustomerAssignmentContractor type.
         * @member {string} type
         * @memberof PlatformAdminApi.CustomerAssignmentContractor
         * @instance
         */
        CustomerAssignmentContractor.prototype.type = "";

        /**
         * CustomerAssignmentContractor fullName.
         * @member {string} fullName
         * @memberof PlatformAdminApi.CustomerAssignmentContractor
         * @instance
         */
        CustomerAssignmentContractor.prototype.fullName = "";

        /**
         * Encodes the specified CustomerAssignmentContractor message. Does not implicitly {@link PlatformAdminApi.CustomerAssignmentContractor.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerAssignmentContractor
         * @static
         * @param {PlatformAdminApi.ICustomerAssignmentContractor} message CustomerAssignmentContractor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerAssignmentContractor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fullName);
            return writer;
        };

        /**
         * Decodes a CustomerAssignmentContractor message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerAssignmentContractor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerAssignmentContractor} CustomerAssignmentContractor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerAssignmentContractor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerAssignmentContractor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.string();
                    break;
                case 3:
                    message.fullName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerAssignmentContractor;
    })();

    PlatformAdminApi.CustomerOrderCost = (function() {

        /**
         * Properties of a CustomerOrderCost.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderCost
         * @property {number|null} [subTotalPrice] CustomerOrderCost subTotalPrice
         * @property {number|null} [discount] CustomerOrderCost discount
         * @property {number|null} [bonusesUsed] CustomerOrderCost bonusesUsed
         * @property {number|null} [totalPrice] CustomerOrderCost totalPrice
         * @property {PlatformAdminApi.PaymentType|null} [paymentType] CustomerOrderCost paymentType
         * @property {string|null} [cardNumber] CustomerOrderCost cardNumber
         * @property {PlatformAdminApi.PaymentSystem|null} [paymentSystem] CustomerOrderCost paymentSystem
         * @property {boolean|null} [isPaid] CustomerOrderCost isPaid
         */

        /**
         * Constructs a new CustomerOrderCost.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderCost.
         * @implements ICustomerOrderCost
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderCost=} [properties] Properties to set
         */
        function CustomerOrderCost(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderCost subTotalPrice.
         * @member {number} subTotalPrice
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.subTotalPrice = 0;

        /**
         * CustomerOrderCost discount.
         * @member {number} discount
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.discount = 0;

        /**
         * CustomerOrderCost bonusesUsed.
         * @member {number} bonusesUsed
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.bonusesUsed = 0;

        /**
         * CustomerOrderCost totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.totalPrice = 0;

        /**
         * CustomerOrderCost paymentType.
         * @member {PlatformAdminApi.PaymentType} paymentType
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.paymentType = 0;

        /**
         * CustomerOrderCost cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.cardNumber = "";

        /**
         * CustomerOrderCost paymentSystem.
         * @member {PlatformAdminApi.PaymentSystem} paymentSystem
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.paymentSystem = 0;

        /**
         * CustomerOrderCost isPaid.
         * @member {boolean} isPaid
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @instance
         */
        CustomerOrderCost.prototype.isPaid = false;

        /**
         * Encodes the specified CustomerOrderCost message. Does not implicitly {@link PlatformAdminApi.CustomerOrderCost.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @static
         * @param {PlatformAdminApi.ICustomerOrderCost} message CustomerOrderCost message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderCost.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.subTotalPrice != null && Object.hasOwnProperty.call(message, "subTotalPrice"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.subTotalPrice);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.discount);
            if (message.bonusesUsed != null && Object.hasOwnProperty.call(message, "bonusesUsed"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.bonusesUsed);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalPrice);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.paymentType);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.cardNumber);
            if (message.paymentSystem != null && Object.hasOwnProperty.call(message, "paymentSystem"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.paymentSystem);
            if (message.isPaid != null && Object.hasOwnProperty.call(message, "isPaid"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isPaid);
            return writer;
        };

        /**
         * Decodes a CustomerOrderCost message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderCost
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderCost} CustomerOrderCost
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderCost.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderCost();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.subTotalPrice = reader.int32();
                    break;
                case 2:
                    message.discount = reader.int32();
                    break;
                case 3:
                    message.bonusesUsed = reader.int32();
                    break;
                case 4:
                    message.totalPrice = reader.int32();
                    break;
                case 5:
                    message.paymentType = reader.int32();
                    break;
                case 6:
                    message.cardNumber = reader.string();
                    break;
                case 7:
                    message.paymentSystem = reader.int32();
                    break;
                case 8:
                    message.isPaid = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderCost;
    })();

    PlatformAdminApi.CustomerOrderAddress = (function() {

        /**
         * Properties of a CustomerOrderAddress.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderAddress
         * @property {string|null} [city] CustomerOrderAddress city
         * @property {Array.<PlatformAdminApi.ICustomerOrderMetro>|null} [metroStations] CustomerOrderAddress metroStations
         * @property {string|null} [value] CustomerOrderAddress value
         * @property {PlatformAdminApi.ICoordinates|null} [coordinates] CustomerOrderAddress coordinates
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] CustomerOrderAddress type
         */

        /**
         * Constructs a new CustomerOrderAddress.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderAddress.
         * @implements ICustomerOrderAddress
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderAddress=} [properties] Properties to set
         */
        function CustomerOrderAddress(properties) {
            this.metroStations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderAddress city.
         * @member {string} city
         * @memberof PlatformAdminApi.CustomerOrderAddress
         * @instance
         */
        CustomerOrderAddress.prototype.city = "";

        /**
         * CustomerOrderAddress metroStations.
         * @member {Array.<PlatformAdminApi.ICustomerOrderMetro>} metroStations
         * @memberof PlatformAdminApi.CustomerOrderAddress
         * @instance
         */
        CustomerOrderAddress.prototype.metroStations = $util.emptyArray;

        /**
         * CustomerOrderAddress value.
         * @member {string} value
         * @memberof PlatformAdminApi.CustomerOrderAddress
         * @instance
         */
        CustomerOrderAddress.prototype.value = "";

        /**
         * CustomerOrderAddress coordinates.
         * @member {PlatformAdminApi.ICoordinates|null|undefined} coordinates
         * @memberof PlatformAdminApi.CustomerOrderAddress
         * @instance
         */
        CustomerOrderAddress.prototype.coordinates = null;

        /**
         * CustomerOrderAddress type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.CustomerOrderAddress
         * @instance
         */
        CustomerOrderAddress.prototype.type = 0;

        /**
         * Encodes the specified CustomerOrderAddress message. Does not implicitly {@link PlatformAdminApi.CustomerOrderAddress.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderAddress
         * @static
         * @param {PlatformAdminApi.ICustomerOrderAddress} message CustomerOrderAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.city);
            if (message.metroStations != null && message.metroStations.length)
                for (var i = 0; i < message.metroStations.length; ++i)
                    $root.PlatformAdminApi.CustomerOrderMetro.encode(message.metroStations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.PlatformAdminApi.Coordinates.encode(message.coordinates, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
            return writer;
        };

        /**
         * Decodes a CustomerOrderAddress message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderAddress} CustomerOrderAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderAddress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.city = reader.string();
                    break;
                case 2:
                    if (!(message.metroStations && message.metroStations.length))
                        message.metroStations = [];
                    message.metroStations.push($root.PlatformAdminApi.CustomerOrderMetro.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.coordinates = $root.PlatformAdminApi.Coordinates.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderAddress;
    })();

    PlatformAdminApi.CustomerOrderMetro = (function() {

        /**
         * Properties of a CustomerOrderMetro.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderMetro
         * @property {string|null} [name] CustomerOrderMetro name
         * @property {string|null} [color] CustomerOrderMetro color
         */

        /**
         * Constructs a new CustomerOrderMetro.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderMetro.
         * @implements ICustomerOrderMetro
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderMetro=} [properties] Properties to set
         */
        function CustomerOrderMetro(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderMetro name.
         * @member {string} name
         * @memberof PlatformAdminApi.CustomerOrderMetro
         * @instance
         */
        CustomerOrderMetro.prototype.name = "";

        /**
         * CustomerOrderMetro color.
         * @member {string} color
         * @memberof PlatformAdminApi.CustomerOrderMetro
         * @instance
         */
        CustomerOrderMetro.prototype.color = "";

        /**
         * Encodes the specified CustomerOrderMetro message. Does not implicitly {@link PlatformAdminApi.CustomerOrderMetro.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderMetro
         * @static
         * @param {PlatformAdminApi.ICustomerOrderMetro} message CustomerOrderMetro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderMetro.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.color);
            return writer;
        };

        /**
         * Decodes a CustomerOrderMetro message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderMetro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderMetro} CustomerOrderMetro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderMetro.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderMetro();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.color = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderMetro;
    })();

    PlatformAdminApi.SetContactMainRequest = (function() {

        /**
         * Properties of a SetContactMainRequest.
         * @memberof PlatformAdminApi
         * @interface ISetContactMainRequest
         * @property {string|null} [userId] SetContactMainRequest userId
         * @property {string|null} [contactId] SetContactMainRequest contactId
         */

        /**
         * Constructs a new SetContactMainRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SetContactMainRequest.
         * @implements ISetContactMainRequest
         * @constructor
         * @param {PlatformAdminApi.ISetContactMainRequest=} [properties] Properties to set
         */
        function SetContactMainRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetContactMainRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.SetContactMainRequest
         * @instance
         */
        SetContactMainRequest.prototype.userId = "";

        /**
         * SetContactMainRequest contactId.
         * @member {string} contactId
         * @memberof PlatformAdminApi.SetContactMainRequest
         * @instance
         */
        SetContactMainRequest.prototype.contactId = "";

        /**
         * Encodes the specified SetContactMainRequest message. Does not implicitly {@link PlatformAdminApi.SetContactMainRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SetContactMainRequest
         * @static
         * @param {PlatformAdminApi.ISetContactMainRequest} message SetContactMainRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetContactMainRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.contactId != null && Object.hasOwnProperty.call(message, "contactId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contactId);
            return writer;
        };

        /**
         * Decodes a SetContactMainRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SetContactMainRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SetContactMainRequest} SetContactMainRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetContactMainRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SetContactMainRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.contactId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SetContactMainRequest;
    })();

    PlatformAdminApi.ContractorsService = (function() {

        /**
         * Constructs a new ContractorsService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorsService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ContractorsService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ContractorsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ContractorsService;

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#contractorsSearch}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef ContractorsSearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorsList} [response] ContractorsList
         */

        /**
         * Calls ContractorsSearch.
         * @function contractorsSearch
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorsSearchRequest} request ContractorsSearchRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.ContractorsSearchCallback} callback Node-style callback called with the error, if any, and ContractorsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.contractorsSearch = function contractorsSearch(request, callback) {
            return this.rpcCall(contractorsSearch, $root.PlatformAdminApi.ContractorsSearchRequest, $root.PlatformAdminApi.ContractorsList, request, callback);
        }, "name", { value: "ContractorsSearch" });

        /**
         * Calls ContractorsSearch.
         * @function contractorsSearch
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorsSearchRequest} request ContractorsSearchRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#findById}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef FindByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorUser} [response] ContractorUser
         */

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.FindByIdCallback} callback Node-style callback called with the error, if any, and ContractorUser
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.findById = function findById(request, callback) {
            return this.rpcCall(findById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorUser, request, callback);
        }, "name", { value: "FindById" });

        /**
         * Calls FindById.
         * @function findById
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorUser>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#block}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef BlockCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorUser} [response] ContractorUser
         */

        /**
         * Calls Block.
         * @function block
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IBlockContractorRequest} request BlockContractorRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.BlockCallback} callback Node-style callback called with the error, if any, and ContractorUser
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.block = function block(request, callback) {
            return this.rpcCall(block, $root.PlatformAdminApi.BlockContractorRequest, $root.PlatformAdminApi.ContractorUser, request, callback);
        }, "name", { value: "Block" });

        /**
         * Calls Block.
         * @function block
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IBlockContractorRequest} request BlockContractorRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorUser>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#unblock}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef UnblockCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorUser} [response] ContractorUser
         */

        /**
         * Calls Unblock.
         * @function unblock
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.UnblockCallback} callback Node-style callback called with the error, if any, and ContractorUser
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.unblock = function unblock(request, callback) {
            return this.rpcCall(unblock, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorUser, request, callback);
        }, "name", { value: "Unblock" });

        /**
         * Calls Unblock.
         * @function unblock
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorUser>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchBlockingReasons}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchBlockingReasonsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorBlockingReasonResponse} [response] ContractorBlockingReasonResponse
         */

        /**
         * Calls SearchBlockingReasons.
         * @function searchBlockingReasons
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorBlockingReasonRequest} request ContractorBlockingReasonRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchBlockingReasonsCallback} callback Node-style callback called with the error, if any, and ContractorBlockingReasonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchBlockingReasons = function searchBlockingReasons(request, callback) {
            return this.rpcCall(searchBlockingReasons, $root.PlatformAdminApi.ContractorBlockingReasonRequest, $root.PlatformAdminApi.ContractorBlockingReasonResponse, request, callback);
        }, "name", { value: "SearchBlockingReasons" });

        /**
         * Calls SearchBlockingReasons.
         * @function searchBlockingReasons
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorBlockingReasonRequest} request ContractorBlockingReasonRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorBlockingReasonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#update}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorUser} [response] ContractorUser
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorEditFrontend} request ContractorEditFrontend message or plain object
         * @param {PlatformAdminApi.ContractorsService.UpdateCallback} callback Node-style callback called with the error, if any, and ContractorUser
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformAdminApi.ContractorEditFrontend, $root.PlatformAdminApi.ContractorUser, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorEditFrontend} request ContractorEditFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorUser>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#retrieveEditContractor}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RetrieveEditContractorCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorEditFrontend} [response] ContractorEditFrontend
         */

        /**
         * Calls RetrieveEditContractor.
         * @function retrieveEditContractor
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RetrieveEditContractorCallback} callback Node-style callback called with the error, if any, and ContractorEditFrontend
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.retrieveEditContractor = function retrieveEditContractor(request, callback) {
            return this.rpcCall(retrieveEditContractor, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorEditFrontend, request, callback);
        }, "name", { value: "RetrieveEditContractor" });

        /**
         * Calls RetrieveEditContractor.
         * @function retrieveEditContractor
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorEditFrontend>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchAddressesById}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchAddressesByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorAddressesList} [response] ContractorAddressesList
         */

        /**
         * Calls SearchAddressesById.
         * @function searchAddressesById
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchAddressesByIdCallback} callback Node-style callback called with the error, if any, and ContractorAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchAddressesById = function searchAddressesById(request, callback) {
            return this.rpcCall(searchAddressesById, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorAddressesList, request, callback);
        }, "name", { value: "SearchAddressesById" });

        /**
         * Calls SearchAddressesById.
         * @function searchAddressesById
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#retrieveViewAddress}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RetrieveViewAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorViewAddress} [response] ContractorViewAddress
         */

        /**
         * Calls RetrieveViewAddress.
         * @function retrieveViewAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RetrieveViewAddressCallback} callback Node-style callback called with the error, if any, and ContractorViewAddress
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.retrieveViewAddress = function retrieveViewAddress(request, callback) {
            return this.rpcCall(retrieveViewAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.ContractorViewAddress, request, callback);
        }, "name", { value: "RetrieveViewAddress" });

        /**
         * Calls RetrieveViewAddress.
         * @function retrieveViewAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorViewAddress>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#retrieveEditAddress}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RetrieveEditAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorAddressEditForm} [response] ContractorAddressEditForm
         */

        /**
         * Calls RetrieveEditAddress.
         * @function retrieveEditAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RetrieveEditAddressCallback} callback Node-style callback called with the error, if any, and ContractorAddressEditForm
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.retrieveEditAddress = function retrieveEditAddress(request, callback) {
            return this.rpcCall(retrieveEditAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.ContractorAddressEditForm, request, callback);
        }, "name", { value: "RetrieveEditAddress" });

        /**
         * Calls RetrieveEditAddress.
         * @function retrieveEditAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorAddressEditForm>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#createAddress}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef CreateAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorAddressesList} [response] ContractorAddressesList
         */

        /**
         * Calls CreateAddress.
         * @function createAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorAddressCreateFrontend} request ContractorAddressCreateFrontend message or plain object
         * @param {PlatformAdminApi.ContractorsService.CreateAddressCallback} callback Node-style callback called with the error, if any, and ContractorAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.createAddress = function createAddress(request, callback) {
            return this.rpcCall(createAddress, $root.PlatformAdminApi.ContractorAddressCreateFrontend, $root.PlatformAdminApi.ContractorAddressesList, request, callback);
        }, "name", { value: "CreateAddress" });

        /**
         * Calls CreateAddress.
         * @function createAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorAddressCreateFrontend} request ContractorAddressCreateFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#updateAddress}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef UpdateAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorAddressesList} [response] ContractorAddressesList
         */

        /**
         * Calls UpdateAddress.
         * @function updateAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorAddressUpdateFrontend} request ContractorAddressUpdateFrontend message or plain object
         * @param {PlatformAdminApi.ContractorsService.UpdateAddressCallback} callback Node-style callback called with the error, if any, and ContractorAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.updateAddress = function updateAddress(request, callback) {
            return this.rpcCall(updateAddress, $root.PlatformAdminApi.ContractorAddressUpdateFrontend, $root.PlatformAdminApi.ContractorAddressesList, request, callback);
        }, "name", { value: "UpdateAddress" });

        /**
         * Calls UpdateAddress.
         * @function updateAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorAddressUpdateFrontend} request ContractorAddressUpdateFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#deleteAddress}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef DeleteAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressesList} [response] CustomerAddressesList
         */

        /**
         * Calls DeleteAddress.
         * @function deleteAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.DeleteAddressCallback} callback Node-style callback called with the error, if any, and CustomerAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.deleteAddress = function deleteAddress(request, callback) {
            return this.rpcCall(deleteAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.CustomerAddressesList, request, callback);
        }, "name", { value: "DeleteAddress" });

        /**
         * Calls DeleteAddress.
         * @function deleteAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#restoreAddress}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RestoreAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerAddressesList} [response] CustomerAddressesList
         */

        /**
         * Calls RestoreAddress.
         * @function restoreAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RestoreAddressCallback} callback Node-style callback called with the error, if any, and CustomerAddressesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.restoreAddress = function restoreAddress(request, callback) {
            return this.rpcCall(restoreAddress, $root.PlatformAdminApi.UserAddressRequest, $root.PlatformAdminApi.CustomerAddressesList, request, callback);
        }, "name", { value: "RestoreAddress" });

        /**
         * Calls RestoreAddress.
         * @function restoreAddress
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserAddressRequest} request UserAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerAddressesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchTenant}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchTenantCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TenantSearchResponse} [response] TenantSearchResponse
         */

        /**
         * Calls SearchTenant.
         * @function searchTenant
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.ITenantSearchRequestFrontend} request TenantSearchRequestFrontend message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchTenantCallback} callback Node-style callback called with the error, if any, and TenantSearchResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchTenant = function searchTenant(request, callback) {
            return this.rpcCall(searchTenant, $root.PlatformAdminApi.TenantSearchRequestFrontend, $root.PlatformAdminApi.TenantSearchResponse, request, callback);
        }, "name", { value: "SearchTenant" });

        /**
         * Calls SearchTenant.
         * @function searchTenant
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.ITenantSearchRequestFrontend} request TenantSearchRequestFrontend message or plain object
         * @returns {Promise<PlatformAdminApi.TenantSearchResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#retrieveOrders}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RetrieveOrdersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorOrdersListResponse} [response] ContractorOrdersListResponse
         */

        /**
         * Calls RetrieveOrders.
         * @function retrieveOrders
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRetrieveContractorOrdersRequest} request RetrieveContractorOrdersRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RetrieveOrdersCallback} callback Node-style callback called with the error, if any, and ContractorOrdersListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.retrieveOrders = function retrieveOrders(request, callback) {
            return this.rpcCall(retrieveOrders, $root.PlatformAdminApi.RetrieveContractorOrdersRequest, $root.PlatformAdminApi.ContractorOrdersListResponse, request, callback);
        }, "name", { value: "RetrieveOrders" });

        /**
         * Calls RetrieveOrders.
         * @function retrieveOrders
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRetrieveContractorOrdersRequest} request RetrieveContractorOrdersRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorOrdersListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#retrieveCrmOrders}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RetrieveCrmOrdersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorOrdersListResponse} [response] ContractorOrdersListResponse
         */

        /**
         * Calls RetrieveCrmOrders.
         * @function retrieveCrmOrders
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRetrieveContractorOrdersRequest} request RetrieveContractorOrdersRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RetrieveCrmOrdersCallback} callback Node-style callback called with the error, if any, and ContractorOrdersListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.retrieveCrmOrders = function retrieveCrmOrders(request, callback) {
            return this.rpcCall(retrieveCrmOrders, $root.PlatformAdminApi.RetrieveContractorOrdersRequest, $root.PlatformAdminApi.ContractorOrdersListResponse, request, callback);
        }, "name", { value: "RetrieveCrmOrders" });

        /**
         * Calls RetrieveCrmOrders.
         * @function retrieveCrmOrders
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRetrieveContractorOrdersRequest} request RetrieveContractorOrdersRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorOrdersListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchContractorContacts}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchContractorContactsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContactResponse} [response] ContractorContactResponse
         */

        /**
         * Calls SearchContractorContacts.
         * @function searchContractorContacts
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchContractorContactsCallback} callback Node-style callback called with the error, if any, and ContractorContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchContractorContacts = function searchContractorContacts(request, callback) {
            return this.rpcCall(searchContractorContacts, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorContactResponse, request, callback);
        }, "name", { value: "SearchContractorContacts" });

        /**
         * Calls SearchContractorContacts.
         * @function searchContractorContacts
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#createContact}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef CreateContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContactResponse} [response] ContractorContactResponse
         */

        /**
         * Calls CreateContact.
         * @function createContact
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContactCreateRequest} request ContractorContactCreateRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.CreateContactCallback} callback Node-style callback called with the error, if any, and ContractorContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.createContact = function createContact(request, callback) {
            return this.rpcCall(createContact, $root.PlatformAdminApi.ContractorContactCreateRequest, $root.PlatformAdminApi.ContractorContactResponse, request, callback);
        }, "name", { value: "CreateContact" });

        /**
         * Calls CreateContact.
         * @function createContact
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContactCreateRequest} request ContractorContactCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#deleteContact}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef DeleteContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContactResponse} [response] ContractorContactResponse
         */

        /**
         * Calls DeleteContact.
         * @function deleteContact
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContactDeleteRequest} request ContractorContactDeleteRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.DeleteContactCallback} callback Node-style callback called with the error, if any, and ContractorContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.deleteContact = function deleteContact(request, callback) {
            return this.rpcCall(deleteContact, $root.PlatformAdminApi.ContractorContactDeleteRequest, $root.PlatformAdminApi.ContractorContactResponse, request, callback);
        }, "name", { value: "DeleteContact" });

        /**
         * Calls DeleteContact.
         * @function deleteContact
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContactDeleteRequest} request ContractorContactDeleteRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#setContactMain}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SetContactMainCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContactResponse} [response] ContractorContactResponse
         */

        /**
         * Calls SetContactMain.
         * @function setContactMain
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserContactRequest} request UserContactRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SetContactMainCallback} callback Node-style callback called with the error, if any, and ContractorContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.setContactMain = function setContactMain(request, callback) {
            return this.rpcCall(setContactMain, $root.PlatformAdminApi.UserContactRequest, $root.PlatformAdminApi.ContractorContactResponse, request, callback);
        }, "name", { value: "SetContactMain" });

        /**
         * Calls SetContactMain.
         * @function setContactMain
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUserContactRequest} request UserContactRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#getCardsList}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef GetCardsListCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CardsListFrontend} [response] CardsListFrontend
         */

        /**
         * Calls GetCardsList.
         * @function getCardsList
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.ICardsListRequest} request CardsListRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.GetCardsListCallback} callback Node-style callback called with the error, if any, and CardsListFrontend
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.getCardsList = function getCardsList(request, callback) {
            return this.rpcCall(getCardsList, $root.PlatformAdminApi.CardsListRequest, $root.PlatformAdminApi.CardsListFrontend, request, callback);
        }, "name", { value: "GetCardsList" });

        /**
         * Calls GetCardsList.
         * @function getCardsList
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.ICardsListRequest} request CardsListRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CardsListFrontend>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#attachCard}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef AttachCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Empty} [response] Empty
         */

        /**
         * Calls AttachCard.
         * @function attachCard
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IAttachCardRequest} request AttachCardRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.AttachCardCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.attachCard = function attachCard(request, callback) {
            return this.rpcCall(attachCard, $root.PlatformAdminApi.AttachCardRequest, $root.PlatformAdminApi.Empty, request, callback);
        }, "name", { value: "AttachCard" });

        /**
         * Calls AttachCard.
         * @function attachCard
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IAttachCardRequest} request AttachCardRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#getCardDetails}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef GetCardDetailsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CardViewResponse} [response] CardViewResponse
         */

        /**
         * Calls GetCardDetails.
         * @function getCardDetails
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.ICardDetailsRequest} request CardDetailsRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.GetCardDetailsCallback} callback Node-style callback called with the error, if any, and CardViewResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.getCardDetails = function getCardDetails(request, callback) {
            return this.rpcCall(getCardDetails, $root.PlatformAdminApi.CardDetailsRequest, $root.PlatformAdminApi.CardViewResponse, request, callback);
        }, "name", { value: "GetCardDetails" });

        /**
         * Calls GetCardDetails.
         * @function getCardDetails
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.ICardDetailsRequest} request CardDetailsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CardViewResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#removeCard}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RemoveCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RemoveCardResponse} [response] RemoveCardResponse
         */

        /**
         * Calls RemoveCard.
         * @function removeCard
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRemoveCardRequest} request RemoveCardRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RemoveCardCallback} callback Node-style callback called with the error, if any, and RemoveCardResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.removeCard = function removeCard(request, callback) {
            return this.rpcCall(removeCard, $root.PlatformAdminApi.RemoveCardRequest, $root.PlatformAdminApi.RemoveCardResponse, request, callback);
        }, "name", { value: "RemoveCard" });

        /**
         * Calls RemoveCard.
         * @function removeCard
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRemoveCardRequest} request RemoveCardRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RemoveCardResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#rootCard}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef RootCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.RootCardChangeResponse} [response] RootCardChangeResponse
         */

        /**
         * Calls RootCard.
         * @function rootCard
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRootCardRequest} request RootCardRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.RootCardCallback} callback Node-style callback called with the error, if any, and RootCardChangeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.rootCard = function rootCard(request, callback) {
            return this.rpcCall(rootCard, $root.PlatformAdminApi.RootCardRequest, $root.PlatformAdminApi.RootCardChangeResponse, request, callback);
        }, "name", { value: "RootCard" });

        /**
         * Calls RootCard.
         * @function rootCard
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IRootCardRequest} request RootCardRequest message or plain object
         * @returns {Promise<PlatformAdminApi.RootCardChangeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#getContracts}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef GetContractsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContractResponse} [response] ContractorContractResponse
         */

        /**
         * Calls GetContracts.
         * @function getContracts
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractRequest} request ContractorContractRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.GetContractsCallback} callback Node-style callback called with the error, if any, and ContractorContractResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.getContracts = function getContracts(request, callback) {
            return this.rpcCall(getContracts, $root.PlatformAdminApi.ContractorContractRequest, $root.PlatformAdminApi.ContractorContractResponse, request, callback);
        }, "name", { value: "GetContracts" });

        /**
         * Calls GetContracts.
         * @function getContracts
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractRequest} request ContractorContractRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContractResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchSkills}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchSkillsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorSkillsResponse} [response] ContractorSkillsResponse
         */

        /**
         * Calls SearchSkills.
         * @function searchSkills
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorSkillsRequest} request ContractorSkillsRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchSkillsCallback} callback Node-style callback called with the error, if any, and ContractorSkillsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchSkills = function searchSkills(request, callback) {
            return this.rpcCall(searchSkills, $root.PlatformAdminApi.ContractorSkillsRequest, $root.PlatformAdminApi.ContractorSkillsResponse, request, callback);
        }, "name", { value: "SearchSkills" });

        /**
         * Calls SearchSkills.
         * @function searchSkills
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorSkillsRequest} request ContractorSkillsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorSkillsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchWages}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchWagesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorWagesResponse} [response] ContractorWagesResponse
         */

        /**
         * Calls SearchWages.
         * @function searchWages
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorWagesRequest} request ContractorWagesRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchWagesCallback} callback Node-style callback called with the error, if any, and ContractorWagesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchWages = function searchWages(request, callback) {
            return this.rpcCall(searchWages, $root.PlatformAdminApi.ContractorWagesRequest, $root.PlatformAdminApi.ContractorWagesResponse, request, callback);
        }, "name", { value: "SearchWages" });

        /**
         * Calls SearchWages.
         * @function searchWages
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorWagesRequest} request ContractorWagesRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorWagesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#createContract}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef CreateContractCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Empty} [response] Empty
         */

        /**
         * Calls CreateContract.
         * @function createContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractCreateRequest} request ContractorContractCreateRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.CreateContractCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.createContract = function createContract(request, callback) {
            return this.rpcCall(createContract, $root.PlatformAdminApi.ContractorContractCreateRequest, $root.PlatformAdminApi.Empty, request, callback);
        }, "name", { value: "CreateContract" });

        /**
         * Calls CreateContract.
         * @function createContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractCreateRequest} request ContractorContractCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#updateContract}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef UpdateContractCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Empty} [response] Empty
         */

        /**
         * Calls UpdateContract.
         * @function updateContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractUpdateRequest} request ContractorContractUpdateRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.UpdateContractCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.updateContract = function updateContract(request, callback) {
            return this.rpcCall(updateContract, $root.PlatformAdminApi.ContractorContractUpdateRequest, $root.PlatformAdminApi.Empty, request, callback);
        }, "name", { value: "UpdateContract" });

        /**
         * Calls UpdateContract.
         * @function updateContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractUpdateRequest} request ContractorContractUpdateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#unblockContractorAndCreateContract}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef UnblockContractorAndCreateContractCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Empty} [response] Empty
         */

        /**
         * Calls UnblockContractorAndCreateContract.
         * @function unblockContractorAndCreateContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractCreateRequest} request ContractorContractCreateRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.UnblockContractorAndCreateContractCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.unblockContractorAndCreateContract = function unblockContractorAndCreateContract(request, callback) {
            return this.rpcCall(unblockContractorAndCreateContract, $root.PlatformAdminApi.ContractorContractCreateRequest, $root.PlatformAdminApi.Empty, request, callback);
        }, "name", { value: "UnblockContractorAndCreateContract" });

        /**
         * Calls UnblockContractorAndCreateContract.
         * @function unblockContractorAndCreateContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractCreateRequest} request ContractorContractCreateRequest message or plain object
         * @returns {Promise<PlatformAdminApi.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#getContractEditInformation}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef GetContractEditInformationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContractInformationResponse} [response] ContractorContractInformationResponse
         */

        /**
         * Calls GetContractEditInformation.
         * @function getContractEditInformation
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.GetContractEditInformationCallback} callback Node-style callback called with the error, if any, and ContractorContractInformationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.getContractEditInformation = function getContractEditInformation(request, callback) {
            return this.rpcCall(getContractEditInformation, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.ContractorContractInformationResponse, request, callback);
        }, "name", { value: "GetContractEditInformation" });

        /**
         * Calls GetContractEditInformation.
         * @function getContractEditInformation
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContractInformationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#blockContract}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef BlockContractCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContractBlockResponse} [response] ContractorContractBlockResponse
         */

        /**
         * Calls BlockContract.
         * @function blockContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractBlockRequest} request ContractorContractBlockRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.BlockContractCallback} callback Node-style callback called with the error, if any, and ContractorContractBlockResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.blockContract = function blockContract(request, callback) {
            return this.rpcCall(blockContract, $root.PlatformAdminApi.ContractorContractBlockRequest, $root.PlatformAdminApi.ContractorContractBlockResponse, request, callback);
        }, "name", { value: "BlockContract" });

        /**
         * Calls BlockContract.
         * @function blockContract
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractBlockRequest} request ContractorContractBlockRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContractBlockResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchContractBlockingReasonGroups}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchContractBlockingReasonGroupsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse} [response] ContractorContractSearchBlockingReasonGroupsResponse
         */

        /**
         * Calls SearchContractBlockingReasonGroups.
         * @function searchContractBlockingReasonGroups
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonGroupsRequest} request ContractorContractSearchBlockingReasonGroupsRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchContractBlockingReasonGroupsCallback} callback Node-style callback called with the error, if any, and ContractorContractSearchBlockingReasonGroupsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchContractBlockingReasonGroups = function searchContractBlockingReasonGroups(request, callback) {
            return this.rpcCall(searchContractBlockingReasonGroups, $root.PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest, $root.PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse, request, callback);
        }, "name", { value: "SearchContractBlockingReasonGroups" });

        /**
         * Calls SearchContractBlockingReasonGroups.
         * @function searchContractBlockingReasonGroups
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonGroupsRequest} request ContractorContractSearchBlockingReasonGroupsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#searchContractBlockingReasons}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef SearchContractBlockingReasonsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse} [response] ContractorContractSearchBlockingReasonsResponse
         */

        /**
         * Calls SearchContractBlockingReasons.
         * @function searchContractBlockingReasons
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonsRequest} request ContractorContractSearchBlockingReasonsRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.SearchContractBlockingReasonsCallback} callback Node-style callback called with the error, if any, and ContractorContractSearchBlockingReasonsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.searchContractBlockingReasons = function searchContractBlockingReasons(request, callback) {
            return this.rpcCall(searchContractBlockingReasons, $root.PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest, $root.PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse, request, callback);
        }, "name", { value: "SearchContractBlockingReasons" });

        /**
         * Calls SearchContractBlockingReasons.
         * @function searchContractBlockingReasons
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonsRequest} request ContractorContractSearchBlockingReasonsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#addTask}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef AddTaskCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.UuidResponse} [response] UuidResponse
         */

        /**
         * Calls AddTask.
         * @function addTask
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.AddTaskCallback} callback Node-style callback called with the error, if any, and UuidResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.addTask = function addTask(request, callback) {
            return this.rpcCall(addTask, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.UuidResponse, request, callback);
        }, "name", { value: "AddTask" });

        /**
         * Calls AddTask.
         * @function addTask
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.UuidResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#changeCommunicationChannels}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef ChangeCommunicationChannelsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ChangeCommunicationChannelsResponse} [response] ChangeCommunicationChannelsResponse
         */

        /**
         * Calls ChangeCommunicationChannels.
         * @function changeCommunicationChannels
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IChangeCommunicationChannelsRequest} request ChangeCommunicationChannelsRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.ChangeCommunicationChannelsCallback} callback Node-style callback called with the error, if any, and ChangeCommunicationChannelsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.changeCommunicationChannels = function changeCommunicationChannels(request, callback) {
            return this.rpcCall(changeCommunicationChannels, $root.PlatformAdminApi.ChangeCommunicationChannelsRequest, $root.PlatformAdminApi.ChangeCommunicationChannelsResponse, request, callback);
        }, "name", { value: "ChangeCommunicationChannels" });

        /**
         * Calls ChangeCommunicationChannels.
         * @function changeCommunicationChannels
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IChangeCommunicationChannelsRequest} request ChangeCommunicationChannelsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.ChangeCommunicationChannelsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.ContractorsService#getPreferencesByUserId}.
         * @memberof PlatformAdminApi.ContractorsService
         * @typedef GetPreferencesByUserIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FindUserPreferencesByIdResponse} [response] FindUserPreferencesByIdResponse
         */

        /**
         * Calls GetPreferencesByUserId.
         * @function getPreferencesByUserId
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest} request FindUserPreferencesByIdRequest message or plain object
         * @param {PlatformAdminApi.ContractorsService.GetPreferencesByUserIdCallback} callback Node-style callback called with the error, if any, and FindUserPreferencesByIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ContractorsService.prototype.getPreferencesByUserId = function getPreferencesByUserId(request, callback) {
            return this.rpcCall(getPreferencesByUserId, $root.PlatformAdminApi.FindUserPreferencesByIdRequest, $root.PlatformAdminApi.FindUserPreferencesByIdResponse, request, callback);
        }, "name", { value: "GetPreferencesByUserId" });

        /**
         * Calls GetPreferencesByUserId.
         * @function getPreferencesByUserId
         * @memberof PlatformAdminApi.ContractorsService
         * @instance
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest} request FindUserPreferencesByIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FindUserPreferencesByIdResponse>} Promise
         * @variation 2
         */

        return ContractorsService;
    })();

    PlatformAdminApi.ContractorUser = (function() {

        /**
         * Properties of a ContractorUser.
         * @memberof PlatformAdminApi
         * @interface IContractorUser
         * @property {string|null} [id] ContractorUser id
         * @property {string|null} [userType] ContractorUser userType
         * @property {string|null} [tenantName] ContractorUser tenantName
         * @property {string|null} [tenantId] ContractorUser tenantId
         * @property {string|null} [fullName] ContractorUser fullName
         * @property {string|null} [birthday] ContractorUser birthday
         * @property {boolean|null} [isBlocked] ContractorUser isBlocked
         * @property {string|null} [blockingReasonSlug] ContractorUser blockingReasonSlug
         * @property {string|null} [firstName] ContractorUser firstName
         * @property {string|null} [lastName] ContractorUser lastName
         * @property {string|null} [middleName] ContractorUser middleName
         * @property {string|null} [createdAt] ContractorUser createdAt
         * @property {boolean|null} [isAdmin] ContractorUser isAdmin
         * @property {boolean|null} [isDeleted] ContractorUser isDeleted
         * @property {Array.<PlatformAdminApi.ISocialNetwork>|null} [socialNetworks] ContractorUser socialNetworks
         * @property {PlatformAdminApi.IProfileDadataFIO|null} [object] ContractorUser object
         * @property {string|null} [blockingReasonDescription] ContractorUser blockingReasonDescription
         * @property {string|null} [blockedAt] ContractorUser blockedAt
         * @property {string|null} [mainPhone] ContractorUser mainPhone
         */

        /**
         * Constructs a new ContractorUser.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorUser.
         * @implements IContractorUser
         * @constructor
         * @param {PlatformAdminApi.IContractorUser=} [properties] Properties to set
         */
        function ContractorUser(properties) {
            this.socialNetworks = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorUser id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.id = "";

        /**
         * ContractorUser userType.
         * @member {string} userType
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.userType = "";

        /**
         * ContractorUser tenantName.
         * @member {string} tenantName
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.tenantName = "";

        /**
         * ContractorUser tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.tenantId = "";

        /**
         * ContractorUser fullName.
         * @member {string} fullName
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.fullName = "";

        /**
         * ContractorUser birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.birthday = "";

        /**
         * ContractorUser isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.isBlocked = false;

        /**
         * ContractorUser blockingReasonSlug.
         * @member {string} blockingReasonSlug
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.blockingReasonSlug = "";

        /**
         * ContractorUser firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.firstName = "";

        /**
         * ContractorUser lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.lastName = "";

        /**
         * ContractorUser middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.middleName = "";

        /**
         * ContractorUser createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.createdAt = "";

        /**
         * ContractorUser isAdmin.
         * @member {boolean} isAdmin
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.isAdmin = false;

        /**
         * ContractorUser isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.isDeleted = false;

        /**
         * ContractorUser socialNetworks.
         * @member {Array.<PlatformAdminApi.ISocialNetwork>} socialNetworks
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.socialNetworks = $util.emptyArray;

        /**
         * ContractorUser object.
         * @member {PlatformAdminApi.IProfileDadataFIO|null|undefined} object
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.object = null;

        /**
         * ContractorUser blockingReasonDescription.
         * @member {string} blockingReasonDescription
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.blockingReasonDescription = "";

        /**
         * ContractorUser blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.blockedAt = "";

        /**
         * ContractorUser mainPhone.
         * @member {string} mainPhone
         * @memberof PlatformAdminApi.ContractorUser
         * @instance
         */
        ContractorUser.prototype.mainPhone = "";

        /**
         * Encodes the specified ContractorUser message. Does not implicitly {@link PlatformAdminApi.ContractorUser.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorUser
         * @static
         * @param {PlatformAdminApi.IContractorUser} message ContractorUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userType);
            if (message.tenantName != null && Object.hasOwnProperty.call(message, "tenantName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tenantName);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.tenantId);
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.fullName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.birthday);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isBlocked);
            if (message.blockingReasonSlug != null && Object.hasOwnProperty.call(message, "blockingReasonSlug"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.blockingReasonSlug);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.middleName);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.createdAt);
            if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.isAdmin);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isDeleted);
            if (message.socialNetworks != null && message.socialNetworks.length)
                for (var i = 0; i < message.socialNetworks.length; ++i)
                    $root.PlatformAdminApi.SocialNetwork.encode(message.socialNetworks[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.ProfileDadataFIO.encode(message.object, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.blockingReasonDescription != null && Object.hasOwnProperty.call(message, "blockingReasonDescription"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.blockingReasonDescription);
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.blockedAt);
            if (message.mainPhone != null && Object.hasOwnProperty.call(message, "mainPhone"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.mainPhone);
            return writer;
        };

        /**
         * Decodes a ContractorUser message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorUser} ContractorUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorUser();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userType = reader.string();
                    break;
                case 3:
                    message.tenantName = reader.string();
                    break;
                case 4:
                    message.tenantId = reader.string();
                    break;
                case 5:
                    message.fullName = reader.string();
                    break;
                case 6:
                    message.birthday = reader.string();
                    break;
                case 11:
                    message.isBlocked = reader.bool();
                    break;
                case 12:
                    message.blockingReasonSlug = reader.string();
                    break;
                case 13:
                    message.firstName = reader.string();
                    break;
                case 14:
                    message.lastName = reader.string();
                    break;
                case 15:
                    message.middleName = reader.string();
                    break;
                case 16:
                    message.createdAt = reader.string();
                    break;
                case 17:
                    message.isAdmin = reader.bool();
                    break;
                case 18:
                    message.isDeleted = reader.bool();
                    break;
                case 19:
                    if (!(message.socialNetworks && message.socialNetworks.length))
                        message.socialNetworks = [];
                    message.socialNetworks.push($root.PlatformAdminApi.SocialNetwork.decode(reader, reader.uint32()));
                    break;
                case 20:
                    message.object = $root.PlatformAdminApi.ProfileDadataFIO.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.blockingReasonDescription = reader.string();
                    break;
                case 22:
                    message.blockedAt = reader.string();
                    break;
                case 23:
                    message.mainPhone = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorUser;
    })();

    PlatformAdminApi.ContractorAddressesListItem = (function() {

        /**
         * Properties of a ContractorAddressesListItem.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressesListItem
         * @property {string|null} [id] ContractorAddressesListItem id
         * @property {string|null} [value] ContractorAddressesListItem value
         * @property {string|null} [label] ContractorAddressesListItem label
         * @property {boolean|null} [isDeleted] ContractorAddressesListItem isDeleted
         * @property {string|null} [isDeletedString] ContractorAddressesListItem isDeletedString
         * @property {string|null} [addressTypeLabel] ContractorAddressesListItem addressTypeLabel
         * @property {string|null} [link] ContractorAddressesListItem link
         */

        /**
         * Constructs a new ContractorAddressesListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressesListItem.
         * @implements IContractorAddressesListItem
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressesListItem=} [properties] Properties to set
         */
        function ContractorAddressesListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressesListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @instance
         */
        ContractorAddressesListItem.prototype.id = "";

        /**
         * ContractorAddressesListItem value.
         * @member {string} value
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @instance
         */
        ContractorAddressesListItem.prototype.value = "";

        /**
         * ContractorAddressesListItem label.
         * @member {string} label
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @instance
         */
        ContractorAddressesListItem.prototype.label = "";

        /**
         * ContractorAddressesListItem isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @instance
         */
        ContractorAddressesListItem.prototype.isDeleted = false;

        /**
         * ContractorAddressesListItem isDeletedString.
         * @member {string} isDeletedString
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @instance
         */
        ContractorAddressesListItem.prototype.isDeletedString = "";

        /**
         * ContractorAddressesListItem addressTypeLabel.
         * @member {string} addressTypeLabel
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @instance
         */
        ContractorAddressesListItem.prototype.addressTypeLabel = "";

        /**
         * ContractorAddressesListItem link.
         * @member {string} link
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @instance
         */
        ContractorAddressesListItem.prototype.link = "";

        /**
         * Encodes the specified ContractorAddressesListItem message. Does not implicitly {@link PlatformAdminApi.ContractorAddressesListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @static
         * @param {PlatformAdminApi.IContractorAddressesListItem} message ContractorAddressesListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressesListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.isDeletedString != null && Object.hasOwnProperty.call(message, "isDeletedString"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.isDeletedString);
            if (message.addressTypeLabel != null && Object.hasOwnProperty.call(message, "addressTypeLabel"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.addressTypeLabel);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.link);
            return writer;
        };

        /**
         * Decodes a ContractorAddressesListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressesListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressesListItem} ContractorAddressesListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressesListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressesListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.label = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.isDeletedString = reader.string();
                    break;
                case 6:
                    message.addressTypeLabel = reader.string();
                    break;
                case 7:
                    message.link = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressesListItem;
    })();

    PlatformAdminApi.ContractorAddressesList = (function() {

        /**
         * Properties of a ContractorAddressesList.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressesList
         * @property {Array.<PlatformAdminApi.IContractorAddressesListItem>|null} [data] ContractorAddressesList data
         */

        /**
         * Constructs a new ContractorAddressesList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressesList.
         * @implements IContractorAddressesList
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressesList=} [properties] Properties to set
         */
        function ContractorAddressesList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressesList data.
         * @member {Array.<PlatformAdminApi.IContractorAddressesListItem>} data
         * @memberof PlatformAdminApi.ContractorAddressesList
         * @instance
         */
        ContractorAddressesList.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified ContractorAddressesList message. Does not implicitly {@link PlatformAdminApi.ContractorAddressesList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressesList
         * @static
         * @param {PlatformAdminApi.IContractorAddressesList} message ContractorAddressesList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressesList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.ContractorAddressesListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorAddressesList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressesList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressesList} ContractorAddressesList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressesList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressesList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.ContractorAddressesListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressesList;
    })();

    PlatformAdminApi.BlockContractorRequest = (function() {

        /**
         * Properties of a BlockContractorRequest.
         * @memberof PlatformAdminApi
         * @interface IBlockContractorRequest
         * @property {string|null} [id] BlockContractorRequest id
         * @property {PlatformAdminApi.BLOCKING_REASON_SLUG|null} [blockingReasonSlug] BlockContractorRequest blockingReasonSlug
         */

        /**
         * Constructs a new BlockContractorRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a BlockContractorRequest.
         * @implements IBlockContractorRequest
         * @constructor
         * @param {PlatformAdminApi.IBlockContractorRequest=} [properties] Properties to set
         */
        function BlockContractorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockContractorRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.BlockContractorRequest
         * @instance
         */
        BlockContractorRequest.prototype.id = "";

        /**
         * BlockContractorRequest blockingReasonSlug.
         * @member {PlatformAdminApi.BLOCKING_REASON_SLUG} blockingReasonSlug
         * @memberof PlatformAdminApi.BlockContractorRequest
         * @instance
         */
        BlockContractorRequest.prototype.blockingReasonSlug = 0;

        /**
         * Encodes the specified BlockContractorRequest message. Does not implicitly {@link PlatformAdminApi.BlockContractorRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.BlockContractorRequest
         * @static
         * @param {PlatformAdminApi.IBlockContractorRequest} message BlockContractorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockContractorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.blockingReasonSlug != null && Object.hasOwnProperty.call(message, "blockingReasonSlug"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.blockingReasonSlug);
            return writer;
        };

        /**
         * Decodes a BlockContractorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.BlockContractorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.BlockContractorRequest} BlockContractorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockContractorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.BlockContractorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 21:
                    message.blockingReasonSlug = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockContractorRequest;
    })();

    PlatformAdminApi.ContractorViewAddress = (function() {

        /**
         * Properties of a ContractorViewAddress.
         * @memberof PlatformAdminApi
         * @interface IContractorViewAddress
         * @property {PlatformAdminApi.IAddressLink|null} [addressLink] ContractorViewAddress addressLink
         * @property {string|null} [intercom] ContractorViewAddress intercom
         * @property {Array.<PlatformAdminApi.IMetroStation>|null} [metro] ContractorViewAddress metro
         * @property {boolean|null} [isDeleted] ContractorViewAddress isDeleted
         * @property {string|null} [isDeletedStatus] ContractorViewAddress isDeletedStatus
         */

        /**
         * Constructs a new ContractorViewAddress.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorViewAddress.
         * @implements IContractorViewAddress
         * @constructor
         * @param {PlatformAdminApi.IContractorViewAddress=} [properties] Properties to set
         */
        function ContractorViewAddress(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorViewAddress addressLink.
         * @member {PlatformAdminApi.IAddressLink|null|undefined} addressLink
         * @memberof PlatformAdminApi.ContractorViewAddress
         * @instance
         */
        ContractorViewAddress.prototype.addressLink = null;

        /**
         * ContractorViewAddress intercom.
         * @member {string} intercom
         * @memberof PlatformAdminApi.ContractorViewAddress
         * @instance
         */
        ContractorViewAddress.prototype.intercom = "";

        /**
         * ContractorViewAddress metro.
         * @member {Array.<PlatformAdminApi.IMetroStation>} metro
         * @memberof PlatformAdminApi.ContractorViewAddress
         * @instance
         */
        ContractorViewAddress.prototype.metro = $util.emptyArray;

        /**
         * ContractorViewAddress isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ContractorViewAddress
         * @instance
         */
        ContractorViewAddress.prototype.isDeleted = false;

        /**
         * ContractorViewAddress isDeletedStatus.
         * @member {string} isDeletedStatus
         * @memberof PlatformAdminApi.ContractorViewAddress
         * @instance
         */
        ContractorViewAddress.prototype.isDeletedStatus = "";

        /**
         * Encodes the specified ContractorViewAddress message. Does not implicitly {@link PlatformAdminApi.ContractorViewAddress.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorViewAddress
         * @static
         * @param {PlatformAdminApi.IContractorViewAddress} message ContractorViewAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorViewAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addressLink != null && Object.hasOwnProperty.call(message, "addressLink"))
                $root.PlatformAdminApi.AddressLink.encode(message.addressLink, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.intercom);
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.PlatformAdminApi.MetroStation.encode(message.metro[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.isDeletedStatus != null && Object.hasOwnProperty.call(message, "isDeletedStatus"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.isDeletedStatus);
            return writer;
        };

        /**
         * Decodes a ContractorViewAddress message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorViewAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorViewAddress} ContractorViewAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorViewAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorViewAddress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.addressLink = $root.PlatformAdminApi.AddressLink.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.intercom = reader.string();
                    break;
                case 3:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.PlatformAdminApi.MetroStation.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.isDeletedStatus = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorViewAddress;
    })();

    PlatformAdminApi.ContractorAddressEditForm = (function() {

        /**
         * Properties of a ContractorAddressEditForm.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressEditForm
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] ContractorAddressEditForm type
         * @property {PlatformAdminApi.IAddress|null} [value] ContractorAddressEditForm value
         */

        /**
         * Constructs a new ContractorAddressEditForm.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressEditForm.
         * @implements IContractorAddressEditForm
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressEditForm=} [properties] Properties to set
         */
        function ContractorAddressEditForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressEditForm type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.ContractorAddressEditForm
         * @instance
         */
        ContractorAddressEditForm.prototype.type = 0;

        /**
         * ContractorAddressEditForm value.
         * @member {PlatformAdminApi.IAddress|null|undefined} value
         * @memberof PlatformAdminApi.ContractorAddressEditForm
         * @instance
         */
        ContractorAddressEditForm.prototype.value = null;

        /**
         * Encodes the specified ContractorAddressEditForm message. Does not implicitly {@link PlatformAdminApi.ContractorAddressEditForm.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressEditForm
         * @static
         * @param {PlatformAdminApi.IContractorAddressEditForm} message ContractorAddressEditForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressEditForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.PlatformAdminApi.Address.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorAddressEditForm message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressEditForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressEditForm} ContractorAddressEditForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressEditForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressEditForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressEditForm;
    })();

    PlatformAdminApi.ContractorAddressCreateFrontend = (function() {

        /**
         * Properties of a ContractorAddressCreateFrontend.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressCreateFrontend
         * @property {string|null} [id] ContractorAddressCreateFrontend id
         * @property {PlatformAdminApi.IContractorAddressForm|null} [newAddress] ContractorAddressCreateFrontend newAddress
         */

        /**
         * Constructs a new ContractorAddressCreateFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressCreateFrontend.
         * @implements IContractorAddressCreateFrontend
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressCreateFrontend=} [properties] Properties to set
         */
        function ContractorAddressCreateFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressCreateFrontend id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorAddressCreateFrontend
         * @instance
         */
        ContractorAddressCreateFrontend.prototype.id = "";

        /**
         * ContractorAddressCreateFrontend newAddress.
         * @member {PlatformAdminApi.IContractorAddressForm|null|undefined} newAddress
         * @memberof PlatformAdminApi.ContractorAddressCreateFrontend
         * @instance
         */
        ContractorAddressCreateFrontend.prototype.newAddress = null;

        /**
         * Encodes the specified ContractorAddressCreateFrontend message. Does not implicitly {@link PlatformAdminApi.ContractorAddressCreateFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressCreateFrontend
         * @static
         * @param {PlatformAdminApi.IContractorAddressCreateFrontend} message ContractorAddressCreateFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressCreateFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.newAddress != null && Object.hasOwnProperty.call(message, "newAddress"))
                $root.PlatformAdminApi.ContractorAddressForm.encode(message.newAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorAddressCreateFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressCreateFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressCreateFrontend} ContractorAddressCreateFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressCreateFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressCreateFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.newAddress = $root.PlatformAdminApi.ContractorAddressForm.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressCreateFrontend;
    })();

    PlatformAdminApi.ContractorAddressCreateBackend = (function() {

        /**
         * Properties of a ContractorAddressCreateBackend.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressCreateBackend
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] ContractorAddressCreateBackend addresses
         */

        /**
         * Constructs a new ContractorAddressCreateBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressCreateBackend.
         * @implements IContractorAddressCreateBackend
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressCreateBackend=} [properties] Properties to set
         */
        function ContractorAddressCreateBackend(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressCreateBackend addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.ContractorAddressCreateBackend
         * @instance
         */
        ContractorAddressCreateBackend.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified ContractorAddressCreateBackend message. Does not implicitly {@link PlatformAdminApi.ContractorAddressCreateBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressCreateBackend
         * @static
         * @param {PlatformAdminApi.IContractorAddressCreateBackend} message ContractorAddressCreateBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressCreateBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorAddressCreateBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressCreateBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressCreateBackend} ContractorAddressCreateBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressCreateBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressCreateBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressCreateBackend;
    })();

    PlatformAdminApi.ContractorAddressUpdateFrontend = (function() {

        /**
         * Properties of a ContractorAddressUpdateFrontend.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressUpdateFrontend
         * @property {string|null} [id] ContractorAddressUpdateFrontend id
         * @property {PlatformAdminApi.IContractorAddressForm|null} [address] ContractorAddressUpdateFrontend address
         */

        /**
         * Constructs a new ContractorAddressUpdateFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressUpdateFrontend.
         * @implements IContractorAddressUpdateFrontend
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressUpdateFrontend=} [properties] Properties to set
         */
        function ContractorAddressUpdateFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressUpdateFrontend id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorAddressUpdateFrontend
         * @instance
         */
        ContractorAddressUpdateFrontend.prototype.id = "";

        /**
         * ContractorAddressUpdateFrontend address.
         * @member {PlatformAdminApi.IContractorAddressForm|null|undefined} address
         * @memberof PlatformAdminApi.ContractorAddressUpdateFrontend
         * @instance
         */
        ContractorAddressUpdateFrontend.prototype.address = null;

        /**
         * Encodes the specified ContractorAddressUpdateFrontend message. Does not implicitly {@link PlatformAdminApi.ContractorAddressUpdateFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressUpdateFrontend
         * @static
         * @param {PlatformAdminApi.IContractorAddressUpdateFrontend} message ContractorAddressUpdateFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressUpdateFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.ContractorAddressForm.encode(message.address, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorAddressUpdateFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressUpdateFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressUpdateFrontend} ContractorAddressUpdateFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressUpdateFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressUpdateFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 3:
                    message.address = $root.PlatformAdminApi.ContractorAddressForm.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressUpdateFrontend;
    })();

    PlatformAdminApi.ContractorAddressUpdateBackend = (function() {

        /**
         * Properties of a ContractorAddressUpdateBackend.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressUpdateBackend
         * @property {Array.<PlatformAdminApi.IAddressRel>|null} [addresses] ContractorAddressUpdateBackend addresses
         */

        /**
         * Constructs a new ContractorAddressUpdateBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressUpdateBackend.
         * @implements IContractorAddressUpdateBackend
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressUpdateBackend=} [properties] Properties to set
         */
        function ContractorAddressUpdateBackend(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressUpdateBackend addresses.
         * @member {Array.<PlatformAdminApi.IAddressRel>} addresses
         * @memberof PlatformAdminApi.ContractorAddressUpdateBackend
         * @instance
         */
        ContractorAddressUpdateBackend.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified ContractorAddressUpdateBackend message. Does not implicitly {@link PlatformAdminApi.ContractorAddressUpdateBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressUpdateBackend
         * @static
         * @param {PlatformAdminApi.IContractorAddressUpdateBackend} message ContractorAddressUpdateBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressUpdateBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformAdminApi.AddressRel.encode(message.addresses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorAddressUpdateBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressUpdateBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressUpdateBackend} ContractorAddressUpdateBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressUpdateBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressUpdateBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformAdminApi.AddressRel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressUpdateBackend;
    })();

    PlatformAdminApi.ContractorContactCreateRequest = (function() {

        /**
         * Properties of a ContractorContactCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContactCreateRequest
         * @property {string|null} [userId] ContractorContactCreateRequest userId
         * @property {PlatformAdminApi.IContractorContactCreate|null} [contact] ContractorContactCreateRequest contact
         */

        /**
         * Constructs a new ContractorContactCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContactCreateRequest.
         * @implements IContractorContactCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContactCreateRequest=} [properties] Properties to set
         */
        function ContractorContactCreateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContactCreateRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.ContractorContactCreateRequest
         * @instance
         */
        ContractorContactCreateRequest.prototype.userId = "";

        /**
         * ContractorContactCreateRequest contact.
         * @member {PlatformAdminApi.IContractorContactCreate|null|undefined} contact
         * @memberof PlatformAdminApi.ContractorContactCreateRequest
         * @instance
         */
        ContractorContactCreateRequest.prototype.contact = null;

        /**
         * Encodes the specified ContractorContactCreateRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContactCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContactCreateRequest
         * @static
         * @param {PlatformAdminApi.IContractorContactCreateRequest} message ContractorContactCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContactCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.contact != null && Object.hasOwnProperty.call(message, "contact"))
                $root.PlatformAdminApi.ContractorContactCreate.encode(message.contact, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContactCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContactCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContactCreateRequest} ContractorContactCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContactCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContactCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.contact = $root.PlatformAdminApi.ContractorContactCreate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContactCreateRequest;
    })();

    PlatformAdminApi.ContractorContactCreate = (function() {

        /**
         * Properties of a ContractorContactCreate.
         * @memberof PlatformAdminApi
         * @interface IContractorContactCreate
         * @property {PlatformAdminApi.CONTACT_TYPE|null} [type] ContractorContactCreate type
         * @property {string|null} [value] ContractorContactCreate value
         */

        /**
         * Constructs a new ContractorContactCreate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContactCreate.
         * @implements IContractorContactCreate
         * @constructor
         * @param {PlatformAdminApi.IContractorContactCreate=} [properties] Properties to set
         */
        function ContractorContactCreate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContactCreate type.
         * @member {PlatformAdminApi.CONTACT_TYPE} type
         * @memberof PlatformAdminApi.ContractorContactCreate
         * @instance
         */
        ContractorContactCreate.prototype.type = 0;

        /**
         * ContractorContactCreate value.
         * @member {string} value
         * @memberof PlatformAdminApi.ContractorContactCreate
         * @instance
         */
        ContractorContactCreate.prototype.value = "";

        /**
         * Encodes the specified ContractorContactCreate message. Does not implicitly {@link PlatformAdminApi.ContractorContactCreate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContactCreate
         * @static
         * @param {PlatformAdminApi.IContractorContactCreate} message ContractorContactCreate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContactCreate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Decodes a ContractorContactCreate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContactCreate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContactCreate} ContractorContactCreate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContactCreate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContactCreate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContactCreate;
    })();

    PlatformAdminApi.ContractorContactDeleteRequest = (function() {

        /**
         * Properties of a ContractorContactDeleteRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContactDeleteRequest
         * @property {string|null} [contractorId] ContractorContactDeleteRequest contractorId
         * @property {string|null} [contactId] ContractorContactDeleteRequest contactId
         */

        /**
         * Constructs a new ContractorContactDeleteRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContactDeleteRequest.
         * @implements IContractorContactDeleteRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContactDeleteRequest=} [properties] Properties to set
         */
        function ContractorContactDeleteRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContactDeleteRequest contractorId.
         * @member {string} contractorId
         * @memberof PlatformAdminApi.ContractorContactDeleteRequest
         * @instance
         */
        ContractorContactDeleteRequest.prototype.contractorId = "";

        /**
         * ContractorContactDeleteRequest contactId.
         * @member {string} contactId
         * @memberof PlatformAdminApi.ContractorContactDeleteRequest
         * @instance
         */
        ContractorContactDeleteRequest.prototype.contactId = "";

        /**
         * Encodes the specified ContractorContactDeleteRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContactDeleteRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContactDeleteRequest
         * @static
         * @param {PlatformAdminApi.IContractorContactDeleteRequest} message ContractorContactDeleteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContactDeleteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.contractorId);
            if (message.contactId != null && Object.hasOwnProperty.call(message, "contactId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contactId);
            return writer;
        };

        /**
         * Decodes a ContractorContactDeleteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContactDeleteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContactDeleteRequest} ContractorContactDeleteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContactDeleteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContactDeleteRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.contractorId = reader.string();
                    break;
                case 2:
                    message.contactId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContactDeleteRequest;
    })();

    PlatformAdminApi.ContractorContractRequest = (function() {

        /**
         * Properties of a ContractorContractRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContractRequest
         * @property {string|null} [id] ContractorContractRequest id
         * @property {boolean|null} [shouldShowBlocked] ContractorContractRequest shouldShowBlocked
         */

        /**
         * Constructs a new ContractorContractRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractRequest.
         * @implements IContractorContractRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContractRequest=} [properties] Properties to set
         */
        function ContractorContractRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorContractRequest
         * @instance
         */
        ContractorContractRequest.prototype.id = "";

        /**
         * ContractorContractRequest shouldShowBlocked.
         * @member {boolean} shouldShowBlocked
         * @memberof PlatformAdminApi.ContractorContractRequest
         * @instance
         */
        ContractorContractRequest.prototype.shouldShowBlocked = false;

        /**
         * Encodes the specified ContractorContractRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContractRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractRequest
         * @static
         * @param {PlatformAdminApi.IContractorContractRequest} message ContractorContractRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.shouldShowBlocked != null && Object.hasOwnProperty.call(message, "shouldShowBlocked"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.shouldShowBlocked);
            return writer;
        };

        /**
         * Decodes a ContractorContractRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractRequest} ContractorContractRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.shouldShowBlocked = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractRequest;
    })();

    PlatformAdminApi.ContractorContractResponse = (function() {

        /**
         * Properties of a ContractorContractResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorContractResponse
         * @property {Array.<PlatformAdminApi.IContractorContract>|null} [data] ContractorContractResponse data
         */

        /**
         * Constructs a new ContractorContractResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractResponse.
         * @implements IContractorContractResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorContractResponse=} [properties] Properties to set
         */
        function ContractorContractResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractResponse data.
         * @member {Array.<PlatformAdminApi.IContractorContract>} data
         * @memberof PlatformAdminApi.ContractorContractResponse
         * @instance
         */
        ContractorContractResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified ContractorContractResponse message. Does not implicitly {@link PlatformAdminApi.ContractorContractResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractResponse
         * @static
         * @param {PlatformAdminApi.IContractorContractResponse} message ContractorContractResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.ContractorContract.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContractResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractResponse} ContractorContractResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.ContractorContract.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractResponse;
    })();

    PlatformAdminApi.ContractorContract = (function() {

        /**
         * Properties of a ContractorContract.
         * @memberof PlatformAdminApi
         * @interface IContractorContract
         * @property {string|null} [id] ContractorContract id
         * @property {string|null} [creationDate] ContractorContract creationDate
         * @property {string|null} [product] ContractorContract product
         * @property {string|null} [role] ContractorContract role
         * @property {boolean|null} [isBlocked] ContractorContract isBlocked
         * @property {string|null} [blockDate] ContractorContract blockDate
         * @property {string|null} [wage] ContractorContract wage
         * @property {string|null} [grade] ContractorContract grade
         */

        /**
         * Constructs a new ContractorContract.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContract.
         * @implements IContractorContract
         * @constructor
         * @param {PlatformAdminApi.IContractorContract=} [properties] Properties to set
         */
        function ContractorContract(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContract id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.id = "";

        /**
         * ContractorContract creationDate.
         * @member {string} creationDate
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.creationDate = "";

        /**
         * ContractorContract product.
         * @member {string} product
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.product = "";

        /**
         * ContractorContract role.
         * @member {string} role
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.role = "";

        /**
         * ContractorContract isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.isBlocked = false;

        /**
         * ContractorContract blockDate.
         * @member {string|null|undefined} blockDate
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.blockDate = null;

        /**
         * ContractorContract wage.
         * @member {string} wage
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.wage = "";

        /**
         * ContractorContract grade.
         * @member {string} grade
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        ContractorContract.prototype.grade = "";

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ContractorContract _blockDate.
         * @member {"blockDate"|undefined} _blockDate
         * @memberof PlatformAdminApi.ContractorContract
         * @instance
         */
        Object.defineProperty(ContractorContract.prototype, "_blockDate", {
            get: $util.oneOfGetter($oneOfFields = ["blockDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ContractorContract message. Does not implicitly {@link PlatformAdminApi.ContractorContract.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContract
         * @static
         * @param {PlatformAdminApi.IContractorContract} message ContractorContract message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContract.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.creationDate != null && Object.hasOwnProperty.call(message, "creationDate"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.creationDate);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.product);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.role);
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isBlocked);
            if (message.blockDate != null && Object.hasOwnProperty.call(message, "blockDate"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.blockDate);
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.wage);
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.grade);
            return writer;
        };

        /**
         * Decodes a ContractorContract message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContract
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContract} ContractorContract
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContract.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContract();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.creationDate = reader.string();
                    break;
                case 3:
                    message.product = reader.string();
                    break;
                case 4:
                    message.role = reader.string();
                    break;
                case 5:
                    message.isBlocked = reader.bool();
                    break;
                case 6:
                    message.blockDate = reader.string();
                    break;
                case 7:
                    message.wage = reader.string();
                    break;
                case 8:
                    message.grade = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContract;
    })();

    PlatformAdminApi.ContractorContractBlockRequest = (function() {

        /**
         * Properties of a ContractorContractBlockRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContractBlockRequest
         * @property {string|null} [id] ContractorContractBlockRequest id
         * @property {PlatformAdminApi.IContractorContractBlockingReason|null} [blockingReason] ContractorContractBlockRequest blockingReason
         */

        /**
         * Constructs a new ContractorContractBlockRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractBlockRequest.
         * @implements IContractorContractBlockRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContractBlockRequest=} [properties] Properties to set
         */
        function ContractorContractBlockRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractBlockRequest id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorContractBlockRequest
         * @instance
         */
        ContractorContractBlockRequest.prototype.id = "";

        /**
         * ContractorContractBlockRequest blockingReason.
         * @member {PlatformAdminApi.IContractorContractBlockingReason|null|undefined} blockingReason
         * @memberof PlatformAdminApi.ContractorContractBlockRequest
         * @instance
         */
        ContractorContractBlockRequest.prototype.blockingReason = null;

        /**
         * Encodes the specified ContractorContractBlockRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContractBlockRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractBlockRequest
         * @static
         * @param {PlatformAdminApi.IContractorContractBlockRequest} message ContractorContractBlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractBlockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.blockingReason != null && Object.hasOwnProperty.call(message, "blockingReason"))
                $root.PlatformAdminApi.ContractorContractBlockingReason.encode(message.blockingReason, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContractBlockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractBlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractBlockRequest} ContractorContractBlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractBlockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractBlockRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.blockingReason = $root.PlatformAdminApi.ContractorContractBlockingReason.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractBlockRequest;
    })();

    PlatformAdminApi.ContractorContractBlockResponse = (function() {

        /**
         * Properties of a ContractorContractBlockResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorContractBlockResponse
         * @property {PlatformAdminApi.IContractorContract|null} [updatedContract] ContractorContractBlockResponse updatedContract
         */

        /**
         * Constructs a new ContractorContractBlockResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractBlockResponse.
         * @implements IContractorContractBlockResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorContractBlockResponse=} [properties] Properties to set
         */
        function ContractorContractBlockResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractBlockResponse updatedContract.
         * @member {PlatformAdminApi.IContractorContract|null|undefined} updatedContract
         * @memberof PlatformAdminApi.ContractorContractBlockResponse
         * @instance
         */
        ContractorContractBlockResponse.prototype.updatedContract = null;

        /**
         * Encodes the specified ContractorContractBlockResponse message. Does not implicitly {@link PlatformAdminApi.ContractorContractBlockResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractBlockResponse
         * @static
         * @param {PlatformAdminApi.IContractorContractBlockResponse} message ContractorContractBlockResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractBlockResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.updatedContract != null && Object.hasOwnProperty.call(message, "updatedContract"))
                $root.PlatformAdminApi.ContractorContract.encode(message.updatedContract, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContractBlockResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractBlockResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractBlockResponse} ContractorContractBlockResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractBlockResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractBlockResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.updatedContract = $root.PlatformAdminApi.ContractorContract.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractBlockResponse;
    })();

    PlatformAdminApi.ContractorContractBlockingReason = (function() {

        /**
         * Properties of a ContractorContractBlockingReason.
         * @memberof PlatformAdminApi
         * @interface IContractorContractBlockingReason
         * @property {string|null} [id] ContractorContractBlockingReason id
         * @property {string|null} [name] ContractorContractBlockingReason name
         */

        /**
         * Constructs a new ContractorContractBlockingReason.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractBlockingReason.
         * @implements IContractorContractBlockingReason
         * @constructor
         * @param {PlatformAdminApi.IContractorContractBlockingReason=} [properties] Properties to set
         */
        function ContractorContractBlockingReason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractBlockingReason id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorContractBlockingReason
         * @instance
         */
        ContractorContractBlockingReason.prototype.id = "";

        /**
         * ContractorContractBlockingReason name.
         * @member {string} name
         * @memberof PlatformAdminApi.ContractorContractBlockingReason
         * @instance
         */
        ContractorContractBlockingReason.prototype.name = "";

        /**
         * Encodes the specified ContractorContractBlockingReason message. Does not implicitly {@link PlatformAdminApi.ContractorContractBlockingReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractBlockingReason
         * @static
         * @param {PlatformAdminApi.IContractorContractBlockingReason} message ContractorContractBlockingReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractBlockingReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a ContractorContractBlockingReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractBlockingReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractBlockingReason} ContractorContractBlockingReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractBlockingReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractBlockingReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractBlockingReason;
    })();

    PlatformAdminApi.ContractorContractBlockingReasonGroup = (function() {

        /**
         * Properties of a ContractorContractBlockingReasonGroup.
         * @memberof PlatformAdminApi
         * @interface IContractorContractBlockingReasonGroup
         * @property {string|null} [id] ContractorContractBlockingReasonGroup id
         * @property {string|null} [name] ContractorContractBlockingReasonGroup name
         */

        /**
         * Constructs a new ContractorContractBlockingReasonGroup.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractBlockingReasonGroup.
         * @implements IContractorContractBlockingReasonGroup
         * @constructor
         * @param {PlatformAdminApi.IContractorContractBlockingReasonGroup=} [properties] Properties to set
         */
        function ContractorContractBlockingReasonGroup(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractBlockingReasonGroup id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorContractBlockingReasonGroup
         * @instance
         */
        ContractorContractBlockingReasonGroup.prototype.id = "";

        /**
         * ContractorContractBlockingReasonGroup name.
         * @member {string} name
         * @memberof PlatformAdminApi.ContractorContractBlockingReasonGroup
         * @instance
         */
        ContractorContractBlockingReasonGroup.prototype.name = "";

        /**
         * Encodes the specified ContractorContractBlockingReasonGroup message. Does not implicitly {@link PlatformAdminApi.ContractorContractBlockingReasonGroup.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractBlockingReasonGroup
         * @static
         * @param {PlatformAdminApi.IContractorContractBlockingReasonGroup} message ContractorContractBlockingReasonGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractBlockingReasonGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a ContractorContractBlockingReasonGroup message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractBlockingReasonGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractBlockingReasonGroup} ContractorContractBlockingReasonGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractBlockingReasonGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractBlockingReasonGroup();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractBlockingReasonGroup;
    })();

    PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest = (function() {

        /**
         * Properties of a ContractorContractSearchBlockingReasonGroupsRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContractSearchBlockingReasonGroupsRequest
         * @property {string|null} [searchString] ContractorContractSearchBlockingReasonGroupsRequest searchString
         */

        /**
         * Constructs a new ContractorContractSearchBlockingReasonGroupsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractSearchBlockingReasonGroupsRequest.
         * @implements IContractorContractSearchBlockingReasonGroupsRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonGroupsRequest=} [properties] Properties to set
         */
        function ContractorContractSearchBlockingReasonGroupsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractSearchBlockingReasonGroupsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest
         * @instance
         */
        ContractorContractSearchBlockingReasonGroupsRequest.prototype.searchString = "";

        /**
         * Encodes the specified ContractorContractSearchBlockingReasonGroupsRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest
         * @static
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonGroupsRequest} message ContractorContractSearchBlockingReasonGroupsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractSearchBlockingReasonGroupsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a ContractorContractSearchBlockingReasonGroupsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest} ContractorContractSearchBlockingReasonGroupsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractSearchBlockingReasonGroupsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractSearchBlockingReasonGroupsRequest;
    })();

    PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse = (function() {

        /**
         * Properties of a ContractorContractSearchBlockingReasonGroupsResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorContractSearchBlockingReasonGroupsResponse
         * @property {Array.<PlatformAdminApi.IContractorContractBlockingReasonGroup>|null} [blockingReasonsGroups] ContractorContractSearchBlockingReasonGroupsResponse blockingReasonsGroups
         */

        /**
         * Constructs a new ContractorContractSearchBlockingReasonGroupsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractSearchBlockingReasonGroupsResponse.
         * @implements IContractorContractSearchBlockingReasonGroupsResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonGroupsResponse=} [properties] Properties to set
         */
        function ContractorContractSearchBlockingReasonGroupsResponse(properties) {
            this.blockingReasonsGroups = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractSearchBlockingReasonGroupsResponse blockingReasonsGroups.
         * @member {Array.<PlatformAdminApi.IContractorContractBlockingReasonGroup>} blockingReasonsGroups
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse
         * @instance
         */
        ContractorContractSearchBlockingReasonGroupsResponse.prototype.blockingReasonsGroups = $util.emptyArray;

        /**
         * Encodes the specified ContractorContractSearchBlockingReasonGroupsResponse message. Does not implicitly {@link PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse
         * @static
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonGroupsResponse} message ContractorContractSearchBlockingReasonGroupsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractSearchBlockingReasonGroupsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blockingReasonsGroups != null && message.blockingReasonsGroups.length)
                for (var i = 0; i < message.blockingReasonsGroups.length; ++i)
                    $root.PlatformAdminApi.ContractorContractBlockingReasonGroup.encode(message.blockingReasonsGroups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContractSearchBlockingReasonGroupsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse} ContractorContractSearchBlockingReasonGroupsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractSearchBlockingReasonGroupsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.blockingReasonsGroups && message.blockingReasonsGroups.length))
                        message.blockingReasonsGroups = [];
                    message.blockingReasonsGroups.push($root.PlatformAdminApi.ContractorContractBlockingReasonGroup.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractSearchBlockingReasonGroupsResponse;
    })();

    PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest = (function() {

        /**
         * Properties of a ContractorContractSearchBlockingReasonsRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContractSearchBlockingReasonsRequest
         * @property {string|null} [searchString] ContractorContractSearchBlockingReasonsRequest searchString
         * @property {string|null} [groupName] ContractorContractSearchBlockingReasonsRequest groupName
         */

        /**
         * Constructs a new ContractorContractSearchBlockingReasonsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractSearchBlockingReasonsRequest.
         * @implements IContractorContractSearchBlockingReasonsRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonsRequest=} [properties] Properties to set
         */
        function ContractorContractSearchBlockingReasonsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractSearchBlockingReasonsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest
         * @instance
         */
        ContractorContractSearchBlockingReasonsRequest.prototype.searchString = "";

        /**
         * ContractorContractSearchBlockingReasonsRequest groupName.
         * @member {string} groupName
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest
         * @instance
         */
        ContractorContractSearchBlockingReasonsRequest.prototype.groupName = "";

        /**
         * Encodes the specified ContractorContractSearchBlockingReasonsRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest
         * @static
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonsRequest} message ContractorContractSearchBlockingReasonsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractSearchBlockingReasonsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.groupName);
            return writer;
        };

        /**
         * Decodes a ContractorContractSearchBlockingReasonsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest} ContractorContractSearchBlockingReasonsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractSearchBlockingReasonsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                case 2:
                    message.groupName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractSearchBlockingReasonsRequest;
    })();

    PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse = (function() {

        /**
         * Properties of a ContractorContractSearchBlockingReasonsResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorContractSearchBlockingReasonsResponse
         * @property {Array.<PlatformAdminApi.IContractorContractBlockingReason>|null} [blockingReasons] ContractorContractSearchBlockingReasonsResponse blockingReasons
         */

        /**
         * Constructs a new ContractorContractSearchBlockingReasonsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractSearchBlockingReasonsResponse.
         * @implements IContractorContractSearchBlockingReasonsResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonsResponse=} [properties] Properties to set
         */
        function ContractorContractSearchBlockingReasonsResponse(properties) {
            this.blockingReasons = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractSearchBlockingReasonsResponse blockingReasons.
         * @member {Array.<PlatformAdminApi.IContractorContractBlockingReason>} blockingReasons
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse
         * @instance
         */
        ContractorContractSearchBlockingReasonsResponse.prototype.blockingReasons = $util.emptyArray;

        /**
         * Encodes the specified ContractorContractSearchBlockingReasonsResponse message. Does not implicitly {@link PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse
         * @static
         * @param {PlatformAdminApi.IContractorContractSearchBlockingReasonsResponse} message ContractorContractSearchBlockingReasonsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractSearchBlockingReasonsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blockingReasons != null && message.blockingReasons.length)
                for (var i = 0; i < message.blockingReasons.length; ++i)
                    $root.PlatformAdminApi.ContractorContractBlockingReason.encode(message.blockingReasons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContractSearchBlockingReasonsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse} ContractorContractSearchBlockingReasonsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractSearchBlockingReasonsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.blockingReasons && message.blockingReasons.length))
                        message.blockingReasons = [];
                    message.blockingReasons.push($root.PlatformAdminApi.ContractorContractBlockingReason.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractSearchBlockingReasonsResponse;
    })();

    PlatformAdminApi.ContractorContractCreateRequest = (function() {

        /**
         * Properties of a ContractorContractCreateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContractCreateRequest
         * @property {string|null} [contractorId] ContractorContractCreateRequest contractorId
         * @property {string|null} [wageId] ContractorContractCreateRequest wageId
         * @property {Array.<string>|null} [grades] ContractorContractCreateRequest grades
         * @property {Array.<string>|null} [skills] ContractorContractCreateRequest skills
         */

        /**
         * Constructs a new ContractorContractCreateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractCreateRequest.
         * @implements IContractorContractCreateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContractCreateRequest=} [properties] Properties to set
         */
        function ContractorContractCreateRequest(properties) {
            this.grades = [];
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractCreateRequest contractorId.
         * @member {string} contractorId
         * @memberof PlatformAdminApi.ContractorContractCreateRequest
         * @instance
         */
        ContractorContractCreateRequest.prototype.contractorId = "";

        /**
         * ContractorContractCreateRequest wageId.
         * @member {string} wageId
         * @memberof PlatformAdminApi.ContractorContractCreateRequest
         * @instance
         */
        ContractorContractCreateRequest.prototype.wageId = "";

        /**
         * ContractorContractCreateRequest grades.
         * @member {Array.<string>} grades
         * @memberof PlatformAdminApi.ContractorContractCreateRequest
         * @instance
         */
        ContractorContractCreateRequest.prototype.grades = $util.emptyArray;

        /**
         * ContractorContractCreateRequest skills.
         * @member {Array.<string>} skills
         * @memberof PlatformAdminApi.ContractorContractCreateRequest
         * @instance
         */
        ContractorContractCreateRequest.prototype.skills = $util.emptyArray;

        /**
         * Encodes the specified ContractorContractCreateRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContractCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractCreateRequest
         * @static
         * @param {PlatformAdminApi.IContractorContractCreateRequest} message ContractorContractCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contractorId);
            if (message.wageId != null && Object.hasOwnProperty.call(message, "wageId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.wageId);
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.grades[i]);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.skills[i]);
            return writer;
        };

        /**
         * Decodes a ContractorContractCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractCreateRequest} ContractorContractCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractCreateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.contractorId = reader.string();
                    break;
                case 3:
                    message.wageId = reader.string();
                    break;
                case 4:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push(reader.string());
                    break;
                case 5:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractCreateRequest;
    })();

    PlatformAdminApi.ContractorContractInformationResponse = (function() {

        /**
         * Properties of a ContractorContractInformationResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorContractInformationResponse
         * @property {string|null} [createDate] ContractorContractInformationResponse createDate
         * @property {string|null} [product] ContractorContractInformationResponse product
         * @property {PlatformAdminApi.ISelectOption|null} [wage] ContractorContractInformationResponse wage
         * @property {Array.<PlatformAdminApi.ISelectOption>|null} [grades] ContractorContractInformationResponse grades
         * @property {Array.<PlatformAdminApi.ISelectOption>|null} [skills] ContractorContractInformationResponse skills
         */

        /**
         * Constructs a new ContractorContractInformationResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractInformationResponse.
         * @implements IContractorContractInformationResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorContractInformationResponse=} [properties] Properties to set
         */
        function ContractorContractInformationResponse(properties) {
            this.grades = [];
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractInformationResponse createDate.
         * @member {string} createDate
         * @memberof PlatformAdminApi.ContractorContractInformationResponse
         * @instance
         */
        ContractorContractInformationResponse.prototype.createDate = "";

        /**
         * ContractorContractInformationResponse product.
         * @member {string} product
         * @memberof PlatformAdminApi.ContractorContractInformationResponse
         * @instance
         */
        ContractorContractInformationResponse.prototype.product = "";

        /**
         * ContractorContractInformationResponse wage.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} wage
         * @memberof PlatformAdminApi.ContractorContractInformationResponse
         * @instance
         */
        ContractorContractInformationResponse.prototype.wage = null;

        /**
         * ContractorContractInformationResponse grades.
         * @member {Array.<PlatformAdminApi.ISelectOption>} grades
         * @memberof PlatformAdminApi.ContractorContractInformationResponse
         * @instance
         */
        ContractorContractInformationResponse.prototype.grades = $util.emptyArray;

        /**
         * ContractorContractInformationResponse skills.
         * @member {Array.<PlatformAdminApi.ISelectOption>} skills
         * @memberof PlatformAdminApi.ContractorContractInformationResponse
         * @instance
         */
        ContractorContractInformationResponse.prototype.skills = $util.emptyArray;

        /**
         * Encodes the specified ContractorContractInformationResponse message. Does not implicitly {@link PlatformAdminApi.ContractorContractInformationResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractInformationResponse
         * @static
         * @param {PlatformAdminApi.IContractorContractInformationResponse} message ContractorContractInformationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractInformationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.createDate);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.product);
            if (message.wage != null && Object.hasOwnProperty.call(message, "wage"))
                $root.PlatformAdminApi.SelectOption.encode(message.wage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.SelectOption.encode(message.grades[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformAdminApi.SelectOption.encode(message.skills[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContractInformationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractInformationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractInformationResponse} ContractorContractInformationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractInformationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractInformationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.createDate = reader.string();
                    break;
                case 2:
                    message.product = reader.string();
                    break;
                case 3:
                    message.wage = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 4:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractInformationResponse;
    })();

    PlatformAdminApi.ContractorContractUpdateRequest = (function() {

        /**
         * Properties of a ContractorContractUpdateRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorContractUpdateRequest
         * @property {string|null} [contractId] ContractorContractUpdateRequest contractId
         * @property {string|null} [contractorId] ContractorContractUpdateRequest contractorId
         * @property {string|null} [wageId] ContractorContractUpdateRequest wageId
         * @property {Array.<string>|null} [grades] ContractorContractUpdateRequest grades
         * @property {Array.<string>|null} [skills] ContractorContractUpdateRequest skills
         */

        /**
         * Constructs a new ContractorContractUpdateRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContractUpdateRequest.
         * @implements IContractorContractUpdateRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorContractUpdateRequest=} [properties] Properties to set
         */
        function ContractorContractUpdateRequest(properties) {
            this.grades = [];
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContractUpdateRequest contractId.
         * @member {string} contractId
         * @memberof PlatformAdminApi.ContractorContractUpdateRequest
         * @instance
         */
        ContractorContractUpdateRequest.prototype.contractId = "";

        /**
         * ContractorContractUpdateRequest contractorId.
         * @member {string} contractorId
         * @memberof PlatformAdminApi.ContractorContractUpdateRequest
         * @instance
         */
        ContractorContractUpdateRequest.prototype.contractorId = "";

        /**
         * ContractorContractUpdateRequest wageId.
         * @member {string} wageId
         * @memberof PlatformAdminApi.ContractorContractUpdateRequest
         * @instance
         */
        ContractorContractUpdateRequest.prototype.wageId = "";

        /**
         * ContractorContractUpdateRequest grades.
         * @member {Array.<string>} grades
         * @memberof PlatformAdminApi.ContractorContractUpdateRequest
         * @instance
         */
        ContractorContractUpdateRequest.prototype.grades = $util.emptyArray;

        /**
         * ContractorContractUpdateRequest skills.
         * @member {Array.<string>} skills
         * @memberof PlatformAdminApi.ContractorContractUpdateRequest
         * @instance
         */
        ContractorContractUpdateRequest.prototype.skills = $util.emptyArray;

        /**
         * Encodes the specified ContractorContractUpdateRequest message. Does not implicitly {@link PlatformAdminApi.ContractorContractUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContractUpdateRequest
         * @static
         * @param {PlatformAdminApi.IContractorContractUpdateRequest} message ContractorContractUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContractUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contractId != null && Object.hasOwnProperty.call(message, "contractId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.contractId);
            if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contractorId);
            if (message.wageId != null && Object.hasOwnProperty.call(message, "wageId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.wageId);
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.grades[i]);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.skills[i]);
            return writer;
        };

        /**
         * Decodes a ContractorContractUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContractUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContractUpdateRequest} ContractorContractUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContractUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContractUpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.contractId = reader.string();
                    break;
                case 2:
                    message.contractorId = reader.string();
                    break;
                case 3:
                    message.wageId = reader.string();
                    break;
                case 4:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push(reader.string());
                    break;
                case 5:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContractUpdateRequest;
    })();

    PlatformAdminApi.ContractorAddressForm = (function() {

        /**
         * Properties of a ContractorAddressForm.
         * @memberof PlatformAdminApi
         * @interface IContractorAddressForm
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] ContractorAddressForm type
         * @property {PlatformAdminApi.IAddress|null} [value] ContractorAddressForm value
         */

        /**
         * Constructs a new ContractorAddressForm.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorAddressForm.
         * @implements IContractorAddressForm
         * @constructor
         * @param {PlatformAdminApi.IContractorAddressForm=} [properties] Properties to set
         */
        function ContractorAddressForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorAddressForm type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.ContractorAddressForm
         * @instance
         */
        ContractorAddressForm.prototype.type = 0;

        /**
         * ContractorAddressForm value.
         * @member {PlatformAdminApi.IAddress|null|undefined} value
         * @memberof PlatformAdminApi.ContractorAddressForm
         * @instance
         */
        ContractorAddressForm.prototype.value = null;

        /**
         * Encodes the specified ContractorAddressForm message. Does not implicitly {@link PlatformAdminApi.ContractorAddressForm.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorAddressForm
         * @static
         * @param {PlatformAdminApi.IContractorAddressForm} message ContractorAddressForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorAddressForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.PlatformAdminApi.Address.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorAddressForm message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorAddressForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorAddressForm} ContractorAddressForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorAddressForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorAddressForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorAddressForm;
    })();

    PlatformAdminApi.ContractorBlockingReasonRequest = (function() {

        /**
         * Properties of a ContractorBlockingReasonRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorBlockingReasonRequest
         * @property {string|null} [searchString] ContractorBlockingReasonRequest searchString
         */

        /**
         * Constructs a new ContractorBlockingReasonRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorBlockingReasonRequest.
         * @implements IContractorBlockingReasonRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorBlockingReasonRequest=} [properties] Properties to set
         */
        function ContractorBlockingReasonRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorBlockingReasonRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.ContractorBlockingReasonRequest
         * @instance
         */
        ContractorBlockingReasonRequest.prototype.searchString = "";

        /**
         * Encodes the specified ContractorBlockingReasonRequest message. Does not implicitly {@link PlatformAdminApi.ContractorBlockingReasonRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorBlockingReasonRequest
         * @static
         * @param {PlatformAdminApi.IContractorBlockingReasonRequest} message ContractorBlockingReasonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorBlockingReasonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a ContractorBlockingReasonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorBlockingReasonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorBlockingReasonRequest} ContractorBlockingReasonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorBlockingReasonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorBlockingReasonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorBlockingReasonRequest;
    })();

    PlatformAdminApi.ContractorBlockingReasonResponse = (function() {

        /**
         * Properties of a ContractorBlockingReasonResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorBlockingReasonResponse
         * @property {Array.<PlatformAdminApi.IUserBlockingReason>|null} [data] ContractorBlockingReasonResponse data
         */

        /**
         * Constructs a new ContractorBlockingReasonResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorBlockingReasonResponse.
         * @implements IContractorBlockingReasonResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorBlockingReasonResponse=} [properties] Properties to set
         */
        function ContractorBlockingReasonResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorBlockingReasonResponse data.
         * @member {Array.<PlatformAdminApi.IUserBlockingReason>} data
         * @memberof PlatformAdminApi.ContractorBlockingReasonResponse
         * @instance
         */
        ContractorBlockingReasonResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified ContractorBlockingReasonResponse message. Does not implicitly {@link PlatformAdminApi.ContractorBlockingReasonResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorBlockingReasonResponse
         * @static
         * @param {PlatformAdminApi.IContractorBlockingReasonResponse} message ContractorBlockingReasonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorBlockingReasonResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.UserBlockingReason.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorBlockingReasonResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorBlockingReasonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorBlockingReasonResponse} ContractorBlockingReasonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorBlockingReasonResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorBlockingReasonResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.UserBlockingReason.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorBlockingReasonResponse;
    })();

    PlatformAdminApi.ContractorEditFrontend = (function() {

        /**
         * Properties of a ContractorEditFrontend.
         * @memberof PlatformAdminApi
         * @interface IContractorEditFrontend
         * @property {string|null} [id] ContractorEditFrontend id
         * @property {PlatformAdminApi.ContractorEditFrontend.IFioObject|null} [fioData] ContractorEditFrontend fioData
         * @property {string|null} [birthday] ContractorEditFrontend birthday
         * @property {PlatformAdminApi.ContractorEditFrontend.IUserTypeFrontend|null} [userType] ContractorEditFrontend userType
         * @property {string|null} [gender] ContractorEditFrontend gender
         * @property {PlatformAdminApi.ITenant|null} [tenant] ContractorEditFrontend tenant
         * @property {boolean|null} [isBlocked] ContractorEditFrontend isBlocked
         * @property {string|null} [blockingReasonDescription] ContractorEditFrontend blockingReasonDescription
         * @property {string|null} [blockedAt] ContractorEditFrontend blockedAt
         * @property {string|null} [createdAt] ContractorEditFrontend createdAt
         */

        /**
         * Constructs a new ContractorEditFrontend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorEditFrontend.
         * @implements IContractorEditFrontend
         * @constructor
         * @param {PlatformAdminApi.IContractorEditFrontend=} [properties] Properties to set
         */
        function ContractorEditFrontend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorEditFrontend id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.id = "";

        /**
         * ContractorEditFrontend fioData.
         * @member {PlatformAdminApi.ContractorEditFrontend.IFioObject|null|undefined} fioData
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.fioData = null;

        /**
         * ContractorEditFrontend birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.birthday = "";

        /**
         * ContractorEditFrontend userType.
         * @member {PlatformAdminApi.ContractorEditFrontend.IUserTypeFrontend|null|undefined} userType
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.userType = null;

        /**
         * ContractorEditFrontend gender.
         * @member {string} gender
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.gender = "";

        /**
         * ContractorEditFrontend tenant.
         * @member {PlatformAdminApi.ITenant|null|undefined} tenant
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.tenant = null;

        /**
         * ContractorEditFrontend isBlocked.
         * @member {boolean} isBlocked
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.isBlocked = false;

        /**
         * ContractorEditFrontend blockingReasonDescription.
         * @member {string} blockingReasonDescription
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.blockingReasonDescription = "";

        /**
         * ContractorEditFrontend blockedAt.
         * @member {string} blockedAt
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.blockedAt = "";

        /**
         * ContractorEditFrontend createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @instance
         */
        ContractorEditFrontend.prototype.createdAt = "";

        /**
         * Encodes the specified ContractorEditFrontend message. Does not implicitly {@link PlatformAdminApi.ContractorEditFrontend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @static
         * @param {PlatformAdminApi.IContractorEditFrontend} message ContractorEditFrontend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorEditFrontend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.fioData != null && Object.hasOwnProperty.call(message, "fioData"))
                $root.PlatformAdminApi.ContractorEditFrontend.FioObject.encode(message.fioData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.birthday);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                $root.PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend.encode(message.userType, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gender);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                $root.PlatformAdminApi.Tenant.encode(message.tenant, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isBlocked != null && Object.hasOwnProperty.call(message, "isBlocked"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isBlocked);
            if (message.blockingReasonDescription != null && Object.hasOwnProperty.call(message, "blockingReasonDescription"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.blockingReasonDescription);
            if (message.blockedAt != null && Object.hasOwnProperty.call(message, "blockedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.blockedAt);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.createdAt);
            return writer;
        };

        /**
         * Decodes a ContractorEditFrontend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorEditFrontend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorEditFrontend} ContractorEditFrontend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorEditFrontend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorEditFrontend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.fioData = $root.PlatformAdminApi.ContractorEditFrontend.FioObject.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.birthday = reader.string();
                    break;
                case 4:
                    message.userType = $root.PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.gender = reader.string();
                    break;
                case 6:
                    message.tenant = $root.PlatformAdminApi.Tenant.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isBlocked = reader.bool();
                    break;
                case 8:
                    message.blockingReasonDescription = reader.string();
                    break;
                case 9:
                    message.blockedAt = reader.string();
                    break;
                case 10:
                    message.createdAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ContractorEditFrontend.FioObject = (function() {

            /**
             * Properties of a FioObject.
             * @memberof PlatformAdminApi.ContractorEditFrontend
             * @interface IFioObject
             * @property {PlatformAdminApi.IProfileDadataFIO|null} [dadataFio] FioObject dadataFio
             * @property {string|null} [firstName] FioObject firstName
             * @property {string|null} [lastName] FioObject lastName
             * @property {string|null} [middleName] FioObject middleName
             */

            /**
             * Constructs a new FioObject.
             * @memberof PlatformAdminApi.ContractorEditFrontend
             * @classdesc Represents a FioObject.
             * @implements IFioObject
             * @constructor
             * @param {PlatformAdminApi.ContractorEditFrontend.IFioObject=} [properties] Properties to set
             */
            function FioObject(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FioObject dadataFio.
             * @member {PlatformAdminApi.IProfileDadataFIO|null|undefined} dadataFio
             * @memberof PlatformAdminApi.ContractorEditFrontend.FioObject
             * @instance
             */
            FioObject.prototype.dadataFio = null;

            /**
             * FioObject firstName.
             * @member {string} firstName
             * @memberof PlatformAdminApi.ContractorEditFrontend.FioObject
             * @instance
             */
            FioObject.prototype.firstName = "";

            /**
             * FioObject lastName.
             * @member {string} lastName
             * @memberof PlatformAdminApi.ContractorEditFrontend.FioObject
             * @instance
             */
            FioObject.prototype.lastName = "";

            /**
             * FioObject middleName.
             * @member {string} middleName
             * @memberof PlatformAdminApi.ContractorEditFrontend.FioObject
             * @instance
             */
            FioObject.prototype.middleName = "";

            /**
             * Encodes the specified FioObject message. Does not implicitly {@link PlatformAdminApi.ContractorEditFrontend.FioObject.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ContractorEditFrontend.FioObject
             * @static
             * @param {PlatformAdminApi.ContractorEditFrontend.IFioObject} message FioObject message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FioObject.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dadataFio != null && Object.hasOwnProperty.call(message, "dadataFio"))
                    $root.PlatformAdminApi.ProfileDadataFIO.encode(message.dadataFio, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.middleName);
                return writer;
            };

            /**
             * Decodes a FioObject message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ContractorEditFrontend.FioObject
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ContractorEditFrontend.FioObject} FioObject
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FioObject.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorEditFrontend.FioObject();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.dadataFio = $root.PlatformAdminApi.ProfileDadataFIO.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.firstName = reader.string();
                        break;
                    case 3:
                        message.lastName = reader.string();
                        break;
                    case 4:
                        message.middleName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return FioObject;
        })();

        ContractorEditFrontend.UserTypeFrontend = (function() {

            /**
             * Properties of a UserTypeFrontend.
             * @memberof PlatformAdminApi.ContractorEditFrontend
             * @interface IUserTypeFrontend
             * @property {string|null} [label] UserTypeFrontend label
             * @property {PlatformAdminApi.USER_TYPE|null} [name] UserTypeFrontend name
             */

            /**
             * Constructs a new UserTypeFrontend.
             * @memberof PlatformAdminApi.ContractorEditFrontend
             * @classdesc Represents a UserTypeFrontend.
             * @implements IUserTypeFrontend
             * @constructor
             * @param {PlatformAdminApi.ContractorEditFrontend.IUserTypeFrontend=} [properties] Properties to set
             */
            function UserTypeFrontend(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserTypeFrontend label.
             * @member {string} label
             * @memberof PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend
             * @instance
             */
            UserTypeFrontend.prototype.label = "";

            /**
             * UserTypeFrontend name.
             * @member {PlatformAdminApi.USER_TYPE} name
             * @memberof PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend
             * @instance
             */
            UserTypeFrontend.prototype.name = 0;

            /**
             * Encodes the specified UserTypeFrontend message. Does not implicitly {@link PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend
             * @static
             * @param {PlatformAdminApi.ContractorEditFrontend.IUserTypeFrontend} message UserTypeFrontend message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserTypeFrontend.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.name);
                return writer;
            };

            /**
             * Decodes a UserTypeFrontend message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend} UserTypeFrontend
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserTypeFrontend.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorEditFrontend.UserTypeFrontend();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.label = reader.string();
                        break;
                    case 2:
                        message.name = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return UserTypeFrontend;
        })();

        return ContractorEditFrontend;
    })();

    PlatformAdminApi.ContractorEditBackend = (function() {

        /**
         * Properties of a ContractorEditBackend.
         * @memberof PlatformAdminApi
         * @interface IContractorEditBackend
         * @property {string|null} [id] ContractorEditBackend id
         * @property {string|null} [firstName] ContractorEditBackend firstName
         * @property {string|null} [lastName] ContractorEditBackend lastName
         * @property {string|null} [middleName] ContractorEditBackend middleName
         * @property {string|null} [birthday] ContractorEditBackend birthday
         * @property {PlatformAdminApi.IProfileDadataFIO|null} [object] ContractorEditBackend object
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] ContractorEditBackend userType
         * @property {PlatformAdminApi.GENDER_TYPE|null} [gender] ContractorEditBackend gender
         * @property {string|null} [tenantName] ContractorEditBackend tenantName
         * @property {string|null} [tenantId] ContractorEditBackend tenantId
         */

        /**
         * Constructs a new ContractorEditBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorEditBackend.
         * @implements IContractorEditBackend
         * @constructor
         * @param {PlatformAdminApi.IContractorEditBackend=} [properties] Properties to set
         */
        function ContractorEditBackend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorEditBackend id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.id = "";

        /**
         * ContractorEditBackend firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.firstName = "";

        /**
         * ContractorEditBackend lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.lastName = "";

        /**
         * ContractorEditBackend middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.middleName = "";

        /**
         * ContractorEditBackend birthday.
         * @member {string} birthday
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.birthday = "";

        /**
         * ContractorEditBackend object.
         * @member {PlatformAdminApi.IProfileDadataFIO|null|undefined} object
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.object = null;

        /**
         * ContractorEditBackend userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.userType = 0;

        /**
         * ContractorEditBackend gender.
         * @member {PlatformAdminApi.GENDER_TYPE} gender
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.gender = 0;

        /**
         * ContractorEditBackend tenantName.
         * @member {string} tenantName
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.tenantName = "";

        /**
         * ContractorEditBackend tenantId.
         * @member {string} tenantId
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @instance
         */
        ContractorEditBackend.prototype.tenantId = "";

        /**
         * Encodes the specified ContractorEditBackend message. Does not implicitly {@link PlatformAdminApi.ContractorEditBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @static
         * @param {PlatformAdminApi.IContractorEditBackend} message ContractorEditBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorEditBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.middleName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.birthday);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformAdminApi.ProfileDadataFIO.encode(message.object, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.userType);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.gender);
            if (message.tenantName != null && Object.hasOwnProperty.call(message, "tenantName"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.tenantName);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.tenantId);
            return writer;
        };

        /**
         * Decodes a ContractorEditBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorEditBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorEditBackend} ContractorEditBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorEditBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorEditBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.firstName = reader.string();
                    break;
                case 3:
                    message.lastName = reader.string();
                    break;
                case 4:
                    message.middleName = reader.string();
                    break;
                case 5:
                    message.birthday = reader.string();
                    break;
                case 6:
                    message.object = $root.PlatformAdminApi.ProfileDadataFIO.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.userType = reader.int32();
                    break;
                case 8:
                    message.gender = reader.int32();
                    break;
                case 9:
                    message.tenantName = reader.string();
                    break;
                case 10:
                    message.tenantId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorEditBackend;
    })();

    PlatformAdminApi.ContractorContactResponse = (function() {

        /**
         * Properties of a ContractorContactResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorContactResponse
         * @property {Array.<PlatformAdminApi.IContractorContact>|null} [phone] ContractorContactResponse phone
         * @property {Array.<PlatformAdminApi.IContractorContact>|null} [email] ContractorContactResponse email
         */

        /**
         * Constructs a new ContractorContactResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContactResponse.
         * @implements IContractorContactResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorContactResponse=} [properties] Properties to set
         */
        function ContractorContactResponse(properties) {
            this.phone = [];
            this.email = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContactResponse phone.
         * @member {Array.<PlatformAdminApi.IContractorContact>} phone
         * @memberof PlatformAdminApi.ContractorContactResponse
         * @instance
         */
        ContractorContactResponse.prototype.phone = $util.emptyArray;

        /**
         * ContractorContactResponse email.
         * @member {Array.<PlatformAdminApi.IContractorContact>} email
         * @memberof PlatformAdminApi.ContractorContactResponse
         * @instance
         */
        ContractorContactResponse.prototype.email = $util.emptyArray;

        /**
         * Encodes the specified ContractorContactResponse message. Does not implicitly {@link PlatformAdminApi.ContractorContactResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContactResponse
         * @static
         * @param {PlatformAdminApi.IContractorContactResponse} message ContractorContactResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContactResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && message.phone.length)
                for (var i = 0; i < message.phone.length; ++i)
                    $root.PlatformAdminApi.ContractorContact.encode(message.phone[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.email != null && message.email.length)
                for (var i = 0; i < message.email.length; ++i)
                    $root.PlatformAdminApi.ContractorContact.encode(message.email[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorContactResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContactResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContactResponse} ContractorContactResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContactResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContactResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.phone && message.phone.length))
                        message.phone = [];
                    message.phone.push($root.PlatformAdminApi.ContractorContact.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.email && message.email.length))
                        message.email = [];
                    message.email.push($root.PlatformAdminApi.ContractorContact.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContactResponse;
    })();

    PlatformAdminApi.ContractorContact = (function() {

        /**
         * Properties of a ContractorContact.
         * @memberof PlatformAdminApi
         * @interface IContractorContact
         * @property {string|null} [id] ContractorContact id
         * @property {PlatformAdminApi.CONTACT_TYPE|null} [type] ContractorContact type
         * @property {string|null} [value] ContractorContact value
         * @property {boolean|null} [isVerified] ContractorContact isVerified
         * @property {boolean|null} [isMain] ContractorContact isMain
         * @property {boolean|null} [isDeleted] ContractorContact isDeleted
         */

        /**
         * Constructs a new ContractorContact.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorContact.
         * @implements IContractorContact
         * @constructor
         * @param {PlatformAdminApi.IContractorContact=} [properties] Properties to set
         */
        function ContractorContact(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorContact id.
         * @member {string} id
         * @memberof PlatformAdminApi.ContractorContact
         * @instance
         */
        ContractorContact.prototype.id = "";

        /**
         * ContractorContact type.
         * @member {PlatformAdminApi.CONTACT_TYPE} type
         * @memberof PlatformAdminApi.ContractorContact
         * @instance
         */
        ContractorContact.prototype.type = 0;

        /**
         * ContractorContact value.
         * @member {string} value
         * @memberof PlatformAdminApi.ContractorContact
         * @instance
         */
        ContractorContact.prototype.value = "";

        /**
         * ContractorContact isVerified.
         * @member {boolean} isVerified
         * @memberof PlatformAdminApi.ContractorContact
         * @instance
         */
        ContractorContact.prototype.isVerified = false;

        /**
         * ContractorContact isMain.
         * @member {boolean} isMain
         * @memberof PlatformAdminApi.ContractorContact
         * @instance
         */
        ContractorContact.prototype.isMain = false;

        /**
         * ContractorContact isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.ContractorContact
         * @instance
         */
        ContractorContact.prototype.isDeleted = false;

        /**
         * Encodes the specified ContractorContact message. Does not implicitly {@link PlatformAdminApi.ContractorContact.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorContact
         * @static
         * @param {PlatformAdminApi.IContractorContact} message ContractorContact message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorContact.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVerified);
            if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isMain);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a ContractorContact message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorContact
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorContact} ContractorContact
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorContact.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorContact();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.isVerified = reader.bool();
                    break;
                case 5:
                    message.isMain = reader.bool();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorContact;
    })();

    PlatformAdminApi.ContractorsSearchRequest = (function() {

        /**
         * Properties of a ContractorsSearchRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorsSearchRequest
         * @property {number|null} [page] ContractorsSearchRequest page
         * @property {number|null} [limit] ContractorsSearchRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] ContractorsSearchRequest sort
         * @property {string|null} [searchString] ContractorsSearchRequest searchString
         */

        /**
         * Constructs a new ContractorsSearchRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorsSearchRequest.
         * @implements IContractorsSearchRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorsSearchRequest=} [properties] Properties to set
         */
        function ContractorsSearchRequest(properties) {
            this.sort = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorsSearchRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractorsSearchRequest
         * @instance
         */
        ContractorsSearchRequest.prototype.page = 0;

        /**
         * ContractorsSearchRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractorsSearchRequest
         * @instance
         */
        ContractorsSearchRequest.prototype.limit = 0;

        /**
         * ContractorsSearchRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.ContractorsSearchRequest
         * @instance
         */
        ContractorsSearchRequest.prototype.sort = $util.emptyArray;

        /**
         * ContractorsSearchRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.ContractorsSearchRequest
         * @instance
         */
        ContractorsSearchRequest.prototype.searchString = "";

        /**
         * Encodes the specified ContractorsSearchRequest message. Does not implicitly {@link PlatformAdminApi.ContractorsSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorsSearchRequest
         * @static
         * @param {PlatformAdminApi.IContractorsSearchRequest} message ContractorsSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorsSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a ContractorsSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorsSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorsSearchRequest} ContractorsSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorsSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorsSearchRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorsSearchRequest;
    })();

    PlatformAdminApi.ContractorsSearchResponse = (function() {

        /**
         * Properties of a ContractorsSearchResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorsSearchResponse
         * @property {Array.<PlatformAdminApi.IUser>|null} [data] ContractorsSearchResponse data
         * @property {number|null} [page] ContractorsSearchResponse page
         * @property {number|null} [pageCount] ContractorsSearchResponse pageCount
         * @property {number|null} [limit] ContractorsSearchResponse limit
         * @property {number|null} [count] ContractorsSearchResponse count
         * @property {number|null} [total] ContractorsSearchResponse total
         */

        /**
         * Constructs a new ContractorsSearchResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorsSearchResponse.
         * @implements IContractorsSearchResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorsSearchResponse=} [properties] Properties to set
         */
        function ContractorsSearchResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorsSearchResponse data.
         * @member {Array.<PlatformAdminApi.IUser>} data
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @instance
         */
        ContractorsSearchResponse.prototype.data = $util.emptyArray;

        /**
         * ContractorsSearchResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @instance
         */
        ContractorsSearchResponse.prototype.page = 0;

        /**
         * ContractorsSearchResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @instance
         */
        ContractorsSearchResponse.prototype.pageCount = 0;

        /**
         * ContractorsSearchResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @instance
         */
        ContractorsSearchResponse.prototype.limit = 0;

        /**
         * ContractorsSearchResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @instance
         */
        ContractorsSearchResponse.prototype.count = 0;

        /**
         * ContractorsSearchResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @instance
         */
        ContractorsSearchResponse.prototype.total = 0;

        /**
         * Encodes the specified ContractorsSearchResponse message. Does not implicitly {@link PlatformAdminApi.ContractorsSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @static
         * @param {PlatformAdminApi.IContractorsSearchResponse} message ContractorsSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorsSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.User.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ContractorsSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorsSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorsSearchResponse} ContractorsSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorsSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorsSearchResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.User.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorsSearchResponse;
    })();

    PlatformAdminApi.ContractorsListItem = (function() {

        /**
         * Properties of a ContractorsListItem.
         * @memberof PlatformAdminApi
         * @interface IContractorsListItem
         * @property {PlatformAdminApi.ContractorsListItem.IIdWithName|null} [idWithName] ContractorsListItem idWithName
         * @property {string|null} [userType] ContractorsListItem userType
         * @property {string|null} [tenant] ContractorsListItem tenant
         * @property {string|null} [lastName] ContractorsListItem lastName
         * @property {string|null} [firstName] ContractorsListItem firstName
         * @property {string|null} [middleName] ContractorsListItem middleName
         * @property {string|null} [phone] ContractorsListItem phone
         * @property {PlatformAdminApi.IUserBlockInfo|null} [block] ContractorsListItem block
         * @property {string|null} [createdAt] ContractorsListItem createdAt
         * @property {string|null} [isDeleted] ContractorsListItem isDeleted
         */

        /**
         * Constructs a new ContractorsListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorsListItem.
         * @implements IContractorsListItem
         * @constructor
         * @param {PlatformAdminApi.IContractorsListItem=} [properties] Properties to set
         */
        function ContractorsListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorsListItem idWithName.
         * @member {PlatformAdminApi.ContractorsListItem.IIdWithName|null|undefined} idWithName
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.idWithName = null;

        /**
         * ContractorsListItem userType.
         * @member {string} userType
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.userType = "";

        /**
         * ContractorsListItem tenant.
         * @member {string} tenant
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.tenant = "";

        /**
         * ContractorsListItem lastName.
         * @member {string} lastName
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.lastName = "";

        /**
         * ContractorsListItem firstName.
         * @member {string} firstName
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.firstName = "";

        /**
         * ContractorsListItem middleName.
         * @member {string} middleName
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.middleName = "";

        /**
         * ContractorsListItem phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.phone = "";

        /**
         * ContractorsListItem block.
         * @member {PlatformAdminApi.IUserBlockInfo|null|undefined} block
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.block = null;

        /**
         * ContractorsListItem createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.createdAt = "";

        /**
         * ContractorsListItem isDeleted.
         * @member {string} isDeleted
         * @memberof PlatformAdminApi.ContractorsListItem
         * @instance
         */
        ContractorsListItem.prototype.isDeleted = "";

        /**
         * Encodes the specified ContractorsListItem message. Does not implicitly {@link PlatformAdminApi.ContractorsListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorsListItem
         * @static
         * @param {PlatformAdminApi.IContractorsListItem} message ContractorsListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorsListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.idWithName != null && Object.hasOwnProperty.call(message, "idWithName"))
                $root.PlatformAdminApi.ContractorsListItem.IdWithName.encode(message.idWithName, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userType);
            if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tenant);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.firstName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.middleName);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.phone);
            if (message.block != null && Object.hasOwnProperty.call(message, "block"))
                $root.PlatformAdminApi.UserBlockInfo.encode(message.block, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a ContractorsListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorsListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorsListItem} ContractorsListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorsListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorsListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.idWithName = $root.PlatformAdminApi.ContractorsListItem.IdWithName.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.userType = reader.string();
                    break;
                case 3:
                    message.tenant = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.firstName = reader.string();
                    break;
                case 6:
                    message.middleName = reader.string();
                    break;
                case 7:
                    message.phone = reader.string();
                    break;
                case 8:
                    message.block = $root.PlatformAdminApi.UserBlockInfo.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.isDeleted = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ContractorsListItem.IdWithName = (function() {

            /**
             * Properties of an IdWithName.
             * @memberof PlatformAdminApi.ContractorsListItem
             * @interface IIdWithName
             * @property {string|null} [id] IdWithName id
             * @property {string|null} [name] IdWithName name
             */

            /**
             * Constructs a new IdWithName.
             * @memberof PlatformAdminApi.ContractorsListItem
             * @classdesc Represents an IdWithName.
             * @implements IIdWithName
             * @constructor
             * @param {PlatformAdminApi.ContractorsListItem.IIdWithName=} [properties] Properties to set
             */
            function IdWithName(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IdWithName id.
             * @member {string} id
             * @memberof PlatformAdminApi.ContractorsListItem.IdWithName
             * @instance
             */
            IdWithName.prototype.id = "";

            /**
             * IdWithName name.
             * @member {string} name
             * @memberof PlatformAdminApi.ContractorsListItem.IdWithName
             * @instance
             */
            IdWithName.prototype.name = "";

            /**
             * Encodes the specified IdWithName message. Does not implicitly {@link PlatformAdminApi.ContractorsListItem.IdWithName.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.ContractorsListItem.IdWithName
             * @static
             * @param {PlatformAdminApi.ContractorsListItem.IIdWithName} message IdWithName message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IdWithName.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes an IdWithName message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.ContractorsListItem.IdWithName
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.ContractorsListItem.IdWithName} IdWithName
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IdWithName.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorsListItem.IdWithName();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return IdWithName;
        })();

        return ContractorsListItem;
    })();

    PlatformAdminApi.ContractorsList = (function() {

        /**
         * Properties of a ContractorsList.
         * @memberof PlatformAdminApi
         * @interface IContractorsList
         * @property {Array.<PlatformAdminApi.IContractorsListItem>|null} [data] ContractorsList data
         * @property {number|null} [page] ContractorsList page
         * @property {number|null} [pageCount] ContractorsList pageCount
         * @property {number|null} [limit] ContractorsList limit
         * @property {number|null} [count] ContractorsList count
         * @property {number|null} [total] ContractorsList total
         */

        /**
         * Constructs a new ContractorsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorsList.
         * @implements IContractorsList
         * @constructor
         * @param {PlatformAdminApi.IContractorsList=} [properties] Properties to set
         */
        function ContractorsList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorsList data.
         * @member {Array.<PlatformAdminApi.IContractorsListItem>} data
         * @memberof PlatformAdminApi.ContractorsList
         * @instance
         */
        ContractorsList.prototype.data = $util.emptyArray;

        /**
         * ContractorsList page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractorsList
         * @instance
         */
        ContractorsList.prototype.page = 0;

        /**
         * ContractorsList pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.ContractorsList
         * @instance
         */
        ContractorsList.prototype.pageCount = 0;

        /**
         * ContractorsList limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractorsList
         * @instance
         */
        ContractorsList.prototype.limit = 0;

        /**
         * ContractorsList count.
         * @member {number} count
         * @memberof PlatformAdminApi.ContractorsList
         * @instance
         */
        ContractorsList.prototype.count = 0;

        /**
         * ContractorsList total.
         * @member {number} total
         * @memberof PlatformAdminApi.ContractorsList
         * @instance
         */
        ContractorsList.prototype.total = 0;

        /**
         * Encodes the specified ContractorsList message. Does not implicitly {@link PlatformAdminApi.ContractorsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorsList
         * @static
         * @param {PlatformAdminApi.IContractorsList} message ContractorsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.ContractorsListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ContractorsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorsList} ContractorsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.ContractorsListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorsList;
    })();

    PlatformAdminApi.ContractorSkillsRequest = (function() {

        /**
         * Properties of a ContractorSkillsRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorSkillsRequest
         * @property {string|null} [searchString] ContractorSkillsRequest searchString
         */

        /**
         * Constructs a new ContractorSkillsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorSkillsRequest.
         * @implements IContractorSkillsRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorSkillsRequest=} [properties] Properties to set
         */
        function ContractorSkillsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorSkillsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.ContractorSkillsRequest
         * @instance
         */
        ContractorSkillsRequest.prototype.searchString = "";

        /**
         * Encodes the specified ContractorSkillsRequest message. Does not implicitly {@link PlatformAdminApi.ContractorSkillsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorSkillsRequest
         * @static
         * @param {PlatformAdminApi.IContractorSkillsRequest} message ContractorSkillsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorSkillsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a ContractorSkillsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorSkillsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorSkillsRequest} ContractorSkillsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorSkillsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSkillsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorSkillsRequest;
    })();

    PlatformAdminApi.ContractorSkillsResponse = (function() {

        /**
         * Properties of a ContractorSkillsResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorSkillsResponse
         * @property {Array.<PlatformAdminApi.ISelectOption>|null} [data] ContractorSkillsResponse data
         */

        /**
         * Constructs a new ContractorSkillsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorSkillsResponse.
         * @implements IContractorSkillsResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorSkillsResponse=} [properties] Properties to set
         */
        function ContractorSkillsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorSkillsResponse data.
         * @member {Array.<PlatformAdminApi.ISelectOption>} data
         * @memberof PlatformAdminApi.ContractorSkillsResponse
         * @instance
         */
        ContractorSkillsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified ContractorSkillsResponse message. Does not implicitly {@link PlatformAdminApi.ContractorSkillsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorSkillsResponse
         * @static
         * @param {PlatformAdminApi.IContractorSkillsResponse} message ContractorSkillsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorSkillsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.SelectOption.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorSkillsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorSkillsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorSkillsResponse} ContractorSkillsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorSkillsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorSkillsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorSkillsResponse;
    })();

    PlatformAdminApi.ContractorWagesRequest = (function() {

        /**
         * Properties of a ContractorWagesRequest.
         * @memberof PlatformAdminApi
         * @interface IContractorWagesRequest
         * @property {string|null} [searchString] ContractorWagesRequest searchString
         */

        /**
         * Constructs a new ContractorWagesRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorWagesRequest.
         * @implements IContractorWagesRequest
         * @constructor
         * @param {PlatformAdminApi.IContractorWagesRequest=} [properties] Properties to set
         */
        function ContractorWagesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorWagesRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.ContractorWagesRequest
         * @instance
         */
        ContractorWagesRequest.prototype.searchString = "";

        /**
         * Encodes the specified ContractorWagesRequest message. Does not implicitly {@link PlatformAdminApi.ContractorWagesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorWagesRequest
         * @static
         * @param {PlatformAdminApi.IContractorWagesRequest} message ContractorWagesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorWagesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.searchString);
            return writer;
        };

        /**
         * Decodes a ContractorWagesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorWagesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorWagesRequest} ContractorWagesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorWagesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorWagesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorWagesRequest;
    })();

    PlatformAdminApi.ContractorWagesResponse = (function() {

        /**
         * Properties of a ContractorWagesResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorWagesResponse
         * @property {Array.<PlatformAdminApi.IContractorWageItem>|null} [data] ContractorWagesResponse data
         */

        /**
         * Constructs a new ContractorWagesResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorWagesResponse.
         * @implements IContractorWagesResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorWagesResponse=} [properties] Properties to set
         */
        function ContractorWagesResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorWagesResponse data.
         * @member {Array.<PlatformAdminApi.IContractorWageItem>} data
         * @memberof PlatformAdminApi.ContractorWagesResponse
         * @instance
         */
        ContractorWagesResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified ContractorWagesResponse message. Does not implicitly {@link PlatformAdminApi.ContractorWagesResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorWagesResponse
         * @static
         * @param {PlatformAdminApi.IContractorWagesResponse} message ContractorWagesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorWagesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.ContractorWageItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorWagesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorWagesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorWagesResponse} ContractorWagesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorWagesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorWagesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.ContractorWageItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorWagesResponse;
    })();

    PlatformAdminApi.ContractorWageItem = (function() {

        /**
         * Properties of a ContractorWageItem.
         * @memberof PlatformAdminApi
         * @interface IContractorWageItem
         * @property {string|null} [label] ContractorWageItem label
         * @property {string|null} [name] ContractorWageItem name
         * @property {Array.<PlatformAdminApi.ISelectOption>|null} [grades] ContractorWageItem grades
         */

        /**
         * Constructs a new ContractorWageItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorWageItem.
         * @implements IContractorWageItem
         * @constructor
         * @param {PlatformAdminApi.IContractorWageItem=} [properties] Properties to set
         */
        function ContractorWageItem(properties) {
            this.grades = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorWageItem label.
         * @member {string} label
         * @memberof PlatformAdminApi.ContractorWageItem
         * @instance
         */
        ContractorWageItem.prototype.label = "";

        /**
         * ContractorWageItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.ContractorWageItem
         * @instance
         */
        ContractorWageItem.prototype.name = "";

        /**
         * ContractorWageItem grades.
         * @member {Array.<PlatformAdminApi.ISelectOption>} grades
         * @memberof PlatformAdminApi.ContractorWageItem
         * @instance
         */
        ContractorWageItem.prototype.grades = $util.emptyArray;

        /**
         * Encodes the specified ContractorWageItem message. Does not implicitly {@link PlatformAdminApi.ContractorWageItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorWageItem
         * @static
         * @param {PlatformAdminApi.IContractorWageItem} message ContractorWageItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorWageItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.grades != null && message.grades.length)
                for (var i = 0; i < message.grades.length; ++i)
                    $root.PlatformAdminApi.SelectOption.encode(message.grades[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ContractorWageItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorWageItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorWageItem} ContractorWageItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorWageItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorWageItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.grades && message.grades.length))
                        message.grades = [];
                    message.grades.push($root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorWageItem;
    })();

    PlatformAdminApi.RetrieveContractorOrdersRequest = (function() {

        /**
         * Properties of a RetrieveContractorOrdersRequest.
         * @memberof PlatformAdminApi
         * @interface IRetrieveContractorOrdersRequest
         * @property {number|null} [page] RetrieveContractorOrdersRequest page
         * @property {number|null} [limit] RetrieveContractorOrdersRequest limit
         * @property {Array.<PlatformAdminApi.ISortField>|null} [sort] RetrieveContractorOrdersRequest sort
         * @property {Array.<PlatformAdminApi.RetrieveContractorOrdersRequest.IWhereFields>|null} [where] RetrieveContractorOrdersRequest where
         */

        /**
         * Constructs a new RetrieveContractorOrdersRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a RetrieveContractorOrdersRequest.
         * @implements IRetrieveContractorOrdersRequest
         * @constructor
         * @param {PlatformAdminApi.IRetrieveContractorOrdersRequest=} [properties] Properties to set
         */
        function RetrieveContractorOrdersRequest(properties) {
            this.sort = [];
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RetrieveContractorOrdersRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
         * @instance
         */
        RetrieveContractorOrdersRequest.prototype.page = 0;

        /**
         * RetrieveContractorOrdersRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
         * @instance
         */
        RetrieveContractorOrdersRequest.prototype.limit = 0;

        /**
         * RetrieveContractorOrdersRequest sort.
         * @member {Array.<PlatformAdminApi.ISortField>} sort
         * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
         * @instance
         */
        RetrieveContractorOrdersRequest.prototype.sort = $util.emptyArray;

        /**
         * RetrieveContractorOrdersRequest where.
         * @member {Array.<PlatformAdminApi.RetrieveContractorOrdersRequest.IWhereFields>} where
         * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
         * @instance
         */
        RetrieveContractorOrdersRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified RetrieveContractorOrdersRequest message. Does not implicitly {@link PlatformAdminApi.RetrieveContractorOrdersRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
         * @static
         * @param {PlatformAdminApi.IRetrieveContractorOrdersRequest} message RetrieveContractorOrdersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetrieveContractorOrdersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformAdminApi.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields.encode(message.where[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RetrieveContractorOrdersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.RetrieveContractorOrdersRequest} RetrieveContractorOrdersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetrieveContractorOrdersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RetrieveContractorOrdersRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformAdminApi.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RetrieveContractorOrdersRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
             * @interface IWhereFields
             * @property {string|null} [contractorId] WhereFields contractorId
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformAdminApi.RetrieveContractorOrdersRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields contractorId.
             * @member {string} contractorId
             * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.contractorId = "";

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields
             * @static
             * @param {PlatformAdminApi.RetrieveContractorOrdersRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.contractorId != null && Object.hasOwnProperty.call(message, "contractorId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.contractorId);
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.RetrieveContractorOrdersRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.contractorId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return RetrieveContractorOrdersRequest;
    })();

    PlatformAdminApi.ContractorOrdersListResponse = (function() {

        /**
         * Properties of a ContractorOrdersListResponse.
         * @memberof PlatformAdminApi
         * @interface IContractorOrdersListResponse
         * @property {Array.<PlatformAdminApi.ICustomerOrdersListItem>|null} [data] ContractorOrdersListResponse data
         * @property {number|null} [page] ContractorOrdersListResponse page
         * @property {number|null} [limit] ContractorOrdersListResponse limit
         * @property {number|null} [total] ContractorOrdersListResponse total
         */

        /**
         * Constructs a new ContractorOrdersListResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ContractorOrdersListResponse.
         * @implements IContractorOrdersListResponse
         * @constructor
         * @param {PlatformAdminApi.IContractorOrdersListResponse=} [properties] Properties to set
         */
        function ContractorOrdersListResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractorOrdersListResponse data.
         * @member {Array.<PlatformAdminApi.ICustomerOrdersListItem>} data
         * @memberof PlatformAdminApi.ContractorOrdersListResponse
         * @instance
         */
        ContractorOrdersListResponse.prototype.data = $util.emptyArray;

        /**
         * ContractorOrdersListResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.ContractorOrdersListResponse
         * @instance
         */
        ContractorOrdersListResponse.prototype.page = 0;

        /**
         * ContractorOrdersListResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.ContractorOrdersListResponse
         * @instance
         */
        ContractorOrdersListResponse.prototype.limit = 0;

        /**
         * ContractorOrdersListResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.ContractorOrdersListResponse
         * @instance
         */
        ContractorOrdersListResponse.prototype.total = 0;

        /**
         * Encodes the specified ContractorOrdersListResponse message. Does not implicitly {@link PlatformAdminApi.ContractorOrdersListResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ContractorOrdersListResponse
         * @static
         * @param {PlatformAdminApi.IContractorOrdersListResponse} message ContractorOrdersListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContractorOrdersListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.CustomerOrdersListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Decodes a ContractorOrdersListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ContractorOrdersListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ContractorOrdersListResponse} ContractorOrdersListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContractorOrdersListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ContractorOrdersListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.CustomerOrdersListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContractorOrdersListResponse;
    })();

    PlatformAdminApi.OrderService = (function() {

        /**
         * Constructs a new OrderService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function OrderService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (OrderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OrderService;

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#retrieveOrder}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RetrieveOrderCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls RetrieveOrder.
         * @function retrieveOrder
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OrderService.RetrieveOrderCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.retrieveOrder = function retrieveOrder(request, callback) {
            return this.rpcCall(retrieveOrder, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "RetrieveOrder" });

        /**
         * Calls RetrieveOrder.
         * @function retrieveOrder
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#retrieveOrderBySerialNumber}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RetrieveOrderBySerialNumberCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls RetrieveOrderBySerialNumber.
         * @function retrieveOrderBySerialNumber
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderBySerialNumberRequest} request OrderBySerialNumberRequest message or plain object
         * @param {PlatformAdminApi.OrderService.RetrieveOrderBySerialNumberCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.retrieveOrderBySerialNumber = function retrieveOrderBySerialNumber(request, callback) {
            return this.rpcCall(retrieveOrderBySerialNumber, $root.PlatformAdminApi.OrderBySerialNumberRequest, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "RetrieveOrderBySerialNumber" });

        /**
         * Calls RetrieveOrderBySerialNumber.
         * @function retrieveOrderBySerialNumber
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderBySerialNumberRequest} request OrderBySerialNumberRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#retrieveOptions}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RetrieveOptionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderElementOptionsResponse} [response] OrderElementOptionsResponse
         */

        /**
         * Calls RetrieveOptions.
         * @function retrieveOptions
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @param {PlatformAdminApi.OrderService.RetrieveOptionsCallback} callback Node-style callback called with the error, if any, and OrderElementOptionsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.retrieveOptions = function retrieveOptions(request, callback) {
            return this.rpcCall(retrieveOptions, $root.PlatformAdminApi.UuidRequest, $root.PlatformAdminApi.OrderElementOptionsResponse, request, callback);
        }, "name", { value: "RetrieveOptions" });

        /**
         * Calls RetrieveOptions.
         * @function retrieveOptions
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUuidRequest} request UuidRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderElementOptionsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#retrieveElements}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RetrieveElementsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderElementResponse} [response] OrderElementResponse
         */

        /**
         * Calls RetrieveElements.
         * @function retrieveElements
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IEmpty} request Empty message or plain object
         * @param {PlatformAdminApi.OrderService.RetrieveElementsCallback} callback Node-style callback called with the error, if any, and OrderElementResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.retrieveElements = function retrieveElements(request, callback) {
            return this.rpcCall(retrieveElements, $root.PlatformAdminApi.Empty, $root.PlatformAdminApi.OrderElementResponse, request, callback);
        }, "name", { value: "RetrieveElements" });

        /**
         * Calls RetrieveElements.
         * @function retrieveElements
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IEmpty} request Empty message or plain object
         * @returns {Promise<PlatformAdminApi.OrderElementResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#retrieveDiscounts}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RetrieveDiscountsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderDiscountsResponse} [response] OrderDiscountsResponse
         */

        /**
         * Calls RetrieveDiscounts.
         * @function retrieveDiscounts
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderDiscountsRequest} request OrderDiscountsRequest message or plain object
         * @param {PlatformAdminApi.OrderService.RetrieveDiscountsCallback} callback Node-style callback called with the error, if any, and OrderDiscountsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.retrieveDiscounts = function retrieveDiscounts(request, callback) {
            return this.rpcCall(retrieveDiscounts, $root.PlatformAdminApi.OrderDiscountsRequest, $root.PlatformAdminApi.OrderDiscountsResponse, request, callback);
        }, "name", { value: "RetrieveDiscounts" });

        /**
         * Calls RetrieveDiscounts.
         * @function retrieveDiscounts
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderDiscountsRequest} request OrderDiscountsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderDiscountsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#updateCustomerInformation}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef UpdateCustomerInformationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Empty} [response] Empty
         */

        /**
         * Calls UpdateCustomerInformation.
         * @function updateCustomerInformation
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpdateCustomerFIOBackend} request UpdateCustomerFIOBackend message or plain object
         * @param {PlatformAdminApi.OrderService.UpdateCustomerInformationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.updateCustomerInformation = function updateCustomerInformation(request, callback) {
            return this.rpcCall(updateCustomerInformation, $root.PlatformAdminApi.UpdateCustomerFIOBackend, $root.PlatformAdminApi.Empty, request, callback);
        }, "name", { value: "UpdateCustomerInformation" });

        /**
         * Calls UpdateCustomerInformation.
         * @function updateCustomerInformation
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpdateCustomerFIOBackend} request UpdateCustomerFIOBackend message or plain object
         * @returns {Promise<PlatformAdminApi.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#upsert}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef UpsertCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderBackend} request UpsertOrderBackend message or plain object
         * @param {PlatformAdminApi.OrderService.UpsertCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.upsert = function upsert(request, callback) {
            return this.rpcCall(upsert, $root.PlatformAdminApi.UpsertOrderBackend, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "Upsert" });

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderBackend} request UpsertOrderBackend message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#createOrder}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef CreateOrderCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CreateCustomerOrderResponse} [response] CreateCustomerOrderResponse
         */

        /**
         * Calls CreateOrder.
         * @function createOrder
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.ICreateCustomerOrderRequest} request CreateCustomerOrderRequest message or plain object
         * @param {PlatformAdminApi.OrderService.CreateOrderCallback} callback Node-style callback called with the error, if any, and CreateCustomerOrderResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.createOrder = function createOrder(request, callback) {
            return this.rpcCall(createOrder, $root.PlatformAdminApi.CreateCustomerOrderRequest, $root.PlatformAdminApi.CreateCustomerOrderResponse, request, callback);
        }, "name", { value: "CreateOrder" });

        /**
         * Calls CreateOrder.
         * @function createOrder
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.ICreateCustomerOrderRequest} request CreateCustomerOrderRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CreateCustomerOrderResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#getAvailableTabs}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef GetAvailableTabsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GetAvailableTabsResponse} [response] GetAvailableTabsResponse
         */

        /**
         * Calls GetAvailableTabs.
         * @function getAvailableTabs
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IEmpty} request Empty message or plain object
         * @param {PlatformAdminApi.OrderService.GetAvailableTabsCallback} callback Node-style callback called with the error, if any, and GetAvailableTabsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.getAvailableTabs = function getAvailableTabs(request, callback) {
            return this.rpcCall(getAvailableTabs, $root.PlatformAdminApi.Empty, $root.PlatformAdminApi.GetAvailableTabsResponse, request, callback);
        }, "name", { value: "GetAvailableTabs" });

        /**
         * Calls GetAvailableTabs.
         * @function getAvailableTabs
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IEmpty} request Empty message or plain object
         * @returns {Promise<PlatformAdminApi.GetAvailableTabsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#getAvailableTimeslots}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef GetAvailableTimeslotsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GetAvailableTimeslotsResponse} [response] GetAvailableTimeslotsResponse
         */

        /**
         * Calls GetAvailableTimeslots.
         * @function getAvailableTimeslots
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IGetAvailableTimeslotsRequest} request GetAvailableTimeslotsRequest message or plain object
         * @param {PlatformAdminApi.OrderService.GetAvailableTimeslotsCallback} callback Node-style callback called with the error, if any, and GetAvailableTimeslotsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.getAvailableTimeslots = function getAvailableTimeslots(request, callback) {
            return this.rpcCall(getAvailableTimeslots, $root.PlatformAdminApi.GetAvailableTimeslotsRequest, $root.PlatformAdminApi.GetAvailableTimeslotsResponse, request, callback);
        }, "name", { value: "GetAvailableTimeslots" });

        /**
         * Calls GetAvailableTimeslots.
         * @function getAvailableTimeslots
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IGetAvailableTimeslotsRequest} request GetAvailableTimeslotsRequest message or plain object
         * @returns {Promise<PlatformAdminApi.GetAvailableTimeslotsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#getOrderAddresses}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef GetOrderAddressesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderAddressList} [response] OrderAddressList
         */

        /**
         * Calls GetOrderAddresses.
         * @function getOrderAddresses
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IGetOrderAddressRequest} request GetOrderAddressRequest message or plain object
         * @param {PlatformAdminApi.OrderService.GetOrderAddressesCallback} callback Node-style callback called with the error, if any, and OrderAddressList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.getOrderAddresses = function getOrderAddresses(request, callback) {
            return this.rpcCall(getOrderAddresses, $root.PlatformAdminApi.GetOrderAddressRequest, $root.PlatformAdminApi.OrderAddressList, request, callback);
        }, "name", { value: "GetOrderAddresses" });

        /**
         * Calls GetOrderAddresses.
         * @function getOrderAddresses
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IGetOrderAddressRequest} request GetOrderAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderAddressList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#createOrderAddress}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef CreateOrderAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderAddressList} [response] OrderAddressList
         */

        /**
         * Calls CreateOrderAddress.
         * @function createOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderAddressRequest} request UpsertOrderAddressRequest message or plain object
         * @param {PlatformAdminApi.OrderService.CreateOrderAddressCallback} callback Node-style callback called with the error, if any, and OrderAddressList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.createOrderAddress = function createOrderAddress(request, callback) {
            return this.rpcCall(createOrderAddress, $root.PlatformAdminApi.UpsertOrderAddressRequest, $root.PlatformAdminApi.OrderAddressList, request, callback);
        }, "name", { value: "CreateOrderAddress" });

        /**
         * Calls CreateOrderAddress.
         * @function createOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderAddressRequest} request UpsertOrderAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderAddressList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#updateOrderAddress}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef UpdateOrderAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderAddressList} [response] OrderAddressList
         */

        /**
         * Calls UpdateOrderAddress.
         * @function updateOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderAddressRequest} request UpsertOrderAddressRequest message or plain object
         * @param {PlatformAdminApi.OrderService.UpdateOrderAddressCallback} callback Node-style callback called with the error, if any, and OrderAddressList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.updateOrderAddress = function updateOrderAddress(request, callback) {
            return this.rpcCall(updateOrderAddress, $root.PlatformAdminApi.UpsertOrderAddressRequest, $root.PlatformAdminApi.OrderAddressList, request, callback);
        }, "name", { value: "UpdateOrderAddress" });

        /**
         * Calls UpdateOrderAddress.
         * @function updateOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderAddressRequest} request UpsertOrderAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderAddressList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#deleteOrderAddress}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef DeleteOrderAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderAddressList} [response] OrderAddressList
         */

        /**
         * Calls DeleteOrderAddress.
         * @function deleteOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @param {PlatformAdminApi.OrderService.DeleteOrderAddressCallback} callback Node-style callback called with the error, if any, and OrderAddressList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.deleteOrderAddress = function deleteOrderAddress(request, callback) {
            return this.rpcCall(deleteOrderAddress, $root.PlatformAdminApi.OrderAddressRequest, $root.PlatformAdminApi.OrderAddressList, request, callback);
        }, "name", { value: "DeleteOrderAddress" });

        /**
         * Calls DeleteOrderAddress.
         * @function deleteOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderAddressList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#restoreOrderAddress}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RestoreOrderAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderAddressList} [response] OrderAddressList
         */

        /**
         * Calls RestoreOrderAddress.
         * @function restoreOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @param {PlatformAdminApi.OrderService.RestoreOrderAddressCallback} callback Node-style callback called with the error, if any, and OrderAddressList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.restoreOrderAddress = function restoreOrderAddress(request, callback) {
            return this.rpcCall(restoreOrderAddress, $root.PlatformAdminApi.OrderAddressRequest, $root.PlatformAdminApi.OrderAddressList, request, callback);
        }, "name", { value: "RestoreOrderAddress" });

        /**
         * Calls RestoreOrderAddress.
         * @function restoreOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderAddressList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#retrieveEditOrderAddress}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RetrieveEditOrderAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderAddressForm} [response] OrderAddressForm
         */

        /**
         * Calls RetrieveEditOrderAddress.
         * @function retrieveEditOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @param {PlatformAdminApi.OrderService.RetrieveEditOrderAddressCallback} callback Node-style callback called with the error, if any, and OrderAddressForm
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.retrieveEditOrderAddress = function retrieveEditOrderAddress(request, callback) {
            return this.rpcCall(retrieveEditOrderAddress, $root.PlatformAdminApi.OrderAddressRequest, $root.PlatformAdminApi.OrderAddressForm, request, callback);
        }, "name", { value: "RetrieveEditOrderAddress" });

        /**
         * Calls RetrieveEditOrderAddress.
         * @function retrieveEditOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderAddressForm>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#retrieveViewOrderAddress}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef RetrieveViewOrderAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderAddressView} [response] OrderAddressView
         */

        /**
         * Calls RetrieveViewOrderAddress.
         * @function retrieveViewOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @param {PlatformAdminApi.OrderService.RetrieveViewOrderAddressCallback} callback Node-style callback called with the error, if any, and OrderAddressView
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.retrieveViewOrderAddress = function retrieveViewOrderAddress(request, callback) {
            return this.rpcCall(retrieveViewOrderAddress, $root.PlatformAdminApi.OrderAddressRequest, $root.PlatformAdminApi.OrderAddressView, request, callback);
        }, "name", { value: "RetrieveViewOrderAddress" });

        /**
         * Calls RetrieveViewOrderAddress.
         * @function retrieveViewOrderAddress
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IOrderAddressRequest} request OrderAddressRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderAddressView>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#updateOrderOptions}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef UpdateOrderOptionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls UpdateOrderOptions.
         * @function updateOrderOptions
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderOptionsBackend} request UpsertOrderOptionsBackend message or plain object
         * @param {PlatformAdminApi.OrderService.UpdateOrderOptionsCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.updateOrderOptions = function updateOrderOptions(request, callback) {
            return this.rpcCall(updateOrderOptions, $root.PlatformAdminApi.UpsertOrderOptionsBackend, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "UpdateOrderOptions" });

        /**
         * Calls UpdateOrderOptions.
         * @function updateOrderOptions
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertOrderOptionsBackend} request UpsertOrderOptionsBackend message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#updateOrderStatus}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef UpdateOrderStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.UpdateOrderStatusResponse} [response] UpdateOrderStatusResponse
         */

        /**
         * Calls UpdateOrderStatus.
         * @function updateOrderStatus
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpdateOrderStatusRequest} request UpdateOrderStatusRequest message or plain object
         * @param {PlatformAdminApi.OrderService.UpdateOrderStatusCallback} callback Node-style callback called with the error, if any, and UpdateOrderStatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.updateOrderStatus = function updateOrderStatus(request, callback) {
            return this.rpcCall(updateOrderStatus, $root.PlatformAdminApi.UpdateOrderStatusRequest, $root.PlatformAdminApi.UpdateOrderStatusResponse, request, callback);
        }, "name", { value: "UpdateOrderStatus" });

        /**
         * Calls UpdateOrderStatus.
         * @function updateOrderStatus
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpdateOrderStatusRequest} request UpdateOrderStatusRequest message or plain object
         * @returns {Promise<PlatformAdminApi.UpdateOrderStatusResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#confirmCart}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef ConfirmCartCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls ConfirmCart.
         * @function confirmCart
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.ICoreBaseCartConfirmRequest} request CoreBaseCartConfirmRequest message or plain object
         * @param {PlatformAdminApi.OrderService.ConfirmCartCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.confirmCart = function confirmCart(request, callback) {
            return this.rpcCall(confirmCart, $root.PlatformAdminApi.CoreBaseCartConfirmRequest, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "ConfirmCart" });

        /**
         * Calls ConfirmCart.
         * @function confirmCart
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.ICoreBaseCartConfirmRequest} request CoreBaseCartConfirmRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#updateAdditionalServices}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef UpdateAdditionalServicesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls UpdateAdditionalServices.
         * @function updateAdditionalServices
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpdateAdditionalServicesRequest} request UpdateAdditionalServicesRequest message or plain object
         * @param {PlatformAdminApi.OrderService.UpdateAdditionalServicesCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.updateAdditionalServices = function updateAdditionalServices(request, callback) {
            return this.rpcCall(updateAdditionalServices, $root.PlatformAdminApi.UpdateAdditionalServicesRequest, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "UpdateAdditionalServices" });

        /**
         * Calls UpdateAdditionalServices.
         * @function updateAdditionalServices
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpdateAdditionalServicesRequest} request UpdateAdditionalServicesRequest message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#searchAvailableContractors}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef SearchAvailableContractorsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.OrderContractorsList} [response] OrderContractorsList
         */

        /**
         * Calls SearchAvailableContractors.
         * @function searchAvailableContractors
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IGetContractorsForOrderRequest} request GetContractorsForOrderRequest message or plain object
         * @param {PlatformAdminApi.OrderService.SearchAvailableContractorsCallback} callback Node-style callback called with the error, if any, and OrderContractorsList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.searchAvailableContractors = function searchAvailableContractors(request, callback) {
            return this.rpcCall(searchAvailableContractors, $root.PlatformAdminApi.GetContractorsForOrderRequest, $root.PlatformAdminApi.OrderContractorsList, request, callback);
        }, "name", { value: "SearchAvailableContractors" });

        /**
         * Calls SearchAvailableContractors.
         * @function searchAvailableContractors
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IGetContractorsForOrderRequest} request GetContractorsForOrderRequest message or plain object
         * @returns {Promise<PlatformAdminApi.OrderContractorsList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.OrderService#upsertAssignments}.
         * @memberof PlatformAdminApi.OrderService
         * @typedef UpsertAssignmentsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.CustomerViewOrder} [response] CustomerViewOrder
         */

        /**
         * Calls UpsertAssignments.
         * @function upsertAssignments
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertAssignmentBackend} request UpsertAssignmentBackend message or plain object
         * @param {PlatformAdminApi.OrderService.UpsertAssignmentsCallback} callback Node-style callback called with the error, if any, and CustomerViewOrder
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.upsertAssignments = function upsertAssignments(request, callback) {
            return this.rpcCall(upsertAssignments, $root.PlatformAdminApi.UpsertAssignmentBackend, $root.PlatformAdminApi.CustomerViewOrder, request, callback);
        }, "name", { value: "UpsertAssignments" });

        /**
         * Calls UpsertAssignments.
         * @function upsertAssignments
         * @memberof PlatformAdminApi.OrderService
         * @instance
         * @param {PlatformAdminApi.IUpsertAssignmentBackend} request UpsertAssignmentBackend message or plain object
         * @returns {Promise<PlatformAdminApi.CustomerViewOrder>} Promise
         * @variation 2
         */

        return OrderService;
    })();

    PlatformAdminApi.UpdateAdditionalServicesRequest = (function() {

        /**
         * Properties of an UpdateAdditionalServicesRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateAdditionalServicesRequest
         * @property {number|null} [orderSerialNumber] UpdateAdditionalServicesRequest orderSerialNumber
         * @property {string|null} [serviceId] UpdateAdditionalServicesRequest serviceId
         * @property {number|null} [amount] UpdateAdditionalServicesRequest amount
         * @property {boolean|null} [isFree] UpdateAdditionalServicesRequest isFree
         * @property {string|null} [addressId] UpdateAdditionalServicesRequest addressId
         */

        /**
         * Constructs a new UpdateAdditionalServicesRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpdateAdditionalServicesRequest.
         * @implements IUpdateAdditionalServicesRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateAdditionalServicesRequest=} [properties] Properties to set
         */
        function UpdateAdditionalServicesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateAdditionalServicesRequest orderSerialNumber.
         * @member {number} orderSerialNumber
         * @memberof PlatformAdminApi.UpdateAdditionalServicesRequest
         * @instance
         */
        UpdateAdditionalServicesRequest.prototype.orderSerialNumber = 0;

        /**
         * UpdateAdditionalServicesRequest serviceId.
         * @member {string} serviceId
         * @memberof PlatformAdminApi.UpdateAdditionalServicesRequest
         * @instance
         */
        UpdateAdditionalServicesRequest.prototype.serviceId = "";

        /**
         * UpdateAdditionalServicesRequest amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.UpdateAdditionalServicesRequest
         * @instance
         */
        UpdateAdditionalServicesRequest.prototype.amount = 0;

        /**
         * UpdateAdditionalServicesRequest isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.UpdateAdditionalServicesRequest
         * @instance
         */
        UpdateAdditionalServicesRequest.prototype.isFree = false;

        /**
         * UpdateAdditionalServicesRequest addressId.
         * @member {string} addressId
         * @memberof PlatformAdminApi.UpdateAdditionalServicesRequest
         * @instance
         */
        UpdateAdditionalServicesRequest.prototype.addressId = "";

        /**
         * Encodes the specified UpdateAdditionalServicesRequest message. Does not implicitly {@link PlatformAdminApi.UpdateAdditionalServicesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateAdditionalServicesRequest
         * @static
         * @param {PlatformAdminApi.IUpdateAdditionalServicesRequest} message UpdateAdditionalServicesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateAdditionalServicesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.orderSerialNumber);
            if (message.serviceId != null && Object.hasOwnProperty.call(message, "serviceId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.serviceId);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isFree);
            if (message.addressId != null && Object.hasOwnProperty.call(message, "addressId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.addressId);
            return writer;
        };

        /**
         * Decodes an UpdateAdditionalServicesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateAdditionalServicesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateAdditionalServicesRequest} UpdateAdditionalServicesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateAdditionalServicesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateAdditionalServicesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderSerialNumber = reader.int32();
                    break;
                case 2:
                    message.serviceId = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.isFree = reader.bool();
                    break;
                case 5:
                    message.addressId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateAdditionalServicesRequest;
    })();

    PlatformAdminApi.GetAvailableTabsResponse = (function() {

        /**
         * Properties of a GetAvailableTabsResponse.
         * @memberof PlatformAdminApi
         * @interface IGetAvailableTabsResponse
         * @property {Array.<PlatformAdminApi.ICustomerViewAvailableOrderTab>|null} [tabs] GetAvailableTabsResponse tabs
         */

        /**
         * Constructs a new GetAvailableTabsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetAvailableTabsResponse.
         * @implements IGetAvailableTabsResponse
         * @constructor
         * @param {PlatformAdminApi.IGetAvailableTabsResponse=} [properties] Properties to set
         */
        function GetAvailableTabsResponse(properties) {
            this.tabs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableTabsResponse tabs.
         * @member {Array.<PlatformAdminApi.ICustomerViewAvailableOrderTab>} tabs
         * @memberof PlatformAdminApi.GetAvailableTabsResponse
         * @instance
         */
        GetAvailableTabsResponse.prototype.tabs = $util.emptyArray;

        /**
         * Encodes the specified GetAvailableTabsResponse message. Does not implicitly {@link PlatformAdminApi.GetAvailableTabsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetAvailableTabsResponse
         * @static
         * @param {PlatformAdminApi.IGetAvailableTabsResponse} message GetAvailableTabsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableTabsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tabs != null && message.tabs.length)
                for (var i = 0; i < message.tabs.length; ++i)
                    $root.PlatformAdminApi.CustomerViewAvailableOrderTab.encode(message.tabs[i], writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetAvailableTabsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetAvailableTabsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetAvailableTabsResponse} GetAvailableTabsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableTabsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetAvailableTabsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 26:
                    if (!(message.tabs && message.tabs.length))
                        message.tabs = [];
                    message.tabs.push($root.PlatformAdminApi.CustomerViewAvailableOrderTab.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableTabsResponse;
    })();

    PlatformAdminApi.CustomerViewAvailableOrderTab = (function() {

        /**
         * Properties of a CustomerViewAvailableOrderTab.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewAvailableOrderTab
         * @property {string|null} [name] CustomerViewAvailableOrderTab name
         * @property {string|null} [value] CustomerViewAvailableOrderTab value
         * @property {string|null} [slug] CustomerViewAvailableOrderTab slug
         */

        /**
         * Constructs a new CustomerViewAvailableOrderTab.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewAvailableOrderTab.
         * @implements ICustomerViewAvailableOrderTab
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewAvailableOrderTab=} [properties] Properties to set
         */
        function CustomerViewAvailableOrderTab(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewAvailableOrderTab name.
         * @member {string} name
         * @memberof PlatformAdminApi.CustomerViewAvailableOrderTab
         * @instance
         */
        CustomerViewAvailableOrderTab.prototype.name = "";

        /**
         * CustomerViewAvailableOrderTab value.
         * @member {string} value
         * @memberof PlatformAdminApi.CustomerViewAvailableOrderTab
         * @instance
         */
        CustomerViewAvailableOrderTab.prototype.value = "";

        /**
         * CustomerViewAvailableOrderTab slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.CustomerViewAvailableOrderTab
         * @instance
         */
        CustomerViewAvailableOrderTab.prototype.slug = "";

        /**
         * Encodes the specified CustomerViewAvailableOrderTab message. Does not implicitly {@link PlatformAdminApi.CustomerViewAvailableOrderTab.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewAvailableOrderTab
         * @static
         * @param {PlatformAdminApi.ICustomerViewAvailableOrderTab} message CustomerViewAvailableOrderTab message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewAvailableOrderTab.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.slug);
            return writer;
        };

        /**
         * Decodes a CustomerViewAvailableOrderTab message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewAvailableOrderTab
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewAvailableOrderTab} CustomerViewAvailableOrderTab
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewAvailableOrderTab.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewAvailableOrderTab();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.slug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewAvailableOrderTab;
    })();

    PlatformAdminApi.OrderAddressRequest = (function() {

        /**
         * Properties of an OrderAddressRequest.
         * @memberof PlatformAdminApi
         * @interface IOrderAddressRequest
         * @property {string|null} [userId] OrderAddressRequest userId
         * @property {number|null} [orderSerialNumber] OrderAddressRequest orderSerialNumber
         * @property {string|null} [addressId] OrderAddressRequest addressId
         */

        /**
         * Constructs a new OrderAddressRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderAddressRequest.
         * @implements IOrderAddressRequest
         * @constructor
         * @param {PlatformAdminApi.IOrderAddressRequest=} [properties] Properties to set
         */
        function OrderAddressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderAddressRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.OrderAddressRequest
         * @instance
         */
        OrderAddressRequest.prototype.userId = "";

        /**
         * OrderAddressRequest orderSerialNumber.
         * @member {number} orderSerialNumber
         * @memberof PlatformAdminApi.OrderAddressRequest
         * @instance
         */
        OrderAddressRequest.prototype.orderSerialNumber = 0;

        /**
         * OrderAddressRequest addressId.
         * @member {string} addressId
         * @memberof PlatformAdminApi.OrderAddressRequest
         * @instance
         */
        OrderAddressRequest.prototype.addressId = "";

        /**
         * Encodes the specified OrderAddressRequest message. Does not implicitly {@link PlatformAdminApi.OrderAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderAddressRequest
         * @static
         * @param {PlatformAdminApi.IOrderAddressRequest} message OrderAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderSerialNumber);
            if (message.addressId != null && Object.hasOwnProperty.call(message, "addressId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.addressId);
            return writer;
        };

        /**
         * Decodes an OrderAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderAddressRequest} OrderAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderAddressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.orderSerialNumber = reader.int32();
                    break;
                case 3:
                    message.addressId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderAddressRequest;
    })();

    PlatformAdminApi.GetOrderAddressRequest = (function() {

        /**
         * Properties of a GetOrderAddressRequest.
         * @memberof PlatformAdminApi
         * @interface IGetOrderAddressRequest
         * @property {string|null} [userId] GetOrderAddressRequest userId
         * @property {number|null} [orderSerialNumber] GetOrderAddressRequest orderSerialNumber
         */

        /**
         * Constructs a new GetOrderAddressRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetOrderAddressRequest.
         * @implements IGetOrderAddressRequest
         * @constructor
         * @param {PlatformAdminApi.IGetOrderAddressRequest=} [properties] Properties to set
         */
        function GetOrderAddressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrderAddressRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.GetOrderAddressRequest
         * @instance
         */
        GetOrderAddressRequest.prototype.userId = "";

        /**
         * GetOrderAddressRequest orderSerialNumber.
         * @member {number} orderSerialNumber
         * @memberof PlatformAdminApi.GetOrderAddressRequest
         * @instance
         */
        GetOrderAddressRequest.prototype.orderSerialNumber = 0;

        /**
         * Encodes the specified GetOrderAddressRequest message. Does not implicitly {@link PlatformAdminApi.GetOrderAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetOrderAddressRequest
         * @static
         * @param {PlatformAdminApi.IGetOrderAddressRequest} message GetOrderAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrderAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderSerialNumber);
            return writer;
        };

        /**
         * Decodes a GetOrderAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetOrderAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetOrderAddressRequest} GetOrderAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrderAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetOrderAddressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.orderSerialNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOrderAddressRequest;
    })();

    PlatformAdminApi.UpsertOrderAddressRequest = (function() {

        /**
         * Properties of an UpsertOrderAddressRequest.
         * @memberof PlatformAdminApi
         * @interface IUpsertOrderAddressRequest
         * @property {string|null} [userId] UpsertOrderAddressRequest userId
         * @property {number|null} [orderSerialNumber] UpsertOrderAddressRequest orderSerialNumber
         * @property {PlatformAdminApi.IOrderAddressForm|null} [address] UpsertOrderAddressRequest address
         */

        /**
         * Constructs a new UpsertOrderAddressRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpsertOrderAddressRequest.
         * @implements IUpsertOrderAddressRequest
         * @constructor
         * @param {PlatformAdminApi.IUpsertOrderAddressRequest=} [properties] Properties to set
         */
        function UpsertOrderAddressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertOrderAddressRequest userId.
         * @member {string} userId
         * @memberof PlatformAdminApi.UpsertOrderAddressRequest
         * @instance
         */
        UpsertOrderAddressRequest.prototype.userId = "";

        /**
         * UpsertOrderAddressRequest orderSerialNumber.
         * @member {number} orderSerialNumber
         * @memberof PlatformAdminApi.UpsertOrderAddressRequest
         * @instance
         */
        UpsertOrderAddressRequest.prototype.orderSerialNumber = 0;

        /**
         * UpsertOrderAddressRequest address.
         * @member {PlatformAdminApi.IOrderAddressForm|null|undefined} address
         * @memberof PlatformAdminApi.UpsertOrderAddressRequest
         * @instance
         */
        UpsertOrderAddressRequest.prototype.address = null;

        /**
         * Encodes the specified UpsertOrderAddressRequest message. Does not implicitly {@link PlatformAdminApi.UpsertOrderAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpsertOrderAddressRequest
         * @static
         * @param {PlatformAdminApi.IUpsertOrderAddressRequest} message UpsertOrderAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertOrderAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderSerialNumber);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.OrderAddressForm.encode(message.address, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpsertOrderAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpsertOrderAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpsertOrderAddressRequest} UpsertOrderAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertOrderAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpsertOrderAddressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.orderSerialNumber = reader.int32();
                    break;
                case 3:
                    message.address = $root.PlatformAdminApi.OrderAddressForm.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertOrderAddressRequest;
    })();

    PlatformAdminApi.OrderAddressForm = (function() {

        /**
         * Properties of an OrderAddressForm.
         * @memberof PlatformAdminApi
         * @interface IOrderAddressForm
         * @property {PlatformAdminApi.ADDRESS_TYPE|null} [type] OrderAddressForm type
         * @property {PlatformAdminApi.IAddress|null} [value] OrderAddressForm value
         */

        /**
         * Constructs a new OrderAddressForm.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderAddressForm.
         * @implements IOrderAddressForm
         * @constructor
         * @param {PlatformAdminApi.IOrderAddressForm=} [properties] Properties to set
         */
        function OrderAddressForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderAddressForm type.
         * @member {PlatformAdminApi.ADDRESS_TYPE} type
         * @memberof PlatformAdminApi.OrderAddressForm
         * @instance
         */
        OrderAddressForm.prototype.type = 0;

        /**
         * OrderAddressForm value.
         * @member {PlatformAdminApi.IAddress|null|undefined} value
         * @memberof PlatformAdminApi.OrderAddressForm
         * @instance
         */
        OrderAddressForm.prototype.value = null;

        /**
         * Encodes the specified OrderAddressForm message. Does not implicitly {@link PlatformAdminApi.OrderAddressForm.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderAddressForm
         * @static
         * @param {PlatformAdminApi.IOrderAddressForm} message OrderAddressForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAddressForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.PlatformAdminApi.Address.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderAddressForm message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderAddressForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderAddressForm} OrderAddressForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAddressForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderAddressForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.value = $root.PlatformAdminApi.Address.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderAddressForm;
    })();

    PlatformAdminApi.OrderAddressView = (function() {

        /**
         * Properties of an OrderAddressView.
         * @memberof PlatformAdminApi
         * @interface IOrderAddressView
         * @property {PlatformAdminApi.IAddressLink|null} [addressLink] OrderAddressView addressLink
         * @property {string|null} [intercom] OrderAddressView intercom
         * @property {Array.<PlatformAdminApi.IMetroStation>|null} [metro] OrderAddressView metro
         * @property {boolean|null} [isDeleted] OrderAddressView isDeleted
         * @property {string|null} [isDeletedStatus] OrderAddressView isDeletedStatus
         * @property {string|null} [comment] OrderAddressView comment
         */

        /**
         * Constructs a new OrderAddressView.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderAddressView.
         * @implements IOrderAddressView
         * @constructor
         * @param {PlatformAdminApi.IOrderAddressView=} [properties] Properties to set
         */
        function OrderAddressView(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderAddressView addressLink.
         * @member {PlatformAdminApi.IAddressLink|null|undefined} addressLink
         * @memberof PlatformAdminApi.OrderAddressView
         * @instance
         */
        OrderAddressView.prototype.addressLink = null;

        /**
         * OrderAddressView intercom.
         * @member {string} intercom
         * @memberof PlatformAdminApi.OrderAddressView
         * @instance
         */
        OrderAddressView.prototype.intercom = "";

        /**
         * OrderAddressView metro.
         * @member {Array.<PlatformAdminApi.IMetroStation>} metro
         * @memberof PlatformAdminApi.OrderAddressView
         * @instance
         */
        OrderAddressView.prototype.metro = $util.emptyArray;

        /**
         * OrderAddressView isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.OrderAddressView
         * @instance
         */
        OrderAddressView.prototype.isDeleted = false;

        /**
         * OrderAddressView isDeletedStatus.
         * @member {string} isDeletedStatus
         * @memberof PlatformAdminApi.OrderAddressView
         * @instance
         */
        OrderAddressView.prototype.isDeletedStatus = "";

        /**
         * OrderAddressView comment.
         * @member {string|null|undefined} comment
         * @memberof PlatformAdminApi.OrderAddressView
         * @instance
         */
        OrderAddressView.prototype.comment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * OrderAddressView _comment.
         * @member {"comment"|undefined} _comment
         * @memberof PlatformAdminApi.OrderAddressView
         * @instance
         */
        Object.defineProperty(OrderAddressView.prototype, "_comment", {
            get: $util.oneOfGetter($oneOfFields = ["comment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified OrderAddressView message. Does not implicitly {@link PlatformAdminApi.OrderAddressView.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderAddressView
         * @static
         * @param {PlatformAdminApi.IOrderAddressView} message OrderAddressView message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAddressView.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addressLink != null && Object.hasOwnProperty.call(message, "addressLink"))
                $root.PlatformAdminApi.AddressLink.encode(message.addressLink, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.intercom);
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.PlatformAdminApi.MetroStation.encode(message.metro[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.isDeletedStatus != null && Object.hasOwnProperty.call(message, "isDeletedStatus"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.isDeletedStatus);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.comment);
            return writer;
        };

        /**
         * Decodes an OrderAddressView message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderAddressView
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderAddressView} OrderAddressView
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAddressView.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderAddressView();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.addressLink = $root.PlatformAdminApi.AddressLink.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.intercom = reader.string();
                    break;
                case 3:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.PlatformAdminApi.MetroStation.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.isDeletedStatus = reader.string();
                    break;
                case 6:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderAddressView;
    })();

    PlatformAdminApi.OrderAddressList = (function() {

        /**
         * Properties of an OrderAddressList.
         * @memberof PlatformAdminApi
         * @interface IOrderAddressList
         * @property {Array.<PlatformAdminApi.IOrderAddressListItem>|null} [data] OrderAddressList data
         */

        /**
         * Constructs a new OrderAddressList.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderAddressList.
         * @implements IOrderAddressList
         * @constructor
         * @param {PlatformAdminApi.IOrderAddressList=} [properties] Properties to set
         */
        function OrderAddressList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderAddressList data.
         * @member {Array.<PlatformAdminApi.IOrderAddressListItem>} data
         * @memberof PlatformAdminApi.OrderAddressList
         * @instance
         */
        OrderAddressList.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified OrderAddressList message. Does not implicitly {@link PlatformAdminApi.OrderAddressList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderAddressList
         * @static
         * @param {PlatformAdminApi.IOrderAddressList} message OrderAddressList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAddressList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.OrderAddressListItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderAddressList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderAddressList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderAddressList} OrderAddressList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAddressList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderAddressList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.OrderAddressListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderAddressList;
    })();

    PlatformAdminApi.OrderAddressListItem = (function() {

        /**
         * Properties of an OrderAddressListItem.
         * @memberof PlatformAdminApi
         * @interface IOrderAddressListItem
         * @property {string|null} [id] OrderAddressListItem id
         * @property {string|null} [city] OrderAddressListItem city
         * @property {string|null} [value] OrderAddressListItem value
         * @property {string|null} [label] OrderAddressListItem label
         * @property {boolean|null} [isDeleted] OrderAddressListItem isDeleted
         * @property {string|null} [isDeletedString] OrderAddressListItem isDeletedString
         * @property {string|null} [addressTypeLabel] OrderAddressListItem addressTypeLabel
         * @property {string|null} [link] OrderAddressListItem link
         * @property {boolean|null} [serviceAvailable] OrderAddressListItem serviceAvailable
         * @property {boolean|null} [needsToComplete] OrderAddressListItem needsToComplete
         * @property {boolean|null} [disableSelection] OrderAddressListItem disableSelection
         * @property {boolean|null} [disableDeletion] OrderAddressListItem disableDeletion
         */

        /**
         * Constructs a new OrderAddressListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderAddressListItem.
         * @implements IOrderAddressListItem
         * @constructor
         * @param {PlatformAdminApi.IOrderAddressListItem=} [properties] Properties to set
         */
        function OrderAddressListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderAddressListItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.id = "";

        /**
         * OrderAddressListItem city.
         * @member {string} city
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.city = "";

        /**
         * OrderAddressListItem value.
         * @member {string} value
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.value = "";

        /**
         * OrderAddressListItem label.
         * @member {string} label
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.label = "";

        /**
         * OrderAddressListItem isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.isDeleted = false;

        /**
         * OrderAddressListItem isDeletedString.
         * @member {string} isDeletedString
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.isDeletedString = "";

        /**
         * OrderAddressListItem addressTypeLabel.
         * @member {string} addressTypeLabel
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.addressTypeLabel = "";

        /**
         * OrderAddressListItem link.
         * @member {string} link
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.link = "";

        /**
         * OrderAddressListItem serviceAvailable.
         * @member {boolean} serviceAvailable
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.serviceAvailable = false;

        /**
         * OrderAddressListItem needsToComplete.
         * @member {boolean} needsToComplete
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.needsToComplete = false;

        /**
         * OrderAddressListItem disableSelection.
         * @member {boolean} disableSelection
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.disableSelection = false;

        /**
         * OrderAddressListItem disableDeletion.
         * @member {boolean} disableDeletion
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @instance
         */
        OrderAddressListItem.prototype.disableDeletion = false;

        /**
         * Encodes the specified OrderAddressListItem message. Does not implicitly {@link PlatformAdminApi.OrderAddressListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @static
         * @param {PlatformAdminApi.IOrderAddressListItem} message OrderAddressListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAddressListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.label);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
            if (message.isDeletedString != null && Object.hasOwnProperty.call(message, "isDeletedString"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.isDeletedString);
            if (message.addressTypeLabel != null && Object.hasOwnProperty.call(message, "addressTypeLabel"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.addressTypeLabel);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.link);
            if (message.serviceAvailable != null && Object.hasOwnProperty.call(message, "serviceAvailable"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.serviceAvailable);
            if (message.needsToComplete != null && Object.hasOwnProperty.call(message, "needsToComplete"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.needsToComplete);
            if (message.disableSelection != null && Object.hasOwnProperty.call(message, "disableSelection"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.disableSelection);
            if (message.disableDeletion != null && Object.hasOwnProperty.call(message, "disableDeletion"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.disableDeletion);
            return writer;
        };

        /**
         * Decodes an OrderAddressListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderAddressListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderAddressListItem} OrderAddressListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAddressListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderAddressListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.city = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.label = reader.string();
                    break;
                case 5:
                    message.isDeleted = reader.bool();
                    break;
                case 6:
                    message.isDeletedString = reader.string();
                    break;
                case 7:
                    message.addressTypeLabel = reader.string();
                    break;
                case 8:
                    message.link = reader.string();
                    break;
                case 9:
                    message.serviceAvailable = reader.bool();
                    break;
                case 10:
                    message.needsToComplete = reader.bool();
                    break;
                case 11:
                    message.disableSelection = reader.bool();
                    break;
                case 12:
                    message.disableDeletion = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderAddressListItem;
    })();

    PlatformAdminApi.OrderContractorsList = (function() {

        /**
         * Properties of an OrderContractorsList.
         * @memberof PlatformAdminApi
         * @interface IOrderContractorsList
         * @property {Array.<PlatformAdminApi.IOrderContractorItem>|null} [data] OrderContractorsList data
         */

        /**
         * Constructs a new OrderContractorsList.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderContractorsList.
         * @implements IOrderContractorsList
         * @constructor
         * @param {PlatformAdminApi.IOrderContractorsList=} [properties] Properties to set
         */
        function OrderContractorsList(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderContractorsList data.
         * @member {Array.<PlatformAdminApi.IOrderContractorItem>} data
         * @memberof PlatformAdminApi.OrderContractorsList
         * @instance
         */
        OrderContractorsList.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified OrderContractorsList message. Does not implicitly {@link PlatformAdminApi.OrderContractorsList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderContractorsList
         * @static
         * @param {PlatformAdminApi.IOrderContractorsList} message OrderContractorsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderContractorsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.OrderContractorItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderContractorsList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderContractorsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderContractorsList} OrderContractorsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderContractorsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderContractorsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.OrderContractorItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderContractorsList;
    })();

    PlatformAdminApi.OrderContractorItem = (function() {

        /**
         * Properties of an OrderContractorItem.
         * @memberof PlatformAdminApi
         * @interface IOrderContractorItem
         * @property {string|null} [id] OrderContractorItem id
         * @property {string|null} [name] OrderContractorItem name
         * @property {string|null} [phone] OrderContractorItem phone
         * @property {number|null} [rating] OrderContractorItem rating
         * @property {string|null} [grade] OrderContractorItem grade
         * @property {number|null} [distance] OrderContractorItem distance
         * @property {boolean|null} [isFavorite] OrderContractorItem isFavorite
         * @property {string|null} [hrmId] OrderContractorItem hrmId
         */

        /**
         * Constructs a new OrderContractorItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderContractorItem.
         * @implements IOrderContractorItem
         * @constructor
         * @param {PlatformAdminApi.IOrderContractorItem=} [properties] Properties to set
         */
        function OrderContractorItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderContractorItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.id = "";

        /**
         * OrderContractorItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.name = "";

        /**
         * OrderContractorItem phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.phone = "";

        /**
         * OrderContractorItem rating.
         * @member {number} rating
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.rating = 0;

        /**
         * OrderContractorItem grade.
         * @member {string} grade
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.grade = "";

        /**
         * OrderContractorItem distance.
         * @member {number} distance
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.distance = 0;

        /**
         * OrderContractorItem isFavorite.
         * @member {boolean} isFavorite
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.isFavorite = false;

        /**
         * OrderContractorItem hrmId.
         * @member {string} hrmId
         * @memberof PlatformAdminApi.OrderContractorItem
         * @instance
         */
        OrderContractorItem.prototype.hrmId = "";

        /**
         * Encodes the specified OrderContractorItem message. Does not implicitly {@link PlatformAdminApi.OrderContractorItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderContractorItem
         * @static
         * @param {PlatformAdminApi.IOrderContractorItem} message OrderContractorItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderContractorItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.rating);
            if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.grade);
            if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.distance);
            if (message.isFavorite != null && Object.hasOwnProperty.call(message, "isFavorite"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isFavorite);
            if (message.hrmId != null && Object.hasOwnProperty.call(message, "hrmId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.hrmId);
            return writer;
        };

        /**
         * Decodes an OrderContractorItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderContractorItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderContractorItem} OrderContractorItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderContractorItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderContractorItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.phone = reader.string();
                    break;
                case 4:
                    message.rating = reader.int32();
                    break;
                case 5:
                    message.grade = reader.string();
                    break;
                case 6:
                    message.distance = reader.float();
                    break;
                case 7:
                    message.isFavorite = reader.bool();
                    break;
                case 8:
                    message.hrmId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderContractorItem;
    })();

    PlatformAdminApi.GetContractorsForOrderRequest = (function() {

        /**
         * Properties of a GetContractorsForOrderRequest.
         * @memberof PlatformAdminApi
         * @interface IGetContractorsForOrderRequest
         * @property {number|null} [orderId] GetContractorsForOrderRequest orderId
         * @property {string|null} [date] GetContractorsForOrderRequest date
         * @property {string|null} [fio] GetContractorsForOrderRequest fio
         * @property {string|null} [positionId] GetContractorsForOrderRequest positionId
         */

        /**
         * Constructs a new GetContractorsForOrderRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetContractorsForOrderRequest.
         * @implements IGetContractorsForOrderRequest
         * @constructor
         * @param {PlatformAdminApi.IGetContractorsForOrderRequest=} [properties] Properties to set
         */
        function GetContractorsForOrderRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetContractorsForOrderRequest orderId.
         * @member {number} orderId
         * @memberof PlatformAdminApi.GetContractorsForOrderRequest
         * @instance
         */
        GetContractorsForOrderRequest.prototype.orderId = 0;

        /**
         * GetContractorsForOrderRequest date.
         * @member {string} date
         * @memberof PlatformAdminApi.GetContractorsForOrderRequest
         * @instance
         */
        GetContractorsForOrderRequest.prototype.date = "";

        /**
         * GetContractorsForOrderRequest fio.
         * @member {string} fio
         * @memberof PlatformAdminApi.GetContractorsForOrderRequest
         * @instance
         */
        GetContractorsForOrderRequest.prototype.fio = "";

        /**
         * GetContractorsForOrderRequest positionId.
         * @member {string} positionId
         * @memberof PlatformAdminApi.GetContractorsForOrderRequest
         * @instance
         */
        GetContractorsForOrderRequest.prototype.positionId = "";

        /**
         * Encodes the specified GetContractorsForOrderRequest message. Does not implicitly {@link PlatformAdminApi.GetContractorsForOrderRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetContractorsForOrderRequest
         * @static
         * @param {PlatformAdminApi.IGetContractorsForOrderRequest} message GetContractorsForOrderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetContractorsForOrderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.orderId);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fio);
            if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.positionId);
            return writer;
        };

        /**
         * Decodes a GetContractorsForOrderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetContractorsForOrderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetContractorsForOrderRequest} GetContractorsForOrderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetContractorsForOrderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetContractorsForOrderRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.int32();
                    break;
                case 2:
                    message.date = reader.string();
                    break;
                case 3:
                    message.fio = reader.string();
                    break;
                case 4:
                    message.positionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetContractorsForOrderRequest;
    })();

    PlatformAdminApi.GetContractorsForOrderResponse = (function() {

        /**
         * Properties of a GetContractorsForOrderResponse.
         * @memberof PlatformAdminApi
         * @interface IGetContractorsForOrderResponse
         * @property {Array.<PlatformAdminApi.GetContractorsForOrderResponse.IRelevantContractor>|null} [contractors] GetContractorsForOrderResponse contractors
         */

        /**
         * Constructs a new GetContractorsForOrderResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetContractorsForOrderResponse.
         * @implements IGetContractorsForOrderResponse
         * @constructor
         * @param {PlatformAdminApi.IGetContractorsForOrderResponse=} [properties] Properties to set
         */
        function GetContractorsForOrderResponse(properties) {
            this.contractors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetContractorsForOrderResponse contractors.
         * @member {Array.<PlatformAdminApi.GetContractorsForOrderResponse.IRelevantContractor>} contractors
         * @memberof PlatformAdminApi.GetContractorsForOrderResponse
         * @instance
         */
        GetContractorsForOrderResponse.prototype.contractors = $util.emptyArray;

        /**
         * Encodes the specified GetContractorsForOrderResponse message. Does not implicitly {@link PlatformAdminApi.GetContractorsForOrderResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetContractorsForOrderResponse
         * @static
         * @param {PlatformAdminApi.IGetContractorsForOrderResponse} message GetContractorsForOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetContractorsForOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contractors != null && message.contractors.length)
                for (var i = 0; i < message.contractors.length; ++i)
                    $root.PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor.encode(message.contractors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetContractorsForOrderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetContractorsForOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetContractorsForOrderResponse} GetContractorsForOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetContractorsForOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetContractorsForOrderResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.contractors && message.contractors.length))
                        message.contractors = [];
                    message.contractors.push($root.PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        GetContractorsForOrderResponse.RelevantContractor = (function() {

            /**
             * Properties of a RelevantContractor.
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse
             * @interface IRelevantContractor
             * @property {number|null} [outCleanerId] RelevantContractor outCleanerId
             * @property {string|null} [outSSOUserId] RelevantContractor outSSOUserId
             * @property {number|null} [outRating] RelevantContractor outRating
             * @property {string|null} [outPhone] RelevantContractor outPhone
             * @property {string|null} [outSkill] RelevantContractor outSkill
             * @property {string|null} [outFIO] RelevantContractor outFIO
             * @property {number|null} [outDistance] RelevantContractor outDistance
             * @property {boolean|null} [outFavorite] RelevantContractor outFavorite
             * @property {number|null} [r] RelevantContractor r
             */

            /**
             * Constructs a new RelevantContractor.
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse
             * @classdesc Represents a RelevantContractor.
             * @implements IRelevantContractor
             * @constructor
             * @param {PlatformAdminApi.GetContractorsForOrderResponse.IRelevantContractor=} [properties] Properties to set
             */
            function RelevantContractor(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RelevantContractor outCleanerId.
             * @member {number} outCleanerId
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outCleanerId = 0;

            /**
             * RelevantContractor outSSOUserId.
             * @member {string} outSSOUserId
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outSSOUserId = "";

            /**
             * RelevantContractor outRating.
             * @member {number} outRating
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outRating = 0;

            /**
             * RelevantContractor outPhone.
             * @member {string} outPhone
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outPhone = "";

            /**
             * RelevantContractor outSkill.
             * @member {string} outSkill
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outSkill = "";

            /**
             * RelevantContractor outFIO.
             * @member {string} outFIO
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outFIO = "";

            /**
             * RelevantContractor outDistance.
             * @member {number} outDistance
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outDistance = 0;

            /**
             * RelevantContractor outFavorite.
             * @member {boolean} outFavorite
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.outFavorite = false;

            /**
             * RelevantContractor r.
             * @member {number} r
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @instance
             */
            RelevantContractor.prototype.r = 0;

            /**
             * Encodes the specified RelevantContractor message. Does not implicitly {@link PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @static
             * @param {PlatformAdminApi.GetContractorsForOrderResponse.IRelevantContractor} message RelevantContractor message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RelevantContractor.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.outCleanerId != null && Object.hasOwnProperty.call(message, "outCleanerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.outCleanerId);
                if (message.outSSOUserId != null && Object.hasOwnProperty.call(message, "outSSOUserId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.outSSOUserId);
                if (message.outRating != null && Object.hasOwnProperty.call(message, "outRating"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.outRating);
                if (message.outPhone != null && Object.hasOwnProperty.call(message, "outPhone"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.outPhone);
                if (message.outSkill != null && Object.hasOwnProperty.call(message, "outSkill"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.outSkill);
                if (message.outFIO != null && Object.hasOwnProperty.call(message, "outFIO"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.outFIO);
                if (message.outDistance != null && Object.hasOwnProperty.call(message, "outDistance"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.outDistance);
                if (message.outFavorite != null && Object.hasOwnProperty.call(message, "outFavorite"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.outFavorite);
                if (message.r != null && Object.hasOwnProperty.call(message, "r"))
                    writer.uint32(/* id 9, wireType 5 =*/77).float(message.r);
                return writer;
            };

            /**
             * Decodes a RelevantContractor message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor} RelevantContractor
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RelevantContractor.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetContractorsForOrderResponse.RelevantContractor();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.outCleanerId = reader.int32();
                        break;
                    case 2:
                        message.outSSOUserId = reader.string();
                        break;
                    case 3:
                        message.outRating = reader.float();
                        break;
                    case 4:
                        message.outPhone = reader.string();
                        break;
                    case 5:
                        message.outSkill = reader.string();
                        break;
                    case 6:
                        message.outFIO = reader.string();
                        break;
                    case 7:
                        message.outDistance = reader.int32();
                        break;
                    case 8:
                        message.outFavorite = reader.bool();
                        break;
                    case 9:
                        message.r = reader.float();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return RelevantContractor;
        })();

        return GetContractorsForOrderResponse;
    })();

    PlatformAdminApi.UpsertAssignmentBackend = (function() {

        /**
         * Properties of an UpsertAssignmentBackend.
         * @memberof PlatformAdminApi
         * @interface IUpsertAssignmentBackend
         * @property {string|null} [contractorSsoId] UpsertAssignmentBackend contractorSsoId
         * @property {string|null} [contractorHrmId] UpsertAssignmentBackend contractorHrmId
         * @property {string|null} [positionId] UpsertAssignmentBackend positionId
         * @property {string|null} [orderNumber] UpsertAssignmentBackend orderNumber
         * @property {string|null} [cartId] UpsertAssignmentBackend cartId
         * @property {string|null} [orderId] UpsertAssignmentBackend orderId
         */

        /**
         * Constructs a new UpsertAssignmentBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpsertAssignmentBackend.
         * @implements IUpsertAssignmentBackend
         * @constructor
         * @param {PlatformAdminApi.IUpsertAssignmentBackend=} [properties] Properties to set
         */
        function UpsertAssignmentBackend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertAssignmentBackend contractorSsoId.
         * @member {string} contractorSsoId
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @instance
         */
        UpsertAssignmentBackend.prototype.contractorSsoId = "";

        /**
         * UpsertAssignmentBackend contractorHrmId.
         * @member {string} contractorHrmId
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @instance
         */
        UpsertAssignmentBackend.prototype.contractorHrmId = "";

        /**
         * UpsertAssignmentBackend positionId.
         * @member {string} positionId
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @instance
         */
        UpsertAssignmentBackend.prototype.positionId = "";

        /**
         * UpsertAssignmentBackend orderNumber.
         * @member {string} orderNumber
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @instance
         */
        UpsertAssignmentBackend.prototype.orderNumber = "";

        /**
         * UpsertAssignmentBackend cartId.
         * @member {string} cartId
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @instance
         */
        UpsertAssignmentBackend.prototype.cartId = "";

        /**
         * UpsertAssignmentBackend orderId.
         * @member {string} orderId
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @instance
         */
        UpsertAssignmentBackend.prototype.orderId = "";

        /**
         * Encodes the specified UpsertAssignmentBackend message. Does not implicitly {@link PlatformAdminApi.UpsertAssignmentBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @static
         * @param {PlatformAdminApi.IUpsertAssignmentBackend} message UpsertAssignmentBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertAssignmentBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contractorSsoId != null && Object.hasOwnProperty.call(message, "contractorSsoId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.contractorSsoId);
            if (message.contractorHrmId != null && Object.hasOwnProperty.call(message, "contractorHrmId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contractorHrmId);
            if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.positionId);
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.orderNumber);
            if (message.cartId != null && Object.hasOwnProperty.call(message, "cartId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.cartId);
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.orderId);
            return writer;
        };

        /**
         * Decodes an UpsertAssignmentBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpsertAssignmentBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpsertAssignmentBackend} UpsertAssignmentBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertAssignmentBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpsertAssignmentBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.contractorSsoId = reader.string();
                    break;
                case 2:
                    message.contractorHrmId = reader.string();
                    break;
                case 3:
                    message.positionId = reader.string();
                    break;
                case 4:
                    message.orderNumber = reader.string();
                    break;
                case 5:
                    message.cartId = reader.string();
                    break;
                case 6:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertAssignmentBackend;
    })();

    PlatformAdminApi.OrderDiscountsResponse = (function() {

        /**
         * Properties of an OrderDiscountsResponse.
         * @memberof PlatformAdminApi
         * @interface IOrderDiscountsResponse
         * @property {Array.<PlatformAdminApi.ISelectOption>|null} [data] OrderDiscountsResponse data
         */

        /**
         * Constructs a new OrderDiscountsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderDiscountsResponse.
         * @implements IOrderDiscountsResponse
         * @constructor
         * @param {PlatformAdminApi.IOrderDiscountsResponse=} [properties] Properties to set
         */
        function OrderDiscountsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderDiscountsResponse data.
         * @member {Array.<PlatformAdminApi.ISelectOption>} data
         * @memberof PlatformAdminApi.OrderDiscountsResponse
         * @instance
         */
        OrderDiscountsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified OrderDiscountsResponse message. Does not implicitly {@link PlatformAdminApi.OrderDiscountsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderDiscountsResponse
         * @static
         * @param {PlatformAdminApi.IOrderDiscountsResponse} message OrderDiscountsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderDiscountsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.SelectOption.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderDiscountsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderDiscountsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderDiscountsResponse} OrderDiscountsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderDiscountsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderDiscountsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderDiscountsResponse;
    })();

    PlatformAdminApi.OrderDiscountsRequest = (function() {

        /**
         * Properties of an OrderDiscountsRequest.
         * @memberof PlatformAdminApi
         * @interface IOrderDiscountsRequest
         * @property {string|null} [elementSlug] OrderDiscountsRequest elementSlug
         * @property {string|null} [customerId] OrderDiscountsRequest customerId
         * @property {string|null} [regionId] OrderDiscountsRequest regionId
         * @property {string|null} [searchString] OrderDiscountsRequest searchString
         */

        /**
         * Constructs a new OrderDiscountsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderDiscountsRequest.
         * @implements IOrderDiscountsRequest
         * @constructor
         * @param {PlatformAdminApi.IOrderDiscountsRequest=} [properties] Properties to set
         */
        function OrderDiscountsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderDiscountsRequest elementSlug.
         * @member {string} elementSlug
         * @memberof PlatformAdminApi.OrderDiscountsRequest
         * @instance
         */
        OrderDiscountsRequest.prototype.elementSlug = "";

        /**
         * OrderDiscountsRequest customerId.
         * @member {string} customerId
         * @memberof PlatformAdminApi.OrderDiscountsRequest
         * @instance
         */
        OrderDiscountsRequest.prototype.customerId = "";

        /**
         * OrderDiscountsRequest regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.OrderDiscountsRequest
         * @instance
         */
        OrderDiscountsRequest.prototype.regionId = "";

        /**
         * OrderDiscountsRequest searchString.
         * @member {string} searchString
         * @memberof PlatformAdminApi.OrderDiscountsRequest
         * @instance
         */
        OrderDiscountsRequest.prototype.searchString = "";

        /**
         * Encodes the specified OrderDiscountsRequest message. Does not implicitly {@link PlatformAdminApi.OrderDiscountsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderDiscountsRequest
         * @static
         * @param {PlatformAdminApi.IOrderDiscountsRequest} message OrderDiscountsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderDiscountsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.elementSlug != null && Object.hasOwnProperty.call(message, "elementSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.elementSlug);
            if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerId);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.regionId);
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.searchString);
            return writer;
        };

        /**
         * Decodes an OrderDiscountsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderDiscountsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderDiscountsRequest} OrderDiscountsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderDiscountsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderDiscountsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.elementSlug = reader.string();
                    break;
                case 2:
                    message.customerId = reader.string();
                    break;
                case 3:
                    message.regionId = reader.string();
                    break;
                case 4:
                    message.searchString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderDiscountsRequest;
    })();

    PlatformAdminApi.UpsertOrderOptionsBackend = (function() {

        /**
         * Properties of an UpsertOrderOptionsBackend.
         * @memberof PlatformAdminApi
         * @interface IUpsertOrderOptionsBackend
         * @property {string|null} [cartId] UpsertOrderOptionsBackend cartId
         * @property {PlatformAdminApi.INewOrderOptionValues|null} [newOrderOption] UpsertOrderOptionsBackend newOrderOption
         * @property {number|null} [orderSerialNumber] UpsertOrderOptionsBackend orderSerialNumber
         * @property {PlatformAdminApi.ICustomerViewOrder|null} [oldOrder] UpsertOrderOptionsBackend oldOrder
         */

        /**
         * Constructs a new UpsertOrderOptionsBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpsertOrderOptionsBackend.
         * @implements IUpsertOrderOptionsBackend
         * @constructor
         * @param {PlatformAdminApi.IUpsertOrderOptionsBackend=} [properties] Properties to set
         */
        function UpsertOrderOptionsBackend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertOrderOptionsBackend cartId.
         * @member {string} cartId
         * @memberof PlatformAdminApi.UpsertOrderOptionsBackend
         * @instance
         */
        UpsertOrderOptionsBackend.prototype.cartId = "";

        /**
         * UpsertOrderOptionsBackend newOrderOption.
         * @member {PlatformAdminApi.INewOrderOptionValues|null|undefined} newOrderOption
         * @memberof PlatformAdminApi.UpsertOrderOptionsBackend
         * @instance
         */
        UpsertOrderOptionsBackend.prototype.newOrderOption = null;

        /**
         * UpsertOrderOptionsBackend orderSerialNumber.
         * @member {number} orderSerialNumber
         * @memberof PlatformAdminApi.UpsertOrderOptionsBackend
         * @instance
         */
        UpsertOrderOptionsBackend.prototype.orderSerialNumber = 0;

        /**
         * UpsertOrderOptionsBackend oldOrder.
         * @member {PlatformAdminApi.ICustomerViewOrder|null|undefined} oldOrder
         * @memberof PlatformAdminApi.UpsertOrderOptionsBackend
         * @instance
         */
        UpsertOrderOptionsBackend.prototype.oldOrder = null;

        /**
         * Encodes the specified UpsertOrderOptionsBackend message. Does not implicitly {@link PlatformAdminApi.UpsertOrderOptionsBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpsertOrderOptionsBackend
         * @static
         * @param {PlatformAdminApi.IUpsertOrderOptionsBackend} message UpsertOrderOptionsBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertOrderOptionsBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cartId != null && Object.hasOwnProperty.call(message, "cartId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cartId);
            if (message.newOrderOption != null && Object.hasOwnProperty.call(message, "newOrderOption"))
                $root.PlatformAdminApi.NewOrderOptionValues.encode(message.newOrderOption, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.orderSerialNumber);
            if (message.oldOrder != null && Object.hasOwnProperty.call(message, "oldOrder"))
                $root.PlatformAdminApi.CustomerViewOrder.encode(message.oldOrder, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpsertOrderOptionsBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpsertOrderOptionsBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpsertOrderOptionsBackend} UpsertOrderOptionsBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertOrderOptionsBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpsertOrderOptionsBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cartId = reader.string();
                    break;
                case 2:
                    message.newOrderOption = $root.PlatformAdminApi.NewOrderOptionValues.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.orderSerialNumber = reader.int32();
                    break;
                case 4:
                    message.oldOrder = $root.PlatformAdminApi.CustomerViewOrder.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertOrderOptionsBackend;
    })();

    PlatformAdminApi.NewOrderOptionValues = (function() {

        /**
         * Properties of a NewOrderOptionValues.
         * @memberof PlatformAdminApi
         * @interface INewOrderOptionValues
         * @property {string|null} [priceOptionId] NewOrderOptionValues priceOptionId
         * @property {boolean|null} [isFree] NewOrderOptionValues isFree
         * @property {string|null} [value] NewOrderOptionValues value
         */

        /**
         * Constructs a new NewOrderOptionValues.
         * @memberof PlatformAdminApi
         * @classdesc Represents a NewOrderOptionValues.
         * @implements INewOrderOptionValues
         * @constructor
         * @param {PlatformAdminApi.INewOrderOptionValues=} [properties] Properties to set
         */
        function NewOrderOptionValues(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewOrderOptionValues priceOptionId.
         * @member {string} priceOptionId
         * @memberof PlatformAdminApi.NewOrderOptionValues
         * @instance
         */
        NewOrderOptionValues.prototype.priceOptionId = "";

        /**
         * NewOrderOptionValues isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.NewOrderOptionValues
         * @instance
         */
        NewOrderOptionValues.prototype.isFree = false;

        /**
         * NewOrderOptionValues value.
         * @member {string} value
         * @memberof PlatformAdminApi.NewOrderOptionValues
         * @instance
         */
        NewOrderOptionValues.prototype.value = "";

        /**
         * Encodes the specified NewOrderOptionValues message. Does not implicitly {@link PlatformAdminApi.NewOrderOptionValues.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.NewOrderOptionValues
         * @static
         * @param {PlatformAdminApi.INewOrderOptionValues} message NewOrderOptionValues message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewOrderOptionValues.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.priceOptionId != null && Object.hasOwnProperty.call(message, "priceOptionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.priceOptionId);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isFree);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            return writer;
        };

        /**
         * Decodes a NewOrderOptionValues message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.NewOrderOptionValues
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.NewOrderOptionValues} NewOrderOptionValues
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewOrderOptionValues.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.NewOrderOptionValues();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.priceOptionId = reader.string();
                    break;
                case 2:
                    message.isFree = reader.bool();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NewOrderOptionValues;
    })();

    PlatformAdminApi.CreateCustomerOrderRequest = (function() {

        /**
         * Properties of a CreateCustomerOrderRequest.
         * @memberof PlatformAdminApi
         * @interface ICreateCustomerOrderRequest
         * @property {string|null} [customerId] CreateCustomerOrderRequest customerId
         * @property {string|null} [cartId] CreateCustomerOrderRequest cartId
         * @property {string|null} [elementId] CreateCustomerOrderRequest elementId
         */

        /**
         * Constructs a new CreateCustomerOrderRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CreateCustomerOrderRequest.
         * @implements ICreateCustomerOrderRequest
         * @constructor
         * @param {PlatformAdminApi.ICreateCustomerOrderRequest=} [properties] Properties to set
         */
        function CreateCustomerOrderRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateCustomerOrderRequest customerId.
         * @member {string} customerId
         * @memberof PlatformAdminApi.CreateCustomerOrderRequest
         * @instance
         */
        CreateCustomerOrderRequest.prototype.customerId = "";

        /**
         * CreateCustomerOrderRequest cartId.
         * @member {string} cartId
         * @memberof PlatformAdminApi.CreateCustomerOrderRequest
         * @instance
         */
        CreateCustomerOrderRequest.prototype.cartId = "";

        /**
         * CreateCustomerOrderRequest elementId.
         * @member {string} elementId
         * @memberof PlatformAdminApi.CreateCustomerOrderRequest
         * @instance
         */
        CreateCustomerOrderRequest.prototype.elementId = "";

        /**
         * Encodes the specified CreateCustomerOrderRequest message. Does not implicitly {@link PlatformAdminApi.CreateCustomerOrderRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateCustomerOrderRequest
         * @static
         * @param {PlatformAdminApi.ICreateCustomerOrderRequest} message CreateCustomerOrderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCustomerOrderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
            if (message.cartId != null && Object.hasOwnProperty.call(message, "cartId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cartId);
            if (message.elementId != null && Object.hasOwnProperty.call(message, "elementId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.elementId);
            return writer;
        };

        /**
         * Decodes a CreateCustomerOrderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateCustomerOrderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateCustomerOrderRequest} CreateCustomerOrderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCustomerOrderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateCustomerOrderRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.customerId = reader.string();
                    break;
                case 2:
                    message.cartId = reader.string();
                    break;
                case 3:
                    message.elementId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateCustomerOrderRequest;
    })();

    PlatformAdminApi.CreateCustomerOrderResponse = (function() {

        /**
         * Properties of a CreateCustomerOrderResponse.
         * @memberof PlatformAdminApi
         * @interface ICreateCustomerOrderResponse
         * @property {number|null} [orderSerialNumber] CreateCustomerOrderResponse orderSerialNumber
         */

        /**
         * Constructs a new CreateCustomerOrderResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CreateCustomerOrderResponse.
         * @implements ICreateCustomerOrderResponse
         * @constructor
         * @param {PlatformAdminApi.ICreateCustomerOrderResponse=} [properties] Properties to set
         */
        function CreateCustomerOrderResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateCustomerOrderResponse orderSerialNumber.
         * @member {number} orderSerialNumber
         * @memberof PlatformAdminApi.CreateCustomerOrderResponse
         * @instance
         */
        CreateCustomerOrderResponse.prototype.orderSerialNumber = 0;

        /**
         * Encodes the specified CreateCustomerOrderResponse message. Does not implicitly {@link PlatformAdminApi.CreateCustomerOrderResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CreateCustomerOrderResponse
         * @static
         * @param {PlatformAdminApi.ICreateCustomerOrderResponse} message CreateCustomerOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCustomerOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.orderSerialNumber);
            return writer;
        };

        /**
         * Decodes a CreateCustomerOrderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CreateCustomerOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CreateCustomerOrderResponse} CreateCustomerOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCustomerOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CreateCustomerOrderResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderSerialNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateCustomerOrderResponse;
    })();

    PlatformAdminApi.CustomerViewOrder = (function() {

        /**
         * Properties of a CustomerViewOrder.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewOrder
         * @property {string|null} [id] CustomerViewOrder id
         * @property {string|null} [firstname] CustomerViewOrder firstname
         * @property {string|null} [lastname] CustomerViewOrder lastname
         * @property {string|null} [phoneNumber] CustomerViewOrder phoneNumber
         * @property {boolean|null} [phoneNumberIsVerified] CustomerViewOrder phoneNumberIsVerified
         * @property {number|null} [orderNumber] CustomerViewOrder orderNumber
         * @property {PlatformAdminApi.IOrderViewPaymentInformation|null} [paymentType] CustomerViewOrder paymentType
         * @property {plt.core.base.PAYMENT_STATUS_TYPE|null} [paymentStatus] CustomerViewOrder paymentStatus
         * @property {number|null} [usedBonuses] CustomerViewOrder usedBonuses
         * @property {number|null} [availableBonuses] CustomerViewOrder availableBonuses
         * @property {PlatformAdminApi.ISelectOption|null} [discount] CustomerViewOrder discount
         * @property {string|null} [promocode] CustomerViewOrder promocode
         * @property {plt.core.base.ORDER_STATUS_TYPE|null} [status] CustomerViewOrder status
         * @property {string|null} [comment] CustomerViewOrder comment
         * @property {string|null} [selectedAddress] CustomerViewOrder selectedAddress
         * @property {PlatformAdminApi.IOrderAddress|null} [address] CustomerViewOrder address
         * @property {PlatformAdminApi.ICustomerViewOrderAdditionalOptions|null} [additionalOptions] CustomerViewOrder additionalOptions
         * @property {PlatformAdminApi.ICustomerViewOrderBaseOptions|null} [baseOptions] CustomerViewOrder baseOptions
         * @property {PlatformAdminApi.ICustomerViewOrderElement|null} [element] CustomerViewOrder element
         * @property {Array.<PlatformAdminApi.IOrderViewCustomerPaymentCards>|null} [cards] CustomerViewOrder cards
         * @property {string|null} [customerId] CustomerViewOrder customerId
         * @property {Array.<PlatformAdminApi.ICustomerViewOrderTab>|null} [tabs] CustomerViewOrder tabs
         * @property {string|null} [cartId] CustomerViewOrder cartId
         * @property {Array.<PlatformAdminApi.ICoreBaseOrderOption>|null} [options] CustomerViewOrder options
         * @property {Array.<PlatformAdminApi.ICoreBasePriceOption>|null} [priceOptions] CustomerViewOrder priceOptions
         * @property {PlatformAdminApi.ICoreBaseCart|null} [cart] CustomerViewOrder cart
         * @property {Array.<PlatformAdminApi.ICustomerViewStrategyItem>|null} [strategies] CustomerViewOrder strategies
         * @property {PlatformAdminApi.ICustomerViewStrategyItem|null} [selectedStrategy] CustomerViewOrder selectedStrategy
         * @property {boolean|null} [isMultiAssignment] CustomerViewOrder isMultiAssignment
         * @property {Array.<PlatformAdminApi.IOrderStrategyAssignment>|null} [assignments] CustomerViewOrder assignments
         * @property {string|null} [totalDuration] CustomerViewOrder totalDuration
         * @property {string|null} [totalPrice] CustomerViewOrder totalPrice
         * @property {string|null} [subTotalPrice] CustomerViewOrder subTotalPrice
         * @property {string|null} [totalDiscount] CustomerViewOrder totalDiscount
         * @property {string|null} [totalDiscountPercent] CustomerViewOrder totalDiscountPercent
         * @property {string|null} [bookingStartDate] CustomerViewOrder bookingStartDate
         * @property {string|null} [elementSlug] CustomerViewOrder elementSlug
         * @property {string|null} [regionId] CustomerViewOrder regionId
         * @property {Array.<PlatformAdminApi.IAdditionalServiceTab>|null} [additionalServices] CustomerViewOrder additionalServices
         * @property {boolean|null} [isDraft] CustomerViewOrder isDraft
         * @property {boolean|null} [isExternal] CustomerViewOrder isExternal
         * @property {string|null} [externalDetailsPageUrl] CustomerViewOrder externalDetailsPageUrl
         * @property {string|null} [startDateUnparsed] CustomerViewOrder startDateUnparsed
         * @property {string|null} [statusName] CustomerViewOrder statusName
         * @property {PlatformAdminApi.IOrderMeta|null} [orderMeta] CustomerViewOrder orderMeta
         * @property {PlatformAdminApi.OrderSystem|null} [source] CustomerViewOrder source
         * @property {PlatformAdminApi.IRejectionReason|null} [rejectionReason] CustomerViewOrder rejectionReason
         * @property {Array.<PlatformAdminApi.IOrderStatusHistory>|null} [statusHistory] CustomerViewOrder statusHistory
         * @property {Array.<PlatformAdminApi.ICustomerOrderAction>|null} [actions] CustomerViewOrder actions
         * @property {string|null} [receiptLink] CustomerViewOrder receiptLink
         * @property {PlatformAdminApi.IOrderCreator|null} [creator] CustomerViewOrder creator
         * @property {boolean|null} [leaveAtTheDoor] CustomerViewOrder leaveAtTheDoor
         * @property {PlatformAdminApi.CustomerViewOrder.IOrderViewMeta|null} [meta] CustomerViewOrder meta
         */

        /**
         * Constructs a new CustomerViewOrder.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewOrder.
         * @implements ICustomerViewOrder
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewOrder=} [properties] Properties to set
         */
        function CustomerViewOrder(properties) {
            this.cards = [];
            this.tabs = [];
            this.options = [];
            this.priceOptions = [];
            this.strategies = [];
            this.assignments = [];
            this.additionalServices = [];
            this.statusHistory = [];
            this.actions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewOrder id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.id = "";

        /**
         * CustomerViewOrder firstname.
         * @member {string} firstname
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.firstname = "";

        /**
         * CustomerViewOrder lastname.
         * @member {string} lastname
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.lastname = "";

        /**
         * CustomerViewOrder phoneNumber.
         * @member {string} phoneNumber
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.phoneNumber = "";

        /**
         * CustomerViewOrder phoneNumberIsVerified.
         * @member {boolean} phoneNumberIsVerified
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.phoneNumberIsVerified = false;

        /**
         * CustomerViewOrder orderNumber.
         * @member {number} orderNumber
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.orderNumber = 0;

        /**
         * CustomerViewOrder paymentType.
         * @member {PlatformAdminApi.IOrderViewPaymentInformation|null|undefined} paymentType
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.paymentType = null;

        /**
         * CustomerViewOrder paymentStatus.
         * @member {plt.core.base.PAYMENT_STATUS_TYPE} paymentStatus
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.paymentStatus = 0;

        /**
         * CustomerViewOrder usedBonuses.
         * @member {number} usedBonuses
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.usedBonuses = 0;

        /**
         * CustomerViewOrder availableBonuses.
         * @member {number} availableBonuses
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.availableBonuses = 0;

        /**
         * CustomerViewOrder discount.
         * @member {PlatformAdminApi.ISelectOption|null|undefined} discount
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.discount = null;

        /**
         * CustomerViewOrder promocode.
         * @member {string} promocode
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.promocode = "";

        /**
         * CustomerViewOrder status.
         * @member {plt.core.base.ORDER_STATUS_TYPE} status
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.status = 0;

        /**
         * CustomerViewOrder comment.
         * @member {string} comment
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.comment = "";

        /**
         * CustomerViewOrder selectedAddress.
         * @member {string} selectedAddress
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.selectedAddress = "";

        /**
         * CustomerViewOrder address.
         * @member {PlatformAdminApi.IOrderAddress|null|undefined} address
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.address = null;

        /**
         * CustomerViewOrder additionalOptions.
         * @member {PlatformAdminApi.ICustomerViewOrderAdditionalOptions|null|undefined} additionalOptions
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.additionalOptions = null;

        /**
         * CustomerViewOrder baseOptions.
         * @member {PlatformAdminApi.ICustomerViewOrderBaseOptions|null|undefined} baseOptions
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.baseOptions = null;

        /**
         * CustomerViewOrder element.
         * @member {PlatformAdminApi.ICustomerViewOrderElement|null|undefined} element
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.element = null;

        /**
         * CustomerViewOrder cards.
         * @member {Array.<PlatformAdminApi.IOrderViewCustomerPaymentCards>} cards
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.cards = $util.emptyArray;

        /**
         * CustomerViewOrder customerId.
         * @member {string} customerId
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.customerId = "";

        /**
         * CustomerViewOrder tabs.
         * @member {Array.<PlatformAdminApi.ICustomerViewOrderTab>} tabs
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.tabs = $util.emptyArray;

        /**
         * CustomerViewOrder cartId.
         * @member {string} cartId
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.cartId = "";

        /**
         * CustomerViewOrder options.
         * @member {Array.<PlatformAdminApi.ICoreBaseOrderOption>} options
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.options = $util.emptyArray;

        /**
         * CustomerViewOrder priceOptions.
         * @member {Array.<PlatformAdminApi.ICoreBasePriceOption>} priceOptions
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.priceOptions = $util.emptyArray;

        /**
         * CustomerViewOrder cart.
         * @member {PlatformAdminApi.ICoreBaseCart|null|undefined} cart
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.cart = null;

        /**
         * CustomerViewOrder strategies.
         * @member {Array.<PlatformAdminApi.ICustomerViewStrategyItem>} strategies
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.strategies = $util.emptyArray;

        /**
         * CustomerViewOrder selectedStrategy.
         * @member {PlatformAdminApi.ICustomerViewStrategyItem|null|undefined} selectedStrategy
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.selectedStrategy = null;

        /**
         * CustomerViewOrder isMultiAssignment.
         * @member {boolean} isMultiAssignment
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.isMultiAssignment = false;

        /**
         * CustomerViewOrder assignments.
         * @member {Array.<PlatformAdminApi.IOrderStrategyAssignment>} assignments
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.assignments = $util.emptyArray;

        /**
         * CustomerViewOrder totalDuration.
         * @member {string} totalDuration
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.totalDuration = "";

        /**
         * CustomerViewOrder totalPrice.
         * @member {string} totalPrice
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.totalPrice = "";

        /**
         * CustomerViewOrder subTotalPrice.
         * @member {string} subTotalPrice
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.subTotalPrice = "";

        /**
         * CustomerViewOrder totalDiscount.
         * @member {string} totalDiscount
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.totalDiscount = "";

        /**
         * CustomerViewOrder totalDiscountPercent.
         * @member {string} totalDiscountPercent
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.totalDiscountPercent = "";

        /**
         * CustomerViewOrder bookingStartDate.
         * @member {string} bookingStartDate
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.bookingStartDate = "";

        /**
         * CustomerViewOrder elementSlug.
         * @member {string} elementSlug
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.elementSlug = "";

        /**
         * CustomerViewOrder regionId.
         * @member {string} regionId
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.regionId = "";

        /**
         * CustomerViewOrder additionalServices.
         * @member {Array.<PlatformAdminApi.IAdditionalServiceTab>} additionalServices
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.additionalServices = $util.emptyArray;

        /**
         * CustomerViewOrder isDraft.
         * @member {boolean} isDraft
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.isDraft = false;

        /**
         * CustomerViewOrder isExternal.
         * @member {boolean} isExternal
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.isExternal = false;

        /**
         * CustomerViewOrder externalDetailsPageUrl.
         * @member {string} externalDetailsPageUrl
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.externalDetailsPageUrl = "";

        /**
         * CustomerViewOrder startDateUnparsed.
         * @member {string} startDateUnparsed
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.startDateUnparsed = "";

        /**
         * CustomerViewOrder statusName.
         * @member {string} statusName
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.statusName = "";

        /**
         * CustomerViewOrder orderMeta.
         * @member {PlatformAdminApi.IOrderMeta|null|undefined} orderMeta
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.orderMeta = null;

        /**
         * CustomerViewOrder source.
         * @member {PlatformAdminApi.OrderSystem} source
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.source = 0;

        /**
         * CustomerViewOrder rejectionReason.
         * @member {PlatformAdminApi.IRejectionReason|null|undefined} rejectionReason
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.rejectionReason = null;

        /**
         * CustomerViewOrder statusHistory.
         * @member {Array.<PlatformAdminApi.IOrderStatusHistory>} statusHistory
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.statusHistory = $util.emptyArray;

        /**
         * CustomerViewOrder actions.
         * @member {Array.<PlatformAdminApi.ICustomerOrderAction>} actions
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.actions = $util.emptyArray;

        /**
         * CustomerViewOrder receiptLink.
         * @member {string|null|undefined} receiptLink
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.receiptLink = null;

        /**
         * CustomerViewOrder creator.
         * @member {PlatformAdminApi.IOrderCreator|null|undefined} creator
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.creator = null;

        /**
         * CustomerViewOrder leaveAtTheDoor.
         * @member {boolean|null|undefined} leaveAtTheDoor
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.leaveAtTheDoor = null;

        /**
         * CustomerViewOrder meta.
         * @member {PlatformAdminApi.CustomerViewOrder.IOrderViewMeta|null|undefined} meta
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        CustomerViewOrder.prototype.meta = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CustomerViewOrder _receiptLink.
         * @member {"receiptLink"|undefined} _receiptLink
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        Object.defineProperty(CustomerViewOrder.prototype, "_receiptLink", {
            get: $util.oneOfGetter($oneOfFields = ["receiptLink"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CustomerViewOrder _creator.
         * @member {"creator"|undefined} _creator
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        Object.defineProperty(CustomerViewOrder.prototype, "_creator", {
            get: $util.oneOfGetter($oneOfFields = ["creator"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CustomerViewOrder _leaveAtTheDoor.
         * @member {"leaveAtTheDoor"|undefined} _leaveAtTheDoor
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        Object.defineProperty(CustomerViewOrder.prototype, "_leaveAtTheDoor", {
            get: $util.oneOfGetter($oneOfFields = ["leaveAtTheDoor"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CustomerViewOrder _meta.
         * @member {"meta"|undefined} _meta
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @instance
         */
        Object.defineProperty(CustomerViewOrder.prototype, "_meta", {
            get: $util.oneOfGetter($oneOfFields = ["meta"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified CustomerViewOrder message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrder.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @static
         * @param {PlatformAdminApi.ICustomerViewOrder} message CustomerViewOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewOrder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.firstname != null && Object.hasOwnProperty.call(message, "firstname"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstname);
            if (message.lastname != null && Object.hasOwnProperty.call(message, "lastname"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastname);
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phoneNumber);
            if (message.phoneNumberIsVerified != null && Object.hasOwnProperty.call(message, "phoneNumberIsVerified"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.phoneNumberIsVerified);
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.orderNumber);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                $root.PlatformAdminApi.OrderViewPaymentInformation.encode(message.paymentType, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.paymentStatus);
            if (message.usedBonuses != null && Object.hasOwnProperty.call(message, "usedBonuses"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.usedBonuses);
            if (message.availableBonuses != null && Object.hasOwnProperty.call(message, "availableBonuses"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.availableBonuses);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                $root.PlatformAdminApi.SelectOption.encode(message.discount, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.promocode);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.status);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.comment);
            if (message.selectedAddress != null && Object.hasOwnProperty.call(message, "selectedAddress"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.selectedAddress);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.OrderAddress.encode(message.address, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.additionalOptions != null && Object.hasOwnProperty.call(message, "additionalOptions"))
                $root.PlatformAdminApi.CustomerViewOrderAdditionalOptions.encode(message.additionalOptions, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.baseOptions != null && Object.hasOwnProperty.call(message, "baseOptions"))
                $root.PlatformAdminApi.CustomerViewOrderBaseOptions.encode(message.baseOptions, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.element != null && Object.hasOwnProperty.call(message, "element"))
                $root.PlatformAdminApi.CustomerViewOrderElement.encode(message.element, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.cards != null && message.cards.length)
                for (var i = 0; i < message.cards.length; ++i)
                    $root.PlatformAdminApi.OrderViewCustomerPaymentCards.encode(message.cards[i], writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.customerId);
            if (message.tabs != null && message.tabs.length)
                for (var i = 0; i < message.tabs.length; ++i)
                    $root.PlatformAdminApi.CustomerViewOrderTab.encode(message.tabs[i], writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.cartId != null && Object.hasOwnProperty.call(message, "cartId"))
                writer.uint32(/* id 27, wireType 2 =*/218).string(message.cartId);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.CoreBaseOrderOption.encode(message.options[i], writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.priceOptions != null && message.priceOptions.length)
                for (var i = 0; i < message.priceOptions.length; ++i)
                    $root.PlatformAdminApi.CoreBasePriceOption.encode(message.priceOptions[i], writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.cart != null && Object.hasOwnProperty.call(message, "cart"))
                $root.PlatformAdminApi.CoreBaseCart.encode(message.cart, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.strategies != null && message.strategies.length)
                for (var i = 0; i < message.strategies.length; ++i)
                    $root.PlatformAdminApi.CustomerViewStrategyItem.encode(message.strategies[i], writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.selectedStrategy != null && Object.hasOwnProperty.call(message, "selectedStrategy"))
                $root.PlatformAdminApi.CustomerViewStrategyItem.encode(message.selectedStrategy, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.isMultiAssignment != null && Object.hasOwnProperty.call(message, "isMultiAssignment"))
                writer.uint32(/* id 35, wireType 0 =*/280).bool(message.isMultiAssignment);
            if (message.assignments != null && message.assignments.length)
                for (var i = 0; i < message.assignments.length; ++i)
                    $root.PlatformAdminApi.OrderStrategyAssignment.encode(message.assignments[i], writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 37, wireType 2 =*/298).string(message.totalDuration);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 38, wireType 2 =*/306).string(message.totalPrice);
            if (message.subTotalPrice != null && Object.hasOwnProperty.call(message, "subTotalPrice"))
                writer.uint32(/* id 39, wireType 2 =*/314).string(message.subTotalPrice);
            if (message.totalDiscount != null && Object.hasOwnProperty.call(message, "totalDiscount"))
                writer.uint32(/* id 40, wireType 2 =*/322).string(message.totalDiscount);
            if (message.totalDiscountPercent != null && Object.hasOwnProperty.call(message, "totalDiscountPercent"))
                writer.uint32(/* id 41, wireType 2 =*/330).string(message.totalDiscountPercent);
            if (message.bookingStartDate != null && Object.hasOwnProperty.call(message, "bookingStartDate"))
                writer.uint32(/* id 42, wireType 2 =*/338).string(message.bookingStartDate);
            if (message.elementSlug != null && Object.hasOwnProperty.call(message, "elementSlug"))
                writer.uint32(/* id 43, wireType 2 =*/346).string(message.elementSlug);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 44, wireType 2 =*/354).string(message.regionId);
            if (message.additionalServices != null && message.additionalServices.length)
                for (var i = 0; i < message.additionalServices.length; ++i)
                    $root.PlatformAdminApi.AdditionalServiceTab.encode(message.additionalServices[i], writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.isDraft != null && Object.hasOwnProperty.call(message, "isDraft"))
                writer.uint32(/* id 46, wireType 0 =*/368).bool(message.isDraft);
            if (message.isExternal != null && Object.hasOwnProperty.call(message, "isExternal"))
                writer.uint32(/* id 47, wireType 0 =*/376).bool(message.isExternal);
            if (message.externalDetailsPageUrl != null && Object.hasOwnProperty.call(message, "externalDetailsPageUrl"))
                writer.uint32(/* id 48, wireType 2 =*/386).string(message.externalDetailsPageUrl);
            if (message.startDateUnparsed != null && Object.hasOwnProperty.call(message, "startDateUnparsed"))
                writer.uint32(/* id 49, wireType 2 =*/394).string(message.startDateUnparsed);
            if (message.statusName != null && Object.hasOwnProperty.call(message, "statusName"))
                writer.uint32(/* id 50, wireType 2 =*/402).string(message.statusName);
            if (message.orderMeta != null && Object.hasOwnProperty.call(message, "orderMeta"))
                $root.PlatformAdminApi.OrderMeta.encode(message.orderMeta, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 52, wireType 0 =*/416).int32(message.source);
            if (message.rejectionReason != null && Object.hasOwnProperty.call(message, "rejectionReason"))
                $root.PlatformAdminApi.RejectionReason.encode(message.rejectionReason, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
            if (message.statusHistory != null && message.statusHistory.length)
                for (var i = 0; i < message.statusHistory.length; ++i)
                    $root.PlatformAdminApi.OrderStatusHistory.encode(message.statusHistory[i], writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
            if (message.actions != null && message.actions.length)
                for (var i = 0; i < message.actions.length; ++i)
                    $root.PlatformAdminApi.CustomerOrderAction.encode(message.actions[i], writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
            if (message.receiptLink != null && Object.hasOwnProperty.call(message, "receiptLink"))
                writer.uint32(/* id 56, wireType 2 =*/450).string(message.receiptLink);
            if (message.creator != null && Object.hasOwnProperty.call(message, "creator"))
                $root.PlatformAdminApi.OrderCreator.encode(message.creator, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
            if (message.leaveAtTheDoor != null && Object.hasOwnProperty.call(message, "leaveAtTheDoor"))
                writer.uint32(/* id 58, wireType 0 =*/464).bool(message.leaveAtTheDoor);
            if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                $root.PlatformAdminApi.CustomerViewOrder.OrderViewMeta.encode(message.meta, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerViewOrder message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewOrder} CustomerViewOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewOrder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrder();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.firstname = reader.string();
                    break;
                case 3:
                    message.lastname = reader.string();
                    break;
                case 4:
                    message.phoneNumber = reader.string();
                    break;
                case 5:
                    message.phoneNumberIsVerified = reader.bool();
                    break;
                case 6:
                    message.orderNumber = reader.int32();
                    break;
                case 7:
                    message.paymentType = $root.PlatformAdminApi.OrderViewPaymentInformation.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.paymentStatus = reader.int32();
                    break;
                case 12:
                    message.usedBonuses = reader.int32();
                    break;
                case 13:
                    message.availableBonuses = reader.int32();
                    break;
                case 14:
                    message.discount = $root.PlatformAdminApi.SelectOption.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.promocode = reader.string();
                    break;
                case 16:
                    message.status = reader.int32();
                    break;
                case 17:
                    message.comment = reader.string();
                    break;
                case 18:
                    message.selectedAddress = reader.string();
                    break;
                case 19:
                    message.address = $root.PlatformAdminApi.OrderAddress.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.additionalOptions = $root.PlatformAdminApi.CustomerViewOrderAdditionalOptions.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.baseOptions = $root.PlatformAdminApi.CustomerViewOrderBaseOptions.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.element = $root.PlatformAdminApi.CustomerViewOrderElement.decode(reader, reader.uint32());
                    break;
                case 23:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    message.cards.push($root.PlatformAdminApi.OrderViewCustomerPaymentCards.decode(reader, reader.uint32()));
                    break;
                case 24:
                    message.customerId = reader.string();
                    break;
                case 26:
                    if (!(message.tabs && message.tabs.length))
                        message.tabs = [];
                    message.tabs.push($root.PlatformAdminApi.CustomerViewOrderTab.decode(reader, reader.uint32()));
                    break;
                case 27:
                    message.cartId = reader.string();
                    break;
                case 28:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.CoreBaseOrderOption.decode(reader, reader.uint32()));
                    break;
                case 29:
                    if (!(message.priceOptions && message.priceOptions.length))
                        message.priceOptions = [];
                    message.priceOptions.push($root.PlatformAdminApi.CoreBasePriceOption.decode(reader, reader.uint32()));
                    break;
                case 30:
                    message.cart = $root.PlatformAdminApi.CoreBaseCart.decode(reader, reader.uint32());
                    break;
                case 33:
                    if (!(message.strategies && message.strategies.length))
                        message.strategies = [];
                    message.strategies.push($root.PlatformAdminApi.CustomerViewStrategyItem.decode(reader, reader.uint32()));
                    break;
                case 34:
                    message.selectedStrategy = $root.PlatformAdminApi.CustomerViewStrategyItem.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.isMultiAssignment = reader.bool();
                    break;
                case 36:
                    if (!(message.assignments && message.assignments.length))
                        message.assignments = [];
                    message.assignments.push($root.PlatformAdminApi.OrderStrategyAssignment.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.totalDuration = reader.string();
                    break;
                case 38:
                    message.totalPrice = reader.string();
                    break;
                case 39:
                    message.subTotalPrice = reader.string();
                    break;
                case 40:
                    message.totalDiscount = reader.string();
                    break;
                case 41:
                    message.totalDiscountPercent = reader.string();
                    break;
                case 42:
                    message.bookingStartDate = reader.string();
                    break;
                case 43:
                    message.elementSlug = reader.string();
                    break;
                case 44:
                    message.regionId = reader.string();
                    break;
                case 45:
                    if (!(message.additionalServices && message.additionalServices.length))
                        message.additionalServices = [];
                    message.additionalServices.push($root.PlatformAdminApi.AdditionalServiceTab.decode(reader, reader.uint32()));
                    break;
                case 46:
                    message.isDraft = reader.bool();
                    break;
                case 47:
                    message.isExternal = reader.bool();
                    break;
                case 48:
                    message.externalDetailsPageUrl = reader.string();
                    break;
                case 49:
                    message.startDateUnparsed = reader.string();
                    break;
                case 50:
                    message.statusName = reader.string();
                    break;
                case 51:
                    message.orderMeta = $root.PlatformAdminApi.OrderMeta.decode(reader, reader.uint32());
                    break;
                case 52:
                    message.source = reader.int32();
                    break;
                case 53:
                    message.rejectionReason = $root.PlatformAdminApi.RejectionReason.decode(reader, reader.uint32());
                    break;
                case 54:
                    if (!(message.statusHistory && message.statusHistory.length))
                        message.statusHistory = [];
                    message.statusHistory.push($root.PlatformAdminApi.OrderStatusHistory.decode(reader, reader.uint32()));
                    break;
                case 55:
                    if (!(message.actions && message.actions.length))
                        message.actions = [];
                    message.actions.push($root.PlatformAdminApi.CustomerOrderAction.decode(reader, reader.uint32()));
                    break;
                case 56:
                    message.receiptLink = reader.string();
                    break;
                case 57:
                    message.creator = $root.PlatformAdminApi.OrderCreator.decode(reader, reader.uint32());
                    break;
                case 58:
                    message.leaveAtTheDoor = reader.bool();
                    break;
                case 59:
                    message.meta = $root.PlatformAdminApi.CustomerViewOrder.OrderViewMeta.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CustomerViewOrder.OrderViewMeta = (function() {

            /**
             * Properties of an OrderViewMeta.
             * @memberof PlatformAdminApi.CustomerViewOrder
             * @interface IOrderViewMeta
             * @property {number|null} [numberOfPayments] OrderViewMeta numberOfPayments
             */

            /**
             * Constructs a new OrderViewMeta.
             * @memberof PlatformAdminApi.CustomerViewOrder
             * @classdesc Represents an OrderViewMeta.
             * @implements IOrderViewMeta
             * @constructor
             * @param {PlatformAdminApi.CustomerViewOrder.IOrderViewMeta=} [properties] Properties to set
             */
            function OrderViewMeta(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderViewMeta numberOfPayments.
             * @member {number|null|undefined} numberOfPayments
             * @memberof PlatformAdminApi.CustomerViewOrder.OrderViewMeta
             * @instance
             */
            OrderViewMeta.prototype.numberOfPayments = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * OrderViewMeta _numberOfPayments.
             * @member {"numberOfPayments"|undefined} _numberOfPayments
             * @memberof PlatformAdminApi.CustomerViewOrder.OrderViewMeta
             * @instance
             */
            Object.defineProperty(OrderViewMeta.prototype, "_numberOfPayments", {
                get: $util.oneOfGetter($oneOfFields = ["numberOfPayments"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified OrderViewMeta message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrder.OrderViewMeta.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.CustomerViewOrder.OrderViewMeta
             * @static
             * @param {PlatformAdminApi.CustomerViewOrder.IOrderViewMeta} message OrderViewMeta message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderViewMeta.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.numberOfPayments != null && Object.hasOwnProperty.call(message, "numberOfPayments"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.numberOfPayments);
                return writer;
            };

            /**
             * Decodes an OrderViewMeta message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.CustomerViewOrder.OrderViewMeta
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.CustomerViewOrder.OrderViewMeta} OrderViewMeta
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderViewMeta.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrder.OrderViewMeta();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.numberOfPayments = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return OrderViewMeta;
        })();

        return CustomerViewOrder;
    })();

    PlatformAdminApi.OrderStatusHistory = (function() {

        /**
         * Properties of an OrderStatusHistory.
         * @memberof PlatformAdminApi
         * @interface IOrderStatusHistory
         * @property {PlatformAdminApi.OrderStatusType|null} [status] OrderStatusHistory status
         * @property {string|null} [createdAt] OrderStatusHistory createdAt
         */

        /**
         * Constructs a new OrderStatusHistory.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderStatusHistory.
         * @implements IOrderStatusHistory
         * @constructor
         * @param {PlatformAdminApi.IOrderStatusHistory=} [properties] Properties to set
         */
        function OrderStatusHistory(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderStatusHistory status.
         * @member {PlatformAdminApi.OrderStatusType} status
         * @memberof PlatformAdminApi.OrderStatusHistory
         * @instance
         */
        OrderStatusHistory.prototype.status = 0;

        /**
         * OrderStatusHistory createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.OrderStatusHistory
         * @instance
         */
        OrderStatusHistory.prototype.createdAt = "";

        /**
         * Encodes the specified OrderStatusHistory message. Does not implicitly {@link PlatformAdminApi.OrderStatusHistory.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderStatusHistory
         * @static
         * @param {PlatformAdminApi.IOrderStatusHistory} message OrderStatusHistory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderStatusHistory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            return writer;
        };

        /**
         * Decodes an OrderStatusHistory message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderStatusHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderStatusHistory} OrderStatusHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderStatusHistory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderStatusHistory();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderStatusHistory;
    })();

    PlatformAdminApi.OrderMeta = (function() {

        /**
         * Properties of an OrderMeta.
         * @memberof PlatformAdminApi
         * @interface IOrderMeta
         * @property {string|null} [drycleaningReturnAt] OrderMeta drycleaningReturnAt
         * @property {number|null} [drycleaningSerialNumber] OrderMeta drycleaningSerialNumber
         */

        /**
         * Constructs a new OrderMeta.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderMeta.
         * @implements IOrderMeta
         * @constructor
         * @param {PlatformAdminApi.IOrderMeta=} [properties] Properties to set
         */
        function OrderMeta(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderMeta drycleaningReturnAt.
         * @member {string} drycleaningReturnAt
         * @memberof PlatformAdminApi.OrderMeta
         * @instance
         */
        OrderMeta.prototype.drycleaningReturnAt = "";

        /**
         * OrderMeta drycleaningSerialNumber.
         * @member {number} drycleaningSerialNumber
         * @memberof PlatformAdminApi.OrderMeta
         * @instance
         */
        OrderMeta.prototype.drycleaningSerialNumber = 0;

        /**
         * Encodes the specified OrderMeta message. Does not implicitly {@link PlatformAdminApi.OrderMeta.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderMeta
         * @static
         * @param {PlatformAdminApi.IOrderMeta} message OrderMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.drycleaningReturnAt != null && Object.hasOwnProperty.call(message, "drycleaningReturnAt"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.drycleaningReturnAt);
            if (message.drycleaningSerialNumber != null && Object.hasOwnProperty.call(message, "drycleaningSerialNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.drycleaningSerialNumber);
            return writer;
        };

        /**
         * Decodes an OrderMeta message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderMeta} OrderMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderMeta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.drycleaningReturnAt = reader.string();
                    break;
                case 2:
                    message.drycleaningSerialNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderMeta;
    })();

    PlatformAdminApi.AdditionalServiceTab = (function() {

        /**
         * Properties of an AdditionalServiceTab.
         * @memberof PlatformAdminApi
         * @interface IAdditionalServiceTab
         * @property {string|null} [id] AdditionalServiceTab id
         * @property {string|null} [name] AdditionalServiceTab name
         * @property {Array.<PlatformAdminApi.IAdditionalService>|null} [services] AdditionalServiceTab services
         */

        /**
         * Constructs a new AdditionalServiceTab.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalServiceTab.
         * @implements IAdditionalServiceTab
         * @constructor
         * @param {PlatformAdminApi.IAdditionalServiceTab=} [properties] Properties to set
         */
        function AdditionalServiceTab(properties) {
            this.services = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalServiceTab id.
         * @member {string} id
         * @memberof PlatformAdminApi.AdditionalServiceTab
         * @instance
         */
        AdditionalServiceTab.prototype.id = "";

        /**
         * AdditionalServiceTab name.
         * @member {string} name
         * @memberof PlatformAdminApi.AdditionalServiceTab
         * @instance
         */
        AdditionalServiceTab.prototype.name = "";

        /**
         * AdditionalServiceTab services.
         * @member {Array.<PlatformAdminApi.IAdditionalService>} services
         * @memberof PlatformAdminApi.AdditionalServiceTab
         * @instance
         */
        AdditionalServiceTab.prototype.services = $util.emptyArray;

        /**
         * Encodes the specified AdditionalServiceTab message. Does not implicitly {@link PlatformAdminApi.AdditionalServiceTab.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalServiceTab
         * @static
         * @param {PlatformAdminApi.IAdditionalServiceTab} message AdditionalServiceTab message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalServiceTab.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.services != null && message.services.length)
                for (var i = 0; i < message.services.length; ++i)
                    $root.PlatformAdminApi.AdditionalService.encode(message.services[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AdditionalServiceTab message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalServiceTab
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalServiceTab} AdditionalServiceTab
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalServiceTab.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalServiceTab();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 4:
                    if (!(message.services && message.services.length))
                        message.services = [];
                    message.services.push($root.PlatformAdminApi.AdditionalService.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalServiceTab;
    })();

    PlatformAdminApi.OrderAddress = (function() {

        /**
         * Properties of an OrderAddress.
         * @memberof PlatformAdminApi
         * @interface IOrderAddress
         * @property {string|null} [id] OrderAddress id
         * @property {string|null} [value] OrderAddress value
         * @property {PlatformAdminApi.ICoordinates|null} [coordinates] OrderAddress coordinates
         */

        /**
         * Constructs a new OrderAddress.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderAddress.
         * @implements IOrderAddress
         * @constructor
         * @param {PlatformAdminApi.IOrderAddress=} [properties] Properties to set
         */
        function OrderAddress(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderAddress id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderAddress
         * @instance
         */
        OrderAddress.prototype.id = "";

        /**
         * OrderAddress value.
         * @member {string} value
         * @memberof PlatformAdminApi.OrderAddress
         * @instance
         */
        OrderAddress.prototype.value = "";

        /**
         * OrderAddress coordinates.
         * @member {PlatformAdminApi.ICoordinates|null|undefined} coordinates
         * @memberof PlatformAdminApi.OrderAddress
         * @instance
         */
        OrderAddress.prototype.coordinates = null;

        /**
         * Encodes the specified OrderAddress message. Does not implicitly {@link PlatformAdminApi.OrderAddress.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderAddress
         * @static
         * @param {PlatformAdminApi.IOrderAddress} message OrderAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.PlatformAdminApi.Coordinates.encode(message.coordinates, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderAddress message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderAddress} OrderAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderAddress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.coordinates = $root.PlatformAdminApi.Coordinates.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderAddress;
    })();

    PlatformAdminApi.AdditionalService = (function() {

        /**
         * Properties of an AdditionalService.
         * @memberof PlatformAdminApi
         * @interface IAdditionalService
         * @property {string|null} [id] AdditionalService id
         * @property {string|null} [name] AdditionalService name
         * @property {number|null} [amount] AdditionalService amount
         * @property {number|null} [minAmount] AdditionalService minAmount
         * @property {number|null} [maxAmount] AdditionalService maxAmount
         * @property {boolean|null} [isFree] AdditionalService isFree
         * @property {string|null} [totalPrice] AdditionalService totalPrice
         * @property {string|null} [totalDurationText] AdditionalService totalDurationText
         * @property {string|null} [startDate] AdditionalService startDate
         * @property {PlatformAdminApi.IAdditionalServiceAddress|null} [address] AdditionalService address
         * @property {string|null} [orderOptionId] AdditionalService orderOptionId
         */

        /**
         * Constructs a new AdditionalService.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalService.
         * @implements IAdditionalService
         * @constructor
         * @param {PlatformAdminApi.IAdditionalService=} [properties] Properties to set
         */
        function AdditionalService(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalService id.
         * @member {string} id
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.id = "";

        /**
         * AdditionalService name.
         * @member {string} name
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.name = "";

        /**
         * AdditionalService amount.
         * @member {number} amount
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.amount = 0;

        /**
         * AdditionalService minAmount.
         * @member {number} minAmount
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.minAmount = 0;

        /**
         * AdditionalService maxAmount.
         * @member {number} maxAmount
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.maxAmount = 0;

        /**
         * AdditionalService isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.isFree = false;

        /**
         * AdditionalService totalPrice.
         * @member {string} totalPrice
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.totalPrice = "";

        /**
         * AdditionalService totalDurationText.
         * @member {string} totalDurationText
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.totalDurationText = "";

        /**
         * AdditionalService startDate.
         * @member {string} startDate
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.startDate = "";

        /**
         * AdditionalService address.
         * @member {PlatformAdminApi.IAdditionalServiceAddress|null|undefined} address
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.address = null;

        /**
         * AdditionalService orderOptionId.
         * @member {string} orderOptionId
         * @memberof PlatformAdminApi.AdditionalService
         * @instance
         */
        AdditionalService.prototype.orderOptionId = "";

        /**
         * Encodes the specified AdditionalService message. Does not implicitly {@link PlatformAdminApi.AdditionalService.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalService
         * @static
         * @param {PlatformAdminApi.IAdditionalService} message AdditionalService message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalService.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.amount);
            if (message.minAmount != null && Object.hasOwnProperty.call(message, "minAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minAmount);
            if (message.maxAmount != null && Object.hasOwnProperty.call(message, "maxAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxAmount);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isFree);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.totalPrice);
            if (message.totalDurationText != null && Object.hasOwnProperty.call(message, "totalDurationText"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.totalDurationText);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.startDate);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.PlatformAdminApi.AdditionalServiceAddress.encode(message.address, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.orderOptionId != null && Object.hasOwnProperty.call(message, "orderOptionId"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.orderOptionId);
            return writer;
        };

        /**
         * Decodes an AdditionalService message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalService
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalService} AdditionalService
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalService.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalService();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.amount = reader.int32();
                    break;
                case 4:
                    message.minAmount = reader.int32();
                    break;
                case 5:
                    message.maxAmount = reader.int32();
                    break;
                case 6:
                    message.isFree = reader.bool();
                    break;
                case 7:
                    message.totalPrice = reader.string();
                    break;
                case 8:
                    message.totalDurationText = reader.string();
                    break;
                case 9:
                    message.startDate = reader.string();
                    break;
                case 10:
                    message.address = $root.PlatformAdminApi.AdditionalServiceAddress.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.orderOptionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalService;
    })();

    PlatformAdminApi.AdditionalServiceAddress = (function() {

        /**
         * Properties of an AdditionalServiceAddress.
         * @memberof PlatformAdminApi
         * @interface IAdditionalServiceAddress
         * @property {string|null} [id] AdditionalServiceAddress id
         * @property {string|null} [text] AdditionalServiceAddress text
         */

        /**
         * Constructs a new AdditionalServiceAddress.
         * @memberof PlatformAdminApi
         * @classdesc Represents an AdditionalServiceAddress.
         * @implements IAdditionalServiceAddress
         * @constructor
         * @param {PlatformAdminApi.IAdditionalServiceAddress=} [properties] Properties to set
         */
        function AdditionalServiceAddress(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalServiceAddress id.
         * @member {string} id
         * @memberof PlatformAdminApi.AdditionalServiceAddress
         * @instance
         */
        AdditionalServiceAddress.prototype.id = "";

        /**
         * AdditionalServiceAddress text.
         * @member {string} text
         * @memberof PlatformAdminApi.AdditionalServiceAddress
         * @instance
         */
        AdditionalServiceAddress.prototype.text = "";

        /**
         * Encodes the specified AdditionalServiceAddress message. Does not implicitly {@link PlatformAdminApi.AdditionalServiceAddress.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.AdditionalServiceAddress
         * @static
         * @param {PlatformAdminApi.IAdditionalServiceAddress} message AdditionalServiceAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalServiceAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            return writer;
        };

        /**
         * Decodes an AdditionalServiceAddress message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.AdditionalServiceAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.AdditionalServiceAddress} AdditionalServiceAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalServiceAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.AdditionalServiceAddress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AdditionalServiceAddress;
    })();

    PlatformAdminApi.CustomerViewOrderTab = (function() {

        /**
         * Properties of a CustomerViewOrderTab.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewOrderTab
         * @property {string|null} [name] CustomerViewOrderTab name
         * @property {string|null} [value] CustomerViewOrderTab value
         * @property {number|null} [orderSerialNumber] CustomerViewOrderTab orderSerialNumber
         */

        /**
         * Constructs a new CustomerViewOrderTab.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewOrderTab.
         * @implements ICustomerViewOrderTab
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewOrderTab=} [properties] Properties to set
         */
        function CustomerViewOrderTab(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewOrderTab name.
         * @member {string} name
         * @memberof PlatformAdminApi.CustomerViewOrderTab
         * @instance
         */
        CustomerViewOrderTab.prototype.name = "";

        /**
         * CustomerViewOrderTab value.
         * @member {string} value
         * @memberof PlatformAdminApi.CustomerViewOrderTab
         * @instance
         */
        CustomerViewOrderTab.prototype.value = "";

        /**
         * CustomerViewOrderTab orderSerialNumber.
         * @member {number} orderSerialNumber
         * @memberof PlatformAdminApi.CustomerViewOrderTab
         * @instance
         */
        CustomerViewOrderTab.prototype.orderSerialNumber = 0;

        /**
         * Encodes the specified CustomerViewOrderTab message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrderTab.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewOrderTab
         * @static
         * @param {PlatformAdminApi.ICustomerViewOrderTab} message CustomerViewOrderTab message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewOrderTab.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.orderSerialNumber);
            return writer;
        };

        /**
         * Decodes a CustomerViewOrderTab message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewOrderTab
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewOrderTab} CustomerViewOrderTab
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewOrderTab.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrderTab();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.orderSerialNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewOrderTab;
    })();

    PlatformAdminApi.OrderViewPaymentInformation = (function() {

        /**
         * Properties of an OrderViewPaymentInformation.
         * @memberof PlatformAdminApi
         * @interface IOrderViewPaymentInformation
         * @property {boolean|null} [isCash] OrderViewPaymentInformation isCash
         * @property {boolean|null} [isPaymentLink] OrderViewPaymentInformation isPaymentLink
         * @property {boolean|null} [isContractorLinkedCard] OrderViewPaymentInformation isContractorLinkedCard
         * @property {boolean|null} [isInstallmentPlan] OrderViewPaymentInformation isInstallmentPlan
         * @property {string|null} [selectedCardId] OrderViewPaymentInformation selectedCardId
         * @property {PlatformAdminApi.PaymentType|null} [paymentType] OrderViewPaymentInformation paymentType
         * @property {string|null} [paymentTypeName] OrderViewPaymentInformation paymentTypeName
         */

        /**
         * Constructs a new OrderViewPaymentInformation.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderViewPaymentInformation.
         * @implements IOrderViewPaymentInformation
         * @constructor
         * @param {PlatformAdminApi.IOrderViewPaymentInformation=} [properties] Properties to set
         */
        function OrderViewPaymentInformation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderViewPaymentInformation isCash.
         * @member {boolean} isCash
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @instance
         */
        OrderViewPaymentInformation.prototype.isCash = false;

        /**
         * OrderViewPaymentInformation isPaymentLink.
         * @member {boolean} isPaymentLink
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @instance
         */
        OrderViewPaymentInformation.prototype.isPaymentLink = false;

        /**
         * OrderViewPaymentInformation isContractorLinkedCard.
         * @member {boolean} isContractorLinkedCard
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @instance
         */
        OrderViewPaymentInformation.prototype.isContractorLinkedCard = false;

        /**
         * OrderViewPaymentInformation isInstallmentPlan.
         * @member {boolean} isInstallmentPlan
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @instance
         */
        OrderViewPaymentInformation.prototype.isInstallmentPlan = false;

        /**
         * OrderViewPaymentInformation selectedCardId.
         * @member {string} selectedCardId
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @instance
         */
        OrderViewPaymentInformation.prototype.selectedCardId = "";

        /**
         * OrderViewPaymentInformation paymentType.
         * @member {PlatformAdminApi.PaymentType} paymentType
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @instance
         */
        OrderViewPaymentInformation.prototype.paymentType = 0;

        /**
         * OrderViewPaymentInformation paymentTypeName.
         * @member {string} paymentTypeName
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @instance
         */
        OrderViewPaymentInformation.prototype.paymentTypeName = "";

        /**
         * Encodes the specified OrderViewPaymentInformation message. Does not implicitly {@link PlatformAdminApi.OrderViewPaymentInformation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @static
         * @param {PlatformAdminApi.IOrderViewPaymentInformation} message OrderViewPaymentInformation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderViewPaymentInformation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isCash != null && Object.hasOwnProperty.call(message, "isCash"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isCash);
            if (message.isContractorLinkedCard != null && Object.hasOwnProperty.call(message, "isContractorLinkedCard"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isContractorLinkedCard);
            if (message.isInstallmentPlan != null && Object.hasOwnProperty.call(message, "isInstallmentPlan"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isInstallmentPlan);
            if (message.selectedCardId != null && Object.hasOwnProperty.call(message, "selectedCardId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.selectedCardId);
            if (message.isPaymentLink != null && Object.hasOwnProperty.call(message, "isPaymentLink"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isPaymentLink);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.paymentType);
            if (message.paymentTypeName != null && Object.hasOwnProperty.call(message, "paymentTypeName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.paymentTypeName);
            return writer;
        };

        /**
         * Decodes an OrderViewPaymentInformation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderViewPaymentInformation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderViewPaymentInformation} OrderViewPaymentInformation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderViewPaymentInformation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderViewPaymentInformation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.isCash = reader.bool();
                    break;
                case 5:
                    message.isPaymentLink = reader.bool();
                    break;
                case 2:
                    message.isContractorLinkedCard = reader.bool();
                    break;
                case 3:
                    message.isInstallmentPlan = reader.bool();
                    break;
                case 4:
                    message.selectedCardId = reader.string();
                    break;
                case 6:
                    message.paymentType = reader.int32();
                    break;
                case 7:
                    message.paymentTypeName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderViewPaymentInformation;
    })();

    PlatformAdminApi.OrderViewCustomerPaymentCards = (function() {

        /**
         * Properties of an OrderViewCustomerPaymentCards.
         * @memberof PlatformAdminApi
         * @interface IOrderViewCustomerPaymentCards
         * @property {string|null} [id] OrderViewCustomerPaymentCards id
         * @property {string|null} [cardType] OrderViewCustomerPaymentCards cardType
         * @property {string|null} [cardNumber] OrderViewCustomerPaymentCards cardNumber
         * @property {string|null} [cardValidity] OrderViewCustomerPaymentCards cardValidity
         */

        /**
         * Constructs a new OrderViewCustomerPaymentCards.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderViewCustomerPaymentCards.
         * @implements IOrderViewCustomerPaymentCards
         * @constructor
         * @param {PlatformAdminApi.IOrderViewCustomerPaymentCards=} [properties] Properties to set
         */
        function OrderViewCustomerPaymentCards(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderViewCustomerPaymentCards id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderViewCustomerPaymentCards
         * @instance
         */
        OrderViewCustomerPaymentCards.prototype.id = "";

        /**
         * OrderViewCustomerPaymentCards cardType.
         * @member {string} cardType
         * @memberof PlatformAdminApi.OrderViewCustomerPaymentCards
         * @instance
         */
        OrderViewCustomerPaymentCards.prototype.cardType = "";

        /**
         * OrderViewCustomerPaymentCards cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformAdminApi.OrderViewCustomerPaymentCards
         * @instance
         */
        OrderViewCustomerPaymentCards.prototype.cardNumber = "";

        /**
         * OrderViewCustomerPaymentCards cardValidity.
         * @member {string} cardValidity
         * @memberof PlatformAdminApi.OrderViewCustomerPaymentCards
         * @instance
         */
        OrderViewCustomerPaymentCards.prototype.cardValidity = "";

        /**
         * Encodes the specified OrderViewCustomerPaymentCards message. Does not implicitly {@link PlatformAdminApi.OrderViewCustomerPaymentCards.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderViewCustomerPaymentCards
         * @static
         * @param {PlatformAdminApi.IOrderViewCustomerPaymentCards} message OrderViewCustomerPaymentCards message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderViewCustomerPaymentCards.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.cardType != null && Object.hasOwnProperty.call(message, "cardType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardType);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cardNumber);
            if (message.cardValidity != null && Object.hasOwnProperty.call(message, "cardValidity"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.cardValidity);
            return writer;
        };

        /**
         * Decodes an OrderViewCustomerPaymentCards message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderViewCustomerPaymentCards
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderViewCustomerPaymentCards} OrderViewCustomerPaymentCards
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderViewCustomerPaymentCards.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderViewCustomerPaymentCards();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.cardType = reader.string();
                    break;
                case 3:
                    message.cardNumber = reader.string();
                    break;
                case 4:
                    message.cardValidity = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderViewCustomerPaymentCards;
    })();

    PlatformAdminApi.CustomerViewOrderElement = (function() {

        /**
         * Properties of a CustomerViewOrderElement.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewOrderElement
         * @property {string|null} [id] CustomerViewOrderElement id
         * @property {string|null} [slug] CustomerViewOrderElement slug
         * @property {string|null} [title] CustomerViewOrderElement title
         */

        /**
         * Constructs a new CustomerViewOrderElement.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewOrderElement.
         * @implements ICustomerViewOrderElement
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewOrderElement=} [properties] Properties to set
         */
        function CustomerViewOrderElement(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewOrderElement id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerViewOrderElement
         * @instance
         */
        CustomerViewOrderElement.prototype.id = "";

        /**
         * CustomerViewOrderElement slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.CustomerViewOrderElement
         * @instance
         */
        CustomerViewOrderElement.prototype.slug = "";

        /**
         * CustomerViewOrderElement title.
         * @member {string} title
         * @memberof PlatformAdminApi.CustomerViewOrderElement
         * @instance
         */
        CustomerViewOrderElement.prototype.title = "";

        /**
         * Encodes the specified CustomerViewOrderElement message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrderElement.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewOrderElement
         * @static
         * @param {PlatformAdminApi.ICustomerViewOrderElement} message CustomerViewOrderElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewOrderElement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slug);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            return writer;
        };

        /**
         * Decodes a CustomerViewOrderElement message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewOrderElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewOrderElement} CustomerViewOrderElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewOrderElement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrderElement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewOrderElement;
    })();

    PlatformAdminApi.CustomerViewStrategyItem = (function() {

        /**
         * Properties of a CustomerViewStrategyItem.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewStrategyItem
         * @property {string|null} [label] CustomerViewStrategyItem label
         * @property {string|null} [name] CustomerViewStrategyItem name
         */

        /**
         * Constructs a new CustomerViewStrategyItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewStrategyItem.
         * @implements ICustomerViewStrategyItem
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewStrategyItem=} [properties] Properties to set
         */
        function CustomerViewStrategyItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewStrategyItem label.
         * @member {string} label
         * @memberof PlatformAdminApi.CustomerViewStrategyItem
         * @instance
         */
        CustomerViewStrategyItem.prototype.label = "";

        /**
         * CustomerViewStrategyItem name.
         * @member {string} name
         * @memberof PlatformAdminApi.CustomerViewStrategyItem
         * @instance
         */
        CustomerViewStrategyItem.prototype.name = "";

        /**
         * Encodes the specified CustomerViewStrategyItem message. Does not implicitly {@link PlatformAdminApi.CustomerViewStrategyItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewStrategyItem
         * @static
         * @param {PlatformAdminApi.ICustomerViewStrategyItem} message CustomerViewStrategyItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewStrategyItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes a CustomerViewStrategyItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewStrategyItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewStrategyItem} CustomerViewStrategyItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewStrategyItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewStrategyItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewStrategyItem;
    })();

    PlatformAdminApi.TagWithOptions = (function() {

        /**
         * Properties of a TagWithOptions.
         * @memberof PlatformAdminApi
         * @interface ITagWithOptions
         * @property {string|null} [id] TagWithOptions id
         * @property {boolean|null} [isDeleted] TagWithOptions isDeleted
         * @property {string|null} [name] TagWithOptions name
         * @property {string|null} [title] TagWithOptions title
         * @property {Array.<PlatformAdminApi.ICoreBasePriceOption>|null} [options] TagWithOptions options
         */

        /**
         * Constructs a new TagWithOptions.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TagWithOptions.
         * @implements ITagWithOptions
         * @constructor
         * @param {PlatformAdminApi.ITagWithOptions=} [properties] Properties to set
         */
        function TagWithOptions(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagWithOptions id.
         * @member {string} id
         * @memberof PlatformAdminApi.TagWithOptions
         * @instance
         */
        TagWithOptions.prototype.id = "";

        /**
         * TagWithOptions isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.TagWithOptions
         * @instance
         */
        TagWithOptions.prototype.isDeleted = false;

        /**
         * TagWithOptions name.
         * @member {string} name
         * @memberof PlatformAdminApi.TagWithOptions
         * @instance
         */
        TagWithOptions.prototype.name = "";

        /**
         * TagWithOptions title.
         * @member {string} title
         * @memberof PlatformAdminApi.TagWithOptions
         * @instance
         */
        TagWithOptions.prototype.title = "";

        /**
         * TagWithOptions options.
         * @member {Array.<PlatformAdminApi.ICoreBasePriceOption>} options
         * @memberof PlatformAdminApi.TagWithOptions
         * @instance
         */
        TagWithOptions.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified TagWithOptions message. Does not implicitly {@link PlatformAdminApi.TagWithOptions.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TagWithOptions
         * @static
         * @param {PlatformAdminApi.ITagWithOptions} message TagWithOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagWithOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.CoreBasePriceOption.encode(message.options[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TagWithOptions message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TagWithOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TagWithOptions} TagWithOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagWithOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TagWithOptions();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.title = reader.string();
                    break;
                case 5:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.CoreBasePriceOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TagWithOptions;
    })();

    PlatformAdminApi.CustomerViewOrderAdditionalOptions = (function() {

        /**
         * Properties of a CustomerViewOrderAdditionalOptions.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewOrderAdditionalOptions
         * @property {string|null} [orderId] CustomerViewOrderAdditionalOptions orderId
         * @property {string|null} [duration] CustomerViewOrderAdditionalOptions duration
         * @property {string|null} [basePrice] CustomerViewOrderAdditionalOptions basePrice
         * @property {Array.<PlatformAdminApi.ITagWithOptions>|null} [tags] CustomerViewOrderAdditionalOptions tags
         */

        /**
         * Constructs a new CustomerViewOrderAdditionalOptions.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewOrderAdditionalOptions.
         * @implements ICustomerViewOrderAdditionalOptions
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewOrderAdditionalOptions=} [properties] Properties to set
         */
        function CustomerViewOrderAdditionalOptions(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewOrderAdditionalOptions orderId.
         * @member {string} orderId
         * @memberof PlatformAdminApi.CustomerViewOrderAdditionalOptions
         * @instance
         */
        CustomerViewOrderAdditionalOptions.prototype.orderId = "";

        /**
         * CustomerViewOrderAdditionalOptions duration.
         * @member {string} duration
         * @memberof PlatformAdminApi.CustomerViewOrderAdditionalOptions
         * @instance
         */
        CustomerViewOrderAdditionalOptions.prototype.duration = "";

        /**
         * CustomerViewOrderAdditionalOptions basePrice.
         * @member {string} basePrice
         * @memberof PlatformAdminApi.CustomerViewOrderAdditionalOptions
         * @instance
         */
        CustomerViewOrderAdditionalOptions.prototype.basePrice = "";

        /**
         * CustomerViewOrderAdditionalOptions tags.
         * @member {Array.<PlatformAdminApi.ITagWithOptions>} tags
         * @memberof PlatformAdminApi.CustomerViewOrderAdditionalOptions
         * @instance
         */
        CustomerViewOrderAdditionalOptions.prototype.tags = $util.emptyArray;

        /**
         * Encodes the specified CustomerViewOrderAdditionalOptions message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrderAdditionalOptions.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewOrderAdditionalOptions
         * @static
         * @param {PlatformAdminApi.ICustomerViewOrderAdditionalOptions} message CustomerViewOrderAdditionalOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewOrderAdditionalOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.duration);
            if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.basePrice);
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.PlatformAdminApi.TagWithOptions.encode(message.tags[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerViewOrderAdditionalOptions message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewOrderAdditionalOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewOrderAdditionalOptions} CustomerViewOrderAdditionalOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewOrderAdditionalOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrderAdditionalOptions();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    message.duration = reader.string();
                    break;
                case 3:
                    message.basePrice = reader.string();
                    break;
                case 4:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.PlatformAdminApi.TagWithOptions.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewOrderAdditionalOptions;
    })();

    PlatformAdminApi.CustomerOrderDayOptionItem = (function() {

        /**
         * Properties of a CustomerOrderDayOptionItem.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderDayOptionItem
         * @property {string|null} [dayLabel] CustomerOrderDayOptionItem dayLabel
         * @property {string|null} [dayValue] CustomerOrderDayOptionItem dayValue
         * @property {string|null} [dayOfWeek] CustomerOrderDayOptionItem dayOfWeek
         * @property {boolean|null} [disabled] CustomerOrderDayOptionItem disabled
         * @property {Array.<PlatformAdminApi.CustomerOrderDayOptionItem.ICustomerOrderTimeOptionItem>|null} [timeSlots] CustomerOrderDayOptionItem timeSlots
         */

        /**
         * Constructs a new CustomerOrderDayOptionItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderDayOptionItem.
         * @implements ICustomerOrderDayOptionItem
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderDayOptionItem=} [properties] Properties to set
         */
        function CustomerOrderDayOptionItem(properties) {
            this.timeSlots = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderDayOptionItem dayLabel.
         * @member {string} dayLabel
         * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
         * @instance
         */
        CustomerOrderDayOptionItem.prototype.dayLabel = "";

        /**
         * CustomerOrderDayOptionItem dayValue.
         * @member {string} dayValue
         * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
         * @instance
         */
        CustomerOrderDayOptionItem.prototype.dayValue = "";

        /**
         * CustomerOrderDayOptionItem dayOfWeek.
         * @member {string} dayOfWeek
         * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
         * @instance
         */
        CustomerOrderDayOptionItem.prototype.dayOfWeek = "";

        /**
         * CustomerOrderDayOptionItem disabled.
         * @member {boolean} disabled
         * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
         * @instance
         */
        CustomerOrderDayOptionItem.prototype.disabled = false;

        /**
         * CustomerOrderDayOptionItem timeSlots.
         * @member {Array.<PlatformAdminApi.CustomerOrderDayOptionItem.ICustomerOrderTimeOptionItem>} timeSlots
         * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
         * @instance
         */
        CustomerOrderDayOptionItem.prototype.timeSlots = $util.emptyArray;

        /**
         * Encodes the specified CustomerOrderDayOptionItem message. Does not implicitly {@link PlatformAdminApi.CustomerOrderDayOptionItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
         * @static
         * @param {PlatformAdminApi.ICustomerOrderDayOptionItem} message CustomerOrderDayOptionItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderDayOptionItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dayLabel != null && Object.hasOwnProperty.call(message, "dayLabel"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dayLabel);
            if (message.dayValue != null && Object.hasOwnProperty.call(message, "dayValue"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dayValue);
            if (message.dayOfWeek != null && Object.hasOwnProperty.call(message, "dayOfWeek"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.dayOfWeek);
            if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.disabled);
            if (message.timeSlots != null && message.timeSlots.length)
                for (var i = 0; i < message.timeSlots.length; ++i)
                    $root.PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem.encode(message.timeSlots[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerOrderDayOptionItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderDayOptionItem} CustomerOrderDayOptionItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderDayOptionItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderDayOptionItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.dayLabel = reader.string();
                    break;
                case 2:
                    message.dayValue = reader.string();
                    break;
                case 3:
                    message.dayOfWeek = reader.string();
                    break;
                case 4:
                    message.disabled = reader.bool();
                    break;
                case 5:
                    if (!(message.timeSlots && message.timeSlots.length))
                        message.timeSlots = [];
                    message.timeSlots.push($root.PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem = (function() {

            /**
             * Properties of a CustomerOrderTimeOptionItem.
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
             * @interface ICustomerOrderTimeOptionItem
             * @property {string|null} [time] CustomerOrderTimeOptionItem time
             * @property {boolean|null} [disabled] CustomerOrderTimeOptionItem disabled
             * @property {string|null} [startAt] CustomerOrderTimeOptionItem startAt
             * @property {string|null} [endAt] CustomerOrderTimeOptionItem endAt
             */

            /**
             * Constructs a new CustomerOrderTimeOptionItem.
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem
             * @classdesc Represents a CustomerOrderTimeOptionItem.
             * @implements ICustomerOrderTimeOptionItem
             * @constructor
             * @param {PlatformAdminApi.CustomerOrderDayOptionItem.ICustomerOrderTimeOptionItem=} [properties] Properties to set
             */
            function CustomerOrderTimeOptionItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CustomerOrderTimeOptionItem time.
             * @member {string} time
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem
             * @instance
             */
            CustomerOrderTimeOptionItem.prototype.time = "";

            /**
             * CustomerOrderTimeOptionItem disabled.
             * @member {boolean} disabled
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem
             * @instance
             */
            CustomerOrderTimeOptionItem.prototype.disabled = false;

            /**
             * CustomerOrderTimeOptionItem startAt.
             * @member {string} startAt
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem
             * @instance
             */
            CustomerOrderTimeOptionItem.prototype.startAt = "";

            /**
             * CustomerOrderTimeOptionItem endAt.
             * @member {string} endAt
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem
             * @instance
             */
            CustomerOrderTimeOptionItem.prototype.endAt = "";

            /**
             * Encodes the specified CustomerOrderTimeOptionItem message. Does not implicitly {@link PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem
             * @static
             * @param {PlatformAdminApi.CustomerOrderDayOptionItem.ICustomerOrderTimeOptionItem} message CustomerOrderTimeOptionItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomerOrderTimeOptionItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.time);
                if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.disabled);
                if (message.startAt != null && Object.hasOwnProperty.call(message, "startAt"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.startAt);
                if (message.endAt != null && Object.hasOwnProperty.call(message, "endAt"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.endAt);
                return writer;
            };

            /**
             * Decodes a CustomerOrderTimeOptionItem message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem} CustomerOrderTimeOptionItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomerOrderTimeOptionItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderDayOptionItem.CustomerOrderTimeOptionItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.time = reader.string();
                        break;
                    case 2:
                        message.disabled = reader.bool();
                        break;
                    case 3:
                        message.startAt = reader.string();
                        break;
                    case 4:
                        message.endAt = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return CustomerOrderTimeOptionItem;
        })();

        return CustomerOrderDayOptionItem;
    })();

    PlatformAdminApi.CustomerViewOrderBaseOptions = (function() {

        /**
         * Properties of a CustomerViewOrderBaseOptions.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewOrderBaseOptions
         * @property {string|null} [orderId] CustomerViewOrderBaseOptions orderId
         * @property {Array.<PlatformAdminApi.ICoreBasePriceOption>|null} [options] CustomerViewOrderBaseOptions options
         */

        /**
         * Constructs a new CustomerViewOrderBaseOptions.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewOrderBaseOptions.
         * @implements ICustomerViewOrderBaseOptions
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewOrderBaseOptions=} [properties] Properties to set
         */
        function CustomerViewOrderBaseOptions(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewOrderBaseOptions orderId.
         * @member {string} orderId
         * @memberof PlatformAdminApi.CustomerViewOrderBaseOptions
         * @instance
         */
        CustomerViewOrderBaseOptions.prototype.orderId = "";

        /**
         * CustomerViewOrderBaseOptions options.
         * @member {Array.<PlatformAdminApi.ICoreBasePriceOption>} options
         * @memberof PlatformAdminApi.CustomerViewOrderBaseOptions
         * @instance
         */
        CustomerViewOrderBaseOptions.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified CustomerViewOrderBaseOptions message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrderBaseOptions.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewOrderBaseOptions
         * @static
         * @param {PlatformAdminApi.ICustomerViewOrderBaseOptions} message CustomerViewOrderBaseOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewOrderBaseOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformAdminApi.CoreBasePriceOption.encode(message.options[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CustomerViewOrderBaseOptions message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewOrderBaseOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewOrderBaseOptions} CustomerViewOrderBaseOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewOrderBaseOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrderBaseOptions();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 5:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformAdminApi.CoreBasePriceOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewOrderBaseOptions;
    })();

    PlatformAdminApi.CoreBaseOrderOption = (function() {

        /**
         * Properties of a CoreBaseOrderOption.
         * @memberof PlatformAdminApi
         * @interface ICoreBaseOrderOption
         * @property {string|null} [id] CoreBaseOrderOption id
         * @property {boolean|null} [isDeleted] CoreBaseOrderOption isDeleted
         * @property {PlatformAdminApi.ICoreBaseOption|null} [option] CoreBaseOrderOption option
         * @property {number|null} [value] CoreBaseOrderOption value
         * @property {number|null} [totalDuration] CoreBaseOrderOption totalDuration
         * @property {number|null} [totalPrice] CoreBaseOrderOption totalPrice
         * @property {number|null} [factor] CoreBaseOrderOption factor
         * @property {boolean|null} [isFree] CoreBaseOrderOption isFree
         * @property {string|null} [slug] CoreBaseOrderOption slug
         * @property {string|null} [totalDurationString] CoreBaseOrderOption totalDurationString
         */

        /**
         * Constructs a new CoreBaseOrderOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CoreBaseOrderOption.
         * @implements ICoreBaseOrderOption
         * @constructor
         * @param {PlatformAdminApi.ICoreBaseOrderOption=} [properties] Properties to set
         */
        function CoreBaseOrderOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoreBaseOrderOption id.
         * @member {string} id
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.id = "";

        /**
         * CoreBaseOrderOption isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.isDeleted = false;

        /**
         * CoreBaseOrderOption option.
         * @member {PlatformAdminApi.ICoreBaseOption|null|undefined} option
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.option = null;

        /**
         * CoreBaseOrderOption value.
         * @member {number} value
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.value = 0;

        /**
         * CoreBaseOrderOption totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.totalDuration = 0;

        /**
         * CoreBaseOrderOption totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.totalPrice = 0;

        /**
         * CoreBaseOrderOption factor.
         * @member {number} factor
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.factor = 0;

        /**
         * CoreBaseOrderOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.isFree = false;

        /**
         * CoreBaseOrderOption slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.slug = "";

        /**
         * CoreBaseOrderOption totalDurationString.
         * @member {string} totalDurationString
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @instance
         */
        CoreBaseOrderOption.prototype.totalDurationString = "";

        /**
         * Encodes the specified CoreBaseOrderOption message. Does not implicitly {@link PlatformAdminApi.CoreBaseOrderOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @static
         * @param {PlatformAdminApi.ICoreBaseOrderOption} message CoreBaseOrderOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoreBaseOrderOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.CoreBaseOption.encode(message.option, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.value);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.totalDuration);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.totalPrice);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.factor);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isFree);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.slug);
            if (message.totalDurationString != null && Object.hasOwnProperty.call(message, "totalDurationString"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.totalDurationString);
            return writer;
        };

        /**
         * Decodes a CoreBaseOrderOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CoreBaseOrderOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CoreBaseOrderOption} CoreBaseOrderOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoreBaseOrderOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBaseOrderOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 6:
                    message.option = $root.PlatformAdminApi.CoreBaseOption.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.value = reader.int32();
                    break;
                case 8:
                    message.totalDuration = reader.int32();
                    break;
                case 9:
                    message.totalPrice = reader.int32();
                    break;
                case 10:
                    message.factor = reader.int32();
                    break;
                case 11:
                    message.isFree = reader.bool();
                    break;
                case 12:
                    message.slug = reader.string();
                    break;
                case 14:
                    message.totalDurationString = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CoreBaseOrderOption;
    })();

    PlatformAdminApi.CoreBaseCart = (function() {

        /**
         * Properties of a CoreBaseCart.
         * @memberof PlatformAdminApi
         * @interface ICoreBaseCart
         * @property {string|null} [id] CoreBaseCart id
         * @property {string|null} [createdAt] CoreBaseCart createdAt
         * @property {Array.<PlatformAdminApi.ICoreBaseCartOrder>|null} [orders] CoreBaseCart orders
         */

        /**
         * Constructs a new CoreBaseCart.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CoreBaseCart.
         * @implements ICoreBaseCart
         * @constructor
         * @param {PlatformAdminApi.ICoreBaseCart=} [properties] Properties to set
         */
        function CoreBaseCart(properties) {
            this.orders = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoreBaseCart id.
         * @member {string} id
         * @memberof PlatformAdminApi.CoreBaseCart
         * @instance
         */
        CoreBaseCart.prototype.id = "";

        /**
         * CoreBaseCart createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.CoreBaseCart
         * @instance
         */
        CoreBaseCart.prototype.createdAt = "";

        /**
         * CoreBaseCart orders.
         * @member {Array.<PlatformAdminApi.ICoreBaseCartOrder>} orders
         * @memberof PlatformAdminApi.CoreBaseCart
         * @instance
         */
        CoreBaseCart.prototype.orders = $util.emptyArray;

        /**
         * Encodes the specified CoreBaseCart message. Does not implicitly {@link PlatformAdminApi.CoreBaseCart.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CoreBaseCart
         * @static
         * @param {PlatformAdminApi.ICoreBaseCart} message CoreBaseCart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoreBaseCart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.orders != null && message.orders.length)
                for (var i = 0; i < message.orders.length; ++i)
                    $root.PlatformAdminApi.CoreBaseCartOrder.encode(message.orders[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CoreBaseCart message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CoreBaseCart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CoreBaseCart} CoreBaseCart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoreBaseCart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBaseCart();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    if (!(message.orders && message.orders.length))
                        message.orders = [];
                    message.orders.push($root.PlatformAdminApi.CoreBaseCartOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CoreBaseCart;
    })();

    PlatformAdminApi.CoreBaseCartOrder = (function() {

        /**
         * Properties of a CoreBaseCartOrder.
         * @memberof PlatformAdminApi
         * @interface ICoreBaseCartOrder
         * @property {string|null} [id] CoreBaseCartOrder id
         * @property {PlatformAdminApi.ICoreBaseCartElement|null} [element] CoreBaseCartOrder element
         * @property {number|null} [serialNumber] CoreBaseCartOrder serialNumber
         */

        /**
         * Constructs a new CoreBaseCartOrder.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CoreBaseCartOrder.
         * @implements ICoreBaseCartOrder
         * @constructor
         * @param {PlatformAdminApi.ICoreBaseCartOrder=} [properties] Properties to set
         */
        function CoreBaseCartOrder(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoreBaseCartOrder id.
         * @member {string} id
         * @memberof PlatformAdminApi.CoreBaseCartOrder
         * @instance
         */
        CoreBaseCartOrder.prototype.id = "";

        /**
         * CoreBaseCartOrder element.
         * @member {PlatformAdminApi.ICoreBaseCartElement|null|undefined} element
         * @memberof PlatformAdminApi.CoreBaseCartOrder
         * @instance
         */
        CoreBaseCartOrder.prototype.element = null;

        /**
         * CoreBaseCartOrder serialNumber.
         * @member {number} serialNumber
         * @memberof PlatformAdminApi.CoreBaseCartOrder
         * @instance
         */
        CoreBaseCartOrder.prototype.serialNumber = 0;

        /**
         * Encodes the specified CoreBaseCartOrder message. Does not implicitly {@link PlatformAdminApi.CoreBaseCartOrder.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CoreBaseCartOrder
         * @static
         * @param {PlatformAdminApi.ICoreBaseCartOrder} message CoreBaseCartOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoreBaseCartOrder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.element != null && Object.hasOwnProperty.call(message, "element"))
                $root.PlatformAdminApi.CoreBaseCartElement.encode(message.element, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.serialNumber != null && Object.hasOwnProperty.call(message, "serialNumber"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.serialNumber);
            return writer;
        };

        /**
         * Decodes a CoreBaseCartOrder message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CoreBaseCartOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CoreBaseCartOrder} CoreBaseCartOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoreBaseCartOrder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBaseCartOrder();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.element = $root.PlatformAdminApi.CoreBaseCartElement.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.serialNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CoreBaseCartOrder;
    })();

    PlatformAdminApi.CoreBaseCartElement = (function() {

        /**
         * Properties of a CoreBaseCartElement.
         * @memberof PlatformAdminApi
         * @interface ICoreBaseCartElement
         * @property {string|null} [id] CoreBaseCartElement id
         * @property {string|null} [title] CoreBaseCartElement title
         */

        /**
         * Constructs a new CoreBaseCartElement.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CoreBaseCartElement.
         * @implements ICoreBaseCartElement
         * @constructor
         * @param {PlatformAdminApi.ICoreBaseCartElement=} [properties] Properties to set
         */
        function CoreBaseCartElement(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoreBaseCartElement id.
         * @member {string} id
         * @memberof PlatformAdminApi.CoreBaseCartElement
         * @instance
         */
        CoreBaseCartElement.prototype.id = "";

        /**
         * CoreBaseCartElement title.
         * @member {string} title
         * @memberof PlatformAdminApi.CoreBaseCartElement
         * @instance
         */
        CoreBaseCartElement.prototype.title = "";

        /**
         * Encodes the specified CoreBaseCartElement message. Does not implicitly {@link PlatformAdminApi.CoreBaseCartElement.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CoreBaseCartElement
         * @static
         * @param {PlatformAdminApi.ICoreBaseCartElement} message CoreBaseCartElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoreBaseCartElement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes a CoreBaseCartElement message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CoreBaseCartElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CoreBaseCartElement} CoreBaseCartElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoreBaseCartElement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBaseCartElement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CoreBaseCartElement;
    })();

    PlatformAdminApi.OrderContractorInformation = (function() {

        /**
         * Properties of an OrderContractorInformation.
         * @memberof PlatformAdminApi
         * @interface IOrderContractorInformation
         * @property {string|null} [id] OrderContractorInformation id
         * @property {string|null} [name] OrderContractorInformation name
         */

        /**
         * Constructs a new OrderContractorInformation.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderContractorInformation.
         * @implements IOrderContractorInformation
         * @constructor
         * @param {PlatformAdminApi.IOrderContractorInformation=} [properties] Properties to set
         */
        function OrderContractorInformation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderContractorInformation id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderContractorInformation
         * @instance
         */
        OrderContractorInformation.prototype.id = "";

        /**
         * OrderContractorInformation name.
         * @member {string} name
         * @memberof PlatformAdminApi.OrderContractorInformation
         * @instance
         */
        OrderContractorInformation.prototype.name = "";

        /**
         * Encodes the specified OrderContractorInformation message. Does not implicitly {@link PlatformAdminApi.OrderContractorInformation.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderContractorInformation
         * @static
         * @param {PlatformAdminApi.IOrderContractorInformation} message OrderContractorInformation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderContractorInformation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Decodes an OrderContractorInformation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderContractorInformation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderContractorInformation} OrderContractorInformation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderContractorInformation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderContractorInformation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderContractorInformation;
    })();

    PlatformAdminApi.OrderStrategyAssignment = (function() {

        /**
         * Properties of an OrderStrategyAssignment.
         * @memberof PlatformAdminApi
         * @interface IOrderStrategyAssignment
         * @property {number|null} [count] OrderStrategyAssignment count
         * @property {string|null} [positionId] OrderStrategyAssignment positionId
         * @property {Array.<PlatformAdminApi.IOrderSelectedContractor>|null} [contractors] OrderStrategyAssignment contractors
         * @property {string|null} [label] OrderStrategyAssignment label
         * @property {string|null} [id] OrderStrategyAssignment id
         * @property {string|null} [status] OrderStrategyAssignment status
         */

        /**
         * Constructs a new OrderStrategyAssignment.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderStrategyAssignment.
         * @implements IOrderStrategyAssignment
         * @constructor
         * @param {PlatformAdminApi.IOrderStrategyAssignment=} [properties] Properties to set
         */
        function OrderStrategyAssignment(properties) {
            this.contractors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderStrategyAssignment count.
         * @member {number} count
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @instance
         */
        OrderStrategyAssignment.prototype.count = 0;

        /**
         * OrderStrategyAssignment positionId.
         * @member {string} positionId
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @instance
         */
        OrderStrategyAssignment.prototype.positionId = "";

        /**
         * OrderStrategyAssignment contractors.
         * @member {Array.<PlatformAdminApi.IOrderSelectedContractor>} contractors
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @instance
         */
        OrderStrategyAssignment.prototype.contractors = $util.emptyArray;

        /**
         * OrderStrategyAssignment label.
         * @member {string} label
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @instance
         */
        OrderStrategyAssignment.prototype.label = "";

        /**
         * OrderStrategyAssignment id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @instance
         */
        OrderStrategyAssignment.prototype.id = "";

        /**
         * OrderStrategyAssignment status.
         * @member {string} status
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @instance
         */
        OrderStrategyAssignment.prototype.status = "";

        /**
         * Encodes the specified OrderStrategyAssignment message. Does not implicitly {@link PlatformAdminApi.OrderStrategyAssignment.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @static
         * @param {PlatformAdminApi.IOrderStrategyAssignment} message OrderStrategyAssignment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderStrategyAssignment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
            if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.positionId);
            if (message.contractors != null && message.contractors.length)
                for (var i = 0; i < message.contractors.length; ++i)
                    $root.PlatformAdminApi.OrderSelectedContractor.encode(message.contractors[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.label);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.status);
            return writer;
        };

        /**
         * Decodes an OrderStrategyAssignment message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderStrategyAssignment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderStrategyAssignment} OrderStrategyAssignment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderStrategyAssignment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderStrategyAssignment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.count = reader.int32();
                    break;
                case 2:
                    message.positionId = reader.string();
                    break;
                case 3:
                    if (!(message.contractors && message.contractors.length))
                        message.contractors = [];
                    message.contractors.push($root.PlatformAdminApi.OrderSelectedContractor.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.label = reader.string();
                    break;
                case 5:
                    message.id = reader.string();
                    break;
                case 6:
                    message.status = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderStrategyAssignment;
    })();

    PlatformAdminApi.OrderSelectedContractor = (function() {

        /**
         * Properties of an OrderSelectedContractor.
         * @memberof PlatformAdminApi
         * @interface IOrderSelectedContractor
         * @property {string|null} [id] OrderSelectedContractor id
         * @property {string|null} [name] OrderSelectedContractor name
         * @property {string|null} [phone] OrderSelectedContractor phone
         */

        /**
         * Constructs a new OrderSelectedContractor.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderSelectedContractor.
         * @implements IOrderSelectedContractor
         * @constructor
         * @param {PlatformAdminApi.IOrderSelectedContractor=} [properties] Properties to set
         */
        function OrderSelectedContractor(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderSelectedContractor id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderSelectedContractor
         * @instance
         */
        OrderSelectedContractor.prototype.id = "";

        /**
         * OrderSelectedContractor name.
         * @member {string} name
         * @memberof PlatformAdminApi.OrderSelectedContractor
         * @instance
         */
        OrderSelectedContractor.prototype.name = "";

        /**
         * OrderSelectedContractor phone.
         * @member {string} phone
         * @memberof PlatformAdminApi.OrderSelectedContractor
         * @instance
         */
        OrderSelectedContractor.prototype.phone = "";

        /**
         * Encodes the specified OrderSelectedContractor message. Does not implicitly {@link PlatformAdminApi.OrderSelectedContractor.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderSelectedContractor
         * @static
         * @param {PlatformAdminApi.IOrderSelectedContractor} message OrderSelectedContractor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderSelectedContractor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
            return writer;
        };

        /**
         * Decodes an OrderSelectedContractor message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderSelectedContractor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderSelectedContractor} OrderSelectedContractor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderSelectedContractor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderSelectedContractor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.phone = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderSelectedContractor;
    })();

    PlatformAdminApi.OrderBySerialNumberRequest = (function() {

        /**
         * Properties of an OrderBySerialNumberRequest.
         * @memberof PlatformAdminApi
         * @interface IOrderBySerialNumberRequest
         * @property {string|null} [serialNumber] OrderBySerialNumberRequest serialNumber
         * @property {boolean|null} [isExternal] OrderBySerialNumberRequest isExternal
         */

        /**
         * Constructs a new OrderBySerialNumberRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderBySerialNumberRequest.
         * @implements IOrderBySerialNumberRequest
         * @constructor
         * @param {PlatformAdminApi.IOrderBySerialNumberRequest=} [properties] Properties to set
         */
        function OrderBySerialNumberRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderBySerialNumberRequest serialNumber.
         * @member {string} serialNumber
         * @memberof PlatformAdminApi.OrderBySerialNumberRequest
         * @instance
         */
        OrderBySerialNumberRequest.prototype.serialNumber = "";

        /**
         * OrderBySerialNumberRequest isExternal.
         * @member {boolean} isExternal
         * @memberof PlatformAdminApi.OrderBySerialNumberRequest
         * @instance
         */
        OrderBySerialNumberRequest.prototype.isExternal = false;

        /**
         * Encodes the specified OrderBySerialNumberRequest message. Does not implicitly {@link PlatformAdminApi.OrderBySerialNumberRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderBySerialNumberRequest
         * @static
         * @param {PlatformAdminApi.IOrderBySerialNumberRequest} message OrderBySerialNumberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderBySerialNumberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.serialNumber != null && Object.hasOwnProperty.call(message, "serialNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.serialNumber);
            if (message.isExternal != null && Object.hasOwnProperty.call(message, "isExternal"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExternal);
            return writer;
        };

        /**
         * Decodes an OrderBySerialNumberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderBySerialNumberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderBySerialNumberRequest} OrderBySerialNumberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderBySerialNumberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderBySerialNumberRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.serialNumber = reader.string();
                    break;
                case 2:
                    message.isExternal = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderBySerialNumberRequest;
    })();

    PlatformAdminApi.CustomerOrderAction = (function() {

        /**
         * Properties of a CustomerOrderAction.
         * @memberof PlatformAdminApi
         * @interface ICustomerOrderAction
         * @property {string|null} [title] CustomerOrderAction title
         * @property {string|null} [slug] CustomerOrderAction slug
         * @property {string|null} [description] CustomerOrderAction description
         */

        /**
         * Constructs a new CustomerOrderAction.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerOrderAction.
         * @implements ICustomerOrderAction
         * @constructor
         * @param {PlatformAdminApi.ICustomerOrderAction=} [properties] Properties to set
         */
        function CustomerOrderAction(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerOrderAction title.
         * @member {string} title
         * @memberof PlatformAdminApi.CustomerOrderAction
         * @instance
         */
        CustomerOrderAction.prototype.title = "";

        /**
         * CustomerOrderAction slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.CustomerOrderAction
         * @instance
         */
        CustomerOrderAction.prototype.slug = "";

        /**
         * CustomerOrderAction description.
         * @member {string} description
         * @memberof PlatformAdminApi.CustomerOrderAction
         * @instance
         */
        CustomerOrderAction.prototype.description = "";

        /**
         * Encodes the specified CustomerOrderAction message. Does not implicitly {@link PlatformAdminApi.CustomerOrderAction.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerOrderAction
         * @static
         * @param {PlatformAdminApi.ICustomerOrderAction} message CustomerOrderAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerOrderAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slug);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            return writer;
        };

        /**
         * Decodes a CustomerOrderAction message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerOrderAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerOrderAction} CustomerOrderAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerOrderAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerOrderAction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerOrderAction;
    })();

    PlatformAdminApi.OrderCreator = (function() {

        /**
         * Properties of an OrderCreator.
         * @memberof PlatformAdminApi
         * @interface IOrderCreator
         * @property {string|null} [id] OrderCreator id
         * @property {string|null} [name] OrderCreator name
         * @property {boolean|null} [isSelfEmployed] OrderCreator isSelfEmployed
         * @property {PlatformAdminApi.USER_TYPE|null} [userType] OrderCreator userType
         */

        /**
         * Constructs a new OrderCreator.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderCreator.
         * @implements IOrderCreator
         * @constructor
         * @param {PlatformAdminApi.IOrderCreator=} [properties] Properties to set
         */
        function OrderCreator(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderCreator id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderCreator
         * @instance
         */
        OrderCreator.prototype.id = "";

        /**
         * OrderCreator name.
         * @member {string} name
         * @memberof PlatformAdminApi.OrderCreator
         * @instance
         */
        OrderCreator.prototype.name = "";

        /**
         * OrderCreator isSelfEmployed.
         * @member {boolean} isSelfEmployed
         * @memberof PlatformAdminApi.OrderCreator
         * @instance
         */
        OrderCreator.prototype.isSelfEmployed = false;

        /**
         * OrderCreator userType.
         * @member {PlatformAdminApi.USER_TYPE} userType
         * @memberof PlatformAdminApi.OrderCreator
         * @instance
         */
        OrderCreator.prototype.userType = 0;

        /**
         * Encodes the specified OrderCreator message. Does not implicitly {@link PlatformAdminApi.OrderCreator.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderCreator
         * @static
         * @param {PlatformAdminApi.IOrderCreator} message OrderCreator message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderCreator.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.isSelfEmployed != null && Object.hasOwnProperty.call(message, "isSelfEmployed"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isSelfEmployed);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.userType);
            return writer;
        };

        /**
         * Decodes an OrderCreator message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderCreator
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderCreator} OrderCreator
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderCreator.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderCreator();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.isSelfEmployed = reader.bool();
                    break;
                case 4:
                    message.userType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderCreator;
    })();

    PlatformAdminApi.OrderElementResponse = (function() {

        /**
         * Properties of an OrderElementResponse.
         * @memberof PlatformAdminApi
         * @interface IOrderElementResponse
         * @property {Array.<PlatformAdminApi.IOrderElement>|null} [data] OrderElementResponse data
         */

        /**
         * Constructs a new OrderElementResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderElementResponse.
         * @implements IOrderElementResponse
         * @constructor
         * @param {PlatformAdminApi.IOrderElementResponse=} [properties] Properties to set
         */
        function OrderElementResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderElementResponse data.
         * @member {Array.<PlatformAdminApi.IOrderElement>} data
         * @memberof PlatformAdminApi.OrderElementResponse
         * @instance
         */
        OrderElementResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified OrderElementResponse message. Does not implicitly {@link PlatformAdminApi.OrderElementResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderElementResponse
         * @static
         * @param {PlatformAdminApi.IOrderElementResponse} message OrderElementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderElementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.OrderElement.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderElementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderElementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderElementResponse} OrderElementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderElementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderElementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.OrderElement.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderElementResponse;
    })();

    PlatformAdminApi.OrderElement = (function() {

        /**
         * Properties of an OrderElement.
         * @memberof PlatformAdminApi
         * @interface IOrderElement
         * @property {string|null} [id] OrderElement id
         * @property {string|null} [title] OrderElement title
         */

        /**
         * Constructs a new OrderElement.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderElement.
         * @implements IOrderElement
         * @constructor
         * @param {PlatformAdminApi.IOrderElement=} [properties] Properties to set
         */
        function OrderElement(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderElement id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderElement
         * @instance
         */
        OrderElement.prototype.id = "";

        /**
         * OrderElement title.
         * @member {string} title
         * @memberof PlatformAdminApi.OrderElement
         * @instance
         */
        OrderElement.prototype.title = "";

        /**
         * Encodes the specified OrderElement message. Does not implicitly {@link PlatformAdminApi.OrderElement.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderElement
         * @static
         * @param {PlatformAdminApi.IOrderElement} message OrderElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderElement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes an OrderElement message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderElement} OrderElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderElement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderElement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderElement;
    })();

    PlatformAdminApi.OrderElementOptionsResponse = (function() {

        /**
         * Properties of an OrderElementOptionsResponse.
         * @memberof PlatformAdminApi
         * @interface IOrderElementOptionsResponse
         * @property {Array.<PlatformAdminApi.IOrderElementOption>|null} [data] OrderElementOptionsResponse data
         */

        /**
         * Constructs a new OrderElementOptionsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderElementOptionsResponse.
         * @implements IOrderElementOptionsResponse
         * @constructor
         * @param {PlatformAdminApi.IOrderElementOptionsResponse=} [properties] Properties to set
         */
        function OrderElementOptionsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderElementOptionsResponse data.
         * @member {Array.<PlatformAdminApi.IOrderElementOption>} data
         * @memberof PlatformAdminApi.OrderElementOptionsResponse
         * @instance
         */
        OrderElementOptionsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified OrderElementOptionsResponse message. Does not implicitly {@link PlatformAdminApi.OrderElementOptionsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderElementOptionsResponse
         * @static
         * @param {PlatformAdminApi.IOrderElementOptionsResponse} message OrderElementOptionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderElementOptionsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.OrderElementOption.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an OrderElementOptionsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderElementOptionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderElementOptionsResponse} OrderElementOptionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderElementOptionsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderElementOptionsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.OrderElementOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderElementOptionsResponse;
    })();

    PlatformAdminApi.OrderElementOption = (function() {

        /**
         * Properties of an OrderElementOption.
         * @memberof PlatformAdminApi
         * @interface IOrderElementOption
         * @property {string|null} [id] OrderElementOption id
         * @property {string|null} [slug] OrderElementOption slug
         * @property {number|null} [basePrice] OrderElementOption basePrice
         * @property {number|null} [duration] OrderElementOption duration
         */

        /**
         * Constructs a new OrderElementOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OrderElementOption.
         * @implements IOrderElementOption
         * @constructor
         * @param {PlatformAdminApi.IOrderElementOption=} [properties] Properties to set
         */
        function OrderElementOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderElementOption id.
         * @member {string} id
         * @memberof PlatformAdminApi.OrderElementOption
         * @instance
         */
        OrderElementOption.prototype.id = "";

        /**
         * OrderElementOption slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.OrderElementOption
         * @instance
         */
        OrderElementOption.prototype.slug = "";

        /**
         * OrderElementOption basePrice.
         * @member {number} basePrice
         * @memberof PlatformAdminApi.OrderElementOption
         * @instance
         */
        OrderElementOption.prototype.basePrice = 0;

        /**
         * OrderElementOption duration.
         * @member {number} duration
         * @memberof PlatformAdminApi.OrderElementOption
         * @instance
         */
        OrderElementOption.prototype.duration = 0;

        /**
         * Encodes the specified OrderElementOption message. Does not implicitly {@link PlatformAdminApi.OrderElementOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OrderElementOption
         * @static
         * @param {PlatformAdminApi.IOrderElementOption} message OrderElementOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderElementOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slug);
            if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.basePrice);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.duration);
            return writer;
        };

        /**
         * Decodes an OrderElementOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OrderElementOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OrderElementOption} OrderElementOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderElementOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OrderElementOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.basePrice = reader.int32();
                    break;
                case 4:
                    message.duration = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderElementOption;
    })();

    PlatformAdminApi.UpdateCustomerFIOBackend = (function() {

        /**
         * Properties of an UpdateCustomerFIOBackend.
         * @memberof PlatformAdminApi
         * @interface IUpdateCustomerFIOBackend
         * @property {string|null} [customerId] UpdateCustomerFIOBackend customerId
         * @property {string|null} [firstname] UpdateCustomerFIOBackend firstname
         * @property {string|null} [lastname] UpdateCustomerFIOBackend lastname
         */

        /**
         * Constructs a new UpdateCustomerFIOBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpdateCustomerFIOBackend.
         * @implements IUpdateCustomerFIOBackend
         * @constructor
         * @param {PlatformAdminApi.IUpdateCustomerFIOBackend=} [properties] Properties to set
         */
        function UpdateCustomerFIOBackend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCustomerFIOBackend customerId.
         * @member {string} customerId
         * @memberof PlatformAdminApi.UpdateCustomerFIOBackend
         * @instance
         */
        UpdateCustomerFIOBackend.prototype.customerId = "";

        /**
         * UpdateCustomerFIOBackend firstname.
         * @member {string} firstname
         * @memberof PlatformAdminApi.UpdateCustomerFIOBackend
         * @instance
         */
        UpdateCustomerFIOBackend.prototype.firstname = "";

        /**
         * UpdateCustomerFIOBackend lastname.
         * @member {string} lastname
         * @memberof PlatformAdminApi.UpdateCustomerFIOBackend
         * @instance
         */
        UpdateCustomerFIOBackend.prototype.lastname = "";

        /**
         * Encodes the specified UpdateCustomerFIOBackend message. Does not implicitly {@link PlatformAdminApi.UpdateCustomerFIOBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateCustomerFIOBackend
         * @static
         * @param {PlatformAdminApi.IUpdateCustomerFIOBackend} message UpdateCustomerFIOBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateCustomerFIOBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
            if (message.firstname != null && Object.hasOwnProperty.call(message, "firstname"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstname);
            if (message.lastname != null && Object.hasOwnProperty.call(message, "lastname"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastname);
            return writer;
        };

        /**
         * Decodes an UpdateCustomerFIOBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateCustomerFIOBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateCustomerFIOBackend} UpdateCustomerFIOBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateCustomerFIOBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateCustomerFIOBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.customerId = reader.string();
                    break;
                case 2:
                    message.firstname = reader.string();
                    break;
                case 3:
                    message.lastname = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateCustomerFIOBackend;
    })();

    PlatformAdminApi.UpsertOrderBackend = (function() {

        /**
         * Properties of an UpsertOrderBackend.
         * @memberof PlatformAdminApi
         * @interface IUpsertOrderBackend
         * @property {string|null} [cartId] UpsertOrderBackend cartId
         * @property {PlatformAdminApi.ICustomerViewOrder|null} [order] UpsertOrderBackend order
         * @property {string|null} [orderSerialNumber] UpsertOrderBackend orderSerialNumber
         * @property {string|null} [prop] UpsertOrderBackend prop
         * @property {string|null} [value] UpsertOrderBackend value
         */

        /**
         * Constructs a new UpsertOrderBackend.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpsertOrderBackend.
         * @implements IUpsertOrderBackend
         * @constructor
         * @param {PlatformAdminApi.IUpsertOrderBackend=} [properties] Properties to set
         */
        function UpsertOrderBackend(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertOrderBackend cartId.
         * @member {string} cartId
         * @memberof PlatformAdminApi.UpsertOrderBackend
         * @instance
         */
        UpsertOrderBackend.prototype.cartId = "";

        /**
         * UpsertOrderBackend order.
         * @member {PlatformAdminApi.ICustomerViewOrder|null|undefined} order
         * @memberof PlatformAdminApi.UpsertOrderBackend
         * @instance
         */
        UpsertOrderBackend.prototype.order = null;

        /**
         * UpsertOrderBackend orderSerialNumber.
         * @member {string} orderSerialNumber
         * @memberof PlatformAdminApi.UpsertOrderBackend
         * @instance
         */
        UpsertOrderBackend.prototype.orderSerialNumber = "";

        /**
         * UpsertOrderBackend prop.
         * @member {string} prop
         * @memberof PlatformAdminApi.UpsertOrderBackend
         * @instance
         */
        UpsertOrderBackend.prototype.prop = "";

        /**
         * UpsertOrderBackend value.
         * @member {string} value
         * @memberof PlatformAdminApi.UpsertOrderBackend
         * @instance
         */
        UpsertOrderBackend.prototype.value = "";

        /**
         * Encodes the specified UpsertOrderBackend message. Does not implicitly {@link PlatformAdminApi.UpsertOrderBackend.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpsertOrderBackend
         * @static
         * @param {PlatformAdminApi.IUpsertOrderBackend} message UpsertOrderBackend message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertOrderBackend.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cartId != null && Object.hasOwnProperty.call(message, "cartId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cartId);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                $root.PlatformAdminApi.CustomerViewOrder.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.orderSerialNumber);
            if (message.prop != null && Object.hasOwnProperty.call(message, "prop"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.prop);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.value);
            return writer;
        };

        /**
         * Decodes an UpsertOrderBackend message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpsertOrderBackend
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpsertOrderBackend} UpsertOrderBackend
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertOrderBackend.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpsertOrderBackend();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cartId = reader.string();
                    break;
                case 2:
                    message.order = $root.PlatformAdminApi.CustomerViewOrder.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.orderSerialNumber = reader.string();
                    break;
                case 4:
                    message.prop = reader.string();
                    break;
                case 5:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertOrderBackend;
    })();

    PlatformAdminApi.CoreBasePriceOption = (function() {

        /**
         * Properties of a CoreBasePriceOption.
         * @memberof PlatformAdminApi
         * @interface ICoreBasePriceOption
         * @property {string|null} [id] CoreBasePriceOption id
         * @property {PlatformAdminApi.ICoreBaseOption|null} [option] CoreBasePriceOption option
         * @property {PlatformAdminApi.ICustomerOrderOptionUnit|null} [unit] CoreBasePriceOption unit
         * @property {plt.core.base.PRICE_ELEMENT_OPTION_TYPE|null} [type] CoreBasePriceOption type
         * @property {number|null} [costLinear] CoreBasePriceOption costLinear
         * @property {plt.core.base.MATRIX_UNIT_TYPE|null} [costMatrixPer] CoreBasePriceOption costMatrixPer
         * @property {Array.<PlatformAdminApi.IPriceOptionMatrixItem>|null} [costMatrixItems] CoreBasePriceOption costMatrixItems
         * @property {plt.core.base.MATRIX_UNIT_TYPE|null} [durationMatrixPer] CoreBasePriceOption durationMatrixPer
         * @property {Array.<PlatformAdminApi.IPriceOptionMatrixItem>|null} [durationMatrixItems] CoreBasePriceOption durationMatrixItems
         * @property {number|null} [durationLinear] CoreBasePriceOption durationLinear
         * @property {boolean|null} [isFree] CoreBasePriceOption isFree
         * @property {number|null} [defaultValue] CoreBasePriceOption defaultValue
         * @property {number|null} [minValue] CoreBasePriceOption minValue
         * @property {number|null} [defaultFactor] CoreBasePriceOption defaultFactor
         * @property {number|null} [minFactor] CoreBasePriceOption minFactor
         * @property {number|null} [maxFactor] CoreBasePriceOption maxFactor
         * @property {string|null} [slug] CoreBasePriceOption slug
         * @property {number|null} [minBase] CoreBasePriceOption minBase
         * @property {boolean|null} [isBaseOption] CoreBasePriceOption isBaseOption
         * @property {PlatformAdminApi.ICoreBaseOrderOption|null} [orderOption] CoreBasePriceOption orderOption
         * @property {number|null} [max] CoreBasePriceOption max
         */

        /**
         * Constructs a new CoreBasePriceOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CoreBasePriceOption.
         * @implements ICoreBasePriceOption
         * @constructor
         * @param {PlatformAdminApi.ICoreBasePriceOption=} [properties] Properties to set
         */
        function CoreBasePriceOption(properties) {
            this.costMatrixItems = [];
            this.durationMatrixItems = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoreBasePriceOption id.
         * @member {string} id
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.id = "";

        /**
         * CoreBasePriceOption option.
         * @member {PlatformAdminApi.ICoreBaseOption|null|undefined} option
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.option = null;

        /**
         * CoreBasePriceOption unit.
         * @member {PlatformAdminApi.ICustomerOrderOptionUnit|null|undefined} unit
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.unit = null;

        /**
         * CoreBasePriceOption type.
         * @member {plt.core.base.PRICE_ELEMENT_OPTION_TYPE} type
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.type = 0;

        /**
         * CoreBasePriceOption costLinear.
         * @member {number} costLinear
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.costLinear = 0;

        /**
         * CoreBasePriceOption costMatrixPer.
         * @member {plt.core.base.MATRIX_UNIT_TYPE} costMatrixPer
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.costMatrixPer = 0;

        /**
         * CoreBasePriceOption costMatrixItems.
         * @member {Array.<PlatformAdminApi.IPriceOptionMatrixItem>} costMatrixItems
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.costMatrixItems = $util.emptyArray;

        /**
         * CoreBasePriceOption durationMatrixPer.
         * @member {plt.core.base.MATRIX_UNIT_TYPE} durationMatrixPer
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.durationMatrixPer = 0;

        /**
         * CoreBasePriceOption durationMatrixItems.
         * @member {Array.<PlatformAdminApi.IPriceOptionMatrixItem>} durationMatrixItems
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.durationMatrixItems = $util.emptyArray;

        /**
         * CoreBasePriceOption durationLinear.
         * @member {number} durationLinear
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.durationLinear = 0;

        /**
         * CoreBasePriceOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.isFree = false;

        /**
         * CoreBasePriceOption defaultValue.
         * @member {number} defaultValue
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.defaultValue = 0;

        /**
         * CoreBasePriceOption minValue.
         * @member {number} minValue
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.minValue = 0;

        /**
         * CoreBasePriceOption defaultFactor.
         * @member {number} defaultFactor
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.defaultFactor = 0;

        /**
         * CoreBasePriceOption minFactor.
         * @member {number} minFactor
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.minFactor = 0;

        /**
         * CoreBasePriceOption maxFactor.
         * @member {number} maxFactor
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.maxFactor = 0;

        /**
         * CoreBasePriceOption slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.slug = "";

        /**
         * CoreBasePriceOption minBase.
         * @member {number} minBase
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.minBase = 0;

        /**
         * CoreBasePriceOption isBaseOption.
         * @member {boolean} isBaseOption
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.isBaseOption = false;

        /**
         * CoreBasePriceOption orderOption.
         * @member {PlatformAdminApi.ICoreBaseOrderOption|null|undefined} orderOption
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.orderOption = null;

        /**
         * CoreBasePriceOption max.
         * @member {number} max
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @instance
         */
        CoreBasePriceOption.prototype.max = 0;

        /**
         * Encodes the specified CoreBasePriceOption message. Does not implicitly {@link PlatformAdminApi.CoreBasePriceOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @static
         * @param {PlatformAdminApi.ICoreBasePriceOption} message CoreBasePriceOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoreBasePriceOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.option != null && Object.hasOwnProperty.call(message, "option"))
                $root.PlatformAdminApi.CoreBaseOption.encode(message.option, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                $root.PlatformAdminApi.CustomerOrderOptionUnit.encode(message.unit, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.type);
            if (message.costLinear != null && Object.hasOwnProperty.call(message, "costLinear"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.costLinear);
            if (message.costMatrixPer != null && Object.hasOwnProperty.call(message, "costMatrixPer"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.costMatrixPer);
            if (message.costMatrixItems != null && message.costMatrixItems.length)
                for (var i = 0; i < message.costMatrixItems.length; ++i)
                    $root.PlatformAdminApi.PriceOptionMatrixItem.encode(message.costMatrixItems[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.durationMatrixPer != null && Object.hasOwnProperty.call(message, "durationMatrixPer"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.durationMatrixPer);
            if (message.durationMatrixItems != null && message.durationMatrixItems.length)
                for (var i = 0; i < message.durationMatrixItems.length; ++i)
                    $root.PlatformAdminApi.PriceOptionMatrixItem.encode(message.durationMatrixItems[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.durationLinear != null && Object.hasOwnProperty.call(message, "durationLinear"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.durationLinear);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isFree);
            if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                writer.uint32(/* id 17, wireType 0 =*/136).int32(message.defaultValue);
            if (message.minValue != null && Object.hasOwnProperty.call(message, "minValue"))
                writer.uint32(/* id 18, wireType 0 =*/144).int32(message.minValue);
            if (message.defaultFactor != null && Object.hasOwnProperty.call(message, "defaultFactor"))
                writer.uint32(/* id 19, wireType 0 =*/152).int32(message.defaultFactor);
            if (message.minFactor != null && Object.hasOwnProperty.call(message, "minFactor"))
                writer.uint32(/* id 20, wireType 0 =*/160).int32(message.minFactor);
            if (message.maxFactor != null && Object.hasOwnProperty.call(message, "maxFactor"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.maxFactor);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.slug);
            if (message.minBase != null && Object.hasOwnProperty.call(message, "minBase"))
                writer.uint32(/* id 23, wireType 0 =*/184).int32(message.minBase);
            if (message.isBaseOption != null && Object.hasOwnProperty.call(message, "isBaseOption"))
                writer.uint32(/* id 24, wireType 0 =*/192).bool(message.isBaseOption);
            if (message.orderOption != null && Object.hasOwnProperty.call(message, "orderOption"))
                $root.PlatformAdminApi.CoreBaseOrderOption.encode(message.orderOption, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 26, wireType 0 =*/208).int32(message.max);
            return writer;
        };

        /**
         * Decodes a CoreBasePriceOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CoreBasePriceOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CoreBasePriceOption} CoreBasePriceOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoreBasePriceOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBasePriceOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 6:
                    message.option = $root.PlatformAdminApi.CoreBaseOption.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.unit = $root.PlatformAdminApi.CustomerOrderOptionUnit.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.type = reader.int32();
                    break;
                case 9:
                    message.costLinear = reader.int32();
                    break;
                case 10:
                    message.costMatrixPer = reader.int32();
                    break;
                case 11:
                    if (!(message.costMatrixItems && message.costMatrixItems.length))
                        message.costMatrixItems = [];
                    message.costMatrixItems.push($root.PlatformAdminApi.PriceOptionMatrixItem.decode(reader, reader.uint32()));
                    break;
                case 12:
                    message.durationMatrixPer = reader.int32();
                    break;
                case 13:
                    if (!(message.durationMatrixItems && message.durationMatrixItems.length))
                        message.durationMatrixItems = [];
                    message.durationMatrixItems.push($root.PlatformAdminApi.PriceOptionMatrixItem.decode(reader, reader.uint32()));
                    break;
                case 14:
                    message.durationLinear = reader.int32();
                    break;
                case 15:
                    message.isFree = reader.bool();
                    break;
                case 17:
                    message.defaultValue = reader.int32();
                    break;
                case 18:
                    message.minValue = reader.int32();
                    break;
                case 19:
                    message.defaultFactor = reader.int32();
                    break;
                case 20:
                    message.minFactor = reader.int32();
                    break;
                case 21:
                    message.maxFactor = reader.int32();
                    break;
                case 22:
                    message.slug = reader.string();
                    break;
                case 23:
                    message.minBase = reader.int32();
                    break;
                case 24:
                    message.isBaseOption = reader.bool();
                    break;
                case 25:
                    message.orderOption = $root.PlatformAdminApi.CoreBaseOrderOption.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.max = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CoreBasePriceOption;
    })();

    PlatformAdminApi.PriceOptionMatrixItem = (function() {

        /**
         * Properties of a PriceOptionMatrixItem.
         * @memberof PlatformAdminApi
         * @interface IPriceOptionMatrixItem
         * @property {string|null} [id] PriceOptionMatrixItem id
         * @property {string|null} [range] PriceOptionMatrixItem range
         * @property {number|null} [value] PriceOptionMatrixItem value
         */

        /**
         * Constructs a new PriceOptionMatrixItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PriceOptionMatrixItem.
         * @implements IPriceOptionMatrixItem
         * @constructor
         * @param {PlatformAdminApi.IPriceOptionMatrixItem=} [properties] Properties to set
         */
        function PriceOptionMatrixItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceOptionMatrixItem id.
         * @member {string} id
         * @memberof PlatformAdminApi.PriceOptionMatrixItem
         * @instance
         */
        PriceOptionMatrixItem.prototype.id = "";

        /**
         * PriceOptionMatrixItem range.
         * @member {string} range
         * @memberof PlatformAdminApi.PriceOptionMatrixItem
         * @instance
         */
        PriceOptionMatrixItem.prototype.range = "";

        /**
         * PriceOptionMatrixItem value.
         * @member {number} value
         * @memberof PlatformAdminApi.PriceOptionMatrixItem
         * @instance
         */
        PriceOptionMatrixItem.prototype.value = 0;

        /**
         * Encodes the specified PriceOptionMatrixItem message. Does not implicitly {@link PlatformAdminApi.PriceOptionMatrixItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PriceOptionMatrixItem
         * @static
         * @param {PlatformAdminApi.IPriceOptionMatrixItem} message PriceOptionMatrixItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceOptionMatrixItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.range);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.value);
            return writer;
        };

        /**
         * Decodes a PriceOptionMatrixItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PriceOptionMatrixItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PriceOptionMatrixItem} PriceOptionMatrixItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceOptionMatrixItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PriceOptionMatrixItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.range = reader.string();
                    break;
                case 3:
                    message.value = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceOptionMatrixItem;
    })();

    PlatformAdminApi.CustomerViewOrderOptionCreate = (function() {

        /**
         * Properties of a CustomerViewOrderOptionCreate.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewOrderOptionCreate
         * @property {string|null} [optionId] CustomerViewOrderOptionCreate optionId
         * @property {number|null} [value] CustomerViewOrderOptionCreate value
         * @property {number|null} [factor] CustomerViewOrderOptionCreate factor
         * @property {boolean|null} [isFree] CustomerViewOrderOptionCreate isFree
         */

        /**
         * Constructs a new CustomerViewOrderOptionCreate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewOrderOptionCreate.
         * @implements ICustomerViewOrderOptionCreate
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewOrderOptionCreate=} [properties] Properties to set
         */
        function CustomerViewOrderOptionCreate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewOrderOptionCreate optionId.
         * @member {string} optionId
         * @memberof PlatformAdminApi.CustomerViewOrderOptionCreate
         * @instance
         */
        CustomerViewOrderOptionCreate.prototype.optionId = "";

        /**
         * CustomerViewOrderOptionCreate value.
         * @member {number} value
         * @memberof PlatformAdminApi.CustomerViewOrderOptionCreate
         * @instance
         */
        CustomerViewOrderOptionCreate.prototype.value = 0;

        /**
         * CustomerViewOrderOptionCreate factor.
         * @member {number} factor
         * @memberof PlatformAdminApi.CustomerViewOrderOptionCreate
         * @instance
         */
        CustomerViewOrderOptionCreate.prototype.factor = 0;

        /**
         * CustomerViewOrderOptionCreate isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.CustomerViewOrderOptionCreate
         * @instance
         */
        CustomerViewOrderOptionCreate.prototype.isFree = false;

        /**
         * Encodes the specified CustomerViewOrderOptionCreate message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrderOptionCreate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewOrderOptionCreate
         * @static
         * @param {PlatformAdminApi.ICustomerViewOrderOptionCreate} message CustomerViewOrderOptionCreate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewOrderOptionCreate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.optionId != null && Object.hasOwnProperty.call(message, "optionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.optionId);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.factor);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isFree);
            return writer;
        };

        /**
         * Decodes a CustomerViewOrderOptionCreate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewOrderOptionCreate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewOrderOptionCreate} CustomerViewOrderOptionCreate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewOrderOptionCreate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrderOptionCreate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.optionId = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.factor = reader.int32();
                    break;
                case 4:
                    message.isFree = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewOrderOptionCreate;
    })();

    PlatformAdminApi.CustomerViewOrderOptionUpdate = (function() {

        /**
         * Properties of a CustomerViewOrderOptionUpdate.
         * @memberof PlatformAdminApi
         * @interface ICustomerViewOrderOptionUpdate
         * @property {string|null} [id] CustomerViewOrderOptionUpdate id
         * @property {number|null} [value] CustomerViewOrderOptionUpdate value
         * @property {number|null} [factor] CustomerViewOrderOptionUpdate factor
         * @property {boolean|null} [isFree] CustomerViewOrderOptionUpdate isFree
         */

        /**
         * Constructs a new CustomerViewOrderOptionUpdate.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CustomerViewOrderOptionUpdate.
         * @implements ICustomerViewOrderOptionUpdate
         * @constructor
         * @param {PlatformAdminApi.ICustomerViewOrderOptionUpdate=} [properties] Properties to set
         */
        function CustomerViewOrderOptionUpdate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerViewOrderOptionUpdate id.
         * @member {string} id
         * @memberof PlatformAdminApi.CustomerViewOrderOptionUpdate
         * @instance
         */
        CustomerViewOrderOptionUpdate.prototype.id = "";

        /**
         * CustomerViewOrderOptionUpdate value.
         * @member {number} value
         * @memberof PlatformAdminApi.CustomerViewOrderOptionUpdate
         * @instance
         */
        CustomerViewOrderOptionUpdate.prototype.value = 0;

        /**
         * CustomerViewOrderOptionUpdate factor.
         * @member {number} factor
         * @memberof PlatformAdminApi.CustomerViewOrderOptionUpdate
         * @instance
         */
        CustomerViewOrderOptionUpdate.prototype.factor = 0;

        /**
         * CustomerViewOrderOptionUpdate isFree.
         * @member {boolean} isFree
         * @memberof PlatformAdminApi.CustomerViewOrderOptionUpdate
         * @instance
         */
        CustomerViewOrderOptionUpdate.prototype.isFree = false;

        /**
         * Encodes the specified CustomerViewOrderOptionUpdate message. Does not implicitly {@link PlatformAdminApi.CustomerViewOrderOptionUpdate.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CustomerViewOrderOptionUpdate
         * @static
         * @param {PlatformAdminApi.ICustomerViewOrderOptionUpdate} message CustomerViewOrderOptionUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerViewOrderOptionUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.factor);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isFree);
            return writer;
        };

        /**
         * Decodes a CustomerViewOrderOptionUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CustomerViewOrderOptionUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CustomerViewOrderOptionUpdate} CustomerViewOrderOptionUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerViewOrderOptionUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CustomerViewOrderOptionUpdate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.factor = reader.int32();
                    break;
                case 4:
                    message.isFree = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerViewOrderOptionUpdate;
    })();

    PlatformAdminApi.CoreBaseOption = (function() {

        /**
         * Properties of a CoreBaseOption.
         * @memberof PlatformAdminApi
         * @interface ICoreBaseOption
         * @property {string|null} [id] CoreBaseOption id
         * @property {boolean|null} [isDeleted] CoreBaseOption isDeleted
         * @property {PlatformAdminApi.OptionType|null} [type] CoreBaseOption type
         * @property {string|null} [slug] CoreBaseOption slug
         * @property {string|null} [name] CoreBaseOption name
         * @property {boolean|null} [systemOption] CoreBaseOption systemOption
         * @property {Array.<PlatformAdminApi.CoreBaseOption.IOptionTagRelation>|null} [tags] CoreBaseOption tags
         */

        /**
         * Constructs a new CoreBaseOption.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CoreBaseOption.
         * @implements ICoreBaseOption
         * @constructor
         * @param {PlatformAdminApi.ICoreBaseOption=} [properties] Properties to set
         */
        function CoreBaseOption(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoreBaseOption id.
         * @member {string} id
         * @memberof PlatformAdminApi.CoreBaseOption
         * @instance
         */
        CoreBaseOption.prototype.id = "";

        /**
         * CoreBaseOption isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformAdminApi.CoreBaseOption
         * @instance
         */
        CoreBaseOption.prototype.isDeleted = false;

        /**
         * CoreBaseOption type.
         * @member {PlatformAdminApi.OptionType} type
         * @memberof PlatformAdminApi.CoreBaseOption
         * @instance
         */
        CoreBaseOption.prototype.type = 0;

        /**
         * CoreBaseOption slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.CoreBaseOption
         * @instance
         */
        CoreBaseOption.prototype.slug = "";

        /**
         * CoreBaseOption name.
         * @member {string} name
         * @memberof PlatformAdminApi.CoreBaseOption
         * @instance
         */
        CoreBaseOption.prototype.name = "";

        /**
         * CoreBaseOption systemOption.
         * @member {boolean} systemOption
         * @memberof PlatformAdminApi.CoreBaseOption
         * @instance
         */
        CoreBaseOption.prototype.systemOption = false;

        /**
         * CoreBaseOption tags.
         * @member {Array.<PlatformAdminApi.CoreBaseOption.IOptionTagRelation>} tags
         * @memberof PlatformAdminApi.CoreBaseOption
         * @instance
         */
        CoreBaseOption.prototype.tags = $util.emptyArray;

        /**
         * Encodes the specified CoreBaseOption message. Does not implicitly {@link PlatformAdminApi.CoreBaseOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CoreBaseOption
         * @static
         * @param {PlatformAdminApi.ICoreBaseOption} message CoreBaseOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoreBaseOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.slug);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
            if (message.systemOption != null && Object.hasOwnProperty.call(message, "systemOption"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.systemOption);
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    $root.PlatformAdminApi.CoreBaseOption.OptionTagRelation.encode(message.tags[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CoreBaseOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CoreBaseOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CoreBaseOption} CoreBaseOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoreBaseOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBaseOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.isDeleted = reader.bool();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 6:
                    message.slug = reader.string();
                    break;
                case 7:
                    message.name = reader.string();
                    break;
                case 8:
                    message.systemOption = reader.bool();
                    break;
                case 11:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push($root.PlatformAdminApi.CoreBaseOption.OptionTagRelation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CoreBaseOption.OptionTagRelation = (function() {

            /**
             * Properties of an OptionTagRelation.
             * @memberof PlatformAdminApi.CoreBaseOption
             * @interface IOptionTagRelation
             * @property {string|null} [id] OptionTagRelation id
             * @property {number|null} [order] OptionTagRelation order
             * @property {PlatformAdminApi.ICustomerOrderOptionTag|null} [tag] OptionTagRelation tag
             */

            /**
             * Constructs a new OptionTagRelation.
             * @memberof PlatformAdminApi.CoreBaseOption
             * @classdesc Represents an OptionTagRelation.
             * @implements IOptionTagRelation
             * @constructor
             * @param {PlatformAdminApi.CoreBaseOption.IOptionTagRelation=} [properties] Properties to set
             */
            function OptionTagRelation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OptionTagRelation id.
             * @member {string} id
             * @memberof PlatformAdminApi.CoreBaseOption.OptionTagRelation
             * @instance
             */
            OptionTagRelation.prototype.id = "";

            /**
             * OptionTagRelation order.
             * @member {number} order
             * @memberof PlatformAdminApi.CoreBaseOption.OptionTagRelation
             * @instance
             */
            OptionTagRelation.prototype.order = 0;

            /**
             * OptionTagRelation tag.
             * @member {PlatformAdminApi.ICustomerOrderOptionTag|null|undefined} tag
             * @memberof PlatformAdminApi.CoreBaseOption.OptionTagRelation
             * @instance
             */
            OptionTagRelation.prototype.tag = null;

            /**
             * Encodes the specified OptionTagRelation message. Does not implicitly {@link PlatformAdminApi.CoreBaseOption.OptionTagRelation.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.CoreBaseOption.OptionTagRelation
             * @static
             * @param {PlatformAdminApi.CoreBaseOption.IOptionTagRelation} message OptionTagRelation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionTagRelation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.order);
                if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                    $root.PlatformAdminApi.CustomerOrderOptionTag.encode(message.tag, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OptionTagRelation message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.CoreBaseOption.OptionTagRelation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.CoreBaseOption.OptionTagRelation} OptionTagRelation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionTagRelation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBaseOption.OptionTagRelation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.order = reader.int32();
                        break;
                    case 3:
                        message.tag = $root.PlatformAdminApi.CustomerOrderOptionTag.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return OptionTagRelation;
        })();

        return CoreBaseOption;
    })();

    /**
     * OptionType enum.
     * @name PlatformAdminApi.OptionType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} GOODS=1 GOODS value
     * @property {number} SERVICE=2 SERVICE value
     */
    PlatformAdminApi.OptionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "GOODS"] = 1;
        values[valuesById[2] = "SERVICE"] = 2;
        return values;
    })();

    PlatformAdminApi.CoreBaseCartConfirmRequest = (function() {

        /**
         * Properties of a CoreBaseCartConfirmRequest.
         * @memberof PlatformAdminApi
         * @interface ICoreBaseCartConfirmRequest
         * @property {string|null} [orderSerialNumber] CoreBaseCartConfirmRequest orderSerialNumber
         * @property {string|null} [cartId] CoreBaseCartConfirmRequest cartId
         */

        /**
         * Constructs a new CoreBaseCartConfirmRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CoreBaseCartConfirmRequest.
         * @implements ICoreBaseCartConfirmRequest
         * @constructor
         * @param {PlatformAdminApi.ICoreBaseCartConfirmRequest=} [properties] Properties to set
         */
        function CoreBaseCartConfirmRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoreBaseCartConfirmRequest orderSerialNumber.
         * @member {string} orderSerialNumber
         * @memberof PlatformAdminApi.CoreBaseCartConfirmRequest
         * @instance
         */
        CoreBaseCartConfirmRequest.prototype.orderSerialNumber = "";

        /**
         * CoreBaseCartConfirmRequest cartId.
         * @member {string} cartId
         * @memberof PlatformAdminApi.CoreBaseCartConfirmRequest
         * @instance
         */
        CoreBaseCartConfirmRequest.prototype.cartId = "";

        /**
         * Encodes the specified CoreBaseCartConfirmRequest message. Does not implicitly {@link PlatformAdminApi.CoreBaseCartConfirmRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.CoreBaseCartConfirmRequest
         * @static
         * @param {PlatformAdminApi.ICoreBaseCartConfirmRequest} message CoreBaseCartConfirmRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoreBaseCartConfirmRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderSerialNumber != null && Object.hasOwnProperty.call(message, "orderSerialNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderSerialNumber);
            if (message.cartId != null && Object.hasOwnProperty.call(message, "cartId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cartId);
            return writer;
        };

        /**
         * Decodes a CoreBaseCartConfirmRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.CoreBaseCartConfirmRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.CoreBaseCartConfirmRequest} CoreBaseCartConfirmRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoreBaseCartConfirmRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.CoreBaseCartConfirmRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderSerialNumber = reader.string();
                    break;
                case 2:
                    message.cartId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CoreBaseCartConfirmRequest;
    })();

    PlatformAdminApi.GetAvailableTimeslotsRequest = (function() {

        /**
         * Properties of a GetAvailableTimeslotsRequest.
         * @memberof PlatformAdminApi
         * @interface IGetAvailableTimeslotsRequest
         * @property {string|null} [addressId] GetAvailableTimeslotsRequest addressId
         * @property {string|null} [serviceType] GetAvailableTimeslotsRequest serviceType
         */

        /**
         * Constructs a new GetAvailableTimeslotsRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetAvailableTimeslotsRequest.
         * @implements IGetAvailableTimeslotsRequest
         * @constructor
         * @param {PlatformAdminApi.IGetAvailableTimeslotsRequest=} [properties] Properties to set
         */
        function GetAvailableTimeslotsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableTimeslotsRequest addressId.
         * @member {string} addressId
         * @memberof PlatformAdminApi.GetAvailableTimeslotsRequest
         * @instance
         */
        GetAvailableTimeslotsRequest.prototype.addressId = "";

        /**
         * GetAvailableTimeslotsRequest serviceType.
         * @member {string} serviceType
         * @memberof PlatformAdminApi.GetAvailableTimeslotsRequest
         * @instance
         */
        GetAvailableTimeslotsRequest.prototype.serviceType = "";

        /**
         * Encodes the specified GetAvailableTimeslotsRequest message. Does not implicitly {@link PlatformAdminApi.GetAvailableTimeslotsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetAvailableTimeslotsRequest
         * @static
         * @param {PlatformAdminApi.IGetAvailableTimeslotsRequest} message GetAvailableTimeslotsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableTimeslotsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addressId != null && Object.hasOwnProperty.call(message, "addressId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.addressId);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.serviceType);
            return writer;
        };

        /**
         * Decodes a GetAvailableTimeslotsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetAvailableTimeslotsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetAvailableTimeslotsRequest} GetAvailableTimeslotsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableTimeslotsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetAvailableTimeslotsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.addressId = reader.string();
                    break;
                case 2:
                    message.serviceType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableTimeslotsRequest;
    })();

    PlatformAdminApi.GetAvailableTimeslotsResponse = (function() {

        /**
         * Properties of a GetAvailableTimeslotsResponse.
         * @memberof PlatformAdminApi
         * @interface IGetAvailableTimeslotsResponse
         * @property {Array.<string>|null} [timeslots] GetAvailableTimeslotsResponse timeslots
         */

        /**
         * Constructs a new GetAvailableTimeslotsResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetAvailableTimeslotsResponse.
         * @implements IGetAvailableTimeslotsResponse
         * @constructor
         * @param {PlatformAdminApi.IGetAvailableTimeslotsResponse=} [properties] Properties to set
         */
        function GetAvailableTimeslotsResponse(properties) {
            this.timeslots = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableTimeslotsResponse timeslots.
         * @member {Array.<string>} timeslots
         * @memberof PlatformAdminApi.GetAvailableTimeslotsResponse
         * @instance
         */
        GetAvailableTimeslotsResponse.prototype.timeslots = $util.emptyArray;

        /**
         * Encodes the specified GetAvailableTimeslotsResponse message. Does not implicitly {@link PlatformAdminApi.GetAvailableTimeslotsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetAvailableTimeslotsResponse
         * @static
         * @param {PlatformAdminApi.IGetAvailableTimeslotsResponse} message GetAvailableTimeslotsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableTimeslotsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeslots != null && message.timeslots.length)
                for (var i = 0; i < message.timeslots.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.timeslots[i]);
            return writer;
        };

        /**
         * Decodes a GetAvailableTimeslotsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetAvailableTimeslotsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetAvailableTimeslotsResponse} GetAvailableTimeslotsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableTimeslotsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetAvailableTimeslotsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.timeslots && message.timeslots.length))
                        message.timeslots = [];
                    message.timeslots.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableTimeslotsResponse;
    })();

    PlatformAdminApi.UpdateOrderStatusRequest = (function() {

        /**
         * Properties of an UpdateOrderStatusRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateOrderStatusRequest
         * @property {boolean|null} [isExternal] UpdateOrderStatusRequest isExternal
         * @property {string|null} [orderId] UpdateOrderStatusRequest orderId
         * @property {plt.core.base.ORDER_STATUS_TYPE|null} [status] UpdateOrderStatusRequest status
         */

        /**
         * Constructs a new UpdateOrderStatusRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpdateOrderStatusRequest.
         * @implements IUpdateOrderStatusRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateOrderStatusRequest=} [properties] Properties to set
         */
        function UpdateOrderStatusRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateOrderStatusRequest isExternal.
         * @member {boolean} isExternal
         * @memberof PlatformAdminApi.UpdateOrderStatusRequest
         * @instance
         */
        UpdateOrderStatusRequest.prototype.isExternal = false;

        /**
         * UpdateOrderStatusRequest orderId.
         * @member {string} orderId
         * @memberof PlatformAdminApi.UpdateOrderStatusRequest
         * @instance
         */
        UpdateOrderStatusRequest.prototype.orderId = "";

        /**
         * UpdateOrderStatusRequest status.
         * @member {plt.core.base.ORDER_STATUS_TYPE} status
         * @memberof PlatformAdminApi.UpdateOrderStatusRequest
         * @instance
         */
        UpdateOrderStatusRequest.prototype.status = 0;

        /**
         * Encodes the specified UpdateOrderStatusRequest message. Does not implicitly {@link PlatformAdminApi.UpdateOrderStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateOrderStatusRequest
         * @static
         * @param {PlatformAdminApi.IUpdateOrderStatusRequest} message UpdateOrderStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateOrderStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isExternal != null && Object.hasOwnProperty.call(message, "isExternal"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isExternal);
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
            return writer;
        };

        /**
         * Decodes an UpdateOrderStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateOrderStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateOrderStatusRequest} UpdateOrderStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateOrderStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateOrderStatusRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.isExternal = reader.bool();
                    break;
                case 2:
                    message.orderId = reader.string();
                    break;
                case 3:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateOrderStatusRequest;
    })();

    PlatformAdminApi.UpdateOrderStatusResponse = (function() {

        /**
         * Properties of an UpdateOrderStatusResponse.
         * @memberof PlatformAdminApi
         * @interface IUpdateOrderStatusResponse
         * @property {string|null} [message] UpdateOrderStatusResponse message
         */

        /**
         * Constructs a new UpdateOrderStatusResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpdateOrderStatusResponse.
         * @implements IUpdateOrderStatusResponse
         * @constructor
         * @param {PlatformAdminApi.IUpdateOrderStatusResponse=} [properties] Properties to set
         */
        function UpdateOrderStatusResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateOrderStatusResponse message.
         * @member {string} message
         * @memberof PlatformAdminApi.UpdateOrderStatusResponse
         * @instance
         */
        UpdateOrderStatusResponse.prototype.message = "";

        /**
         * Encodes the specified UpdateOrderStatusResponse message. Does not implicitly {@link PlatformAdminApi.UpdateOrderStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateOrderStatusResponse
         * @static
         * @param {PlatformAdminApi.IUpdateOrderStatusResponse} message UpdateOrderStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateOrderStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
            return writer;
        };

        /**
         * Decodes an UpdateOrderStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateOrderStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateOrderStatusResponse} UpdateOrderStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateOrderStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateOrderStatusResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateOrderStatusResponse;
    })();

    PlatformAdminApi.FinanceService = (function() {

        /**
         * Constructs a new FinanceService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FinanceService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function FinanceService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (FinanceService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = FinanceService;

        /**
         * Callback as used by {@link PlatformAdminApi.FinanceService#getPaymentTypes}.
         * @memberof PlatformAdminApi.FinanceService
         * @typedef GetPaymentTypesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.GetPaymentTypesResponse} [response] GetPaymentTypesResponse
         */

        /**
         * Calls GetPaymentTypes.
         * @function getPaymentTypes
         * @memberof PlatformAdminApi.FinanceService
         * @instance
         * @param {PlatformAdminApi.IGetPaymentTypesRequest} request GetPaymentTypesRequest message or plain object
         * @param {PlatformAdminApi.FinanceService.GetPaymentTypesCallback} callback Node-style callback called with the error, if any, and GetPaymentTypesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FinanceService.prototype.getPaymentTypes = function getPaymentTypes(request, callback) {
            return this.rpcCall(getPaymentTypes, $root.PlatformAdminApi.GetPaymentTypesRequest, $root.PlatformAdminApi.GetPaymentTypesResponse, request, callback);
        }, "name", { value: "GetPaymentTypes" });

        /**
         * Calls GetPaymentTypes.
         * @function getPaymentTypes
         * @memberof PlatformAdminApi.FinanceService
         * @instance
         * @param {PlatformAdminApi.IGetPaymentTypesRequest} request GetPaymentTypesRequest message or plain object
         * @returns {Promise<PlatformAdminApi.GetPaymentTypesResponse>} Promise
         * @variation 2
         */

        return FinanceService;
    })();

    PlatformAdminApi.GetPaymentTypesRequest = (function() {

        /**
         * Properties of a GetPaymentTypesRequest.
         * @memberof PlatformAdminApi
         * @interface IGetPaymentTypesRequest
         * @property {string|null} [productSlug] GetPaymentTypesRequest productSlug
         * @property {PlatformAdminApi.GetPaymentTypesRequest.IOrderSettings|null} [settings] GetPaymentTypesRequest settings
         */

        /**
         * Constructs a new GetPaymentTypesRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetPaymentTypesRequest.
         * @implements IGetPaymentTypesRequest
         * @constructor
         * @param {PlatformAdminApi.IGetPaymentTypesRequest=} [properties] Properties to set
         */
        function GetPaymentTypesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPaymentTypesRequest productSlug.
         * @member {string} productSlug
         * @memberof PlatformAdminApi.GetPaymentTypesRequest
         * @instance
         */
        GetPaymentTypesRequest.prototype.productSlug = "";

        /**
         * GetPaymentTypesRequest settings.
         * @member {PlatformAdminApi.GetPaymentTypesRequest.IOrderSettings|null|undefined} settings
         * @memberof PlatformAdminApi.GetPaymentTypesRequest
         * @instance
         */
        GetPaymentTypesRequest.prototype.settings = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * GetPaymentTypesRequest _settings.
         * @member {"settings"|undefined} _settings
         * @memberof PlatformAdminApi.GetPaymentTypesRequest
         * @instance
         */
        Object.defineProperty(GetPaymentTypesRequest.prototype, "_settings", {
            get: $util.oneOfGetter($oneOfFields = ["settings"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified GetPaymentTypesRequest message. Does not implicitly {@link PlatformAdminApi.GetPaymentTypesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetPaymentTypesRequest
         * @static
         * @param {PlatformAdminApi.IGetPaymentTypesRequest} message GetPaymentTypesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPaymentTypesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                $root.PlatformAdminApi.GetPaymentTypesRequest.OrderSettings.encode(message.settings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetPaymentTypesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetPaymentTypesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetPaymentTypesRequest} GetPaymentTypesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPaymentTypesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetPaymentTypesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                case 2:
                    message.settings = $root.PlatformAdminApi.GetPaymentTypesRequest.OrderSettings.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        GetPaymentTypesRequest.OrderSettings = (function() {

            /**
             * Properties of an OrderSettings.
             * @memberof PlatformAdminApi.GetPaymentTypesRequest
             * @interface IOrderSettings
             * @property {boolean|null} [leaveAtTheDoor] OrderSettings leaveAtTheDoor
             */

            /**
             * Constructs a new OrderSettings.
             * @memberof PlatformAdminApi.GetPaymentTypesRequest
             * @classdesc Represents an OrderSettings.
             * @implements IOrderSettings
             * @constructor
             * @param {PlatformAdminApi.GetPaymentTypesRequest.IOrderSettings=} [properties] Properties to set
             */
            function OrderSettings(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderSettings leaveAtTheDoor.
             * @member {boolean|null|undefined} leaveAtTheDoor
             * @memberof PlatformAdminApi.GetPaymentTypesRequest.OrderSettings
             * @instance
             */
            OrderSettings.prototype.leaveAtTheDoor = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * OrderSettings _leaveAtTheDoor.
             * @member {"leaveAtTheDoor"|undefined} _leaveAtTheDoor
             * @memberof PlatformAdminApi.GetPaymentTypesRequest.OrderSettings
             * @instance
             */
            Object.defineProperty(OrderSettings.prototype, "_leaveAtTheDoor", {
                get: $util.oneOfGetter($oneOfFields = ["leaveAtTheDoor"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified OrderSettings message. Does not implicitly {@link PlatformAdminApi.GetPaymentTypesRequest.OrderSettings.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.GetPaymentTypesRequest.OrderSettings
             * @static
             * @param {PlatformAdminApi.GetPaymentTypesRequest.IOrderSettings} message OrderSettings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderSettings.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.leaveAtTheDoor != null && Object.hasOwnProperty.call(message, "leaveAtTheDoor"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.leaveAtTheDoor);
                return writer;
            };

            /**
             * Decodes an OrderSettings message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.GetPaymentTypesRequest.OrderSettings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.GetPaymentTypesRequest.OrderSettings} OrderSettings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderSettings.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetPaymentTypesRequest.OrderSettings();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.leaveAtTheDoor = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return OrderSettings;
        })();

        return GetPaymentTypesRequest;
    })();

    PlatformAdminApi.GetPaymentTypesResponse = (function() {

        /**
         * Properties of a GetPaymentTypesResponse.
         * @memberof PlatformAdminApi
         * @interface IGetPaymentTypesResponse
         * @property {Array.<PlatformAdminApi.GetPaymentTypesResponse.IPaymentTypeItem>|null} [data] GetPaymentTypesResponse data
         */

        /**
         * Constructs a new GetPaymentTypesResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a GetPaymentTypesResponse.
         * @implements IGetPaymentTypesResponse
         * @constructor
         * @param {PlatformAdminApi.IGetPaymentTypesResponse=} [properties] Properties to set
         */
        function GetPaymentTypesResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPaymentTypesResponse data.
         * @member {Array.<PlatformAdminApi.GetPaymentTypesResponse.IPaymentTypeItem>} data
         * @memberof PlatformAdminApi.GetPaymentTypesResponse
         * @instance
         */
        GetPaymentTypesResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified GetPaymentTypesResponse message. Does not implicitly {@link PlatformAdminApi.GetPaymentTypesResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.GetPaymentTypesResponse
         * @static
         * @param {PlatformAdminApi.IGetPaymentTypesResponse} message GetPaymentTypesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPaymentTypesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetPaymentTypesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.GetPaymentTypesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.GetPaymentTypesResponse} GetPaymentTypesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPaymentTypesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetPaymentTypesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        GetPaymentTypesResponse.PaymentTypeItem = (function() {

            /**
             * Properties of a PaymentTypeItem.
             * @memberof PlatformAdminApi.GetPaymentTypesResponse
             * @interface IPaymentTypeItem
             * @property {PlatformAdminApi.PaymentType|null} [paymentType] PaymentTypeItem paymentType
             * @property {string|null} [label] PaymentTypeItem label
             */

            /**
             * Constructs a new PaymentTypeItem.
             * @memberof PlatformAdminApi.GetPaymentTypesResponse
             * @classdesc Represents a PaymentTypeItem.
             * @implements IPaymentTypeItem
             * @constructor
             * @param {PlatformAdminApi.GetPaymentTypesResponse.IPaymentTypeItem=} [properties] Properties to set
             */
            function PaymentTypeItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PaymentTypeItem paymentType.
             * @member {PlatformAdminApi.PaymentType} paymentType
             * @memberof PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem
             * @instance
             */
            PaymentTypeItem.prototype.paymentType = 0;

            /**
             * PaymentTypeItem label.
             * @member {string} label
             * @memberof PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem
             * @instance
             */
            PaymentTypeItem.prototype.label = "";

            /**
             * Encodes the specified PaymentTypeItem message. Does not implicitly {@link PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem
             * @static
             * @param {PlatformAdminApi.GetPaymentTypesResponse.IPaymentTypeItem} message PaymentTypeItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentTypeItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.paymentType);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
                return writer;
            };

            /**
             * Decodes a PaymentTypeItem message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem} PaymentTypeItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentTypeItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.GetPaymentTypesResponse.PaymentTypeItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.paymentType = reader.int32();
                        break;
                    case 2:
                        message.label = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return PaymentTypeItem;
        })();

        return GetPaymentTypesResponse;
    })();

    PlatformAdminApi.PaymentMethod = (function() {

        /**
         * Properties of a PaymentMethod.
         * @memberof PlatformAdminApi
         * @interface IPaymentMethod
         * @property {string|null} [slug] PaymentMethod slug
         * @property {string|null} [title] PaymentMethod title
         */

        /**
         * Constructs a new PaymentMethod.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PaymentMethod.
         * @implements IPaymentMethod
         * @constructor
         * @param {PlatformAdminApi.IPaymentMethod=} [properties] Properties to set
         */
        function PaymentMethod(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentMethod slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.slug = "";

        /**
         * PaymentMethod title.
         * @member {string} title
         * @memberof PlatformAdminApi.PaymentMethod
         * @instance
         */
        PaymentMethod.prototype.title = "";

        /**
         * Encodes the specified PaymentMethod message. Does not implicitly {@link PlatformAdminApi.PaymentMethod.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PaymentMethod
         * @static
         * @param {PlatformAdminApi.IPaymentMethod} message PaymentMethod message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentMethod.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slug);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes a PaymentMethod message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PaymentMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PaymentMethod} PaymentMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentMethod.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PaymentMethod();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slug = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PaymentMethod;
    })();

    PlatformAdminApi.OtpCodesService = (function() {

        /**
         * Constructs a new OtpCodesService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OtpCodesService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function OtpCodesService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (OtpCodesService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OtpCodesService;

        /**
         * Callback as used by {@link PlatformAdminApi.OtpCodesService#search}.
         * @memberof PlatformAdminApi.OtpCodesService
         * @typedef SearchCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SearchOtpCodesResponse} [response] SearchOtpCodesResponse
         */

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OtpCodesService
         * @instance
         * @param {PlatformAdminApi.ISearchOtpCodesRequest} request SearchOtpCodesRequest message or plain object
         * @param {PlatformAdminApi.OtpCodesService.SearchCallback} callback Node-style callback called with the error, if any, and SearchOtpCodesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OtpCodesService.prototype.search = function search(request, callback) {
            return this.rpcCall(search, $root.PlatformAdminApi.SearchOtpCodesRequest, $root.PlatformAdminApi.SearchOtpCodesResponse, request, callback);
        }, "name", { value: "Search" });

        /**
         * Calls Search.
         * @function search
         * @memberof PlatformAdminApi.OtpCodesService
         * @instance
         * @param {PlatformAdminApi.ISearchOtpCodesRequest} request SearchOtpCodesRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SearchOtpCodesResponse>} Promise
         * @variation 2
         */

        return OtpCodesService;
    })();

    PlatformAdminApi.OtpCode = (function() {

        /**
         * Properties of an OtpCode.
         * @memberof PlatformAdminApi
         * @interface IOtpCode
         * @property {string|null} [contactValue] OtpCode contactValue
         * @property {string|null} [code] OtpCode code
         * @property {string|null} [createdAt] OtpCode createdAt
         */

        /**
         * Constructs a new OtpCode.
         * @memberof PlatformAdminApi
         * @classdesc Represents an OtpCode.
         * @implements IOtpCode
         * @constructor
         * @param {PlatformAdminApi.IOtpCode=} [properties] Properties to set
         */
        function OtpCode(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtpCode contactValue.
         * @member {string} contactValue
         * @memberof PlatformAdminApi.OtpCode
         * @instance
         */
        OtpCode.prototype.contactValue = "";

        /**
         * OtpCode code.
         * @member {string} code
         * @memberof PlatformAdminApi.OtpCode
         * @instance
         */
        OtpCode.prototype.code = "";

        /**
         * OtpCode createdAt.
         * @member {string} createdAt
         * @memberof PlatformAdminApi.OtpCode
         * @instance
         */
        OtpCode.prototype.createdAt = "";

        /**
         * Encodes the specified OtpCode message. Does not implicitly {@link PlatformAdminApi.OtpCode.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.OtpCode
         * @static
         * @param {PlatformAdminApi.IOtpCode} message OtpCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtpCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contactValue != null && Object.hasOwnProperty.call(message, "contactValue"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.contactValue);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.createdAt);
            return writer;
        };

        /**
         * Decodes an OtpCode message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.OtpCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.OtpCode} OtpCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtpCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.OtpCode();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.contactValue = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.createdAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OtpCode;
    })();

    PlatformAdminApi.SearchOtpCodesRequest = (function() {

        /**
         * Properties of a SearchOtpCodesRequest.
         * @memberof PlatformAdminApi
         * @interface ISearchOtpCodesRequest
         * @property {number|null} [page] SearchOtpCodesRequest page
         * @property {number|null} [limit] SearchOtpCodesRequest limit
         * @property {string|null} [searchText] SearchOtpCodesRequest searchText
         */

        /**
         * Constructs a new SearchOtpCodesRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchOtpCodesRequest.
         * @implements ISearchOtpCodesRequest
         * @constructor
         * @param {PlatformAdminApi.ISearchOtpCodesRequest=} [properties] Properties to set
         */
        function SearchOtpCodesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOtpCodesRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchOtpCodesRequest
         * @instance
         */
        SearchOtpCodesRequest.prototype.page = 0;

        /**
         * SearchOtpCodesRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchOtpCodesRequest
         * @instance
         */
        SearchOtpCodesRequest.prototype.limit = 0;

        /**
         * SearchOtpCodesRequest searchText.
         * @member {string|null|undefined} searchText
         * @memberof PlatformAdminApi.SearchOtpCodesRequest
         * @instance
         */
        SearchOtpCodesRequest.prototype.searchText = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * SearchOtpCodesRequest _searchText.
         * @member {"searchText"|undefined} _searchText
         * @memberof PlatformAdminApi.SearchOtpCodesRequest
         * @instance
         */
        Object.defineProperty(SearchOtpCodesRequest.prototype, "_searchText", {
            get: $util.oneOfGetter($oneOfFields = ["searchText"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified SearchOtpCodesRequest message. Does not implicitly {@link PlatformAdminApi.SearchOtpCodesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchOtpCodesRequest
         * @static
         * @param {PlatformAdminApi.ISearchOtpCodesRequest} message SearchOtpCodesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOtpCodesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.searchText != null && Object.hasOwnProperty.call(message, "searchText"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.searchText);
            return writer;
        };

        /**
         * Decodes a SearchOtpCodesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchOtpCodesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchOtpCodesRequest} SearchOtpCodesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOtpCodesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOtpCodesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    message.searchText = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchOtpCodesRequest;
    })();

    PlatformAdminApi.SearchOtpCodesResponse = (function() {

        /**
         * Properties of a SearchOtpCodesResponse.
         * @memberof PlatformAdminApi
         * @interface ISearchOtpCodesResponse
         * @property {Array.<PlatformAdminApi.IOtpCode>|null} [data] SearchOtpCodesResponse data
         * @property {number|null} [page] SearchOtpCodesResponse page
         * @property {number|null} [pageCount] SearchOtpCodesResponse pageCount
         * @property {number|null} [limit] SearchOtpCodesResponse limit
         * @property {number|null} [count] SearchOtpCodesResponse count
         * @property {number|null} [total] SearchOtpCodesResponse total
         */

        /**
         * Constructs a new SearchOtpCodesResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SearchOtpCodesResponse.
         * @implements ISearchOtpCodesResponse
         * @constructor
         * @param {PlatformAdminApi.ISearchOtpCodesResponse=} [properties] Properties to set
         */
        function SearchOtpCodesResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOtpCodesResponse data.
         * @member {Array.<PlatformAdminApi.IOtpCode>} data
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @instance
         */
        SearchOtpCodesResponse.prototype.data = $util.emptyArray;

        /**
         * SearchOtpCodesResponse page.
         * @member {number} page
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @instance
         */
        SearchOtpCodesResponse.prototype.page = 0;

        /**
         * SearchOtpCodesResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @instance
         */
        SearchOtpCodesResponse.prototype.pageCount = 0;

        /**
         * SearchOtpCodesResponse limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @instance
         */
        SearchOtpCodesResponse.prototype.limit = 0;

        /**
         * SearchOtpCodesResponse count.
         * @member {number} count
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @instance
         */
        SearchOtpCodesResponse.prototype.count = 0;

        /**
         * SearchOtpCodesResponse total.
         * @member {number} total
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @instance
         */
        SearchOtpCodesResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchOtpCodesResponse message. Does not implicitly {@link PlatformAdminApi.SearchOtpCodesResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @static
         * @param {PlatformAdminApi.ISearchOtpCodesResponse} message SearchOtpCodesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOtpCodesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformAdminApi.OtpCode.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchOtpCodesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SearchOtpCodesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SearchOtpCodesResponse} SearchOtpCodesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOtpCodesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SearchOtpCodesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformAdminApi.OtpCode.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchOtpCodesResponse;
    })();

    PlatformAdminApi.SkuService = (function() {

        /**
         * Constructs a new SkuService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkuService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SkuService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SkuService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SkuService;

        /**
         * Callback as used by {@link PlatformAdminApi.SkuService#retrieveSkus}.
         * @memberof PlatformAdminApi.SkuService
         * @typedef RetrieveSkusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkusList} [response] SkusList
         */

        /**
         * Calls RetrieveSkus.
         * @function retrieveSkus
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.ISkusRequest} request SkusRequest message or plain object
         * @param {PlatformAdminApi.SkuService.RetrieveSkusCallback} callback Node-style callback called with the error, if any, and SkusList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkuService.prototype.retrieveSkus = function retrieveSkus(request, callback) {
            return this.rpcCall(retrieveSkus, $root.PlatformAdminApi.SkusRequest, $root.PlatformAdminApi.SkusList, request, callback);
        }, "name", { value: "RetrieveSkus" });

        /**
         * Calls RetrieveSkus.
         * @function retrieveSkus
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.ISkusRequest} request SkusRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkusList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkuService#findSkuById}.
         * @memberof PlatformAdminApi.SkuService
         * @typedef FindSkuByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkuViewItem} [response] SkuViewItem
         */

        /**
         * Calls FindSkuById.
         * @function findSkuById
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.IIUuid} request IUuid message or plain object
         * @param {PlatformAdminApi.SkuService.FindSkuByIdCallback} callback Node-style callback called with the error, if any, and SkuViewItem
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkuService.prototype.findSkuById = function findSkuById(request, callback) {
            return this.rpcCall(findSkuById, $root.PlatformAdminApi.IUuid, $root.PlatformAdminApi.SkuViewItem, request, callback);
        }, "name", { value: "FindSkuById" });

        /**
         * Calls FindSkuById.
         * @function findSkuById
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.IIUuid} request IUuid message or plain object
         * @returns {Promise<PlatformAdminApi.SkuViewItem>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkuService#updateImage}.
         * @memberof PlatformAdminApi.SkuService
         * @typedef UpdateImageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkuViewItem} [response] SkuViewItem
         */

        /**
         * Calls UpdateImage.
         * @function updateImage
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.IChangeImageRequest} request ChangeImageRequest message or plain object
         * @param {PlatformAdminApi.SkuService.UpdateImageCallback} callback Node-style callback called with the error, if any, and SkuViewItem
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkuService.prototype.updateImage = function updateImage(request, callback) {
            return this.rpcCall(updateImage, $root.PlatformAdminApi.ChangeImageRequest, $root.PlatformAdminApi.SkuViewItem, request, callback);
        }, "name", { value: "UpdateImage" });

        /**
         * Calls UpdateImage.
         * @function updateImage
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.IChangeImageRequest} request ChangeImageRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkuViewItem>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.SkuService#updateSku}.
         * @memberof PlatformAdminApi.SkuService
         * @typedef UpdateSkuCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.SkuViewItem} [response] SkuViewItem
         */

        /**
         * Calls UpdateSku.
         * @function updateSku
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.IUpdateSkuRequest} request UpdateSkuRequest message or plain object
         * @param {PlatformAdminApi.SkuService.UpdateSkuCallback} callback Node-style callback called with the error, if any, and SkuViewItem
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SkuService.prototype.updateSku = function updateSku(request, callback) {
            return this.rpcCall(updateSku, $root.PlatformAdminApi.UpdateSkuRequest, $root.PlatformAdminApi.SkuViewItem, request, callback);
        }, "name", { value: "UpdateSku" });

        /**
         * Calls UpdateSku.
         * @function updateSku
         * @memberof PlatformAdminApi.SkuService
         * @instance
         * @param {PlatformAdminApi.IUpdateSkuRequest} request UpdateSkuRequest message or plain object
         * @returns {Promise<PlatformAdminApi.SkuViewItem>} Promise
         * @variation 2
         */

        return SkuService;
    })();

    PlatformAdminApi.SkusListItem = (function() {

        /**
         * Properties of a SkusListItem.
         * @memberof PlatformAdminApi
         * @interface ISkusListItem
         * @property {number|null} [id] SkusListItem id
         * @property {string|null} [title] SkusListItem title
         */

        /**
         * Constructs a new SkusListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkusListItem.
         * @implements ISkusListItem
         * @constructor
         * @param {PlatformAdminApi.ISkusListItem=} [properties] Properties to set
         */
        function SkusListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkusListItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.SkusListItem
         * @instance
         */
        SkusListItem.prototype.id = 0;

        /**
         * SkusListItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.SkusListItem
         * @instance
         */
        SkusListItem.prototype.title = "";

        /**
         * Encodes the specified SkusListItem message. Does not implicitly {@link PlatformAdminApi.SkusListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkusListItem
         * @static
         * @param {PlatformAdminApi.ISkusListItem} message SkusListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkusListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes a SkusListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkusListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkusListItem} SkusListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkusListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkusListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkusListItem;
    })();

    PlatformAdminApi.SkusList = (function() {

        /**
         * Properties of a SkusList.
         * @memberof PlatformAdminApi
         * @interface ISkusList
         * @property {Array.<PlatformAdminApi.ISkusListItem>|null} [skus] SkusList skus
         * @property {number|null} [total] SkusList total
         */

        /**
         * Constructs a new SkusList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkusList.
         * @implements ISkusList
         * @constructor
         * @param {PlatformAdminApi.ISkusList=} [properties] Properties to set
         */
        function SkusList(properties) {
            this.skus = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkusList skus.
         * @member {Array.<PlatformAdminApi.ISkusListItem>} skus
         * @memberof PlatformAdminApi.SkusList
         * @instance
         */
        SkusList.prototype.skus = $util.emptyArray;

        /**
         * SkusList total.
         * @member {number} total
         * @memberof PlatformAdminApi.SkusList
         * @instance
         */
        SkusList.prototype.total = 0;

        /**
         * Encodes the specified SkusList message. Does not implicitly {@link PlatformAdminApi.SkusList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkusList
         * @static
         * @param {PlatformAdminApi.ISkusList} message SkusList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkusList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skus != null && message.skus.length)
                for (var i = 0; i < message.skus.length; ++i)
                    $root.PlatformAdminApi.SkusListItem.encode(message.skus[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SkusList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkusList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkusList} SkusList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkusList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkusList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.skus && message.skus.length))
                        message.skus = [];
                    message.skus.push($root.PlatformAdminApi.SkusListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkusList;
    })();

    PlatformAdminApi.SkusRequest = (function() {

        /**
         * Properties of a SkusRequest.
         * @memberof PlatformAdminApi
         * @interface ISkusRequest
         * @property {number|null} [limit] SkusRequest limit
         * @property {number|null} [page] SkusRequest page
         * @property {PlatformAdminApi.SkusRequest.IFilterOptions|null} [filterOptions] SkusRequest filterOptions
         */

        /**
         * Constructs a new SkusRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkusRequest.
         * @implements ISkusRequest
         * @constructor
         * @param {PlatformAdminApi.ISkusRequest=} [properties] Properties to set
         */
        function SkusRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkusRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.SkusRequest
         * @instance
         */
        SkusRequest.prototype.limit = 0;

        /**
         * SkusRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.SkusRequest
         * @instance
         */
        SkusRequest.prototype.page = 0;

        /**
         * SkusRequest filterOptions.
         * @member {PlatformAdminApi.SkusRequest.IFilterOptions|null|undefined} filterOptions
         * @memberof PlatformAdminApi.SkusRequest
         * @instance
         */
        SkusRequest.prototype.filterOptions = null;

        /**
         * Encodes the specified SkusRequest message. Does not implicitly {@link PlatformAdminApi.SkusRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkusRequest
         * @static
         * @param {PlatformAdminApi.ISkusRequest} message SkusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.filterOptions != null && Object.hasOwnProperty.call(message, "filterOptions"))
                $root.PlatformAdminApi.SkusRequest.FilterOptions.encode(message.filterOptions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SkusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkusRequest} SkusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkusRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.filterOptions = $root.PlatformAdminApi.SkusRequest.FilterOptions.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SkusRequest.FilterOptions = (function() {

            /**
             * Properties of a FilterOptions.
             * @memberof PlatformAdminApi.SkusRequest
             * @interface IFilterOptions
             * @property {string|null} [title] FilterOptions title
             */

            /**
             * Constructs a new FilterOptions.
             * @memberof PlatformAdminApi.SkusRequest
             * @classdesc Represents a FilterOptions.
             * @implements IFilterOptions
             * @constructor
             * @param {PlatformAdminApi.SkusRequest.IFilterOptions=} [properties] Properties to set
             */
            function FilterOptions(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FilterOptions title.
             * @member {string} title
             * @memberof PlatformAdminApi.SkusRequest.FilterOptions
             * @instance
             */
            FilterOptions.prototype.title = "";

            /**
             * Encodes the specified FilterOptions message. Does not implicitly {@link PlatformAdminApi.SkusRequest.FilterOptions.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.SkusRequest.FilterOptions
             * @static
             * @param {PlatformAdminApi.SkusRequest.IFilterOptions} message FilterOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FilterOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                return writer;
            };

            /**
             * Decodes a FilterOptions message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.SkusRequest.FilterOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.SkusRequest.FilterOptions} FilterOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FilterOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkusRequest.FilterOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.title = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return FilterOptions;
        })();

        return SkusRequest;
    })();

    PlatformAdminApi.SkuViewItem = (function() {

        /**
         * Properties of a SkuViewItem.
         * @memberof PlatformAdminApi
         * @interface ISkuViewItem
         * @property {number|null} [id] SkuViewItem id
         * @property {string|null} [title] SkuViewItem title
         * @property {string|null} [image] SkuViewItem image
         * @property {string|null} [qr] SkuViewItem qr
         * @property {string|null} [slug] SkuViewItem slug
         */

        /**
         * Constructs a new SkuViewItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a SkuViewItem.
         * @implements ISkuViewItem
         * @constructor
         * @param {PlatformAdminApi.ISkuViewItem=} [properties] Properties to set
         */
        function SkuViewItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SkuViewItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.SkuViewItem
         * @instance
         */
        SkuViewItem.prototype.id = 0;

        /**
         * SkuViewItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.SkuViewItem
         * @instance
         */
        SkuViewItem.prototype.title = "";

        /**
         * SkuViewItem image.
         * @member {string} image
         * @memberof PlatformAdminApi.SkuViewItem
         * @instance
         */
        SkuViewItem.prototype.image = "";

        /**
         * SkuViewItem qr.
         * @member {string} qr
         * @memberof PlatformAdminApi.SkuViewItem
         * @instance
         */
        SkuViewItem.prototype.qr = "";

        /**
         * SkuViewItem slug.
         * @member {string} slug
         * @memberof PlatformAdminApi.SkuViewItem
         * @instance
         */
        SkuViewItem.prototype.slug = "";

        /**
         * Encodes the specified SkuViewItem message. Does not implicitly {@link PlatformAdminApi.SkuViewItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.SkuViewItem
         * @static
         * @param {PlatformAdminApi.ISkuViewItem} message SkuViewItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SkuViewItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.image);
            if (message.qr != null && Object.hasOwnProperty.call(message, "qr"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.qr);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.slug);
            return writer;
        };

        /**
         * Decodes a SkuViewItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.SkuViewItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.SkuViewItem} SkuViewItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SkuViewItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.SkuViewItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.image = reader.string();
                    break;
                case 4:
                    message.qr = reader.string();
                    break;
                case 5:
                    message.slug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SkuViewItem;
    })();

    PlatformAdminApi.IUuid = (function() {

        /**
         * Properties of a IUuid.
         * @memberof PlatformAdminApi
         * @interface IIUuid
         * @property {number|Long|null} [id] IUuid id
         */

        /**
         * Constructs a new IUuid.
         * @memberof PlatformAdminApi
         * @classdesc Represents a IUuid.
         * @implements IIUuid
         * @constructor
         * @param {PlatformAdminApi.IIUuid=} [properties] Properties to set
         */
        function IUuid(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IUuid id.
         * @member {number|Long} id
         * @memberof PlatformAdminApi.IUuid
         * @instance
         */
        IUuid.prototype.id = 0;

        /**
         * Encodes the specified IUuid message. Does not implicitly {@link PlatformAdminApi.IUuid.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.IUuid
         * @static
         * @param {PlatformAdminApi.IIUuid} message IUuid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IUuid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            return writer;
        };

        /**
         * Decodes a IUuid message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.IUuid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.IUuid} IUuid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IUuid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.IUuid();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return IUuid;
    })();

    PlatformAdminApi.ChangeImageRequest = (function() {

        /**
         * Properties of a ChangeImageRequest.
         * @memberof PlatformAdminApi
         * @interface IChangeImageRequest
         * @property {number|Long|null} [skuId] ChangeImageRequest skuId
         * @property {string|null} [imageName] ChangeImageRequest imageName
         * @property {string|null} [imageBase64] ChangeImageRequest imageBase64
         */

        /**
         * Constructs a new ChangeImageRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ChangeImageRequest.
         * @implements IChangeImageRequest
         * @constructor
         * @param {PlatformAdminApi.IChangeImageRequest=} [properties] Properties to set
         */
        function ChangeImageRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeImageRequest skuId.
         * @member {number|Long} skuId
         * @memberof PlatformAdminApi.ChangeImageRequest
         * @instance
         */
        ChangeImageRequest.prototype.skuId = 0;

        /**
         * ChangeImageRequest imageName.
         * @member {string} imageName
         * @memberof PlatformAdminApi.ChangeImageRequest
         * @instance
         */
        ChangeImageRequest.prototype.imageName = "";

        /**
         * ChangeImageRequest imageBase64.
         * @member {string} imageBase64
         * @memberof PlatformAdminApi.ChangeImageRequest
         * @instance
         */
        ChangeImageRequest.prototype.imageBase64 = "";

        /**
         * Encodes the specified ChangeImageRequest message. Does not implicitly {@link PlatformAdminApi.ChangeImageRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ChangeImageRequest
         * @static
         * @param {PlatformAdminApi.IChangeImageRequest} message ChangeImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skuId != null && Object.hasOwnProperty.call(message, "skuId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.skuId);
            if (message.imageName != null && Object.hasOwnProperty.call(message, "imageName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageName);
            if (message.imageBase64 != null && Object.hasOwnProperty.call(message, "imageBase64"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageBase64);
            return writer;
        };

        /**
         * Decodes a ChangeImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ChangeImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ChangeImageRequest} ChangeImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ChangeImageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.skuId = reader.int64();
                    break;
                case 2:
                    message.imageName = reader.string();
                    break;
                case 3:
                    message.imageBase64 = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ChangeImageRequest;
    })();

    PlatformAdminApi.UpdateSkuRequest = (function() {

        /**
         * Properties of an UpdateSkuRequest.
         * @memberof PlatformAdminApi
         * @interface IUpdateSkuRequest
         * @property {number|Long|null} [skuId] UpdateSkuRequest skuId
         * @property {PlatformAdminApi.UpdateSkuRequest.IUpdateImage|null} [image] UpdateSkuRequest image
         * @property {string|null} [slug] UpdateSkuRequest slug
         */

        /**
         * Constructs a new UpdateSkuRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an UpdateSkuRequest.
         * @implements IUpdateSkuRequest
         * @constructor
         * @param {PlatformAdminApi.IUpdateSkuRequest=} [properties] Properties to set
         */
        function UpdateSkuRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateSkuRequest skuId.
         * @member {number|Long} skuId
         * @memberof PlatformAdminApi.UpdateSkuRequest
         * @instance
         */
        UpdateSkuRequest.prototype.skuId = 0;

        /**
         * UpdateSkuRequest image.
         * @member {PlatformAdminApi.UpdateSkuRequest.IUpdateImage|null|undefined} image
         * @memberof PlatformAdminApi.UpdateSkuRequest
         * @instance
         */
        UpdateSkuRequest.prototype.image = null;

        /**
         * UpdateSkuRequest slug.
         * @member {string|null|undefined} slug
         * @memberof PlatformAdminApi.UpdateSkuRequest
         * @instance
         */
        UpdateSkuRequest.prototype.slug = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpdateSkuRequest _image.
         * @member {"image"|undefined} _image
         * @memberof PlatformAdminApi.UpdateSkuRequest
         * @instance
         */
        Object.defineProperty(UpdateSkuRequest.prototype, "_image", {
            get: $util.oneOfGetter($oneOfFields = ["image"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UpdateSkuRequest _slug.
         * @member {"slug"|undefined} _slug
         * @memberof PlatformAdminApi.UpdateSkuRequest
         * @instance
         */
        Object.defineProperty(UpdateSkuRequest.prototype, "_slug", {
            get: $util.oneOfGetter($oneOfFields = ["slug"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified UpdateSkuRequest message. Does not implicitly {@link PlatformAdminApi.UpdateSkuRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.UpdateSkuRequest
         * @static
         * @param {PlatformAdminApi.IUpdateSkuRequest} message UpdateSkuRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSkuRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skuId != null && Object.hasOwnProperty.call(message, "skuId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.skuId);
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                $root.PlatformAdminApi.UpdateSkuRequest.UpdateImage.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.slug);
            return writer;
        };

        /**
         * Decodes an UpdateSkuRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.UpdateSkuRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.UpdateSkuRequest} UpdateSkuRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSkuRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateSkuRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.skuId = reader.int64();
                    break;
                case 2:
                    message.image = $root.PlatformAdminApi.UpdateSkuRequest.UpdateImage.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.slug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UpdateSkuRequest.UpdateImage = (function() {

            /**
             * Properties of an UpdateImage.
             * @memberof PlatformAdminApi.UpdateSkuRequest
             * @interface IUpdateImage
             * @property {string|null} [imageName] UpdateImage imageName
             * @property {string|null} [imageBase64] UpdateImage imageBase64
             */

            /**
             * Constructs a new UpdateImage.
             * @memberof PlatformAdminApi.UpdateSkuRequest
             * @classdesc Represents an UpdateImage.
             * @implements IUpdateImage
             * @constructor
             * @param {PlatformAdminApi.UpdateSkuRequest.IUpdateImage=} [properties] Properties to set
             */
            function UpdateImage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateImage imageName.
             * @member {string} imageName
             * @memberof PlatformAdminApi.UpdateSkuRequest.UpdateImage
             * @instance
             */
            UpdateImage.prototype.imageName = "";

            /**
             * UpdateImage imageBase64.
             * @member {string} imageBase64
             * @memberof PlatformAdminApi.UpdateSkuRequest.UpdateImage
             * @instance
             */
            UpdateImage.prototype.imageBase64 = "";

            /**
             * Encodes the specified UpdateImage message. Does not implicitly {@link PlatformAdminApi.UpdateSkuRequest.UpdateImage.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.UpdateSkuRequest.UpdateImage
             * @static
             * @param {PlatformAdminApi.UpdateSkuRequest.IUpdateImage} message UpdateImage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateImage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.imageName != null && Object.hasOwnProperty.call(message, "imageName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageName);
                if (message.imageBase64 != null && Object.hasOwnProperty.call(message, "imageBase64"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageBase64);
                return writer;
            };

            /**
             * Decodes an UpdateImage message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.UpdateSkuRequest.UpdateImage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.UpdateSkuRequest.UpdateImage} UpdateImage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateImage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.UpdateSkuRequest.UpdateImage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.imageName = reader.string();
                        break;
                    case 2:
                        message.imageBase64 = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return UpdateImage;
        })();

        return UpdateSkuRequest;
    })();

    PlatformAdminApi.TemplatesService = (function() {

        /**
         * Constructs a new TemplatesService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function TemplatesService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (TemplatesService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TemplatesService;

        /**
         * Callback as used by {@link PlatformAdminApi.TemplatesService#retrieveTemplates}.
         * @memberof PlatformAdminApi.TemplatesService
         * @typedef RetrieveTemplatesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TemplatesList} [response] TemplatesList
         */

        /**
         * Calls RetrieveTemplates.
         * @function retrieveTemplates
         * @memberof PlatformAdminApi.TemplatesService
         * @instance
         * @param {PlatformAdminApi.ITemplatesRequest} request TemplatesRequest message or plain object
         * @param {PlatformAdminApi.TemplatesService.RetrieveTemplatesCallback} callback Node-style callback called with the error, if any, and TemplatesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TemplatesService.prototype.retrieveTemplates = function retrieveTemplates(request, callback) {
            return this.rpcCall(retrieveTemplates, $root.PlatformAdminApi.TemplatesRequest, $root.PlatformAdminApi.TemplatesList, request, callback);
        }, "name", { value: "RetrieveTemplates" });

        /**
         * Calls RetrieveTemplates.
         * @function retrieveTemplates
         * @memberof PlatformAdminApi.TemplatesService
         * @instance
         * @param {PlatformAdminApi.ITemplatesRequest} request TemplatesRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TemplatesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TemplatesService#findParentTemplateById}.
         * @memberof PlatformAdminApi.TemplatesService
         * @typedef FindParentTemplateByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TemplatesParentItem} [response] TemplatesParentItem
         */

        /**
         * Calls FindParentTemplateById.
         * @function findParentTemplateById
         * @memberof PlatformAdminApi.TemplatesService
         * @instance
         * @param {PlatformAdminApi.ITemplatesParentItemRequest} request TemplatesParentItemRequest message or plain object
         * @param {PlatformAdminApi.TemplatesService.FindParentTemplateByIdCallback} callback Node-style callback called with the error, if any, and TemplatesParentItem
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TemplatesService.prototype.findParentTemplateById = function findParentTemplateById(request, callback) {
            return this.rpcCall(findParentTemplateById, $root.PlatformAdminApi.TemplatesParentItemRequest, $root.PlatformAdminApi.TemplatesParentItem, request, callback);
        }, "name", { value: "FindParentTemplateById" });

        /**
         * Calls FindParentTemplateById.
         * @function findParentTemplateById
         * @memberof PlatformAdminApi.TemplatesService
         * @instance
         * @param {PlatformAdminApi.ITemplatesParentItemRequest} request TemplatesParentItemRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TemplatesParentItem>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.TemplatesService#findChildTemplateById}.
         * @memberof PlatformAdminApi.TemplatesService
         * @typedef FindChildTemplateByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.TemplatesChildItem} [response] TemplatesChildItem
         */

        /**
         * Calls FindChildTemplateById.
         * @function findChildTemplateById
         * @memberof PlatformAdminApi.TemplatesService
         * @instance
         * @param {PlatformAdminApi.ITemplatesChildItemRequest} request TemplatesChildItemRequest message or plain object
         * @param {PlatformAdminApi.TemplatesService.FindChildTemplateByIdCallback} callback Node-style callback called with the error, if any, and TemplatesChildItem
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(TemplatesService.prototype.findChildTemplateById = function findChildTemplateById(request, callback) {
            return this.rpcCall(findChildTemplateById, $root.PlatformAdminApi.TemplatesChildItemRequest, $root.PlatformAdminApi.TemplatesChildItem, request, callback);
        }, "name", { value: "FindChildTemplateById" });

        /**
         * Calls FindChildTemplateById.
         * @function findChildTemplateById
         * @memberof PlatformAdminApi.TemplatesService
         * @instance
         * @param {PlatformAdminApi.ITemplatesChildItemRequest} request TemplatesChildItemRequest message or plain object
         * @returns {Promise<PlatformAdminApi.TemplatesChildItem>} Promise
         * @variation 2
         */

        return TemplatesService;
    })();

    PlatformAdminApi.TemplatesList = (function() {

        /**
         * Properties of a TemplatesList.
         * @memberof PlatformAdminApi
         * @interface ITemplatesList
         * @property {Array.<PlatformAdminApi.ITemplatesParentListItem>|null} [templates] TemplatesList templates
         * @property {number|null} [total] TemplatesList total
         */

        /**
         * Constructs a new TemplatesList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesList.
         * @implements ITemplatesList
         * @constructor
         * @param {PlatformAdminApi.ITemplatesList=} [properties] Properties to set
         */
        function TemplatesList(properties) {
            this.templates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesList templates.
         * @member {Array.<PlatformAdminApi.ITemplatesParentListItem>} templates
         * @memberof PlatformAdminApi.TemplatesList
         * @instance
         */
        TemplatesList.prototype.templates = $util.emptyArray;

        /**
         * TemplatesList total.
         * @member {number} total
         * @memberof PlatformAdminApi.TemplatesList
         * @instance
         */
        TemplatesList.prototype.total = 0;

        /**
         * Encodes the specified TemplatesList message. Does not implicitly {@link PlatformAdminApi.TemplatesList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesList
         * @static
         * @param {PlatformAdminApi.ITemplatesList} message TemplatesList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.templates != null && message.templates.length)
                for (var i = 0; i < message.templates.length; ++i)
                    $root.PlatformAdminApi.TemplatesParentListItem.encode(message.templates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
            return writer;
        };

        /**
         * Decodes a TemplatesList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesList} TemplatesList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.templates && message.templates.length))
                        message.templates = [];
                    message.templates.push($root.PlatformAdminApi.TemplatesParentListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TemplatesList;
    })();

    PlatformAdminApi.TemplatesParentListItem = (function() {

        /**
         * Properties of a TemplatesParentListItem.
         * @memberof PlatformAdminApi
         * @interface ITemplatesParentListItem
         * @property {number|null} [id] TemplatesParentListItem id
         * @property {string|null} [title] TemplatesParentListItem title
         * @property {number|null} [ruleId] TemplatesParentListItem ruleId
         * @property {string|null} [activationDate] TemplatesParentListItem activationDate
         * @property {string|null} [deactivationDate] TemplatesParentListItem deactivationDate
         * @property {boolean|null} [isActive] TemplatesParentListItem isActive
         * @property {Array.<PlatformAdminApi.ITemplatesChildrenListItem>|null} [items] TemplatesParentListItem items
         */

        /**
         * Constructs a new TemplatesParentListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesParentListItem.
         * @implements ITemplatesParentListItem
         * @constructor
         * @param {PlatformAdminApi.ITemplatesParentListItem=} [properties] Properties to set
         */
        function TemplatesParentListItem(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesParentListItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @instance
         */
        TemplatesParentListItem.prototype.id = 0;

        /**
         * TemplatesParentListItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @instance
         */
        TemplatesParentListItem.prototype.title = "";

        /**
         * TemplatesParentListItem ruleId.
         * @member {number} ruleId
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @instance
         */
        TemplatesParentListItem.prototype.ruleId = 0;

        /**
         * TemplatesParentListItem activationDate.
         * @member {string} activationDate
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @instance
         */
        TemplatesParentListItem.prototype.activationDate = "";

        /**
         * TemplatesParentListItem deactivationDate.
         * @member {string} deactivationDate
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @instance
         */
        TemplatesParentListItem.prototype.deactivationDate = "";

        /**
         * TemplatesParentListItem isActive.
         * @member {boolean} isActive
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @instance
         */
        TemplatesParentListItem.prototype.isActive = false;

        /**
         * TemplatesParentListItem items.
         * @member {Array.<PlatformAdminApi.ITemplatesChildrenListItem>} items
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @instance
         */
        TemplatesParentListItem.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified TemplatesParentListItem message. Does not implicitly {@link PlatformAdminApi.TemplatesParentListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @static
         * @param {PlatformAdminApi.ITemplatesParentListItem} message TemplatesParentListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesParentListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.ruleId != null && Object.hasOwnProperty.call(message, "ruleId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ruleId);
            if (message.activationDate != null && Object.hasOwnProperty.call(message, "activationDate"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.activationDate);
            if (message.deactivationDate != null && Object.hasOwnProperty.call(message, "deactivationDate"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deactivationDate);
            if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isActive);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformAdminApi.TemplatesChildrenListItem.encode(message.items[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TemplatesParentListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesParentListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesParentListItem} TemplatesParentListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesParentListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesParentListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.ruleId = reader.int32();
                    break;
                case 4:
                    message.activationDate = reader.string();
                    break;
                case 5:
                    message.deactivationDate = reader.string();
                    break;
                case 6:
                    message.isActive = reader.bool();
                    break;
                case 7:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformAdminApi.TemplatesChildrenListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TemplatesParentListItem;
    })();

    PlatformAdminApi.TemplatesChildrenListItem = (function() {

        /**
         * Properties of a TemplatesChildrenListItem.
         * @memberof PlatformAdminApi
         * @interface ITemplatesChildrenListItem
         * @property {number|null} [id] TemplatesChildrenListItem id
         * @property {string|null} [title] TemplatesChildrenListItem title
         * @property {number|null} [count] TemplatesChildrenListItem count
         * @property {number|null} [parentRuleId] TemplatesChildrenListItem parentRuleId
         * @property {number|null} [parentId] TemplatesChildrenListItem parentId
         */

        /**
         * Constructs a new TemplatesChildrenListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesChildrenListItem.
         * @implements ITemplatesChildrenListItem
         * @constructor
         * @param {PlatformAdminApi.ITemplatesChildrenListItem=} [properties] Properties to set
         */
        function TemplatesChildrenListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesChildrenListItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.TemplatesChildrenListItem
         * @instance
         */
        TemplatesChildrenListItem.prototype.id = 0;

        /**
         * TemplatesChildrenListItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.TemplatesChildrenListItem
         * @instance
         */
        TemplatesChildrenListItem.prototype.title = "";

        /**
         * TemplatesChildrenListItem count.
         * @member {number} count
         * @memberof PlatformAdminApi.TemplatesChildrenListItem
         * @instance
         */
        TemplatesChildrenListItem.prototype.count = 0;

        /**
         * TemplatesChildrenListItem parentRuleId.
         * @member {number} parentRuleId
         * @memberof PlatformAdminApi.TemplatesChildrenListItem
         * @instance
         */
        TemplatesChildrenListItem.prototype.parentRuleId = 0;

        /**
         * TemplatesChildrenListItem parentId.
         * @member {number} parentId
         * @memberof PlatformAdminApi.TemplatesChildrenListItem
         * @instance
         */
        TemplatesChildrenListItem.prototype.parentId = 0;

        /**
         * Encodes the specified TemplatesChildrenListItem message. Does not implicitly {@link PlatformAdminApi.TemplatesChildrenListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesChildrenListItem
         * @static
         * @param {PlatformAdminApi.ITemplatesChildrenListItem} message TemplatesChildrenListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesChildrenListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.count);
            if (message.parentRuleId != null && Object.hasOwnProperty.call(message, "parentRuleId"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.parentRuleId);
            if (message.parentId != null && Object.hasOwnProperty.call(message, "parentId"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.parentId);
            return writer;
        };

        /**
         * Decodes a TemplatesChildrenListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesChildrenListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesChildrenListItem} TemplatesChildrenListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesChildrenListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesChildrenListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.count = reader.int32();
                    break;
                case 4:
                    message.parentRuleId = reader.int32();
                    break;
                case 5:
                    message.parentId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TemplatesChildrenListItem;
    })();

    PlatformAdminApi.TemplatesRequest = (function() {

        /**
         * Properties of a TemplatesRequest.
         * @memberof PlatformAdminApi
         * @interface ITemplatesRequest
         * @property {number|null} [limit] TemplatesRequest limit
         * @property {number|null} [page] TemplatesRequest page
         * @property {PlatformAdminApi.TemplatesRequest.IFilterOptions|null} [filterOptions] TemplatesRequest filterOptions
         */

        /**
         * Constructs a new TemplatesRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesRequest.
         * @implements ITemplatesRequest
         * @constructor
         * @param {PlatformAdminApi.ITemplatesRequest=} [properties] Properties to set
         */
        function TemplatesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.TemplatesRequest
         * @instance
         */
        TemplatesRequest.prototype.limit = 0;

        /**
         * TemplatesRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.TemplatesRequest
         * @instance
         */
        TemplatesRequest.prototype.page = 0;

        /**
         * TemplatesRequest filterOptions.
         * @member {PlatformAdminApi.TemplatesRequest.IFilterOptions|null|undefined} filterOptions
         * @memberof PlatformAdminApi.TemplatesRequest
         * @instance
         */
        TemplatesRequest.prototype.filterOptions = null;

        /**
         * Encodes the specified TemplatesRequest message. Does not implicitly {@link PlatformAdminApi.TemplatesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesRequest
         * @static
         * @param {PlatformAdminApi.ITemplatesRequest} message TemplatesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.filterOptions != null && Object.hasOwnProperty.call(message, "filterOptions"))
                $root.PlatformAdminApi.TemplatesRequest.FilterOptions.encode(message.filterOptions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TemplatesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesRequest} TemplatesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.filterOptions = $root.PlatformAdminApi.TemplatesRequest.FilterOptions.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        TemplatesRequest.FilterOptions = (function() {

            /**
             * Properties of a FilterOptions.
             * @memberof PlatformAdminApi.TemplatesRequest
             * @interface IFilterOptions
             * @property {boolean|null} [activated] FilterOptions activated
             */

            /**
             * Constructs a new FilterOptions.
             * @memberof PlatformAdminApi.TemplatesRequest
             * @classdesc Represents a FilterOptions.
             * @implements IFilterOptions
             * @constructor
             * @param {PlatformAdminApi.TemplatesRequest.IFilterOptions=} [properties] Properties to set
             */
            function FilterOptions(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FilterOptions activated.
             * @member {boolean} activated
             * @memberof PlatformAdminApi.TemplatesRequest.FilterOptions
             * @instance
             */
            FilterOptions.prototype.activated = false;

            /**
             * Encodes the specified FilterOptions message. Does not implicitly {@link PlatformAdminApi.TemplatesRequest.FilterOptions.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.TemplatesRequest.FilterOptions
             * @static
             * @param {PlatformAdminApi.TemplatesRequest.IFilterOptions} message FilterOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FilterOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.activated != null && Object.hasOwnProperty.call(message, "activated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.activated);
                return writer;
            };

            /**
             * Decodes a FilterOptions message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.TemplatesRequest.FilterOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.TemplatesRequest.FilterOptions} FilterOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FilterOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesRequest.FilterOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.activated = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return FilterOptions;
        })();

        return TemplatesRequest;
    })();

    PlatformAdminApi.TemplatesParentItem = (function() {

        /**
         * Properties of a TemplatesParentItem.
         * @memberof PlatformAdminApi
         * @interface ITemplatesParentItem
         * @property {number|null} [id] TemplatesParentItem id
         * @property {string|null} [title] TemplatesParentItem title
         * @property {string|null} [activationDate] TemplatesParentItem activationDate
         * @property {string|null} [deactivationDate] TemplatesParentItem deactivationDate
         * @property {string|null} [qr] TemplatesParentItem qr
         * @property {string|null} [image] TemplatesParentItem image
         * @property {boolean|null} [isTracked] TemplatesParentItem isTracked
         * @property {boolean|null} [enabled] TemplatesParentItem enabled
         */

        /**
         * Constructs a new TemplatesParentItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesParentItem.
         * @implements ITemplatesParentItem
         * @constructor
         * @param {PlatformAdminApi.ITemplatesParentItem=} [properties] Properties to set
         */
        function TemplatesParentItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesParentItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.id = 0;

        /**
         * TemplatesParentItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.title = "";

        /**
         * TemplatesParentItem activationDate.
         * @member {string} activationDate
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.activationDate = "";

        /**
         * TemplatesParentItem deactivationDate.
         * @member {string} deactivationDate
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.deactivationDate = "";

        /**
         * TemplatesParentItem qr.
         * @member {string} qr
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.qr = "";

        /**
         * TemplatesParentItem image.
         * @member {string} image
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.image = "";

        /**
         * TemplatesParentItem isTracked.
         * @member {boolean} isTracked
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.isTracked = false;

        /**
         * TemplatesParentItem enabled.
         * @member {boolean} enabled
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @instance
         */
        TemplatesParentItem.prototype.enabled = false;

        /**
         * Encodes the specified TemplatesParentItem message. Does not implicitly {@link PlatformAdminApi.TemplatesParentItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @static
         * @param {PlatformAdminApi.ITemplatesParentItem} message TemplatesParentItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesParentItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.activationDate != null && Object.hasOwnProperty.call(message, "activationDate"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.activationDate);
            if (message.deactivationDate != null && Object.hasOwnProperty.call(message, "deactivationDate"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.deactivationDate);
            if (message.qr != null && Object.hasOwnProperty.call(message, "qr"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.qr);
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.image);
            if (message.isTracked != null && Object.hasOwnProperty.call(message, "isTracked"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isTracked);
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.enabled);
            return writer;
        };

        /**
         * Decodes a TemplatesParentItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesParentItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesParentItem} TemplatesParentItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesParentItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesParentItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.activationDate = reader.string();
                    break;
                case 4:
                    message.deactivationDate = reader.string();
                    break;
                case 5:
                    message.qr = reader.string();
                    break;
                case 6:
                    message.image = reader.string();
                    break;
                case 7:
                    message.isTracked = reader.bool();
                    break;
                case 8:
                    message.enabled = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TemplatesParentItem;
    })();

    PlatformAdminApi.TemplatesChildItem = (function() {

        /**
         * Properties of a TemplatesChildItem.
         * @memberof PlatformAdminApi
         * @interface ITemplatesChildItem
         * @property {number|null} [id] TemplatesChildItem id
         * @property {string|null} [title] TemplatesChildItem title
         * @property {number|null} [count] TemplatesChildItem count
         * @property {string|null} [image] TemplatesChildItem image
         * @property {string|null} [qr] TemplatesChildItem qr
         */

        /**
         * Constructs a new TemplatesChildItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesChildItem.
         * @implements ITemplatesChildItem
         * @constructor
         * @param {PlatformAdminApi.ITemplatesChildItem=} [properties] Properties to set
         */
        function TemplatesChildItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesChildItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.TemplatesChildItem
         * @instance
         */
        TemplatesChildItem.prototype.id = 0;

        /**
         * TemplatesChildItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.TemplatesChildItem
         * @instance
         */
        TemplatesChildItem.prototype.title = "";

        /**
         * TemplatesChildItem count.
         * @member {number} count
         * @memberof PlatformAdminApi.TemplatesChildItem
         * @instance
         */
        TemplatesChildItem.prototype.count = 0;

        /**
         * TemplatesChildItem image.
         * @member {string} image
         * @memberof PlatformAdminApi.TemplatesChildItem
         * @instance
         */
        TemplatesChildItem.prototype.image = "";

        /**
         * TemplatesChildItem qr.
         * @member {string} qr
         * @memberof PlatformAdminApi.TemplatesChildItem
         * @instance
         */
        TemplatesChildItem.prototype.qr = "";

        /**
         * Encodes the specified TemplatesChildItem message. Does not implicitly {@link PlatformAdminApi.TemplatesChildItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesChildItem
         * @static
         * @param {PlatformAdminApi.ITemplatesChildItem} message TemplatesChildItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesChildItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.count);
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.image);
            if (message.qr != null && Object.hasOwnProperty.call(message, "qr"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.qr);
            return writer;
        };

        /**
         * Decodes a TemplatesChildItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesChildItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesChildItem} TemplatesChildItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesChildItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesChildItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.count = reader.int32();
                    break;
                case 4:
                    message.image = reader.string();
                    break;
                case 5:
                    message.qr = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TemplatesChildItem;
    })();

    PlatformAdminApi.TemplatesParentItemRequest = (function() {

        /**
         * Properties of a TemplatesParentItemRequest.
         * @memberof PlatformAdminApi
         * @interface ITemplatesParentItemRequest
         * @property {number|null} [id] TemplatesParentItemRequest id
         * @property {number|null} [ruleId] TemplatesParentItemRequest ruleId
         */

        /**
         * Constructs a new TemplatesParentItemRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesParentItemRequest.
         * @implements ITemplatesParentItemRequest
         * @constructor
         * @param {PlatformAdminApi.ITemplatesParentItemRequest=} [properties] Properties to set
         */
        function TemplatesParentItemRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesParentItemRequest id.
         * @member {number} id
         * @memberof PlatformAdminApi.TemplatesParentItemRequest
         * @instance
         */
        TemplatesParentItemRequest.prototype.id = 0;

        /**
         * TemplatesParentItemRequest ruleId.
         * @member {number} ruleId
         * @memberof PlatformAdminApi.TemplatesParentItemRequest
         * @instance
         */
        TemplatesParentItemRequest.prototype.ruleId = 0;

        /**
         * Encodes the specified TemplatesParentItemRequest message. Does not implicitly {@link PlatformAdminApi.TemplatesParentItemRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesParentItemRequest
         * @static
         * @param {PlatformAdminApi.ITemplatesParentItemRequest} message TemplatesParentItemRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesParentItemRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.ruleId != null && Object.hasOwnProperty.call(message, "ruleId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ruleId);
            return writer;
        };

        /**
         * Decodes a TemplatesParentItemRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesParentItemRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesParentItemRequest} TemplatesParentItemRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesParentItemRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesParentItemRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 3:
                    message.ruleId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TemplatesParentItemRequest;
    })();

    PlatformAdminApi.TemplatesChildItemRequest = (function() {

        /**
         * Properties of a TemplatesChildItemRequest.
         * @memberof PlatformAdminApi
         * @interface ITemplatesChildItemRequest
         * @property {number|null} [id] TemplatesChildItemRequest id
         * @property {number|null} [parentId] TemplatesChildItemRequest parentId
         */

        /**
         * Constructs a new TemplatesChildItemRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a TemplatesChildItemRequest.
         * @implements ITemplatesChildItemRequest
         * @constructor
         * @param {PlatformAdminApi.ITemplatesChildItemRequest=} [properties] Properties to set
         */
        function TemplatesChildItemRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TemplatesChildItemRequest id.
         * @member {number} id
         * @memberof PlatformAdminApi.TemplatesChildItemRequest
         * @instance
         */
        TemplatesChildItemRequest.prototype.id = 0;

        /**
         * TemplatesChildItemRequest parentId.
         * @member {number} parentId
         * @memberof PlatformAdminApi.TemplatesChildItemRequest
         * @instance
         */
        TemplatesChildItemRequest.prototype.parentId = 0;

        /**
         * Encodes the specified TemplatesChildItemRequest message. Does not implicitly {@link PlatformAdminApi.TemplatesChildItemRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.TemplatesChildItemRequest
         * @static
         * @param {PlatformAdminApi.ITemplatesChildItemRequest} message TemplatesChildItemRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TemplatesChildItemRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.parentId != null && Object.hasOwnProperty.call(message, "parentId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.parentId);
            return writer;
        };

        /**
         * Decodes a TemplatesChildItemRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.TemplatesChildItemRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.TemplatesChildItemRequest} TemplatesChildItemRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TemplatesChildItemRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.TemplatesChildItemRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 3:
                    message.parentId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TemplatesChildItemRequest;
    })();

    PlatformAdminApi.PlacesService = (function() {

        /**
         * Constructs a new PlacesService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PlacesService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PlacesService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PlacesService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PlacesService;

        /**
         * Callback as used by {@link PlatformAdminApi.PlacesService#retrievePlaces}.
         * @memberof PlatformAdminApi.PlacesService
         * @typedef RetrievePlacesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WarehousesList} [response] WarehousesList
         */

        /**
         * Calls RetrievePlaces.
         * @function retrievePlaces
         * @memberof PlatformAdminApi.PlacesService
         * @instance
         * @param {PlatformAdminApi.IPlacesRequest} request PlacesRequest message or plain object
         * @param {PlatformAdminApi.PlacesService.RetrievePlacesCallback} callback Node-style callback called with the error, if any, and WarehousesList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PlacesService.prototype.retrievePlaces = function retrievePlaces(request, callback) {
            return this.rpcCall(retrievePlaces, $root.PlatformAdminApi.PlacesRequest, $root.PlatformAdminApi.WarehousesList, request, callback);
        }, "name", { value: "RetrievePlaces" });

        /**
         * Calls RetrievePlaces.
         * @function retrievePlaces
         * @memberof PlatformAdminApi.PlacesService
         * @instance
         * @param {PlatformAdminApi.IPlacesRequest} request PlacesRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WarehousesList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.PlacesService#findPlaceById}.
         * @memberof PlatformAdminApi.PlacesService
         * @typedef FindPlaceByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.PlaceItem} [response] PlaceItem
         */

        /**
         * Calls FindPlaceById.
         * @function findPlaceById
         * @memberof PlatformAdminApi.PlacesService
         * @instance
         * @param {PlatformAdminApi.IPlaceRequest} request PlaceRequest message or plain object
         * @param {PlatformAdminApi.PlacesService.FindPlaceByIdCallback} callback Node-style callback called with the error, if any, and PlaceItem
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PlacesService.prototype.findPlaceById = function findPlaceById(request, callback) {
            return this.rpcCall(findPlaceById, $root.PlatformAdminApi.PlaceRequest, $root.PlatformAdminApi.PlaceItem, request, callback);
        }, "name", { value: "FindPlaceById" });

        /**
         * Calls FindPlaceById.
         * @function findPlaceById
         * @memberof PlatformAdminApi.PlacesService
         * @instance
         * @param {PlatformAdminApi.IPlaceRequest} request PlaceRequest message or plain object
         * @returns {Promise<PlatformAdminApi.PlaceItem>} Promise
         * @variation 2
         */

        return PlacesService;
    })();

    PlatformAdminApi.WarehousesList = (function() {

        /**
         * Properties of a WarehousesList.
         * @memberof PlatformAdminApi
         * @interface IWarehousesList
         * @property {Array.<PlatformAdminApi.IWarehousesListItem>|null} [warehouses] WarehousesList warehouses
         * @property {number|null} [total] WarehousesList total
         */

        /**
         * Constructs a new WarehousesList.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WarehousesList.
         * @implements IWarehousesList
         * @constructor
         * @param {PlatformAdminApi.IWarehousesList=} [properties] Properties to set
         */
        function WarehousesList(properties) {
            this.warehouses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WarehousesList warehouses.
         * @member {Array.<PlatformAdminApi.IWarehousesListItem>} warehouses
         * @memberof PlatformAdminApi.WarehousesList
         * @instance
         */
        WarehousesList.prototype.warehouses = $util.emptyArray;

        /**
         * WarehousesList total.
         * @member {number} total
         * @memberof PlatformAdminApi.WarehousesList
         * @instance
         */
        WarehousesList.prototype.total = 0;

        /**
         * Encodes the specified WarehousesList message. Does not implicitly {@link PlatformAdminApi.WarehousesList.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WarehousesList
         * @static
         * @param {PlatformAdminApi.IWarehousesList} message WarehousesList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WarehousesList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.warehouses != null && message.warehouses.length)
                for (var i = 0; i < message.warehouses.length; ++i)
                    $root.PlatformAdminApi.WarehousesListItem.encode(message.warehouses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
            return writer;
        };

        /**
         * Decodes a WarehousesList message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WarehousesList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WarehousesList} WarehousesList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WarehousesList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WarehousesList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.warehouses && message.warehouses.length))
                        message.warehouses = [];
                    message.warehouses.push($root.PlatformAdminApi.WarehousesListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WarehousesList;
    })();

    PlatformAdminApi.WarehousesListItem = (function() {

        /**
         * Properties of a WarehousesListItem.
         * @memberof PlatformAdminApi
         * @interface IWarehousesListItem
         * @property {number|null} [id] WarehousesListItem id
         * @property {string|null} [title] WarehousesListItem title
         * @property {string|null} [type] WarehousesListItem type
         * @property {Array.<PlatformAdminApi.IPlacesListItem>|null} [items] WarehousesListItem items
         */

        /**
         * Constructs a new WarehousesListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WarehousesListItem.
         * @implements IWarehousesListItem
         * @constructor
         * @param {PlatformAdminApi.IWarehousesListItem=} [properties] Properties to set
         */
        function WarehousesListItem(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WarehousesListItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.WarehousesListItem
         * @instance
         */
        WarehousesListItem.prototype.id = 0;

        /**
         * WarehousesListItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.WarehousesListItem
         * @instance
         */
        WarehousesListItem.prototype.title = "";

        /**
         * WarehousesListItem type.
         * @member {string} type
         * @memberof PlatformAdminApi.WarehousesListItem
         * @instance
         */
        WarehousesListItem.prototype.type = "";

        /**
         * WarehousesListItem items.
         * @member {Array.<PlatformAdminApi.IPlacesListItem>} items
         * @memberof PlatformAdminApi.WarehousesListItem
         * @instance
         */
        WarehousesListItem.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified WarehousesListItem message. Does not implicitly {@link PlatformAdminApi.WarehousesListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WarehousesListItem
         * @static
         * @param {PlatformAdminApi.IWarehousesListItem} message WarehousesListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WarehousesListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformAdminApi.PlacesListItem.encode(message.items[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a WarehousesListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WarehousesListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WarehousesListItem} WarehousesListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WarehousesListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WarehousesListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformAdminApi.PlacesListItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WarehousesListItem;
    })();

    PlatformAdminApi.PlacesListItem = (function() {

        /**
         * Properties of a PlacesListItem.
         * @memberof PlatformAdminApi
         * @interface IPlacesListItem
         * @property {number|null} [id] PlacesListItem id
         * @property {string|null} [zone] PlacesListItem zone
         * @property {string|null} [type] PlacesListItem type
         * @property {number|null} [warehouseId] PlacesListItem warehouseId
         */

        /**
         * Constructs a new PlacesListItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PlacesListItem.
         * @implements IPlacesListItem
         * @constructor
         * @param {PlatformAdminApi.IPlacesListItem=} [properties] Properties to set
         */
        function PlacesListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlacesListItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.PlacesListItem
         * @instance
         */
        PlacesListItem.prototype.id = 0;

        /**
         * PlacesListItem zone.
         * @member {string} zone
         * @memberof PlatformAdminApi.PlacesListItem
         * @instance
         */
        PlacesListItem.prototype.zone = "";

        /**
         * PlacesListItem type.
         * @member {string} type
         * @memberof PlatformAdminApi.PlacesListItem
         * @instance
         */
        PlacesListItem.prototype.type = "";

        /**
         * PlacesListItem warehouseId.
         * @member {number} warehouseId
         * @memberof PlatformAdminApi.PlacesListItem
         * @instance
         */
        PlacesListItem.prototype.warehouseId = 0;

        /**
         * Encodes the specified PlacesListItem message. Does not implicitly {@link PlatformAdminApi.PlacesListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PlacesListItem
         * @static
         * @param {PlatformAdminApi.IPlacesListItem} message PlacesListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlacesListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.zone != null && Object.hasOwnProperty.call(message, "zone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.zone);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.warehouseId != null && Object.hasOwnProperty.call(message, "warehouseId"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.warehouseId);
            return writer;
        };

        /**
         * Decodes a PlacesListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PlacesListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PlacesListItem} PlacesListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlacesListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PlacesListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.zone = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.warehouseId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PlacesListItem;
    })();

    PlatformAdminApi.PlacesRequest = (function() {

        /**
         * Properties of a PlacesRequest.
         * @memberof PlatformAdminApi
         * @interface IPlacesRequest
         * @property {number|null} [limit] PlacesRequest limit
         * @property {number|null} [page] PlacesRequest page
         * @property {PlatformAdminApi.PlacesRequest.IFilterOptions|null} [filterOptions] PlacesRequest filterOptions
         */

        /**
         * Constructs a new PlacesRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PlacesRequest.
         * @implements IPlacesRequest
         * @constructor
         * @param {PlatformAdminApi.IPlacesRequest=} [properties] Properties to set
         */
        function PlacesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlacesRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.PlacesRequest
         * @instance
         */
        PlacesRequest.prototype.limit = 0;

        /**
         * PlacesRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.PlacesRequest
         * @instance
         */
        PlacesRequest.prototype.page = 0;

        /**
         * PlacesRequest filterOptions.
         * @member {PlatformAdminApi.PlacesRequest.IFilterOptions|null|undefined} filterOptions
         * @memberof PlatformAdminApi.PlacesRequest
         * @instance
         */
        PlacesRequest.prototype.filterOptions = null;

        /**
         * Encodes the specified PlacesRequest message. Does not implicitly {@link PlatformAdminApi.PlacesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PlacesRequest
         * @static
         * @param {PlatformAdminApi.IPlacesRequest} message PlacesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlacesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.filterOptions != null && Object.hasOwnProperty.call(message, "filterOptions"))
                $root.PlatformAdminApi.PlacesRequest.FilterOptions.encode(message.filterOptions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PlacesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PlacesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PlacesRequest} PlacesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlacesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PlacesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.filterOptions = $root.PlatformAdminApi.PlacesRequest.FilterOptions.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlacesRequest.FilterOptions = (function() {

            /**
             * Properties of a FilterOptions.
             * @memberof PlatformAdminApi.PlacesRequest
             * @interface IFilterOptions
             * @property {string|null} [title] FilterOptions title
             */

            /**
             * Constructs a new FilterOptions.
             * @memberof PlatformAdminApi.PlacesRequest
             * @classdesc Represents a FilterOptions.
             * @implements IFilterOptions
             * @constructor
             * @param {PlatformAdminApi.PlacesRequest.IFilterOptions=} [properties] Properties to set
             */
            function FilterOptions(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FilterOptions title.
             * @member {string} title
             * @memberof PlatformAdminApi.PlacesRequest.FilterOptions
             * @instance
             */
            FilterOptions.prototype.title = "";

            /**
             * Encodes the specified FilterOptions message. Does not implicitly {@link PlatformAdminApi.PlacesRequest.FilterOptions.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.PlacesRequest.FilterOptions
             * @static
             * @param {PlatformAdminApi.PlacesRequest.IFilterOptions} message FilterOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FilterOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                return writer;
            };

            /**
             * Decodes a FilterOptions message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.PlacesRequest.FilterOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.PlacesRequest.FilterOptions} FilterOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FilterOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PlacesRequest.FilterOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.title = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return FilterOptions;
        })();

        return PlacesRequest;
    })();

    PlatformAdminApi.PlaceItem = (function() {

        /**
         * Properties of a PlaceItem.
         * @memberof PlatformAdminApi
         * @interface IPlaceItem
         * @property {number|null} [id] PlaceItem id
         * @property {string|null} [zone] PlaceItem zone
         * @property {string|null} [type] PlaceItem type
         * @property {string|null} [qr] PlaceItem qr
         */

        /**
         * Constructs a new PlaceItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PlaceItem.
         * @implements IPlaceItem
         * @constructor
         * @param {PlatformAdminApi.IPlaceItem=} [properties] Properties to set
         */
        function PlaceItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlaceItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.PlaceItem
         * @instance
         */
        PlaceItem.prototype.id = 0;

        /**
         * PlaceItem zone.
         * @member {string} zone
         * @memberof PlatformAdminApi.PlaceItem
         * @instance
         */
        PlaceItem.prototype.zone = "";

        /**
         * PlaceItem type.
         * @member {string} type
         * @memberof PlatformAdminApi.PlaceItem
         * @instance
         */
        PlaceItem.prototype.type = "";

        /**
         * PlaceItem qr.
         * @member {string} qr
         * @memberof PlatformAdminApi.PlaceItem
         * @instance
         */
        PlaceItem.prototype.qr = "";

        /**
         * Encodes the specified PlaceItem message. Does not implicitly {@link PlatformAdminApi.PlaceItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PlaceItem
         * @static
         * @param {PlatformAdminApi.IPlaceItem} message PlaceItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaceItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.zone != null && Object.hasOwnProperty.call(message, "zone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.zone);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.qr != null && Object.hasOwnProperty.call(message, "qr"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.qr);
            return writer;
        };

        /**
         * Decodes a PlaceItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PlaceItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PlaceItem} PlaceItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaceItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PlaceItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.zone = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                case 4:
                    message.qr = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PlaceItem;
    })();

    PlatformAdminApi.PlaceRequest = (function() {

        /**
         * Properties of a PlaceRequest.
         * @memberof PlatformAdminApi
         * @interface IPlaceRequest
         * @property {number|null} [id] PlaceRequest id
         * @property {number|null} [warehouseId] PlaceRequest warehouseId
         */

        /**
         * Constructs a new PlaceRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a PlaceRequest.
         * @implements IPlaceRequest
         * @constructor
         * @param {PlatformAdminApi.IPlaceRequest=} [properties] Properties to set
         */
        function PlaceRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlaceRequest id.
         * @member {number} id
         * @memberof PlatformAdminApi.PlaceRequest
         * @instance
         */
        PlaceRequest.prototype.id = 0;

        /**
         * PlaceRequest warehouseId.
         * @member {number} warehouseId
         * @memberof PlatformAdminApi.PlaceRequest
         * @instance
         */
        PlaceRequest.prototype.warehouseId = 0;

        /**
         * Encodes the specified PlaceRequest message. Does not implicitly {@link PlatformAdminApi.PlaceRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.PlaceRequest
         * @static
         * @param {PlatformAdminApi.IPlaceRequest} message PlaceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.warehouseId != null && Object.hasOwnProperty.call(message, "warehouseId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.warehouseId);
            return writer;
        };

        /**
         * Decodes a PlaceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.PlaceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.PlaceRequest} PlaceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.PlaceRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.warehouseId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PlaceRequest;
    })();

    PlatformAdminApi.WarehousesService = (function() {

        /**
         * Constructs a new WarehousesService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WarehousesService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function WarehousesService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (WarehousesService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WarehousesService;

        /**
         * Callback as used by {@link PlatformAdminApi.WarehousesService#retrieveWarehouses}.
         * @memberof PlatformAdminApi.WarehousesService
         * @typedef RetrieveWarehousesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.WarehousesCatalog} [response] WarehousesCatalog
         */

        /**
         * Calls RetrieveWarehouses.
         * @function retrieveWarehouses
         * @memberof PlatformAdminApi.WarehousesService
         * @instance
         * @param {PlatformAdminApi.IWarehousesRequest} request WarehousesRequest message or plain object
         * @param {PlatformAdminApi.WarehousesService.RetrieveWarehousesCallback} callback Node-style callback called with the error, if any, and WarehousesCatalog
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WarehousesService.prototype.retrieveWarehouses = function retrieveWarehouses(request, callback) {
            return this.rpcCall(retrieveWarehouses, $root.PlatformAdminApi.WarehousesRequest, $root.PlatformAdminApi.WarehousesCatalog, request, callback);
        }, "name", { value: "RetrieveWarehouses" });

        /**
         * Calls RetrieveWarehouses.
         * @function retrieveWarehouses
         * @memberof PlatformAdminApi.WarehousesService
         * @instance
         * @param {PlatformAdminApi.IWarehousesRequest} request WarehousesRequest message or plain object
         * @returns {Promise<PlatformAdminApi.WarehousesCatalog>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.WarehousesService#findWarehouseById}.
         * @memberof PlatformAdminApi.WarehousesService
         * @typedef FindWarehouseByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.Warehouse} [response] Warehouse
         */

        /**
         * Calls FindWarehouseById.
         * @function findWarehouseById
         * @memberof PlatformAdminApi.WarehousesService
         * @instance
         * @param {PlatformAdminApi.IIUuid} request IUuid message or plain object
         * @param {PlatformAdminApi.WarehousesService.FindWarehouseByIdCallback} callback Node-style callback called with the error, if any, and Warehouse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WarehousesService.prototype.findWarehouseById = function findWarehouseById(request, callback) {
            return this.rpcCall(findWarehouseById, $root.PlatformAdminApi.IUuid, $root.PlatformAdminApi.Warehouse, request, callback);
        }, "name", { value: "FindWarehouseById" });

        /**
         * Calls FindWarehouseById.
         * @function findWarehouseById
         * @memberof PlatformAdminApi.WarehousesService
         * @instance
         * @param {PlatformAdminApi.IIUuid} request IUuid message or plain object
         * @returns {Promise<PlatformAdminApi.Warehouse>} Promise
         * @variation 2
         */

        return WarehousesService;
    })();

    PlatformAdminApi.WarehousesCatalog = (function() {

        /**
         * Properties of a WarehousesCatalog.
         * @memberof PlatformAdminApi
         * @interface IWarehousesCatalog
         * @property {Array.<PlatformAdminApi.IWarehousesListItem>|null} [warehouses] WarehousesCatalog warehouses
         * @property {number|null} [total] WarehousesCatalog total
         */

        /**
         * Constructs a new WarehousesCatalog.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WarehousesCatalog.
         * @implements IWarehousesCatalog
         * @constructor
         * @param {PlatformAdminApi.IWarehousesCatalog=} [properties] Properties to set
         */
        function WarehousesCatalog(properties) {
            this.warehouses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WarehousesCatalog warehouses.
         * @member {Array.<PlatformAdminApi.IWarehousesListItem>} warehouses
         * @memberof PlatformAdminApi.WarehousesCatalog
         * @instance
         */
        WarehousesCatalog.prototype.warehouses = $util.emptyArray;

        /**
         * WarehousesCatalog total.
         * @member {number} total
         * @memberof PlatformAdminApi.WarehousesCatalog
         * @instance
         */
        WarehousesCatalog.prototype.total = 0;

        /**
         * Encodes the specified WarehousesCatalog message. Does not implicitly {@link PlatformAdminApi.WarehousesCatalog.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WarehousesCatalog
         * @static
         * @param {PlatformAdminApi.IWarehousesCatalog} message WarehousesCatalog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WarehousesCatalog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.warehouses != null && message.warehouses.length)
                for (var i = 0; i < message.warehouses.length; ++i)
                    $root.PlatformAdminApi.WarehousesListItem.encode(message.warehouses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
            return writer;
        };

        /**
         * Decodes a WarehousesCatalog message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WarehousesCatalog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WarehousesCatalog} WarehousesCatalog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WarehousesCatalog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WarehousesCatalog();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.warehouses && message.warehouses.length))
                        message.warehouses = [];
                    message.warehouses.push($root.PlatformAdminApi.WarehousesListItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WarehousesCatalog;
    })();

    PlatformAdminApi.WarehousesCatalogItem = (function() {

        /**
         * Properties of a WarehousesCatalogItem.
         * @memberof PlatformAdminApi
         * @interface IWarehousesCatalogItem
         * @property {number|null} [id] WarehousesCatalogItem id
         * @property {string|null} [title] WarehousesCatalogItem title
         * @property {string|null} [type] WarehousesCatalogItem type
         */

        /**
         * Constructs a new WarehousesCatalogItem.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WarehousesCatalogItem.
         * @implements IWarehousesCatalogItem
         * @constructor
         * @param {PlatformAdminApi.IWarehousesCatalogItem=} [properties] Properties to set
         */
        function WarehousesCatalogItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WarehousesCatalogItem id.
         * @member {number} id
         * @memberof PlatformAdminApi.WarehousesCatalogItem
         * @instance
         */
        WarehousesCatalogItem.prototype.id = 0;

        /**
         * WarehousesCatalogItem title.
         * @member {string} title
         * @memberof PlatformAdminApi.WarehousesCatalogItem
         * @instance
         */
        WarehousesCatalogItem.prototype.title = "";

        /**
         * WarehousesCatalogItem type.
         * @member {string} type
         * @memberof PlatformAdminApi.WarehousesCatalogItem
         * @instance
         */
        WarehousesCatalogItem.prototype.type = "";

        /**
         * Encodes the specified WarehousesCatalogItem message. Does not implicitly {@link PlatformAdminApi.WarehousesCatalogItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WarehousesCatalogItem
         * @static
         * @param {PlatformAdminApi.IWarehousesCatalogItem} message WarehousesCatalogItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WarehousesCatalogItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            return writer;
        };

        /**
         * Decodes a WarehousesCatalogItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WarehousesCatalogItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WarehousesCatalogItem} WarehousesCatalogItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WarehousesCatalogItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WarehousesCatalogItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WarehousesCatalogItem;
    })();

    PlatformAdminApi.WarehousesRequest = (function() {

        /**
         * Properties of a WarehousesRequest.
         * @memberof PlatformAdminApi
         * @interface IWarehousesRequest
         * @property {number|null} [limit] WarehousesRequest limit
         * @property {number|null} [page] WarehousesRequest page
         */

        /**
         * Constructs a new WarehousesRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a WarehousesRequest.
         * @implements IWarehousesRequest
         * @constructor
         * @param {PlatformAdminApi.IWarehousesRequest=} [properties] Properties to set
         */
        function WarehousesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WarehousesRequest limit.
         * @member {number} limit
         * @memberof PlatformAdminApi.WarehousesRequest
         * @instance
         */
        WarehousesRequest.prototype.limit = 0;

        /**
         * WarehousesRequest page.
         * @member {number} page
         * @memberof PlatformAdminApi.WarehousesRequest
         * @instance
         */
        WarehousesRequest.prototype.page = 0;

        /**
         * Encodes the specified WarehousesRequest message. Does not implicitly {@link PlatformAdminApi.WarehousesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.WarehousesRequest
         * @static
         * @param {PlatformAdminApi.IWarehousesRequest} message WarehousesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WarehousesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            return writer;
        };

        /**
         * Decodes a WarehousesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.WarehousesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.WarehousesRequest} WarehousesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WarehousesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.WarehousesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return WarehousesRequest;
    })();

    PlatformAdminApi.Warehouse = (function() {

        /**
         * Properties of a Warehouse.
         * @memberof PlatformAdminApi
         * @interface IWarehouse
         * @property {number|null} [id] Warehouse id
         * @property {string|null} [title] Warehouse title
         * @property {string|null} [type] Warehouse type
         */

        /**
         * Constructs a new Warehouse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a Warehouse.
         * @implements IWarehouse
         * @constructor
         * @param {PlatformAdminApi.IWarehouse=} [properties] Properties to set
         */
        function Warehouse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Warehouse id.
         * @member {number} id
         * @memberof PlatformAdminApi.Warehouse
         * @instance
         */
        Warehouse.prototype.id = 0;

        /**
         * Warehouse title.
         * @member {string} title
         * @memberof PlatformAdminApi.Warehouse
         * @instance
         */
        Warehouse.prototype.title = "";

        /**
         * Warehouse type.
         * @member {string} type
         * @memberof PlatformAdminApi.Warehouse
         * @instance
         */
        Warehouse.prototype.type = "";

        /**
         * Encodes the specified Warehouse message. Does not implicitly {@link PlatformAdminApi.Warehouse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.Warehouse
         * @static
         * @param {PlatformAdminApi.IWarehouse} message Warehouse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Warehouse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            return writer;
        };

        /**
         * Decodes a Warehouse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.Warehouse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.Warehouse} Warehouse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Warehouse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.Warehouse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Warehouse;
    })();

    PlatformAdminApi.CommunicationChannelsService = (function() {

        /**
         * Constructs a new CommunicationChannelsService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CommunicationChannelsService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CommunicationChannelsService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CommunicationChannelsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CommunicationChannelsService;

        /**
         * Callback as used by {@link PlatformAdminApi.CommunicationChannelsService#enableCommunicationChannel}.
         * @memberof PlatformAdminApi.CommunicationChannelsService
         * @typedef EnableCommunicationChannelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.EnableCommunicationChannelResponse} [response] EnableCommunicationChannelResponse
         */

        /**
         * Calls EnableCommunicationChannel.
         * @function enableCommunicationChannel
         * @memberof PlatformAdminApi.CommunicationChannelsService
         * @instance
         * @param {PlatformAdminApi.IEnableCommunicationChannelRequest} request EnableCommunicationChannelRequest message or plain object
         * @param {PlatformAdminApi.CommunicationChannelsService.EnableCommunicationChannelCallback} callback Node-style callback called with the error, if any, and EnableCommunicationChannelResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationChannelsService.prototype.enableCommunicationChannel = function enableCommunicationChannel(request, callback) {
            return this.rpcCall(enableCommunicationChannel, $root.PlatformAdminApi.EnableCommunicationChannelRequest, $root.PlatformAdminApi.EnableCommunicationChannelResponse, request, callback);
        }, "name", { value: "EnableCommunicationChannel" });

        /**
         * Calls EnableCommunicationChannel.
         * @function enableCommunicationChannel
         * @memberof PlatformAdminApi.CommunicationChannelsService
         * @instance
         * @param {PlatformAdminApi.IEnableCommunicationChannelRequest} request EnableCommunicationChannelRequest message or plain object
         * @returns {Promise<PlatformAdminApi.EnableCommunicationChannelResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformAdminApi.CommunicationChannelsService#disableCommunicationChannel}.
         * @memberof PlatformAdminApi.CommunicationChannelsService
         * @typedef DisableCommunicationChannelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.DisableCommunicationChannelResponse} [response] DisableCommunicationChannelResponse
         */

        /**
         * Calls DisableCommunicationChannel.
         * @function disableCommunicationChannel
         * @memberof PlatformAdminApi.CommunicationChannelsService
         * @instance
         * @param {PlatformAdminApi.IDisableCommunicationChannelRequest} request DisableCommunicationChannelRequest message or plain object
         * @param {PlatformAdminApi.CommunicationChannelsService.DisableCommunicationChannelCallback} callback Node-style callback called with the error, if any, and DisableCommunicationChannelResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationChannelsService.prototype.disableCommunicationChannel = function disableCommunicationChannel(request, callback) {
            return this.rpcCall(disableCommunicationChannel, $root.PlatformAdminApi.DisableCommunicationChannelRequest, $root.PlatformAdminApi.DisableCommunicationChannelResponse, request, callback);
        }, "name", { value: "DisableCommunicationChannel" });

        /**
         * Calls DisableCommunicationChannel.
         * @function disableCommunicationChannel
         * @memberof PlatformAdminApi.CommunicationChannelsService
         * @instance
         * @param {PlatformAdminApi.IDisableCommunicationChannelRequest} request DisableCommunicationChannelRequest message or plain object
         * @returns {Promise<PlatformAdminApi.DisableCommunicationChannelResponse>} Promise
         * @variation 2
         */

        return CommunicationChannelsService;
    })();

    PlatformAdminApi.EnableCommunicationChannelRequest = (function() {

        /**
         * Properties of an EnableCommunicationChannelRequest.
         * @memberof PlatformAdminApi
         * @interface IEnableCommunicationChannelRequest
         * @property {string|null} [ssoId] EnableCommunicationChannelRequest ssoId
         * @property {string|null} [channel] EnableCommunicationChannelRequest channel
         * @property {string|null} [group] EnableCommunicationChannelRequest group
         */

        /**
         * Constructs a new EnableCommunicationChannelRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents an EnableCommunicationChannelRequest.
         * @implements IEnableCommunicationChannelRequest
         * @constructor
         * @param {PlatformAdminApi.IEnableCommunicationChannelRequest=} [properties] Properties to set
         */
        function EnableCommunicationChannelRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnableCommunicationChannelRequest ssoId.
         * @member {string} ssoId
         * @memberof PlatformAdminApi.EnableCommunicationChannelRequest
         * @instance
         */
        EnableCommunicationChannelRequest.prototype.ssoId = "";

        /**
         * EnableCommunicationChannelRequest channel.
         * @member {string} channel
         * @memberof PlatformAdminApi.EnableCommunicationChannelRequest
         * @instance
         */
        EnableCommunicationChannelRequest.prototype.channel = "";

        /**
         * EnableCommunicationChannelRequest group.
         * @member {string} group
         * @memberof PlatformAdminApi.EnableCommunicationChannelRequest
         * @instance
         */
        EnableCommunicationChannelRequest.prototype.group = "";

        /**
         * Encodes the specified EnableCommunicationChannelRequest message. Does not implicitly {@link PlatformAdminApi.EnableCommunicationChannelRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.EnableCommunicationChannelRequest
         * @static
         * @param {PlatformAdminApi.IEnableCommunicationChannelRequest} message EnableCommunicationChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnableCommunicationChannelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ssoId);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.channel);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.group);
            return writer;
        };

        /**
         * Decodes an EnableCommunicationChannelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.EnableCommunicationChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.EnableCommunicationChannelRequest} EnableCommunicationChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnableCommunicationChannelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.EnableCommunicationChannelRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ssoId = reader.string();
                    break;
                case 2:
                    message.channel = reader.string();
                    break;
                case 3:
                    message.group = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return EnableCommunicationChannelRequest;
    })();

    PlatformAdminApi.EnableCommunicationChannelResponse = (function() {

        /**
         * Properties of an EnableCommunicationChannelResponse.
         * @memberof PlatformAdminApi
         * @interface IEnableCommunicationChannelResponse
         */

        /**
         * Constructs a new EnableCommunicationChannelResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents an EnableCommunicationChannelResponse.
         * @implements IEnableCommunicationChannelResponse
         * @constructor
         * @param {PlatformAdminApi.IEnableCommunicationChannelResponse=} [properties] Properties to set
         */
        function EnableCommunicationChannelResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified EnableCommunicationChannelResponse message. Does not implicitly {@link PlatformAdminApi.EnableCommunicationChannelResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.EnableCommunicationChannelResponse
         * @static
         * @param {PlatformAdminApi.IEnableCommunicationChannelResponse} message EnableCommunicationChannelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnableCommunicationChannelResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an EnableCommunicationChannelResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.EnableCommunicationChannelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.EnableCommunicationChannelResponse} EnableCommunicationChannelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnableCommunicationChannelResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.EnableCommunicationChannelResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return EnableCommunicationChannelResponse;
    })();

    PlatformAdminApi.DisableCommunicationChannelRequest = (function() {

        /**
         * Properties of a DisableCommunicationChannelRequest.
         * @memberof PlatformAdminApi
         * @interface IDisableCommunicationChannelRequest
         * @property {string|null} [ssoId] DisableCommunicationChannelRequest ssoId
         * @property {string|null} [channel] DisableCommunicationChannelRequest channel
         * @property {string|null} [group] DisableCommunicationChannelRequest group
         */

        /**
         * Constructs a new DisableCommunicationChannelRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a DisableCommunicationChannelRequest.
         * @implements IDisableCommunicationChannelRequest
         * @constructor
         * @param {PlatformAdminApi.IDisableCommunicationChannelRequest=} [properties] Properties to set
         */
        function DisableCommunicationChannelRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DisableCommunicationChannelRequest ssoId.
         * @member {string} ssoId
         * @memberof PlatformAdminApi.DisableCommunicationChannelRequest
         * @instance
         */
        DisableCommunicationChannelRequest.prototype.ssoId = "";

        /**
         * DisableCommunicationChannelRequest channel.
         * @member {string} channel
         * @memberof PlatformAdminApi.DisableCommunicationChannelRequest
         * @instance
         */
        DisableCommunicationChannelRequest.prototype.channel = "";

        /**
         * DisableCommunicationChannelRequest group.
         * @member {string} group
         * @memberof PlatformAdminApi.DisableCommunicationChannelRequest
         * @instance
         */
        DisableCommunicationChannelRequest.prototype.group = "";

        /**
         * Encodes the specified DisableCommunicationChannelRequest message. Does not implicitly {@link PlatformAdminApi.DisableCommunicationChannelRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.DisableCommunicationChannelRequest
         * @static
         * @param {PlatformAdminApi.IDisableCommunicationChannelRequest} message DisableCommunicationChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisableCommunicationChannelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ssoId);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.channel);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.group);
            return writer;
        };

        /**
         * Decodes a DisableCommunicationChannelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.DisableCommunicationChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.DisableCommunicationChannelRequest} DisableCommunicationChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisableCommunicationChannelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.DisableCommunicationChannelRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ssoId = reader.string();
                    break;
                case 2:
                    message.channel = reader.string();
                    break;
                case 3:
                    message.group = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DisableCommunicationChannelRequest;
    })();

    PlatformAdminApi.DisableCommunicationChannelResponse = (function() {

        /**
         * Properties of a DisableCommunicationChannelResponse.
         * @memberof PlatformAdminApi
         * @interface IDisableCommunicationChannelResponse
         */

        /**
         * Constructs a new DisableCommunicationChannelResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a DisableCommunicationChannelResponse.
         * @implements IDisableCommunicationChannelResponse
         * @constructor
         * @param {PlatformAdminApi.IDisableCommunicationChannelResponse=} [properties] Properties to set
         */
        function DisableCommunicationChannelResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified DisableCommunicationChannelResponse message. Does not implicitly {@link PlatformAdminApi.DisableCommunicationChannelResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.DisableCommunicationChannelResponse
         * @static
         * @param {PlatformAdminApi.IDisableCommunicationChannelResponse} message DisableCommunicationChannelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisableCommunicationChannelResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a DisableCommunicationChannelResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.DisableCommunicationChannelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.DisableCommunicationChannelResponse} DisableCommunicationChannelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisableCommunicationChannelResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.DisableCommunicationChannelResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DisableCommunicationChannelResponse;
    })();

    PlatformAdminApi.CommunicationPreferencesService = (function() {

        /**
         * Constructs a new CommunicationPreferencesService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a CommunicationPreferencesService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CommunicationPreferencesService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CommunicationPreferencesService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CommunicationPreferencesService;

        /**
         * Callback as used by {@link PlatformAdminApi.CommunicationPreferencesService#findUserPreferences}.
         * @memberof PlatformAdminApi.CommunicationPreferencesService
         * @typedef FindUserPreferencesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.FindUserPreferencesByIdResponse} [response] FindUserPreferencesByIdResponse
         */

        /**
         * Calls FindUserPreferences.
         * @function findUserPreferences
         * @memberof PlatformAdminApi.CommunicationPreferencesService
         * @instance
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest} request FindUserPreferencesByIdRequest message or plain object
         * @param {PlatformAdminApi.CommunicationPreferencesService.FindUserPreferencesCallback} callback Node-style callback called with the error, if any, and FindUserPreferencesByIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationPreferencesService.prototype.findUserPreferences = function findUserPreferences(request, callback) {
            return this.rpcCall(findUserPreferences, $root.PlatformAdminApi.FindUserPreferencesByIdRequest, $root.PlatformAdminApi.FindUserPreferencesByIdResponse, request, callback);
        }, "name", { value: "FindUserPreferences" });

        /**
         * Calls FindUserPreferences.
         * @function findUserPreferences
         * @memberof PlatformAdminApi.CommunicationPreferencesService
         * @instance
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest} request FindUserPreferencesByIdRequest message or plain object
         * @returns {Promise<PlatformAdminApi.FindUserPreferencesByIdResponse>} Promise
         * @variation 2
         */

        return CommunicationPreferencesService;
    })();

    PlatformAdminApi.FindUserPreferencesByIdRequest = (function() {

        /**
         * Properties of a FindUserPreferencesByIdRequest.
         * @memberof PlatformAdminApi
         * @interface IFindUserPreferencesByIdRequest
         * @property {string|null} [ssoUserId] FindUserPreferencesByIdRequest ssoUserId
         */

        /**
         * Constructs a new FindUserPreferencesByIdRequest.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FindUserPreferencesByIdRequest.
         * @implements IFindUserPreferencesByIdRequest
         * @constructor
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest=} [properties] Properties to set
         */
        function FindUserPreferencesByIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindUserPreferencesByIdRequest ssoUserId.
         * @member {string} ssoUserId
         * @memberof PlatformAdminApi.FindUserPreferencesByIdRequest
         * @instance
         */
        FindUserPreferencesByIdRequest.prototype.ssoUserId = "";

        /**
         * Encodes the specified FindUserPreferencesByIdRequest message. Does not implicitly {@link PlatformAdminApi.FindUserPreferencesByIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FindUserPreferencesByIdRequest
         * @static
         * @param {PlatformAdminApi.IFindUserPreferencesByIdRequest} message FindUserPreferencesByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindUserPreferencesByIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ssoUserId != null && Object.hasOwnProperty.call(message, "ssoUserId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ssoUserId);
            return writer;
        };

        /**
         * Decodes a FindUserPreferencesByIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FindUserPreferencesByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FindUserPreferencesByIdRequest} FindUserPreferencesByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindUserPreferencesByIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FindUserPreferencesByIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ssoUserId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindUserPreferencesByIdRequest;
    })();

    PlatformAdminApi.FindUserPreferencesByIdResponse = (function() {

        /**
         * Properties of a FindUserPreferencesByIdResponse.
         * @memberof PlatformAdminApi
         * @interface IFindUserPreferencesByIdResponse
         * @property {boolean|null} [messagesInsteadVoice] FindUserPreferencesByIdResponse messagesInsteadVoice
         * @property {boolean|null} [receiveNews] FindUserPreferencesByIdResponse receiveNews
         * @property {string|null} [priorityChannel] FindUserPreferencesByIdResponse priorityChannel
         * @property {Array.<PlatformAdminApi.FindUserPreferencesByIdResponse.IDisabledChannel>|null} [disabledChannels] FindUserPreferencesByIdResponse disabledChannels
         */

        /**
         * Constructs a new FindUserPreferencesByIdResponse.
         * @memberof PlatformAdminApi
         * @classdesc Represents a FindUserPreferencesByIdResponse.
         * @implements IFindUserPreferencesByIdResponse
         * @constructor
         * @param {PlatformAdminApi.IFindUserPreferencesByIdResponse=} [properties] Properties to set
         */
        function FindUserPreferencesByIdResponse(properties) {
            this.disabledChannels = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindUserPreferencesByIdResponse messagesInsteadVoice.
         * @member {boolean} messagesInsteadVoice
         * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
         * @instance
         */
        FindUserPreferencesByIdResponse.prototype.messagesInsteadVoice = false;

        /**
         * FindUserPreferencesByIdResponse receiveNews.
         * @member {boolean} receiveNews
         * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
         * @instance
         */
        FindUserPreferencesByIdResponse.prototype.receiveNews = false;

        /**
         * FindUserPreferencesByIdResponse priorityChannel.
         * @member {string} priorityChannel
         * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
         * @instance
         */
        FindUserPreferencesByIdResponse.prototype.priorityChannel = "";

        /**
         * FindUserPreferencesByIdResponse disabledChannels.
         * @member {Array.<PlatformAdminApi.FindUserPreferencesByIdResponse.IDisabledChannel>} disabledChannels
         * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
         * @instance
         */
        FindUserPreferencesByIdResponse.prototype.disabledChannels = $util.emptyArray;

        /**
         * Encodes the specified FindUserPreferencesByIdResponse message. Does not implicitly {@link PlatformAdminApi.FindUserPreferencesByIdResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
         * @static
         * @param {PlatformAdminApi.IFindUserPreferencesByIdResponse} message FindUserPreferencesByIdResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindUserPreferencesByIdResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.messagesInsteadVoice != null && Object.hasOwnProperty.call(message, "messagesInsteadVoice"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messagesInsteadVoice);
            if (message.receiveNews != null && Object.hasOwnProperty.call(message, "receiveNews"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.receiveNews);
            if (message.priorityChannel != null && Object.hasOwnProperty.call(message, "priorityChannel"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.priorityChannel);
            if (message.disabledChannels != null && message.disabledChannels.length)
                for (var i = 0; i < message.disabledChannels.length; ++i)
                    $root.PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel.encode(message.disabledChannels[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FindUserPreferencesByIdResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.FindUserPreferencesByIdResponse} FindUserPreferencesByIdResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindUserPreferencesByIdResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FindUserPreferencesByIdResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.messagesInsteadVoice = reader.bool();
                    break;
                case 2:
                    message.receiveNews = reader.bool();
                    break;
                case 3:
                    message.priorityChannel = reader.string();
                    break;
                case 4:
                    if (!(message.disabledChannels && message.disabledChannels.length))
                        message.disabledChannels = [];
                    message.disabledChannels.push($root.PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        FindUserPreferencesByIdResponse.DisabledChannel = (function() {

            /**
             * Properties of a DisabledChannel.
             * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
             * @interface IDisabledChannel
             * @property {string|null} [group] DisabledChannel group
             * @property {string|null} [channel] DisabledChannel channel
             */

            /**
             * Constructs a new DisabledChannel.
             * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse
             * @classdesc Represents a DisabledChannel.
             * @implements IDisabledChannel
             * @constructor
             * @param {PlatformAdminApi.FindUserPreferencesByIdResponse.IDisabledChannel=} [properties] Properties to set
             */
            function DisabledChannel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DisabledChannel group.
             * @member {string} group
             * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel
             * @instance
             */
            DisabledChannel.prototype.group = "";

            /**
             * DisabledChannel channel.
             * @member {string} channel
             * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel
             * @instance
             */
            DisabledChannel.prototype.channel = "";

            /**
             * Encodes the specified DisabledChannel message. Does not implicitly {@link PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel.verify|verify} messages.
             * @function encode
             * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel
             * @static
             * @param {PlatformAdminApi.FindUserPreferencesByIdResponse.IDisabledChannel} message DisabledChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DisabledChannel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.group);
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.channel);
                return writer;
            };

            /**
             * Decodes a DisabledChannel message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel} DisabledChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DisabledChannel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.FindUserPreferencesByIdResponse.DisabledChannel();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.group = reader.string();
                        break;
                    case 2:
                        message.channel = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return DisabledChannel;
        })();

        return FindUserPreferencesByIdResponse;
    })();

    PlatformAdminApi.MetaService = (function() {

        /**
         * Constructs a new MetaService service.
         * @memberof PlatformAdminApi
         * @classdesc Represents a MetaService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MetaService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MetaService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MetaService;

        /**
         * Callback as used by {@link PlatformAdminApi.MetaService#resolvePhotos}.
         * @memberof PlatformAdminApi.MetaService
         * @typedef ResolvePhotosCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformAdminApi.ResolvePhotosRes} [response] ResolvePhotosRes
         */

        /**
         * Calls ResolvePhotos.
         * @function resolvePhotos
         * @memberof PlatformAdminApi.MetaService
         * @instance
         * @param {PlatformAdminApi.IResolvePhotosReq} request ResolvePhotosReq message or plain object
         * @param {PlatformAdminApi.MetaService.ResolvePhotosCallback} callback Node-style callback called with the error, if any, and ResolvePhotosRes
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.resolvePhotos = function resolvePhotos(request, callback) {
            return this.rpcCall(resolvePhotos, $root.PlatformAdminApi.ResolvePhotosReq, $root.PlatformAdminApi.ResolvePhotosRes, request, callback);
        }, "name", { value: "ResolvePhotos" });

        /**
         * Calls ResolvePhotos.
         * @function resolvePhotos
         * @memberof PlatformAdminApi.MetaService
         * @instance
         * @param {PlatformAdminApi.IResolvePhotosReq} request ResolvePhotosReq message or plain object
         * @returns {Promise<PlatformAdminApi.ResolvePhotosRes>} Promise
         * @variation 2
         */

        return MetaService;
    })();

    PlatformAdminApi.ResolvePhotosReq = (function() {

        /**
         * Properties of a ResolvePhotosReq.
         * @memberof PlatformAdminApi
         * @interface IResolvePhotosReq
         * @property {Array.<string>|null} [keys] ResolvePhotosReq keys
         */

        /**
         * Constructs a new ResolvePhotosReq.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ResolvePhotosReq.
         * @implements IResolvePhotosReq
         * @constructor
         * @param {PlatformAdminApi.IResolvePhotosReq=} [properties] Properties to set
         */
        function ResolvePhotosReq(properties) {
            this.keys = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResolvePhotosReq keys.
         * @member {Array.<string>} keys
         * @memberof PlatformAdminApi.ResolvePhotosReq
         * @instance
         */
        ResolvePhotosReq.prototype.keys = $util.emptyArray;

        /**
         * Encodes the specified ResolvePhotosReq message. Does not implicitly {@link PlatformAdminApi.ResolvePhotosReq.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ResolvePhotosReq
         * @static
         * @param {PlatformAdminApi.IResolvePhotosReq} message ResolvePhotosReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResolvePhotosReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keys != null && message.keys.length)
                for (var i = 0; i < message.keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.keys[i]);
            return writer;
        };

        /**
         * Decodes a ResolvePhotosReq message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ResolvePhotosReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ResolvePhotosReq} ResolvePhotosReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResolvePhotosReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ResolvePhotosReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.keys && message.keys.length))
                        message.keys = [];
                    message.keys.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ResolvePhotosReq;
    })();

    PlatformAdminApi.ResolvePhotosRes = (function() {

        /**
         * Properties of a ResolvePhotosRes.
         * @memberof PlatformAdminApi
         * @interface IResolvePhotosRes
         * @property {Array.<string>|null} [links] ResolvePhotosRes links
         */

        /**
         * Constructs a new ResolvePhotosRes.
         * @memberof PlatformAdminApi
         * @classdesc Represents a ResolvePhotosRes.
         * @implements IResolvePhotosRes
         * @constructor
         * @param {PlatformAdminApi.IResolvePhotosRes=} [properties] Properties to set
         */
        function ResolvePhotosRes(properties) {
            this.links = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResolvePhotosRes links.
         * @member {Array.<string>} links
         * @memberof PlatformAdminApi.ResolvePhotosRes
         * @instance
         */
        ResolvePhotosRes.prototype.links = $util.emptyArray;

        /**
         * Encodes the specified ResolvePhotosRes message. Does not implicitly {@link PlatformAdminApi.ResolvePhotosRes.verify|verify} messages.
         * @function encode
         * @memberof PlatformAdminApi.ResolvePhotosRes
         * @static
         * @param {PlatformAdminApi.IResolvePhotosRes} message ResolvePhotosRes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResolvePhotosRes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.links != null && message.links.length)
                for (var i = 0; i < message.links.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.links[i]);
            return writer;
        };

        /**
         * Decodes a ResolvePhotosRes message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformAdminApi.ResolvePhotosRes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformAdminApi.ResolvePhotosRes} ResolvePhotosRes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResolvePhotosRes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformAdminApi.ResolvePhotosRes();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.links && message.links.length))
                        message.links = [];
                    message.links.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ResolvePhotosRes;
    })();

    return PlatformAdminApi;
})();

$root.plt = (function() {

    /**
     * Namespace plt.
     * @exports plt
     * @namespace
     */
    var plt = {};

    plt.core = (function() {

        /**
         * Namespace core.
         * @memberof plt
         * @namespace
         */
        var core = {};

        core.base = (function() {

            /**
             * Namespace base.
             * @memberof plt.core
             * @namespace
             */
            var base = {};

            /**
             * ORDER_ASSIGNMENT_STATUS enum.
             * @name plt.core.base.ORDER_ASSIGNMENT_STATUS
             * @enum {number}
             * @property {number} UNKNOWN_ORDER_ASSIGNMENT_STATUS=0 UNKNOWN_ORDER_ASSIGNMENT_STATUS value
             * @property {number} WAITING=1 WAITING value
             * @property {number} CONFIRMED=2 CONFIRMED value
             * @property {number} REJECTED=3 REJECTED value
             * @property {number} AT_WORK=4 AT_WORK value
             * @property {number} ASSIGNMENT_DONE=5 ASSIGNMENT_DONE value
             * @property {number} HOLD=6 HOLD value
             * @property {number} DONE=7 DONE value
             */
            base.ORDER_ASSIGNMENT_STATUS = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_ORDER_ASSIGNMENT_STATUS"] = 0;
                values[valuesById[1] = "WAITING"] = 1;
                values[valuesById[2] = "CONFIRMED"] = 2;
                values[valuesById[3] = "REJECTED"] = 3;
                values[valuesById[4] = "AT_WORK"] = 4;
                values[valuesById[5] = "ASSIGNMENT_DONE"] = 5;
                values[valuesById[6] = "HOLD"] = 6;
                values[valuesById[7] = "DONE"] = 7;
                return values;
            })();

            /**
             * ORDER_STATUS_TYPE enum.
             * @name plt.core.base.ORDER_STATUS_TYPE
             * @enum {number}
             * @property {number} UNKNOWN_ORDER_STATUS_TYPE=0 UNKNOWN_ORDER_STATUS_TYPE value
             * @property {number} TODO=1 TODO value
             * @property {number} IN_PROGRESS=2 IN_PROGRESS value
             * @property {number} DONE=3 DONE value
             * @property {number} CANCELED=4 CANCELED value
             * @property {number} ON_COORDINATION=5 ON_COORDINATION value
             * @property {number} WAIT_IN_PICKPOINT=6 WAIT_IN_PICKPOINT value
             */
            base.ORDER_STATUS_TYPE = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_ORDER_STATUS_TYPE"] = 0;
                values[valuesById[1] = "TODO"] = 1;
                values[valuesById[2] = "IN_PROGRESS"] = 2;
                values[valuesById[3] = "DONE"] = 3;
                values[valuesById[4] = "CANCELED"] = 4;
                values[valuesById[5] = "ON_COORDINATION"] = 5;
                values[valuesById[6] = "WAIT_IN_PICKPOINT"] = 6;
                return values;
            })();

            /**
             * PRICE_ELEMENT_OPTION_TYPE enum.
             * @name plt.core.base.PRICE_ELEMENT_OPTION_TYPE
             * @enum {number}
             * @property {number} UNKNOWN_PRICE_ELEMENT_OPTION_TYPE=0 UNKNOWN_PRICE_ELEMENT_OPTION_TYPE value
             * @property {number} BASE=1 BASE value
             * @property {number} OPTION=2 OPTION value
             * @property {number} CROSS_SALE=3 CROSS_SALE value
             */
            base.PRICE_ELEMENT_OPTION_TYPE = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_PRICE_ELEMENT_OPTION_TYPE"] = 0;
                values[valuesById[1] = "BASE"] = 1;
                values[valuesById[2] = "OPTION"] = 2;
                values[valuesById[3] = "CROSS_SALE"] = 3;
                return values;
            })();

            /**
             * MATRIX_UNIT_TYPE enum.
             * @name plt.core.base.MATRIX_UNIT_TYPE
             * @enum {number}
             * @property {number} UNKNOWN_MATRIX_UNIT_TYPE=0 UNKNOWN_MATRIX_UNIT_TYPE value
             * @property {number} UNIT=1 UNIT value
             * @property {number} SERVICE=2 SERVICE value
             */
            base.MATRIX_UNIT_TYPE = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_MATRIX_UNIT_TYPE"] = 0;
                values[valuesById[1] = "UNIT"] = 1;
                values[valuesById[2] = "SERVICE"] = 2;
                return values;
            })();

            /**
             * PAYMENT_TYPE enum.
             * @name plt.core.base.PAYMENT_TYPE
             * @enum {number}
             * @property {number} UNKNOWN_PAYMENT_TYPE=0 UNKNOWN_PAYMENT_TYPE value
             * @property {number} CLIENT_CARD=1 CLIENT_CARD value
             * @property {number} CLIENT_CASH=2 CLIENT_CASH value
             * @property {number} CLIENT_BONUS=3 CLIENT_BONUS value
             * @property {number} CLIENT_SMS=4 CLIENT_SMS value
             * @property {number} INSTALLMENT_PLAN=5 INSTALLMENT_PLAN value
             * @property {number} CONTRACTOR_CARD_LINKED=6 CONTRACTOR_CARD_LINKED value
             * @property {number} CARD_ONLINE=7 CARD_ONLINE value
             * @property {number} SOFT_POS=8 SOFT_POS value
             * @property {number} LINK=9 LINK value
             */
            base.PAYMENT_TYPE = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_PAYMENT_TYPE"] = 0;
                values[valuesById[1] = "CLIENT_CARD"] = 1;
                values[valuesById[2] = "CLIENT_CASH"] = 2;
                values[valuesById[3] = "CLIENT_BONUS"] = 3;
                values[valuesById[4] = "CLIENT_SMS"] = 4;
                values[valuesById[5] = "INSTALLMENT_PLAN"] = 5;
                values[valuesById[6] = "CONTRACTOR_CARD_LINKED"] = 6;
                values[valuesById[7] = "CARD_ONLINE"] = 7;
                values[valuesById[8] = "SOFT_POS"] = 8;
                values[valuesById[9] = "LINK"] = 9;
                return values;
            })();

            /**
             * PAYMENT_STATUS_TYPE enum.
             * @name plt.core.base.PAYMENT_STATUS_TYPE
             * @enum {number}
             * @property {number} UNKNOWN_PAYMENT_STATUS_TYPE=0 UNKNOWN_PAYMENT_STATUS_TYPE value
             * @property {number} new=1 new value
             * @property {number} authorized=2 authorized value
             * @property {number} paid=3 paid value
             * @property {number} failed=4 failed value
             * @property {number} canceled=5 canceled value
             * @property {number} to_refund=6 to_refund value
             * @property {number} refunded=7 refunded value
             */
            base.PAYMENT_STATUS_TYPE = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN_PAYMENT_STATUS_TYPE"] = 0;
                values[valuesById[1] = "new"] = 1;
                values[valuesById[2] = "authorized"] = 2;
                values[valuesById[3] = "paid"] = 3;
                values[valuesById[4] = "failed"] = 4;
                values[valuesById[5] = "canceled"] = 5;
                values[valuesById[6] = "to_refund"] = 6;
                values[valuesById[7] = "refunded"] = 7;
                return values;
            })();

            /**
             * REASON_AVAILABLE_FOR enum.
             * @name plt.core.base.REASON_AVAILABLE_FOR
             * @enum {number}
             * @property {number} CONTRACTOR=0 CONTRACTOR value
             * @property {number} CUSTOMER=1 CUSTOMER value
             * @property {number} SYSTEM=2 SYSTEM value
             * @property {number} PARTNER=3 PARTNER value
             */
            base.REASON_AVAILABLE_FOR = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CONTRACTOR"] = 0;
                values[valuesById[1] = "CUSTOMER"] = 1;
                values[valuesById[2] = "SYSTEM"] = 2;
                values[valuesById[3] = "PARTNER"] = 3;
                return values;
            })();

            /**
             * REJECTION_GROUP_TYPE enum.
             * @name plt.core.base.REJECTION_GROUP_TYPE
             * @enum {number}
             * @property {number} DEFAULT=1 DEFAULT value
             * @property {number} BEFORE_EXECUTE=2 BEFORE_EXECUTE value
             * @property {number} ON_EXECUTING=3 ON_EXECUTING value
             * @property {number} BY_SERVICE=4 BY_SERVICE value
             * @property {number} ON_CONFIRM=5 ON_CONFIRM value
             * @property {number} BY_CLIENT=6 BY_CLIENT value
             * @property {number} NOT_SATISFIED_WITH_CONDITIONS=7 NOT_SATISFIED_WITH_CONDITIONS value
             * @property {number} BY_TECHNOLOGIST=8 BY_TECHNOLOGIST value
             */
            base.REJECTION_GROUP_TYPE = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "DEFAULT"] = 1;
                values[valuesById[2] = "BEFORE_EXECUTE"] = 2;
                values[valuesById[3] = "ON_EXECUTING"] = 3;
                values[valuesById[4] = "BY_SERVICE"] = 4;
                values[valuesById[5] = "ON_CONFIRM"] = 5;
                values[valuesById[6] = "BY_CLIENT"] = 6;
                values[valuesById[7] = "NOT_SATISFIED_WITH_CONDITIONS"] = 7;
                values[valuesById[8] = "BY_TECHNOLOGIST"] = 8;
                return values;
            })();

            /**
             * REJECTION_GROUP_AVAILABLE_FOR enum.
             * @name plt.core.base.REJECTION_GROUP_AVAILABLE_FOR
             * @enum {number}
             * @property {number} CONTRACTOR=0 CONTRACTOR value
             * @property {number} CUSTOMER=1 CUSTOMER value
             * @property {number} SYSTEM=2 SYSTEM value
             */
            base.REJECTION_GROUP_AVAILABLE_FOR = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CONTRACTOR"] = 0;
                values[valuesById[1] = "CUSTOMER"] = 1;
                values[valuesById[2] = "SYSTEM"] = 2;
                return values;
            })();

            /**
             * WAYPOINT_TYPE enum.
             * @name plt.core.base.WAYPOINT_TYPE
             * @enum {number}
             * @property {number} PICKUP=1 PICKUP value
             * @property {number} DELIVERY=2 DELIVERY value
             */
            base.WAYPOINT_TYPE = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "PICKUP"] = 1;
                values[valuesById[2] = "DELIVERY"] = 2;
                return values;
            })();

            base.TechnologicalMap = (function() {

                /**
                 * Properties of a TechnologicalMap.
                 * @memberof plt.core.base
                 * @interface ITechnologicalMap
                 * @property {string|null} [id] TechnologicalMap id
                 * @property {string|null} [title] TechnologicalMap title
                 * @property {string|null} [name] TechnologicalMap name
                 * @property {string|null} [abbreviation] TechnologicalMap abbreviation
                 */

                /**
                 * Constructs a new TechnologicalMap.
                 * @memberof plt.core.base
                 * @classdesc Represents a TechnologicalMap.
                 * @implements ITechnologicalMap
                 * @constructor
                 * @param {plt.core.base.ITechnologicalMap=} [properties] Properties to set
                 */
                function TechnologicalMap(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TechnologicalMap id.
                 * @member {string} id
                 * @memberof plt.core.base.TechnologicalMap
                 * @instance
                 */
                TechnologicalMap.prototype.id = "";

                /**
                 * TechnologicalMap title.
                 * @member {string} title
                 * @memberof plt.core.base.TechnologicalMap
                 * @instance
                 */
                TechnologicalMap.prototype.title = "";

                /**
                 * TechnologicalMap name.
                 * @member {string} name
                 * @memberof plt.core.base.TechnologicalMap
                 * @instance
                 */
                TechnologicalMap.prototype.name = "";

                /**
                 * TechnologicalMap abbreviation.
                 * @member {string} abbreviation
                 * @memberof plt.core.base.TechnologicalMap
                 * @instance
                 */
                TechnologicalMap.prototype.abbreviation = "";

                /**
                 * Encodes the specified TechnologicalMap message. Does not implicitly {@link plt.core.base.TechnologicalMap.verify|verify} messages.
                 * @function encode
                 * @memberof plt.core.base.TechnologicalMap
                 * @static
                 * @param {plt.core.base.ITechnologicalMap} message TechnologicalMap message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TechnologicalMap.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.abbreviation != null && Object.hasOwnProperty.call(message, "abbreviation"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.abbreviation);
                    return writer;
                };

                /**
                 * Decodes a TechnologicalMap message from the specified reader or buffer.
                 * @function decode
                 * @memberof plt.core.base.TechnologicalMap
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {plt.core.base.TechnologicalMap} TechnologicalMap
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TechnologicalMap.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plt.core.base.TechnologicalMap();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id = reader.string();
                            break;
                        case 2:
                            message.title = reader.string();
                            break;
                        case 3:
                            message.name = reader.string();
                            break;
                        case 4:
                            message.abbreviation = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return TechnologicalMap;
            })();

            base.TechnologicalMapSelect = (function() {

                /**
                 * Properties of a TechnologicalMapSelect.
                 * @memberof plt.core.base
                 * @interface ITechnologicalMapSelect
                 * @property {boolean|null} [id] TechnologicalMapSelect id
                 * @property {boolean|null} [title] TechnologicalMapSelect title
                 * @property {boolean|null} [name] TechnologicalMapSelect name
                 */

                /**
                 * Constructs a new TechnologicalMapSelect.
                 * @memberof plt.core.base
                 * @classdesc Represents a TechnologicalMapSelect.
                 * @implements ITechnologicalMapSelect
                 * @constructor
                 * @param {plt.core.base.ITechnologicalMapSelect=} [properties] Properties to set
                 */
                function TechnologicalMapSelect(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TechnologicalMapSelect id.
                 * @member {boolean} id
                 * @memberof plt.core.base.TechnologicalMapSelect
                 * @instance
                 */
                TechnologicalMapSelect.prototype.id = false;

                /**
                 * TechnologicalMapSelect title.
                 * @member {boolean} title
                 * @memberof plt.core.base.TechnologicalMapSelect
                 * @instance
                 */
                TechnologicalMapSelect.prototype.title = false;

                /**
                 * TechnologicalMapSelect name.
                 * @member {boolean} name
                 * @memberof plt.core.base.TechnologicalMapSelect
                 * @instance
                 */
                TechnologicalMapSelect.prototype.name = false;

                /**
                 * Encodes the specified TechnologicalMapSelect message. Does not implicitly {@link plt.core.base.TechnologicalMapSelect.verify|verify} messages.
                 * @function encode
                 * @memberof plt.core.base.TechnologicalMapSelect
                 * @static
                 * @param {plt.core.base.ITechnologicalMapSelect} message TechnologicalMapSelect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TechnologicalMapSelect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.id);
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.title);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.name);
                    return writer;
                };

                /**
                 * Decodes a TechnologicalMapSelect message from the specified reader or buffer.
                 * @function decode
                 * @memberof plt.core.base.TechnologicalMapSelect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {plt.core.base.TechnologicalMapSelect} TechnologicalMapSelect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TechnologicalMapSelect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plt.core.base.TechnologicalMapSelect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id = reader.bool();
                            break;
                        case 2:
                            message.title = reader.bool();
                            break;
                        case 3:
                            message.name = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return TechnologicalMapSelect;
            })();

            base.TechnologicalMapUnique = (function() {

                /**
                 * Properties of a TechnologicalMapUnique.
                 * @memberof plt.core.base
                 * @interface ITechnologicalMapUnique
                 * @property {string|null} [id] TechnologicalMapUnique id
                 * @property {string|null} [name] TechnologicalMapUnique name
                 */

                /**
                 * Constructs a new TechnologicalMapUnique.
                 * @memberof plt.core.base
                 * @classdesc Represents a TechnologicalMapUnique.
                 * @implements ITechnologicalMapUnique
                 * @constructor
                 * @param {plt.core.base.ITechnologicalMapUnique=} [properties] Properties to set
                 */
                function TechnologicalMapUnique(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TechnologicalMapUnique id.
                 * @member {string|null|undefined} id
                 * @memberof plt.core.base.TechnologicalMapUnique
                 * @instance
                 */
                TechnologicalMapUnique.prototype.id = null;

                /**
                 * TechnologicalMapUnique name.
                 * @member {string|null|undefined} name
                 * @memberof plt.core.base.TechnologicalMapUnique
                 * @instance
                 */
                TechnologicalMapUnique.prototype.name = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * TechnologicalMapUnique unique.
                 * @member {"id"|"name"|undefined} unique
                 * @memberof plt.core.base.TechnologicalMapUnique
                 * @instance
                 */
                Object.defineProperty(TechnologicalMapUnique.prototype, "unique", {
                    get: $util.oneOfGetter($oneOfFields = ["id", "name"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Encodes the specified TechnologicalMapUnique message. Does not implicitly {@link plt.core.base.TechnologicalMapUnique.verify|verify} messages.
                 * @function encode
                 * @memberof plt.core.base.TechnologicalMapUnique
                 * @static
                 * @param {plt.core.base.ITechnologicalMapUnique} message TechnologicalMapUnique message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TechnologicalMapUnique.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    return writer;
                };

                /**
                 * Decodes a TechnologicalMapUnique message from the specified reader or buffer.
                 * @function decode
                 * @memberof plt.core.base.TechnologicalMapUnique
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {plt.core.base.TechnologicalMapUnique} TechnologicalMapUnique
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TechnologicalMapUnique.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plt.core.base.TechnologicalMapUnique();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id = reader.string();
                            break;
                        case 2:
                            message.name = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return TechnologicalMapUnique;
            })();

            base.TechnologicalMapConnect = (function() {

                /**
                 * Properties of a TechnologicalMapConnect.
                 * @memberof plt.core.base
                 * @interface ITechnologicalMapConnect
                 * @property {plt.core.base.ITechnologicalMapUnique|null} [connect] TechnologicalMapConnect connect
                 */

                /**
                 * Constructs a new TechnologicalMapConnect.
                 * @memberof plt.core.base
                 * @classdesc Represents a TechnologicalMapConnect.
                 * @implements ITechnologicalMapConnect
                 * @constructor
                 * @param {plt.core.base.ITechnologicalMapConnect=} [properties] Properties to set
                 */
                function TechnologicalMapConnect(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TechnologicalMapConnect connect.
                 * @member {plt.core.base.ITechnologicalMapUnique|null|undefined} connect
                 * @memberof plt.core.base.TechnologicalMapConnect
                 * @instance
                 */
                TechnologicalMapConnect.prototype.connect = null;

                /**
                 * Encodes the specified TechnologicalMapConnect message. Does not implicitly {@link plt.core.base.TechnologicalMapConnect.verify|verify} messages.
                 * @function encode
                 * @memberof plt.core.base.TechnologicalMapConnect
                 * @static
                 * @param {plt.core.base.ITechnologicalMapConnect} message TechnologicalMapConnect message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TechnologicalMapConnect.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.connect != null && Object.hasOwnProperty.call(message, "connect"))
                        $root.plt.core.base.TechnologicalMapUnique.encode(message.connect, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a TechnologicalMapConnect message from the specified reader or buffer.
                 * @function decode
                 * @memberof plt.core.base.TechnologicalMapConnect
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {plt.core.base.TechnologicalMapConnect} TechnologicalMapConnect
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TechnologicalMapConnect.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plt.core.base.TechnologicalMapConnect();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.connect = $root.plt.core.base.TechnologicalMapUnique.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return TechnologicalMapConnect;
            })();

            base.TechnologicalMapCreate = (function() {

                /**
                 * Properties of a TechnologicalMapCreate.
                 * @memberof plt.core.base
                 * @interface ITechnologicalMapCreate
                 * @property {string|null} [title] TechnologicalMapCreate title
                 * @property {string|null} [name] TechnologicalMapCreate name
                 */

                /**
                 * Constructs a new TechnologicalMapCreate.
                 * @memberof plt.core.base
                 * @classdesc Represents a TechnologicalMapCreate.
                 * @implements ITechnologicalMapCreate
                 * @constructor
                 * @param {plt.core.base.ITechnologicalMapCreate=} [properties] Properties to set
                 */
                function TechnologicalMapCreate(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TechnologicalMapCreate title.
                 * @member {string} title
                 * @memberof plt.core.base.TechnologicalMapCreate
                 * @instance
                 */
                TechnologicalMapCreate.prototype.title = "";

                /**
                 * TechnologicalMapCreate name.
                 * @member {string} name
                 * @memberof plt.core.base.TechnologicalMapCreate
                 * @instance
                 */
                TechnologicalMapCreate.prototype.name = "";

                /**
                 * Encodes the specified TechnologicalMapCreate message. Does not implicitly {@link plt.core.base.TechnologicalMapCreate.verify|verify} messages.
                 * @function encode
                 * @memberof plt.core.base.TechnologicalMapCreate
                 * @static
                 * @param {plt.core.base.ITechnologicalMapCreate} message TechnologicalMapCreate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TechnologicalMapCreate.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    return writer;
                };

                /**
                 * Decodes a TechnologicalMapCreate message from the specified reader or buffer.
                 * @function decode
                 * @memberof plt.core.base.TechnologicalMapCreate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {plt.core.base.TechnologicalMapCreate} TechnologicalMapCreate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TechnologicalMapCreate.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plt.core.base.TechnologicalMapCreate();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.title = reader.string();
                            break;
                        case 2:
                            message.name = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return TechnologicalMapCreate;
            })();

            base.TechnologicalMapUpdate = (function() {

                /**
                 * Properties of a TechnologicalMapUpdate.
                 * @memberof plt.core.base
                 * @interface ITechnologicalMapUpdate
                 * @property {string|null} [id] TechnologicalMapUpdate id
                 * @property {string|null} [title] TechnologicalMapUpdate title
                 * @property {string|null} [name] TechnologicalMapUpdate name
                 */

                /**
                 * Constructs a new TechnologicalMapUpdate.
                 * @memberof plt.core.base
                 * @classdesc Represents a TechnologicalMapUpdate.
                 * @implements ITechnologicalMapUpdate
                 * @constructor
                 * @param {plt.core.base.ITechnologicalMapUpdate=} [properties] Properties to set
                 */
                function TechnologicalMapUpdate(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TechnologicalMapUpdate id.
                 * @member {string} id
                 * @memberof plt.core.base.TechnologicalMapUpdate
                 * @instance
                 */
                TechnologicalMapUpdate.prototype.id = "";

                /**
                 * TechnologicalMapUpdate title.
                 * @member {string} title
                 * @memberof plt.core.base.TechnologicalMapUpdate
                 * @instance
                 */
                TechnologicalMapUpdate.prototype.title = "";

                /**
                 * TechnologicalMapUpdate name.
                 * @member {string} name
                 * @memberof plt.core.base.TechnologicalMapUpdate
                 * @instance
                 */
                TechnologicalMapUpdate.prototype.name = "";

                /**
                 * Encodes the specified TechnologicalMapUpdate message. Does not implicitly {@link plt.core.base.TechnologicalMapUpdate.verify|verify} messages.
                 * @function encode
                 * @memberof plt.core.base.TechnologicalMapUpdate
                 * @static
                 * @param {plt.core.base.ITechnologicalMapUpdate} message TechnologicalMapUpdate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TechnologicalMapUpdate.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    return writer;
                };

                /**
                 * Decodes a TechnologicalMapUpdate message from the specified reader or buffer.
                 * @function decode
                 * @memberof plt.core.base.TechnologicalMapUpdate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {plt.core.base.TechnologicalMapUpdate} TechnologicalMapUpdate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TechnologicalMapUpdate.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plt.core.base.TechnologicalMapUpdate();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.id = reader.string();
                            break;
                        case 2:
                            message.title = reader.string();
                            break;
                        case 3:
                            message.name = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                return TechnologicalMapUpdate;
            })();

            return base;
        })();

        return core;
    })();

    return plt;
})();

$root.sso = (function() {

    /**
     * Namespace sso.
     * @exports sso
     * @namespace
     */
    var sso = {};

    /**
     * APP_TYPE enum.
     * @name sso.APP_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_APP_TYPE=0 UNDEFINED_APP_TYPE value
     * @property {number} NATIVE=1 NATIVE value
     * @property {number} SPA=2 SPA value
     * @property {number} REGULAR=3 REGULAR value
     * @property {number} MACHINE=4 MACHINE value
     */
    sso.APP_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_APP_TYPE"] = 0;
        values[valuesById[1] = "NATIVE"] = 1;
        values[valuesById[2] = "SPA"] = 2;
        values[valuesById[3] = "REGULAR"] = 3;
        values[valuesById[4] = "MACHINE"] = 4;
        return values;
    })();

    /**
     * SEND enum.
     * @name sso.SEND
     * @enum {number}
     * @property {number} UNDEFINED_SEND=0 UNDEFINED_SEND value
     * @property {number} CODE=1 CODE value
     * @property {number} VERIFICATION_LINK=2 VERIFICATION_LINK value
     */
    sso.SEND = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_SEND"] = 0;
        values[valuesById[1] = "CODE"] = 1;
        values[valuesById[2] = "VERIFICATION_LINK"] = 2;
        return values;
    })();

    /**
     * USER_TYPE enum.
     * @name sso.USER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_USER_TYPE=0 UNDEFINED_USER_TYPE value
     * @property {number} CUSTOMER=1 CUSTOMER value
     * @property {number} CONTRACTOR=2 CONTRACTOR value
     * @property {number} EMPLOYEE=3 EMPLOYEE value
     * @property {number} PARTNER=4 PARTNER value
     */
    sso.USER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_USER_TYPE"] = 0;
        values[valuesById[1] = "CUSTOMER"] = 1;
        values[valuesById[2] = "CONTRACTOR"] = 2;
        values[valuesById[3] = "EMPLOYEE"] = 3;
        values[valuesById[4] = "PARTNER"] = 4;
        return values;
    })();

    /**
     * CONNECTION enum.
     * @name sso.CONNECTION
     * @enum {number}
     * @property {number} UNDEFINED_CONNECTION=0 UNDEFINED_CONNECTION value
     * @property {number} CREDENTIAL=1 CREDENTIAL value
     * @property {number} M2M=2 M2M value
     * @property {number} PASSWORDLESS=3 PASSWORDLESS value
     * @property {number} YCLIENTS=4 YCLIENTS value
     */
    sso.CONNECTION = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONNECTION"] = 0;
        values[valuesById[1] = "CREDENTIAL"] = 1;
        values[valuesById[2] = "M2M"] = 2;
        values[valuesById[3] = "PASSWORDLESS"] = 3;
        values[valuesById[4] = "YCLIENTS"] = 4;
        return values;
    })();

    /**
     * CONTACT_TYPE enum.
     * @name sso.CONTACT_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_CONTACT_TYPE=0 UNDEFINED_CONTACT_TYPE value
     * @property {number} MOBILE=1 MOBILE value
     * @property {number} EMAIL=2 EMAIL value
     * @property {number} TELEGRAM=3 TELEGRAM value
     * @property {number} WHATSAPP=4 WHATSAPP value
     * @property {number} FACEBOOK=5 FACEBOOK value
     * @property {number} VK=6 VK value
     * @property {number} OK=7 OK value
     * @property {number} INSTAGRAM=8 INSTAGRAM value
     * @property {number} YOUTUBE=9 YOUTUBE value
     * @property {number} TWITTER=10 TWITTER value
     * @property {number} MY_MAIL=11 MY_MAIL value
     * @property {number} LINKED_IN=12 LINKED_IN value
     */
    sso.CONTACT_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONTACT_TYPE"] = 0;
        values[valuesById[1] = "MOBILE"] = 1;
        values[valuesById[2] = "EMAIL"] = 2;
        values[valuesById[3] = "TELEGRAM"] = 3;
        values[valuesById[4] = "WHATSAPP"] = 4;
        values[valuesById[5] = "FACEBOOK"] = 5;
        values[valuesById[6] = "VK"] = 6;
        values[valuesById[7] = "OK"] = 7;
        values[valuesById[8] = "INSTAGRAM"] = 8;
        values[valuesById[9] = "YOUTUBE"] = 9;
        values[valuesById[10] = "TWITTER"] = 10;
        values[valuesById[11] = "MY_MAIL"] = 11;
        values[valuesById[12] = "LINKED_IN"] = 12;
        return values;
    })();

    /**
     * ADDRESS_TYPE enum.
     * @name sso.ADDRESS_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_ADDRESS_TYPE=0 UNDEFINED_ADDRESS_TYPE value
     * @property {number} DEFAULT=1 DEFAULT value
     * @property {number} BIRTH_ADDRESS=2 BIRTH_ADDRESS value
     * @property {number} REG_ADDRESS=3 REG_ADDRESS value
     * @property {number} RESIDENCE_ADDRESS=4 RESIDENCE_ADDRESS value
     * @property {number} PICKUP_POINT=5 PICKUP_POINT value
     */
    sso.ADDRESS_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_ADDRESS_TYPE"] = 0;
        values[valuesById[1] = "DEFAULT"] = 1;
        values[valuesById[2] = "BIRTH_ADDRESS"] = 2;
        values[valuesById[3] = "REG_ADDRESS"] = 3;
        values[valuesById[4] = "RESIDENCE_ADDRESS"] = 4;
        values[valuesById[5] = "PICKUP_POINT"] = 5;
        return values;
    })();

    /**
     * GENDER_TYPE enum.
     * @name sso.GENDER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_GENDER_TYPE=0 UNDEFINED_GENDER_TYPE value
     * @property {number} MALE=1 MALE value
     * @property {number} FEMALE=2 FEMALE value
     */
    sso.GENDER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_GENDER_TYPE"] = 0;
        values[valuesById[1] = "MALE"] = 1;
        values[valuesById[2] = "FEMALE"] = 2;
        return values;
    })();

    /**
     * API_TYPE enum.
     * @name sso.API_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_API_TYPE=0 UNDEFINED_API_TYPE value
     * @property {number} PLATFORM=1 PLATFORM value
     * @property {number} CUSTOM=2 CUSTOM value
     * @property {number} MANAGEMENT=3 MANAGEMENT value
     */
    sso.API_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_API_TYPE"] = 0;
        values[valuesById[1] = "PLATFORM"] = 1;
        values[valuesById[2] = "CUSTOM"] = 2;
        values[valuesById[3] = "MANAGEMENT"] = 3;
        return values;
    })();

    /**
     * LOGIN_ENTRY_STATUS enum.
     * @name sso.LOGIN_ENTRY_STATUS
     * @enum {number}
     * @property {number} UNDEFINED_LOGIN_ENTRY_STATUS=0 UNDEFINED_LOGIN_ENTRY_STATUS value
     * @property {number} PENDING=1 PENDING value
     * @property {number} SUCCESS=2 SUCCESS value
     * @property {number} ERROR=3 ERROR value
     */
    sso.LOGIN_ENTRY_STATUS = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_LOGIN_ENTRY_STATUS"] = 0;
        values[valuesById[1] = "PENDING"] = 1;
        values[valuesById[2] = "SUCCESS"] = 2;
        values[valuesById[3] = "ERROR"] = 3;
        return values;
    })();

    /**
     * BAN_RULE_SLUG enum.
     * @name sso.BAN_RULE_SLUG
     * @enum {number}
     * @property {number} UNDEFINED_RULE_SLUG=0 UNDEFINED_RULE_SLUG value
     * @property {number} MAX_ATTEMPTS_COUNT_BY_CONTACT_REACHED=1 MAX_ATTEMPTS_COUNT_BY_CONTACT_REACHED value
     * @property {number} MAX_ATTEMPTS_COUNT_BY_IP_REACHED=2 MAX_ATTEMPTS_COUNT_BY_IP_REACHED value
     * @property {number} MAX_IP_COUNT_BY_CONTACT_REACHED=3 MAX_IP_COUNT_BY_CONTACT_REACHED value
     */
    sso.BAN_RULE_SLUG = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_RULE_SLUG"] = 0;
        values[valuesById[1] = "MAX_ATTEMPTS_COUNT_BY_CONTACT_REACHED"] = 1;
        values[valuesById[2] = "MAX_ATTEMPTS_COUNT_BY_IP_REACHED"] = 2;
        values[valuesById[3] = "MAX_IP_COUNT_BY_CONTACT_REACHED"] = 3;
        return values;
    })();

    /**
     * BLOCKING_REASON_SLUG enum.
     * @name sso.BLOCKING_REASON_SLUG
     * @enum {number}
     * @property {number} UNDEFINED_BLOCKING_REASON_SLUG=0 UNDEFINED_BLOCKING_REASON_SLUG value
     * @property {number} REVOCATION_PERS_DATA=1 REVOCATION_PERS_DATA value
     * @property {number} END_COOPERATION=2 END_COOPERATION value
     */
    sso.BLOCKING_REASON_SLUG = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_BLOCKING_REASON_SLUG"] = 0;
        values[valuesById[1] = "REVOCATION_PERS_DATA"] = 1;
        values[valuesById[2] = "END_COOPERATION"] = 2;
        return values;
    })();

    /**
     * TENANT_TYPE enum.
     * @name sso.TENANT_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_TENANT_TYPE=0 UNDEFINED_TENANT_TYPE value
     * @property {number} TENANT=1 TENANT value
     * @property {number} ECOSYSTEM=2 ECOSYSTEM value
     * @property {number} SUBTENANT=3 SUBTENANT value
     */
    sso.TENANT_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_TENANT_TYPE"] = 0;
        values[valuesById[1] = "TENANT"] = 1;
        values[valuesById[2] = "ECOSYSTEM"] = 2;
        values[valuesById[3] = "SUBTENANT"] = 3;
        return values;
    })();

    /**
     * ACCESS_BLOCKING_TYPE enum.
     * @name sso.ACCESS_BLOCKING_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_ACCESS_BLOCKING_TYPE=0 UNDEFINED_ACCESS_BLOCKING_TYPE value
     * @property {number} BLOCK=1 BLOCK value
     * @property {number} FREEZE=2 FREEZE value
     */
    sso.ACCESS_BLOCKING_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_ACCESS_BLOCKING_TYPE"] = 0;
        values[valuesById[1] = "BLOCK"] = 1;
        values[valuesById[2] = "FREEZE"] = 2;
        return values;
    })();

    sso.Empty = (function() {

        /**
         * Properties of an Empty.
         * @memberof sso
         * @interface IEmpty
         */

        /**
         * Constructs a new Empty.
         * @memberof sso
         * @classdesc Represents an Empty.
         * @implements IEmpty
         * @constructor
         * @param {sso.IEmpty=} [properties] Properties to set
         */
        function Empty(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified Empty message. Does not implicitly {@link sso.Empty.verify|verify} messages.
         * @function encode
         * @memberof sso.Empty
         * @static
         * @param {sso.IEmpty} message Empty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Empty.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an Empty message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Empty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Empty} Empty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Empty.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Empty();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Empty;
    })();

    sso.DadataFIO = (function() {

        /**
         * Properties of a DadataFIO.
         * @memberof sso
         * @interface IDadataFIO
         * @property {string|null} [value] DadataFIO value
         * @property {string|null} [unrestrictedValue] DadataFIO unrestrictedValue
         * @property {sso.DadataFIO.IData|null} [data] DadataFIO data
         */

        /**
         * Constructs a new DadataFIO.
         * @memberof sso
         * @classdesc Represents a DadataFIO.
         * @implements IDadataFIO
         * @constructor
         * @param {sso.IDadataFIO=} [properties] Properties to set
         */
        function DadataFIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DadataFIO value.
         * @member {string} value
         * @memberof sso.DadataFIO
         * @instance
         */
        DadataFIO.prototype.value = "";

        /**
         * DadataFIO unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.DadataFIO
         * @instance
         */
        DadataFIO.prototype.unrestrictedValue = "";

        /**
         * DadataFIO data.
         * @member {sso.DadataFIO.IData|null|undefined} data
         * @memberof sso.DadataFIO
         * @instance
         */
        DadataFIO.prototype.data = null;

        /**
         * Encodes the specified DadataFIO message. Does not implicitly {@link sso.DadataFIO.verify|verify} messages.
         * @function encode
         * @memberof sso.DadataFIO
         * @static
         * @param {sso.IDadataFIO} message DadataFIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DadataFIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.unrestrictedValue);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.sso.DadataFIO.Data.encode(message.data, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DadataFIO message from the specified reader or buffer.
         * @function decode
         * @memberof sso.DadataFIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.DadataFIO} DadataFIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DadataFIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DadataFIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                case 2:
                    message.unrestrictedValue = reader.string();
                    break;
                case 3:
                    message.data = $root.sso.DadataFIO.Data.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DadataFIO.Data = (function() {

            /**
             * Properties of a Data.
             * @memberof sso.DadataFIO
             * @interface IData
             * @property {string|null} [surname] Data surname
             * @property {string|null} [name] Data name
             * @property {string|null} [patronymic] Data patronymic
             * @property {string|null} [gender] Data gender
             * @property {string|null} [source] Data source
             * @property {string|null} [qc] Data qc
             */

            /**
             * Constructs a new Data.
             * @memberof sso.DadataFIO
             * @classdesc Represents a Data.
             * @implements IData
             * @constructor
             * @param {sso.DadataFIO.IData=} [properties] Properties to set
             */
            function Data(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Data surname.
             * @member {string} surname
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.surname = "";

            /**
             * Data name.
             * @member {string} name
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.name = "";

            /**
             * Data patronymic.
             * @member {string} patronymic
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.patronymic = "";

            /**
             * Data gender.
             * @member {string} gender
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.gender = "";

            /**
             * Data source.
             * @member {string} source
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.source = "";

            /**
             * Data qc.
             * @member {string} qc
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.qc = "";

            /**
             * Encodes the specified Data message. Does not implicitly {@link sso.DadataFIO.Data.verify|verify} messages.
             * @function encode
             * @memberof sso.DadataFIO.Data
             * @static
             * @param {sso.DadataFIO.IData} message Data message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Data.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.surname);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.patronymic);
                if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.gender);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.source);
                if (message.qc != null && Object.hasOwnProperty.call(message, "qc"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.qc);
                return writer;
            };

            /**
             * Decodes a Data message from the specified reader or buffer.
             * @function decode
             * @memberof sso.DadataFIO.Data
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {sso.DadataFIO.Data} Data
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Data.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DadataFIO.Data();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.surname = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.patronymic = reader.string();
                        break;
                    case 4:
                        message.gender = reader.string();
                        break;
                    case 5:
                        message.source = reader.string();
                        break;
                    case 6:
                        message.qc = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Data;
        })();

        return DadataFIO;
    })();

    return sso;
})();

$root.ru = (function() {

    /**
     * Namespace ru.
     * @exports ru
     * @namespace
     */
    var ru = {};

    ru.qleanlabs = (function() {

        /**
         * Namespace qleanlabs.
         * @memberof ru
         * @namespace
         */
        var qleanlabs = {};

        qleanlabs.gitlab = (function() {

            /**
             * Namespace gitlab.
             * @memberof ru.qleanlabs
             * @namespace
             */
            var gitlab = {};

            gitlab.platform = (function() {

                /**
                 * Namespace platform.
                 * @memberof ru.qleanlabs.gitlab
                 * @namespace
                 */
                var platform = {};

                platform.finance = (function() {

                    /**
                     * Namespace finance.
                     * @memberof ru.qleanlabs.gitlab.platform
                     * @namespace
                     */
                    var finance = {};

                    finance.finance_acquiring_svs = (function() {

                        /**
                         * Namespace finance_acquiring_svs.
                         * @memberof ru.qleanlabs.gitlab.platform.finance
                         * @namespace
                         */
                        var finance_acquiring_svs = {};

                        finance_acquiring_svs.creditcards = (function() {

                            /**
                             * Namespace creditcards.
                             * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs
                             * @namespace
                             */
                            var creditcards = {};

                            creditcards.CreditCardsAPI = (function() {

                                /**
                                 * Constructs a new CreditCardsAPI service.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a CreditCardsAPI
                                 * @extends $protobuf.rpc.Service
                                 * @constructor
                                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                                 */
                                function CreditCardsAPI(rpcImpl, requestDelimited, responseDelimited) {
                                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                                }

                                (CreditCardsAPI.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CreditCardsAPI;

                                /**
                                 * Callback as used by {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI#addCard}.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @typedef AddCardCallback
                                 * @type {function}
                                 * @param {Error|null} error Error, if any
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse} [response] AddCardResponse
                                 */

                                /**
                                 * Calls AddCard.
                                 * @function addCard
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IAddCardRequest} request AddCardRequest message or plain object
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI.AddCardCallback} callback Node-style callback called with the error, if any, and AddCardResponse
                                 * @returns {undefined}
                                 * @variation 1
                                 */
                                Object.defineProperty(CreditCardsAPI.prototype.addCard = function addCard(request, callback) {
                                    return this.rpcCall(addCard, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse, request, callback);
                                }, "name", { value: "AddCard" });

                                /**
                                 * Calls AddCard.
                                 * @function addCard
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IAddCardRequest} request AddCardRequest message or plain object
                                 * @returns {Promise<ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse>} Promise
                                 * @variation 2
                                 */

                                /**
                                 * Callback as used by {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI#cardsList}.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @typedef CardsListCallback
                                 * @type {function}
                                 * @param {Error|null} error Error, if any
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse} [response] CardsListResponse
                                 */

                                /**
                                 * Calls CardsList.
                                 * @function cardsList
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardsListRequest} request CardsListRequest message or plain object
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI.CardsListCallback} callback Node-style callback called with the error, if any, and CardsListResponse
                                 * @returns {undefined}
                                 * @variation 1
                                 */
                                Object.defineProperty(CreditCardsAPI.prototype.cardsList = function cardsList(request, callback) {
                                    return this.rpcCall(cardsList, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse, request, callback);
                                }, "name", { value: "CardsList" });

                                /**
                                 * Calls CardsList.
                                 * @function cardsList
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardsListRequest} request CardsListRequest message or plain object
                                 * @returns {Promise<ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse>} Promise
                                 * @variation 2
                                 */

                                /**
                                 * Callback as used by {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI#removeCard}.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @typedef RemoveCardCallback
                                 * @type {function}
                                 * @param {Error|null} error Error, if any
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse} [response] RemoveCardResponse
                                 */

                                /**
                                 * Calls RemoveCard.
                                 * @function removeCard
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRemoveCardRequest} request RemoveCardRequest message or plain object
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI.RemoveCardCallback} callback Node-style callback called with the error, if any, and RemoveCardResponse
                                 * @returns {undefined}
                                 * @variation 1
                                 */
                                Object.defineProperty(CreditCardsAPI.prototype.removeCard = function removeCard(request, callback) {
                                    return this.rpcCall(removeCard, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse, request, callback);
                                }, "name", { value: "RemoveCard" });

                                /**
                                 * Calls RemoveCard.
                                 * @function removeCard
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRemoveCardRequest} request RemoveCardRequest message or plain object
                                 * @returns {Promise<ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse>} Promise
                                 * @variation 2
                                 */

                                /**
                                 * Callback as used by {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI#rootCard}.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @typedef RootCardCallback
                                 * @type {function}
                                 * @param {Error|null} error Error, if any
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse} [response] RootCardResponse
                                 */

                                /**
                                 * Calls RootCard.
                                 * @function rootCard
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRootCardRequest} request RootCardRequest message or plain object
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI.RootCardCallback} callback Node-style callback called with the error, if any, and RootCardResponse
                                 * @returns {undefined}
                                 * @variation 1
                                 */
                                Object.defineProperty(CreditCardsAPI.prototype.rootCard = function rootCard(request, callback) {
                                    return this.rpcCall(rootCard, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse, request, callback);
                                }, "name", { value: "RootCard" });

                                /**
                                 * Calls RootCard.
                                 * @function rootCard
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRootCardRequest} request RootCardRequest message or plain object
                                 * @returns {Promise<ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse>} Promise
                                 * @variation 2
                                 */

                                /**
                                 * Callback as used by {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI#publicKey}.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @typedef PublicKeyCallback
                                 * @type {function}
                                 * @param {Error|null} error Error, if any
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse} [response] PublicKeyResponse
                                 */

                                /**
                                 * Calls PublicKey.
                                 * @function publicKey
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IPublicKeyRequest} request PublicKeyRequest message or plain object
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI.PublicKeyCallback} callback Node-style callback called with the error, if any, and PublicKeyResponse
                                 * @returns {undefined}
                                 * @variation 1
                                 */
                                Object.defineProperty(CreditCardsAPI.prototype.publicKey = function publicKey(request, callback) {
                                    return this.rpcCall(publicKey, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyRequest, $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse, request, callback);
                                }, "name", { value: "PublicKey" });

                                /**
                                 * Calls PublicKey.
                                 * @function publicKey
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CreditCardsAPI
                                 * @instance
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IPublicKeyRequest} request PublicKeyRequest message or plain object
                                 * @returns {Promise<ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse>} Promise
                                 * @variation 2
                                 */

                                return CreditCardsAPI;
                            })();

                            creditcards.CardData = (function() {

                                /**
                                 * Properties of a CardData.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface ICardData
                                 * @property {string|null} [id] CardData id
                                 * @property {string|null} [number] CardData number
                                 * @property {number|null} [expMonth] CardData expMonth
                                 * @property {number|null} [expYear] CardData expYear
                                 * @property {boolean|null} [root] CardData root
                                 */

                                /**
                                 * Constructs a new CardData.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a CardData.
                                 * @implements ICardData
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardData=} [properties] Properties to set
                                 */
                                function CardData(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * CardData id.
                                 * @member {string} id
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData
                                 * @instance
                                 */
                                CardData.prototype.id = "";

                                /**
                                 * CardData number.
                                 * @member {string} number
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData
                                 * @instance
                                 */
                                CardData.prototype.number = "";

                                /**
                                 * CardData expMonth.
                                 * @member {number} expMonth
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData
                                 * @instance
                                 */
                                CardData.prototype.expMonth = 0;

                                /**
                                 * CardData expYear.
                                 * @member {number} expYear
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData
                                 * @instance
                                 */
                                CardData.prototype.expYear = 0;

                                /**
                                 * CardData root.
                                 * @member {boolean} root
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData
                                 * @instance
                                 */
                                CardData.prototype.root = false;

                                /**
                                 * Encodes the specified CardData message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardData} message CardData message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                CardData.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.number);
                                    if (message.expMonth != null && Object.hasOwnProperty.call(message, "expMonth"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.expMonth);
                                    if (message.expYear != null && Object.hasOwnProperty.call(message, "expYear"))
                                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.expYear);
                                    if (message.root != null && Object.hasOwnProperty.call(message, "root"))
                                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.root);
                                    return writer;
                                };

                                /**
                                 * Decodes a CardData message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData} CardData
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                CardData.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.id = reader.string();
                                            break;
                                        case 2:
                                            message.number = reader.string();
                                            break;
                                        case 3:
                                            message.expMonth = reader.int32();
                                            break;
                                        case 4:
                                            message.expYear = reader.int32();
                                            break;
                                        case 5:
                                            message.root = reader.bool();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return CardData;
                            })();

                            creditcards.AddCardRequest = (function() {

                                /**
                                 * Properties of an AddCardRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IAddCardRequest
                                 * @property {string|null} [customerID] AddCardRequest customerID
                                 * @property {string|null} [cardData] AddCardRequest cardData
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product|null} [product] AddCardRequest product
                                 */

                                /**
                                 * Constructs a new AddCardRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents an AddCardRequest.
                                 * @implements IAddCardRequest
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IAddCardRequest=} [properties] Properties to set
                                 */
                                function AddCardRequest(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * AddCardRequest customerID.
                                 * @member {string} customerID
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest
                                 * @instance
                                 */
                                AddCardRequest.prototype.customerID = "";

                                /**
                                 * AddCardRequest cardData.
                                 * @member {string} cardData
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest
                                 * @instance
                                 */
                                AddCardRequest.prototype.cardData = "";

                                /**
                                 * AddCardRequest product.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product} product
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest
                                 * @instance
                                 */
                                AddCardRequest.prototype.product = 0;

                                /**
                                 * Encodes the specified AddCardRequest message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IAddCardRequest} message AddCardRequest message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                AddCardRequest.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.customerID != null && Object.hasOwnProperty.call(message, "customerID"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerID);
                                    if (message.cardData != null && Object.hasOwnProperty.call(message, "cardData"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardData);
                                    if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.product);
                                    return writer;
                                };

                                /**
                                 * Decodes an AddCardRequest message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest} AddCardRequest
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                AddCardRequest.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardRequest();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.customerID = reader.string();
                                            break;
                                        case 2:
                                            message.cardData = reader.string();
                                            break;
                                        case 3:
                                            message.product = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return AddCardRequest;
                            })();

                            creditcards.AddCardResponse = (function() {

                                /**
                                 * Properties of an AddCardResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IAddCardResponse
                                 * @property {boolean|null} [success] AddCardResponse success
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardData|null} [card] AddCardResponse card
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError|null} [error] AddCardResponse error
                                 */

                                /**
                                 * Constructs a new AddCardResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents an AddCardResponse.
                                 * @implements IAddCardResponse
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IAddCardResponse=} [properties] Properties to set
                                 */
                                function AddCardResponse(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * AddCardResponse success.
                                 * @member {boolean} success
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse
                                 * @instance
                                 */
                                AddCardResponse.prototype.success = false;

                                /**
                                 * AddCardResponse card.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardData|null|undefined} card
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse
                                 * @instance
                                 */
                                AddCardResponse.prototype.card = null;

                                /**
                                 * AddCardResponse error.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError|null|undefined} error
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse
                                 * @instance
                                 */
                                AddCardResponse.prototype.error = null;

                                /**
                                 * Encodes the specified AddCardResponse message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IAddCardResponse} message AddCardResponse message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                AddCardResponse.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
                                    if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                                        $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData.encode(message.card, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                                        $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Decodes an AddCardResponse message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse} AddCardResponse
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                AddCardResponse.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.AddCardResponse();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.success = reader.bool();
                                            break;
                                        case 2:
                                            message.card = $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData.decode(reader, reader.uint32());
                                            break;
                                        case 3:
                                            message.error = $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return AddCardResponse;
                            })();

                            creditcards.CardsListRequest = (function() {

                                /**
                                 * Properties of a CardsListRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface ICardsListRequest
                                 * @property {string|null} [customerID] CardsListRequest customerID
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product|null} [product] CardsListRequest product
                                 */

                                /**
                                 * Constructs a new CardsListRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a CardsListRequest.
                                 * @implements ICardsListRequest
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardsListRequest=} [properties] Properties to set
                                 */
                                function CardsListRequest(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * CardsListRequest customerID.
                                 * @member {string} customerID
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest
                                 * @instance
                                 */
                                CardsListRequest.prototype.customerID = "";

                                /**
                                 * CardsListRequest product.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product} product
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest
                                 * @instance
                                 */
                                CardsListRequest.prototype.product = 0;

                                /**
                                 * Encodes the specified CardsListRequest message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardsListRequest} message CardsListRequest message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                CardsListRequest.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.customerID != null && Object.hasOwnProperty.call(message, "customerID"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerID);
                                    if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.product);
                                    return writer;
                                };

                                /**
                                 * Decodes a CardsListRequest message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest} CardsListRequest
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                CardsListRequest.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListRequest();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.customerID = reader.string();
                                            break;
                                        case 2:
                                            message.product = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return CardsListRequest;
                            })();

                            creditcards.CardsListResponse = (function() {

                                /**
                                 * Properties of a CardsListResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface ICardsListResponse
                                 * @property {Array.<ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardData>|null} [cards] CardsListResponse cards
                                 */

                                /**
                                 * Constructs a new CardsListResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a CardsListResponse.
                                 * @implements ICardsListResponse
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardsListResponse=} [properties] Properties to set
                                 */
                                function CardsListResponse(properties) {
                                    this.cards = [];
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * CardsListResponse cards.
                                 * @member {Array.<ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardData>} cards
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse
                                 * @instance
                                 */
                                CardsListResponse.prototype.cards = $util.emptyArray;

                                /**
                                 * Encodes the specified CardsListResponse message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.ICardsListResponse} message CardsListResponse message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                CardsListResponse.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.cards != null && message.cards.length)
                                        for (var i = 0; i < message.cards.length; ++i)
                                            $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData.encode(message.cards[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Decodes a CardsListResponse message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse} CardsListResponse
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                CardsListResponse.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardsListResponse();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            if (!(message.cards && message.cards.length))
                                                message.cards = [];
                                            message.cards.push($root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.CardData.decode(reader, reader.uint32()));
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return CardsListResponse;
                            })();

                            creditcards.RemoveCardRequest = (function() {

                                /**
                                 * Properties of a RemoveCardRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IRemoveCardRequest
                                 * @property {string|null} [customerID] RemoveCardRequest customerID
                                 * @property {string|null} [cardID] RemoveCardRequest cardID
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product|null} [product] RemoveCardRequest product
                                 */

                                /**
                                 * Constructs a new RemoveCardRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a RemoveCardRequest.
                                 * @implements IRemoveCardRequest
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRemoveCardRequest=} [properties] Properties to set
                                 */
                                function RemoveCardRequest(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * RemoveCardRequest customerID.
                                 * @member {string} customerID
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest
                                 * @instance
                                 */
                                RemoveCardRequest.prototype.customerID = "";

                                /**
                                 * RemoveCardRequest cardID.
                                 * @member {string} cardID
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest
                                 * @instance
                                 */
                                RemoveCardRequest.prototype.cardID = "";

                                /**
                                 * RemoveCardRequest product.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product} product
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest
                                 * @instance
                                 */
                                RemoveCardRequest.prototype.product = 0;

                                /**
                                 * Encodes the specified RemoveCardRequest message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRemoveCardRequest} message RemoveCardRequest message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                RemoveCardRequest.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.customerID != null && Object.hasOwnProperty.call(message, "customerID"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerID);
                                    if (message.cardID != null && Object.hasOwnProperty.call(message, "cardID"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardID);
                                    if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.product);
                                    return writer;
                                };

                                /**
                                 * Decodes a RemoveCardRequest message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest} RemoveCardRequest
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                RemoveCardRequest.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardRequest();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.customerID = reader.string();
                                            break;
                                        case 2:
                                            message.cardID = reader.string();
                                            break;
                                        case 3:
                                            message.product = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return RemoveCardRequest;
                            })();

                            creditcards.RemoveCardResponse = (function() {

                                /**
                                 * Properties of a RemoveCardResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IRemoveCardResponse
                                 * @property {boolean|null} [success] RemoveCardResponse success
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError|null} [error] RemoveCardResponse error
                                 */

                                /**
                                 * Constructs a new RemoveCardResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a RemoveCardResponse.
                                 * @implements IRemoveCardResponse
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRemoveCardResponse=} [properties] Properties to set
                                 */
                                function RemoveCardResponse(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * RemoveCardResponse success.
                                 * @member {boolean} success
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse
                                 * @instance
                                 */
                                RemoveCardResponse.prototype.success = false;

                                /**
                                 * RemoveCardResponse error.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError|null|undefined} error
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse
                                 * @instance
                                 */
                                RemoveCardResponse.prototype.error = null;

                                /**
                                 * Encodes the specified RemoveCardResponse message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRemoveCardResponse} message RemoveCardResponse message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                RemoveCardResponse.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
                                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                                        $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError.encode(message.error, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Decodes a RemoveCardResponse message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse} RemoveCardResponse
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                RemoveCardResponse.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RemoveCardResponse();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.success = reader.bool();
                                            break;
                                        case 2:
                                            message.error = $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return RemoveCardResponse;
                            })();

                            creditcards.RootCardRequest = (function() {

                                /**
                                 * Properties of a RootCardRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IRootCardRequest
                                 * @property {string|null} [customerID] RootCardRequest customerID
                                 * @property {string|null} [cardID] RootCardRequest cardID
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product|null} [product] RootCardRequest product
                                 */

                                /**
                                 * Constructs a new RootCardRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a RootCardRequest.
                                 * @implements IRootCardRequest
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRootCardRequest=} [properties] Properties to set
                                 */
                                function RootCardRequest(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * RootCardRequest customerID.
                                 * @member {string} customerID
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest
                                 * @instance
                                 */
                                RootCardRequest.prototype.customerID = "";

                                /**
                                 * RootCardRequest cardID.
                                 * @member {string} cardID
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest
                                 * @instance
                                 */
                                RootCardRequest.prototype.cardID = "";

                                /**
                                 * RootCardRequest product.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product} product
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest
                                 * @instance
                                 */
                                RootCardRequest.prototype.product = 0;

                                /**
                                 * Encodes the specified RootCardRequest message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRootCardRequest} message RootCardRequest message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                RootCardRequest.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.customerID != null && Object.hasOwnProperty.call(message, "customerID"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerID);
                                    if (message.cardID != null && Object.hasOwnProperty.call(message, "cardID"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardID);
                                    if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.product);
                                    return writer;
                                };

                                /**
                                 * Decodes a RootCardRequest message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest} RootCardRequest
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                RootCardRequest.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardRequest();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.customerID = reader.string();
                                            break;
                                        case 2:
                                            message.cardID = reader.string();
                                            break;
                                        case 3:
                                            message.product = reader.int32();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return RootCardRequest;
                            })();

                            creditcards.RootCardResponse = (function() {

                                /**
                                 * Properties of a RootCardResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IRootCardResponse
                                 * @property {boolean|null} [success] RootCardResponse success
                                 * @property {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError|null} [error] RootCardResponse error
                                 */

                                /**
                                 * Constructs a new RootCardResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a RootCardResponse.
                                 * @implements IRootCardResponse
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRootCardResponse=} [properties] Properties to set
                                 */
                                function RootCardResponse(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * RootCardResponse success.
                                 * @member {boolean} success
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse
                                 * @instance
                                 */
                                RootCardResponse.prototype.success = false;

                                /**
                                 * RootCardResponse error.
                                 * @member {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError|null|undefined} error
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse
                                 * @instance
                                 */
                                RootCardResponse.prototype.error = null;

                                /**
                                 * Encodes the specified RootCardResponse message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IRootCardResponse} message RootCardResponse message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                RootCardResponse.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
                                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                                        $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError.encode(message.error, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                    return writer;
                                };

                                /**
                                 * Decodes a RootCardResponse message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse} RootCardResponse
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                RootCardResponse.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.RootCardResponse();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.success = reader.bool();
                                            break;
                                        case 2:
                                            message.error = $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError.decode(reader, reader.uint32());
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return RootCardResponse;
                            })();

                            creditcards.PublicKeyRequest = (function() {

                                /**
                                 * Properties of a PublicKeyRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IPublicKeyRequest
                                 */

                                /**
                                 * Constructs a new PublicKeyRequest.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a PublicKeyRequest.
                                 * @implements IPublicKeyRequest
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IPublicKeyRequest=} [properties] Properties to set
                                 */
                                function PublicKeyRequest(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * Encodes the specified PublicKeyRequest message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyRequest.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyRequest
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IPublicKeyRequest} message PublicKeyRequest message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PublicKeyRequest.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    return writer;
                                };

                                /**
                                 * Decodes a PublicKeyRequest message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyRequest
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyRequest} PublicKeyRequest
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PublicKeyRequest.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyRequest();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return PublicKeyRequest;
                            })();

                            creditcards.PublicKeyResponse = (function() {

                                /**
                                 * Properties of a PublicKeyResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @interface IPublicKeyResponse
                                 * @property {string|null} [publicKey] PublicKeyResponse publicKey
                                 */

                                /**
                                 * Constructs a new PublicKeyResponse.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards
                                 * @classdesc Represents a PublicKeyResponse.
                                 * @implements IPublicKeyResponse
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IPublicKeyResponse=} [properties] Properties to set
                                 */
                                function PublicKeyResponse(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * PublicKeyResponse publicKey.
                                 * @member {string} publicKey
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse
                                 * @instance
                                 */
                                PublicKeyResponse.prototype.publicKey = "";

                                /**
                                 * Encodes the specified PublicKeyResponse message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.IPublicKeyResponse} message PublicKeyResponse message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                PublicKeyResponse.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.publicKey != null && Object.hasOwnProperty.call(message, "publicKey"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.publicKey);
                                    return writer;
                                };

                                /**
                                 * Decodes a PublicKeyResponse message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse} PublicKeyResponse
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                PublicKeyResponse.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.PublicKeyResponse();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.publicKey = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return PublicKeyResponse;
                            })();

                            /**
                             * Product enum.
                             * @name ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.creditcards.Product
                             * @enum {number}
                             * @property {number} UNKNOWN_PRODUCT=0 UNKNOWN_PRODUCT value
                             * @property {number} QLEAN_BASIC=1 QLEAN_BASIC value
                             * @property {number} QLEAN_DEEP_CLEANING=2 QLEAN_DEEP_CLEANING value
                             * @property {number} DRYCLEANING_CURTAINS=3 DRYCLEANING_CURTAINS value
                             * @property {number} SUBSCRIPTION=4 SUBSCRIPTION value
                             * @property {number} DRYCLEANING_FURNITURE=5 DRYCLEANING_FURNITURE value
                             * @property {number} CLEANING_HOUSE_DEEP=6 CLEANING_HOUSE_DEEP value
                             * @property {number} WOLA_DELIVERY=7 WOLA_DELIVERY value
                             * @property {number} SELL_GOODS=8 SELL_GOODS value
                             * @property {number} WOLA=9 WOLA value
                             */
                            creditcards.Product = (function() {
                                var valuesById = {}, values = Object.create(valuesById);
                                values[valuesById[0] = "UNKNOWN_PRODUCT"] = 0;
                                values[valuesById[1] = "QLEAN_BASIC"] = 1;
                                values[valuesById[2] = "QLEAN_DEEP_CLEANING"] = 2;
                                values[valuesById[3] = "DRYCLEANING_CURTAINS"] = 3;
                                values[valuesById[4] = "SUBSCRIPTION"] = 4;
                                values[valuesById[5] = "DRYCLEANING_FURNITURE"] = 5;
                                values[valuesById[6] = "CLEANING_HOUSE_DEEP"] = 6;
                                values[valuesById[7] = "WOLA_DELIVERY"] = 7;
                                values[valuesById[8] = "SELL_GOODS"] = 8;
                                values[valuesById[9] = "WOLA"] = 9;
                                return values;
                            })();

                            return creditcards;
                        })();

                        finance_acquiring_svs.domain = (function() {

                            /**
                             * Namespace domain.
                             * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs
                             * @namespace
                             */
                            var domain = {};

                            domain.AppError = (function() {

                                /**
                                 * Properties of an AppError.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain
                                 * @interface IAppError
                                 * @property {string|null} [code] AppError code
                                 * @property {string|null} [message] AppError message
                                 * @property {boolean|null} [retryable] AppError retryable
                                 * @property {google.protobuf.IDuration|null} [retryDelay] AppError retryDelay
                                 * @property {string|null} [details] AppError details
                                 */

                                /**
                                 * Constructs a new AppError.
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain
                                 * @classdesc Represents an AppError.
                                 * @implements IAppError
                                 * @constructor
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError=} [properties] Properties to set
                                 */
                                function AppError(properties) {
                                    if (properties)
                                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * AppError code.
                                 * @member {string} code
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError
                                 * @instance
                                 */
                                AppError.prototype.code = "";

                                /**
                                 * AppError message.
                                 * @member {string} message
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError
                                 * @instance
                                 */
                                AppError.prototype.message = "";

                                /**
                                 * AppError retryable.
                                 * @member {boolean} retryable
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError
                                 * @instance
                                 */
                                AppError.prototype.retryable = false;

                                /**
                                 * AppError retryDelay.
                                 * @member {google.protobuf.IDuration|null|undefined} retryDelay
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError
                                 * @instance
                                 */
                                AppError.prototype.retryDelay = null;

                                /**
                                 * AppError details.
                                 * @member {string} details
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError
                                 * @instance
                                 */
                                AppError.prototype.details = "";

                                /**
                                 * Encodes the specified AppError message. Does not implicitly {@link ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError.verify|verify} messages.
                                 * @function encode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError
                                 * @static
                                 * @param {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.IAppError} message AppError message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                AppError.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                                    if (message.retryable != null && Object.hasOwnProperty.call(message, "retryable"))
                                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.retryable);
                                    if (message.retryDelay != null && Object.hasOwnProperty.call(message, "retryDelay"))
                                        $root.google.protobuf.Duration.encode(message.retryDelay, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                    if (message.details != null && Object.hasOwnProperty.call(message, "details"))
                                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.details);
                                    return writer;
                                };

                                /**
                                 * Decodes an AppError message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError} AppError
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                AppError.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ru.qleanlabs.gitlab.platform.finance.finance_acquiring_svs.domain.AppError();
                                    while (reader.pos < end) {
                                        var tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1:
                                            message.code = reader.string();
                                            break;
                                        case 2:
                                            message.message = reader.string();
                                            break;
                                        case 3:
                                            message.retryable = reader.bool();
                                            break;
                                        case 4:
                                            message.retryDelay = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                            break;
                                        case 5:
                                            message.details = reader.string();
                                            break;
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                return AppError;
                            })();

                            return domain;
                        })();

                        return finance_acquiring_svs;
                    })();

                    return finance;
                })();

                return platform;
            })();

            return gitlab;
        })();

        return qleanlabs;
    })();

    return ru;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.FieldMask = (function() {

            /**
             * Properties of a FieldMask.
             * @memberof google.protobuf
             * @interface IFieldMask
             * @property {Array.<string>|null} [paths] FieldMask paths
             */

            /**
             * Constructs a new FieldMask.
             * @memberof google.protobuf
             * @classdesc Represents a FieldMask.
             * @implements IFieldMask
             * @constructor
             * @param {google.protobuf.IFieldMask=} [properties] Properties to set
             */
            function FieldMask(properties) {
                this.paths = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldMask paths.
             * @member {Array.<string>} paths
             * @memberof google.protobuf.FieldMask
             * @instance
             */
            FieldMask.prototype.paths = $util.emptyArray;

            /**
             * Encodes the specified FieldMask message. Does not implicitly {@link google.protobuf.FieldMask.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldMask
             * @static
             * @param {google.protobuf.IFieldMask} message FieldMask message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldMask.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.paths != null && message.paths.length)
                    for (var i = 0; i < message.paths.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.paths[i]);
                return writer;
            };

            /**
             * Decodes a FieldMask message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldMask
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldMask} FieldMask
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldMask.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldMask();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.paths && message.paths.length))
                            message.paths = [];
                        message.paths.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return FieldMask;
        })();

        protobuf.Duration = (function() {

            /**
             * Properties of a Duration.
             * @memberof google.protobuf
             * @interface IDuration
             * @property {number|Long|null} [seconds] Duration seconds
             * @property {number|null} [nanos] Duration nanos
             */

            /**
             * Constructs a new Duration.
             * @memberof google.protobuf
             * @classdesc Represents a Duration.
             * @implements IDuration
             * @constructor
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             */
            function Duration(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Duration seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.seconds = 0;

            /**
             * Duration nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.nanos = 0;

            /**
             * Encodes the specified Duration message. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Decodes a Duration message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Duration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Duration;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
